import {
  Grid,
  View,
  useTheme,
  Heading,
  Flex,
  Button,
  Icon,
  Divider,
  Card
} from '@aws-amplify/ui-react'
import DashboardHeader from '../../layout/DashboardHeader'
import { primaryButton } from '../../assets/style'
import { BsFillFileEarmarkPlusFill, BsUpload } from 'react-icons/bs'
import { MdOutlineDeleteForever } from 'react-icons/md'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
const ContentPreview = () => {
  const { tokens } = useTheme()
  const shadowProp = {
    boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)'
  }
  const card = {
    width: '80%',
    backgroundColor: 'white',
    marginLeft: '11%',
    boxShadow: '0px 4px 9px 0px rgb(0 0 0 / 20%)'
  }
  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  return (
    <div>
      <View>
        <Grid
          templateColumns={{ base: '1fr', large: '5fr' }}
          templateRows={{ base: 'repeat(4, 10rem)', large: 'repeat(2, 5rem)' }}
          gap='0.5rem'
        >
          <View>
            <Card
              className='cardmain'
              style={card}
              direction={{ base: 'column', large: 'row' }}
            ></Card>
          </View>
        </Grid>
      </View>
    </div>
  )
}
export default ContentPreview
