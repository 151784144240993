import React, { useEffect, useState, CSSProperties } from "react";
import {
  Button,
  ButtonGroup,
  useTheme,
  TextField,
  Text,
  Icon,
  Flex,
  View,
  Grid,
  SelectField,
} from "@aws-amplify/ui-react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from '@mui/icons-material/Instagram';
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { createAdCampaign, updateAdCampaign } from "../../graphql/mutations";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { listAdCampaigns } from "../../graphql/queries";
import "../../App.css";
import { BiReset, BiSkipNext } from "react-icons/bi";
import { HiChevronDoubleRight } from "react-icons/hi";
import { SiFacebook } from "react-icons/si";
import { GrInstagram } from 'react-icons/gr';
import { ResetIcon, primaryButton, secondaryButton } from "../../assets/style";
import { ToastContainer, toast } from "react-toastify";
import { ImFacebook } from "react-icons/im";
const SocialChannel = ({ setSocialchannelValue, SocialChannelValue, redirect_pt,DisableFieldsAfterpublished }) => {
  const setInput = (key, value) => {
    let temp = [...SocialChannelValue]
    temp[0][key] = value;
    setSocialchannelValue(temp);
  }

  useEffect(() => {
    if (SocialChannelValue[0]["isigpost"] != "") {

      setShowInstagram(SocialChannelValue[0]["isigpost"])
    }
    if (SocialChannelValue[0]["isfbpost"] != "") {
      setShowFacebook(SocialChannelValue[0]["isfbpost"])
    }
  }, [SocialChannelValue])
  const [showFacebook, setShowFacebook] = useState();
  const [showInstagram, setShowInstagram] = useState();
  const handleShowFacebook = () => {
    setShowFacebook(!showFacebook);
    let temp = [...SocialChannelValue]
    temp[0]["isfbpost"] = !showFacebook;
    setSocialchannelValue(temp);

  };
  const handleShowInstagram = () => {
    setShowInstagram(!showInstagram);

    let temp = [...SocialChannelValue]
    temp[0]["isigpost"] = !showInstagram;


  };
  return (<><div>
    <ToastContainer autoClose={1500} limit={0} />
    <Flex
      direction={{
        base: "column",
        small: "column",
        medium: "column",
        large: "column",
        xl: "column",
        xxl: "column",
      }}
      alignItems="center"
      gap={{ large: "xxs" }}
      style={{
        paddingTop: "2em",
        border: "2px solid #E8E8E8",
        borderRadius: "0.5em",
      }}
    >
      <View width="100%" height="8rem" alignItems='center' margin={"auto"}>
        <Flex direction={"column"} wrap={"wrap"} alignItems={"center"} justifyContent={'center'}>
          <Text fontWeight={"bold"}>Select Social Channels
          </Text>


        </Flex>
        <Flex direction={"row"} rap={"wrap"} alignItems={"center"} justifyContent={'center'} marginTop={"1rem"}>
          <ButtonGroup>
            {showFacebook ? (
              <Button
                backgroundColor="#4267B2"
                boxShadow="large"
                margin="auto"
                color="white"
                borderRadius="50%"
                padding={"0em"}
                border="1px solid white"
                onClick={handleShowFacebook}
                fontSize={{ base: "2rem", small: "2rem", large: "3rem", xl: "3rem" }}
                disabled={DisableFieldsAfterpublished}
              >
                <Icon as={ImFacebook} style={{ marginTop: "0.31em", marginLeft: "0.32em" }}
                ></Icon>
                {/* onClick={(event)=>{setInput("platform","fb");redirect_pt()}}  */}
              </Button>
            ) : (
              <Button
                backgroundColor="white"
                boxShadow="large"
                margin="auto"
                color="grey"
                borderRadius="50%"
                padding={"0em"}
                border="1px solid grey"
                onClick={handleShowFacebook}
                fontSize={{ base: "2rem", small: "2rem", large: "3rem", xl: "3rem" }}
                disabled={DisableFieldsAfterpublished}
              >
                <Icon as={ImFacebook} style={{ marginTop: "0.31em", marginLeft: "0.32em" }}
                ></Icon>
                {/* onClick={(event)=>{setInput("platform","fb");redirect_pt()}}  */}
              </Button>
            )}
            {showInstagram ? (
              <Button
                boxShadow="large"
                margin="auto"
                borderRadius="50%"
                padding={{ base: "0.5em", small: "0.5em", large: "0.7em", xl: "0.7em" }}
                color="white"
                border="1px solid white"
                backgroundImage="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
                onClick={handleShowInstagram}
                disabled={DisableFieldsAfterpublished}
              >
                <Icon as={InstagramIcon} fontSize={{ base: "1.7rem", small: "1.7rem", large: "2.4rem", xl: "2.4rem" }} />
                {/* onClick={(event)=>{setInput("platform","ig");redirect_pt()}} */}
              </Button>
            ) : (
              <Button
                backgroundColor="white"
                boxShadow="large"
                margin="auto"
                borderRadius="50%"
                color="gray"
                padding={{ base: "0.5em", small: "0.5em", large: "0.7em", xl: "0.7em" }}
                border="1px solid grey"
                onClick={handleShowInstagram}
                disabled={DisableFieldsAfterpublished}
              >
                <Icon as={InstagramIcon} fontSize={{ base: "1.7rem", small: "1.7rem", large: "2.4rem", xl: "2.4rem" }} />
                {/* onClick={(event)=>{setInput("platform","ig");redirect_pt()}} */}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </View>
    </Flex>
  </div></>);
}

export default SocialChannel;