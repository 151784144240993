const  Helper= {
   FACEBOOK_APP_ID : "968921934621430",//"890216639514690",//"320067396410532",
   FACEBOOK_CLIENT_ID : "45bec8ead61ee0bf678294cde0cbca1f",//"65a6a477a0fec2b2a85afd39aa0ca3dc", //d58604f795a506135709ce360a7fcc38, //45bec8ead61ee0bf678294cde0cbca1f, //0ff7732b122603cc60140069a48aed01
   FACEBOOK_VERSION : "15.0",
   FACEBOOK_FIELDS : "name,email,picture",
   FACEBOOK_SCOPE : "ads_management",//public_profile
   FACEBOOK_LONG_ACCESS_TOKEN_URL :"https://graph.facebook.com/v15.0/oauth/access_token"
}

export default Helper;
