import React, { useEffect, useState, CSSProperties } from "react";
import { Flex } from "@aws-amplify/ui-react";
import "./managestyle.css";
import {
  Icon,
  SelectField,
  FieldGroupIcon,
  FieldGroupIconButton,
  ScrollView,
  CheckboxField,
} from "@aws-amplify/ui-react";
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Button,
  Grid,
  View,
  useTheme,
  TextField,
  Text,
  Card,
  Divider,
  SearchField,
} from "@aws-amplify/ui-react";
import {
  BiMinus,
  BiReset,
  BiExit,
  BiSave,
  BiDotsHorizontalRounded,
} from "react-icons/bi";
import { RiEditLine, RiEditCircleFill, RiSave3Line } from "react-icons/ri";
import { BsHandIndexThumb, BsClockHistory } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import {
  getAdDetails,
  getAdHistory,
  getAdSet,
  listAdDetails,
  getUserInfo,
} from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import "../../App.css";
import {
  createAdHistory,
  updateAdHistory,
  updateAdDetails,
  deleteAdHistory,
} from "../../graphql/mutations";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import Parser from "html-react-parser";
import "./Ad.css";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { SwitchField } from "@aws-amplify/ui-react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  listUserInfos,
  listAdStatuses,
  listAdHistories,
} from "../../graphql/queries";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { datagridSx } from "../../assets/style";
import { VscOpenPreview } from "react-icons/vsc";
import { MdOutlinePending, MdCancel, MdPending } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { GoIssueOpened } from "react-icons/go";
import { useRef } from "react";
import { primaryButton, secondaryButton } from "../../assets/style";
import Iframe from "react-iframe";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import "../../App.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SyncLockOutlinedIcon from "@mui/icons-material/SyncLockOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { FcApproval, FcCancel } from "react-icons/fc";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

const AdPreview = ({
  ADID_FRM_AD,
  Ad_History_ID,
  ADID_FRM_DB,
  Version_Frm_MC,
  version_adcreate,
  popuponclick,
  editAdValues,
  foradmin_version,
  foradminad_id,
  editAdsetValue,
  adsAccountId,
  show,
  UPDATED_ADCREATIVE_ID,
  handleClose,
  AD_ID,
  PreviewUser,
  PREVIEW_FORMAT,
  accessToken,
  adsuserlist,
  ADSET_ID,
  AD_NAME_FRM_ADPAGE,
  adstatelist,
  messagesEndRef,
  AddformState,
  DisableAfterPublish
}) => {
  const [Progressbar, setProgressbar] = useState(false);
  const [filterAd, setfilterAd] = useState([]);
  const [filteruser, setfilteruser] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [AssignUser, setAssignUser] = useState();
  const [selectedAd, setSelectedAd] = useState();
  const [disableDropDown, setdisableDropDown] = useState(false);
  const [selectedAdformat, setSelectedAdformat] = useState();
  const [previewresponse, setPreviewresponse] = useState();
  const [iframe_Response, setIframe_Response] = useState();
  const [FbAdIdtoDB, set_FbAdIdtoDB] = useState();
  const [AD_IDtoDB, setAD_IDtoDB] = useState();
  const [Nullcheck, setNullcheck] = useState();
  const [Version, setVersion] = useState();
  const [AdState, setAdState] = useState();
  const [PreviewformState, setPreviewformState] = useState({
    addetailsID: "",
    ad_status_id: "",
    assigned_by_userid: "",
    assigned_to_userid: "",
  });

  let rolecheck = localStorage.getItem("role_id");
  const location = useLocation();
  const navigate = useNavigate();

  const OnAdStateChange = (e) => {
    setSelectedState(e);

    console.log("AAAAAAAAA",e)
    if (
      localStorage.getItem("role_id") === "1" ||
      localStorage.getItem("role_id") === "6"
    ) {
      if (e == "6" || e == "5") {
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 2
          )
        );
        if (
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 2
          ).length == 1
        ) {
          setSelectedUser(
            adsuserlist.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == 2
            )[0].id
          );
        }
      }
      if (e == "2") {
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 5
          )
        );
        if (
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 5
          ).length == 1
        ) {
          setSelectedUser(
            adsuserlist.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == 5
            )[0].id
          );
        }
      }
      if (e == "3") {
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 4
          )
        );
        if (
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 4
          ).length == 1
        ) {
          setSelectedUser(
            adsuserlist.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == 4
            )[0].id
          );
        }
      }
      if (e == "1") {
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          )
        );
        if (
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          ).length == 1
        ) {
          setSelectedUser(
            adsuserlist.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == localStorage.getItem("role_id")
            )[0].id
          );
        }
      }
      if (e == "4") {
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          )
        );
        if (
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          ).length == 1
        ) {
          setSelectedUser(
            adsuserlist.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == localStorage.getItem("role_id")
            )[0].id
          );
        }
      }

    }
    //  else {
    //   setSelectedState(e);
    // }
  };

  const filterwithroleid = (Name, Ad) => {
    if (localStorage.getItem("role_id") === "2") {
      if (Name != undefined && Name != null && Name != "") {
        setfilterAd(
          adstatelist.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setSelectedState(Ad);
        setfilteruser(
          adsuserlist.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
        setSelectedUser(Name);
        setTimeout(() => {
          let temp = [...adstatelist];

          let usertemp = [...adsuserlist];

          usertemp.map((x) => {
            if (x.role_id != 5) {
              x.disabled = true;
            } else {
              x.disabled = false;
            }
          });

          temp.map((x) => {
            if (x.status_id != 2) {
              x.disabled = true;
            } else {
              x.disabled = false;
            }
          });

          setfilterAd(temp);

          setfilteruser(usertemp);
          console.log(temp, "jhvjhvjhvhjvTemp");
          console.log(usertemp, "kbkkkbUserTemp");

          setdisableDropDown(true);
        }, 500);
      } else {
        setfilterAd(adstatelist.filter((item) => item.status_id == 2));
        setSelectedState(2);
        setfilteruser(adsuserlist.filter((item) => item.role_id == 5));
        if (adsuserlist.filter((item) => item.role_id == 5).length === 1) {
          setSelectedUser(
            adsuserlist.filter((item) => item.role_id == 5)[0].id
          );
        }
        setdisableDropDown(true);
      }
    }
    //State_of_Ad PreviewUsered_Users
    else if (localStorage.getItem("role_id") === "5") {
      if (Name != undefined && Name != null && Name != "") {
        setfilterAd(
          adstatelist.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setSelectedState(Ad);
        setfilteruser(
          adsuserlist.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
        setSelectedUser(Name);
        setTimeout(() => {
          let temp = [...adstatelist];

          let usertemp = [...adsuserlist];

          usertemp.map((x) => {
            if (x.role_id != 4) {
              x.disabled = true;
            } else {
              x.disabled = false;
            }
          });

          temp.map((x) => {
            if (x.status_id != 3) {
              x.disabled = true;
            } else {
              x.disabled = false;
            }
          });

          setfilterAd(temp);

          setfilteruser(usertemp);
          setdisableDropDown(true);
        }, 500);
      } else {
        setfilterAd(adstatelist.filter((item) => item.status_id == 3));
        setSelectedState(3);
        setfilteruser(adsuserlist.filter((item) => item.role_id == 4));

        if (adsuserlist.filter((item) => item.role_id == 4).length === 1) {
          setSelectedUser(
            adsuserlist.filter((item) => item.role_id == 4)[0].id
          );
        }
        setdisableDropDown(true);
      }
    } else if (localStorage.getItem("role_id") === "6") {
      console.log("PreviewUser", PreviewUser);
      if (Name != undefined && Name != null && Name != "") {
        setfilterAd(
          adstatelist.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setSelectedState(Ad);
        setfilteruser(
          adsuserlist.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
        setSelectedUser(Name);
        setdisableDropDown(true);
      } else {
        setfilterAd(
          adstatelist.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setSelectedState(6);
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
          )
        );
        if (adsuserlist.filter((item) => item.role_id == 2).length === 1) {
          setSelectedUser(
            adsuserlist.filter((item) => item.role_id == 2)[0].id
          );
        }
        setdisableDropDown(true);
      }
    } else if (localStorage.getItem("role_id") === "1") {
      if (Name != undefined && Name != null && Name != "") {
        setfilterAd(
          adstatelist.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setSelectedState(Ad);
        setfilteruser(
          adsuserlist.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
        setSelectedUser(Name);
        setdisableDropDown(true);
      } else {
        setfilterAd(
          adstatelist.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setSelectedState(6);
        setfilteruser(
          adsuserlist.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
          )
        );
        if (adsuserlist.filter((item) => item.role_id == 2).length === 1) {
          setSelectedUser(
            adsuserlist.filter((item) => item.role_id == 2)[0].id
          );
        }
        setdisableDropDown(true);
      }
    }
  };

  useEffect(() => {
    ListAdhistories(Ad_History_ID);

    let FlexibleAdId=""
    if(foradminad_id!=undefined && foradminad_id != null && foradminad_id != ""){
      FlexibleAdId = foradminad_id;
      ListOfAdDetails(foradminad_id);
    }
    else if(editAdValues !=undefined && editAdValues != null && editAdValues != ""){
      FlexibleAdId =editAdValues.id;
      ListOfAdDetails(editAdValues.id);
    }
    else{
      FlexibleAdId =ADID_FRM_DB
      ListOfAdDetails(ADID_FRM_DB);
    }





    //   if (
    //     editAdValues.id !== undefined &&
    //     editAdValues.id !== null &&
    //     editAdValues.id !== ""
    //   ) {
    //     ListOfAdDetails(editAdValues.id);
    //   } else {
    //   }
    // } else {
    //   ListOfAdDetails(ADID_FRM_DB);
    // }

    if (location.state != undefined) {
      console.log("Lastcheck", location.state);
      setAssignUser(location.state.Assigned_user.split("~")[0]);
      setSelectedAd(location.state.AdState);
      filterwithroleid(
        location.state.Assigned_user.split("~")[0],
        location.state.AdState
      );
    } else {
      filterwithroleid();
    }
    console.log("AccessToken", accessToken);
  }, [location.state]);

  const IconArrowDropDown = () => {
    return <Icon pathData="M7 10L12 15L17 10H7Z" ariaLabel="Down arrow" />;
  };

  function setInput(key, value) {
    setPreviewformState({ ...PreviewformState, [key]: value });
    console.log(PreviewformState, "PreviewformState");
  }

  const AdFormatChange = (event) => {
    setSelectedAdformat(event.target.value);
    console.log("Adformat", event.target.value, selectedAdformat);
  };

  // const StateChange = (event) => {
  //   setSelectedState(event.target.value);
  //   console.log("StateofAd", event.target.value, selectedState);
  // };

  // const AssignedUserChange = (event) => {
  //   setSelectedUser(event.target.value);
  //   console.log("AssignedUser", event.target.value, selectedUser);
  // };

  //Ad preview in facebook

  const AdPreview = async () => {
    let Flexible_creative_id = "";
    if (
      UPDATED_ADCREATIVE_ID !== undefined &&
      UPDATED_ADCREATIVE_ID !== null &&
      UPDATED_ADCREATIVE_ID != ""
    ) {
      //alert("hi");
      Flexible_creative_id = UPDATED_ADCREATIVE_ID;
    }
    else if (localStorage.getItem("ExistingAdCreative_Id")!== undefined && localStorage.getItem("ExistingAdCreative_Id")!== null && localStorage.getItem("ExistingAdCreative_Id")!== "" ){
      Flexible_creative_id=localStorage.getItem("ExistingAdCreative_Id");

    } else {
      // alert("hlo");
      Flexible_creative_id = AD_ID;
    }
    // alert("preview :" + selectedAdformat);
    axios({
      method: "get",
      url:
        "https://graph.facebook.com/v15.0/" +
        Flexible_creative_id +
        "/previews?ad_format=" +
        selectedAdformat +
        "&access_token=" +
        accessToken,
      responseType: "stream",
    })
      .then(function (responsef) {
        console.log(responsef.data.data[0].body, "preview");
        setPreviewresponse(responsef.data.data[0].body);
        ResponseUrlsplit(responsef.data.data[0].body);
      })
      .catch((err) => {
        setIframe_Response(undefined);
        console.log(err);
      });
  };

  //Adpreview Response Optimization
  const ResponseUrlsplit = (res) => {
    let iframesrc = res;

    iframesrc.split(" ").map((url) => {
      if (url.startsWith("src")) {
        console.log("URLSPLIT", url.split('"')[1].replace("amp;", ""));
        setIframe_Response(url.split('"')[1].replace("amp;", ""));
        setTimeout(() => {
          console.log("here", iframe_Response);
        }, 1000);
      }
    });
  };

  const ListAdhistories = async (id) => {
    const obj = await API.graphql(graphqlOperation(getAdHistory, { id: id }));
    console.log(obj.data.getAdHistory.assigned_to_userid, "d");
    setAdState(obj.data.getAdHistory.ad_status_id);
    setNullcheck(obj.data.getAdHistory.assigned_to_userid);
    setVersion(obj.data.getAdHistory._version);
  };

  //BridgeFunction
  const BridgeFunction = () => {
    setProgressbar(true);
    if (Nullcheck == null || Nullcheck == undefined) {
      UpdateAdHistory();
    } else {
      SaveAdHistory();
    }
  };

  const adtoastId = useRef(null);

  //update Ad History
  const UpdateAdHistory = async () => {
    let FlexibleAdId=""
      if(foradminad_id!=undefined && foradminad_id != null && foradminad_id != ""){
        FlexibleAdId = foradminad_id;
      }
      else if(editAdValues !=undefined && editAdValues != null && editAdValues != ""){
        FlexibleAdId =editAdValues.id;
      }
      else{
        FlexibleAdId =ADID_FRM_DB
      }
    if (
      Ad_History_ID == undefined ||
      Ad_History_ID == "" ||
      Ad_History_ID == null
    ) {
      SaveAdHistory();
    } else {
      if (
        (localStorage.getItem("role_id") === "1" ||
          localStorage.getItem("role_id") === "6") &&
        selectedState === "4"
      ) {
        try {
          adtoastId.current = toast.loading(
            "Please wait while we are publishing your ad"
          );
          setTimeout(() => {
            setProgressbar(false);
          }, 2500);
          let adCreatives = {
            creative_id: AD_ID,
          };
          axios({
            method: "post",
            url:
              "https://graph.facebook.com/v15.0/" +
              adsAccountId +
              "/ads?name=" +
              AD_NAME_FRM_ADPAGE +
              "&adset_id=" +
              ADSET_ID +
              "&creative=" +
              JSON.stringify(adCreatives) +
              "&status=PAUSED&access_token=" +
              accessToken,

            responseType: "stream",
          })
            .then(async (responsef) => {
              console.log(responsef.data.id, "campaign_id");
              const obj = {
                id: FlexibleAdId,
                ad_status: "4",
                _version: foradmin_version,
                Published_Ad_Id: responsef.data.id,
              };
              toast.update(adtoastId.current, {
                render: "Ad Published Successfully",
                type: "success",
                isLoading: false,
                autoClose: 1000,
              });
              setTimeout(() => {
                setProgressbar(false);
              }, 2500);
              await API.graphql(
                graphqlOperation(updateAdDetails, { input: obj })
              )
                .then(async (response) => {
                  const createHistoryobj = {
                    ad_status_id: "4",
                    assigned_by_userid: localStorage.getItem("userid"),
                    assigned_to_userid: null,
                    addetailsID: FlexibleAdId,
                  };
                  await API.graphql(
                    graphqlOperation(createAdHistory, {
                      input: createHistoryobj,
                    })
                  ).then(() => {});
                })
                .catch(() => {
                  toast.error("error when save to db");
                  setTimeout(() => {
                    setProgressbar(false);
                  }, 2500);
                });
              //set_FbAdIdtoDB(responsef.data.id);

              // UpdateinExistingAd(responsef.data.id);
              // SaveAdHistory();
              setTimeout(() => {
                navigate("/newdashboard");
              }, 800);
            })
            .catch(async (error) => {
              toast.update(adtoastId.current, {
                render: "Something went wrong",
                type: "error",
                isLoading: false,
                autoClose: 1000,
              });
              setTimeout(() => {
                setProgressbar(false);
              }, 2500);
              const obj = {
                id: FlexibleAdId,
                ad_status: "1",
                _version: foradmin_version,
                Published_Ad_Id: null,
              };

              await API.graphql(
                graphqlOperation(updateAdDetails, { input: obj })
              )
                .then(async (response) => {
                  const createHistoryobj = {
                    ad_status_id: "1",
                    assigned_by_userid: localStorage.getItem("userid"),
                    assigned_to_userid: null,
                    addetailsID: FlexibleAdId,
                  };
                  await API.graphql(
                    graphqlOperation(createAdHistory, {
                      input: createHistoryobj,
                    })
                  ).then(() => {});
                })
                .catch(() => {
                  toast.error("error when save to db");
                  setTimeout(() => {
                    setProgressbar(false);
                  }, 2500);
                });
              // toast.error("Error Publishing Ad");
            });
        } catch (e) {
          console.log(e);
        }
      } else {
        const updtAdHistory = {
          id: Ad_History_ID,
          ad_status_id: selectedState,
          assigned_to_userid: selectedUser,
          _version: Version,
        };
        const adobj = {
          id: ADID_FRM_AD,
          ad_status: selectedState,
          _version: version_adcreate,
        };
        await API.graphql(
          graphqlOperation(updateAdDetails, { input: adobj })
        ).then(async () => {
          await API.graphql(
            graphqlOperation(updateAdHistory, { input: updtAdHistory })
          );
        });

        toast.success("Ad Assigned Successfully");
        setTimeout(() => {
          setProgressbar(false);
        }, 2500);
      }
      setTimeout(() => {
        navigate("/newdashboard");
      }, 1500);

      // SETADID_FRM_DB(obj.data.createAdDetails.id);
      //  alert(obj.data.createAdDetails.id + " : Adid");
    }
  };

  //Save AdHistory to DB
  const SaveAdHistory = async () => {
    try {
      let FlexibleAdId=""
      if(foradminad_id!=undefined && foradminad_id != null && foradminad_id != ""){
        FlexibleAdId = foradminad_id;
      }
      else if(editAdValues !=undefined && editAdValues != null && editAdValues != ""){
        FlexibleAdId =editAdValues.id;
      }
      else{
        FlexibleAdId =ADID_FRM_DB
      }

    //for creative_id

    let Flexible_creative_id=""
      if (
        UPDATED_ADCREATIVE_ID !== undefined &&
        UPDATED_ADCREATIVE_ID !== null &&
        UPDATED_ADCREATIVE_ID != ""
      ) {
        Flexible_creative_id = UPDATED_ADCREATIVE_ID;
      }
      else if (localStorage.getItem("ExistingAdCreative_Id")!== undefined && localStorage.getItem("ExistingAdCreative_Id")!== null && localStorage.getItem("ExistingAdCreative_Id")!== "" ){
        Flexible_creative_id=localStorage.getItem("ExistingAdCreative_Id");

      }else {
        Flexible_creative_id = AD_ID;
      }


      let AdHistory = {};
      if (
        (localStorage.getItem("role_id") === "1" ||
          localStorage.getItem("role_id") === "6") &&
        selectedState === "4"
      ) {
        try {
          adtoastId.current = toast.loading(
            "Please wait while we are publishing your ad"
          );
          setTimeout(() => {
            setProgressbar(false);
          }, 2500);
          let adCreatives = {
            creative_id: Flexible_creative_id,
          };
          console.log(adCreatives, "adCreatives");
          axios({
            method: "post",
            url:
              "https://graph.facebook.com/v15.0/" +
              adsAccountId +
              "/ads?name=" +
              editAdValues.ad_name +
              "&adset_id=" +
              editAdsetValue.adset_id +
              "&creative=" +
              JSON.stringify(adCreatives) +
              "&status=PAUSED&access_token=" +
              accessToken,

            responseType: "stream",
          })
            .then(async (responsef) => {
              console.log(responsef.data.id, "campaign_id");
              const obj = {
                id: FlexibleAdId,
                ad_status: "4",
                _version: foradmin_version,
                Published_Ad_Id: responsef.data.id,
              };
              toast.update(adtoastId.current, {
                render: "Ad Published Successfully",
                type: "success",
                isLoading: false,
                autoClose: 1000,
              });
              setTimeout(() => {
                setProgressbar(false);
              }, 2500);
              await API.graphql(
                graphqlOperation(updateAdDetails, { input: obj })
              )
                .then(async (response) => {
                  const createHistoryobj = {
                    ad_status_id: "4",
                    assigned_by_userid: localStorage.getItem("userid"),
                    assigned_to_userid: null,
                    addetailsID: FlexibleAdId,
                  };
                  await API.graphql(
                    graphqlOperation(createAdHistory, {
                      input: createHistoryobj,
                    })
                  ).then(() => {});
                })
                .catch(() => {
                  toast.error("error when save to db");
                  setTimeout(() => {
                    setProgressbar(false);
                  }, 2500);
                });
              //set_FbAdIdtoDB(responsef.data.id);

              // UpdateinExistingAd(responsef.data.id);
              // SaveAdHistory();
              // setTimeout(() => {
              //   navigate("/newdashboard");
              // }, 800);
            })
            .catch(async (error) => {
              toast.update(adtoastId.current, {
                render: "Something went wrong",
                type: "error",
                isLoading: false,
                autoClose: 1000,
              });
              setTimeout(() => {
                setProgressbar(false);
              }, 2500);
              const obj = {
                id: FlexibleAdId,
                ad_status: "1",
                _version: foradmin_version,
                Published_Ad_Id: null,
              };

              await API.graphql(
                graphqlOperation(updateAdDetails, { input: obj })
              )
                .then(async (response) => {
                  const createHistoryobj = {
                    ad_status_id: "1",
                    assigned_by_userid: localStorage.getItem("userid"),
                    assigned_to_userid: null,
                    addetailsID: FlexibleAdId,
                  };
                  await API.graphql(
                    graphqlOperation(createAdHistory, {
                      input: createHistoryobj,
                    })
                  ).then(() => {});
                })
                .catch(() => {
                  toast.error("error when save to db");
                  setTimeout(() => {
                    setProgressbar(false);
                  }, 2500);
                });
              // toast.error("Error Publishing Ad");
            });
        } catch (e) {
          console.log(e);
        }
      } else {
      //  alert("hi")
        if (rolecheck === "4") {
          AdHistory = {
            addetailsID: FlexibleAdId, //Ad_History_ID
            ad_status_id: "4",
            assigned_by_userid: localStorage.getItem("userid"),
            assigned_to_userid: null,
          };
        } else {
          AdHistory = {
            addetailsID: FlexibleAdId, //Ad_History_ID
            ad_status_id: selectedState,
            assigned_by_userid: localStorage.getItem("userid"),
            assigned_to_userid: selectedUser,
          };
        }

        const obj = await API.graphql(
          graphqlOperation(createAdHistory, { input: AdHistory })
        );
        const adobj = {
          id: FlexibleAdId,
          ad_status: selectedState,
          _version: Version_Frm_MC,
        };
        await API.graphql(graphqlOperation(updateAdDetails, { input: adobj }));
        console.log(obj.data.createAdHistory, "SaveAdHistory");
        console.log(obj.data.createAdHistory.id);
        // SETADID_FRM_DB(obj.data.createAdDetails.id);
        //  alert(obj.data.createAdDetails.id + " : Adid");
        if (rolecheck !== "4") {
          toast.success("Ad Assigned Successfully");
          setTimeout(() => {
            setProgressbar(false);

          }, 2500);
        }

      }
      setTimeout(() => {
        navigate("/newdashboard");
      }, 1500);
    } catch (err) {
     // alert("hey")
      toast.error("Error while Assigning Ad");
      setTimeout(() => {
        setProgressbar(false);
      }, 2500);
      console.log(err, "Error while Assigning Ad");
    }

    //h
  };
  // const handleCloseadset = () => setShowadset(false);
  // const handleShowadset = () => {
  //   setShowadset(true);
  // };

  //Publishing Ad
  const PublishAd = () => {
    setProgressbar(true);
        //for creative_id
        let Flexible_creative_id=""
        if (
          UPDATED_ADCREATIVE_ID !== undefined &&
          UPDATED_ADCREATIVE_ID !== null &&
          UPDATED_ADCREATIVE_ID != ""
        ) {
          Flexible_creative_id = UPDATED_ADCREATIVE_ID;
        } else if (localStorage.getItem("ExistingAdCreative_Id") !== undefined && localStorage.getItem("ExistingAdCreative_Id")!== null && localStorage.getItem("ExistingAdCreative_Id") !== "" ){
          Flexible_creative_id=localStorage.getItem("ExistingAdCreative_Id");
        } else {
          Flexible_creative_id = AD_ID;
        }

    let adCreatives = {
      creative_id: Flexible_creative_id,
    };
    axios({
      method: "post",
      url:
        "https://graph.facebook.com/v15.0/" +
        adsAccountId +
        "/ads?name=" +
        editAdValues.ad_name +
        "&adset_id=" +
        editAdsetValue.adset_id +
        "&creative=" +
        JSON.stringify(adCreatives) +
        "&status=PAUSED&access_token=" +
        accessToken,

      responseType: "stream",
    })
      .then(function (responsef) {
        console.log(responsef.data.id, "campaign_id");

        set_FbAdIdtoDB(responsef.data.id);
        // setAD_IDtoDB(responsef.data.id);
        // createAdd(responsef.data.id);
        UpdateinExistingAd(responsef.data.id);
        SaveAdHistory();
        toast.success("Ad Published Successfully");
        setTimeout(() => {
          setProgressbar(false);
        }, 2500);
      })
      .catch(function (error) {
        toast.error("Error Publishing Ad");
        setTimeout(() => {
          setProgressbar(false);
        }, 2500);
        console.log(error, "While Publishing");
      });
  };

  //update Ad Details
  const UpdateinExistingAd = async (PublishedFB_Ad_Id) => {
    try {

      let FlexibleAdId=""
      if(foradminad_id!=undefined && foradminad_id != null && foradminad_id != ""){
        FlexibleAdId = foradminad_id;
      }
      else if(editAdValues !=undefined && editAdValues != null && editAdValues != ""){
        FlexibleAdId =editAdValues.id;
      }
      else{
        FlexibleAdId =ADID_FRM_DB
      }


      const AdDetails = {
        id: FlexibleAdId,
        Published_Ad_Id: PublishedFB_Ad_Id,
        ad_status: "4",
        _version: Version_Frm_MC,
      };

      const obj = await API.graphql(
        graphqlOperation(updateAdDetails, { input: AdDetails })
      );
      console.log(obj.data.updateAdDetails, "updateAdDetails");
      console.log(obj.data.updateAdDetails.id);
      // SETADID_FRM_DB(obj.data.createAdDetails.id);
      //  alert(obj.data.createAdDetails.id + " : Adid");
      //  toast.success("Ad Created Successfully");
    } catch (err) {
      // toast.error("Error Saving Ad to DynamoDB");
      console.log(err, "While Updating AdHistory");
    }
  };

  // Get Ad details
  const [ListOfHistory, setListOfHistory] = useState([]);
  const [ListOfFullHistory, setListOfFullHistory] = useState([]);
  const ListOfAdDetails = async (key) => {
    try {
      const getdata = await API.graphql(
        graphqlOperation(getAdDetails, { id: key })
      );
      const ListOfAdDetails = getdata.data.getAdDetails.AdHistories.items;
      console.log("listAdDetails", getdata);
      // get userinfo
      try {
        const ListOfuser = await API.graphql({
          query: listUserInfos,
        });
        const userinfo = ListOfuser.data.listUserInfos.items;
        for (let i = 0; i < ListOfAdDetails.length; i++) {
          const Aduser = ListOfAdDetails[i];
          if (Aduser.assigned_to_userid != null) {
            const username = userinfo.filter(
              (item) => item.id === Aduser.assigned_by_userid
            );
            const user_fname = username[0].user_fname;
            const user_lname = username[0].user_lname;
            Aduser.assigned_by_userid = user_fname + " " + user_lname;
            const usernamevalue = userinfo.filter(
              (item) => item.id === Aduser.assigned_to_userid
            );
            const user_lname1 = usernamevalue[0].user_fname;
            const user_fname1 = usernamevalue[0].user_lname;
            Aduser.assigned_to_userid = user_lname1 + " " + user_fname1;
            console.log("username", user_fname, user_lname);
          } else {
            const username = userinfo.filter(
              (item) => item.id === Aduser.assigned_by_userid
            );
            const user_fname2 = username[0].user_fname;
            const user_lname2 = username[0].user_lname;
            Aduser.assigned_by_userid = user_fname2 + " " + user_lname2;
            console.log("usernamedfsfddsf", username[0].user_fname);
          }
          console.log("Full", ListOfAdDetails);
          setTimeout(() => {
            setListOfFullHistory(
              ListOfAdDetails.sort(function (a, b) {
                return a._lastChangedAt - b._lastChangedAt;
              })
            );
            console.log("ListOfFullHistory", ListOfFullHistory);
          }, 100);
        }
      } catch (err) {
        console.log("error fetching:", err);
      }
    } catch (err) {
      console.log("error fetching:", err);
    }
  };

  const ConnectHome = async () => {
    // navigate("/newdashboard", {
    //   state: { BridgeFunction: ConnectHome },
    // });
  };


  return (
    <>
      <ToastContainer autoClose={1500} />
      <Modal size="xl" show={show} onHide={handleClose} border="none">
        <Modal.Header
          style={{
            border: "none",
            padding: "2em",
            display: "flow-root",
          }}
        >
          <Flex direction={{ base: "column", large: "column", xl: "column" }}>
            <Text fontSize={{ base: "1.3em", large: "1.5em" }} fontWeight="400">
              Ad Format{" "}
              <Icon
                as={FormatAlignLeftOutlinedIcon}
                fontSize={{ base: "large", large: "xl" }}
                color="black"
                marginTop={"-5px"}
                fontWeight={"bold"}
              ></Icon>
            </Text>
            <Flex
              direction={{ base: "column", large: "row" }}
              gap={{ base: "small", large: "xl" }}
              justifyContent={"center"}
            >
              <SelectField
                labelHidden
                icon={<IconArrowDropDown />}
                iconColor="black"
                // placeholder="preview"
                // onChange={(event) => previewWithDevice("preview", event.target.value)}
                width={{ base: "100%", medium: "100%", large: "30%" }}
                value={selectedAdformat}
                onChange={AdFormatChange}
                //</Flex>  selected={selected == "desktop"}
              >
                <option selected> Select Feed Type</option>
                {PREVIEW_FORMAT !== undefined
                  ? PREVIEW_FORMAT.map((row, key) => (
                      <option key={key} value={row?.name}>
                        {row?.name}
                      </option>
                    ))
                  : ""}
              </SelectField>
              <Button
                style={primaryButton}
                borderRadius="1em"
                backgroundColor={"rgb(183, 31, 57)"}
                boxShadow="large"
                marginTop={"0.4em"}
                onClick={AdPreview}
              >
                Show Preview
                <Icon
                  as={VscOpenPreview}
                  fontSize={{ base: "xl", large: "xxl" }}
                  color="white"
                  fontWeight={"bold"}
                  marginLeft="0.2em"
                  marginTop={"0.4em"}
                ></Icon>
              </Button>
            </Flex>
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ padding: "2em", paddingTop: "0em" }}>
          <Flex direction={"column"}>
            <View style={{ height: "unset", width: "100%" }} marginBottom="1em">
              {/* <View padding="2em" alignItems="center" width="100%">
              <Flex
                direction={{ base: "column", large: "row" }}
                gap={{ base: "small", large: "xl" }}
              >
                <Text
                  fontSize={"1.2em"}
                  fontWeight="400"
                  marginTop={{ base: "-4em", medium: "5px", large: "5px" }}
                >
                  Ad Format
                </Text>
                <SelectField
                  labelHidden
                  icon={<IconArrowDropDown />}
                  iconColor="black"
                  // placeholder="preview"
                  // onChange={(event) => previewWithDevice("preview", event.target.value)}
                  width={{ base: "100%", medium: "100%", large: "30%" }}
                  value={selectedAdformat}
                  onChange={AdFormatChange}
                  //</Flex>  selected={selected == "desktop"}
                >
                  <option selected> Select Feed Type</option>
                  {PREVIEW_FORMAT !== undefined
                    ? PREVIEW_FORMAT.map((row, key) => (
                        <option key={key} value={row?.name}>
                          {row?.name}
                        </option>
                      ))
                    : ""}
                </SelectField>

                <Button
                  style={primaryButton}
                  boxShadow="large"
                  onClick={AdPreview}
                >
                  Show Preview
                </Button>
              </Flex>
            </View> */}

              <Card
                style={{
                  backgroundColor: "rgb(213 208 208 / 82%)",
                  margin: "auto",
                  border: "none",
                }}
              >
                {iframe_Response != undefined ? (
                  <Flex justifyContent="center" overflow="hidden">
                    <View
                      width="100%"
                      justifyContent="center"
                      overflow="hidden"
                    >
                      <iframe
                        id="myId"
                        display="block"
                        position="relative"
                        src={iframe_Response}
                        className="iframetag"
                        // style={{ margin: "auto", position: "portional" }}
                        width="100%"
                        height="100%"
                        style={{ height: "510px" }}
                        margin="auto"
                      ></iframe>
                      {/* <Iframe
                      url={iframe_Response}
                      id="myId"
                      className="iframetag"
                      margin="auto"
                      display="block"
                      position="portional"
                      allowFullScreen
                    /> */}

                      {/* {previewresponse != undefined
                ? Parser(previewresponse)
                : "No Preview Available"} */}
                    </View>
                  </Flex>
                ) : (
                  "Select Ad Format"
                )}
              </Card>
            </View>
            {ListOfFullHistory.length > 2 ? (
              <ScrollView width="100%" height="300px" marginBottom={"2em"}>
                <View
                  width="100%"
                  paddingLeft={{
                    base: "0em",
                    small: "0em",
                    medium: "0em",
                    large: "2em",
                    xl: "5em",
                    xxl: "5em",
                  }}
                  paddingRight={{
                    base: "0em",
                    small: "0em",
                    medium: "0em",
                    large: "2em",
                    xl: "5em",
                    xxl: "5em",
                  }}
                  margin="1em 0em"
                >
                  {ListOfFullHistory.map((data, index) => (
                    <section id="status-timeline" class="status-container" ref={messagesEndRef}>
                      {data.ad_status_id == "6" ? ListOfFullHistory[index + 1] ? (
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={RiDraftLine}
                              fontSize={{ base: "large", large: "xxs" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={RiSave3Line}
                                fontSize={{ base: "1em", large: "1.3em" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Draft
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} className="calender"/>
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      ) : <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(149, 4, 149)" }}
                        >
                          <Icon
                            as={RiDraftLine}
                            fontSize={{ base: "large", large: "xxs" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(149, 4, 149)" }}
                          >
                            {" "}
                            <Icon
                              as={RiSave3Line}
                              fontSize={{ base: "1em", large: "1.3em" }}
                              color="#84d851"
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Draft
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} className="calender"/>
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                        : null}
                      {data.ad_status_id == "5" ? ListOfFullHistory[index + 1] ? (
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={RiEditLine}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={EditIcon}
                                fontSize={{ base: "1em", large: "1.3em", color: "rgb(189, 189, 189)" }}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Requires Edits
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      ) : <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(191, 128, 64)" }}
                        >
                          <Icon
                            as={RiEditLine}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(191, 128, 64)" }}
                          >
                            <Icon
                              as={EditIcon}
                              fontSize={{ base: "1em", large: "1.3em" }}
                              color="red"
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Requires Edits
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div> : null}
                      {data.ad_status_id == "2" ? ListOfFullHistory[index + 1] ? ListOfFullHistory[index + 1].ad_status_id == "3" || ListOfFullHistory[index + 1].ad_status_id == "5" ?
                        (<>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={PendingActionsOutlinedIcon}
                                fontSize={{ base: "large", large: "xxl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {data.assigned_by_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(189, 189, 189)" }}
                              >
                                {" "}
                                <Icon
                                  as={MdPending}
                                  fontSize={{ base: "1.5em", large: "1.5em", color: "rgb(189, 189, 189)" }}
                                  fontWeight={"bold"}
                                  className="img"
                                  marginTop={"-0.2em"}
                                ></Icon>{" "}
                                Pending Approval
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                        </>)
                        :
                        (<>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={PendingActionsOutlinedIcon}
                                fontSize={{ base: "large", large: "xxl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {data.assigned_by_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(189, 189, 189)" }}
                              >
                                {" "}
                                <Icon
                                  as={MdPending}
                                  fontSize={{ base: "1.5em", large: "1.5em" }}
                                  fontWeight={"bold"}
                                  className="img"
                                  marginTop={"-0.2em"}
                                ></Icon>{" "}
                                Pending Approval
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={MdOutlinePending}
                                fontSize={{ base: "large", large: "xxl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {data.assigned_to_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(189, 189, 189)" }}
                              >
                                {" "}
                                <Icon
                                  as={GoIssueOpened}
                                  fontSize={{ base: "1.5em", large: "1.8em" }}
                                  marginTop="0.2em"
                                  color="rgb(189, 189, 189)"
                                  marginRight={"-0.3em"}
                                  fontWeight={"bold"}
                                ></Icon>{" "}
                                Yet to Approve
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                        </>)
                        : (<>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={PendingActionsOutlinedIcon}
                                fontSize={{ base: "large", large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {data.assigned_by_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(189, 189, 189)" }}
                              >
                                {" "}
                                <Icon
                                  as={MdPending}
                                  fontSize={{ base: "1.5em", large: "1.5em", color: "rgb(189, 189, 189)" }}
                                  fontWeight={"bold"}
                                  className="img"
                                  marginTop={"-0.2em"}
                                ></Icon>{" "}
                                Pending Approval
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(191, 191, 64)" }}
                            >
                              <Icon
                                as={MdOutlinePending}
                                fontSize={{ base: "large", large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)" }}
                            >
                              <h2 style={{ marginBottom: "0em" }}>
                                {data.assigned_to_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(191, 191, 64)" }}
                              >
                                {" "}
                                <Icon
                                  as={GoIssueOpened}
                                  fontSize={{ base: "1.5em", large: "1.8em" }}
                                  marginTop="0.2em"
                                  color="rgb(191, 64, 64)"
                                  marginRight={"-0.3em"}
                                  fontWeight={"bold"}
                                ></Icon>{" "}
                                Yet to Approve
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                        </>) : null}
                      {data.ad_status_id == "3" ? ListOfFullHistory[index + 1] ? ListOfFullHistory[index + 1].ad_status_id == "4" || ListOfFullHistory[index + 1].ad_status_id == "5"  ? (
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={HowToRegIcon}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                              Approved
                            </span>
                            <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>) : (
                        <>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={HowToRegIcon}
                                fontSize={{ base: "large", large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {data.assigned_by_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(189, 189, 189)" }}
                              >
                                {" "}
                                <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                                Approved
                              </span>
                              <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={MdOutlinePending}
                                fontSize={{ base: "large", large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {" "}
                                {data.assigned_to_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(189, 189, 189)" }}
                              >
                                <Icon
                                  as={GoIssueOpened}
                                  fontSize={{ base: "large", large: "1.8em" }}
                                  marginTop="0.2em"
                                  color="rgb(189, 189, 189)"
                                  marginRight={"-0.2em"}
                                  fontWeight={"bold"}
                                ></Icon>
                                Yet to publish
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                        </>) : (
                        <>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(189, 189, 189)"}}
                            >
                              <Icon
                                as={HowToRegIcon}
                                fontSize={{ base: "large", large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)" }}
                            >
                              <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                                {data.assigned_by_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{  color: "rgb(189, 189, 189)" }}
                              >
                                {" "}
                                <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                                Approved
                              </span>
                              <span class="status-date" style={{ color: "rgb(189, 189, 189)"}}>
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                          <div class="status-timeline-block">
                            <div
                              class="status-timeline-img status-picture"
                              style={{ background: "rgb(191, 191, 64)" }}
                            >
                              <Icon
                                as={MdOutlinePending}
                                fontSize={{ base: "large", large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                className="img"
                              ></Icon>
                            </div>
                            <div
                              class="status-timeline-content"
                              style={{ boxShadow: "var(--amplify-shadows-large)" }}
                            >
                              <h2 style={{ marginBottom: "0em" }}>
                                {" "}
                                {data.assigned_to_userid}
                              </h2>
                              <span
                                class="status-read-more"
                                style={{ color: "rgb(191, 191, 64)" }}
                              >
                                <Icon
                                  as={GoIssueOpened}
                                  fontSize={{ base: "large", large: "1.8em" }}
                                  marginTop="0.2em"
                                  color="rgb(191, 64, 64)"
                                  marginRight={"-0.2em"}
                                  fontWeight={"bold"}
                                ></Icon>
                                Yet to publish
                              </span>
                              <span class="status-date">
                                <CalendarMonthIcon
                                  sx={{ mt: "-0.2em", mr: "0.2em" }}
                                />
                                {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toDateString()
                                  : "-"}, {data._lastChangedAt
                                    ? new Date(data._lastChangedAt).toLocaleTimeString()
                                    : "-"}
                              </span>
                            </div>
                          </div>
                        </>) : null}
                      {data.ad_status_id == "1" ? ListOfFullHistory[index + 1] ? (
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={UnpublishedOutlinedIcon}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={MdCancel}
                                fontSize={{ base: "xl", large: "1.2em", color: "rgb(189, 189, 189)" }}
                                fontWeight={"bold"}
                                marginTop={"-0.1em"}
                              ></Icon>{" "}
                              Rejected
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      ) : <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(191, 64, 64)" }}
                        >
                          <Icon
                            as={UnpublishedOutlinedIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(191, 64, 64)" }}
                          >
                            {" "}
                            <Icon
                              as={MdCancel}
                              fontSize={{ base: "xl", large: "1.2em" }}
                              color="red"
                              fontWeight={"bold"}
                              marginTop={"-0.1em"}
                            ></Icon>{" "}
                            Rejected
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div> : null}
                      {data.ad_status_id == "4" ? ListOfFullHistory[index + 1] ? (
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PublishedWithChangesIcon}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={FcApproval}
                                fontSize={{ base: "xs", large: "xs" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginRight="1.5em"
                                marginTop={"-1.2em"}
                              ></Icon>
                              Published
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      ) : <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(67, 168, 84)" }}
                        >
                          <Icon
                            as={PublishedWithChangesIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(67, 168, 84)" }}
                          >
                            <Icon
                              as={FcApproval}
                              fontSize={{ base: "xl", large: "small" }}
                              color="white"
                              fontWeight={"bold"}
                              marginRight="1.5em"
                              marginTop={"-1.2em"}
                            ></Icon>
                            Published
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div> : null}
                    </section>
                  ))}
                </View>
              </ScrollView>) : (<View
                width="100%"
                paddingLeft={{
                  base: "0em",
                  small: "0em",
                  medium: "0em",
                  large: "2em",
                  xl: "5em",
                  xxl: "5em",
                }}
                paddingRight={{
                  base: "0em",
                  small: "0em",
                  medium: "0em",
                  large: "2em",
                  xl: "5em",
                  xxl: "5em",
                }}
                margin="1em 0em"
              >
                {ListOfFullHistory.map((data, index) => (
                  <section id="status-timeline" class="status-container" ref={messagesEndRef}>
                    {data.ad_status_id == "6" ? ListOfFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={RiDraftLine}
                            fontSize={{ base: "large", large: "xxs" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={RiSave3Line}
                              fontSize={{ base: "1em", large: "1.3em" }}
                              color="rgb(189, 189, 189)"
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Draft
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} className="calender"/>
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(149, 4, 149)" }}
                      >
                        <Icon
                          as={RiDraftLine}
                          fontSize={{ base: "large", large: "xxs" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(149, 4, 149)" }}
                        >
                          {" "}
                          <Icon
                            as={RiSave3Line}
                            fontSize={{ base: "1em", large: "1.3em" }}
                            color="#84d851"
                            fontWeight={"bold"}
                            className="img"
                            marginTop={"-0.2em"}
                          ></Icon>{" "}
                          Draft
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} className="calender"/>
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>
                      : null}
                    {data.ad_status_id == "5" ? ListOfFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={RiEditLine}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={EditIcon}
                              fontSize={{ base: "1em", large: "1.3em", color: "rgb(189, 189, 189)" }}
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Requires Edits
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(191, 128, 64)" }}
                      >
                        <Icon
                          as={RiEditLine}
                          fontSize={{ base: "large", large: "xl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(191, 128, 64)" }}
                        >
                          <Icon
                            as={EditIcon}
                            fontSize={{ base: "1em", large: "1.3em" }}
                            color="red"
                            fontWeight={"bold"}
                            className="img"
                            marginTop={"-0.2em"}
                          ></Icon>{" "}
                          Requires Edits
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div> : null}
                    {data.ad_status_id == "2" ? ListOfFullHistory[index + 1] ? ListOfFullHistory[index + 1].ad_status_id == "3" || ListOfFullHistory[index + 1].ad_status_id == "5"?
                      (<>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PendingActionsOutlinedIcon}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={MdPending}
                                fontSize={{ base: "1.5em", large: "1.5em", color: "rgb(189, 189, 189)" }}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Pending Approval
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>)
                      :
                      (<>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PendingActionsOutlinedIcon}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={MdPending}
                                fontSize={{ base: "1.5em", large: "1.5em" }}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Pending Approval
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "1.5em", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(189, 189, 189)"
                                marginRight={"-0.3em"}
                                fontWeight={"bold"}
                              ></Icon>{" "}
                              Yet to Approve
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>)
                      : (<>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PendingActionsOutlinedIcon}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={MdPending}
                                fontSize={{ base: "1.5em", large: "1.5em", color: "rgb(189, 189, 189)" }}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Pending Approval
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(191, 191, 64)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)" }}
                          >
                            <h2 style={{ marginBottom: "0em" }}>
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(191, 191, 64)" }}
                            >
                              {" "}
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "1.5em", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(191, 64, 64)"
                                marginRight={"-0.3em"}
                                fontWeight={"bold"}
                              ></Icon>{" "}
                              Yet to Approve
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>) : null}
                    {data.ad_status_id == "3" ? ListOfFullHistory[index + 1] ? ListOfFullHistory[index + 1].ad_status_id == "4" || ListOfFullHistory[index + 1].ad_status_id == "5" ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={HowToRegIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                            Approved
                          </span>
                          <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>) : (
                      <>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={HowToRegIcon}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                              Approved
                            </span>
                            <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {" "}
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "large", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(189, 189, 189)"
                                marginRight={"-0.2em"}
                                fontWeight={"bold"}
                              ></Icon>
                              Yet to publish
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>) : (
                      <>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={HowToRegIcon}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                              Approved
                            </span>
                            <span class="status-date" style={{ color: "rgb(189, 189, 189)" }}>
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(191, 191, 64)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)" }}
                          >
                            <h2 style={{ marginBottom: "0em" }}>
                              {" "}
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(191, 191, 64)" }}
                            >
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "large", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(191, 64, 64)"
                                marginRight={"-0.2em"}
                                fontWeight={"bold"}
                              ></Icon>
                              Yet to publish
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>) : null}
                    {data.ad_status_id == "1" ? ListOfFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={UnpublishedOutlinedIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={MdCancel}
                              fontSize={{ base: "xl", large: "1.2em", color: "rgb(189, 189, 189)" }}
                              fontWeight={"bold"}
                              marginTop={"-0.1em"}
                            ></Icon>{" "}
                            Rejected
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(191, 64, 64)" }}
                      >
                        <Icon
                          as={UnpublishedOutlinedIcon}
                          fontSize={{ base: "large", large: "xl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(191, 64, 64)" }}
                        >
                          {" "}
                          <Icon
                            as={MdCancel}
                            fontSize={{ base: "xl", large: "1.2em" }}
                            color="red"
                            fontWeight={"bold"}
                            marginTop={"-0.1em"}
                          ></Icon>{" "}
                          Rejected
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div> : null}
                    {data.ad_status_id == "4" ? ListOfFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={PublishedWithChangesIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={FcApproval}
                              fontSize={{ base: "xs", large: "xs" }}
                              color="rgb(189, 189, 189)"
                              fontWeight={"bold"}
                              marginRight="1.5em"
                              marginTop={"-1.2em"}
                            ></Icon>
                            Published
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(67, 168, 84)" }}
                      >
                        <Icon
                          as={PublishedWithChangesIcon}
                          fontSize={{ base: "large", large: "xxl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(67, 168, 84)" }}
                        >
                          <Icon
                            as={FcApproval}
                            fontSize={{ base: "xl", large: "small" }}
                            color="white"
                            fontWeight={"bold"}
                            marginRight="1.5em"
                            marginTop={"-1.2em"}
                          ></Icon>
                          Published
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div> : null}
                  </section>
                ))}
              </View>)}
            <View width="100%">
              {rolecheck !== "4" && (
                <Flex
                  direction={{
                    base: "column",
                    small: "column",
                    large: "row",
                    xl: "row",
                  }}
                  gap={{ base: "large", large: "xl" }}
                >
                  <Text
                    fontSize={"1em"}
                    fontWeight="400"
                    marginTop={{ base: "0em", medium: "5px", large: "5px" }}
                  >
                    State
                  </Text>
                  <SelectField
                    labelHidden
                    icon={<IconArrowDropDown />}
                    iconColor="black"
                    // placeholder="preview"
                    // onChange={(event) => previewWithDevice("preview", event.target.value)}
                    width={{ base: "100%", medium: "100%", large: "30%" }}
                    value={selectedState}
                    onChange={(e) => {
                      OnAdStateChange(e.target.value);
                    }}
                    disabled={DisableAfterPublish}
                  >
                    {/* <option selected value="Draft">
                Draft
              </option> */}
                    {filterAd !== undefined
                      ? filterAd.map((row, key) => (
                          <option
                            key={key}
                            disabled={row?.disabled}
                            value={row?.status_id}
                          >
                            {row?.status_name}
                          </option>
                        ))
                      : ""}
                  </SelectField>
                  {(selectedState !="4" && selectedState !="1") ?(
                    <>
                  <Text
                    fontSize={"1em"}
                    fontWeight="400"
                    marginTop={{ base: "0em", medium: "5px", large: "5px" }}
                  >
                    Assigned to
                  </Text>
                  <SelectField
                    labelHidden
                    icon={<IconArrowDropDown />}
                    iconColor="black"
                    // placeholder="preview"
                    // onChange={(event) => previewWithDevice("preview", event.target.value)}
                    width={{ base: "100%", medium: "100%", large: "30%" }}
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                    }}
                    disabled={DisableAfterPublish}
                    //</Flex>  selected={selected == "desktop"}
                  >
                    <option selected>Select Users</option>
                    {filteruser !== undefined
                      ? filteruser.map((row, key) => (
                          <option
                            key={key}
                            disabled={row?.disabled}
                            value={row?.id}
                          >
                            {row?.user_fname + " " + row?.user_lname}
                          </option>
                        ))
                      : ""}
                  </SelectField>
                  </>
                ):<></>}
                  <Button
                    style={primaryButton}
                    boxShadow="large"
                    onClick={BridgeFunction}
                    margin="auto"
                    borderRadius={"1em"}
                    isLoading={Progressbar}
                    loadingText="processing..."
                    disabled={DisableAfterPublish}
                  >
                    Submit{" "}
                    <Icon
                      as={BsHandIndexThumb}
                      fontSize={{ base: "xl", large: "xl" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.3em"
                      marginTop={"0.3em"}
                    ></Icon>
                  </Button>
                </Flex>
              )}
              {rolecheck === "4" && (
                <Button
                  style={primaryButton}
                  boxShadow="large"
                  onClick={PublishAd}
                  margin="auto"
                  disabled={DisableAfterPublish}
                  loadingText="Publishing..."
                  isLoading={Progressbar}
                >
                  Publish
                </Button>
              )}
            </View>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AdPreview;
