import {
  Button,
  Grid,
  useBreakpointValue,
  Flex,
  Icon,
  View,
  TabItem,
  Tabs,
  Text,
  TextField,
  SelectField,
  PhoneNumberField,
  Alert,
  Card,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { createCompanyInfo, updateCompanyInfo } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";

import { listCompanyInfos } from "../../graphql/queries";
import DashboardHeader from "../../layout/DashboardHeader";
import "../../App.css";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { useLocation } from "react-router-dom";
// import { duration, Snackbar } from "@mui/material";
// import { SnackbarProvider, wrapComponent } from 'react-snackbar-alert';

const initialState = {
  comp_name: "",
  comp_type: "",
  comp_street: "",
  comp_city: "",
  comp_email: "",
  comp_phone: "",
  comp_state: "",
  comp_zipcode: "",
  comp_timezone: "",
  contact_fname: "",
  contact_lname: "",
  contacy_email: "",
  contact_phone: "",
};

const AccountInfo = ({
  tabindex,
  retreiveCompIdFromAccDashboard,
  backindexx,
  selectedCompanyId,
  updateText,
  currentIndex
}) => {
  localStorage.setItem("initialState", JSON.stringify(initialState));
  const [host_name_Error, sethost_name_Error] = useState(false);
  const [Progressbar, setProgressbar] = useState(false);
  const [streeterror, street_error] = useState(false);
  const [cityerror, city_error] = useState(false);
  const [cfnameerror, cfname_error] = useState(false);
  const [clanmeError, clanme_Error] = useState(false);
  const [cemailError, cemail_Error] = useState("");
  const [cphoneError, cphone_Error] = useState("");
  const [stateerror, state_error] = useState(false);
  const [ziperror, zip_error] = useState(false);
  const [tzoneError, tzone_Error] = useState(false);
  const [ctypeError, ctype_Error] = useState(false);
  const [AccInfoformState, setFormState] = useState(initialState);
  const [clicklistener, set_clicklistener] = useState(0);
  const [version, set_version] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  function setInput(key, value) {
    setFormState({ ...AccInfoformState, [key]: value });

   
    localStorage.setItem("AccInfoformState", JSON.stringify(AccInfoformState));

    // validate();
  }

  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const buttonStyle = {
    backgroundColor: "#639",
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
  };

  const clearStyle = {
    backgroundColor: "#f44336",
    size: "small",
    color: "#fff",
    height: "2rem",
    border: "rgb(244 67 54)",
    float: "center",
  };
  const comHead = {
    width: "101%",
    marginleft: "-1%",
    marginright: "-1%",
    margintop: "-1%",
  };
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const card = {
    width: "80%",
    backgroundColor: "white",
    marginLeft: "11%",
    boxShadow: "0px 4px 9px 0px rgb(0 0 0 / 20%)",
  };
  /////////////////onclickbutton validation////////////////////////
  const validate = (key) => {
    if (AccInfoformState.comp_name == "") {
      sethost_name_Error(true);
    } else {
      sethost_name_Error(false);
    }
    if (AccInfoformState.comp_street == "") {
      street_error(true);
    } else {
      street_error(false);
    }
    if (AccInfoformState.comp_city == "") {
      city_error(true);
    } else {
      city_error(false);
    }
    if (AccInfoformState.comp_state == "") {
      state_error(true);
    } else {
      state_error(false);
    }
    if (AccInfoformState.comp_zipcode == "") {
      zip_error(true);
    } else {
      zip_error(false);
    }
    if (AccInfoformState.contact_fname == "") {
      cfname_error(true);
    } else {
      cfname_error(false);
    }
    if (AccInfoformState.contact_lname == "") {
      clanme_Error(true);
    } else {
      clanme_Error(false);
    }

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        AccInfoformState.contacy_email
      )
    ) {
      cemail_Error("Enter valid email");
    } else {
      cemail_Error("");
    }
    if (AccInfoformState.contacy_email == "") {
      cemail_Error("Enter Email");
    }

    if (AccInfoformState.contact_phone.length < 10) {
      cphone_Error("Enter phone number must be 10 digits");
    } else {
      cphone_Error("");
    }

    if (AccInfoformState.contact_phone == "") {
      cphone_Error("Enter phone number");
    }
    if (AccInfoformState.comp_timezone == "") {
      tzone_Error(true);
    } else {
      tzone_Error(false);
    }
    if (AccInfoformState.comp_type == "") {
      ctype_Error(true);
    } else {
      ctype_Error(false);
    }

    if (
      AccInfoformState.comp_name != "" &&
      AccInfoformState.comp_street != "" &&
      AccInfoformState.comp_city != "" &&
      AccInfoformState.contact_fname != "" &&
      AccInfoformState.contact_lname != "" &&
      AccInfoformState.contacy_email != "" &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        AccInfoformState.contacy_email
      ) &&
      AccInfoformState.contact_phone.length == 10 &&
      AccInfoformState.comp_type != "" &&
      AccInfoformState.comp_timezone != "" &&
      AccInfoformState.contact_phone != "" &&
      AccInfoformState.comp_state != "" &&
      AccInfoformState.comp_zipcode != ""
    ) {
      if ((key == "save" && location.state.key == "create") && (key == "save" && updateText == "d")) {
        SaveAccountInfo();
        // tabindex(1);
      }
      else {
        UpdateAccountInfo();
        // tabindex(1);
      }
    }
  };
  //save account info
  const SaveAccountInfo = async () => {
    //  alert("create");
    setProgressbar(true)
   
    try {
      const saveInfos = {
        comp_name: AccInfoformState.comp_name,
        comp_type: AccInfoformState.comp_type,
        comp_street: AccInfoformState.comp_street,
        comp_city: AccInfoformState.comp_city,
        comp_email: AccInfoformState.comp_email,
        comp_phone: AccInfoformState.comp_phone,
        comp_state: AccInfoformState.comp_state,
        comp_zipcode: AccInfoformState.comp_zipcode,
        isActive: true,
        isDelete: false,
        created_by: "admin",
        updated_by: "admin",
        comp_timezone: AccInfoformState.comp_timezone,
        contact_fname: AccInfoformState.contact_fname,
        contact_lname: AccInfoformState.contact_lname,
        contacy_email: AccInfoformState.contacy_email,
        contact_phone: AccInfoformState.contact_phone,
      };
      const obj = await API.graphql(
        graphqlOperation(createCompanyInfo, { input: saveInfos })
      );
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      toast.success("Account Info has been saved successfully");
      setTimeout(() => {tabindex(parseInt(currentIndex) + 1);
        
      }, 1500);
      

      retreiveCompIdFromAccDashboard(obj.data.createCompanyInfo.id);


    } catch (err) {
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      toast.error("err save");
      console.log(err);
    }
  };
  //Update AccountInfo
  const UpdateAccountInfo = async () => {
    // alert("update");
    setProgressbar(true);
    localStorage.setItem("From", 0);

    try {
      const saveInfos = {
        id: selectedCompanyId,
        //  UserInfos:null,
        comp_name: AccInfoformState.comp_name,
        comp_type: AccInfoformState.comp_type,
        comp_street: AccInfoformState.comp_street,
        comp_city: AccInfoformState.comp_city,
        comp_email: AccInfoformState.comp_email,
        comp_phone: AccInfoformState.comp_phone,
        comp_state: AccInfoformState.comp_state,
        comp_zipcode: AccInfoformState.comp_zipcode,
        isActive: true,
        isDelete: false,
        created_by: "admin",
        updated_by: "admin",
        comp_timezone: AccInfoformState.comp_timezone,
        contact_fname: AccInfoformState.contact_fname,
        contact_lname: AccInfoformState.contact_lname,
        contacy_email: AccInfoformState.contacy_email,
        contact_phone: AccInfoformState.contact_phone,
        _version: version,
      };
      const res = await API.graphql(
        graphqlOperation(updateCompanyInfo, { input: saveInfos })
      );

      retreiveCompIdFromAccDashboard(selectedCompanyId);
      setTimeout(() => { tabindex(parseInt(currentIndex)+1);
        
      }, 1500);
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      toast.success("Account Info has been updated successfully");
     
    } catch (err) {
      toast.error("Error");
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      console.log("error", err);
    }
  };
  //fetch fetchcompanyinfo
  const fetchcompanyinfo = async () => {
    try {
      let filter = {
        id: {
          eq: selectedCompanyId, // filter priority = 1
        },
      };

      const userdata = await API.graphql({
        query: listCompanyInfos,
        variables: { filter: filter },
      });

      set_version(userdata.data.listCompanyInfos.items[0]._version);
      setFormState(userdata.data.listCompanyInfos.items[0]);
    } catch (err) {
      console.log("error fetching actors");
    }
  };
  useEffect(() => {
    
   
    if (location.state.key == "update" || updateText == "Update") {
      fetchcompanyinfo();
    }

    try {
      let data = JSON.parse(localStorage.getItem("AccInfoformState"));

      if (data != null) {
        setFormState(data);
      }
    } catch (err) {
      toast.error("err save");
      console.log(err);
    }
  }, [selectedCompanyId,tabindex]);

  ////////////////////////////////////////////////////////////////////////////

  //validation for inputfields

  const ValidInput = (key, value) => {
    //const comp_nameLength = comp_name.length;

    ///////comp_name/////
    if (key == "comp_name") {
      if (value.length == 0) {
        sethost_name_Error(true);
      } else {
        sethost_name_Error(false);
      }
    }
    //////////////////comp_type/////
    if (key == "comp_type") {
      if (value.length == 0) {
        ctype_Error(true);
      } else {
        ctype_Error(false);
      }
    }
    ///////////////street/////
    if (key == "comp_street") {
      if (value.length == 0) {
        street_error(true);
      } else {
        street_error(false);
      }
    }
    ////timezone//////
    if (key == "comp_timezone") {
      if (value.length == 0) {
        tzone_Error(true);
      } else {
        tzone_Error(false);
      }
    }
    ////////state//////
    if (key == "comp_city") {
      if (value.length == 0) {
        city_error(true);
      } else {
        city_error(false);
      }
    }

    ////////state//////
    if (key == "comp_state") {
      if (value.length == 0) {
        state_error(true);
      } else {
        state_error(false);
      }
    }
    ///////////////////////zipcode/////

    if (key == "comp_zipcode") {
      if (value.length == 0) {
        zip_error(true);
      } else {
        zip_error(false);
      }
    }
    /////fname///////

    if (key == "contact_fname") {
      if (value.length == 0) {
        cfname_error(true);
      } else {
        cfname_error(false);
      }
    }
    /////clname//////
    if (key == "contact_lname") {
      if (value.length == 0) {
        clanme_Error(true);
      } else {
        clanme_Error(false);
      }
    }
    //////email////

    if (key == "contacy_email") {
      const regexp =
        /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const validemail = regexp.test(value);
      if (value.length == 0) {
        cemail_Error(true);
        cemail_Error("Email Id is required.");
      } else if (!validemail) {
        cemail_Error(true);
        cemail_Error("Email Id must be valid");
      } else {
        cemail_Error(false);
      }
    }
    ////phone//////
    if (key == "contact_phone") {
      const phonelength = value.length;
      if (phonelength == 0) {
        cphone_Error(true);
        cphone_Error("Contact Number is required.");
      } else if (phonelength < 10) {
        cphone_Error(true);
        cphone_Error("Contact Number should be 10 digit");
      } else {
        cphone_Error(false);
      }
    }

    setInput(key, value);
  };

  const [count, setCount] = useState(0);
  const [countInTimeout, setCountInTimeout] = useState(0);

  useEffect(() => {
    
    //setCount(10); // Update count to be 5 after timeout is scheduled
  }, []);

  

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />
      <View>
        <Flex direction={"row"} marginTop="2em">
          <View width="100%">
            <Card
              className="cardmain"
              style={card}
              direction={{ base: "column", large: "row" }}
            >
              <View
                className="comHead"
                backgroundColor={"#B71F39"}
                borderRadius={{ base: "xs", large: "small" }}
                fontSize={{ base: "small", large: "medium" }}
                color={"white"}
                padding={{ base: "xs", large: "xs" }}
              >
                Company Info
              </View>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  id="hosp_name_field"
                  onChange={(event) =>
                    ValidInput("comp_name", event.target.value)
                  }
                  onBlur={(event) =>
                    ValidInput("comp_name", event.target.value)
                  }
                  value={AccInfoformState.comp_name}
                  label={<Text>Company Name</Text>}
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={host_name_Error}
                  errorMessage=" Company Name is required"
                ></TextField>

                <SelectField
                  width={{ base: "100%", large: "40%" }}
                  label="Company Type"
                  onChange={(event) =>
                    //Validcomp_type(event.target.value)
                    ValidInput("comp_type", event.target.value)
                  }
                  onBlur={(event) =>
                    //Validcomp_type(event.target.value)
                    ValidInput("comp_type", event.target.value)
                  }
                  value={AccInfoformState.comp_type}
                  isRequired={true}
                  hasError={ctypeError}
                  errorMessage="Select Company Type"
                >
                  <option value="">Select Company Type</option>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Agency">Agency</option>
                  <option value="Brand">Brand</option>
                </SelectField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <SelectField
                  label="Company Time Zone"
                  onChange={(event) =>
                    ValidInput("comp_timezone", event.target.value)
                  }
                  onBlur={(event) =>
                    ValidInput("comp_timezone", event.target.value)
                  }
                  value={AccInfoformState.comp_timezone}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={tzoneError}
                  errorMessage="Select Time Zone"
                >
                  <option value="">Select Time Zone</option>
                  <option value="Central Daylight Time">
                    Central Daylight Time
                  </option>
                  <option value="Chicago (GMT-5)">Chicago (GMT-5)</option>
                  <option value="Mountain Daylight Time">
                    Mountain Daylight Time
                  </option>
                  <option value="Denver (GMT-6)">Denver (GMT-6)</option>
                  <option value="Phoenix (GMT-7)">Phoenix (GMT-7)</option>
                  <option value="Pacific Daylight Time">
                    Pacific Daylight Time
                  </option>
                  <option value="Los Angeles (GMT-7)">
                    Los Angeles (GMT-7)
                  </option>
                  <option value="Alaska Daylight Time">
                    Alaska Daylight Time
                  </option>
                  <option value="Anchorage (GMT-8)">Anchorage (GMT-8)</option>
                  <option value="Hawaii-Aleutian Standard Time">
                    Hawaii-Aleutian Standard Time
                  </option>
                  <option value="Honolulu (GMT-10)">Honolulu (GMT-10)</option>
                </SelectField>

                <TextField
                  onChange={(event) =>
                    ValidInput("comp_street", event.target.value)
                  }
                  onBlur={(event) =>
                    ValidInput("comp_street", event.target.value)
                  }
                  value={AccInfoformState.comp_street}
                  id="hosp_zip_field"
                  label="Street"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={streeterror}
                  errorMessage="Street is required"
                ></TextField>
              </Flex>

              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) =>
                    ValidInput("comp_city", event.target.value)
                  }
                  onBlur={(event) =>
                    ValidInput("comp_city", event.target.value)
                  }
                  value={AccInfoformState.comp_city}
                  id="hosp_zip_field"
                  label="City"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={cityerror}
                  errorMessage="city is required"
                ></TextField>
                <TextField
                  onChange={(event) =>
                    ValidInput(
                      "comp_state",
                      event.target.value.replace(/[0-9]|[^\w\s]/gi, "")
                    )
                  }
                  onBlur={(event) =>
                    ValidInput("comp_state", event.target.value)
                  }
                  value={AccInfoformState.comp_state}
                  id="hosp_zip_field"
                  label="State"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={stateerror}
                  errorMessage="Enter state "
                ></TextField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onPaste={(event) => event.replace(/[aA-zZ]|[^\w\s]/gi, "")}
                  onChange={(event) =>
                    ValidInput(
                      "comp_zipcode",
                      event.target.value
                        .replace(/[^\w\s]|[aA-zZ]/gi, "")
                        .toString()
                        .slice(0, 6)
                    )
                  }
                  onBlur={(event) =>
                    ValidInput("comp_zipcode", event.target.value)
                  }
                  value={AccInfoformState.comp_zipcode}
                  id="hosp_zip_field"
                  label="Zipcode"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={ziperror}
                  errorMessage="Enter Zipcode"
                ></TextField>
                <div></div>
                <div></div>
              </Flex>
              <br></br>

              <View
                className="comHead"
                backgroundColor={"#B71F39"}
                borderRadius={{ base: "xs", large: "small" }}
                fontSize={{ base: "small", large: "medium" }}
                color={"white"}
                padding={{ base: "xs", large: "xs" }}
              >
                Contact Info
              </View>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) =>
                    ValidInput("contact_fname", event.target.value)
                  }
                  onBlur={(event) =>
                    ValidInput("contact_fname", event.target.value)
                  }
                  value={AccInfoformState.contact_fname}
                  id="hosp_zip_field"
                  label="First Name"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={cfnameerror}
                  maxLength="16"
                  errorMessage="Enter First Name"
                ></TextField>
                <TextField
                  onChange={(event) =>
                    ValidInput(
                      "contact_lname",

                      event.target.value
                    )
                  }
                  onBlur={(event) =>
                    ValidInput("contact_lname", event.target.value)
                  }
                  value={AccInfoformState.contact_lname}
                  id="hosp_zip_field"
                  label="Last Name"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={clanmeError}
                  maxLength="16"
                  errorMessage="Enter Last Name"
                ></TextField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) =>
                    ValidInput("contacy_email", event.target.value)
                  }
                  onBlur={(event) =>
                    ValidInput("contacy_email", event.target.value)
                  }
                  value={AccInfoformState.contacy_email}
                  id="hosp_zip_field"
                  label="Email"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={cemailError}
                  errorMessage={cemailError}
                ></TextField>
                <PhoneNumberField
                  onPaste={(event) => event.replace(/[aA-zZ]|[^\w\s]/gi, "")}
                  onChange={(event) =>
                    ValidInput(
                      "contact_phone",
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, "")
                        .toString()
                        .slice(0, 10)
                    )
                  }
                  onBlur={(event) =>
                    ValidInput(
                      "contact_phone",
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, "")
                        .toString()
                        .slice(0, 10)
                    )
                  }
                  value={AccInfoformState.contact_phone}
                  id="hosp_zip_field"
                  label="Phone Number"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%" }}
                  isRequired={true}
                  hasError={cphoneError}
                  errorMessage={cphoneError}
                ></PhoneNumberField>
              </Flex>

              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                
                <Button
                  size="small"
                  backgroundColor={"#212E50"}
                  height={"2.5rem"}
                  boxShadow={"0px 0px 4px rgb(0 0 0 / 25%)"}
                  padding={"2px 1px 2px 10px"}
                  style={buttonStyle}
                  fontSize={{base:"0.7em", large:"0.9em"}}
                  marginLeft={"auto"}
                  setTimeout count={countInTimeout}
                  isLoading={Progressbar}
            loadingText="processing..."
                  onClick={() => {  
                    validate("save");
                  }}
                >
                  
                  Billing Info&nbsp;&nbsp;
                  <Icon
                    as={BsFillArrowRightCircleFill}
                    fontSize={{ base:"large",medium:"xl",large: "xxl" }}
                    marginTop={{ base:"relative.xs", medium:"relative.xs",large: "relative.xxs" }}
                  ></Icon>
                </Button>
              </Flex>
            </Card>
          </View>
        </Flex>
      </View>
    </div>
  );
};
export default AccountInfo;
