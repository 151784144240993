import {
  Button,
  Grid,
  useBreakpointValue,
  Flex,
  Icon,
  View,
  TabItem,
  Tabs,
  Text,
  TextField,
  SelectField,
  PhoneNumberField,
  Alert,
  Card,
} from "@aws-amplify/ui-react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCompanyInfo,
  updateCompanyInfo,
  deleteCompanyInfo,
} from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useEffect, useState } from "react";

import { listCompanyInfos } from "../../graphql/queries";
import DashboardHeader from "../../layout/DashboardHeader";
import "../../App.css";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { manageaccuontdatagrid } from "../../assets/style";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const Manageaccount = ({
  tabindex,
  retreiveCompIdFromAccDashboard,
  setupdateText,
  currentIndex,
  seteditid,
}) => {
  const [pagelimit, setpagelimit] = useState(5);

  const edit = (id) => {
    retreiveCompIdFromAccDashboard(id);
    seteditid(id);
   
    tabindex(parseInt(currentIndex) + 1);
    setupdateText("Update");
  };
  const [companylist, setcompanylist] = useState();

  const columns: GridColDef[] = [
    {
      field: "comp_name",
      headerName: "Name",
      //width: 320,
      maxWidth:250,
      flex: 2,
      editable: false,
    },
    {
      field: "contacy_email",
      headerName: "Email",
      flex: 1,
      width: 100,
      editable: false,
    },
    {
      field: "contact_phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    // {
    //   field: "role_id",
    //   headerName: "Role",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   minWidth: 100,
    //   editable: false,
    //   renderCell: (params) => {
    //     if (params.row.UserInfos) {
    //       console.log(params.row.UserInfos, "paramx");
    //       if (params.row.UserInfos.items.length > 0) {
    //         let filter = params.row.UserInfos.items.filter(
    //           (item) => item.role_id == 1
    //         );
    //         console.log(
    //           params.row.UserInfos.items.filter((item) => item.role_id == 1),
    //           "role"
    //         );
    //         if (
    //           filter[0].role_id != undefined &&
    //           filter[0].role_id != null &&
    //           filter[0].role_id != ""
    //         ) {
    //           var role = "Super Admin";
    //           var color = "success";
    //         }
    //       }
    //     }

    //     return (
    //       <div>
    //         <Flex width={"100%"} alignItems="center" justifyContent={"center"}>
    //           <Badge badgeContent={role} color={color}></Badge>
    //         </Flex>
    //       </div>
    //     );
    //   },
    // },
   // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   flex: 1,
    //   minWidth: 100,
    //   align: "center",
    //   headerAlign: "center",
    //   editable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Tooltip title="Edit">
    //           <Flex width={"100%"}>
    //             <BorderColorIcon
    //               as={BiEdit}
    //               sx={{ color: "Green", fontSize: "1.5em", cursor: "pointer" }}
    //               onClick={() => edit(params.id)}
    //             ></BorderColorIcon>
    //           </Flex>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Actions",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => {
        return (
          <div>
              <Flex width={"100%"} justifyContent="center">
              <Tooltip BorderColorIcon  title="Edit">
              <View width="50%">
              <BorderColorIcon
                  as={BiEdit}
                  sx={{ color: "Green", fontSize: "1.5em", cursor: "pointer" }}
                  onClick={() => edit(params.id)}
                  cursor={"pointer"}
                ></BorderColorIcon>
                </View> 
                </Tooltip>
              <Tooltip BorderColorIcon  title="Delete">
              <View width="50%">
                <DeleteForeverIcon
                  as={MdDelete}
                  onClick={() => Delete(params)}
                  sx={{ color: "red", fontSize: "1.5em", cursor: "pointer" }}
                  cursor={"pointer"}
                />
                </View> 
               </Tooltip>
              </Flex>  
          </div>
        );
      },
    },
  ];
  //Fetching the fulllist from the listAdddetails model
  const listcompany = async () => {
    if (localStorage.getItem("role_id") == 6) {
      let filter = {
        and: {
          id: { eq: localStorage.getItem("companyinfoID") },
          isDelete: { eq: false },
        },
      };
      const userInfo = await API.graphql({
        query: listCompanyInfos,

        variables: { filter: filter },
      });
      let camp = userInfo.data.listCompanyInfos.items;
      setcompanylist(
        camp.filter((item) => (item.UserInfos.items.length>0)&&(item._deleted !== true || item._deleted == null))//(item.UserInfos.items.length>0)&&
      );
      
    } else {
      try {
        let filter = {
          isDelete: { eq: false },
        };
        const addlist = await API.graphql({
          query: listCompanyInfos,
          variables: { filter: filter },
        });
       
        let camp = addlist.data.listCompanyInfos.items;
        let tempArr = [];
        let tempJSON = {};
       
        setcompanylist(
          camp.filter((item) =>(item.UserInfos.items.length>0)&&( item._deleted !== true || item._deleted == null))
        );
        
      } catch (err) {
        console.log("error fetching actors" + err);
      }
    }
  };
  const Delete = async (params) => {
    const obj = {
      id: params.row.id,
      _version: params.row._version,
    };
   
    try {
      API.graphql(graphqlOperation(deleteCompanyInfo, { input: obj }))
        .then(() => {
        
          toast.success("Company Deleted Successfully");
          listcompany();
        })
        .catch(() => {
          toast.warning("Failed to Delete Company");
        });
    } catch {}
  };

  useEffect(() => {
    listcompany();
  }, []);
  /////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <View height="400px" marginLeft={"10%"} marginRight={"10%"}>
        <DataGrid
          width="100%"
          headerHeight={40}
          rowHeight={40}
          sx={manageaccuontdatagrid}
          columns={columns}
          rows={companylist !== undefined ? companylist : []}
          pageSize={pagelimit}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newpagesize) => {
            setpagelimit(newpagesize);
          }}
          pagination={true}
        />
      </View>
    </div>
  );
};
export default Manageaccount;
