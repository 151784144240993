import {
  Flex,
  View,
  useTheme,
  Heading,
  SearchField,
  Collection,
  Button,
  ThemeProvider,
  Icon,
} from "@aws-amplify/ui-react";
import { viewCompList, createButtonTheme } from "../../assets/style";
import { New, Company, CompanyCollection } from "../../ui-components";
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listCompanyInfos } from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { VscListSelection } from "react-icons/vsc";
import { MdOutlinePersonAddAlt1 } from "react-icons/md";
const CompaniesList = () => {
  const { tokens } = useTheme();
  const [companyList, setCompanyList] = useState();
  const [tabid, settabid] = useState();
  const [role, set_role] = useState(localStorage.getItem("role_id"));
  const [create_manage_acccount, setcreate_manage_acccount] = useState(
    localStorage.getItem("create_manage_acccount")
  );

  const navigate = useNavigate();
  const addCompAction = async () => {
    localStorage.removeItem("AccInfoformState");
    localStorage.removeItem("BillInfoformState");
    localStorage.removeItem("UformState");
   
      // settabid(1);
      navigate("/account", {
        state: { From: "dashboard", tabindex: 1, key: "create" },
      });


  };

  useEffect(() => {
    if (
      localStorage.getItem("role_id") == 1 ||
      localStorage.getItem("role_id") == 3
    ) {
      settabid(1);
      console.log("$$$$", tabid);
    }
    getCompanyInfo();
    // fetchCompanyList();
  }, [tabid]);
  const fetchCompanyList = async () => {
    try {
      console.log("$$$$");
      let compData = await API.graphql(graphqlOperation(listCompanyInfos));
      let comp = compData.data.listCompanyInfos.items;
      console.log(JSON.stringify(comp));
    } catch (err) {
      console.log("error fetching monitor apps", err);
    }
  };

  const companyclick = (id, name) => {
    localStorage.setItem("distinct", true);
    localStorage.setItem("companyinfoID", id);

    navigate("/newdashboard", { state: { comp_ame: name } });
  };
  //role_id
  const [items, setitems] = useState();
  const getCompanyInfo = async () => {
    try {
      let query = {};
      const role_id = localStorage.getItem("role_id");
      if (role_id === "1" || role_id == "3") {
        let filter = {
          isDelete: { eq: false },
        };
        query = {
          query: listCompanyInfos,
          variables: { filter: filter },
        };
      }
      if (
        role_id === "5" ||
        role_id === "4" ||
        role_id === "2" ||
        role_id === "6"
      ) {
        let filter = {
          and: {
            id: { eq: localStorage.getItem("companyinfoID") },
            isDelete: { eq: false },
          },
        };
        query = {
          query: listCompanyInfos,
          variables: { filter: filter },
        };
      }

      const list = await API.graphql(query);
      console.log("Listofimage: ", list.data.listCompanyInfos.items);//.filter(item=>item.UserInfos.length>0)
     
      setitems(
        list.data.listCompanyInfos.items
          .filter((item) =>(item.UserInfos.items.length>0)&&(item._deleted !== true || item._deleted == null))
          .sort(function (a, b) {
            return a._lastChangedAt - b._lastChangedAt;
          })
      );
    } catch (err) {
      console.log("error fetching actors", err);
    }
  };
  return (
    <div>
      <Flex
        direction="row"
        alignItems={"center"}
        margin="auto"
        verticalAlign="center"
      >
        <View
          style={viewCompList}
          width="100%"
          margin={{ base: "1em", medium: "0.5em", large: "2em" }}
          // backgroundColor={tokens.colors.neutral[40]}
          borderRadius="1em"
          boxShadow="large"
          height="31.7rem"
        >
          <Flex
            margin={{ base: "large", medium: "xl", large: "small" }}
            gap={{ base: "0em", large: "relative.xxxl" }}
            padding={{ base: "0.2em", medium: "1em", large: "1em" }}
          >
            <Heading
              level={4}
              color={"#000"}
              fontStyle={"normal"}
              fontSize={"large"}
              fontWeight={"normal"}
            >
              List of Accounts{" "}
              <Icon
                as={VscListSelection}
                fontSize={{ base: "xl", large: "xl" }}
                color="black"
                fontWeight={"bold"}
                marginLeft="0.2em"
                marginTop={"0.4em"}
              ></Icon>
            </Heading>
            {/* <SearchField
             size="small" margin={"auto"}></SearchField> */}
          </Flex>
          <Flex direction={"column"} alignItems={"center"}>
            <ThemeProvider theme={createButtonTheme}>
            {(create_manage_acccount == "true" && role!=6) &&  (
                <Button
                  variation="primary"
                  backgroundColor={"#b71f39"}
                  paddingLeft={{ base: "xs", large: "xxxl" }}
                  paddingRight={{ base: "xs", large: "xxxl" }}
                  onClick={addCompAction}
                >
                  <Icon
                    as={MdOutlinePersonAddAlt1}
                    fontSize={{ base: "large", large: "large" }}
                    color="white"
                    fontWeight={"bold"}
                    marginRight="0.2em"
                    marginTop={"0em"}
                  ></Icon>{" "}
                  Create Account
                </Button>
              )}
            </ThemeProvider>
            <View
              paddingLeft={{ base: "0em", large: "2em" }}
              paddingRight={{ base: "0em", large: "2em" }}
              paddingTop="0em"
              paddingBottom={{ base: "1em", large: "1em" }}
            >
              <Collection
                type="list"
                isSearchable={true}
                isPaginated={true}
                searchPlaceholder="Search..."
                direction="column"
                justifyContent="left"
                items={items}
                itemsPerPage={3}
                className="dashboard"
                alignItems="center"
                padding="1em"
              >
                {(item, index) => (
                  <Flex
                    gap="0"
                    direction="row"
                    width="100%"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    position="relative"
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    borderRadius="4px"
                    padding="0px 0px 0px 0px"
                  >
                    <Button
                      height="60px"
                      grow="1"
                      shrink="1"
                      basis="0"
                      backgroundColor="rgba(255,255,255,1)"
                      size="default"
                      isDisabled={false}
                      variation="link"
                      onClick={() => {
                        companyclick(item.id, item.comp_name);
                      }}
                      children={item.comp_name}
                    ></Button>
                  </Flex>
                )}
              </Collection>
            </View>
          </Flex>
        </View>
      </Flex>
    </div>
  );
};
export default CompaniesList;
