/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPostHistory = /* GraphQL */ `
  mutation CreatePostHistory(
    $input: CreatePostHistoryInput!
    $condition: ModelPostHistoryConditionInput
  ) {
    createPostHistory(input: $input, condition: $condition) {
      id
      post_status_id
      assigned_by_userid
      assigned_to_userid
      postdetailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePostHistory = /* GraphQL */ `
  mutation UpdatePostHistory(
    $input: UpdatePostHistoryInput!
    $condition: ModelPostHistoryConditionInput
  ) {
    updatePostHistory(input: $input, condition: $condition) {
      id
      post_status_id
      assigned_by_userid
      assigned_to_userid
      postdetailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePostHistory = /* GraphQL */ `
  mutation DeletePostHistory(
    $input: DeletePostHistoryInput!
    $condition: ModelPostHistoryConditionInput
  ) {
    deletePostHistory(input: $input, condition: $condition) {
      id
      post_status_id
      assigned_by_userid
      assigned_to_userid
      postdetailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAdHistory = /* GraphQL */ `
  mutation CreateAdHistory(
    $input: CreateAdHistoryInput!
    $condition: ModelAdHistoryConditionInput
  ) {
    createAdHistory(input: $input, condition: $condition) {
      id
      addetailsID
      ad_status_id
      assigned_by_userid
      assigned_to_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdHistory = /* GraphQL */ `
  mutation UpdateAdHistory(
    $input: UpdateAdHistoryInput!
    $condition: ModelAdHistoryConditionInput
  ) {
    updateAdHistory(input: $input, condition: $condition) {
      id
      addetailsID
      ad_status_id
      assigned_by_userid
      assigned_to_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdHistory = /* GraphQL */ `
  mutation DeleteAdHistory(
    $input: DeleteAdHistoryInput!
    $condition: ModelAdHistoryConditionInput
  ) {
    deleteAdHistory(input: $input, condition: $condition) {
      id
      addetailsID
      ad_status_id
      assigned_by_userid
      assigned_to_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRolesModules = /* GraphQL */ `
  mutation CreateRolesModules(
    $input: CreateRolesModulesInput!
    $condition: ModelRolesModulesConditionInput
  ) {
    createRolesModules(input: $input, condition: $condition) {
      id
      role_name
      create_manage_acccount
      create_manage_users
      manage_social_account
      landing_page
      dashboard
      content_library
      manage_ads
      manage_posts
      role_id
      isActive
      isDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRolesModules = /* GraphQL */ `
  mutation UpdateRolesModules(
    $input: UpdateRolesModulesInput!
    $condition: ModelRolesModulesConditionInput
  ) {
    updateRolesModules(input: $input, condition: $condition) {
      id
      role_name
      create_manage_acccount
      create_manage_users
      manage_social_account
      landing_page
      dashboard
      content_library
      manage_ads
      manage_posts
      role_id
      isActive
      isDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRolesModules = /* GraphQL */ `
  mutation DeleteRolesModules(
    $input: DeleteRolesModulesInput!
    $condition: ModelRolesModulesConditionInput
  ) {
    deleteRolesModules(input: $input, condition: $condition) {
      id
      role_name
      create_manage_acccount
      create_manage_users
      manage_social_account
      landing_page
      dashboard
      content_library
      manage_ads
      manage_posts
      role_id
      isActive
      isDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAdStatus = /* GraphQL */ `
  mutation CreateAdStatus(
    $input: CreateAdStatusInput!
    $condition: ModelAdStatusConditionInput
  ) {
    createAdStatus(input: $input, condition: $condition) {
      id
      status_name
      isActive
      status_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdStatus = /* GraphQL */ `
  mutation UpdateAdStatus(
    $input: UpdateAdStatusInput!
    $condition: ModelAdStatusConditionInput
  ) {
    updateAdStatus(input: $input, condition: $condition) {
      id
      status_name
      isActive
      status_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdStatus = /* GraphQL */ `
  mutation DeleteAdStatus(
    $input: DeleteAdStatusInput!
    $condition: ModelAdStatusConditionInput
  ) {
    deleteAdStatus(input: $input, condition: $condition) {
      id
      status_name
      isActive
      status_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPostDetails = /* GraphQL */ `
  mutation CreatePostDetails(
    $input: CreatePostDetailsInput!
    $condition: ModelPostDetailsConditionInput
  ) {
    createPostDetails(input: $input, condition: $condition) {
      id
      post_message
      post_schedule_timestamp
      page_id
      user_id
      post_status
      isActive
      isDelete
      post_type
      post_channels
      media_hash_id
      fb_post_id
      insta_container_id
      companyinfoID
      PostHistories {
        nextToken
        startedAt
      }
      fb_post_msg
      insta_post_msg
      fb_image_url
      insta_image_url
      insta_post_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePostDetails = /* GraphQL */ `
  mutation UpdatePostDetails(
    $input: UpdatePostDetailsInput!
    $condition: ModelPostDetailsConditionInput
  ) {
    updatePostDetails(input: $input, condition: $condition) {
      id
      post_message
      post_schedule_timestamp
      page_id
      user_id
      post_status
      isActive
      isDelete
      post_type
      post_channels
      media_hash_id
      fb_post_id
      insta_container_id
      companyinfoID
      PostHistories {
        nextToken
        startedAt
      }
      fb_post_msg
      insta_post_msg
      fb_image_url
      insta_image_url
      insta_post_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePostDetails = /* GraphQL */ `
  mutation DeletePostDetails(
    $input: DeletePostDetailsInput!
    $condition: ModelPostDetailsConditionInput
  ) {
    deletePostDetails(input: $input, condition: $condition) {
      id
      post_message
      post_schedule_timestamp
      page_id
      user_id
      post_status
      isActive
      isDelete
      post_type
      post_channels
      media_hash_id
      fb_post_id
      insta_container_id
      companyinfoID
      PostHistories {
        nextToken
        startedAt
      }
      fb_post_msg
      insta_post_msg
      fb_image_url
      insta_image_url
      insta_post_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAdDetails = /* GraphQL */ `
  mutation CreateAdDetails(
    $input: CreateAdDetailsInput!
    $condition: ModelAdDetailsConditionInput
  ) {
    createAdDetails(input: $input, condition: $condition) {
      id
      ad_name
      ad_primary_text
      media_type
      media_file_name
      media_file_path
      ad_headline
      ad_description
      ad_website_url
      ad_see_more_url
      ad_see_more_display
      call_to_action
      created_by
      updated_by
      ad_campaign_id
      ad_account_id
      user_id
      isActive
      IsDelete
      ad_status
      fb_ad_id
      insta_selected_account
      insta_selected_id
      adset_Id
      AdHistories {
        nextToken
        startedAt
      }
      Published_Ad_Id
      Media_Hash_Id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdDetails = /* GraphQL */ `
  mutation UpdateAdDetails(
    $input: UpdateAdDetailsInput!
    $condition: ModelAdDetailsConditionInput
  ) {
    updateAdDetails(input: $input, condition: $condition) {
      id
      ad_name
      ad_primary_text
      media_type
      media_file_name
      media_file_path
      ad_headline
      ad_description
      ad_website_url
      ad_see_more_url
      ad_see_more_display
      call_to_action
      created_by
      updated_by
      ad_campaign_id
      ad_account_id
      user_id
      isActive
      IsDelete
      ad_status
      fb_ad_id
      insta_selected_account
      insta_selected_id
      adset_Id
      AdHistories {
        nextToken
        startedAt
      }
      Published_Ad_Id
      Media_Hash_Id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdDetails = /* GraphQL */ `
  mutation DeleteAdDetails(
    $input: DeleteAdDetailsInput!
    $condition: ModelAdDetailsConditionInput
  ) {
    deleteAdDetails(input: $input, condition: $condition) {
      id
      ad_name
      ad_primary_text
      media_type
      media_file_name
      media_file_path
      ad_headline
      ad_description
      ad_website_url
      ad_see_more_url
      ad_see_more_display
      call_to_action
      created_by
      updated_by
      ad_campaign_id
      ad_account_id
      user_id
      isActive
      IsDelete
      ad_status
      fb_ad_id
      insta_selected_account
      insta_selected_id
      adset_Id
      AdHistories {
        nextToken
        startedAt
      }
      Published_Ad_Id
      Media_Hash_Id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAdSet = /* GraphQL */ `
  mutation CreateAdSet(
    $input: CreateAdSetInput!
    $condition: ModelAdSetConditionInput
  ) {
    createAdSet(input: $input, condition: $condition) {
      id
      adset_id
      budget_type
      budget_amount
      schedule_start_date
      schedule_start_time
      schedule_end_date
      schedule_end_time
      conversion_location
      conversion_events
      placements
      custom_audience
      exclude_custom_audience
      age_from
      age_to
      ads_account_id
      gender
      demographics
      interests
      behaviors
      location
      created_by
      updated_by
      language
      adset_status
      isActive
      isDelete
      campaign_id
      AdDetails {
        nextToken
        startedAt
      }
      adset_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdSet = /* GraphQL */ `
  mutation UpdateAdSet(
    $input: UpdateAdSetInput!
    $condition: ModelAdSetConditionInput
  ) {
    updateAdSet(input: $input, condition: $condition) {
      id
      adset_id
      budget_type
      budget_amount
      schedule_start_date
      schedule_start_time
      schedule_end_date
      schedule_end_time
      conversion_location
      conversion_events
      placements
      custom_audience
      exclude_custom_audience
      age_from
      age_to
      ads_account_id
      gender
      demographics
      interests
      behaviors
      location
      created_by
      updated_by
      language
      adset_status
      isActive
      isDelete
      campaign_id
      AdDetails {
        nextToken
        startedAt
      }
      adset_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdSet = /* GraphQL */ `
  mutation DeleteAdSet(
    $input: DeleteAdSetInput!
    $condition: ModelAdSetConditionInput
  ) {
    deleteAdSet(input: $input, condition: $condition) {
      id
      adset_id
      budget_type
      budget_amount
      schedule_start_date
      schedule_start_time
      schedule_end_date
      schedule_end_time
      conversion_location
      conversion_events
      placements
      custom_audience
      exclude_custom_audience
      age_from
      age_to
      ads_account_id
      gender
      demographics
      interests
      behaviors
      location
      created_by
      updated_by
      language
      adset_status
      isActive
      isDelete
      campaign_id
      AdDetails {
        nextToken
        startedAt
      }
      adset_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAdCampaign = /* GraphQL */ `
  mutation CreateAdCampaign(
    $input: CreateAdCampaignInput!
    $condition: ModelAdCampaignConditionInput
  ) {
    createAdCampaign(input: $input, condition: $condition) {
      id
      ads_account_id
      campaign_name
      fb_campaign_id
      special_ad_category
      objective
      created_by
      updated_by
      campaign_status
      isActive
      isDelete
      AdSets {
        nextToken
        startedAt
      }
      companyinfoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdCampaign = /* GraphQL */ `
  mutation UpdateAdCampaign(
    $input: UpdateAdCampaignInput!
    $condition: ModelAdCampaignConditionInput
  ) {
    updateAdCampaign(input: $input, condition: $condition) {
      id
      ads_account_id
      campaign_name
      fb_campaign_id
      special_ad_category
      objective
      created_by
      updated_by
      campaign_status
      isActive
      isDelete
      AdSets {
        nextToken
        startedAt
      }
      companyinfoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdCampaign = /* GraphQL */ `
  mutation DeleteAdCampaign(
    $input: DeleteAdCampaignInput!
    $condition: ModelAdCampaignConditionInput
  ) {
    deleteAdCampaign(input: $input, condition: $condition) {
      id
      ads_account_id
      campaign_name
      fb_campaign_id
      special_ad_category
      objective
      created_by
      updated_by
      campaign_status
      isActive
      isDelete
      AdSets {
        nextToken
        startedAt
      }
      companyinfoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createConfigItems = /* GraphQL */ `
  mutation CreateConfigItems(
    $input: CreateConfigItemsInput!
    $condition: ModelConfigItemsConditionInput
  ) {
    createConfigItems(input: $input, condition: $condition) {
      id
      campaign_objective
      campaign_categories
      adset_budget
      conversion_location
      conversion_event
      placemen
      age_from_and_to
      preview_format
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateConfigItems = /* GraphQL */ `
  mutation UpdateConfigItems(
    $input: UpdateConfigItemsInput!
    $condition: ModelConfigItemsConditionInput
  ) {
    updateConfigItems(input: $input, condition: $condition) {
      id
      campaign_objective
      campaign_categories
      adset_budget
      conversion_location
      conversion_event
      placemen
      age_from_and_to
      preview_format
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteConfigItems = /* GraphQL */ `
  mutation DeleteConfigItems(
    $input: DeleteConfigItemsInput!
    $condition: ModelConfigItemsConditionInput
  ) {
    deleteConfigItems(input: $input, condition: $condition) {
      id
      campaign_objective
      campaign_categories
      adset_budget
      conversion_location
      conversion_event
      placemen
      age_from_and_to
      preview_format
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContentLibrary = /* GraphQL */ `
  mutation CreateContentLibrary(
    $input: CreateContentLibraryInput!
    $condition: ModelContentLibraryConditionInput
  ) {
    createContentLibrary(input: $input, condition: $condition) {
      id
      media_type
      media_url
      media_name
      media_width
      userid
      media_height
      media_size
      media_desc
      isActive
      isDelete
      uploaded_by
      companyinfoID
      default
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContentLibrary = /* GraphQL */ `
  mutation UpdateContentLibrary(
    $input: UpdateContentLibraryInput!
    $condition: ModelContentLibraryConditionInput
  ) {
    updateContentLibrary(input: $input, condition: $condition) {
      id
      media_type
      media_url
      media_name
      media_width
      media_height
      media_size
      media_desc
      isActive
      isDelete
      uploaded_by
      companyinfoID
      default
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContentLibrary = /* GraphQL */ `
  mutation DeleteContentLibrary(
    $input: DeleteContentLibraryInput!
    $condition: ModelContentLibraryConditionInput
  ) {
    deleteContentLibrary(input: $input, condition: $condition) {
      id
      media_type
      media_url
      media_name
      media_width
      media_height
      media_size
      media_desc
      isActive
      isDelete
      uploaded_by
      companyinfoID
      default
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFBLoginInfo = /* GraphQL */ `
  mutation CreateFBLoginInfo(
    $input: CreateFBLoginInfoInput!
    $condition: ModelFBLoginInfoConditionInput
  ) {
    createFBLoginInfo(input: $input, condition: $condition) {
      id
      user_id
      facebook_userid
      pagename
      pageid
      long_access_token
      ads_accountname
      ads_accountid
      facebook_username
      company_id
      instagram_connected
      insta_business_id
      instagram_page_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFBLoginInfo = /* GraphQL */ `
  mutation UpdateFBLoginInfo(
    $input: UpdateFBLoginInfoInput!
    $condition: ModelFBLoginInfoConditionInput
  ) {
    updateFBLoginInfo(input: $input, condition: $condition) {
      id
      user_id
      facebook_userid
      pagename
      pageid
      long_access_token
      ads_accountname
      ads_accountid
      facebook_username
      company_id
      instagram_connected
      insta_business_id
      instagram_page_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFBLoginInfo = /* GraphQL */ `
  mutation DeleteFBLoginInfo(
    $input: DeleteFBLoginInfoInput!
    $condition: ModelFBLoginInfoConditionInput
  ) {
    deleteFBLoginInfo(input: $input, condition: $condition) {
      id
      user_id
      facebook_userid
      pagename
      pageid
      long_access_token
      ads_accountname
      ads_accountid
      facebook_username
      company_id
      instagram_connected
      insta_business_id
      instagram_page_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      user_fname
      user_lname
      user_email
      user_phone
      user_password
      isActive
      isDelete
      created_by
      updated_by
      companyinfoID
      role_id
      cognito_identity_id
      multiple_account
      multiple_accounts_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      user_fname
      user_lname
      user_email
      user_phone
      user_password
      isActive
      isDelete
      created_by
      updated_by
      companyinfoID
      role_id
      cognito_identity_id
      multiple_account
      multiple_accounts_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      user_fname
      user_lname
      user_email
      user_phone
      user_password
      isActive
      isDelete
      created_by
      updated_by
      companyinfoID
      role_id
      cognito_identity_id
      multiple_account
      multiple_accounts_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCompanyInfo = /* GraphQL */ `
  mutation CreateCompanyInfo(
    $input: CreateCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    createCompanyInfo(input: $input, condition: $condition) {
      id
      comp_name
      comp_street
      comp_city
      comp_email
      comp_phone
      comp_state
      comp_zipcode
      isActive
      isDelete
      created_by
      updated_by
      comp_timezone
      contact_fname
      contact_lname
      contacy_email
      contact_phone
      UserInfos {
        nextToken
        startedAt
      }
      comp_type
      card_number
      card_holder_name
      security_code
      expiry_month
      expiry_year
      billing_address
      city
      state
      zipcode
      ContentLibraries {
        nextToken
        startedAt
      }
      AdCampaigns {
        nextToken
        startedAt
      }
      PostDetails {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCompanyInfo = /* GraphQL */ `
  mutation UpdateCompanyInfo(
    $input: UpdateCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    updateCompanyInfo(input: $input, condition: $condition) {
      id
      comp_name
      comp_street
      comp_city
      comp_email
      comp_phone
      comp_state
      comp_zipcode
      isActive
      isDelete
      created_by
      updated_by
      comp_timezone
      contact_fname
      contact_lname
      contacy_email
      contact_phone
      UserInfos {
        nextToken
        startedAt
      }
      comp_type
      card_number
      card_holder_name
      security_code
      expiry_month
      expiry_year
      billing_address
      city
      state
      zipcode
      ContentLibraries {
        nextToken
        startedAt
      }
      AdCampaigns {
        nextToken
        startedAt
      }
      PostDetails {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCompanyInfo = /* GraphQL */ `
  mutation DeleteCompanyInfo(
    $input: DeleteCompanyInfoInput!
    $condition: ModelCompanyInfoConditionInput
  ) {
    deleteCompanyInfo(input: $input, condition: $condition) {
      id
      comp_name
      comp_street
      comp_city
      comp_email
      comp_phone
      comp_state
      comp_zipcode
      isActive
      isDelete
      created_by
      updated_by
      comp_timezone
      contact_fname
      contact_lname
      contacy_email
      contact_phone
      UserInfos {
        nextToken
        startedAt
      }
      comp_type
      card_number
      card_holder_name
      security_code
      expiry_month
      expiry_year
      billing_address
      city
      state
      zipcode
      ContentLibraries {
        nextToken
        startedAt
      }
      AdCampaigns {
        nextToken
        startedAt
      }
      PostDetails {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
