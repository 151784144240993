import {
  Button,
  Grid,
  useBreakpointValue,
  Flex,
  View,
  TabItem,
  Tabs,
  Text,
  TextField,
  Icon,
  Card,
  ScrollView,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ThemeProvider,
  PasswordField,
  SelectField,
  PhoneNumberField,
  Theme,
} from "@aws-amplify/ui-react";
import Badge from "@mui/material/Badge";
import "react-toastify/dist/ReactToastify.css";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { BsFillForwardFill, BsPencilSquare, BsTrash } from "react-icons/bs";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {  } from '../graphql/queries'
import {
  createUserInfo,
  deleteUserInfo,
  updateUserInfo,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import DashboardHeader from "../../layout/DashboardHeader";
import { useRef } from "react";
import {
  listRolesModules,
  listCompanyInfos,
  listUserInfos,
  getCompanyInfo,
} from "../../graphql/queries";
import { BsHandIndexThumb } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Multiselect from "multiselect-react-dropdown";
import Link from "@mui/material/Link";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { usersettingdatagridSx } from "../.././assets/style";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { Edit, SentimentDissatisfied } from "@mui/icons-material";
const Settings = () => {
  function setInput() {}
  let initialState = {
    user_fname: "",
    user_lname: "",
    user_email: "",
    user_phone: "",
    user_password: "",
    cnfrm_password: "",
    role_id: "",
    companyinfoID: "",
    multiple_accounts_id: [],
    multiple_account: false,
  };
  let { id } = useParams();

  let data = [];
  const [array, setarray] = useState([]);
  // const [compname, setcompname]=useState(false);
  const [Fname, setFname] = useState(false);
  const [index, setIndex] = useState(0);
  const [Lname, setLname] = useState(false);
  const [Email, setEmail] = useState(false);
  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  const [options, setoption] = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
  ];
  const [savedUserId, setSavedUserId] = useState("");
  const [version, set_version] = useState(0);
  const [companyid, setcompanyid] = useState(
    localStorage.getItem("companyinfoID")
  );
  const [updatecompanyid, setupdatecompanyid] = useState();
  const [disable, setdisable] = useState();
  const [Phone, setPhone] = useState(false);
  const [updatevariable, setupdatevariable] = useState(false);
  const [showupdate, setshowupdate] = useState(false);
  const [listofroles, ListOfRoles] = useState([]);
  const [Password, setPassword] = useState(false);
  const [CnfrmPassword, setCnfrmPassword] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [pagelimit, setpagelimit] = useState(5);
  const [Role, setRole] = useState(false);
  const [check_role, setcheck_role] = useState(
    localStorage.getItem("role_id") == 6 ? true : false
  );
  const [EditId, setEditId] = useState();
  const [UformState, setUformState] = useState(initialState);
  let excludeCustomAudienceRef = useRef();
  const [Accounterror, setAccounterror] = useState(false);


  /// bind comfirm pasword
 
  const [bindconfirmpasword, setbindconfirmpasword]=useState();
 


  function setInput(key, value) {
    setUformState({ ...UformState, [key]: value });
    // console.log(UformState, "UformState");
  }
  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const buttonStyle = {
    backgroundColor: "rgb(107 42 149)",
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
  };

  const clearStyle = {
    backgroundColor: "#f44336",
    size: "small",
    color: "#fff",
    height: "2rem",
    border: "rgb(244 67 54)",
    float: "center",
  };
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const card = {
    width: "80%",
    boxShadow: "0px 4px 9px 0px rgb(0 0 0 / 20%)",
    marginLeft: "10%",
  };
  const Tablehead = {
    backgroundColor: "#b71f39",
    color: "#FFFFFF",
  };
  const Tablebody = {
    color: "white",
  };
  const renderEditButton = () => {
    return (
      <div>
        <Flex width={"100%"}>
          <BorderColorIcon
            as={BiEdit}
            sx={{ color: "Green", fontSize: "1.5em" }}
          ></BorderColorIcon>{" "}
        </Flex>
      </div>
    );
  };
  const renderDeleteButton = () => {
    return (
      <div>
        <Flex width={"100%"} justifyContent="center">
          <DeleteForeverIcon
            as={MdDelete}
            sx={{ color: "red", fontSize: "1.5em" }}
          />
        </Flex>
      </div>
    );
  };
  const renderRoleBadge = () => {
    return (
      <div>
        {rows.map((file, index) => (
          <Flex width={"100%"} alignItems="center" justifyContent={"center"}>
            <Badge badgeContent={file.role_id} color="secondary"></Badge>
          </Flex>
        ))}
      </div>
    );
  };

  const rows = [
    {
      id: "1",
      Name: "Anu",
      Email: "@MUI",
      Phone: 20,
      role: "Admin",
    },
    {
      id: "2",
      Name: "Anu",
      Email: "@MUI",
      Phone: 20,
      role: "User",
    },
    {
      id: "3",
      Name: "Anu",
      Email: "@MUI",
      Phone: 20,
      role: "User",
    },
    {
      id: "4",
      Name: "Anu",
      Email: "@MUI",
      Phone: 20,
      role: "User",
    },
    {
      id: "5",
      Name: "Anu",
      Email: "@MUI",
      Phone: 20,
      role: "User",
    },
  ];
  const edit = async (params) => {
    setIndex(1);

    setupdatevariable(true);

    console.log(account, "edittemp");

    try {
      let filter = {
        id: {
          eq: params.row.id, // filter priority = 1
        },
      };

      const userInfo = await API.graphql({
        query: listUserInfos,

        variables: { limit: 10000, filter: filter },
      });


      ///bindcomfirmpasword
      setbindconfirmpasword(userInfo.data.listUserInfos.items[0].user_password);
      console.log(userInfo.data.listUserInfos.items[0].user_password,"userInfo")
      
      let filterforcompany = {
        id: { eq: params.row.companyinfoID },
      };

      await API.graphql({
        query: listCompanyInfos,
        variables: { limit: 20000, filter: filterforcompany },
      })
        .then((y) => {
          console.log(y.data.listCompanyInfos.items[0].UserInfos.items.filter(), "swathicomp");
        
          // setselectedAccount(y.data.listCompanyInfos.items[0])
        })
        .catch(() => {
          //  alert("wrong");
        });

      console.log(userInfo.data.listUserInfos.items[0], "datatta");

      setUformState(userInfo.data.listUserInfos.items[0]);

      set_version(userInfo.data.listUserInfos.items[0]._version);

      setSavedUserId(userInfo.data.listUserInfos.items[0].id);

      setEditId(params.row.companyinfoID);

      setupdatecompanyid(params.row.companyinfoID);

      UformState.companyinfoID = params.row.companyinfoID;

      let d = [...account];

      // console.log(data,"edittemp")

      // console.log(
      //   d.filter((item) => item.id == params.row.companyinfoID, "filter")
      // );

      console.log(d, params.row.multiple_accounts_id, "muthupandi");

      if (
        params.row.multiple_accounts_id != null &&
        params.row.multiple_accounts_id.length != 0 &&
        params.row.multiple_accounts_id != undefined &&
        params.row.multiple_accounts_id.length > 0
      ) {
        let tempJSON = {};
        for (let i = 0; i < params.row.multiple_accounts_id.length; i++) {
          // data.push(
          tempJSON = {
            ...tempJSON,
            ...d.filter(
              (item) => item.id == params.row.multiple_accounts_id[i]
            )[0],
          };
          //   d.filter((item) => item.id == params.row.multiple_accounts_id[i])
          // );

          data.push(tempJSON);
          tempJSON = [];
          // console.log(data, "data")
        }

        setselectedAccount(data);
        UformState.multiple_accounts_id = data;
        console.log(data, "dataswathi");
      } else {
        // setselectedAccount(
        //   d.filter((item) => item.id == params.row.companyinfoID)
        // );
        // UformState.multiple_accounts_id =   d.filter((item) => item.id == params.row.companyinfoID);
        setselectedAccount = [];
        UformState.multiple_accounts_id = [];
      }

      // console.log(data, "data");
    } catch (err) {
      console.log(err);
    }
  };

  const Delete = async (params) => {
    const obj = {
      id: params.row.id,
      _version: params.row._version,
    };
    // console.log(obj, "Devices");
    try {
      API.graphql(graphqlOperation(deleteUserInfo, { input: obj }))
        .then(() => {
          listUserInfo();
          toast.success("Deleted Successfully");
        })
        .catch(() => {
          toast.warning("Deleted Failed");
        });
    } catch {}
  };

  const [companylist, setcompanylist] = useState();
  const [account, setaccount] = useState();
  const [selectedAccount, setselectedAccount] = useState([]);
  const columns: GridColDef[] = [
    {
      field: "user_fname",
      headerName: "Name",
      // minWidth: 250,
      maxWidth: 250,
      flex: 1,
      editable: false,
    },
    {
      field: "user_email",
      headerName: "Email",
      flex: 1,
      //minWidth: 300,
      maxWidth: 300,
      editable: false,
    },
    {
      field: "user_phone",
      headerName: "Phone",
      flex: 1,
      //minWidth: 250,
      maxWidth: 150,
      editable: false,
    },
    {
      field: "role_id",
      headerName: "Role",
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      editable: false,
      align: "center",
      renderCell: (params) => {
        console.log(params, "params");
        if (params.row.role_id == 1) {
          var role = "Super Admin";
          var color = "success";
        }
        if (params.row.role_id == 2) {
          var role = "Content Curator";
          var color = "primary";
        }
        if (params.row.role_id == 3) {
          var role = "Manager";
          var color = "secondary";
        }
        if (params.row.role_id == 4) {
          var role = "Publisher";
          var color = "success";
        }
        if (params.row.role_id == 5) {
          var role = "Approver";
          var color = "warning";
        }
        if (params.row.role_id == 6) {
          var role = "Admin";
          var color = "error";
        }
        return (
          <div>
            <Flex width={"100%"} alignItems="center" justifyContent={"center"}>
              <Badge badgeContent={role} color={color}></Badge>
            </Flex>
          </div>
        );
      },
    },
    // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   minWidth: 100,

    //   align: "center",
    //   editable: false,

    //   renderCell: (params) => {
    //     console.log(params, "params");
    //     return (
    //       <div>
    //         <Flex width={"100%"}>
    //           <BorderColorIcon
    //             as={BiEdit}
    //             sx={{ color: "Green", fontSize: "1.5em" }}
    //             onClick={() => edit(params)}
    //           ></BorderColorIcon>
    //         </Flex>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Actions",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      align: "center",
      editable: false,
      renderCell: (params) => {
        return (
          <div>
            <Flex width={"100%"} justifyContent="center">
              <Tooltip title="Edit">
                <View width="50%">
                  <BorderColorIcon
                    as={BiEdit}
                    sx={{ color: "Green", fontSize: "1.5em" }}
                    onClick={() => edit(params)}
                    cursor={"pointer"}
                  ></BorderColorIcon>
                </View>
              </Tooltip>

              <Tooltip title="Delete">
                <View width="50%">
                  <DeleteForeverIcon
                    as={MdDelete}
                    onClick={() => Delete(params)}
                    sx={{ color: "red", fontSize: "1.5em" }}
                    cursor={"pointer"}
                  />
                </View>
              </Tooltip>
            </Flex>
          </div>
        );
      },
    },
  ];
  const theme: Theme = {
    name: "table-theme",
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: "{colors.blue.20}" },
            },

            striped: {
              backgroundColor: { value: "{colors.blue.10}" },
            },
          },

          header: {
            color: { value: "{colors.blue.80}" },
            fontSize: { value: "{fontSizes.medium}" },
          },

          data: {
            fontSize: { value: "{fontSizes.small}" },
          },
        },
      },
    },
  };
  //search by account
  const searchByAccount = async (value) => {
    try {
      let filter = {
        id: {
          eq: value, // filter priority = 1
        },
      };
      const userInfo = await API.graphql({
        query: listCompanyInfos,

        variables: { filter: filter },
      });

      setcompanylist(userInfo.data.listCompanyInfos.items[0].UserInfos.items);
    } catch (err) {
      console.log(err);
    }
  };
  //create user search
  const usersearchaccountdropdown = async (value) => {
    setcompanyid(value);
    setupdatecompanyid(value);

    UformState.companyinfoID = value;
  };
  //Fetching the fulllist from the listUserInfos model
  const listUserInfo = async () => {
    if (localStorage.getItem("role_id") == 6) {
      let filter = {
        and: {
          id: { eq: localStorage.getItem("companyinfoID") },
          isDelete: { eq: false },
        },
      };
      const userInfo = await API.graphql({
        query: listCompanyInfos,

        variables: { limit: 10000, filter: filter },
      });

      setcompanylist(
        userInfo.data.listCompanyInfos.items[0].UserInfos.items.filter(
          (item) => item._deleted !== true || item._deleted == null
        )
      );
    } else {
      try {
        let filter = {
          isDelete: { eq: false },
        };
        const listUserInfo = await API.graphql({
          query: listUserInfos,
          variables: { limit: 10000, filter: filter },
        });

        let camplist = listUserInfo.data.listUserInfos.items;
        console.log(
          camplist.filter(
            (item) => item._deleted !== true || item._deleted == null
          ),
          "camplist"
        );
        setcompanylist(
          camplist.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
      } catch (err) {
        console.log("error fetching actors" + err);
      }
    }
  };

  //Fetching the fulllist from the listAdddetails model
  const listcompany = async () => {
    try {
      let filter = {
        isDelete: { eq: false },
      };
      const addlist = await API.graphql({
        query: listCompanyInfos,
        variables: { filter: filter },
      });

      let camp = addlist.data.listCompanyInfos.items;
      setaccount(camp);

      if (companylist != null) {
        console.log("companylist", companylist);
        // setShowad(true);
      }
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };

  //saveuserinfo to the model
  const saveUserinfo = async () => {
    // console.log("hi", UformState);
    try {
      const { cognitouser } = await Auth.signUp({
        username: UformState.user_email,
        password: UformState.user_password,
        attributes: {
          email: UformState.user_email,
          //phone: UformState.phone,
        },
      });
      console.log(cognitouser, "signup");

      try {
        const saveInfos = {
          // id: id,
          companyinfoID: companyid,
          multiple_accounts_id: UformState.multiple_accounts_id,
          multiple_account: UformState.multiple_account,
          user_fname: UformState.user_fname,
          user_lname: UformState.user_lname,
          user_email: UformState.user_email,
          user_phone: UformState.user_phone,
          user_password: UformState.user_password,

          role_id: UformState.role_id,
          isActive: true,
          isDelete: false,
        };
        //  console.log(saveInfos, "saveInfos");

        let modeldata = await API.graphql(
          graphqlOperation(createUserInfo, {
            input: saveInfos,
          })
        );

        // console.log("IDD " + modeldata.data.createUserInfo.id);
        console.log(modeldata, "modeldata");
        listUserInfo();
        toast.success("Successfully Added");
        UformState.multiple_accounts_id = [];
        setIndex(0);
      } catch (err) {
        setIndex(1);
        toast.error(err.message);
        console.log(err);
      }
    } catch (error) {
      setIndex(1);
      toast.error(error.message);
      console.log("error signing up:", error);
    }
  };

  //bridge function
  const saveAction = async () => {
    if (updatevariable == true) {
      updateUserinfo();
    } else {
      saveUserinfo();
    }

    //saving user info to the model
  };
  //fetchrole
  const fetchrole = async () => {
    try {
      const userdata = await API.graphql(graphqlOperation(listRolesModules));
      console.log(userdata.data.listRolesModules.items, "role");
      if (localStorage.getItem("role_id") == 1) {
        ListOfRoles(userdata.data.listRolesModules.items);
      } else {
        ListOfRoles(
          userdata.data.listRolesModules.items.filter(
            (item) => item.role_id !== 1 && item.role_id !== 3
          )
        );
      }
    } catch (err) {
      console.log("error fetching actors");
    }
  };
  //update userinfo
  const updateUserinfo = async () => {
    try {
      console.log("  $$$$$$$savedUserId");
      //console.log(savedUserId, "  $$$$$$$savedUserId");
      const updateInfos = {
        id: savedUserId,
        companyinfoID: updatecompanyid,
        multiple_accounts_id: UformState.multiple_accounts_id,
        multiple_account: UformState.multiple_account,
        user_fname: UformState.user_fname,
        user_lname: UformState.user_lname,
        user_email: UformState.user_email,
        user_phone: UformState.user_phone,
        user_password: UformState.user_password,

        role_id: UformState.role_id,
        isActive: true,
        isDelete: false,
        _version: version,
      };
      //  console.log(saveInfos, "saveInfos");

      let modeldata = await API.graphql(
        graphqlOperation(updateUserInfo, {
          input: updateInfos,
        })
      );

      toast.success("User Info has been updated successfully");
      UformState.multiple_accounts_id = [];
      setIndex(0);
      // console.log("IDD " + modeldata.data.createUserInfo.id);
      console.log(modeldata, "modeldata");
      // ---------------------------------------------------------------------------------------cognitouserupdate-----------------------------------------------------------------------------------------------
      //   try {
      //     // alert("hi");
      //     const { cognitouser } = await Auth.signUp({
      //       username: UformState.email,
      //       password: UformState.password,
      //       attributes: {
      //         email: UformState.email,
      //         //phone: UformState.phone,
      //       },
      //     });
      //     toast.success("Info has been saved successfully");
      //     tabindex(3);
      //     console.log(cognitouser, "signup");
      //   } catch (error) {
      //     toast.error(error.message);
      //     console.log("error signing up:", error);
      //   }
      // ----------------------------------------------------------------------------------------------end--------------------------------------------------------------------------------------------------------
    } catch (err) {
      setIndex(1);
      toast.error(err.message);
      console.log(err);
    }
  };
  //page rendering
  useEffect(() => {
    if (
      localStorage.getItem("role_id") != 6 &&
      localStorage.getItem("role_id") != 1
    ) {
      setdisable(true);
    }

    fetchrole();
    listUserInfo();
    listcompany();
  }, [index, selectedAccount]);

  //form validations
  const validation = () => {
    if (
      UformState.companyinfoID == "" &&
      localStorage.getItem("role_id") != 6
    ) {
      setAccounterror(true);
      setAccounterror("please select Required Account ");
    } else {
      setAccounterror(false);
    }

    if (
      UformState.companyinfoID == "" &&
      localStorage.getItem("role_id") == 6
    ) {
      setAccounterror(false);
    }

    if (UformState.user_fname == "") {
      setFname(true);
      setFname("Required!");
    } else {
      setFname(false);
    }

    if (UformState.user_lname == "") {
      setLname(true);
      setLname("Required!");
    } else {
      setLname(false);
    }

    if (UformState.user_email == "") {
      setEmail(true);
      setEmail("Email Required!");
    } else {
      setEmail(false);
    }

    if (UformState.user_phone == "") {
      setPhone(true);
      setPhone("ContactNumber is Required!");
    } else {
      setPhone(false);
    }

    if (UformState.user_password == "") {
      setPassword(true);
      setPassword("Password Required!");
    } else {
      setPassword(false);
    }

    // if (UformState.cnfrm_password == "") {
    //   if (UformState.cnfrm_password == "") {
    //     setCnfrmPassword("pasword is required!");
    //   }
    //   if (
    //     UformState.user_password != UformState.cnfrm_password ||
    //     UformState.user_password != UformState.user_password
    //   ) {
    //     setCnfrmPassword("pasword does not match!");
    //   }
    // } else {
    //   setCnfrmPassword(false);
    // }

   ///CONFIRM PASWORD VALIDATION

    if(UformState.cnfrm_password == "" ||bindconfirmpasword=="")
    {
       setCnfrmPassword(true);
       setCnfrmPassword("confirm password is required");
    }
     if(UformState.user_password != (UformState.cnfrm_password ||bindconfirmpasword))
    {
      setCnfrmPassword(true);
       setCnfrmPassword("Password Must be Same ");

    }else{
       setCnfrmPassword(false);
    }

    if (UformState.role_id == 0) {
      setRole(true);
      setRole("Please Select Role");
    } else {
      setRole(false);
    }

    if (
      UformState.user_fname != "" &&
      UformState.user_lname != "" &&
      UformState.user_email != "" &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        UformState.user_email
      ) &&
      UformState.user_phone != "" &&
      UformState.user_phone.length == 10 &&
      UformState.user_password != "" &&
      UformState.user_password ==
        (UformState.cnfrm_password || bindconfirmpasword) &&
      UformState.role_id != ""
    ) {
      setFname(false);
      setLname(false);
      setEmail(false);
      setPhone(false);
      setPassword(false);
      setCnfrmPassword(false);
      setRole(false);
      saveAction();
    }
  };
  ///validate input field

  const validinputfield = (key, value) => {
    if (key == "companyinfoID") {
      if (value == 0 && localStorage.getItem("role_id") != 6) {
        setAccounterror(true);
        setAccounterror("please select Required Account ");
      } else {
        setAccounterror(false);
      }
      if (localStorage.getItem("role_id") == 6) {
        if (value == "" || value != "") {
          setAccounterror(false);
        }
      }
    }

    if (key == "user_fname") {
      if (value.length == 0) {
        setFname(true);
      } else {
        setFname(false);
      }
    }

    if (key == "user_lname") {
      if (value.length == 0) {
        setLname(true);
      } else {
        setLname(false);
      }
    }

    if (key == "user_email") {
      const exp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        );
      if (value.length == 0) {
        setEmail(true);
        setEmail("Email is Required!");
      } else if (!exp) {
        setEmail(true);
        setEmail("Email Id must be valid");
      } else {
        setEmail(false);
      }
    }

    if (key == "user_phone") {
      if (value.length == 0) {
        setPhone(true);
        setPhone("Contact Number is required.");
      } else if (value.length < 10 && value.length > 10) {
        setPhone(true);
        setPhone("Contact Number should be 10 digit");
      } else {
        setPhone(false);
      }
    }

    if (key == "user_password") {
      const uppercasePassword = /(?=.*?[A-Z])/.test(value);
      const minLengthPassword = /.{8,}/.test(value);
      const lowercaseRegExp = /(?=.*?[a-z])/.test(value);
      const digitsPassword = /(?=.*?[0-9])/.test(value);
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/.test(value);
      //onst spcial_digit=/(?=.*[\d$@.$!%*#?&])/.test(value);

      if (value == "") {
        setPassword("Password is required.");
      } else if (!uppercasePassword) {
        setPassword("At least one Uppercase");
      } else if (!lowercaseRegExp) {
        setPassword("At least one Lowercase");
      } else if (!digitsPassword) {
        setPassword("At least one  digit");
      } else if (!specialCharRegExp) {
        setPassword("At least one  specialcharacter");
      } else if (!minLengthPassword) {
        setPassword("At least minumum 8 characters");
      } else {
        setPassword(false);
      }
    }

    if (key == "cnfrm_password") {
      const valpas = UformState.user_password;
      const valcon = UformState.cnfrm_password;

      if (value.length == 0) {
        setCnfrmPassword("Confirm Password is required");
      } else if (valpas !== valcon) {
        setCnfrmPassword("password must be same");
      } else {
        setCnfrmPassword(false);
      }
    }
    if (key == "role_id") {
      if (value == 0) {
        setRole(true);
        setRole("Please select Role");
      } else {
        setRole(false);
      }
    }



     if(key=="user_password")
      {

      if(value!=(UformState.cnfrm_password||bindconfirmpasword))
        {
         setCnfrmPassword(true);
         setCnfrmPassword("Password Must Be Same");
          }else{
         setCnfrmPassword(false);
         }

       }

     //// while fetch or update confirm password///
     if(key=="bind_password") 
     {
       setbindconfirmpasword(value)


       if(bindconfirmpasword=="")
        {
        setCnfrmPassword(true);
        setCnfrmPassword("confirm password is required");
        }
      if(bindconfirmpasword!=UformState.user_password )
        {
         setCnfrmPassword(true);
         setCnfrmPassword("Password Must Be Same");

         }else{
           setCnfrmPassword(false);
        }
  
     }





    setInput(key, value);
  };

  //for update v
  const validatefalse = () => {
    setFname(false);
    setLname(false);
    setEmail(false);
    setPhone(false);
    setPassword(false);
    setCnfrmPassword(false);
    setRole(false);
  };
  const onSelect = (selectedList, selectedItem) => {
    // console.log(selectedList, selectedItem.id);
    //array.push(selectedItem.id);
    //console.log("array", array);
    UformState.multiple_accounts_id.push(selectedItem.id);
    if (UformState.multiple_accounts_id.length > 1) {
      UformState.multiple_account = true;
    }
    console.log(
      "UformState.multiple_accounts_id",
      UformState.multiple_accounts_id
    );
    // = [ ...UformState.multiple_accounts_id , ...array];
  };
  const onRemove = (selectedList, removedItem) => {
    console.log(selectedList, removedItem);

    UformState.multiple_accounts_id.map((y, index) => {
      if (y == removedItem.id) {
        UformState.multiple_accounts_id.splice(index, 1);
      }

      console.log(
        "UformState.multiple_accounts_id",

        UformState.multiple_accounts_id
      );
    });
  };
  const fun = () => {
    UformState.multiple_accounts_id = [];
  };
  return (
    <div>
      <ToastContainer autoClose={3000} />
      <DashboardHeader
        db={false}
        mc={false}
        cl={false}
        mp={false}
        ndb={false}
      ></DashboardHeader>
      <Flex marginLeft={"10%"} color={"black"} fontWeight={"bold"}>
        <View width="100%" marginTop="small" marginBottom="large">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="hover"
              color="rgb(183, 31, 57)"
              fontSize={"1em"}
              sx={{ mt: "10px" }}
              href="/Settings"
            >
              <SettingsOutlinedIcon
                sx={{ mr: "0.2em", mt: "-3px", fontSize: "1.2em" }}
              />
              Settings
            </Link>
            <Link
              underline="hover"
              color="rgb(183, 31, 57)"
              fontSize={"1em"}
              href="/Settings"
            >
              <ManageAccountsOutlinedIcon
                style={{
                  marginRight: "0.1em",
                  marginTop: "-5px",
                  fontSize: "1.4em",
                }}
              />{" "}
              User Settings
            </Link>
          </Breadcrumbs>
          {/* <Text width={{base:"70%", small:"50%", medium:"30%",large:"20%"}}  style={{ borderBottom: "3px solid #b71f39" }} fontSize="1.5em">User</Text> */}
        </View>
      </Flex>

      <Tabs
        justifyContent="left"
        marginLeft="10%"
        width="80%"
        currentIndex={index}
        //onChange={(i: number) => setIndex(i)}
      >
        <TabItem
          title="Manage User"
          onClick={() => {
            setIndex(0);
            setUformState(initialState);
            setEditId(0);
            setupdatevariable(false);
            validatefalse();
            setselectedAccount([]);
            setarray([]);
            fun();
          }}
        >
          {/* <ThemeProvider theme={theme} colorMode="light"> */}
          {/* <View columnSpan={[1, 1, 1, 2]} style={shadowProp}></View> */}

          <Flex
            direction="column"
            marginTop="1em"
            marginLeft={"10%"}
            marginRight={"10%"}
            gap="1em"
          >
            {(localStorage.getItem("role_id") === "1" ||
              localStorage.getItem("role_id") === "3") && (
              <View
                justifyContent={{ base: "center", large: "right" }}
                marginLeft="auto"
              >
                <SelectField
                  width={{ base: "100%", large: "100%" }}
                  // label="Select By Account"
                  onChange={(event) => searchByAccount(event.target.value)}
                >
                  <option value="0" isdisabled>
                    Select By Account
                  </option>
                  {account !== undefined
                    ? account.map((row, key) => (
                        <option key={key} value={row?.id}>
                          {row?.comp_name}
                        </option>
                      ))
                    : ""}
                </SelectField>
              </View>
            )}
            <View height="400px" width={{ base: "100%", large: " 100%" }}>
              <DataGrid
                width="100%"
                headerHeight={40}
                rowHeight={40}
                sx={usersettingdatagridSx}
                columns={columns}
                rows={companylist !== undefined ? companylist : []}
                pageSize={pagelimit}
                rowsPerPageOptions={[5, 10, 20]}
                onPageSizeChange={(newpagesize) => {
                  setpagelimit(newpagesize);
                }}
                pagination={true}
              />
              {/* <ScrollView>
                  <Table
                    highlightOnHover
                    variation="striped"
                    boxShadow="0px 4px 9px 0px rgb(0 0 0 / 20%)"
                    width="80%"
                    marginLeft="10%"
                  >
                    <TableHead>
                      <TableRow style={Tablehead}>
                        <TableCell style={Tablebody} as="th">
                          Name
                        </TableCell>
                        <TableCell style={Tablebody} as="th">
                          Email
                        </TableCell>
                        <TableCell style={Tablebody} as="th">
                          Phone
                        </TableCell>
                        <TableCell style={Tablebody} as="th">
                          Role
                        </TableCell>
                        <TableCell style={Tablebody} as="th"></TableCell>
                        <TableCell style={Tablebody} as="th"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Anu</TableCell>
                        <TableCell>anu@jetzerp.com</TableCell>
                        <TableCell>1236789909</TableCell>
                        <TableCell>
                          <Button>Editor</Button>
                        </TableCell>
                        <TableCell>
                          <Icon as={BsPencilSquare} fontSize={{ large: "xl" }} color="green"/>
                        </TableCell>
                        <TableCell>
                          <Icon as={BsTrash} fontSize={{ large: "xl" }}  color="red"/>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Anu</TableCell>
                        <TableCell>anu@jetzerp.com</TableCell>
                        <TableCell>1236789909</TableCell>
                        <TableCell>
                          <Button>Editor</Button>
                        </TableCell>
                        <TableCell>
                          <Icon as={BsPencilSquare} fontSize={{ large: "xl" }} color="green"/>
                        </TableCell>
                        <TableCell>
                          <Icon as={BsTrash} fontSize={{ large: "xl" }} color="red"/>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ScrollView> */}
              {/* </ThemeProvider> */}
            </View>
          </Flex>
        </TabItem>

        <TabItem
          title="Create User"
          onClick={() => {
            setIndex(1);
            setUformState(initialState);
            setEditId(0);
            setupdatevariable(false);
            setselectedAccount([]);
            setarray([]);
            fun();
          }}
        >
          {/* <View columnSpan={[1, 1, 1, 2]} style={shadowProp}></View> */}
          <View>
            <Card
              className="cardmain"
              style={card}
              direction={{ base: "column", large: "row" }}
            >
              <View
                className="comHead"
                backgroundColor={"#b71f39"}
                borderRadius={{ base: "xs", large: "small" }}
                fontSize={{ base: "small", large: "medium" }}
                color={"white"}
                padding={{ base: "xs", large: "xs" }}
              >
                User Info
              </View>

              <Flex
                direction={{ base: "column", large: "column" }}
                padding={{ base: "small", large: "1em" }}
              >
                <View width={{ base: "100%", large: "100%" }}>
                  <Text
                    style={{
                      fontWeight: "300",
                    }}
                  >
                    Account
                  </Text>
                </View>

                <View width={{ base: "100%", large: " 100%" }}>
                  <Multiselect
                    selectedValues={selectedAccount}
                    label="Account"
                    style={{ color: "black" }}
                    options={
                      account !== undefined && account !== null ? account : []
                    }
                    displayValue="comp_name"
                    ref={excludeCustomAudienceRef}
                    showCheckbox={true}
                    closeOnSelect={true}
                    avoidHighlightFirstOption={true}
                    showArrow={true}
                    disable={disable}
                    singleSelect={false}
                    placeholder="Select Accounts"
                    hidePlaceholder={true}
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove}
                    selectionLimit="-1"
                    emptyRecordMsg="No options available"
                    disablePreSelectedValues={false}
                    disabled={check_role}
                  />
                </View>
              </Flex>

              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  id="hosp_name_field"
                  onChange={(event) =>
                    validinputfield(
                      "user_fname",
                      event.target.value.slice(0, 16)
                    )
                  }
                  onBlur={(event) =>
                    validinputfield(
                      "user_fname",
                      event.target.value.slice(0, 16)
                    )
                  }
                  hasError={Fname}
                  value={UformState.user_fname}
                  errorMessage="Required"
                  label="First Name"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "50%" }}
                  autoComplete="new-password"
                ></TextField>
                <TextField
                  id="hosp_add_field"
                  onChange={(event) =>
                    validinputfield(
                      "user_lname",
                      event.target.value.slice(0, 16)
                    )
                  }
                  onBlur={(event) =>
                    validinputfield(
                      "user_lname",
                      event.target.value.slice(0, 16)
                    )
                  }
                  hasError={Lname}
                  value={UformState.user_lname}
                  label="Last Name"
                  errorMessage="Required"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "50%" }}
                  autoComplete="new-password"
                ></TextField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) =>
                    validinputfield(
                      "user_email",
                      event.target.value.toLowerCase()
                    )
                  }
                  onBlur={(event) =>
                    validinputfield(
                      "user_email",
                      event.target.value.toLowerCase()
                    )
                  }
                  // disabled={updatevariable}
                  id="hosp_zip_field"
                  value={UformState.user_email}
                  hasError={Email}
                  errorMessage={Email}
                  
                   
                 isReadOnly={updatevariable}
                
                  style={updatevariable?{cursor: 'not-allowed' }:{cursor:'text'}}
                     
 
                  
                  label="Email"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "50%" }}
                  autoComplete="new-password"
                ></TextField>
                <PhoneNumberField
                  onChange={(event) =>
                    validinputfield(
                      "user_phone",
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, "")
                        .slice(0, 10)
                    )
                  }
                  onBlur={(event) =>
                    validinputfield(
                      "user_phone",
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, "")
                        .slice(0, 10)
                    )
                  }
                  id="hosp_zip_field"
                  value={UformState.user_phone}
                  hasError={Phone}
                  errorMessage={Phone}
                  label="Phone Number"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "50%" }}
                  autoComplete="new-password"
                ></PhoneNumberField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <PasswordField
                  onChange={(event) =>
                    validinputfield(
                      "user_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")
                    )
                  }
                  onBlur={(event) =>
                    validinputfield(
                      "user_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")
                    )
                  }
                  onKeyUp={(event) =>
                    validinputfield(
                      "user_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")
                    )
                  }
                  id="hosp_zip_field"
                  value={UformState.user_password}
                  // disabled={updatevariable}

                  hasError={Password}
                  errorMessage={Password}
                
                
                  isReadOnly={updatevariable}
                
                 style={updatevariable?{cursor: 'not-allowed' }:{cursor:'text'}}
                     


                  label="Password"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "50%" }}
                  autoComplete="new-password"
                ></PasswordField>
                <PasswordField
                  onChange={(event) => {updatevariable ? validinputfield( "bind_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")) :
                    validinputfield(
                      "cnfrm_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")
                    )
                  }}

                  

                  onBlur={(event) =>{updatevariable ?  validinputfield( "bind_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")):
                    validinputfield(
                      "cnfrm_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")
                    )
                  }}
                  // onKeyDown={(event) =>
                  //   validinputfield("cnfrm_password", event.target.value)
                  // }
                  onKeyUp={(event) =>{updatevariable ?  validinputfield( "bind_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")):
                    validinputfield(
                      "cnfrm_password",
                      event.target.value.replace(/^\s+|\s+$/gm, "")
                    )
                  }}
                  id="hosp_zip_field"
                  value={
                    updatevariable
                      ? bindconfirmpasword
                      : UformState.cnfrm_password
                  }
                  // disabled={updatevariable}

                  
                  isReadOnly={updatevariable}
                
                  style={updatevariable?{cursor: 'not-allowed' }:{cursor:'text'}}
                     

                  hasError={CnfrmPassword}
                  errorMessage={CnfrmPassword}
                  label="Confirm Password"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "50%" }}
                  autoComplete="new-password"
                ></PasswordField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <SelectField
                  width={{ base: "100%", large: "50%" }}
                  label="Role"
                  onChange={(event) =>
                    validinputfield("role_id", event.target.value)
                  }
                  onBlur={(event) =>
                    validinputfield("role_id", event.target.value)
                  }
                  value={UformState.role_id}
                  hasError={Role}
                  errorMessage={Role}
                >
                  <option value="0" isdisabled>
                    Select Role
                  </option>
                  {listofroles.map((row, key) => (
                    <option key={key} value={row?.role_id}>
                      {row?.role_name}
                    </option>
                  ))}
                </SelectField>
                <Text width={{ base: "100%", large: "50%" }}></Text>
              </Flex>
              <Flex
                direction={{ base: "row", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
                justifyContent={{ base: "center", large: "center" }}
              >
                {" "}
                <Button
                  backgroundColor="#8EC641"
                  style={buttonStyle}
                  boxShadow="large"
                  borderRadius="2em"
                  onClick={validation}
                  marginTop="1em"
                  border={"white"}
                >
                  Save{" "}
                  <Icon
                    as={BsHandIndexThumb}
                    fontSize={{ large: "xl" }}
                    color="white"
                    fontWeight={"bold"}
                    marginLeft="0.3em"
                    marginTop={"0.3em"}
                  ></Icon>
                </Button>
              </Flex>
            </Card>
          </View>
        </TabItem>
      </Tabs>
    </div>
  );
};
export default Settings;
