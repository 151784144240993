import React, { useEffect, useState, CSSProperties } from "react";
import {
    Button,
    useTheme,
    TextField,
    Text,
    Icon,
    Flex,
    View,
    Grid,
    SelectField,
} from "@aws-amplify/ui-react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { createAdCampaign, updateAdCampaign } from "../../graphql/mutations";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { listAdCampaigns } from "../../graphql/queries";
import "../../App.css";
import { BiReset, BiSkipNext } from "react-icons/bi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { ResetIcon, primaryButton, secondaryButton } from "../../assets/style";
import { ToastContainer, toast } from "react-toastify";
import { fontWeight, width } from "@mui/system";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
const PostDetails = ({setPostDetailsValue,postDetailsvalue,redirect_sc,DisableFieldsAfterpublished}) => {

const setInput=(key,value)=>{
   let temp=[...postDetailsvalue]
   temp[0][key]=value;
   setPostDetailsValue(temp);
}
const ResetPostDetails=()=>{
    let temp=[...postDetailsvalue]
    temp[0]["scheduleDate"]="";
    temp[0]["scheduleTime"]="";
    temp[0]["Subject"]="";
    setPostDetailsValue(temp);
}

    return (<>
        <div>
            <ToastContainer autoClose={1500} limit={0} />
            <Flex
                direction={{
                    base: "column",
                    small: "column",
                    medium: "column",
                    large: "column",
                    xl: "column",
                    xxl: "column",
                }}
                alignItems="center"
                gap={{ large: "xxs" }}
                style={{

                    border: "2px solid #E8E8E8",
                    borderRadius: "0.5em",
                }}
            >
                <View
                    width={{
                        base: "100%",
                        small: "100%",
                        medium: "50%",
                        large: "40%",
                        xl: "40%",
                        xxl: "40%",
                    }}
                    height={"50%"}
                    padding="2rem"
                    color="var(--amplify-colors-blue-60)">
                    <Text marginBottom={"0.5em"} fontSize="1.2em">Schedule </Text>
                    <Flex direction={{ base: "column", small: "row", large: "row", xl: "row" }} alignItems='center' gap={{ base: "1em", small: "2em", large: "2em", xl: "2em" }} >
                        <View width="100%">
                            <Form.Group controlId="dob" >
                                <Form.Label style={{ color: "#000" }}>Date</Form.Label>
                                <Form.Control
                                    disabled={DisableFieldsAfterpublished}
                                    style={{ width: "100%" }}
                                    type="date"
                                    name="dob"
                                    value={postDetailsvalue[0]["scheduleDate"]}
                                    max={new Date(new Date().getTime()+(75*24*60*60*1000)).toISOString()
                                        .split("T")[0]}
                                    onChange={(event) =>
                                        setInput("scheduleDate", event.target.value)
                                    }
                                    placeholder="Date of Birth"
                                />
                            </Form.Group>
                        </View>
                        <View width="100%">
                            <Form.Group controlId="dob">
                                <Form.Label style={{ color: "#000" }}> Time</Form.Label>
                                <Form.Control
                                    disabled={DisableFieldsAfterpublished}
                                    type="time"
                                    name="dob"
                                    value={postDetailsvalue[0]["scheduleTime"]}
                                    onChange={(event) =>
                                        setInput("scheduleTime", event.target.value)
                                      }
                                    placeholder="Date of Birth"
                                />
                            </Form.Group>
                        </View>
                    </Flex>
                    {/* <TextField value={postDetailsvalue[0]["Subject"]} onChange={(event) =>
                        setInput("Subject", event.target.value)
                    }
                        label={<Text fontWeight={"bold"} marginTop="1em"
                    >Subject</Text>}
                    ></TextField>
                    <SelectField

                        placeholder="Select"
                        label={<Text fontWeight={"bold"} marginTop="1em">Label</Text>}
                    >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                    </SelectField> */}
                    <Flex
                        justifyContent="center"
                        marginTop="2em"
                        gap="3rem"
                    >
                        <Button
                            backgroundColor="#df2121"
                            style={primaryButton}
                            boxShadow="large"
                            onClick={ResetPostDetails}
                            borderRadius="2em"
                            disabled={DisableFieldsAfterpublished}
                        >
                            Reset
                            <Icon
                                as={RestartAltIcon}
                                fontSize={{ large: "xl" }}
                                color="white"
                                fontWeight={"bold"}
                                marginLeft="0.3em"
                            ></Icon>
                        </Button>
                        <Button
                            variation="primary"
                            style={primaryButton}
                            boxShadow="large"
                            borderRadius="2em"
                            onClick={redirect_sc}
                            backgroundColor={"#202A44"}
                           // isLoading={Progressbar}
                            loadingText="processing..."
                            //onClick={CreateCampaign} //
                            disabled={DisableFieldsAfterpublished}
                        >
                            Next
                            <Icon
                                as={BsFillArrowRightCircleFill}
                                fontSize={{base:"1.6em", small:"1.6em", medium:"1.8em", large:"1.9em"}}
                                color="white"
                                fontWeight={"bold"}
                                marginLeft="0.5em"
                                marginTop={"0.36em"}
                            ></Icon>
                        </Button>
                    </Flex>
                </View>
            </Flex></div></>);
}

export default PostDetails;