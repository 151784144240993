import React, { useEffect, useState, CSSProperties } from "react";
import DashboardHeader from "../../layout/DashboardHeader";
import { Expander, ExpanderItem, Flex } from "@aws-amplify/ui-react";
import Table from "react-bootstrap/Table";
import { ImPencil } from "react-icons/im";
import { createPostDetails, createPostHistory, updatePostDetails } from '../../graphql/mutations';
import Helper from "../../assets/Helper";
import { Buffer } from "buffer";
import { MediaCollection } from "../../ui-components";
import { ToastContainer, toast } from "react-toastify";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BsHandIndexThumb } from "react-icons/bs";
import {
    Icon,
    Image,
    TextAreaField,
    SelectField,
    SearchField,
    ScrollView,
    Collection,
    FieldGroupIcon,
    FieldGroupIconButton,
    Link,
} from "@aws-amplify/ui-react";
import ContentLibrary from "../contentlibrary/ContentLibrary";
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import {
    RiDeleteBin5Line,
    RiImage2Fill,
    RiEditBoxLine,
    RiImageAddFill,
    RiShareForwardLine,
    RiClapperboardLine,
} from "react-icons/ri";
import { Storage } from "@aws-amplify/storage";
import {
    BiLike,
    BiMessage,
    BiWorld,
    BiSave,
    BiPaperPlane,
    BiReset,
    BiImageAdd,
    BiSlider,
} from "react-icons/bi";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
    Button,
    ButtonGroup,
    Grid,
    View,
    useTheme,
    TextField,
    Text,
    Divider,
} from "@aws-amplify/ui-react";
import { BsChat } from "react-icons/bs";
import { FcFilmReel } from "react-icons/fc";
import { HiDotsHorizontal, HiDotsVertical, HiChevronDoubleRight } from "react-icons/hi";
import { CgFeed } from "react-icons/cg";
import Modal from "react-bootstrap/Modal";
import { manageposttheme } from "../../assets/style";
import {
    getFBLoginInfo,
    listFBLoginInfos,
    listConfigItems,
    listContentLibraries,
} from "../../graphql/queries";
import { Form } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import { Carousel, Card, Stack } from "react-bootstrap";
// import Button from 'react-bootstrap/Button';
import { ToggleButton, ToggleButtonGroup } from "@aws-amplify/ui-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MapView } from "@aws-amplify/ui-react";
import { createAdDetails } from "../../graphql/mutations";
// import TimePicker from 'react-time-picker';
// import DatePicker from 'react-date-picker';
import OutlinedInput from "@mui/material/OutlinedInput";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Tabs, TabItem, ThemeProvider, Theme } from "@aws-amplify/ui-react";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { fontSize } from "@mui/system";
import HashLoader from "react-spinners/HashLoader";
import LoadingOverlay from "react-loading-overlay";
import "../../App.css";
import Multiselect from "multiselect-react-dropdown";
// import Ad.css
import "../managecontent/Ad.css";
import {
    DataGrid,
    GridColDef,
    GridValueGetterParams,
    GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { SwitchField } from "@aws-amplify/ui-react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { listOrganizationInfos } from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { encode, decode } from 'js-base64';
import { Base64 } from 'js-base64';
import { listAdDetails } from "../../graphql/queries";
import { primaryButton, secondaryButton, ResetIcon } from "../../assets/style";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { ConsoleLogger } from "@aws-amplify/core";
import { mobileStepperClasses } from "@mui/material";
const tempimageArr = ["https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE"];
const imageArr = ["https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE"]
const Post = ({ setPostValues,
    postvalue,
    updatecheck,
    token,
    setpostfbvers,
    sethistoryfbvers,
    pageid,
    userid,
    instg_user,
    setigpostHistId,
    setfbpostHistId,
    adsAccountId,
    fbpostDetailsId,
    setfbpostDetailsId,
    igpostDetailsId,
    setigpostDetailsId,
    setpostigvers,
    sethistoryigvers,

    setGeneralPost_Id ,
    setGeneralpostvers ,
    setGeneralPostHistory_Id ,
    setGeneralPostHistoryvers ,


    updateid,
    redirect_sp,
    updateversion,DisableFieldsAfterpublished }) => {
    const [Nextimageindex, setNextimageindex] = useState(0);
    const [Prviousimageindex, setPrviousimageindex] = useState(0);
    const [saveimagefb, setsaveimagefb] = useState(false);
    const [saveimageig, setsaveimageig] = useState(false);

    const [imgeurl, setimageurl] = useState();
    const [fbpoststate, setfbpoststate] = useState(false);
    const [instapoststate, setinstapoststate] = useState(false);
    const [instaimage, setinstaimage] = useState("https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE")
    const [i, setTabindex] = useState(0);
    const [ShowContentLibrary, set_ShowContentLibrary] = useState(false);

    // {
    //     scheduleDate: "",
    //     scheduleTime: "",
    //     label: "",
    //     Subject: "",
    //     isfbpost: "",
    //     isigpost: "",
    //     msg:"",
    //     image:"",
    //     fbmsg: "",
    //     fbimage: "",
    //     igmsg: "",
    //     igimage: "",
    //   },

// content Library
const [ListOfContentLibrary, setListOfContentLibrary] = useState([]);
const getContentLibrary = async () => { 
  try {
    let filter = {
      companyinfoID: { eq: localStorage.getItem("companyinfoID") },
      isDelete: { eq: false },
    };
    const listOfItem = await API.graphql({
      query: listContentLibraries,
      variables: { filter: filter },
    });
    const ListOfContentLibraryTable =
      listOfItem.data.listContentLibraries.items.filter(
        (item) =>
          (item._deleted !== true || item._deleted == null) &&
          item.media_type.substring(0, 5) == "image"
      ); //Store all data
    // Loop the All File to Get from S3
    console.log("Listofimage: ", ListOfContentLibraryTable);
    const CognitoUserid = localStorage.getItem("Cognito_User_Id"); //particular company Id
    if (
      ListOfContentLibraryTable.length != "" &&
      ListOfContentLibraryTable.length != 0
    ) {
      for (var i = 0; i < ListOfContentLibraryTable.length; i++) {
        const signedURL = await Storage.get(
          ListOfContentLibraryTable[i].media_name,
          {
            expires: 3600,
            level: "protected",
            identityId: CognitoUserid,
          }
        );
        ListOfContentLibraryTable[i].media_url = signedURL; //Get Signedurl Reassign Media_url
        console.log(
          "signedURL",
          signedURL,
          ListOfContentLibraryTable[i].media_url
        );
      }
      console.log("ListofimageUpdatedfsf: ", ListOfContentLibraryTable);

      setTimeout(() => {
        setListOfContentLibrary(ListOfContentLibraryTable);
      }, 1000);
    }
  } catch (err) {
    console.log("error fetching Ad", err);
  }
  console.log("ListofimageUpdateAd: ", ListOfContentLibrary);
};


const [ImageSrcValue, setImageSrcValue] = useState();
const handler = (event) => {
  const ImageSrc = event.target.src;
  console.log("Index", ImageSrc); //will log the index of the clicked item
  setImageSrcValue(ImageSrc);
};

const [showad, setShowad] = useState(false);
  const handleClosead = () => {
    setShowad(false);
    set_ShowContentLibrary(false);
    setImage(ImageSrcValue);
    // console.log("Value", ImageSrcValue);
  };






    useEffect(() => {
        console.log(postvalue[0].isigpost)
        if (postvalue[0].isfbpost !== "" && postvalue[0].isigpost !== "") {

            if (postvalue[0].isfbpost) {
                if (postvalue[0].fbmsg === "") {
                    let temp = [...postvalue]
                    temp[0].fbmsg = postvalue[0].msg
                    temp[0].fbimage = postvalue[0].image
                    setPostValues(temp)
                }

            }

            else {
                let temp = [...postvalue]
                temp[0].fbmsg = ""
                temp[0].fbimage = ""
                setPostValues(temp)
            }
            if (postvalue[0].isigpost) {
                if (postvalue[0].igmsg === "") {
                    let temp = [...postvalue]
                    temp[0].igmsg = postvalue[0].msg
                    temp[0].igimage = postvalue[0].image
                    setPostValues(temp)
                }
            }
            else {
                let temp = [...postvalue]

                temp[0].igmsg = ""
                temp[0].igimage = ""
                setPostValues(temp)
            }


            if (postvalue[0].isigpost && !postvalue[0].isfbpost) {


                setTabindex(1);
            }
        }
        console.log(postvalue, "settttt")
    }, [])

    const CreateFeedForinsta = async () => {
        let unixtimeStamp = "";
setsaveimageig(true);
        if (postvalue[0]["isigpost"]) {
            if( postvalue[0].igmsg != "" && postvalue[0].igimage != "")
            {
            unixtimeStamp = toUnixTime(postvalue[0]["scheduleDate"].split('-')[0], postvalue[0]["scheduleDate"].split('-')[1], postvalue[0]["scheduleDate"].split('-')[2], postvalue[0]["scheduleTime"].split(':')[0], postvalue[0]["scheduleTime"].split(':')[1], 0);
            postvalue[0].timestamp = unixtimeStamp;
            let obj = {}
            let apiName = ""
            if (!updatecheck) {
                apiName = createPostDetails
                obj = {
                    post_message: postvalue[0]["igmsg"],
                    post_schedule_timestamp: unixtimeStamp ,
                    page_id: pageid,
                    companyinfoID: localStorage.getItem("companyinfoID"),
                    user_id: userid,
                    post_status: "6",
                    isActive: true,
                    isDelete: false,
                    post_type: "feed",
                    post_channels: JSON.stringify(["Instagram"]),
                    media_hash_id: postvalue[0].igimage,
                    fb_post_id: null

                }
            }
            else {
                apiName = updatePostDetails
                obj = {
                    id: updateid,
                    post_message: postvalue[0]["igmsg"],
                    post_schedule_timestamp:unixtimeStamp ,
                    page_id: pageid,
                    companyinfoID: localStorage.getItem("companyinfoID"),
                    user_id: userid,
                    post_status: "6",
                    isActive: true,
                    isDelete: false,
                    post_type: "feed",
                    post_channels: JSON.stringify(["Instagram"]),
                    media_hash_id: postvalue[0].igimage,
                    fb_post_id: null,
                    _version: updateversion

                }
            }


            await API.graphql(graphqlOperation(apiName, { input: obj })).then(async (data) => {
                if (!updatecheck) {
                    toast.success("Post Saved SuccessFully");
                    redirect_sp();
                    setTimeout(() => {
                        setsaveimageig(false)
                      }, 2500);
                    setigpostDetailsId(data.data.createPostDetails.id);
                    setpostigvers(data.data.createPostDetails._version)
                }
                else {
                    setTimeout(() => {
                        setsaveimageig(false)
                      }, 2500);
                    toast.success("Post Updated SuccessFully");
                    redirect_sp();
                    setigpostDetailsId(data.data.updatePostDetails.id);
                    setpostigvers(data.data.updatePostDetails._version)
                }

                console.log(data.data.createPostDetails.id, "savingfb")

                if (!updatecheck) {
                    const Historyobj = {
                        post_status_id: "6",
                        assigned_by_userid: localStorage.getItem("userid"),
                        postdetailsID: data.data.createPostDetails.id
                    }
                    await API.graphql(graphqlOperation(createPostHistory, { input: Historyobj })).then((d) => {
                        console.log(d.data, "savingHistory")
                        setigpostHistId(d.data.createPostHistory.id)
                        sethistoryigvers(d.data.createPostHistory._version)
                        // setTimeout(() => {
                        //     if (postvalue[0].isfbpost) {

                        //         //setTabindex(0);
                        //     }
                        // }, 500)

                    })
                }


            })
        }
        else{
            toast.error("Please Fill All Fields")
            setTimeout(() => {
                setsaveimagefb(false)
              }, 2);
        }
        }
    }
    const SaveBothPostdetails = async () =>{
        setsaveimagefb(true);
        let unixtimeStamp = "";
        if (postvalue[0]["isfbpost"] && postvalue[0]["isigpost"] ) {
            console.log("Postvalue",postvalue)
            if(postvalue[0]["scheduleDate"] != "" && postvalue[0].fbmsg !="" && postvalue[0].igmsg != "" && postvalue[0]["scheduleTime"] != "" &&
             postvalue[0].fbimage != "" && postvalue[0].igimage != "")
             {
        try {
            unixtimeStamp = toUnixTime(postvalue[0]["scheduleDate"].split('-')[0], postvalue[0]["scheduleDate"].split('-')[1], postvalue[0]["scheduleDate"].split('-')[2], postvalue[0]["scheduleTime"].split(':')[0], postvalue[0]["scheduleTime"].split(':')[1], 0);
            postvalue[0].timestamp = unixtimeStamp;
            let obj = {}
            let apiName = ""
            if (updatecheck) {
                apiName = updatePostDetails
                obj = {
                    id: updateid,
                    post_message: postvalue[0].msg,
                    post_schedule_timestamp: unixtimeStamp,
                    page_id: pageid,
                    companyinfoID: localStorage.getItem("companyinfoID"),
                    user_id: userid,
                    post_status: "6",
                    isActive: true,
                    isDelete: false,
                    post_type: "feed",
                    post_channels: JSON.stringify(["Facebook","Instagram"]),
                    media_hash_id: postvalue[0].image,
                    fb_post_id: null,
                    _version: updateversion,
                    fb_post_msg:postvalue[0].fbmsg,
                    insta_post_msg:postvalue[0].igmsg,
                    fb_image_url:postvalue[0].fbimage,
                    insta_image_url:postvalue[0].igimage,
                    insta_post_id:null
                }
            }
            else {
                apiName = createPostDetails;
                obj = {
                    post_message: postvalue[0].msg,
                    post_schedule_timestamp: unixtimeStamp,
                    page_id: pageid,
                    companyinfoID: localStorage.getItem("companyinfoID"),
                    user_id: userid,
                    post_status: "6",
                    isActive: true,
                    isDelete: false,
                    post_type: "feed",
                    post_channels: JSON.stringify(["Facebook","Instagram"]),
                    media_hash_id: postvalue[0].image,
                    fb_post_id: null,
                    fb_post_msg:postvalue[0].fbmsg,
                    insta_post_msg:postvalue[0].igmsg,
                    fb_image_url:postvalue[0].fbimage,
                    insta_image_url:postvalue[0].igimage,
                    insta_post_id:null
                }
            }


            console.log(obj, "savingData")

            await API.graphql(graphqlOperation(apiName, { input: obj })).then(async (data) => {
                console.log(data,"Generalpostid");
                if (!updatecheck) {

                    console.log(data,"general")
                    setGeneralPost_Id(data.data.createPostDetails.id)
                    setGeneralpostvers(data.data.createPostDetails._version);

                    const Historyobj = {
                        post_status_id: "6",
                        assigned_by_userid: localStorage.getItem("userid"),
                        postdetailsID: data.data.createPostDetails.id
                    }
                    await API.graphql(graphqlOperation(createPostHistory, { input: Historyobj })).then((d) => {
                        // console.log(d.data, "savingHistory")
                        // setfbpostHistId(d.data.createPostHistory.id)
                        // sethistoryfbvers(d.data.createPostHistory._version)
                        // setigpostHistId(d.data.createPostHistory.id)
                        // sethistoryigvers(d.data.createPostHistory._version)
                       

                        setGeneralPostHistory_Id(d.data.createPostHistory.id)
                        setGeneralPostHistoryvers(d.data.createPostHistory._version)
                         // setTimeout(() => {
                        //     if (postvalue[0].isigpost) {
                        //         setTabindex(1);
                        //     }
                        // }, 500)

                    })

                    setTimeout(() => {
                        setsaveimagefb(false)
                      }, 2500);
                    toast.success("Post Saved SuccessFully");
                    redirect_sp();
                }
                else {
                    console.log(data,"general")
                    setGeneralPost_Id(data.data.updatePostDetails.id)
                    setGeneralpostvers(data.data.updatePostDetails._version);
                    setTimeout(() => {
                        setsaveimagefb(false)
                      }, 2500);
                    toast.success("Post Updated SuccessFully");
                    redirect_sp();
                }



            })

        }
        catch {
            setsaveimagefb(false);
        }
    }
    else{
        toast.error("Please Fill All Fields")
        setTimeout(() => {
            setsaveimagefb(false)
          }, 2500);
    }
    }
}
    const CreateFeed = async () => {
    
setsaveimagefb(true);
        let unixtimeStamp = "";
        if (postvalue[0]["isfbpost"]) {
            if(postvalue[0]["scheduleDate"] != "" && postvalue[0].fbmsg !="" && postvalue[0]["scheduleTime"] != "" &&
            postvalue[0].fbimage != "")
            {
            try {
                unixtimeStamp = toUnixTime(postvalue[0]["scheduleDate"].split('-')[0], postvalue[0]["scheduleDate"].split('-')[1], postvalue[0]["scheduleDate"].split('-')[2], postvalue[0]["scheduleTime"].split(':')[0], postvalue[0]["scheduleTime"].split(':')[1], 0);
                postvalue[0].timestamp = unixtimeStamp;
                
                let obj = {}
                let apiName = ""
                if (updatecheck) {

                    apiName = updatePostDetails
                    obj = {
                        id: updateid,
                        post_message: postvalue[0]["fbmsg"],
                        post_schedule_timestamp: unixtimeStamp,
                        page_id: pageid,
                        companyinfoID: localStorage.getItem("companyinfoID"),
                        user_id: userid,
                        post_status: "6",
                        isActive: true,
                        isDelete: false,
                        post_type: "feed",
                        post_channels: JSON.stringify(["Facebook"]),
                        media_hash_id: postvalue[0].fbimage,
                        fb_post_id: null,
                        _version: updateversion

                    }
                }
                else {
                    apiName = createPostDetails;
                    obj = {
                        post_message: postvalue[0]["fbmsg"],
                        post_schedule_timestamp: unixtimeStamp,
                        page_id: pageid,
                        companyinfoID: localStorage.getItem("companyinfoID"),
                        user_id: userid,
                        post_status: "6",
                        isActive: true,
                        isDelete: false,
                        post_type: "feed",
                        post_channels: JSON.stringify(["Facebook"]),
                        media_hash_id: postvalue[0].fbimage,
                        fb_post_id: null

                    }
                }




                await API.graphql(graphqlOperation(apiName, { input: obj })).then(async (data) => {
                
                    if (!updatecheck) {
                        setTimeout(() => {
                            setsaveimagefb(false)
                          }, 2500);
                        toast.success("Post Saved SuccessFully");
                        redirect_sp();
                        setfbpostDetailsId(data.data.createPostDetails.id);
                        setpostfbvers(data.data.createPostDetails._version)
                    }
                    else {
                      
                        toast.success("Post Updated SuccessFully");
                        setTimeout(() => {
                            setsaveimagefb(false)
                            redirect_sp();
                          }, 2500);
                      
                      
                        setfbpostDetailsId(data.data.updatePostDetails.id);
                        setpostfbvers(data.data.updatePostDetails._version)

                    }
  
                    //console.log(data.data.apiName.id, "savingfb")
                    //   setfbpostDetailsId(data.data.apiName.id);
                   // setpostfbvers(data.data.apiName._version)
                    if (!updatecheck) {

                     console.log(data.data.createPostDetails.id,"fatattata");
                     
                        const Historyobj = {
                            post_status_id: "6",
                            assigned_by_userid: localStorage.getItem("userid"),
                            postdetailsID: data.data.createPostDetails.id
                        }
                        await API.graphql(graphqlOperation(createPostHistory, { input: Historyobj })).then((d) => {
                            console.log(d.data, "savingHistory")
                            setfbpostHistId(d.data.createPostHistory.id)
                            sethistoryfbvers(d.data.createPostHistory._version)
                            setTimeout(() => {
                                if (postvalue[0].isigpost) {
                                    setTabindex(1);
                                }
                            }, 500)

                        })
                    }



                })

            }
            catch {

            }
            finally {


                // if (postvalue[0]["isfbpost"] && !postvalue[0]["isigpost"]) {

                //         const obj = {
                //             post_message: postvalue[0]["msg"],
                //             post_schedule_timestamp: unixtimeStamp,
                //             page_id: pageid,
                //             companyinfoID: localStorage.getItem("companyinfoID"),
                //             user_id: userid,
                //             post_status: "Published",
                //             isActive: true,
                //             isDelete: false,
                //             post_type: "feed",
                //             post_channels: JSON.stringify(["Facebook"]),
                //             media_hash_id: imgeurl,
                //             fb_post_id: null

                //         }

                //         await API.graphql(graphqlOperation(createPostDetails, { input: obj })).then(() => {
                //             toast.success("Post Saved SuccessFully");
                //         })

                // }
                // else if (postvalue[0]["isigpost"] && !postvalue[0]["isfbpost"]) {

                //         const obj = {
                //             post_message: postvalue[0]["msg"],
                //             post_schedule_timestamp: unixtimeStamp,
                //             page_id: pageid,
                //             companyinfoID: localStorage.getItem("companyinfoID"),
                //             user_id: userid,
                //             post_status: "Published",
                //             isActive: true,
                //             isDelete: false,
                //             post_type: "feed",
                //             post_channels: JSON.stringify(["Instagram"]),
                //             media_hash_id: imgeurl,
                //             fb_post_id: null

                //         }

                //         await API.graphql(graphqlOperation(createPostDetails, { input: obj })).then(() => {
                //             toast.success("Post Saved SuccessFully");
                //         })



                // }
                // else if (postvalue[0]["isigpost"] && postvalue[0]["isfbpost"]) {

                //         const obj = {
                //             post_message: postvalue[0]["msg"],
                //             post_schedule_timestamp: unixtimeStamp,
                //             page_id: pageid,
                //             companyinfoID: localStorage.getItem("companyinfoID"),
                //             user_id: userid,
                //             post_status: "Published",
                //             isActive: true,
                //             isDelete: false,
                //             post_type: "feed",
                //             post_channels: JSON.stringify(["Facebook", "Instagram"]),
                //             media_hash_id: imgeurl,
                //             fb_post_id: null

                //         }

                //         await API.graphql(graphqlOperation(createPostDetails, { input: obj })).then(() => {
                //             toast.success("Post Saved SuccessFully");
                //         })

                // }
            }
        }
        else{
    toast.error("Please Fill All Fields")
        setTimeout(() => {
            setsaveimagefb(false)
          }, 2);
        }
        }
    }


    const toUnixTime = (year, month, day, hr, min, sec) => {
        return parseInt((new Date(year + '.' + month + '.' + day + ' ' + hr + ':' + min + ':00').getTime() / 1000).toFixed(0))
    }
    const setInput = (key, value) => {
        let temp = [...postvalue]
        temp[0][key] = value;
        setPostValues(temp);
    };

// ////////validate/////

// if (key == "fbmsg") { 
           
//     if (value == "") { 
//       setcontent(true);
//     } else {
//       setcontent(false);
//     }
//   }


//   if(key == "igmsg"){ 
//     if ( value == ""){ 
//         setimagee(true);

//     }else{
//         setimagee(false);
//     }
//   }
 
// }
//   ////validation setvariable
//   const [content, setcontent] = useState(false);
//   const [ imagee, setimagee] = useState(imageArr[0]);
//    ////   validation for content 
//    const validation=()=>
//    {
//      if(postvalue[0]["fbmsg"]=="")
//      {
//       setcontent("Required content");
//      }else{
//       setcontent(false);
//      }
    
//      if(postvalue[0]["fbmsg"]!=""&& postvalue[0].image !="")
//       {
//           redirect_ge()
  
//       }
//      if(postvalue[0].fbimage [0] =="")
//       { //alert("hi");
//       toast.error("upload image");
//           setimagee("required image");
         
//       }else{
//           setimagee(false);
//       }
     
       





//     };








    


    function _base64ToArrayBuffer(base64) {
        console.log(base64, "entry")
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }
    const onImageChangeInsta = async (event) => {
        const file = event.target.files[0];

        console.log(event.target.files[0], "event");

        if (event.target.files && event.target.files[0]) {
            setsaveimageig(true);
            setinstaimage(URL.createObjectURL(event.target.files[0]));

            console.log(URL.createObjectURL(event.target.files[0]).split(','), "datasss");



            toDataURL(URL.createObjectURL(event.target.files[0])).then(dataUrl => {
                console.log('RESULT:', dataUrl)

                const headers = {
                    "content-type": "application/x-www-form-urlencoded",
                };

                const data = new FormData();

                data.append("bytes", dataUrl.split(",")[1]);
                axios
                    .post(
                        "https://graph.facebook.com/v15.0/" +
                        adsAccountId +
                        "/adimages?access_token=" +
                        token,
                        data,
                        {
                            headers: headers,
                        }
                    )

                    .then(function (responsef) {
                        console.log(responsef.data.images.bytes.hash, "campaign_id");
                        postvalue[0].igimage = responsef.data.images.bytes.url;
                        setimageurl(responsef.data.images.bytes.url);
                        setsaveimageig(false);


                    })
                    .catch(function (error) {
                        toast.error("Error Saving Ad to Fb");
                        setsaveimageig(false);

                    });
                // axios({
                //     method: "post",
                //     url: "https://graph.facebook.com/v15.0/act_220528835008196/adimages?bytes=" + dataUrl.split(',')[1] + "&access_token=EAAIncNkLAIsBADOM2BLkMRf8BjPiGcjkL77hyNXXwkOOWWswDpZC7eAMOgjbOS6kQ895fV94CGKt6ZAR59zqtTfxSnX6VPnhxJSVHQ9C2x12qrK5mZCyOsXo0XXDIkApOoMxX0jZAC4c6nwZB5Dj8EvZAmuRm1KqeUTGC3231VtrrWM1ZBkmyfRTpRuwyR8AvlqjfbGfhFYmMojchC1lORr"

                // }).then((hash) => {
                //     console.log(hash, "data");
                // }).catch((err) => {
                //     console.log("err", err);
                // })
            })
        }
        // try {
        //   const result = await Storage.put(file.name, file, {
        //     contentType: "image/png", // contentType is optional
        //   });
        //   console.log("key", result.key);
        //   ///set_imgkey(result.key);
        // } catch (error) {
        //   console.log("Error uploading file: ", error);
        // }
    };

    const onImageChange = async (event) => {

        const file = event.target.files[0];
        console.log(event.target.files[0], "event");

        if (event.target.files && event.target.files[0]) {
            setsaveimagefb(true);

            setImage(URL.createObjectURL(event.target.files[0]));

            console.log(URL.createObjectURL(event.target.files[0]).split(','), "datasss");



            toDataURL(URL.createObjectURL(event.target.files[0])).then(dataUrl => {
                console.log('RESULT:', dataUrl)

                const headers = {
                    "content-type": "application/x-www-form-urlencoded",
                };

                const data = new FormData();

                data.append("bytes", dataUrl.split(",")[1]);
                axios
                    .post(
                        "https://graph.facebook.com/v15.0/" +
                        adsAccountId +
                        "/adimages?&access_token=" +
                        token,
                        data,
                        {
                            headers: headers,
                        }
                    )

                    .then(function (responsef) {
                        console.log(responsef.data.images.bytes.hash, "campaign_id");
                        postvalue[0]["fbimage"] = responsef.data.images.bytes.url;
                        setimageurl(responsef.data.images.bytes.url);
                        setsaveimagefb(false);


                    })
                    .catch(function (error) {
                        setsaveimagefb(false);
                        toast.error("Error Saving Post to Fb");
                    });
                // axios({
                //     method: "post",
                //     url: "https://graph.facebook.com/v15.0/act_220528835008196/adimages?bytes=" + dataUrl.split(',')[1] + "&access_token=EAAIncNkLAIsBADOM2BLkMRf8BjPiGcjkL77hyNXXwkOOWWswDpZC7eAMOgjbOS6kQ895fV94CGKt6ZAR59zqtTfxSnX6VPnhxJSVHQ9C2x12qrK5mZCyOsXo0XXDIkApOoMxX0jZAC4c6nwZB5Dj8EvZAmuRm1KqeUTGC3231VtrrWM1ZBkmyfRTpRuwyR8AvlqjfbGfhFYmMojchC1lORr"

                // }).then((hash) => {
                //     console.log(hash, "data");
                // }).catch((err) => {
                //     console.log("err", err);
                // })
            })
        }
        // try {
        //   const result = await Storage.put(file.name, file, {
        //     contentType: "image/png", // contentType is optional
        //   });
        //   console.log("key", result.key);
        //   ///set_imgkey(result.key);
        // } catch (error) {
        //   console.log("Error uploading file: ", error);
        // }
    };
    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))
    const { tokens } = useTheme();
    //const[imageindex,setimageindex]=useState();
    const [image, setImage] = useState("https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE");
    const theme: Theme = {
        name: "toggleButton-theme",
        tokens: {
            components: {
                togglebutton: {
                    borderColor: { value: "rgb(104, 2, 80)" },
                    color: { value: "black" },
                    _hover: {
                        backgroundColor: { value: "rgb(104, 2, 80)" },
                        color: { value: "white" },
                    },
                    _focus: {
                        color: { value: "white" },
                        borderColor: { value: "rgb(104, 2, 80)" },
                    },
                    _active: {
                        backgroundColor: { value: "rgb(104, 2, 80)" },
                        color: { value: "white" },
                        borderColor: { value: "rgb(104, 2, 80)" },
                    },
                    _pressed: {
                        backgroundColor: { value: "rgb(104, 2, 80)" },
                        color: { value: "white" },
                        borderColor: { value: "rgb(104, 2, 80)" },
                        _hover: {
                            backgroundColor: { value: "rgb(104, 2, 80)" },
                            color: { value: "white" },
                        },
                    },
                    primary: {
                        backgroundColor: { value: "rgb(104, 2, 80)" },
                    },
                },
            },
        },
    };


     //fetch image from content library
  const ShowContentPoppUp = () => { 
    set_ShowContentLibrary(true);
    getContentLibrary();
  };
  const handleShowad = () => {
    setShowad(true);
  };

//   const ResetGeCt = () => {
//     let temp = [...GenericContentValue]
//     temp[0]["msg"] = "";
//     setGeneriContentValue(temp);
// }


    const reset = () => {

        let temp = [...postvalue]
        temp[0]["scheduleDate"] = "";
        temp[0]["scheduleTime"] = "";
        temp[0]["fbmsg"] = "";
        temp[0]["igmsg"] =""
        console.log(temp);

        setPostValues(temp)
    }
    const [value, setValue] = useState("Feed");
    return (
        <>
            <ToastContainer autoClose={1500} limit={0} />
            <Flex
                direction={{
                    base: "column",
                    small: "column",
                    medium: "column",
                    large: "column",
                    xl: "column",
                    xxl: "column",
                }}
                gap="0em"
                style={{ border: "2px solid #E8E8E8", borderRadius: "0.5em" }}
            >
                <ThemeProvider theme={manageposttheme} colorMode="light">
                    <Tabs justifyContent="center" currentIndex={i}>
                        {postvalue[0].isfbpost != "" ? postvalue[0].isfbpost && (<TabItem onClick={() => { setTabindex(0) }} title="Facebook">
                            <Flex
                                direction={{
                                    base: "column",
                                    small: "column",
                                    medium: "row",
                                    large: "row",
                                    xl: "row",
                                    xxl: "row",
                                }}
                                gap={"0"}
                            >
                                <View
                                    alignItems="center"
                                    padding={{ base: "1em", large: "2em" }}
                                    width={{ base: "100%", small: "100%", medium: "40%", large: "40%" }}
                                >
                                    <ThemeProvider theme={theme} colorMode="light">
                                        <ToggleButtonGroup
                                            isExclusive
                                            justifyContent={"center"}
                                            tooltip="Browse From Local"
                                            value={value}
                                            onChange={(value) => setValue(value)}
                                            isSelectionRequired
                                            
                                        >
                                            <ToggleButton variation="primary" value="Feed">
                                                Feed{" "}
                                                <Icon
                                                    as={CgFeed}
                                                    color="white"
                                                    fontWeight={"bold"}
                                                    marginLeft="0.3em"
                                                    fontSize="1.7em"
                                                ></Icon>
                                            </ToggleButton>
                                            <ToggleButton variation="primary" value="Reels" isDisabled>
                                                Reels{" "}
                                                <Icon as={FcFilmReel} fontSize={"0.7em"} marginLeft="0.2em" marginTop={"-8px"} />
                                            </ToggleButton>
                                            <ToggleButton variation="primary" value="Stoies" isDisabled>
                                                Stories{" "}
                                                <Icon as={AutoStoriesOutlinedIcon} fontSize={"1.4em"} marginLeft="0.2em" />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ThemeProvider>
                                    <Text fontSize={"1.3em"} marginTop="1em">Schedule</Text>
                                    <Flex direction={"row"} alignItems='center' gap="2em" marginTop={"0.5em"}>
                                        <View width="50%">
                                            <Form.Group controlId="dob" >
                                                <Form.Label style={{ color: "#000" }}>Schedule Date</Form.Label>
                                                <Form.Control
                                                    style={{ width: "100%" }}
                                                    type="date"
                                                    name="dob"
                                                    onChange={(event) =>
                                                        setInput("scheduleDate", event.target.value)
                                                    }
                                                    max={new Date(new Date().getTime()+(75*24*60*60*1000)).toISOString()
                                                        .split("T")[0]}
                                                    value={postvalue[0]["scheduleDate"]}
                                                    placeholder="Date of Birth"
                                                    disabled={DisableFieldsAfterpublished}
                                                    
                                                />
                                            </Form.Group>
                                        </View>
                                        <View width="50%">
                                            <Form.Group controlId="dob">
                                                <Form.Label style={{ color: "#000" }}> Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    name="dob"
                                                    onChange={(event) =>
                                                        setInput("scheduleTime", event.target.value)
                                                    }
                                                    value={postvalue[0]["scheduleTime"]}
                                                    placeholder="Date of Birth"
                                                    disabled={DisableFieldsAfterpublished}
                                                />
                                            </Form.Group>
                                        </View>
                                    </Flex>
                                    <TextAreaField onChange={(event) => setInput("fbmsg", event.target.value)} value={postvalue[0]["fbmsg"]} placeholder="Relax! Life is Beautiful"
                                        label={<Text fontWeight={"bold"} marginTop="1em" >Content</Text>} isDisabled={DisableFieldsAfterpublished}></TextAreaField>
                                    <Text fontWeight={"bold"} marginTop="1em">Media</Text>
                                    <Card
                                        style={{
                                            width: "100%",
                                            border: "1px solid black",
                                            marginTop: "10px",
                                            padding: "1em",
                                        }}
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent="center"
                                            style={{ marginTop: "-1em" }}
                                        >
                                            <ToggleButtonGroup
                                                isExclusive
                                                border="none"
                                                margin="0em"
                                                className="custom-tabstoggle"
                                                tooltip="Browse From Local"
                                               
                                            >
                                                <ToggleButton
                                                    // style={{ boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)', backgroundColor: 'rgb(5 84 164 / 46%)', backdropFilter: 'blur(2px)', border: 'none' }}
                                                    className="custom-tab-itemtoggle"
                                                    disabled={DisableFieldsAfterpublished}
                                                >
                                                    <input
                                                        className="choosefile"
                                                        type="file"
                                                        name="myImage"
                                                        id="browse"
                                                        onChange={onImageChange}
                                                        disabled={DisableFieldsAfterpublished}
                                                    />
                                                    <Icon
                                                        target="#browse"
                                                        as={RiImage2Fill}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton
                                                    className="custom-tab-itemtoggle"
                                                    tooltip="Browse From Content Library"
                                                    disabled={DisableFieldsAfterpublished}
                                                onClick={ShowContentPoppUp}
                                                >
                                                    <Icon
                                                        as={RiClapperboardLine}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                {/* <ToggleButton className="custom-tab-itemtoggle">
                                                    <Icon
                                                        as={RiDeleteBin5Line}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton className="custom-tab-itemtoggle">
                                                    <Icon
                                                        as={RiEditBoxLine}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton className="custom-tab-itemtoggle">
                                                    <Icon
                                                        as={RiImageAddFill}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton> */}
                                            </ToggleButtonGroup>
                                        </Flex>
                                        <Card
                                            style={{
                                                color: "black",
                                                border: "none",
                                                marginTop: "-2.3em",
                                            }}
                                        >
                                            <Card.Body style={{ padding: "0em" }}>
                                                <Card.Text style={{ marginTop: "1em" }}>
                                                    <Flex direction={"row"} alignItems="center">
                                                        {/* onClick={() => { setImage(imageArr[0]) }} */}
                                                        <View width="10%">
                                                            <ChevronLeftIcon />
                                                        </View>
                                                        <View width="80%">
                                                            <Image
                                                                className="img-fluid rounded img-responsive"
                                                                src={postvalue[0].fbimage != "" ? postvalue[0].fbimage : image}
                                                                // alt="Second slide"
                                                                minHeight={{
                                                                    base: "30vh",
                                                                    small: "30vh",
                                                                    medium: "30vh",
                                                                    large: "30vh",
                                                                    xl: "30vh",
                                                                    xxl: "40vh",
                                                                }}
                                                                maxHeight={{
                                                                    base: "30vh",
                                                                    small: "30vh",
                                                                    medium: "30vh",
                                                                    large: "30vh",
                                                                    xl: "40vh",
                                                                    xxl: "40vh",
                                                                }}
                                                                style={{
                                                                    width: "100%",
                                                                    objectFit: "contain",
                                                                    backgroundColor: "rgba(128, 128, 128, 0.07)",
                                                                    borderRadius: "0.5em",
                                                                }}
                                                            />
                                                        </View>
                                                        <View width="10%" textAlign="right">
                                                            {/* onClick={() => { setImage(imageArr[1]) }} */}
                                                            <ChevronRightIcon />
                                                        </View>
                                                    </Flex>

                                                </Card.Text>

                                            </Card.Body>

                                        </Card>

                                    </Card>

                                    <Flex
                                        direction="row"
                                        marginTop={{ base: "xl", large: "xl" }}
                                        justifyContent="center"
                                    >

                                        <Button
                                            backgroundColor="#df2121"
                                            style={primaryButton}
                                            boxShadow="large"
                                            borderRadius="2em"
                                            onClick={reset}
                                            disabled={DisableFieldsAfterpublished}
                                        >
                                            Reset
                                            <Icon
                                                as={RestartAltIcon}
                                                fontSize={{ large: "large" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                            ></Icon>
                                        </Button>
                                        {/* {postvalue[0].isfbpost && postvalue[0].isigpost ?(null):(
                                        <Button
                                            backgroundColor="#8EC641"
                                            style={primaryButton}
                                            isLoading={saveimagefb}
                                            loadingText="Image Uploading"
                                            boxShadow="large"
                                            onClick={CreateFeed}
                                            borderRadius="2em"

                                        >
                                            Save{" "}
                                            <Icon
                                                as={SaveAltOutlinedIcon}
                                                fontSize={{ large: "large" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                            ></Icon>
                                        </Button>)} */}
                                    </Flex>
                                </View>
                                <Divider orientation="vertical" size="small" />
                                <View
                                    padding={{ base: "1em", large: "2em" }}
                                    alignItems="center"
                                    width={{ base: "100%", small: "100%", medium: "60%", large: "60%" }}
                                    marginTop={{ base: "2em", large: "0em" }}
                                >
                                    <Flex
                                        direction={{ base: "column", large: "row" }}
                                        gap={{ base: "small", large: "xl" }}
                                        justifyContent="center"
                                    >
                                        <Text
                                            fontSize={"1.2em"}
                                            fontWeight="400"
                                            marginTop={{ base: "-2em", medium: "5px", large: "5px" }}
                                        >
                                            Preview
                                        </Text>
                                    </Flex>
                                    <Flex
                                        style={{
                                            backgroundColor: "rgb(213 208 208 / 82%)",
                                            marginTop: "2em",
                                            border: "none",
                                        }}
                                        borderRadius="0.5em"
                                        paddingRight={{ base: "0em", medium: "1em", large: "3em" }}
                                        paddingLeft={{ base: "0em", medium: "1em", large: "3em" }}
                                    >
                                        <Flex
                                            margin={"auto"}
                                            width={{
                                                base: "calc(100% - 0px)",
                                                medium: "calc(100% - 0px)",
                                                large: "calc(100% - 0px)",
                                            }}
                                            justifyContent="center"
                                        >
                                            <Card
                                                style={{
                                                    color: "black",
                                                    borderRadius: "0em",
                                                    width: "80%",
                                                }}
                                                className="shadow-lg"
                                            >
                                                <Card.Header>
                                                    <Flex direction={{ base: "row", large: "row" }}>
                                                        <Image
                                                            src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                                                            width={{ base: 35, medium: 40, large: 50 }}
                                                            height={{ base: 35, medium: 40, large: 50 }}
                                                            alt="Avatar"
                                                            style={{
                                                                //Below lines will help to set the border radius
                                                                borderRadius: "50%",
                                                                overflow: 'hidden',
                                                            }}
                                                        />
                                                        <Text fontWeight={"600"} fontSize={{ base: "0.7em", large: "1em" }}>
                                                            Webiner Design Spot <br />
                                                            <Text fontSize={"0.6em"} color="grey">
                                                                Sponsered{" "}
                                                                <Icon
                                                                    as={BiWorld}
                                                                    fontSize={{ large: "small" }}
                                                                    color="grey"
                                                                ></Icon>
                                                            </Text>
                                                        </Text>
                                                        <Icon
                                                            as={HiDotsHorizontal}
                                                            fontSize={{ large: "xl" }}
                                                            alignItems="right"
                                                            justifyContent="right"
                                                            marginLeft={"auto"}
                                                            marginTop="0.5em"
                                                        ></Icon>
                                                    </Flex>
                                                    <Flex direction={{ base: "row", large: "row" }}>
                                                        <Text fontSize={"0.6em"}>
                                                            {postvalue[0]["Subject"]}
                                                        </Text>
                                                    </Flex>
                                                    <Text
                                                      fontWeight={"500"}
                                                        fontSize={{
                                                           base: "xs",
                                                            medium: "xs",
                                                            large: "small",
                                                             }}
                                                             >
                                                    {postvalue[0].fbmsg}
                                                </Text>
                                                </Card.Header>
                                                <Card.Body className="p-0">
                                                    <Card.Text>
                                                        <Image
                                                            className="img-responsive"
                                                            src={postvalue[0].fbimage != "" ? postvalue[0].fbimage : image}
                                                            // alt="Second slide"
                                                            style={{
                                                                width: "100%",
                                                                objectFit: "contain",
                                                                backgroundColor: "white",
                                                                maxHeight: "30vh",
                                                                // borderBottom:
                                                                //     "1px solid var(--bs-border-color-translucent)",
                                                                // borderRadius: "0.5em",
                                                            }}
                                                        />
                                                        
                                                        {/* <Flex
                                                            direction={{
                                                                base: "row",
                                                                medium: "row",
                                                                large: "row",
                                                            }}
                                                            gap={"0em"}
                                                            marginRight="2em"
                                                        >
                                                            <View
                                                                padding="1.1em"
                                                                alignItems="center"
                                                                width={{
                                                                    base: "80%",
                                                                    medium: "60%",
                                                                    large: "70%",
                                                                }}
                                                            > */}
                                                                {/* <Text color="grey">
                                                        cfdhtfhfg
                                                        <br />{" "}
                                                        <Text
                                                            fontWeight={"500"}
                                                            fontSize={{
                                                                base: "xs",
                                                                medium: "xs",
                                                                large: "small",
                                                            }}
                                                        >
                                                            gcbcgncg
                                                        </Text>
                                                        <Text
                                                            fontWeight={"500"}
                                                            fontSize={{
                                                                base: "xs",
                                                                medium: "xs",
                                                                large: "small",
                                                            }}
                                                        >
                                                            fdbgfc
                                                        </Text>
                                                    </Text> */}
                                                                {/* <Text
                                                                    fontWeight={"500"}
                                                                    fontSize={{
                                                                        base: "xs",
                                                                        medium: "xs",
                                                                        large: "small",
                                                                    }}
                                                                >
                                                                    {postvalue[0].fbmsg}
                                                                </Text>
                                                            </View>
                                                            <View
                                                                width={{
                                                                    base: "100%",
                                                                    medium: "40%",
                                                                    large: "30%",
                                                                }}
                                                                marginLeft={"auto"}
                                                                marginTop="2em"
                                                            > */}
                                                                {/* <Button
                                                        size={{
                                                            base: "small",
                                                            small: "xs",
                                                            medium: "xs",
                                                            large: "small",
                                                        }}
                                                        style={{
                                                            color: "#fff",
                                                            fontWeight: "600",
                                                            border: "none",
                                                        }}
                                                        boxShadow="large"
                                                        backgroundColor="#B71F39"
                                                        color="white"
                                                        marginLeft={"auto"}
                                                        fontSize={{
                                                            base: "xs",
                                                            small: "xs",
                                                            medium: "xs",
                                                            large: "small",
                                                        }}
                                                        width={{
                                                            base: "100%",
                                                            small: "100%",
                                                            medium: "120%",
                                                            large: "100%",
                                                        }}
                                                    >
                                                        xvfhgfhn
                                                    </Button> */}
                                                            {/* </View>
                                                        </Flex> */}

                                                    </Card.Text>

                                                </Card.Body>
                                                <Card.Footer>
                                                    <Flex
                                                   direction={{ base: "row", large: "row" }}
                                                   gap={{ base: "0.94em", large: "0.9em"   }}
                                                //    marginBottom={"0.9"}
                                                
                                                    >
                                                     {/* <Button
                                               variation="link"
                                                color={"black"}
                                               fontWeight="500"
                                              padding={{ base: "0.5em" }}
                                                 >
                                                  {" "} */}
                                                <Icon
                                                 as={BiLike}
                                               fontSize={{ medium:"xl"}}
                                               marginRight="-0.6em"
                                               fontWeight="200"
                                               ></Icon>{" "}
                                                 Like
                                               {/* </Button> */}
                                                {/* <Button
                                            variation="link"
                                            color={"black"}
                                           fontWeight="500"
                                          padding={{ base: "0.5em" }}
                                               >
                                                {" "} */}
                                              <Icon
                                             as={BiMessage}
                                         
                                           marginRight="-0.6em"
                                           fontSize={{ medium:"xl"}}
                                          ></Icon>{" "}
                                            Command
                                          {/* </Button> */}
                                         {/* <Button
                                         variation="link"
                                        color={"black"}
                                       fontWeight="500"
                                     padding={{ base: "0.5em" }}
                                       >
                                     {" "} */}
                                    <Icon
                                        as={RiShareForwardLine}
                                       fontSize={{ medium:"xl"}}
                                      marginRight="-0.6em"
                                    ></Icon>{" "}
                                      Share
                                        {/* </Button> */}
                                              </Flex>
                                                </Card.Footer>
                                            </Card>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction="row"
                                        marginTop={{ base: "xl", large: "xxl" }}
                                        justifyContent="flex-end"
                                    >
                                        {(postvalue[0]["isfbpost"] && postvalue[0]["isigpost"])? (
                                        <Button
                                            backgroundColor="#202A44"
                                            style={primaryButton}
                                            boxShadow="large"
                                            borderRadius="2em"
                                        //onClick={Redirectsaveandpublish}
                                        onClick={SaveBothPostdetails}
                                        isLoading={saveimagefb}
                                        loadingText="processing..."
                                        disabled={DisableFieldsAfterpublished}
                                        >
                                            Next{" "}
                                            <Icon
                                                as={HiChevronDoubleRight}
                                                fontSize={{ large: "xl" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                                marginTop={"0.2em"}
                                            ></Icon>
                                        </Button>):null}
                                        {(postvalue[0]["isfbpost"] && !postvalue[0]["isigpost"])? ( <Button
                                            backgroundColor="#202A44"
                                            style={primaryButton}
                                            boxShadow="large"
                                            borderRadius="2em"
                                        // onClick={Redirectsaveandpublish}
                                        onClick={CreateFeed}
                                        isLoading={saveimagefb}
                                        loadingText="processing..."
                                        disabled={DisableFieldsAfterpublished}
                                        >
                                            Next{" "}
                                            <Icon
                                                as={HiChevronDoubleRight}
                                                fontSize={{ large: "xl" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                                marginTop={"0.2em"}
                                            ></Icon>
                                        </Button>):null}
                                    </Flex>
                                </View>
                            </Flex>

                        </TabItem>) : null}
                        {postvalue[0].isigpost != "" ? postvalue[0].isigpost && (<TabItem onClick={() => { setTabindex(1) }} title="Instagram">
                            <Flex
                                direction={{
                                    base: "column",
                                    small: "column",
                                    medium: "row",
                                    large: "row",
                                    xl: "row",
                                    xxl: "row",
                                }}
                                gap={"0"}
                            >
                                <View
                                    alignItems="center"
                                    padding={{ base: "1em", large: "2em" }}
                                    width={{ base: "100%", small: "100%", medium: "40%", large: "40%" }}
                                >
                                    <ThemeProvider theme={theme} colorMode="light">
                                        <ToggleButtonGroup
                                            isExclusive
                                            justifyContent={"center"}
                                            tooltip="Browse From Local"
                                            value={value}
                                            onChange={(value) => setValue(value)}
                                            isSelectionRequired
                                        >
                                            <ToggleButton variation="primary" value="Feed">
                                                Feed{" "}
                                                <Icon
                                                    as={CgFeed}
                                                    color="white"
                                                    fontWeight={"bold"}
                                                    marginLeft="0.3em"
                                                    fontSize="1.7em"
                                                ></Icon>
                                            </ToggleButton>
                                            <ToggleButton variation="primary" value="Reels" isDisabled>
                                                Reels{" "}
                                                <Icon as={FcFilmReel} fontSize={"0.7em"} marginLeft="0.2em" marginTop={"-8px"} />
                                            </ToggleButton>
                                            <ToggleButton variation="primary" value="Stoies" isDisabled>
                                                Stories{" "}
                                                <Icon as={AutoStoriesOutlinedIcon} fontSize={"1.4em"} marginLeft="0.2em" />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ThemeProvider>
                                    {/* <Text fontSize={"1.3em"} marginTop="1em">Schedule</Text>
                                    <Flex direction={"row"} alignItems='center' gap="2em" marginTop={"0.5em"}>
                                        <View width="50%">
                                            <Form.Group controlId="dob" >
                                                <Form.Label style={{ color: "#000" }}>Schedule Date</Form.Label>
                                                <Form.Control
                                                    style={{ width: "100%" }}
                                                    type="date"
                                                    name="dob"
                                                    onChange={(event) =>
                                                        setInput("scheduleDate", event.target.value)
                                                    }
                                                    value={postvalue[0]["scheduleDate"]}
                                                    placeholder="Date of Birth"
                                                />
                                            </Form.Group>
                                        </View>
                                        <View width="50%">
                                            <Form.Group controlId="dob">
                                                <Form.Label style={{ color: "#000" }}> Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    name="dob"
                                                    onChange={(event) =>
                                                        setInput("scheduleTime", event.target.value)
                                                    }
                                                    value={postvalue[0]["scheduleTime"]}
                                                    placeholder="Date of Birth"
                                                />
                                            </Form.Group>
                                        </View>
                                    </Flex> */}
                                    <TextAreaField onChange={(event) => setInput("igmsg", event.target.value)} value={postvalue[0]["igmsg"]} placeholder="Relax! Life is Beautiful"
                                        label={<Text fontWeight={"bold"} marginTop="1em">Content</Text>}  disabled={DisableFieldsAfterpublished}></TextAreaField>
                                    <Text fontWeight={"bold"} marginTop="1em">Media</Text>
                                    <Card
                                        style={{
                                            width: "100%",
                                            border: "1px solid black",
                                            marginTop: "10px",
                                            padding: "1em",
                                        }}
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent="center"
                                            style={{ marginTop: "-1em" }}
                                        >
                                            <ToggleButtonGroup
                                                isExclusive
                                                border="none"
                                                margin="0em"
                                                className="custom-tabstoggle"
                                                tooltip="Browse From Local"
                                               
                                            >
                                                <ToggleButton
                                                    // style={{ boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)', backgroundColor: 'rgb(5 84 164 / 46%)', backdropFilter: 'blur(2px)', border: 'none' }}
                                                    className="custom-tab-itemtoggle"
                                                    disabled={DisableFieldsAfterpublished}
                                                >
                                                    <input
                                                        className="choosefile"
                                                        type="file"
                                                        name="myImage"
                                                        id="browse"
                                                        onChange={onImageChangeInsta}
                                                    />
                                                    <Icon
                                                        target="#browse"
                                                        as={RiImage2Fill}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton
                                                    className="custom-tab-itemtoggle"
                                                    tooltip="Browse From Content Library"
                                                    disabled={DisableFieldsAfterpublished}
                                                //onClick={ShowContentPoppUp}
                                                >
                                                    <Icon
                                                        as={RiClapperboardLine}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton className="custom-tab-itemtoggle" disabled={DisableFieldsAfterpublished}>
                                                    <Icon
                                                        as={RiDeleteBin5Line}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton className="custom-tab-itemtoggle" disabled={DisableFieldsAfterpublished}>
                                                    <Icon
                                                        as={RiEditBoxLine}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                                <ToggleButton className="custom-tab-itemtoggle" disabled={DisableFieldsAfterpublished}>
                                                    <Icon
                                                        as={RiImageAddFill}
                                                        fontSize={{ large: "large" }}
                                                    ></Icon>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Flex>
                                        <Card
                                            style={{
                                                color: "black",
                                                border: "none",
                                                marginTop: "-2.3em",
                                            }}
                                        >
                                            <Card.Body style={{ padding: "0em" }}>
                                                <Card.Text style={{ marginTop: "1em" }}>
                                                    <Flex direction={"row"} alignItems="center">
                                                        {/* onClick={() => { setImage(imageArr[0]) }} */}
                                                        <View width="10%">
                                                            <ChevronLeftIcon />
                                                        </View>
                                                        <View width="80%">
                                                            <Image
                                                                className="img-fluid rounded img-responsive"
                                                                src={postvalue[0].igimage != "" ? postvalue[0].igimage : instaimage}
                                                                // alt="Second slide"
                                                                minHeight={{
                                                                    base: "30vh",
                                                                    small: "30vh",
                                                                    medium: "30vh",
                                                                    large: "30vh",
                                                                    xl: "30vh",
                                                                    xxl: "40vh",
                                                                }}
                                                                maxHeight={{
                                                                    base: "30vh",
                                                                    small: "30vh",
                                                                    medium: "30vh",
                                                                    large: "30vh",
                                                                    xl: "40vh",
                                                                    xxl: "40vh",
                                                                }}
                                                                style={{
                                                                    width: "100%",
                                                                    objectFit: "contain",
                                                                    backgroundColor: "rgba(128, 128, 128, 0.07)",
                                                                    borderRadius: "0.5em",
                                                                }}
                                                            />
                                                        </View>
                                                        <View width="10%" textAlign="right">
                                                            {/* onClick={() => { setImage(imageArr[1]) }} */}
                                                            <ChevronRightIcon />
                                                        </View>
                                                    </Flex>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Card>
                                    <Flex
                                        direction="row"
                                        marginTop={{ base: "xl", large: "xl" }}
                                        justifyContent="center"
                                    >
                                        <Button
                                            backgroundColor="#df2121"
                                            style={primaryButton}
                                            boxShadow="large"
                                            borderRadius="2em"
                                            disabled={DisableFieldsAfterpublished}
                                        //onClick={reset}
                                        >
                                            Reset
                                            <Icon
                                                as={RestartAltIcon}
                                                fontSize={{ large: "large" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                            ></Icon>
                                        </Button>
                                        {/* {postvalue[0].isfbpost && postvalue[0].isigpost ?(null):(
                                        <Button
                                            backgroundColor="#8EC641"
                                            isLoading={saveimageig}
                                            loadingText="Image Uploading"
                                            style={primaryButton}
                                            boxShadow="large"
                                            onClick={CreateFeedForinsta}
                                            borderRadius="2em"
                                        >
                                            Save{" "}
                                            <Icon
                                                as={SaveAltOutlinedIcon}
                                                fontSize={{ large: "large" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                            ></Icon>
                                        </Button>)} */}
                                    </Flex>
                                </View>
                                <Divider orientation="vertical" size="small" />
                                <View
                                    padding={{ base: "1em", large: "2em" }}
                                    alignItems="center"
                                    width={{ base: "100%", small: "100%", medium: "60%", large: "60%" }}
                                    marginTop={{ base: "2em", large: "0em" }}
                                >
                                    <Flex
                                        direction={{ base: "column", large: "row" }}
                                        gap={{ base: "small", large: "xl" }}
                                        justifyContent="center"
                                    >
                                        <Text
                                            fontSize={"1.2em"}
                                            fontWeight="400"
                                            marginTop={{ base: "-2em", medium: "5px", large: "5px" }}
                                        >
                                            Preview
                                        </Text>
                                    </Flex>
                                    <Flex
                                        style={{
                                            backgroundColor: "rgb(213 208 208 / 82%)",
                                            marginTop: "2em",
                                            border: "none",
                                        }}
                                        borderRadius="0.5em"
                                        paddingRight={{ base: "0em", medium: "1em", large: "3em" }}
                                        paddingLeft={{ base: "0em", medium: "1em", large: "3em" }}
                                    >
                                        <Flex
                                            margin={"auto"}
                                            width={{
                                                base: "calc(100% - 0px)",
                                                medium: "calc(100% - 0px)",
                                                large: "calc(100% - 0px)",
                                            }}
                                            justifyContent="center"
                                        >
                                            <Card
                                                style={{
                                                    color: "black",
                                                    borderRadius: "0em",
                                                    width: "80%",
                                                }}
                                                className="shadow-lg"
                                            >
                                                <Card.Header>
                                                    <Flex direction={{ base: "row", large: "row" }}>
                                                        <Image
                                                            src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                                                            width={{ base: 35, medium: 40, large: 50 }}
                                                            height={{ base: 35, medium: 40, large: 50 }}
                                                            alt="Avatar"
                                                            style={{
                                                                borderRadius: "50%",
                                                                overflow: 'hidden',
                                                            }}
                                                        />
                                                        <Text fontWeight={"600"} fontSize={{ base: "0.7em", large: "1em" }}>
                                                            Webiner Design Spot <br />
                                                            <Text fontSize={"0.6em"} color="grey">
                                                                Sponsered{" "}
                                                                <Icon
                                                                    as={BiWorld}
                                                                    fontSize={{ large: "small" }}
                                                                    color="grey"
                                                                ></Icon>
                                                            </Text>
                                                        </Text>
                                                        <Icon
                                                            as={HiDotsVertical}
                                                            fontSize={{ large: "xl" }}
                                                            alignItems="right"
                                                            justifyContent="right"
                                                            marginLeft={"auto"}
                                                            marginTop="0.5em"
                                                        ></Icon>
                                                    </Flex>
                                                    <Flex direction={{ base: "row", large: "row" }}>
                                                        <Text fontSize={"0.6em"}>
                                                            {postvalue[0]["Subject"]}
                                                        </Text>
                                                    </Flex>
                                                </Card.Header>
                                                <Card.Body className="p-0">
                                                    <Card.Text>
                                                        <Image
                                                            className="img-responsive"
                                                            src={postvalue[0].igimage != "" ? postvalue[0].igimage : instaimage}
                                                            // alt="Second slide"
                                                            style={{
                                                                width: "100%",
                                                                objectFit: "contain",
                                                                backgroundColor: "white",
                                                                maxHeight: "30vh",
                                                                borderBottom:
                                                                    "1px solid var(--bs-border-color-translucent)",
                                                                // borderRadius: "0.5em",
                                                            }}
                                                        />
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer style={{ backgroundColor: "white", border: "white", paddingTop: '0em' }}>
                                                    <Flex
                                                        direction={{ base: "row", large: "row" }}
                                                        gap={{ base: "1em", medium: "1em", large: "1em" }}
                                                        width={{ base: "50%", medium: "50%", large: "50%" }}
                                                    >
                                                        <View
                                                            width={{
                                                                base: "100%",
                                                                medium: "100%",
                                                                large: "100%",
                                                            }}
                                                        >
                                                            <Icon
                                                                as={FavoriteBorderOutlinedIcon}
                                                                fontSize={{ base: "small", large: "xl" }}
                                                                marginRight="0.4em"
                                                                fontWeight="500"
                                                            ></Icon>{" "}
                                                            <Icon
                                                                as={BsChat}
                                                                fontSize={{ base: "large", large: "xxl" }}
                                                                marginTop={{ base: "5px", large: "10px" }}
                                                            ></Icon>{" "}
                                                            <Icon
                                                                as={BiPaperPlane}
                                                                fontSize={{ base: "small", large: "xl" }}
                                                                marginRight="0.3em"
                                                            ></Icon>{" "}
                                                        </View>
                                                    </Flex>
                                                    <Flex>
                                                        <View
                                                            width={{
                                                                base: "100%",
                                                                medium: "100%",
                                                                large: "100%",
                                                            }}
                                                        >
                                                            <Text fontSize={{ base: "0.7em", large: "1em" }} fontWeight="bold">Web Design Spot <span style={{ marginLeft: "1em", fontSize: "0.88em" }}>{postvalue[0].igmsg}</span></Text>

                                                        </View>
                                                    </Flex>
                                                </Card.Footer>
                                            </Card>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction="row"
                                        marginTop={{ base: "xl", large: "xxl" }}
                                        justifyContent="flex-end"
                                    >
                                          {(postvalue[0]["isfbpost"] && postvalue[0]["isigpost"])? (
                                        <Button
                                            backgroundColor="#202A44"
                                            style={primaryButton}
                                            boxShadow="large"
                                            borderRadius="2em"
                                        // onClick={Redirectsaveandpublish}
                                        onClick={SaveBothPostdetails}
                                        isLoading={saveimagefb}
                                        loadingText="processing..."
                                        disabled={DisableFieldsAfterpublished}
                                        >
                                            Next{" "}
                                            <Icon
                                                as={HiChevronDoubleRight}
                                                fontSize={{ large: "xl" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                                marginTop={"0.2em"}
                                            ></Icon>
                                        </Button>):null}
                                        {(!postvalue[0]["isfbpost"] && postvalue[0]["isigpost"])?(
                                        <Button
                                            backgroundColor="#202A44"
                                            style={primaryButton}
                                            boxShadow="large"
                                            borderRadius="2em"
                                        // onClick={Redirectsaveandpublish}
                                        onClick={CreateFeedForinsta}
                                        isLoading={saveimagefb}
                                        loadingText="processing..."
                                        disabled={DisableFieldsAfterpublished}
                                        >
                                            Next{" "}
                                            <Icon
                                                as={HiChevronDoubleRight}
                                                fontSize={{ large: "xl" }}
                                                color="white"
                                                fontWeight={"bold"}
                                                marginLeft="0.3em"
                                                marginTop={"0.2em"}
                                            ></Icon>
                                        </Button>):null}
                                    </Flex>
                                </View>
                            </Flex>

                        </TabItem>) : null}
                    </Tabs>
                </ThemeProvider>

            </Flex>
            <Modal size="xl" show={ShowContentLibrary} onHide={handleClosead}>
        <Modal.Header
          style={{
            border: "none",
            padding: "2em",
            paddingBottom: "0em",
            display: "flow-root",
          }}
        >
          <Flex direction={"row"} gap="xxxl" justifyContent="space-between">
            <View width="50%">
              <Text style={{ fontSize: "1.3em", fontWeight: "600" }}>
                Content Library
              </Text>
            </View>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          {/* <MediaCollection
              setUrl={setImage}
              set_localimg={set_localimg}
            ></MediaCollection> */}

          <Collection
            type="grid"
            isSearchable={true}
            isPaginated={true}
            searchPlaceholder="Search..."
            itemsPerPage={12}
            templateColumns={{
              base: "1fr 1fr",
              small: "1fr 1fr 1fr",
              medium: "1fr 1fr 1fr",
              large: "1fr 1fr 1fr 1fr ",
              xl: "1fr 1fr 1fr 1fr 1fr 1fr",
              xxl: "1fr 1fr 1fr 1fr 1fr 1fr",
            }}
            autoFlow="row"
            marginLeft={{
              base: "0em",
              small: "0em",
              medium: "0em",
              large: "0em",
              xxl: "0em",
            }}
            alignItems="center"
            justifyContent="center"
            className="collectionad"
            items={ListOfContentLibrary.sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt;
            }).reverse()} // items={listOfFile}
          >
            {(image, index) => (
              <Flex
                key={index}
                className="onfocus"
                gap="10px"
                direction="column"
                width={{
                  base: "150px",
                  medium: "140px",
                  small: "135px",
                  large: "170px",
                  xl: "165px",
                  xxl: "170px",
                }}
                height={{
                  base: "155px",
                  medium: "170px",
                  small: "180px",
                  large: "190px",
                  xl: "190px",
                  xxl: "190px",
                }}
                justifyContent="flex-start"
                alignItems="flex-start"
                position="relative"
                borderRadius="5px"
                padding="8px 7px 8px 7px"
                boxShadow={"large"}
                marginTop={"1em"}
                marginBottom={"1em"}
                marginLeft={"1em"}
                backgroundColor="rgba(255,255,255,1)"
                onClick={handler}
              >
                <Flex
                  gap="0px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                >
                  <Flex
                    gap="10px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    borderRadius="5px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(56, 53, 53, 0.06)"
                  >
                    <Image
                      src={image.media_url}
                      width="unset"
                      height={{
                        base: "110px",
                        medium: "120px",
                        small: "130px",
                        large: "140px",
                      }}
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      borderRadius="5px"
                      alt="image"
                      padding="0px 0px 0px 0px"
                      objectFit="contain"
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Flex>
                  <Flex
                    gap="1px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                  >
                    <Flex
                      gap="10px"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="10px 10px 10px 10px"
                    >
                      {" "}
                      {image.media_name.length > 15 ? (
                        <Text
                          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                          fontSize={{
                            base: "10px",
                            medium: "10px",
                            small: "10px",
                            large: "12px",
                            xxl: "15px",
                          }}
                          fontWeight="500"
                          color="rgba(0,0,0,1)"
                          lineHeight="14px"
                          textAlign="left"
                          display="block"
                          direction="column"
                          justifyContent="unset"
                          width="unset"
                          height="unset"
                          gap="unset"
                          alignItems="unset"
                          grow="1"
                          shrink="1"
                          basis="0"
                          position="relative"
                          padding="0px 0px 0px 0px"
                          whiteSpace="pre-wrap"
                          textTransform="capitalize"
                        >
                          {image.media_name.substring(0, 12)}...
                        </Text>
                      ) : (
                        <Text
                          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                          fontSize={{
                            base: "10px",
                            medium: "10px",
                            small: "10px",
                            large: "12px",
                            xxl: "15px",
                          }}
                          fontWeight="500"
                          color="rgba(0,0,0,1)"
                          lineHeight="14px"
                          textAlign="left"
                          display="block"
                          direction="column"
                          justifyContent="unset"
                          width="unset"
                          height="unset"
                          textTransform="capitalize"
                          gap="unset"
                          alignItems="unset"
                          grow="1"
                          shrink="1"
                          basis="0"
                          position="relative"
                          padding="0px 0px 0px 0px"
                          whiteSpace="pre-wrap"
                        >
                          {image.media_name}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Collection>
          {/* <Button variant="secondary" onClick={handleClose}>
  Close
</Button> */}
          <Flex
            direction="column"
            alignItems="center"
            style={{ marginTop: "1em" }}
            marginBottom={{ large: "relative.large" }}
          >
            <Button
              style={primaryButton}
              boxShadow="large"
              onClick={handleClosead}
            >
              Insert{" "}
              <Icon
                as={BsHandIndexThumb}
                fontSize={{ large: "large" }}
                color="white"
                fontWeight={"bold"}
                marginTop="0.4em"
                marginLeft="0.3em"
              ></Icon>
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>


        </>
    );
}

export default Post;