import React, { useEffect, useState, CSSProperties } from "react";
import DashboardHeader from "../../layout/DashboardHeader";
import { Expander, ExpanderItem, Flex } from "@aws-amplify/ui-react";
import { ImPencil } from "react-icons/im";
import Table from "react-bootstrap/Table";
import { useRef } from "react";
import {
  Icon,
  Image,
  SelectField,
  SearchField,
  ScrollView,
  FieldGroupIcon,
  FieldGroupIconButton,
} from "@aws-amplify/ui-react";
import { Storage } from "@aws-amplify/storage";
import { RiDeleteBin5Line, RiDraftLine } from "react-icons/ri";
import { VscOpenPreview } from "react-icons/vsc";
import {
  Button,
  ButtonGroup,
  Grid,
  View,
  useTheme,
  Link,
  TextField,
  Text,
  Divider,
} from "@aws-amplify/ui-react";
import { BiUpload, BiUserCheck } from "react-icons/bi";
import {
  BsHandThumbsUp,
  BsHandIndexThumb,
  BsArrowsExpand,
} from "react-icons/bs";
import { GiExpandedRays } from "react-icons/gi";
import Modal from "react-bootstrap/Modal";
import {
  getFBLoginInfo,
  listFBLoginInfos,
  listConfigItems,
  listAdCampaigns,
  getAdStatus,
} from "../../graphql/queries";
import { Form } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import { Carousel, Card, Stack } from "react-bootstrap";
// import Button from 'react-bootstrap/Button';
import { ToggleButton, ToggleButtonGroup } from "@aws-amplify/ui-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MapView } from "@aws-amplify/ui-react";
// import TimePicker from 'react-time-picker';
// import DatePicker from 'react-date-picker';
import OutlinedInput from "@mui/material/OutlinedInput";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Tabs, TabItem, ThemeProvider, Theme } from "@aws-amplify/ui-react";
import axios from "axios";
import { API, graphqlOperation} from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import { fontSize } from "@mui/system";
import HashLoader from "react-spinners/HashLoader";
import LoadingOverlay from "react-loading-overlay";
import { InfinitySpin } from "react-loader-spinner";

import "../../App.css";
import Multiselect from "multiselect-react-dropdown";
import "../managecontent/managestyle.css";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { SwitchField } from "@aws-amplify/ui-react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  listOrganizationInfos,
  listUserInfos,
  listAdStatuses,
} from "../../graphql/queries";
import { Navigate, useNavigate } from "react-router-dom";
import { datagridSx } from "./Gridpopups";
import Campaign from "./Campaign";
import Adset from "./Adset";
import Ad from "./Ad";
import AdPreview from "./AdPreview";
import { alertTitleClasses, styled } from "@mui/material";
import "./managestyle.css";
import { listAdDetails, getAdCampaign } from "../../graphql/queries";
import { useLocation } from "react-router-dom";
import { primaryButton, secondaryButton, ResetIcon } from "../../assets/style";
import { updateAdDetails,updateAdHistory } from "../../graphql/mutations";
import { Auth } from 'aws-amplify'

const ManageContent = () => {

  const [logged, setlogged] = useState(true)

  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  const [camSelected, setCamSelected] = useState();
  //const[placementsState,setplacementState]=useState(placementnewarray)
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "white",
  };

  const [CformState, setCformState] = useState({
    campaign_name: "",
    objective: "",
    special_ad_category: "",
  });
  const [CreatedCampaignVersion, setCreatedCampaignVersion] = useState();
  const [placementnewarray, setPlacementnewarray] = useState([]);
  const location = useLocation();
  const { MainDiv, setMainDiv } = useState("");
  const [redirectCampaingnid, setredirectCampaingnid] = useState();
  const [company_id, setcompany_id] = useState("");
  const [facebook_userid, setfacebook_userid] = useState("");
  const [adsaccount, setadsaccount] = useState("");
  const [foradminpublish_Ad_id, setforadminpublish_Ad_id] = useState();
  const [foradminpublish_Ad_Version, setforadminpublish_Ad_Version] =
    useState();
  const [long_access_token, setlong_access_token] = useState("");
  const [listCampaign, setlistCampaign] = useState();
  const [FbAddId, set_FbAddId] = useState();
  const [listAdd, setlistAdd] = useState();
  const [staticlist, setstaticlist] = useState();
  const [listofCategories, setlistofCategories] = useState([]);
  const [listofObjectives, setlistofObjectives] = useState([]);
  const [listofBudget, setlistofBudget] = useState([]);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  let [loader, setloader] = useState(false);
  const [FbPageId, setFbPageId] = useState();
  const [listofConversionLocation, setlistofConversionLocation] = useState();
  const [listofConversionEvent, setlistofConversionEvent] = useState();
  const [placement, setplacement] = useState();
  const [AgeFromandTo, setAgeFromandTo] = useState();
  const [PreviewFormat, setPreviewFormat] = useState();
  const[AdsetVersionforUpdate,setAdsetVersionforUpdate]=useState()
  const [IdFromSelectedCapaignFromGrid, set_IdFromSelectedCapaignFromGrid] =
    useState();
  const [FlagVariable, set_FlagVariable] = useState(false);
  const[forExistingCampain,setforExistingCampain]=useState(false)
  const [show, setShow] = useState(false);
  const [campidFromSave, set_campidFromSave] = useState();
  const [editCampain, seteditCampain] = useState();
  const [editAdset, seteditAdset] = useState();
  const [editAd, seteditAd] = useState();
  const [adsetidfromsave, setadsetidfromsave] = useState();
  const [adidfromsave, setadidfromsave] = useState();
  const [UpdtAdsetId, setUpdtAdsetId] = useState();

  const [DisableAfterPublish,setDisableAfterPublish]=useState(false)

  //Choose Existing Campaign
  const handleClose = () => {
    setShow(false);
  }
  const SaveCampaign = () => {
    setforExistingCampain(true);
    handleClose();
  }

  // const [campidFromSave, set_campidFromSave] = useState();
  const [fbcampaignid, set_fbcampaignid] = useState();
  const [adset_id, setadset_id] = useState();
  const [role, setrole] = useState(localStorage.getItem("role_id"));

  ////disable button state variable
  const [campaign_page, setcampaign_page] = useState(false);
  const [adset_page, setadset_page] = useState(false);
  const [ad_page, setad_page] = useState(false);
  const [AdHistoryID, setAdHistoryID] = useState();
  const [UpdtAdDetailsId, setUpdtAdDetailsId] = useState();
  const [Version_from_Addetails, setVersion_from_Addetails] = useState();
  const [UpdtAdDetailsVersion, setUpdtAdDetailsVersion] = useState();
  const [updtAdHistoryID,setupdtAdHistoryID]=useState();
  const [updtAdHistoryVersion,setupdtAdHistoryVersion]=useState();
  const [AdName, setAdName] = useState();
  const [Conversion_checked_or_not, setConversion_checked_or_not] = useState();
  const [manageadconnect, setmanageadconnect] = useState(false);
  const [CreateAdName, setCreateAdName] = useState();
  //const [savepublish_page, setsavepublish_page]=useState();

  const [AssignEdit, setAssign] = useState();

  const [AdsetformStatepage, setAdsetformStatepage] = useState({
    campaign_id: "",
    adset_name: "",
    adset_id: "",
    budget_type: "",
    budget_amount: "",
    schedule_start_date: "",
    schedule_start_time: "",
    schedule_end_date: "",
    schedule_end_time: "",
    conversion_location: "",
    conversion_events: "",
    placements: "",
    custom_audience: "",
    exclude_custom_audience: "",
    age_from: "",
    age_to: "",
    ads_account_id: "",
    gender: "",
    demographics: "",
    interests: "",
    behaviors: "",
    location: "",
    language: "",
  });
  const [selectedOptionscustom_audience, setSelectedOptionscustom_audience] =
    useState([]);
  const [select_intrests, setselect_intrests] = useState([]);
  const [select_location, setselect_location] = useState([]);
  const [select_behaviour, setselect_behaviour] = useState([]);
  const [select_exclude_audience, setselectexclude_audience] = useState([]);
  const [select_demographics, setselect_demographics] = useState([]);
  const [select_placement, setselect_placement] = useState();
  const [checkedfb, setcheckedfb] = useState(false);
  const [checkedinsta, setcheckedinsta] = useState(false);
  const setspecialAdcatselect = "";

 //ADPAGE
  const [AddformStatePage, setAddformStatePage] = useState({
    ad_name: "",
    ad_primary_text: "",
    media_type: "",
    media_file_name: "",
    media_file_path: "",
    ad_description: "",
    ad_headline: "",
    ad_website_url: "",
    ad_see_more_url: "",
    ad_see_more_display: "",
    call_to_action: "",
    insta_selected_account: "",
    insta_feed: "",
    ad_campaign_id: "",
    ad_account_id: "",
    adset_id: "",
    user_id: "",
    ad_status: "",
  });
 const [multimedia_urlAD, Setmultimedia_urlAD] = useState();//adpage image&video



  useEffect(() => {
    setmanageadconnect(false);
    console.log(placementnewarray, "check");
    getUserInfo();
    getAdStatusInfo();
    fetchfblogin();
    FetchConfigItems(); //Fetching data for Dropdown fields
    setcompany_id(localStorage.getItem("companyinfoID"));
    console.log(company_id);
    if (location.state != undefined) {
      setredirectCampaingnid(location.state.id);
      setAssign(location.state.Assigned_user);
      console.log("location.state", location.state);
      EditAd(location.state.id,location.state.key);
      setcampaign_page(true);
      setadset_page(true);

      if(location.state.key=="disabled"){
        setDisableAfterPublish(true);
      }
      setad_page(true);
    }
  }, [location.state]);

  //Video url token regeneration function
  const[PreUrl,setPreUrl]=useState();

  //change Adstate to Draft for Content currator while Editing
  const changeAdStateToDraft=async(adid,adversion,adhistoryid,adhistoryversion)=>{
    const Adstate = {
        id: adid,
        ad_status: "6",
        _version: adversion,
      }

      await API.graphql(
        graphqlOperation(updateAdDetails, { input: Adstate })
      ).then(async()=>{
        const AdhistoryState = {
          id: adhistoryid,
          ad_status_id: "6",
          assigned_by_userid: localStorage.getItem("userid"),
          assigned_to_userid: localStorage.getItem("userid"),
          _version: adhistoryversion,
        }
       await API.graphql(
          graphqlOperation(updateAdHistory, { input: AdhistoryState })
        )

      }).catch((err)=>{
        console.log(err,"changing state to draft for content curartor while clicking Edit button");
      }
      )
  }


  const getPreSignedURLaws = async (user_id,name) => {
   // alert(name + " :key")

    const listuser = await API.graphql(graphqlOperation(listUserInfos));
    console.log(listuser.data.listUserInfos.items, "forgetAlluser");
    listuser.data.listUserInfos.items
      .filter((x) => x._deleted !== true || x._deleted == null)
      .map(async(y) => {
        if (y.id == user_id) {
          console.log(y,"Looop");
          const NewsignedURL = await Storage.get(name, {
            expires: 100000,
            level: "protected",
            identityId: y.cognito_identity_id,
            customPrefix: { protected: "protected/" + y.cognito_identity_id + "/video/" },
          });
          console.log(NewsignedURL,"S3URLCHECK")
          setTimeout(()=>{
            setPreUrl(NewsignedURL)
          },1000)


        }
      });
  };

  const [State_of_Ad, setState_of_Ad] = useState();
  const [Assigned_Users, setAssigned_Users] = useState();

  //Getting Approvers(users) from DB
  const getUserInfo = async () => {
    let filter = {
      and: {
        companyinfoID: { eq: localStorage.getItem("companyinfoID") },
      },
    };
    const ListUsrInfo = await API.graphql({
      query: listUserInfos,
      variables: { limit: 10000, filter: filter },
    });
    setAssigned_Users(
      ListUsrInfo.data.listUserInfos.items.filter(
        (item) => item._deleted !== true || item._deleted == null
      )
    );
  };

  //Getting Ad Status from DB
  const getAdStatusInfo = async () => {
    const getStatus = await API.graphql({ query: listAdStatuses });
    setState_of_Ad(getStatus.data.listAdStatuses.items);
  };
  const EditAd = async (id,key) => {
    setCformState({
      campaign_name:"",
      objective:"",
      special_ad_category:""
    })
    set_FlagVariable(true);
    const AllData = await API.graphql(
      graphqlOperation(getAdCampaign, { id: id })
    );
    console.log(AllData.data.getAdCampaign,"DatafromDashBoardEdit")
    seteditCampain(AllData.data.getAdCampaign);

    if(key =="Editableforcc"){
      var adhistorydatalength=AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].AdHistories.items.length-1;
      console.log(adhistorydatalength,"ADHISTORYLENGTH")
      changeAdStateToDraft(AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].id,AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0]._version,AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].AdHistories.items[adhistorydatalength].id,AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].AdHistories.items[adhistorydatalength]._version);
    }

    setCformState({
      ...CformState,
      campaign_name: AllData.data.getAdCampaign.campaign_name,
      objective: AllData.data.getAdCampaign.objective,
      special_ad_category: AllData.data.getAdCampaign.special_ad_category,
    });
    seteditAdset(AllData.data.getAdCampaign.AdSets.items[0]);
    setUpdtAdsetId(AllData.data.getAdCampaign.AdSets.items[0].id);
    seteditAd(AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0]);
    setPlacementnewarray(AllData.data.getAdCampaign.AdSets.items[0].placements)
    console.log(AllData.data.getAdCampaign.AdSets.items[0], "forMedia");
    if (
      AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].media_file_path.includes(
        "video"
      )
    ) {
      getPreSignedURLaws(
        AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].user_id,AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].media_file_name
      );
    }

    setUpdtAdDetailsId(
      AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].fb_ad_id
    );
    setforadminpublish_Ad_id(
      AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].id
    );

    setforadminpublish_Ad_Version(
      AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0]._version
    );
    setUpdtAdDetailsVersion(
      AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0]._version
    );

    //  setupdtAdHistoryID(AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].AdHistory.items[0].id)
    //   setupdtAdHistoryVersion(AllData.data.getAdCampaign.AdSets.items[0].AdDetails.items[0].AdHistory.items[0]._version)


  };

  useEffect(() => {
    console.log(editAd, 'editAd');
  }, [editAd])

  ///to navigate social account
  const navigate = useNavigate();

  const connectsocialacc = () => {
    if(localStorage.getItem("role_id") != "2"&& localStorage.getItem("role_id") != "5"&& localStorage.getItem("role_id")!=4  )
    { //alert("o")
    navigate("/account", {
      state: { From: "dashboard", tabindex: "0", key: "managesetting" },
 });
  }
  else{
    toast.error("Please contact your admin");
  }

 };

  const columns = [
    {
      field: "campaign_name",
      flex: 1,
      minwidth: 20,
      headerName: "Campaign Name",
      align: "left",

      renderCell: (params) => {
        return (
          <Button
            color={"black"}
            size="small"
            width={"200%"}
            justifyContent={"left"}
            border={"transparent"}
            boxShadow={"none"}
            backgroundColor="transparent"
            onClick={() => Existingcampaignlist(params.row.id)}
            //onClick={() => Existingcampaignlist(params.row.rows)}
          >
            {params.value}
          </Button>
        );
      },
    },

    // {
    //   field: "edit",
    //   flex: 1,
    //   headerName: "Edit",
    //   minwidth: 150,
    //   align: "center",
    //   editable: true,
    //   renderCell: (params) => {
    //     return (
    //       <Icon
    //         as={ImPencil}
    //         fontSize={{ large: "large" }}
    //         marginTop={{ large: "relative.xxs" }}
    //         onClick={() => Existingcampaignlist(params.row.name)}
    //         color="red"
    //       ></Icon>
    //     );
    //   },
    // },
  ];

  //Existing Campaign Click event
  const Existingcampaignlist = (e) => {
    console.log(e, 'CampSel');
    set_IdFromSelectedCapaignFromGrid(e);
    // getCampaigndeatils();
  };
  ///searching existing campaign name
  const handleChanges = (value) => {
    console.log(staticlist, "test");
    var filteredItems = staticlist.filter((item, i) => {
      if (value == "") {
        return item;
      } else {
        return item.campaign_name.toLowerCase().includes(value.toLowerCase());
      }
    });
    console.log(filteredItems, "filterCamp");
    setlistCampaign(filteredItems);
  };

  const fetchfblogin = async () => {
    console.log(localStorage.getItem("companyinfoID"), "comid");
    try {
      let filter = {
        company_id: {
          eq: localStorage.getItem("companyinfoID"), // filter priority = 1  get location.state/id or
        },
      };

      //   alert("CID : " + company_id);
      const fbdata = await API.graphql({
        query: listFBLoginInfos,
        variables: { filter: filter },
      });

      console.log(fbdata.data.listFBLoginInfos.items.length);

      // alert("g")

      if (
        fbdata.data.listFBLoginInfos.items.filter(
          (item) => item._deleted == null || item._deleted !== true
        ).length > 0
      ) {
        // alert("yes it is");
        let d = fbdata.data.listFBLoginInfos.items.filter(
          (item) => item._deleted == null || item._deleted !== true
        )[0];
        setfacebook_userid(d.facebook_userid);

        setadsaccount(d.ads_accountid);

        setlong_access_token(d.long_access_token);
        setFbPageId(d.pageid);
        console.log(facebook_userid);

        //for show socialaccount connect
        setmanageadconnect(false);
        setShowvalue1(true);
        // alert("Facebook Login Success");
        // -----------------------------------------------------------------------------------AdsetCalls--------------------------------------------------------------------------------------------------------------
        // GetAdset();
        //  CreateAdset();
        // --------------------------------------------------------------------------------------END--------------------------------------------------------------------------------------------------------------
      } else {
        // alert("k")
        setShowvalue1(false);
        setmanageadconnect(true);
      }
    } catch (err) {
      // alert("not it is");
      console.log("error fetching actors" + err);
    }
  };
  //Fetching the fulllist from the getCampaign model
  const getCampaignlist = async () => {
    try {
      const getCampaignlist = await API.graphql({
        query: listAdCampaigns,
      });
      console.log(getCampaignlist.data.listAdDetails.items);
      let camplist = getCampaignlist.data.listAdDetails.items;
      setlistCampaign(camplist);
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };
  const getCampaign_old = async () => {
    // alert("yes");
    // axios({
    //   method: "get",

    //   url:
    //     "https://graph.facebook.com/v15.0/" +
    //     adsaccount +
    //     "/campaigns?fields=id,name&access_token=EAAEjGX93nKQBAGSxIrvAltbcd45t1ScB9ZAtnvGb69DCDQaBLLRRaWzY2qjRcJMbZBT1NPL2I3doWwP2NsMrC6DIpLFkAQ9govYwhylWAUm06jo9hxOrLKqdnmNOJqIIMUfyMBUx8Kx9TQ8RHWMIiEYNeuFFCw1Da9C3VR4Qfh7oiPahmQ",
    //   responseType: "stream",
    // }).then(function (responsef) {
    //   console.log("camp", responsef.data.data);
    //   let camplist = responsef.data.data;
    let filter = {
      companyinfoID: { eq: localStorage.getItem("companyinfoID") },
    };
    try {
      const getCampaignlist = await API.graphql({
        query: listAdCampaigns,
        variables: { filter: filter },
      });
      console.log(getCampaignlist.data.listAdCampaigns.items);
      let camplist = getCampaignlist.data.listAdCampaigns.items;
      setlistCampaign(getCampaignlist.data.listAdCampaigns.items);
      setstaticlist(getCampaignlist.data.listAdCampaigns.items);

      console.log(listCampaign, "success");
      console.log("listCampaignget", getCampaignlist.data.listAdCampaigns.items);

      setShow(true);
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };


  const getCampaign = async () => {
   
    const filter = {
      companyinfoID: { eq: localStorage.getItem("companyinfoID") },
    };
  
    // alert("Fetching campaigns...");
  
    try {
      // Fetch campaign list from the GraphQL API
      const getCampaignlist = await API.graphql({
        query: listAdCampaigns,
        variables: { filter: filter },
      });
  
      // Get the local list of campaigns
      const camplist = getCampaignlist.data.listAdCampaigns.items;
  
      // Fetch campaign details from the Facebook Graph API
      axios.get(`https://graph.facebook.com/v15.0/${adsaccount}/campaigns`, {
        params: {
          access_token: long_access_token,
          limit: 100,
          fields: "name,configured_status,effective_status"
        }
      })
      .then(function (response) {
       
        const fbCampaigns = response.data.data;
  
        // Filter local campaigns based on matching Facebook campaign IDs
        const matchingCampaigns = camplist.filter(campaign => {
          return fbCampaigns.some(fbCampaign => fbCampaign.id === campaign.fb_campaign_id);
        });
  
        // Update the state with the filtered campaigns
        setlistCampaign(matchingCampaigns);
        setstaticlist(matchingCampaigns);
  
        console.log("Matching campaigns:", matchingCampaigns);
        console.log("List of campaigns:", listCampaign, "success");
        console.log("List of campaigns (filtered):", matchingCampaigns);
  
        setShow(true);
      })
      .catch(function (error) {
        // Handle errors
        console.error("Error fetching campaign details:", error);
        
      });
    } catch (err) {
      // Handle errors
      console.error("Error fetching campaigns:", err);
      
    }
  };
  
  






  //fetch Objective
  const FetchConfigItems = async () => {
    try {
      setloader(true);
      const ConfigItems = await API.graphql(graphqlOperation(listConfigItems));
      // console.log(ConfigItems.data.listConfigItems.items, "configs");

      setlistofCategories(
        JSON.parse(
          ConfigItems.data.listConfigItems.items[0].campaign_categories
        ).values
      );
      setlistofObjectives(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].campaign_objective)
          .values
      );
      setlistofBudget(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].adset_budget)
          .values
      );
      setlistofConversionLocation(
        JSON.parse(
          ConfigItems.data.listConfigItems.items[0].conversion_location
        )
      );
      setlistofConversionEvent(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].conversion_event)
          .values
      );
      setplacement(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].placemen).values
      );
      setAgeFromandTo(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].age_from_and_to)
          .values
      );
      setPreviewFormat(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].preview_format)
          .values
      );
      console.log(
        JSON.parse(ConfigItems.data.listConfigItems.items[0].preview_format)
          .values,
        "preview_format"
      );
      setTimeout(() => {
        setloader(false);
      }, 3000);
    } catch (err) {
      // alert("hi");
      console.log("error fetching actors");
    }
  };

  const { tokens } = useTheme();
  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const [showvalue1, setShowvalue1] = useState(true);
  const [showvalue2, setShowvalue2] = useState(false);
  const [showvalue3, setShowvalue3] = useState(false);
  const [showvalue4, setShowvalue4] = useState(false);
  const [showvalue5, setShowvalue5] = useState(false);

  //Choose from Existing Campaign Click Event
  // const handleShow = () => {
  //   getCampaign();
  // };

  const Redirect_to_Campaign = () => {
    setShowvalue1(!showvalue1);
    setShowvalue2(false);
    setShowvalue3(false);
    setShowvalue4(false);
  };
  const Redirect_to_Adset = () => {
    setShowvalue2(!showvalue2);
    setShowvalue1(false);
    setShowvalue3(false);
    setShowvalue4(false);
  };
  const Redirect_to_Ad = () => {
    setShowvalue3(!showvalue3);
    setShowvalue1(false);
    setShowvalue2(false);
    setShowvalue4(false);
  };
  const Redirect_to_SaveandPublish = () => {
    setShowvalue4(!showvalue4);
    setShowvalue1(false);
    setShowvalue2(false);
    setShowvalue3(false);
  };
  const messagesEndRef = useRef();
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1500);
  };
  const Redirect_to_Preview = () => {
    setShowvalue5(true);
    setShowadset(true);
    scrollToBottom();
  };

  const [showadset, setShowadset] = useState(false);
  const handleCloseadset = () => setShowadset(false);

  // //setShowvalue5(false)
  // setTimeout(()=>{
  //   alert(showadset);
  // },1000)

  // }
  const handleShowadset = () => {};

  return (
    <>
    <ToastContainer autoClose={1500} limit={0} />
    <div>
      {loader  ? (
        <LoadingOverlay
          className="loadoverlay"
          active={loader}
          spinner={
            <HashLoader
              color={"white"}
              className="loadoverlay1"
              loading={true}
              cssOverride={override}
              size={80}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          }
        >
          <Flex direction={"row"}>
            <View width="100%">
              <DashboardHeader
                db={false}
                mc={true}
                cl={false}
                mp={false}
                ndb={false}
              ></DashboardHeader>
              <Flex
                hidden={!manageadconnect}
                justifyContent="center"
                padding={{
                  base: "1em",
                  small: "0em",
                  medium: "0em",
                  large: "0em",
                }}
                paddingTop={{
                  base: "0em",
                  small: "2em",
                  medium: "2.2em",
                  large: "2em",
                }}
              >
                {/* <div>
          <p  fontSize="30px" >Please Connect Your</p>
          <span  onClick={connectsocialacc} style={{cursor:'pointer' , color:"red"   }}  fontSize="30px" > SocialAccount! </span>
          </div> */}
                <Text
                  style={{
                    fontWeight: "400",
                    marginTop: "1em",
                    color: "#4267B2",
                  }}
                >
                  Connect Your {""} &nbsp;
                  <Text
                    class="flash"
                    textDecoration="none"
                    onClick={connectsocialacc}
                    style={{
                      cursor: "pointer",
                    }}
                    as="span"
                    fontSize="1rem"
                    color="#4267B2"
                  >
                    <u> SocialAccount </u>{" "}
                  </Text>
                  <Text as="span" fontSize="1rem" color="#4267B2">
                    {" "}
                    &nbsp; For Ads Management
                  </Text>
                </Text>
              </Flex>

              <ButtonGroup
                direction={{ base: "column", large: "column" }}
                margin={{ base: "large", large: "xxl" }}
                style={{ marginTop: "2em", color: "#fff" }}
                variant="primary"
              >
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_Campaign}
                  className="btngrp"
                  disabled={manageadconnect}
                >
                  Campaign
                </Button>
                {showvalue1 == true ? (
                  <Campaign
                    editCampainvalue={editCampain}
                    setConversion_checked_or_not={setConversion_checked_or_not}
                    getCampaignFunction={getCampaign}
                    SET_FB_CAMPAIGN_ID={set_fbcampaignid}
                    FB_CAMPAIGN_ID={fbcampaignid}
                    SET_CAMPID_FRM_DB={set_campidFromSave}
                    CAMPID_FRM_DB={campidFromSave}
                    adsAccountId={adsaccount}
                    accessToken={long_access_token}
                    objectivesList={listofObjectives}
                    categoryList={listofCategories}
                    campaignForm={CformState}
                    setCampaignForm={setCformState}
                    RedirectAdset={Redirect_to_Adset}
                    selectedCampaignname={IdFromSelectedCapaignFromGrid}
                    flagforshowselectedcampaign={FlagVariable}
                    setflagorshowselectedcampaign={set_FlagVariable}
                    campaign_pagesavestatus={campaign_page}
                    setcampaign_pagesavestatus={setcampaign_page}
                    JustCreatedCampaignVersion ={CreatedCampaignVersion}
                    setJustCreatedCampaignVersion={setCreatedCampaignVersion}
                  ></Campaign>
                ) : null}
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_Adset}
                  className="btngrp"
                  disabled={!campaign_page}
                >
                  Ad set
                </Button>
                {showvalue2 == true ? (
                  <Adset
                    editAdsetValue={editAdset}
                    Conversion_check={Conversion_checked_or_not}
                    fun={getCampaign}
                    adsetage={AgeFromandTo}
                    FB_CAMPAIGN_ID={fbcampaignid}
                    CAMPID_FRM_DB={campidFromSave}
                    ADSET_ID ={adset_id}
                    SET_ADSET_ID={setadset_id}
                    SET_ADSETID_FRM_DB={setadsetidfromsave}
                    ADSETID_FRM_DB={adsetidfromsave}
                    listofplacement={placementnewarray}
                    PageIdFromFbLogin={FbPageId}
                    accessToken={long_access_token}
                    adsAccountId={adsaccount}
                    adsetConversionLocation={listofConversionLocation}
                    adsetConversionEvent={listofConversionEvent}
                    adsetplacement={placement}
                    campaignForm={CformState}
                    setcampaignForm={setCformState}
                    adsetBudget={listofBudget}
                    RedirectAd={Redirect_to_Ad}
                    columns={columns}
                    adset_pagestatus={adset_page}
                    setadset_pagestatus={setadset_page}
                    AdsetVersionforUpdate={AdsetVersionforUpdate}
                    setAdsetVersionforUpdate={setAdsetVersionforUpdate}

                  ></Adset>
                ) : null}
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_Ad}
                  className="btngrp"
                  disabled={!adset_page}
                >
                  Ad
                </Button>
                {showvalue3 == true ? (
                  <Ad
                    SET_VERSION_FRM_AD={setVersion_from_Addetails}
                    VERSION_FRM_AD={Version_from_Addetails}
                    SETAD_NAME_FRM_ADPAGE={setAdName}
                    fun={getCampaign}
                    UPDT_ADSET_ID={UpdtAdsetId}
                    editAdValues={editAd}
                    Ad_History_ID={AdHistoryID}
                    setAd_History_ID={setAdHistoryID}
                    FB_CAMPAIGN_ID={fbcampaignid}
                    listofplacement={placementnewarray}
                    ADSETID_FRM_DB={adsetidfromsave}
                    ADSET_ID={adset_id}
                    ADID_FRM_DB={adidfromsave}
                    SETADID_FRM_DB={setadidfromsave}
                    adsAccountId={adsaccount}
                    PREVIEW_FORMAT={PreviewFormat}
                    Redirectsaveandpublish={Redirect_to_SaveandPublish}
                    columns={columns}
                    accessToken={long_access_token}
                    PageIdFromFbLogin={FbPageId}
                    setAD_ID={set_FbAddId}
                    AD_ID={FbAddId}
                    ad_pagestatus={ad_page}
                    setad_pagestatus={setad_page}

                  ></Ad>
                ) : null}
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_SaveandPublish}
                  className="btngrp"
                  disabled={!ad_page}
                >
                  Save and Publish
                </Button>
                {showvalue4 == true ? (
                  <Flex
                    direction={{
                      base: "column",
                      small: "column",
                      medium: "column",
                      large: "column",
                      xl: "column",
                      xxl: "column",
                    }}
                    gap="1rem"
                    style={{
                      border: "2px solid #E8E8E8",
                      borderRadius: "0.5em",
                    }}
                  >
                    <Flex
                      direction={{
                        base: "column",
                        small: "row",
                        medium: "row",
                        large: "row",
                      }}
                      justifyContent="center"
                      padding={{
                        base: "1em",
                        small: "0em",
                        medium: "0em",
                        large: "0em",
                      }}
                      paddingTop={{
                        base: "3em",
                        small: "6em",
                        medium: "6em",
                        large: "6em",
                      }}
                      paddingBottom={{
                        base: "3em",
                        small: "6em",
                        medium: "6em",
                        large: "6em",
                      }}
                      gap={{
                        base: "small",
                        small: "small",
                        medium: "small",
                        large: "xl",
                      }}
                    >
                      {/* <Button
                      style={primaryButton}
                      backgroundColor={"#202A44"}
                      boxShadow="large"
                      borderRadius="2em"
                      fontSize={{ base: "small", large: "medium" }}
                    >
                      Preview{" "}
                      <Icon
                        as={VscOpenPreview}
                        fontSize={{ base: "xl", large: "xl" }}
                        color="white"
                        fontWeight={"bold"}
                        marginLeft="0.2em"
                        marginTop={"0.4em"}
                      ></Icon>
                    </Button> */}
                      {showvalue5 ? (
                        <AdPreview
                          version_adcreate={Version_from_Addetails}
                          Version_Frm_MC={UpdtAdDetailsVersion}
                          adstatelist={State_of_Ad}
                          editAdValues={editAd}
                          ADID_FRM_DB={adidfromsave}
                          editAdsetValue={editAdset}
                          ADSET_ID={adset_id}
                          AD_NAME_FRM_ADPAGE={AdName}
                          adsAccountId={adsaccount}
                          adsuserlist={Assigned_Users}
                          UPDATED_ADCREATIVE_ID={UpdtAdDetailsId}
                          PreviewUser={AssignEdit}
                          ADID_FRM_AD={adidfromsave}
                          Ad_History_ID={AdHistoryID}
                          // setAd_History_ID={setAdHistoryID}
                          popuponclick={handleShowadset}
                          show={showadset}
                          handleClose={handleCloseadset}
                          accessToken={long_access_token}
                          PREVIEW_FORMAT={PreviewFormat}
                          AD_ID={FbAddId}


                        ></AdPreview>
                      ) : null}

                      {/* <Button
                      style={primaryButton}
                      boxShadow="large"
                      backgroundColor="grey"
                      borderRadius="2em"
                      fontSize={{ base: "small", large: "medium" }}
                      size={{ base: "xs", large: "large" }}
                    >
                      Save Draft{" "}
                      <Icon
                        as={RiDraftLine}
                        fontSize={{ large: "medium" }}
                        color="white"
                        fontWeight={"bold"}
                        marginLeft="0.3em"
                      ></Icon>
                    </Button> */}
                      {role !== "4" && (
                        <Button
                          style={primaryButton}
                          boxShadow="large"
                          fontSize={{ base: "xs", large: "medium" }}
                          borderRadius="2em"
                          backgroundColor={
                            role === "5" ? "rgb(67 192 189)" : "rgb(104, 2, 80)"
                          }
                          onClick={Redirect_to_Preview}
                        >
                          {role === "5"
                            ? "Submit to Publisher"
                            : "Submit for Approval"}
                          <Icon
                            as={BsHandIndexThumb}
                            fontSize={{ base: "xl", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            marginLeft="0.3em"
                            marginTop={"0.3em"}
                          ></Icon>
                        </Button>
                      )}
                      {(role === "4" || role === "1") && (
                        <Button
                          style={primaryButton}
                          boxShadow="large"
                          // onClick={handleShow}
                          borderRadius="2em"
                          fontSize={{ base: "small", large: "medium" }}
                          backgroundColor="rgb(142, 198, 65)"
                          size={{ base: "xs", large: "small" }}
                          onClick={Redirect_to_Preview}
                        >
                          Preview and Publish{" "}
                          <Icon
                            as={BsHandThumbsUp}
                            fontSize={{ base: "xl", large: "xl" }}
                            xist
                            color="white"
                            fontWeight={"bold"}
                            marginLeft="0.3em"
                            marginTop={"0.3em"}
                          ></Icon>
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                ) : null}
              </ButtonGroup>
            </View>
          </Flex>
        </LoadingOverlay>
      ) : (
        <div className="overflow">
          <Flex direction={"row"}>
            <View width="100%">
              <DashboardHeader
                db={false}
                mc={true}
                cl={false}
                mp={false}
                ndb={false}
              ></DashboardHeader>
              <Flex
                hidden={!manageadconnect}
                justifyContent="center"
                padding={{
                  base: "1em",
                  small: "0em",
                  medium: "0em",
                  large: "0em",
                }}
                paddingTop={{
                  base: "0em",
                  small: "2em",
                  medium: "2.2em",
                  large: "2em",
                }}
              >
                {/* <div>
          <p  fontSize="30px" >Please Connect Your</p>
          <span  onClick={connectsocialacc} style={{cursor:'pointer' , color:"red"   }}  fontSize="30px" > SocialAccount! </span>
          </div> */}
                <Text
                  style={{
                    fontWeight: "400",
                    marginTop: "1em",
                    color: "#4267B2",
                  }}
                >
                  Connect Your {""} &nbsp;
                  <Text
                    class="flash"
                    textDecoration="none"
                    onClick={connectsocialacc}
                    style={{
                      cursor: "pointer",
                    }}
                    as="span"
                    fontSize="1rem"
                    color="#4267B2"
                  >
                    <u> SocialAccount </u> {""}
                  </Text>
                  <Text as="span" fontSize="1rem" color="#4267B2">
                    {" "}
                    {"  "} &nbsp; For Ads Management
                  </Text>
                </Text>
              </Flex>

              <ButtonGroup
                direction={{ base: "column", large: "column" }}
                margin={{ base: "large", large: "xxl" }}
                style={{ marginTop: "2em", color: "#fff" }}
                variant="primary"
              >
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_Campaign}
                  className="btngrp"
                  disabled={manageadconnect}
                >
                  Campaign
                </Button>
                {showvalue1 == true ? (
                  <Campaign
                    DisableAfterPublish={DisableAfterPublish}
                    editCampainvalue={editCampain}
                    getCampaignFunction={getCampaign}
                    SET_FB_CAMPAIGN_ID={set_fbcampaignid}
                    FB_CAMPAIGN_ID={fbcampaignid}
                    SET_CAMPID_FRM_DB={set_campidFromSave}
                    CAMPID_FRM_DB={campidFromSave}
                    adsAccountId={adsaccount}
                    accessToken={long_access_token}
                    objectivesList={listofObjectives}
                    categoryList={listofCategories}
                    campaignForm={CformState}
                    setCampaignForm={setCformState}
                    RedirectAdset={Redirect_to_Adset}
                    selectedCampaignname={IdFromSelectedCapaignFromGrid}
                    flagforshowselectedcampaign={FlagVariable}
                    setflagorshowselectedcampaign={set_FlagVariable}
                    campaign_pagesavestatus={campaign_page}
                    setcampaign_pagesavestatus={setcampaign_page}
                    forNewExistingCampaign={forExistingCampain}
                    setSelectedspecialad={setspecialAdcatselect}
                    JustCreatedCampaignVersion ={CreatedCampaignVersion}
                    setJustCreatedCampaignVersion={setCreatedCampaignVersion}

                  ></Campaign>
                ) : null}
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_Adset}
                  className="btngrp"
                  disabled={!campaign_page}
                >
                  Ad set
                </Button>
                {showvalue2 == true ? (
                  <Adset
                    DisableAfterPublish={DisableAfterPublish}
                    editAdsetValue={editAdset}
                    fun={getCampaign}
                    adsetage={AgeFromandTo}
                    FB_CAMPAIGN_ID={fbcampaignid}
                    CAMPID_FRM_DB={campidFromSave}
                    ADSET_ID ={adset_id}
                    SET_ADSET_ID={setadset_id}
                    SET_ADSETID_FRM_DB={setadsetidfromsave}
                    ADSETID_FRM_DB={adsetidfromsave}
                    SETUPDT_ADSET_ID={setUpdtAdsetId}
                    listofplacement={placementnewarray}
                    setlistofplacement={setPlacementnewarray}
                    PageIdFromFbLogin={FbPageId}
                    accessToken={long_access_token}
                    adsAccountId={adsaccount}
                    adsetConversionLocation={listofConversionLocation}
                    adsetConversionEvent={listofConversionEvent}
                    adsetplacement={placement}
                    campaignForm={CformState}
                    setcampaignForm={setCformState}
                    adsetBudget={listofBudget}
                    RedirectAd={Redirect_to_Ad}
                    columns={columns}
                    adset_pagestatus={adset_page}
                    setadset_pagestatus={setadset_page}
                    AdsetformState={AdsetformStatepage}
                    setAdsetformState={setAdsetformStatepage}
                    selectedOptionscustomaudience={
                      selectedOptionscustom_audience
                    }
                    setSelectedOptionscustomaudience={
                      setSelectedOptionscustom_audience
                    }
                    selectintrests={select_intrests}
                    setselectintrests={setselect_intrests}
                    selectdemographics={select_demographics}
                    setselectdemographics={setselect_demographics}
                    selectexclude_audience={select_exclude_audience}
                    setselectexcludeaudience={setselectexclude_audience}
                    selectlocation={select_location}
                    setselectlocation={setselect_location}
                    selectbehaviour={select_behaviour}
                    setselectbehaviour={setselect_behaviour}
                    selectplacement={select_placement}
                    setselectplacement={setselect_placement}
                    checked1={checkedfb}
                    setchecked1={setcheckedfb}
                    checked2={checkedinsta}
                    setchecked2={setcheckedinsta}
                    setSelectedspecialad={setspecialAdcatselect}
                    AdsetVersionforUpdate={AdsetVersionforUpdate}
                    setAdsetVersionforUpdate={setAdsetVersionforUpdate}
                  ></Adset>
                ) : null}
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_Ad}
                  className="btngrp"
                  disabled={!adset_page}
                >
                  Ad
                </Button>
                {showvalue3 == true ? (
                  <Ad
                    DisableAfterPublish={DisableAfterPublish}
                    SET_VERSION_FRM_AD={setVersion_from_Addetails}
                    SETAD_NAME_FRM_ADPAGE={setAdName}
                    fun={getCampaign}
                    UPDT_ADSET_ID={UpdtAdsetId}
                    editAdValues={editAd}
                    Ad_History_ID={AdHistoryID}
                    setAd_History_ID={setAdHistoryID}
                    FB_CAMPAIGN_ID={fbcampaignid}
                    listofplacement={placementnewarray}
                    ADSETID_FRM_DB={adsetidfromsave}
                    ADSET_ID={adset_id}
                    ADID_FRM_DB={adidfromsave}
                    SETADID_FRM_DB={setadidfromsave}
                    adsAccountId={adsaccount}
                    PREVIEW_FORMAT={PreviewFormat}
                    Redirectsaveandpublish={Redirect_to_SaveandPublish}
                    columns={columns}
                    accessToken={long_access_token}
                    PageIdFromFbLogin={FbPageId}
                    setAD_ID={set_FbAddId}
                    AD_ID={FbAddId}
                    ad_pagestatus={ad_page}
                    setad_pagestatus={setad_page}
                    setforadminad_version={setforadminpublish_Ad_Version}
                    setforadmin_id={setforadminpublish_Ad_id}
                    setSignurl={setPreUrl}
                    signUrl={PreUrl}
                    VideourlfunctionforExistingAd={getPreSignedURLaws}
                    checked2={checkedinsta}
                    setchecked2={setcheckedinsta}
                     AddformState={AddformStatePage}
                     setAddformState={setAddformStatePage}
                     multimedia_url={multimedia_urlAD}
                     Setmultimedia_url={Setmultimedia_urlAD}

                  ></Ad>
                ) : null}
                <Button
                  fontWeight="600"
                  border="none"
                  justifyContent="left"
                  boxShadow="large"
                  color={"white"}
                  backgroundColor="#B71F39"
                  onClick={Redirect_to_SaveandPublish}
                  className="btngrp"
                  disabled={!ad_page}
                >
                  Save and Publish
                </Button>
                {showvalue4 == true ? (
                  <Flex
                    direction={{
                      base: "column",
                      small: "column",
                      medium: "column",
                      large: "column",
                      xl: "column",
                      xxl: "column",
                    }}
                    gap="1rem"
                    style={{
                      border: "2px solid #E8E8E8",
                      borderRadius: "0.5em",
                    }}
                  >
                    <Flex
                      direction={{
                        base: "column",
                        small: "row",
                        medium: "row",
                        large: "row",
                      }}
                      justifyContent="center"
                      padding={{
                        base: "1em",
                        small: "0em",
                        medium: "0em",
                        large: "0em",
                      }}
                      paddingTop={{
                        base: "3em",
                        small: "6em",
                        medium: "6em",
                        large: "6em",
                      }}
                      paddingBottom={{
                        base: "3em",
                        small: "6em",
                        medium: "6em",
                        large: "6em",
                      }}
                      gap={{
                        base: "small",
                        small: "small",
                        medium: "small",
                        large: "xl",
                      }}
                    >
                      {/* <Button
                      style={primaryButton}
                      backgroundColor={"#202A44"}
                      boxShadow="large"
                      borderRadius="2em"
                      fontSize={{ base: "small", large: "medium" }}
                    >
                      Preview{" "}
                      <Icon
                        as={VscOpenPreview}
                        fontSize={{ base: "xl", large: "xl" }}
                        color="white"
                        fontWeight={"bold"}
                        marginLeft="0.2em"
                        marginTop={"0.4em"}
                      ></Icon>
                    </Button> */}
                      {showvalue5 ? (
                        <AdPreview
                          DisableAfterPublish={DisableAfterPublish}
                          foradminad_id={foradminpublish_Ad_id}
                          foradmin_version={foradminpublish_Ad_Version}
                          version_adcreate={Version_from_Addetails}
                          Version_Frm_MC={UpdtAdDetailsVersion}
                          adstatelist={State_of_Ad}
                          editAdValues={editAd}
                          ADID_FRM_DB={adidfromsave}
                          editAdsetValue={editAdset}
                          ADSET_ID={adset_id}
                          AD_NAME_FRM_ADPAGE={AdName}
                          adsAccountId={adsaccount}
                          adsuserlist={Assigned_Users}
                          UPDATED_ADCREATIVE_ID={UpdtAdDetailsId}
                          ADID_FRM_AD={adidfromsave}
                          Ad_History_ID={AdHistoryID}
                          // setAd_History_ID={setAdHistoryID}
                          popuponclick={handleShowadset}
                          show={showadset}
                          handleClose={handleCloseadset}
                          accessToken={long_access_token}
                          PREVIEW_FORMAT={PreviewFormat}
                          AD_ID={FbAddId}
                          AddformState={AddformStatePage}
                          messagesEndRef={messagesEndRef}
                        ></AdPreview>
                      ) : null}

                      {/* <Button
                      style={primaryButton}
                      boxShadow="large"
                      backgroundColor="grey"
                      borderRadius="2em"
                      fontSize={{ base: "small", large: "medium" }}
                      size={{ base: "xs", large: "large" }}
                    >
                      Save Draft{" "}
                      <Icon
                        as={RiDraftLine}
                        fontSize={{ large: "medium" }}
                        color="white"
                        fontWeight={"bold"}
                        marginLeft="0.3em"
                      ></Icon>
                    </Button> */}
                      {role !== "4" && (
                        <Button
                          style={primaryButton}
                          boxShadow="large"
                          fontSize={{ base: "xs", large: "medium" }}
                          borderRadius="2em"
                          backgroundColor={
                            role === "5" ? "rgb(67 192 189)" : "rgb(104, 2, 80)"
                          }
                          onClick={Redirect_to_Preview}
                        >
                          {role ==="6"?"Submit":(role === "5"
                            ? "Submit to Publisher"
                            : "Submit for Approval")}
                          <Icon
                            as={BsHandIndexThumb}
                            fontSize={{ base: "xl", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            marginLeft="0.3em"
                            marginTop={"0.3em"}
                          ></Icon>
                        </Button>
                      )}
                      {(role === "4" || role === "1") && (
                        <Button
                          style={primaryButton}
                          boxShadow="large"
                          // onClick={handleShow}
                          borderRadius="2em"
                          fontSize={{ base: "small", large: "medium" }}
                          backgroundColor="rgb(142, 198, 65)"
                          size={{ base: "xs", large: "small" }}
                          onClick={Redirect_to_Preview}
                        >
                          Preview and Publish{" "}
                          <Icon
                            as={BsHandThumbsUp}
                            fontSize={{ base: "xl", large: "xl" }}
                            xist
                            color="white"
                            fontWeight={"bold"}
                            marginLeft="0.3em"
                            marginTop={"0.3em"}
                          ></Icon>
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                ) : null}
              </ButtonGroup>
            </View>
          </Flex>
          <Modal size="md" show={show} onHide={handleClose} border="none">
            <Modal.Header
              style={{
                border: "none",
                padding: "1.5em",
                paddingBottom: "0em",
                display: "flow-root",
              }}
            >
              <Flex
                direction={"row"}
                gap="small"
                justifyContent="space-between"
              >
                <View width="55%">
                  <Text style={{ fontSize: "1.2em", fontWeight: "600" }}>
                    Existing Campaign
                  </Text>
                </View>
                <View width="45%">
                  <SearchField
                    label="search"
                    placeholder="Search..."
                    size="small"
                    onChange={(e) => handleChanges(e.target.value)}
                  />
                </View>
              </Flex>
            </Modal.Header>
            <Modal.Body style={{ padding: "2em", paddingTop: "1em" }}>
              <View style={{ height: 200, width: "100%" }}>
                <DataGrid
                  width="10%"
                  headerHeight={40}
                  rowHeight={40}
                  sx={datagridSx}
                  rows={listCampaign}
                  columns={columns}
                  hideFooter
                  // columns={[
                  //   {
                  //     field: 'Campaign name',
                  //     flex: 1,
                  //     minWidth: 150,
                  //   },
                  //   {
                  //     field: 'Delete',
                  //     width: 200,
                  //     textAlign:'center'
                  //   },
                  // ]}
                  pageSize={100}
                  rowsPerPageOptions={[5, 10, 20]}
                  disableSelectionOnClick
                />
              </View>
            </Modal.Body>
            <Flex
              direction="column"
              alignItems="center"
              style={{ marginTop: "1em" }}
              marginBottom={{ large: "relative.large" }}
            >
              <Button
                style={primaryButton}
                boxShadow="large"
                onClick={() =>SaveCampaign()}
              >
                Save
                <Icon
                  as={BsHandIndexThumb}
                  fontSize={{ large: "xl" }}
                  color="white"
                  fontWeight={"bold"}
                  marginLeft="0.3em"
                  marginTop={"0.3em"}
                ></Icon>
              </Button>
            </Flex>
          </Modal>
        </div>
      )}
    </div>
    </>
  );
};
export default ManageContent;
