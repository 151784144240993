import React, { useEffect, useState, CSSProperties } from "react";
import { Flex } from "@aws-amplify/ui-react";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import "../managecontent/managestyle.css";
import {
    Icon,
    SelectField,
    ScrollView,
    FieldGroupIcon,
    FieldGroupIconButton,
    CheckboxField,
} from "@aws-amplify/ui-react";
import {
  Button,
  Grid,
  View,
  useTheme,
  TextField,
  Text,
  Card,
  Divider,
  SearchField,
} from "@aws-amplify/ui-react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { BiMinus, BiReset, BiExit, BiSave } from "react-icons/bi";
import { BsHandIndexThumb } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import {
  getAdHistory,
  getAdSet,
  getPostDetails,
  listPostDetails,
} from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import VerifiedIcon from '@mui/icons-material/Verified';
import "../../App.css";
import { useLocation } from "react-router-dom";
import {
  createAdHistory,
  updateAdHistory,
  deleteAdHistory,
  updatePostHistory,
  createPostHistory,
  updatePostDetails,
} from "../../graphql/mutations";
import Multiselect from "multiselect-react-dropdown";

import Parser from "html-react-parser";
import "../managecontent/Ad.css";

import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { SwitchField } from "@aws-amplify/ui-react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  listUserInfos,
  listAdStatuses,
  listAdHistories,
} from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { datagridSx } from "../../assets/style";
import { RiDraftLine } from "react-icons/ri";
import { useRef } from "react";
import { primaryButton, secondaryButton } from "../../assets/style";
import Iframe from "react-iframe";

import "../../App.css";
import { AdStatus } from "../../models";

import "react-toastify/dist/ReactToastify.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SyncLockOutlinedIcon from "@mui/icons-material/SyncLockOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { FcApproval, FcCancel } from "react-icons/fc";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import { RiEditLine, RiEditCircleFill, RiSave3Line } from "react-icons/ri";
import EditIcon from "@mui/icons-material/Edit";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { MdOutlinePending, MdCancel, MdPending } from "react-icons/md";
import { GoIssueOpened } from "react-icons/go";
const Assinged = ({
  toast,
  Assign,
  show,
  handleCloseAss,
  uList,
  AdList,
  editid,
  postvers,
  historyvers,
  updateversion,
  postid,
  historyid,
  igpostid,
  AdStat,
  ighistoryid,
  igpostvers,
  ighistoryvers,
  assignpost,
  token,
  pageid,
  instg_user,
  GeneralPost_Id,
  Generalpostvers,
  messagesEndRef,
  GeneralPostHistory_Id,
  GeneralPostHistoryvers,
  DisableFieldsAfterpublished
}) => {
  const navigate = useNavigate();
  const instatoastId = useRef(null);
  const toastId = useRef(null);

  useEffect(() => {
    filterwithroleid();
   console.log("Dowt",editid,updateversion)
    if(GeneralPost_Id != undefined){
      ListOfPostDetails(GeneralPost_Id);
    }
    else{
      GeneralPost_Id =editid;
    }
    if(Generalpostvers == undefined){
      Generalpostvers=updateversion;
    }
    if(postid != undefined){
      ListOfPostDetails(postid);
     }
    else if(postid == undefined && igpostid != undefined){
      ListOfPostDetails(igpostid);
    }
    else if(postid == undefined && historyid != undefined){
      ListOfPostDetails(historyid);
    }
    console.log("postid", postid,GeneralPost_Id,historyid,editid,updateversion,
    igpostid,);
    console.log({ useeffect: AdStat });
  }, []);
  const [loading, setloading] = useState(false);
  const filterwithroleid = () => {
    if (localStorage.getItem("role_id") === "2") {
      if (Assign != undefined && Assign != null && Assign != "") {
        setAdstatusList(
          AdList.filter(
            (item) =>
              item.status_id == 2 || item.status_id == 6 || item.status_id == 5
          )
        );
        setAdstatus(AdStat);
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
          )
        );
        setselectedUser(Assign.split("~")[0]);
      } else {
        setAdstatusList(AdList.filter((item) => item.status_id == 2));
        setAdstatus(2);
        setUserList(uList.filter((item) => item.role_id == 5));
        if (uList.filter((item) => item.role_id == 5).length === 1) {
          setselectedUser(uList.filter((item) => item.role_id == 5)[0].id);
        }
      }
    }
    if (localStorage.getItem("role_id") === "5") {
      if (Assign != undefined && Assign != null && Assign != "") {
        setAdstatusList(
          AdList.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 6
          )
        );
        setAdstatus(AdStat);
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
          )
        );
        setselectedUser(Assign.split("~")[0]);
      } else {
        setAdstatusList(AdList.filter((item) => item.status_id == 3));
        setAdstatus(3);
        setUserList(uList.filter((item) => item.role_id == 4));
        if (uList.filter((item) => item.role_id == 4).length === 1) {
          setselectedUser(uList.filter((item) => item.role_id == 4)[0].id);
        }
      }
    }
    if (localStorage.getItem("role_id") === "6") {
      if (Assign != undefined && Assign != null && Assign != "") {
        setAdstatusList(
          AdList.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setAdstatus(AdStat);
        setUserList(
          uList.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
        setselectedUser(Assign.split("~")[0]);
      } else {
        setAdstatusList(
          AdList.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setAdstatus(6);
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
          )
        );
        if (uList.filter((item) => item.role_id == 2).length === 1) {
          setselectedUser(uList.filter((item) => item.role_id == 2)[0].id);
        }
      }
    }
    if (localStorage.getItem("role_id") === "1") {
      if (Assign != undefined && Assign != null && Assign != "") {
        setAdstatusList(
          AdList.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setAdstatus(AdStat);
        setUserList(
          uList.filter(
            (item) => item._deleted !== true || item._deleted == null
          )
        );
        setselectedUser(Assign.split("~")[0]);
      } else {
        setAdstatusList(
          AdList.filter(
            (item) =>
              item.status_id == 3 ||
              item.status_id == 6 ||
              item.status_id == 5 ||
              item.status_id == 1 ||
              item.status_id == 2 ||
              item.status_id == 4
          )
        );
        setAdstatus(6);
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
          )
        );
        if (uList.filter((item) => item.role_id == 2).length === 1) {
          setselectedUser(uList.filter((item) => item.role_id == 2)[0].id);
        }
      }
    }
    // if (localStorage.getItem("role_id") === "3") {
    //     if (Assign != undefined && Assign != null && Assign != "") {
    //         setAdstatusList(AdList.filter(item => (item.status_id == 3 || item.status_id == 6 || item.status_id == 5 || item.status_id == 1 || item.status_id == 2 || item.status_id == 4)))
    //         setAdstatus(Assign.split("~")[1]);
    //         setUserList(uList.filter(item => (item._deleted !== true || item._deleted == null) && (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)))
    //         setselectedUser(Assign.split("~")[0])
    //     }
    //     else {
    //         setAdstatusList(AdList.filter(item => (item.status_id == 3 || item.status_id == 6 || item.status_id == 5 || item.status_id == 1 || item.status_id == 2 || item.status_id == 4)))
    //         setAdstatus(6);
    //         setUserList(uList.filter(item => (item._deleted !== true || item._deleted == null) && (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)))
    //         if (uList.filter(item => item.role_id == 2).length === 1) {
    //             setselectedUser(uList.filter(item => item.role_id == 2)[0].id)
    //         }
    //     }
    // }
  };
  const fbDataupload = async () => {
    // alert(historyid);

    if (
      (localStorage.getItem("role_id") === "1" ||
        localStorage.getItem("role_id") === "6") &&
      Adstatu === "4"
    ) {
      try {
        // instatoastId.current = toast.loading(
        //     "Publishing in Facebook ..."
        // );
        setloading(true);
        axios({
          method: "get",
          url:
            "https://graph.facebook.com/v15.0/" +
            pageid +
            "?fields=access_token&access_token=" +
            token,
        })
          .then((data) => {
            axios({
              method: "post",
              url:
                "https://graph.facebook.com/v15.0/" +
                pageid +
                "/photos?url=" +
                encodeURIComponent(assignpost[0].fbimage) +
                "&message=" +
                assignpost[0].fbmsg +
                "&scheduled_publish_time=" +
                assignpost[0].timestamp +
                "&published=false&access_token=" +
                data.data.access_token,
            })
              .then(async (data) => {
                console.log(data, "data");
                const obj = {
                  id: postid,
                  post_status: "4",
                  _version: postvers,
                  fb_post_id: data.data.id,
                };

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async (response) => {
                    const createHistoryobj = {
                      post_status_id: "4",
                      assigned_by_userid: localStorage.getItem("userid"),
                      assigned_to_userid: null,
                      postdetailsID: postid,
                    };
                    await API.graphql(
                      graphqlOperation(createPostHistory, {
                        input: createHistoryobj,
                      })
                    ).then(() => {});
                  })
                  .catch(() => {
                    toast.error("error when save to db");
                    setloading(false);
                  });
                toast.success("Published in Facebook");
                // toast.update(instatoastId.current, {
                //     render: "Feed Posted SuccessFully",
                //     type: "success",
                //     isLoading: false,
                //     autoClose: 1000,
                // });
                setloading(false);
                // setTimeout(() => {
                //     navigate("/newdashboard");
                // }, 2000);
              })
              .catch(async (error) => {
                console.log("err", error);
                toast.error("Check the Schedule Time");
                setloading(false);
                // toast.update(instatoastId.current, {
                //     render: "Check the Schedule Time",
                //     type: "error",
                //     isLoading: false,
                //     autoClose: 1000,
                // });
                

                // toast.error("something went wrong")

                const obj = {
                  id: postid,
                  post_status: "1",
                  _version: postvers,
                  fb_post_id: "",
                  //  insta_container_id:data.data.id
                };

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async (response) => {
                    const createHistoryobj = {
                      post_status_id: "1",
                      assigned_by_userid: localStorage.getItem("userid"),
                      assigned_to_userid: null,
                      postdetailsID: postid,
                    };
                    await API.graphql(
                      graphqlOperation(createPostHistory, {
                        input: createHistoryobj,
                      })
                    ).then(() => {});
                  })
                  .catch(() => {
                    toast.error("error when save to db");
                    setloading(false);
                  });
              });
          })
          .catch(async () => {
            setloading(false);
            const obj = {
              id: postid,
              post_status: "1",
              _version: postvers,
              fb_post_id: "",
            };

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async (response) => {
                const createHistoryobj = {
                  post_status_id: "1",
                  assigned_by_userid: localStorage.getItem("userid"),
                  assigned_to_userid: null,
                  postdetailsID: postid,
                };
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj,
                  })
                ).then(() => {});
              })
              .catch(() => {
                toast.error("error when save to db");
                setloading(false);
              });
            toast.error("something went wrong");
            setloading(false);
            // toast.update(instatoastId.current, {
            //     render: "something went wrong",
            //     type: "error",
            //     isLoading: false,
            //     autoClose: 1000,
            // });
          });
      } catch (err) {
        console.log(err);
        toast.error("something went wrong");
        setloading(false);
        // toast.update(instatoastId.current, {
        //     render: "something went wrong",
        //     type: "error",
        //     isLoading: false,
        //     autoClose: 1000,
        // });
      }
    } else {
      setloading(false);
      if (historyid != undefined && historyid != "" && historyid != null) {
        
        const updatehistoryobj = {
          id: historyid,
          assigned_to_userid: selectedUser,
          _version: historyvers,
        };
        await API.graphql(
          graphqlOperation(updatePostHistory, { input: updatehistoryobj })
        )
          .then(() => {
            let text = "";
            if (
              localStorage.getItem("role_id") === "1" ||
              localStorage.getItem("role_id") === "6"
            ) {
              text =
                Adstatu === "2"
                  ? "Post has been submitted to Approver"
                  : Adstatu === "5"
                  ? "Post has been submitted to Editor"
                  : Adstatu === "1"
                  ? "Post has been Rejected"
                  : Adstatu === "3"
                  ? "Post has been Submited to Publisher"
                  : Adstatu === "4"
                  ? "Post has been Published"
                  : Adstatu === "6"
                  ? "Post has been Submited to Editor"
                  : "";
            } else {
              text =
                localStorage.getItem("role_id") === "2"
                  ? "Post has been submitted to Approver"
                  : localStorage.getItem("role_id") === "5"
                  ? "Post has been submitted to Publisher"
                  : "";
            }
            setTimeout(() => {
              navigate("/newdashboard");
              setloading(false);
            }, 1000);
          })
          .catch(() => {
            toast.error("Error Whilr Assign");
            setloading(false);
          });
      } else {


        const createHistoryobj = {
          post_status_id: Adstatu,
          assigned_by_userid: localStorage.getItem("userid"),
          postdetailsID: postid,
          assigned_to_userid: selectedUser,
        };

        console.log(createHistoryobj);
        await API.graphql(
          graphqlOperation(createPostHistory, { input: createHistoryobj })
        )
          .then(() => {
            setloading(false);
            let text = "";
            if (
              localStorage.getItem("role_id") === "1" ||
              localStorage.getItem("role_id") === "6"
            ) {
              text =
                Adstatu === "2"
                  ? "Post has been submitted to Approver"
                  : Adstatu === "5"
                  ? "Post has been submitted to Editor"
                  : Adstatu === "1"
                  ? "Post has been Rejected"
                  : Adstatu === "3"
                  ? "Post has been Submited to Publisher"
                  : Adstatu === "4"
                  ? "Post has been Published"
                  : Adstatu === "6"
                  ? "Post has been Submited to Editor"
                  : "";
            } else {
              setloading(false);
              text =
                localStorage.getItem("role_id") === "2"
                  ? "Post has been submitted to Approver"
                  : localStorage.getItem("role_id") === "5"
                  ? "Post has been submitted to Publisher"
                  : "";
            }

            if (text !== "") {
              setloading(false);
              toast.success(text);
            }
            setTimeout(() => {
              navigate("/newdashboard");
              setloading(false);
            }, 1000);
          })
          .catch(() => {
            toast.error("Error While Assign");
            setloading(false);
          });
      }

      const updatePost = {
        id: postid,
        post_status: Adstatu,
        _version: postvers,
      };

      await API.graphql(
        graphqlOperation(updatePostDetails, { input: updatePost })
      )
        .then(() => {})
        .catch(() => {
          setloading(false);
          toast.error("something wrong");
        });
    }
  };

  const igDataUpload = async () => {
    if (
      (localStorage.getItem("role_id") === "1" ||
        localStorage.getItem("role_id") === "6") &&
      Adstatu === "4"
    ) {
      try {
        // toastId.current = toast.loading("Publishing in Instagram...");
        setloading(true);
        axios({
          method: "get",
          url:
            "https://graph.facebook.com/v15.0/" +
            pageid +
            "?fields=access_token&access_token=" +
            token,
        })
          .then((data) => {
            axios({
              method: "post",
              url:
                "https://graph.facebook.com/v15.0/" +
                instg_user +
                "/media?image_url=" +
                encodeURIComponent(assignpost[0].igimage) +
                "&caption=" +
                assignpost[0].igmsg +
                "&access_token=" +
                data.data.access_token,
            })
              .then((get) => {
                console.log(get, "frominstapost");
                axios({
                  method: "post",
                  url:
                    "https://graph.facebook.com/v15.0/" +
                    instg_user +
                    "/media_publish?creation_id=" +
                    get.data.id +
                    "&access_token=" +
                    data.data.access_token,
                })
                  .then(async (d) => {
                    const obj = {
                      id: igpostid,
                      post_status: "4",
                      _version: igpostvers,
                      fb_post_id: d.data.id,
                      insta_container_id: get.data.id,

                      // id: postid,
                      // post_status: "4",
                      // _version: postvers,
                      // fb_post_id: data.data.id,
                    };

                    await API.graphql(
                      graphqlOperation(updatePostDetails, {
                        input: obj,
                      })
                    )
                      .then(async (response) => {
                        const createHistoryobj = {
                          post_status_id: "4",
                          assigned_by_userid: localStorage.getItem("userid"),
                          assigned_to_userid: selectedUser,
                          postdetailsID: igpostid,
                        };
                        await API.graphql(
                          graphqlOperation(createPostHistory, {
                            input: createHistoryobj,
                          })
                        ).then(() => {});
                      })
                      .catch(() => {
                        toast.error("error when save to db");
                      });
                    toast.success("Succesfully Posted in IG");
                    setloading(false);
                    // toast.update(toastId.current, {
                    //     render: "Succesfully Posted in IG",
                    //     type: "success",
                    //     isLoading: false,
                    //     autoClose: 1000,
                    // });

                    setTimeout(() => {
                      navigate("/newdashboard");
                    }, 2000);
                  })
                  .catch(async () => {
                    // toast.update(toastId.current, {
                    //     render: "Something Wrong",
                    //     type: "error",
                    //     isLoading: false,
                    //     autoClose: 1000,
                    // });
                    setloading(false);
                    toast.error("Something Wrong");

                    const obj = {
                      id: igpostid,
                      post_status: "1",
                      _version: igpostvers,
                      fb_post_id: "",
                      insta_container_id: "",
                    };

                    await API.graphql(
                      graphqlOperation(updatePostDetails, {
                        input: obj,
                      })
                    )
                      .then(async (response) => {
                        const createHistoryobj = {
                          post_status_id: "1",
                          assigned_by_userid: localStorage.getItem("userid"),
                          assigned_to_userid: selectedUser,
                          postdetailsID: igpostid,
                        };
                        await API.graphql(
                          graphqlOperation(createPostHistory, {
                            input: createHistoryobj,
                          })
                        ).then(() => {});
                      })
                      .catch(() => {
                        toast.error("error when save to db");
                        setloading(false);
                        //toast.error("error when save to db")
                        // toast.update(toastId.current, {
                        //     render: "error when save to db",
                        //     type: "error",
                        //     isLoading: false,
                        //     autoClose: 1000,
                        // });
                      });
                  });
              })
              .catch(async () => {
                setloading(false);
                toast.error("Please Select Valid Image");
                // toast.update(toastId.current, {
                //     render: "Please Select Valid Image",
                //     type: "error",
                //     isLoading: false,
                //     autoClose: 1000,
                // });
                const obj = {
                  id: igpostid,
                  post_status: "1",
                  _version: igpostvers,
                  fb_post_id: "",
                  insta_container_id: "",
                };

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async (response) => {
                    const createHistoryobj = {
                      post_status_id: "1",
                      assigned_by_userid: localStorage.getItem("userid"),
                      assigned_to_userid: selectedUser,
                      postdetailsID: igpostid,
                    };
                    await API.graphql(
                      graphqlOperation(createPostHistory, {
                        input: createHistoryobj,
                      })
                    ).then(() => {});
                  })
                  .catch(() => {
                    setloading(false);
                    toast.error("error when save to db");
                  });
              });
          })
          .catch(async () => {
            setloading(false);
            toast.error("Something Went Wrong");
            const obj = {
              id: igpostid,
              post_status: "1",
              _version: igpostvers,
              fb_post_id: "",
              insta_container_id: "",
            };

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async (response) => {
                const createHistoryobj = {
                  post_status_id: "1",
                  assigned_by_userid: localStorage.getItem("userid"),
                  assigned_to_userid: selectedUser,
                  postdetailsID: igpostid,
                };
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj,
                  })
                ).then(() => {});
              })
              .catch(() => {
                toast.error("error when save to db");
              });
          });
      } catch (err) {
        toast.error("Something Went Wrong");
        setloading(false);
        // toast.update(toastId.current, {
        //     render: "something went wrong",
        //     type: "error",
        //     isLoading: false,
        //     autoClose: 1000,
        // });
      }
    } else {
      if (
        ighistoryid != undefined &&
        ighistoryid != "" &&
        ighistoryid != null
      ) {
        const updatehistoryobj = {
          id: ighistoryid,
          assigned_to_userid: selectedUser,
          _version: ighistoryvers,
        };
        await API.graphql(
          graphqlOperation(updatePostHistory, { input: updatehistoryobj })
        )
          .then(() => {
            let text = "";
            if (
              localStorage.getItem("role_id") === "1" ||
              localStorage.getItem("role_id") === "6"
            ) {
              text =
                Adstatu === "2"
                  ? "Post has been submitted to Approver"
                  : Adstatu === "5"
                  ? "Post has been submitted to Editor"
                  : Adstatu === "1"
                  ? "Post has been Rejected"
                  : Adstatu === "3"
                  ? "Post has been Submited to Publisher"
                  : Adstatu === "4"
                  ? "Post has been Published"
                  : Adstatu === "6"
                  ? "Post has been Submited to Editor"
                  : "";
            } else {
              text =
                localStorage.getItem("role_id") === "2"
                  ? "Post has been submitted to Approver"
                  : localStorage.getItem("role_id") === "5"
                  ? "Post has been submitted to Publisher"
                  : "";
            }

            // if (text !== "") {
            //     toast.success(text)
            // }
            setTimeout(() => {
              navigate("/newdashboard");
            }, 1000);
          })
          .catch(() => {
            toast.error("Error While Assign");
          });
      } else {
        const createHistoryobj = {
          post_status_id: Adstatu,
          assigned_by_userid: localStorage.getItem("userid"),
          postdetailsID: igpostid,
          assigned_to_userid: selectedUser,
        };
        await API.graphql(
          graphqlOperation(createPostHistory, { input: createHistoryobj })
        )
          .then(() => {
            let text = "";
            if (
              localStorage.getItem("role_id") === "1" ||
              localStorage.getItem("role_id") === "6"
            ) {
              text =
                Adstatu === "2"
                  ? "Post has been submitted to Approver"
                  : Adstatu === "5"
                  ? "Post has been submitted to Editor"
                  : Adstatu === "1"
                  ? "Post has been Rejected"
                  : Adstatu === "3"
                  ? "Post has been Submited to Publisher"
                  : Adstatu === "4"
                  ? "Post has been Published"
                  : Adstatu === "6"
                  ? "Post has been Submited to Editor"
                  : "";
            } else {
              text =
                localStorage.getItem("role_id") === "2"
                  ? "Post has been submitted to Approver"
                  : localStorage.getItem("role_id") === "5"
                  ? "Post has been submitted to Publisher"
                  : "";
            }
            if (text !== "") {
              toast.success(text);
            }
            setTimeout(() => {
              navigate("/newdashboard");
            }, 1000);
          })
          .catch(() => {
            toast.error("Error While Assign");
          });
      }

      const updatePost = {
        id: igpostid,
        post_status: Adstatu,
        _version: igpostvers,
      };

      await API.graphql(
        graphqlOperation(updatePostDetails, { input: updatePost })
      )
        .then(() => {})
        .catch(() => {
          toast.error("something went wrong");
        });
    }
  };
  const CommonPublishFunction = async () => {
    if (
      (localStorage.getItem("role_id") === "1" ||
        localStorage.getItem("role_id") === "6") &&
      Adstatu === "4"
    ) {
    try {
      setloading(true);
      axios({
        method: "get",
        url:
          "https://graph.facebook.com/v15.0/" +
          pageid +
          "?fields=access_token&access_token=" +
          token,
      })
        .then((data) => {
          axios({
            method: "post",
            url:
              "https://graph.facebook.com/v15.0/" +
              pageid +
              "/photos?url=" +
              encodeURIComponent(assignpost[0].fbimage) +
              "&message=" +
              assignpost[0].fbmsg +
              "&scheduled_publish_time=" +
              assignpost[0].timestamp +
              "&published=false&access_token=" +
              data.data.access_token,
          })
            .then(async (data) => {
              console.log(data,GeneralPost_Id, "data11111");
              const obj = {
                id: GeneralPost_Id,
                post_status: "4",
                _version: Generalpostvers,
                fb_post_id: data.data.id,
              };

              await API.graphql(
                graphqlOperation(updatePostDetails, { input: obj })
              )
                .then(async (response) => {
                  console.log(response,"FULLDATA1");
                  await InstaPublishFunction(
                    response.data.updatePostDetails._version
                  );
                  const createHistoryobj = {
                    post_status_id: "4",
                    assigned_by_userid: localStorage.getItem("userid"),
                    assigned_to_userid: null,
                    postdetailsID: GeneralPost_Id,
                  };
                  await API.graphql(
                    graphqlOperation(createPostHistory, {
                      input: createHistoryobj,
                    })
                  ).then(() => {});
                })
                .catch(() => {
                  toast.error("error when save to db");
                });
              toast.success("Published in Facebook");
              // toast.update(instatoastId.current, {
            })
            .catch(async (error) => {
              console.log("err", error);
              toast.error("Check the Schedule Time");
              const obj = {
                id: GeneralPost_Id,
                post_status: "1",
                _version: Generalpostvers,
                fb_post_id: "",

                //  insta_container_id:data.data.id
              };

              await API.graphql(
                graphqlOperation(updatePostDetails, { input: obj })
              ).then(async (response) => {
                console.log(response, "FULLDATA2");
                await InstaPublishFunction(
                  response.data.updatePostDetails._version
                );
                const createHistoryobj = {
                  post_status_id: "1",
                  assigned_by_userid: localStorage.getItem("userid"),
                  assigned_to_userid: null,
                  postdetailsID: GeneralPost_Id,
                };
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj,
                  })
                ).then(() => {});
              });

              setloading(false);
            });
        })
        .catch(async () => {
          setloading(false);
          const obj = {
            id: GeneralPost_Id,
            post_status: "1",
            _version: Generalpostvers,
            fb_post_id: "",
          };

          await API.graphql(graphqlOperation(updatePostDetails, { input: obj }))
            .then(async (response) => {
              console.log(response, "FULLDATA3");
              await InstaPublishFunction(
                response.data.updatePostDetails._version
              );
              const createHistoryobj = {
                post_status_id: "1",
                assigned_by_userid: localStorage.getItem("userid"),
                assigned_to_userid: null,
                postdetailsID: GeneralPost_Id,
              };
              await API.graphql(
                graphqlOperation(createPostHistory, {
                  input: createHistoryobj,
                })
              ).then(() => {});
            })
            .catch(() => {
             
              toast.error("error when save to db");
              setloading(false);
            });
          toast.error("something went wrong");
          setloading(false);
          // toast.update(instatoastId.current, {
          //     render: "something went wrong",
          //     type: "error",
          //     isLoading: false,
          //     autoClose: 1000,
          // });
        });
    } catch (err) {
      console.log(err + "err");
    }
  }else{


    if (
      GeneralPostHistory_Id != undefined &&
      GeneralPostHistory_Id != "" &&
      GeneralPostHistory_Id != null
    ) {
      const updatehistoryobj = {
        id: GeneralPostHistory_Id,
        assigned_to_userid: selectedUser,
        _version: GeneralPostHistoryvers,
      };
      await API.graphql(
        graphqlOperation(updatePostHistory, { input: updatehistoryobj })
      )
        .then(() => {
          let text = "";
          if (
            localStorage.getItem("role_id") === "1" ||
            localStorage.getItem("role_id") === "6"
          ) {
            text =
              Adstatu === "2"
                ? "Post has been submitted to Approver"
                : Adstatu === "5"
                  ? "Post has been submitted to Editor"
                  : Adstatu === "1"
                    ? "Post has been Rejected"
                    : Adstatu === "3"
                      ? "Post has been Submited to Publisher"
                      : Adstatu === "4"
                        ? "Post has been Published"
                        : Adstatu === "6"
                          ? "Post has been Submited to Editor"
                          : "";
          } else {
            text =
              localStorage.getItem("role_id") === "2"
                ? "Post has been submitted to Approver"
                : localStorage.getItem("role_id") === "5"
                  ? "Post has been submitted to Publisher"
                  : "";
          }

          // if (text !== "") {
          //     toast.success(text)
          // }
          setTimeout(() => {
            navigate("/newdashboard");
          }, 1000);
        })
        .catch(() => {
          toast.error("Error While Assign");
        });
    } else {
      const createHistoryobj = {
        post_status_id: Adstatu,
        assigned_by_userid: localStorage.getItem("userid"),
        postdetailsID: GeneralPost_Id,
        assigned_to_userid: selectedUser,
      };
      await API.graphql(
        graphqlOperation(createPostHistory, { input: createHistoryobj })
      )
        .then(() => {
          let text = "";
          if (
            localStorage.getItem("role_id") === "1" ||
            localStorage.getItem("role_id") === "6"
          ) {
            text =
              Adstatu === "2"
                ? "Post has been submitted to Approver"
                : Adstatu === "5"
                  ? "Post has been submitted to Editor"
                  : Adstatu === "1"
                    ? "Post has been Rejected"
                    : Adstatu === "3"
                      ? "Post has been Submited to Publisher"
                      : Adstatu === "4"
                        ? "Post has been Published"
                        : Adstatu === "6"
                          ? "Post has been Submited to Editor"
                          : "";
          } else {
            text =
              localStorage.getItem("role_id") === "2"
                ? "Post has been submitted to Approver"
                : localStorage.getItem("role_id") === "5"
                  ? "Post has been submitted to Publisher"
                  : "";
          }
          if (text !== "") {
            toast.success(text);
          }
          setTimeout(() => {
            navigate("/newdashboard");
          }, 1000);
        })
        .catch(() => {
          toast.error("Error While Assign");
        });
    }

    const updatePost = {
      id: GeneralPost_Id,
      post_status: Adstatu,
      _version: Generalpostvers,
    };

    await API.graphql(
      graphqlOperation(updatePostDetails, { input: updatePost })
    )
      .then(() => { })
      .catch(() => {
        toast.error("something went wrong");
      });
  }
  };
  const InstaPublishFunction = async (_v) => {
    axios({
      method: "get",
      url:
        "https://graph.facebook.com/v15.0/" +
        pageid +
        "?fields=access_token&access_token=" +
        token,
    })
      .then((data) => {
        axios({
          method: "post",
          url:
            "https://graph.facebook.com/v15.0/" +
            instg_user +
            "/media?image_url=" +
            encodeURIComponent(assignpost[0].igimage) +
            "&caption=" +
            assignpost[0].igmsg +
            "&access_token=" +
            data.data.access_token,
        })
          .then((get) => {
            console.log(get, "frominstapost");
            axios({
              method: "post",
              url:
                "https://graph.facebook.com/v15.0/" +
                instg_user +
                "/media_publish?creation_id=" +
                get.data.id +
                "&access_token=" +
                data.data.access_token,
            })
              .then(async (d) => {
                toast.success("Succesfully Posted in IG");
                const obj = {
                  id: GeneralPost_Id,
                  post_status: "4",
                  _version:_v,
                  insta_post_id: d.data.id,
                };

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async (response) => {
                    setloading(false);
                    // const createHistoryobj = {
                    //   post_status_id: "4",
                    //   assigned_by_userid: localStorage.getItem("userid"),
                    //   assigned_to_userid: null,
                    //   postdetailsID: GeneralPost_Id,
                    // };
                    // await API.graphql(
                    //   graphqlOperation(createPostHistory, {
                    //     input: createHistoryobj,
                    //   })
                    // ).then(() => {});
                  })
                  .catch(() => {
                    toast.error("error when save to db");
                  });
              })
              .catch(async () => {
                const obj = {
                  id: GeneralPost_Id,
                  post_status: "1",
                  _version:_v,
                };
                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async (response) => {
                    setloading(false);
                    // const createHistoryobj = {
                    //   post_status_id: "4",
                    //   assigned_by_userid: localStorage.getItem("userid"),
                    //   assigned_to_userid: null,
                    //   postdetailsID: GeneralPost_Id,
                    // };
                    // await API.graphql(
                    //   graphqlOperation(createPostHistory, {
                    //     input: createHistoryobj,
                    //   })
                    // ).then(() => {});
                  })
                  .catch(() => {
                    setloading(false);
                    toast.error("error when save to db");
                  });
              });
          })
          .catch(async () => {
            setloading(false);
            toast.error("Something Wrong");
            const obj = {
              id: GeneralPost_Id,
              post_status: "1",
              _version: _v,
            };

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async (response) => {
                setloading(false);
                // const createHistoryobj = {
                //   post_status_id: "4",
                //   assigned_by_userid: localStorage.getItem("userid"),
                //   assigned_to_userid: null,
                //   postdetailsID: GeneralPost_Id,
                // };
                // await API.graphql(
                //   graphqlOperation(createPostHistory, {
                //     input: createHistoryobj,
                //   })
                //  ).then(() => {});
              })
              .catch(() => {
                setloading(false);
                toast.error("error when save to db");
              });
          })
          .catch(async () => {
            toast.error("Please Select Valid Image");
            const obj = {
              id: GeneralPost_Id,
              post_status: "1",
              _version: _v,
            };

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async (response) => {
                setloading(false);
                // const createHistoryobj = {
                //   post_status_id: "4",
                //   assigned_by_userid: localStorage.getItem("userid"),
                //   assigned_to_userid: null,
                //   postdetailsID: GeneralPost_Id,
                // };
                // await API.graphql(
                //   graphqlOperation(createPostHistory, {
                //     input: createHistoryobj,
                //   })
                // ).then(() => {});
              })
              .catch(() => {
                setloading(false);
                toast.error("error when save to db");
              });
          })
          .catch(async () => {
            const obj = {
              id: GeneralPost_Id,
              post_status: "1",
              _version: _v,
            };

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async (response) => {
                setloading(false);
                // const createHistoryobj = {
                //   post_status_id: "4",
                //   assigned_by_userid: localStorage.getItem("userid"),
                //   assigned_to_userid: null,
                //   postdetailsID: GeneralPost_Id,
                // };
                // await API.graphql(
                //   graphqlOperation(createPostHistory, {
                //     input: createHistoryobj,
                //   })
                // ).then(() => {});
              })
              .catch(() => {
                setloading(false);
                toast.error("error when save to db");
              });
          });
      })
      .catch(async () => {
        const obj = {
          id: GeneralPost_Id,
          post_status: "1",
          _version:_v,
        };

        await API.graphql(graphqlOperation(updatePostDetails, { input: obj }))
          .then(async (response) => {
            setloading(false);
            // const createHistoryobj = {
            //   post_status_id: "4",
            //   assigned_by_userid: localStorage.getItem("userid"),
            //   assigned_to_userid: null,
            //   postdetailsID: GeneralPost_Id,
            // };
            // await API.graphql(
            //   graphqlOperation(createPostHistory, {
            //     input: createHistoryobj,
            //   })
            // ).then(() => {});
          })
          .catch(() => {
            setloading(false);
            toast.error("error when save to db");
          });
      });
  };

  const updateAssign = () => {
    setloading(true);
    if (assignpost[0].isfbpost && !assignpost[0].isigpost) {
      fbDataupload();
    } else if (assignpost[0].isigpost && !assignpost[0].isfbpost) {
      igDataUpload();
    } else if (assignpost[0].isigpost && assignpost[0].isfbpost) {
      CommonPublishFunction();
    }
  };

  const OnAdStateChange = (e) => {
    if (
      localStorage.getItem("role_id") === "1" ||
      localStorage.getItem("role_id") === "6"
    ) {
      if (e == "6" || e == "5") {
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 2
          )
        );
        if (
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 2
          ).length == 1
        ) {
          setselectedUser(
            uList.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == 2
            )[0].id
          );
        }
      }
      if (e == "2") {
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 5
          )
        );
        if (
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 5
          ).length == 1
        ) {
          setselectedUser(
            uList.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == 5
            )[0].id
          );
        }
      }
      if (e == "3") {
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 4
          )
        );
        if (
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == 4
          ).length == 1
        ) {
          setselectedUser(
            uList.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == 4
            )[0].id
          );
        }
      }
      if (e == "1") {
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          )
        );
        if (
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          ).length == 1
        ) {
          setselectedUser(
            uList.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == localStorage.getItem("role_id")
            )[0].id
          );
        }
      }
      if (e == "4") {
        setUserList(
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          )
        );
        if (
          uList.filter(
            (item) =>
              (item._deleted !== true || item._deleted == null) &&
              item.role_id == localStorage.getItem("role_id")
          ).length == 1
        ) {
          setselectedUser(
            uList.filter(
              (item) =>
                (item._deleted !== true || item._deleted == null) &&
                item.role_id == localStorage.getItem("role_id")
            )[0].id
          );
        }
      }

      setAdstatus(e);
    } else {
      setAdstatus(e);
    }
  };
  const [selectedUser, setselectedUser] = useState();
  const [Adstatu, setAdstatus] = useState();
  const [userList, setUserList] = useState();
  const [AdstatusList, setAdstatusList] = useState();
  const IconArrowDropDown = () => {
    return <Icon pathData="M7 10L12 15L17 10H7Z" ariaLabel="Down arrow" />;
  };
  // Get post details
  const [ListOfPostFullHistory, setListOfPostFullHistory] = useState([]);
  const ListOfPostDetails = async (key) => {
    try {
      const getdata = await API.graphql(
        graphqlOperation(getPostDetails, { id: key })
      );
      const ListOfPostDetailsValue =
        getdata.data.getPostDetails.PostHistories.items;
      console.log("ListOfPostDetails", getdata, ListOfPostDetailsValue);
      // get userinfo
      try {
        const ListOfuser = await API.graphql({
          query: listUserInfos,
        });
        const userinfo = ListOfuser.data.listUserInfos.items;
        for (let i = 0; i < ListOfPostDetailsValue.length; i++) {
          const Aduser = ListOfPostDetailsValue[i];
          if (Aduser.assigned_to_userid != null) {
            const username = userinfo.filter(
              (item) => item.id === Aduser.assigned_by_userid
            );
            const user_fname = username[0].user_fname;
            const user_lname = username[0].user_lname;
            Aduser.assigned_by_userid = user_fname + " " + user_lname;
            const usernamevalue = userinfo.filter(
              (item) => item.id === Aduser.assigned_to_userid
            );
            const user_lname1 = usernamevalue[0].user_fname;
            const user_fname1 = usernamevalue[0].user_lname;
            Aduser.assigned_to_userid = user_lname1 + " " + user_fname1;
            console.log("username", user_fname, user_lname);
          } else {
            const username = userinfo.filter(
              (item) => item.id === Aduser.assigned_by_userid
            );
            const user_fname2 = username[0].user_fname;
            const user_lname2 = username[0].user_lname;
            Aduser.assigned_by_userid = user_fname2 + " " + user_lname2;
            console.log("usernamedfsfddsf", username[0].user_fname);
          }
          console.log("Full", ListOfPostDetailsValue);
          setTimeout(() => {
            setListOfPostFullHistory(
              ListOfPostDetailsValue.sort(function (a, b) {
                return a._lastChangedAt - b._lastChangedAt;
              })
            );
            console.log("ListOfFullHistory", ListOfPostFullHistory);
          }, 100);
        }
      } catch (err) {
        console.log("error fetching:", err);
      }
    } catch (err) {
      console.log("error fetching:", err);
    }
  };
  return (
    <>
      {/* <ToastContainer autoClose={1000} /> */}
      <Modal size="xl" show={show} onHide={handleCloseAss} border="none">
        <Modal.Header
          style={{
            border: "none",
            padding: "2em",
            paddingBottom: "0em",
            display: "flow-root",
          }}
        >
          <Flex direction={{ base: "column", large: "column", xl: "column" }}>
            <Text fontSize={{ base: "1.3em", large: "1.5em" }} fontWeight="400">
              Post History{" "}
              <Icon
                as={FormatAlignLeftOutlinedIcon}
                fontSize={{ base: "large", large: "xl" }}
                color="black"
                marginTop={"-5px"}
                fontWeight={"bold"}
              ></Icon>
            </Text>
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ padding: "2em", paddingTop: "1em" }}>
          {ListOfPostFullHistory.length > 3 ? (
<ScrollView width="100%" height={{
              base: "250px",
              small: "250px",
              medium: "250px",
              large: "300px",
              xl: "400px",
              xxl: "400px",
            }} marginBottom={"2em"} overFlow="hidden">
              <View
                width="100%"
                paddingLeft={{
                  base: "0em",
                  small: "0em",
                  medium: "0em",
                  large: "2em",
                  xl: "5em",
                  xxl: "5em",
                }}
                paddingRight={{
                  base: "0em",
                  small: "0em",
                  medium: "0em",
                  large: "2em",
                  xl: "5em",
                  xxl: "5em",
                }}
                margin="1em 0em"
              >
                {ListOfPostFullHistory.map((data, index) => (
                  <section id="status-timeline" class="status-container" ref={messagesEndRef}>

                    {data.post_status_id == "6" ? ListOfPostFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={RiDraftLine}
                            fontSize={{ base: "large", large: "xxs" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={RiSave3Line}
                              fontSize={{ base: "1em", large: "1.3em" }}
                              color="rgb(189, 189, 189)"
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Draft
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(149, 4, 149)" }}
                      >
                        <Icon
                          as={RiDraftLine}
                          fontSize={{ base: "large", large: "xxs" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(149, 4, 149)" }}
                        >
                          {" "}
                          <Icon
                            as={RiSave3Line}
                            fontSize={{ base: "1em", large: "1.3em" }}
                            color="#84d851"
                            fontWeight={"bold"}
                            className="img"
                            marginTop={"-0.2em"}
                          ></Icon>{" "}
                          Draft
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>
                      : null}
                    {data.post_status_id == "5" ? ListOfPostFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={RiEditLine}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={EditIcon}
                              fontSize={{ base: "1em", large: "1.3em", color: "rgb(189, 189, 189)" }}
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Requires Edits
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(191, 128, 64)" }}
                      >
                        <Icon
                          as={RiEditLine}
                          fontSize={{ base: "large", large: "xl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(191, 128, 64)" }}
                        >
                          <Icon
                            as={EditIcon}
                            fontSize={{ base: "1em", large: "1.3em" }}
                            color="red"
                            fontWeight={"bold"}
                            className="img"
                            marginTop={"-0.2em"}
                          ></Icon>{" "}
                          Requires Edits
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div> : null}
                    {data.post_status_id == "2" ? ListOfPostFullHistory[index + 1] ? ListOfPostFullHistory[index + 1].post_status_id == "3" || ListOfPostFullHistory[index + 1].ad_status_id == "5" ?
                      (<>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PendingActionsOutlinedIcon}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color:"rgb(189, 189, 189)"}}
                            >
                              {" "}
                              <Icon
                                as={MdPending}
                                fontSize={{ base: "1.5em", large: "1.5em" ,color:"rgb(189, 189, 189)" }}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Pending Approval
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em",color:"rgb(189, 189, 189)" }}
                              />
                                {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                            </span>
                          </div>
                        </div>
                      </>)
                      :
                      (<>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PendingActionsOutlinedIcon}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={MdPending}
                                fontSize={{ base: "1.5em", large: "1.5em" }}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Pending Approval
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xxl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "1.5em", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(189, 189, 189)"
                                marginRight={"-0.3em"}
                                fontWeight={"bold"}
                              ></Icon>{" "}
                              Yet to Approve
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>)
                      : (<>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background:"rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={PendingActionsOutlinedIcon}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)" ,color:"rgb(189, 189, 189)"}}
                          >
                            <h2 style={{ marginBottom: "0em",color:"rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color:"rgb(189, 189, 189)"}}
                            >
                              {" "}
                              <Icon
                                as={MdPending}
                                fontSize={{ base: "1.5em", large: "1.5em" ,color:"rgb(189, 189, 189)"}}
                                fontWeight={"bold"}
                                className="img"
                                marginTop={"-0.2em"}
                              ></Icon>{" "}
                              Pending Approval
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em",color:"rgb(189, 189, 189)" }}
                              />
                                {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(191, 191, 64)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)" }}
                          >
                            <h2 style={{ marginBottom: "0em" }}>
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(191, 191, 64)" }}
                            >
                              {" "}
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "1.5em", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(191, 64, 64)"
                                marginRight={"-0.3em"}
                                fontWeight={"bold"}
                              ></Icon>{" "}
                              Yet to Approve
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em" }}
                              />
                               {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                            </span>
                          </div>
                        </div>
                      </>) : null}
                    {data.post_status_id == "3" ? ListOfPostFullHistory[index + 1] ? ListOfPostFullHistory[index + 1].post_status_id == "4" || ListOfPostFullHistory[index + 1].post_status_id == "5"   ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={HowToRegIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                            Approved
                          </span>
                          <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>) : (
                      <>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={HowToRegIcon}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {data.assigned_by_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              {" "}
                              <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                              Approved
                            </span>
                            <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                        <div class="status-timeline-block">
                          <div
                            class="status-timeline-img status-picture"
                            style={{ background: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={MdOutlinePending}
                              fontSize={{ base: "large", large: "xl" }}
                              color="white"
                              fontWeight={"bold"}
                              className="img"
                            ></Icon>
                          </div>
                          <div
                            class="status-timeline-content"
                            style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                          >
                            <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                              {" "}
                              {data.assigned_to_userid}
                            </h2>
                            <span
                              class="status-read-more"
                              style={{ color: "rgb(189, 189, 189)" }}
                            >
                              <Icon
                                as={GoIssueOpened}
                                fontSize={{ base: "large", large: "1.8em" }}
                                marginTop="0.2em"
                                color="rgb(189, 189, 189)"
                                marginRight={"-0.2em"}
                                fontWeight={"bold"}
                              ></Icon>
                              Yet to publish
                            </span>
                            <span class="status-date">
                              <CalendarMonthIcon
                                sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                              />
                              {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toDateString()
                                : "-"}, {data._lastChangedAt
                                  ? new Date(data._lastChangedAt).toLocaleTimeString()
                                  : "-"}
                            </span>
                          </div>
                        </div>
                      </>) : (
                      <>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)"}}
                        >
                          <Icon
                            as={HowToRegIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{  color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                            Approved
                          </span>
                          <span class="status-date" style={{ color: "rgb(189, 189, 189)"}}>
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(191, 191, 64)" }}
                        >
                          <Icon
                            as={MdOutlinePending}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {" "}
                            {data.assigned_to_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(191, 191, 64)" }}
                          >
                            <Icon
                              as={GoIssueOpened}
                              fontSize={{ base: "large", large: "1.8em" }}
                              marginTop="0.2em"
                              color="rgb(191, 64, 64)"
                              marginRight={"-0.2em"}
                              fontWeight={"bold"}
                            ></Icon>
                            Yet to publish
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    </>) : null}
                    {data.post_status_id == "1" ? ListOfPostFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={UnpublishedOutlinedIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={MdCancel}
                              fontSize={{ base: "xl", large: "1.2em", color: "rgb(189, 189, 189)" }}
                              fontWeight={"bold"}
                              marginTop={"-0.1em"}
                            ></Icon>{" "}
                            Rejected
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(191, 64, 64)" }}
                      >
                        <Icon
                          as={UnpublishedOutlinedIcon}
                          fontSize={{ base: "large", large: "xl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(191, 64, 64)" }}
                        >
                          {" "}
                          <Icon
                            as={MdCancel}
                            fontSize={{ base: "xl", large: "1.2em" }}
                            color="red"
                            fontWeight={"bold"}
                            marginTop={"-0.1em"}
                          ></Icon>{" "}
                          Rejected
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div> : null}
                    {data.post_status_id == "4" ? ListOfPostFullHistory[index + 1] ? (
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={PublishedWithChangesIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={FcApproval}
                              fontSize={{ base: "xs", large: "xs" }}
                              color="rgb(189, 189, 189)"
                              fontWeight={"bold"}
                              marginRight="1.5em"
                              marginTop={"-1.2em"}
                            ></Icon>
                            Published
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    ) : <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(67, 168, 84)" }}
                      >
                        <Icon
                          as={PublishedWithChangesIcon}
                          fontSize={{ base: "large", large: "xxl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(67, 168, 84)" }}
                        >
                          <Icon
                            as={FcApproval}
                            fontSize={{ base: "xl", large: "small" }}
                            color="white"
                            fontWeight={"bold"}
                            marginRight="1.5em"
                            marginTop={"-1.2em"}
                          ></Icon>
                          Published
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div> : null}
                  </section>
                ))}
              </View>
            </ScrollView>) : (<View
              width="100%"
              paddingLeft={{
                base: "0em",
                small: "0em",
                medium: "0em",
                large: "2em",
                xl: "5em",
                xxl: "5em",
              }}
              paddingRight={{
                base: "0em",
                small: "0em",
                medium: "0em",
                large: "2em",
                xl: "5em",
                xxl: "5em",
              }}
              margin="1em 0em"
            >
              {ListOfPostFullHistory.map((data, index) => (
                <section id="status-timeline" class="status-container" ref={messagesEndRef}>

                  {data.post_status_id == "6" ? ListOfPostFullHistory[index + 1] ? (
                    <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(189, 189, 189)" }}
                      >
                        <Icon
                          as={RiDraftLine}
                          fontSize={{ base: "large", large: "xxs" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                      >
                        <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(189, 189, 189)" }}
                        >
                          {" "}
                          <Icon
                            as={RiSave3Line}
                            fontSize={{ base: "1em", large: "1.3em" }}
                            color="rgb(189, 189, 189)"
                            fontWeight={"bold"}
                            className="img"
                            marginTop={"-0.2em"}
                          ></Icon>{" "}
                          Draft
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>
                  ) : <div class="status-timeline-block">
                    <div
                      class="status-timeline-img status-picture"
                      style={{ background: "rgb(149, 4, 149)" }}
                    >
                      <Icon
                        as={RiDraftLine}
                        fontSize={{ base: "large", large: "xxs" }}
                        color="white"
                        fontWeight={"bold"}
                        className="img"
                      ></Icon>
                    </div>
                    <div
                      class="status-timeline-content"
                      style={{ boxShadow: "var(--amplify-shadows-large)" }}
                    >
                      <h2 style={{ marginBottom: "0em" }}>
                        {data.assigned_by_userid}
                      </h2>
                      <span
                        class="status-read-more"
                        style={{ color: "rgb(149, 4, 149)" }}
                      >
                        {" "}
                        <Icon
                          as={RiSave3Line}
                          fontSize={{ base: "1em", large: "1.3em" }}
                          color="#84d851"
                          fontWeight={"bold"}
                          className="img"
                          marginTop={"-0.2em"}
                        ></Icon>{" "}
                        Draft
                      </span>
                      <span class="status-date">
                        <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                        {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                      </span>
                    </div>
                  </div>
                    : null}
                  {data.post_status_id == "5" ? ListOfPostFullHistory[index + 1] ? (
                    <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(189, 189, 189)" }}
                      >
                        <Icon
                          as={RiEditLine}
                          fontSize={{ base: "large", large: "xl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                      >
                        <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={EditIcon}
                            fontSize={{ base: "1em", large: "1.3em", color: "rgb(189, 189, 189)" }}
                            fontWeight={"bold"}
                            className="img"
                            marginTop={"-0.2em"}
                          ></Icon>{" "}
                          Requires Edits
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>
                  ) : <div class="status-timeline-block">
                    <div
                      class="status-timeline-img status-picture"
                      style={{ background: "rgb(191, 128, 64)" }}
                    >
                      <Icon
                        as={RiEditLine}
                        fontSize={{ base: "large", large: "xl" }}
                        color="white"
                        fontWeight={"bold"}
                        className="img"
                      ></Icon>
                    </div>
                    <div
                      class="status-timeline-content"
                      style={{ boxShadow: "var(--amplify-shadows-large)" }}
                    >
                      <h2 style={{ marginBottom: "0em" }}>
                        {data.assigned_by_userid}
                      </h2>
                      <span
                        class="status-read-more"
                        style={{ color: "rgb(191, 128, 64)" }}
                      >
                        <Icon
                          as={EditIcon}
                          fontSize={{ base: "1em", large: "1.3em" }}
                          color="red"
                          fontWeight={"bold"}
                          className="img"
                          marginTop={"-0.2em"}
                        ></Icon>{" "}
                        Requires Edits
                      </span>
                      <span class="status-date">
                        <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                        {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                      </span>
                    </div>
                  </div> : null}
                  {data.post_status_id == "2" ? ListOfPostFullHistory[index + 1] ? ListOfPostFullHistory[index + 1].post_status_id == "3" || ListOfPostFullHistory[index + 1].post_status_id == "5" ?
                    (<>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={PendingActionsOutlinedIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={MdPending}
                              fontSize={{ base: "1.5em", large: "1.5em", color: "rgb(189, 189, 189)" }}
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Pending Approval
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    </>)
                    :
                    (<>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background:"rgb(189, 189, 189)"}}
                        >
                          <Icon
                            as={PendingActionsOutlinedIcon}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" ,color:"rgb(189, 189, 189)"}}
                        >
                          <h2 style={{ marginBottom: "0em" ,color:"rgb(189, 189, 189)"}}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{color:"rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={MdPending}
                              fontSize={{ base: "1.5em", large: "1.5em"}}
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Pending Approval
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em",color:"rgb(189, 189, 189)"}}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)"}}
                        >
                          <Icon
                            as={MdOutlinePending}
                            fontSize={{ base: "large", large: "xxl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)",color:"rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em" ,color:"rgb(189, 189, 189)"}}>
                            {data.assigned_to_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color:"rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={GoIssueOpened}
                              fontSize={{ base: "1.5em", large: "1.8em" }}
                              marginTop="0.2em"
                              color="rgb(189, 189, 189)"
                              marginRight={"-0.3em"}
                              fontWeight={"bold"}
                            ></Icon>{" "}
                            Yet to Approve
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em",color:"rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    </>)
                    : (<>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={PendingActionsOutlinedIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                              as={MdPending}
                              fontSize={{ base: "1.5em", large: "1.5em", color: "rgb(189, 189, 189)" }}
                              fontWeight={"bold"}
                              className="img"
                              marginTop={"-0.2em"}
                            ></Icon>{" "}
                            Pending Approval
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(191, 191, 64)" }}
                        >
                          <Icon
                            as={MdOutlinePending}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {data.assigned_to_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(191, 191, 64)" }}
                          >
                            {" "}
                            <Icon
                              as={GoIssueOpened}
                              fontSize={{ base: "1.5em", large: "1.8em" }}
                              marginTop="0.2em"
                              color="rgb(191, 64, 64)"
                              marginRight={"-0.3em"}
                              fontWeight={"bold"}
                            ></Icon>{" "}
                            Yet to Approve
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    </>) : null}
                  {data.post_status_id == "3" ? ListOfPostFullHistory[index + 1] ? ListOfPostFullHistory[index + 1].post_status_id == "4" || ListOfPostFullHistory[index + 1].post_status_id == "5" ? (
                    <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(189, 189, 189)" }}
                      >
                        <Icon
                          as={HowToRegIcon}
                          fontSize={{ base: "large", large: "xxl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)" }}
                      >
                        <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(189, 189, 189)" }}
                        >
                          {" "}
                          <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                          Approved
                        </span>
                        <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                          <CalendarMonthIcon
                            sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                          />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>) : (
                    <>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={HowToRegIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            {" "}
                            <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                            Approved
                          </span>
                          <span class="status-date" style={{color: "rgb(189, 189, 189)"}}>
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={MdOutlinePending}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                            {" "}
                            {data.assigned_to_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(189, 189, 189)" }}
                          >
                            <Icon
                              as={GoIssueOpened}
                              fontSize={{ base: "large", large: "1.8em" }}
                              marginTop="0.2em"
                              color="rgb(189, 189, 189)"
                              marginRight={"-0.2em"}
                              fontWeight={"bold"}
                            ></Icon>
                            Yet to publish
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    </>) : (
                    <>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={HowToRegIcon}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em", color:"rgb(189, 189, 189)" }}>
                            {data.assigned_by_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color:"rgb(189, 189, 189)"  }}
                          >
                            {" "}
                            <Icon
                                as={VerifiedIcon}
                                fontSize={{ base: "xs", large: "xl" }}
                                color="rgb(189, 189, 189)"
                                fontWeight={"bold"}
                                marginTop="-0.2em"
                              ></Icon>{" "}
                            Approved
                          </span>
                          <span class="status-date" style={{color:"rgb(189, 189, 189)" }}>
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em",color:"rgb(189, 189, 189)"  }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                      <div class="status-timeline-block">
                        <div
                          class="status-timeline-img status-picture"
                          style={{ background: "rgb(191, 191, 64)" }}
                        >
                          <Icon
                            as={MdOutlinePending}
                            fontSize={{ base: "large", large: "xl" }}
                            color="white"
                            fontWeight={"bold"}
                            className="img"
                          ></Icon>
                        </div>
                        <div
                          class="status-timeline-content"
                          style={{ boxShadow: "var(--amplify-shadows-large)" }}
                        >
                          <h2 style={{ marginBottom: "0em" }}>
                            {" "}
                            {data.assigned_to_userid}
                          </h2>
                          <span
                            class="status-read-more"
                            style={{ color: "rgb(191, 191, 64)" }}
                          >
                            <Icon
                              as={GoIssueOpened}
                              fontSize={{ base: "large", large: "1.8em" }}
                              marginTop="0.2em"
                              color="rgb(191, 64, 64)"
                              marginRight={"-0.2em"}
                              fontWeight={"bold"}
                            ></Icon>
                            Yet to publish
                          </span>
                          <span class="status-date">
                            <CalendarMonthIcon
                              sx={{ mt: "-0.2em", mr: "0.2em" }}
                            />
                            {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toDateString()
                              : "-"}, {data._lastChangedAt
                                ? new Date(data._lastChangedAt).toLocaleTimeString()
                                : "-"}
                          </span>
                        </div>
                      </div>
                    </>) : null}
                  {data.post_status_id == "1" ? ListOfPostFullHistory[index + 1] ? (
                    <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(189, 189, 189)" }}
                      >
                        <Icon
                          as={UnpublishedOutlinedIcon}
                          fontSize={{ base: "large", large: "xl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                      >
                        <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(189, 189, 189)" }}
                        >
                          {" "}
                          <Icon
                            as={MdCancel}
                            fontSize={{ base: "xl", large: "1.2em", color: "rgb(189, 189, 189)" }}
                            fontWeight={"bold"}
                            marginTop={"-0.1em"}
                          ></Icon>{" "}
                          Rejected
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>
                  ) : <div class="status-timeline-block">
                    <div
                      class="status-timeline-img status-picture"
                      style={{ background: "rgb(191, 64, 64)" }}
                    >
                      <Icon
                        as={UnpublishedOutlinedIcon}
                        fontSize={{ base: "large", large: "xl" }}
                        color="white"
                        fontWeight={"bold"}
                        className="img"
                      ></Icon>
                    </div>
                    <div
                      class="status-timeline-content"
                      style={{ boxShadow: "var(--amplify-shadows-large)" }}
                    >
                      <h2 style={{ marginBottom: "0em" }}>
                        {data.assigned_by_userid}
                      </h2>
                      <span
                        class="status-read-more"
                        style={{ color: "rgb(191, 64, 64)" }}
                      >
                        {" "}
                        <Icon
                          as={MdCancel}
                          fontSize={{ base: "xl", large: "1.2em" }}
                          color="red"
                          fontWeight={"bold"}
                          marginTop={"-0.1em"}
                        ></Icon>{" "}
                        Rejected
                      </span>
                      <span class="status-date">
                        <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                        {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                      </span>
                    </div>
                  </div> : null}
                  {data.post_status_id == "4" ? ListOfPostFullHistory[index + 1] ? (
                    <div class="status-timeline-block">
                      <div
                        class="status-timeline-img status-picture"
                        style={{ background: "rgb(189, 189, 189)" }}
                      >
                        <Icon
                          as={PublishedWithChangesIcon}
                          fontSize={{ base: "large", large: "xxl" }}
                          color="white"
                          fontWeight={"bold"}
                          className="img"
                        ></Icon>
                      </div>
                      <div
                        class="status-timeline-content"
                        style={{ boxShadow: "var(--amplify-shadows-large)", color: "rgb(189, 189, 189)" }}
                      >
                        <h2 style={{ marginBottom: "0em", color: "rgb(189, 189, 189)" }}>
                          {data.assigned_by_userid}
                        </h2>
                        <span
                          class="status-read-more"
                          style={{ color: "rgb(189, 189, 189)" }}
                        >
                          <Icon
                            as={FcApproval}
                            fontSize={{ base: "xs", large: "xs" }}
                            color="rgb(189, 189, 189)"
                            fontWeight={"bold"}
                            marginRight="1.5em"
                            marginTop={"-1.2em"}
                          ></Icon>
                          Published
                        </span>
                        <span class="status-date">
                          <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em", color: "rgb(189, 189, 189)" }} />
                          {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toDateString()
                            : "-"}, {data._lastChangedAt
                              ? new Date(data._lastChangedAt).toLocaleTimeString()
                              : "-"}
                        </span>
                      </div>
                    </div>
                  ) : <div class="status-timeline-block">
                    <div
                      class="status-timeline-img status-picture"
                      style={{ background: "rgb(67, 168, 84)" }}
                    >
                      <Icon
                        as={PublishedWithChangesIcon}
                        fontSize={{ base: "large", large: "xxl" }}
                        color="white"
                        fontWeight={"bold"}
                        className="img"
                      ></Icon>
                    </div>
                    <div
                      class="status-timeline-content"
                      style={{ boxShadow: "var(--amplify-shadows-large)" }}
                    >
                      <h2 style={{ marginBottom: "0em" }}>
                        {data.assigned_by_userid}
                      </h2>
                      <span
                        class="status-read-more"
                        style={{ color: "rgb(67, 168, 84)" }}
                      >
                        <Icon
                          as={FcApproval}
                          fontSize={{ base: "xl", large: "small" }}
                          color="white"
                          fontWeight={"bold"}
                          marginRight="1.5em"
                          marginTop={"-1.2em"}
                        ></Icon>
                        Published
                      </span>
                      <span class="status-date">
                        <CalendarMonthIcon sx={{ mt: "-0.2em", mr: "0.2em" }} />
                        {data._lastChangedAt
                          ? new Date(data._lastChangedAt).toDateString()
                          : "-"}, {data._lastChangedAt
                            ? new Date(data._lastChangedAt).toLocaleTimeString()
                            : "-"}
                  </span>
                </div>
              </div> : null}
              </section>
            ))}
          </View>)}
          <View style={{ width: "100%" }}>
            <Flex
              direction={{
                base: "column",
                small: "column",
                large: "row",
                xl: "row",
              }}
              gap={{ base: "large", large: "large" ,xl:"xl"}}
            >
              <Text
                fontSize={"1em"}
                fontWeight="400"
                marginTop={{ base: "0em", medium: "5px", large: "5px" }}
              >
                State
              </Text>
              <SelectField
                labelHidden
                icon={<IconArrowDropDown />}
                iconColor="black"
                value={Adstatu}
                width={{ base: "100%", medium: "100%", large: "25%", xl:"30%" }}
                onChange={(e) => {
                  OnAdStateChange(e.target.value);
                }}
                disabled={DisableFieldsAfterpublished}
              >
                {AdstatusList != undefined
                  ? AdstatusList.map((y) => {
                      return (
                        <option value={y.status_id}>{y.status_name}</option>
                      );
                    })
                  : null}
              </SelectField>
              {/*  */}
              {Adstatu !="4" && Adstatu !="1" &&(<>
              <Text
                fontSize={"1em"}
                fontWeight="400"
                marginTop={{ base: "0em", medium: "5px", large: "5px" }}
              >
                Assigned to
              </Text>
              <SelectField
                labelHidden
                icon={<IconArrowDropDown />}
                iconColor="black"
                value={selectedUser}
                width={{ base: "100%", medium: "100%", large: "25%",xl:"30%" }}
                onChange={(e) => {
                  setselectedUser(e.target.value);
                }}
                disabled={DisableFieldsAfterpublished}
              >
                {userList != undefined
                  ? userList.map((x) => {
                      return (
                        <option value={x.id}>
                          {x.user_fname + " " + x.user_lname}
                        </option>
                      );
                    })
                  : null}
              </SelectField>
              </>
            )}
              <Button
                style={primaryButton}
                boxShadow="large"
                onClick={updateAssign}
                loadingText="Processing"
                isLoading={loading}
                margin="auto"
                borderRadius="1em"
                disabled={DisableFieldsAfterpublished}
              >
                Submit{" "}
                <Icon
                  as={BsHandIndexThumb}
                  fontSize={{ base: "xl", large: "xl" }}
                  color="white"
                  fontWeight={"bold"}
                  marginLeft="0.3em"
                  marginTop={"0.3em"}
                ></Icon>
              </Button>
              {/* <Button style={primaryButton} boxShadow="large" onClick={handleCloseAss}>
            Cancel
            <Icon
              as={BsHandIndexThumb}
              fontSize={{ large: "xl" }}
              color="white"
              fontWeight={"bold"}
              marginLeft="0.3em"
              marginTop={"0.3em"}
            ></Icon>
          </Button> */}
            </Flex>
          </View>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Assinged;
