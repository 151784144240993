import "../../App.css";
import {
  Grid,
  View,
  useTheme,
  Flex,
  Heading,
  Button,
  SelectField,
} from "@aws-amplify/ui-react";
import { primaryButton, dsbprimaryButton } from "../../assets/style";
import { ToastContainer, toast } from "react-toastify";
import { createFBLoginInfo } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import DashboardHeader from "../../layout/DashboardHeader";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Helper from "../../assets/Helper";
import { Auth } from 'aws-amplify'

const Facebook = () => {
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const location = useLocation();
  const [logged, setlogged] = useState(true)
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  let { data } = location.state;
  let [fpagelist, setPage] = useState([]);
  let [fadslist, setads] = useState([]);
  const [fbuserid, setFbuserid] = useState("");
  const [username, setusername] = useState("");
  const [longaccess, setLongaccess] = useState("");
  const [selectpage, setselectpage] = useState("0");
  const [selectads_account, setads_account] = useState("0");
  const [company_id, setcompany_id] = useState("");

  //page render
  useEffect(() => {
    setFbuserid(data.id);
    setusername(data.name);
    setLongaccess(data.accessToken);
    setcompany_id(location.state.compIdFromSocialAccount);
    //Connecting to Facebook
    axios({
      method: "get",
      url:
        Helper.FACEBOOK_LONG_ACCESS_TOKEN_URL +
        "?grant_type=fb_exchange_token&client_id=" +
        Helper.FACEBOOK_APP_ID +
        "&client_secret=" +
        Helper.FACEBOOK_CLIENT_ID +
        "&fb_exchange_token=" +
        data.accessToken,
      responseType: "stream",
    }).then(function (responsec) {
      // console.log(responsec);
      // console.log(responsec.data.access_token);
      setLongaccess(responsec.data.access_token);
    });
    // -----------------------------------------------------------------------------(OR)---------------------------------------------------------------------------------------
    // axios({
    //   method: "get",
    //   url:
    //     "https://graph.facebook.com/v15.0/oauth/access_token?grant_type=fb_exchange_token&client_id=320067396410532&client_secret=65a6a477a0fec2b2a85afd39aa0ca3dc&fb_exchange_token=" +
    //     data.accessToken,
    //   responseType: "stream",
    // }).then(function (responsec) {
    //   console.log(responsec);
    //   console.log(responsec.data.access_token);
    //   setLongaccess(responsec.data.access_token);
    // });
    // -----------------------------------------------------------------------------####----------------------------------------------------------------------------------------

    //Getting Page List
    axios({
      method: "get",
      url:
        "https://graph.facebook.com//v15.0/" +
        data.id +
        "/accounts?fields=id,name&access_token=" +
        data.accessToken,
      responseType: "stream",
    }).then(function (responsef) {
      console.log(responsef);
      let pagedata = responsef.data.data;

      let pagelist = pagedata.map((item, i) => {
        return (
          <option key={i} value={item.name + "~" + item.id}>
            {item.name + " (" + item.id + ")"}
          </option>
        );
      });

      setPage(pagelist);
    });

    //Getting Ads Account List
    axios({
      method: "get",
      url:
        "https://graph.facebook.com/v15.0/" +
        data.id +
        "/adaccounts?fields=id,name&access_token=" +
        data.accessToken,
      responseType: "stream",
    }).then(function (responsed) {
      console.log(responsed);
      let adsdata = responsed.data.data;

      let adslist = adsdata.map((item, i) => {
        return (
          <option key={i} value={item.name + "~" + item.id}>
            {item.name + " (" + item.id + ")"}
          </option>
        );
      });

      console.log(adslist);
      setads(adslist);
    });
  }, []);

  //page redirect
  const cancelconnection = () => {
    navigate("/account", { state: { From: "dashboard", tabindex: "4" } });
  };

  //Create Connection Click
  const submitconnection = async () => {
    try {
      if (selectpage == "0") {
      }
      if (selectads_account == "0") {
      } else {
        const saveInfos = {
          user_id: localStorage.getItem("userid"),
          company_id: location.state.compIdFromSocialAccount,
          facebook_userid: fbuserid,
          facebook_username: username,
          pagename: selectpage.split("~")[0],
          pageid: selectpage.split("~")[1],
          long_access_token: longaccess,
          ads_accountname: selectads_account.split("~")[0],
          ads_accountid: selectads_account.split("~")[1],
        };

        await API.graphql(
          graphqlOperation(createFBLoginInfo, { input: saveInfos })
        );

        navigate("/account", {
          state: {
            From: "dashboard",
            tabindex: "4",
            FcompanyId: location.state.compIdFromSocialAccount,
          },
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />

      <Grid
        templateColumns={{ base: "1fr", large: "1fr" }}
        templateRows={{
          base: "3rem 1rem 34rem 2rem",
          medium: "3rem 1rem 64rem 2rem",
          large: "2rem 1rem 27rem 2rem",
        }}
        gap={tokens.space.xs}
      >
        <View>
          <DashboardHeader></DashboardHeader>
        </View>
        <View></View>
        <View>
          <Flex
            direction={"column"}
            style={shadowProp}
            marginTop={{ base: "large", medium: "xl", large: "xxxl" }}
            maxWidth={{ base: "124vw", medium: "60vw", large: "40vw" }}
            marginInline={{ base: "medium", medium: "xl", large: "auto" }}
            height={{ base: "25rem", medium: "25rem", large: "25rem" }}
          >
            <Heading
              marginInline={"auto"}
              level={4}
              marginTop={{ base: "large", medium: "xl", large: "xxl" }}
            >
              Connecting To Facebook
            </Heading>

            <Flex
              direction={"column"}
              marginLeft={{ base: "medium", medium: "xxl", large: "xxxl" }}
              maxWidth={{ base: "111vw", medium: "50vw", large: "30vw" }}
              height={"100%"}
              gap={tokens.space.large}
            >
              <SelectField
                label="Where Would You Like To Publish The Post From This Account?"
                onChange={(event) => setselectpage(event.target.value)}
                width="100%"
              >
                <option value="0">--Select--</option>
                {fpagelist}
              </SelectField>

              <SelectField
                label="Ads Account"
                width="100%"
                onChange={(event) => setads_account(event.target.value)}
              >
                <option value="0">--Select--</option>
                {fadslist}
              </SelectField>

              <div></div>
              <Flex>
                <Button
                  style={
                    selectpage == 0 || selectads_account == 0
                      ? dsbprimaryButton
                      : primaryButton
                  }
                  disabled={
                    selectpage == 0 || selectads_account == 0 ? true : false
                  }
                  width="50%"
                  onClick={submitconnection}
                >
                  Create Connection
                </Button>
                <Button
                  onClick={cancelconnection}
                  width="50%"
                  style={primaryButton}
                >
                  {" "}
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </View>
        <View></View>
      </Grid>
    </div>
  );
};
export default Facebook;
