import { Theme } from "@aws-amplify/ui-react";

export const ResetIcon = {
  backgroundColor: "white",
  color: "#A0A0A0 !important",
  border: "1px solid white",
  borderRadius: "10px",
};

export const primaryButton = {
  backgroundColor: "hsl(130, 43%, 46%)",
  size: "small",
  variation: "primary",
  color: "#fff",
  height: "2rem",
  border: "rgb(33, 46, 80)",
  float: "center",
};

export const groupButton = {
  backgroundColor: "hsl(130, 43%, 46%)",
  size: "small",
  variation: "primary",
  color: "#fff",
  height: "2rem",
  border: "hsl(130, 43%, 46%)",
  float: "center",
};

export const dsbprimaryButton = {
  backgroundColor: "rgb(146 199 155)",
  size: "small",
  variation: "primary",
  color: "#fff",
  height: "2rem",
  border: "rgb(146 199 155)",
  float: "center",
};
export const secondaryButton = {
  backgroundColor: "hsl(220, 70%, 70%)",

  size: "small",

  variation: "primary",

  color: "#fff",

  height: "2rem",

  border: "none",

  float: "center",
};

export const dashboardLinkButton = {
  size: "small",
  border: "transparent",
  color: "hsl(220, 50%, 50%)",
  variation: "link",
  fontSize: "0.8em",
  fontStyle: "normal",
};

export const viewCompList = {
  boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
};

export const searchfield = {
  border: "hsl(220, 50%, 50%)",
};

export const accountTabTheme: Theme = {
  name: "tabs-theme",
  tokens: {
    components: {
      tabs: {
        borderColor: { value: "{colors.neutral.20}" },
        item: {
          color: { value: "{colors.blue.80}" },
          fontSize: { value: "{fontSizes.small}" },
          fontWeight: { value: "{fontWeights.normal}" },

          _hover: {
            color: { value: "{colors.blue.60}" },
          },
          _focus: {
            color: { value: "{colors.blue.60}" },
          },
          _active: {
            color: { value: "{colors.blue.80}" },
            borderColor: { value: "{colors.blue.80}" },
            backgroundColor: { value: "{colors.blue.10}" },
          },
          _disabled: {
            color: { value: "gray" },
            backgroundColor: { value: "transparent" },
          },
        },
      },
    },
  },
};

export const createButtonTheme: Theme = {
  name: "button-theme",
  tokens: {
    colors: {
      border: {
        // this will affect the default button's border color
        primary: { value: "black" },
      },
    },
    components: {
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: "{fontWeights.lighter}" },
        // style the primary variation
        primary: {
          backgroundColor: { value: "hsl(130, 43%, 46%)" },
          _hover: {
            backgroundColor: { value: "hsl(130, 33%, 37%)" },
          },
          _focus: {
            backgroundColor: { value: "hsl(130, 33%, 37%)" },
          },
          _active: {
            backgroundColor: { value: "hsl(130, 27%, 29%)" },
          },
        },
      },
    },
  },
};
export const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 1 },
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: "hsl(270deg 55% 42%)",
    color: "#639",
    fontSize: "small",
    fontStyle: "bold",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "1px solid #b9b9b9",
    borderBottom: "1px solid #b9b9b9",
  },
  "& .MuiDataGrid-row, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
    borderRight: "1px solid #b9b9b9",
  },
};
export const manageaccuontdatagrid = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 1 },
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: "hsl(270deg 55% 42%)",
    color: "white",
    fontSize: "small",
    fontStyle: "bold",
    backgroundColor: "rgb(183, 31, 57)",
    textTransform: "capitalize",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "block",
    color: "white",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
  },
  "& .MuiDataGrid-row, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
  },
  // "& .MuiDataGrid-columnHeaders": { display: "none" },
  // "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
};
export const usersettingdatagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 1 },
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: "hsl(270deg 55% 42%)",
    color: "white",
    fontSize: "small",
    fontStyle: "bold",
    backgroundColor: "rgb(183, 31, 57)",
    textTransform: "capitalize",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "block",
    color: "white",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
  },
  "& .MuiDataGrid-row, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
  },
  // "& .MuiDataGrid-columnHeaders": { display: "none" },
  // "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
};

export const primaryButtonselect = {
  size: "small",

  variation: "primary",

  color: "#000",

  border: "1px solid black",

  float: "center",
};
export const newdashboarddatagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 1 },
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: "hsl(270deg 55% 42%)",
    color: "white",
    fontSize: "small",
    fontStyle: "bold",
    backgroundColor: "rgb(183, 31, 57)",
    textTransform: "capitalize",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "block",
    color: "white",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
  },
  "& .MuiDataGrid-row, .MuiDataGrid-cell": {
    borderBottom: "1px solid #b9b9b9",
  },
  // "& .MuiDataGrid-columnHeaders": { display: "none" },
  // "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
};

export const manageposttheme: Theme = {
  name: "tabs-theme",
  tokens: {
    components: {
      tabs: {
        borderColor: { value: "{colors.neutral.20}" },
        item: {
          color: { value: "{colors.blue.80}" },
          fontSize: { value: "1.2em" },
          fontWeight: { value: "{fontWeights.normal}" },
          _hover: {
            color: { value: "{colors.blue.60}" },
          },
          _focus: {
            color: { value: "{colors.blue.60}" },
          },
          _active: {
            color: { value: "{colors.blue.80}" },
            borderColor: { value: "{colors.blue.80}" },
            backgroundColor: { value: "{colors.blue.10}" },
          },
          _disabled: {
            color: { value: "gray" },
            backgroundColor: { value: "transparent" },
          },
        },
      },
    },
  },
};

export const dashboardcounttheme: Theme = {
  name: "tabs-theme",
  tokens: {
    components: {
      tabs: {
        borderColor: { value: "{colors.neutral.20}" },
        item: {
          color: { value: "black" },
          fontSize: { value: "1em" },
          fontWeight: { value: "{fontWeights.normal}" },

          _hover: {
            color: { value: "{colors.blue.60}" },
          },
          _focus: {
            color: { value: "{colors.blue.60}" },
          },
          _active: {
            color: { value: "{colors.blue.80}" },
            borderColor: { value: "{colors.blue.80}" },
            backgroundColor: { value: "white" },
          },
          _disabled: {
            color: { value: "gray" },
            backgroundColor: { value: "transparent" },
          },
        },
      },
    },
  },
};