import React, { useEffect, useState, CSSProperties } from "react";
import {
  Button,
  useTheme,
  TextField,
  Text,
  Icon,
  Flex,
  SelectField,
} from "@aws-amplify/ui-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createAdCampaign, updateAdCampaign } from "../../graphql/mutations";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { listAdCampaigns } from "../../graphql/queries";
import "../../App.css";
import { BiReset, BiSkipNext } from "react-icons/bi";
import { HiChevronDoubleRight } from "react-icons/hi";
import { ResetIcon, primaryButton, secondaryButton } from "../../assets/style";
import { ToastContainer, toast } from "react-toastify";
const Campaign = ({
  editCampainvalue,
  forNewExistingCampaign,
  setConversion_checked_or_not,
  objectivesList,
  categoryList,
  campaignForm,
  setCampaignForm,
  getCampaignFunction,
  adsAccountId,
  accessToken,
  SET_FB_CAMPAIGN_ID,
  FB_CAMPAIGN_ID,
  SET_CAMPID_FRM_DB,
  CAMPID_FRM_DB,
  selectedCampaignname,
  RedirectAdset,
  flagforshowselectedcampaign,
  setflagorshowselectedcampaign,
  campaign_pagesavestatus,
  setcampaign_pagesavestatus,
  DisableAfterPublish,
  JustCreatedCampaignVersion,
  setJustCreatedCampaignVersion
}) => {
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "white",
  };
  //  const [CAMPAIGN_ID, set_CAMPAIGN_ID] = useState("");
  // const [fbcampaignid, set_fbcampaignid] = useState("");
  //const [campidFromSave, set_campidFromSave] = useState();
  const [test, setTest] = useState(false);
  const [Progressbar, setProgressbar] = useState(false);
  const [Existing_campaign, setExisting_campaign] = useState(false);
  const [ExistingCampaignId, setExistingCampaignId] = useState();
  const [ExistingCampaignVersion, setExistingCampaignVersion] = useState();
  //const [JustCreatedCampaignVersion, setJustCreatedCampaignVersion] = useState();
  const [campnameError, setcampnameError] = useState(false);
  const [objectiverror, setobjectiverror] = useState(false);
  const [ExistingSelectedFB_Camp_Id, setExistingSelectedFB_Camp_Id] =
    useState();
    const [HideExisitingcampaign, setHideExisitingcampaign] =
    useState(false);
  function setInput(key, value) {
    setCampaignForm({ ...campaignForm, [key]: value });
    console.log(campaignForm, "campaignForm");
  }

  useEffect(()=>{

    
    if (!Existing_campaign && FB_CAMPAIGN_ID) {
      setflagorshowselectedcampaign(true)
    }
      },[])

  useEffect(() => {
    if (test && FB_CAMPAIGN_ID !== undefined && !Existing_campaign) {
      Savecampaign();
    }
    if (forNewExistingCampaign) {
      listCampaign();
      // getCampaigndeatils();
    }
    if (editCampainvalue !== undefined) {
      console.log(editCampainvalue, "editCampainvalue");
      setExisting_campaign(true);
      setExistingCampaignId(editCampainvalue.id)
      setExistingCampaignVersion(editCampainvalue._version)
      setExistingSelectedFB_Camp_Id(editCampainvalue.fb_campaign_id)
      setHideExisitingcampaign(true);
    }
  }, [
    selectedCampaignname,
    forNewExistingCampaign,
    FB_CAMPAIGN_ID,
    editCampainvalue,
  ]);

  //Reset Campaign Form
  const ResetCampaign = () => {
    setflagorshowselectedcampaign(false);
    setCampaignForm({
      campaign_name: "",
      objective: "",
      special_ad_category: "",
    });
  };

  //getting campaign via FB
  const getCampaigndeatils = () => {
    // console.log(id + "," + name);
// alert("dd")
    axios({
      method: "get",
      url:
        "https://graph.facebook.com/v15.0/" +
        adsAccountId +
        "/campaigns?access_token=" +
        accessToken,
      // url: "https://graph.facebook.com/v15.0/6299586944545/campaigns?access_token=EAAEjGX93nKQBAGSxIrvAltbcd45t1ScB9ZAtnvGb69DCDQaBLLRRaWzY2qjRcJMbZBT1NPL2I3doWwP2NsMrC6DIpLFkAQ9govYwhylWAUm06jo9hxOrLKqdnmNOJqIIMUfyMBUx8Kx9TQ8RHWMIiEYNeuFFCw1Da9C3VR4Qfh7oiPahmQ&limit=100&fields=name,configured_status,effective_status",
      responseType: "stream",
    }).then(function (responsef) {
      console.log(responsef.data,"red");
    });
  };

  //fetch campaign details based on campaign name
  const listCampaign = async (id, name) => {

    try {
      let filter = {
        id: {
          eq: selectedCampaignname, // filter priority = 1
        },
      };

      const userdata = await API.graphql({
        query: listAdCampaigns,
        variables: { filter: filter, editable: true },
      });
      console.log("listcamp", userdata.data.listAdCampaigns.items);
      setExistingCampaignVersion(
        userdata.data.listAdCampaigns.items[0]._version
      );
      setExistingCampaignId(userdata.data.listAdCampaigns.items[0].id);
      SET_CAMPID_FRM_DB(userdata.data.listAdCampaigns.items[0].id);
      setExistingSelectedFB_Camp_Id(
        userdata.data.listAdCampaigns.items[0].fb_campaign_id
      );
      SET_FB_CAMPAIGN_ID(userdata.data.listAdCampaigns.items[0].fb_campaign_id);

      setCampaignForm(userdata.data.listAdCampaigns.items[0]);
      setflagorshowselectedcampaign(true)
    } catch (err) {
      console.log("error fetching actors");
    }
  };

  const BridgeFunction = () => {
    if (!Existing_campaign) {
      // alert("exist")
      if(FB_CAMPAIGN_ID){
        setflagorshowselectedcampaign(true)
        updatecampaignfb();
        // alert(FB_CAMPAIGN_ID)
      }
      else{
        CreateCampaign();

      }
      
    } else {

      updatecampaignfb();
      
    }
  };

  const CreateCampaign = () => {
    //https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#odax-mapping => Reference
    localStorage.setItem("Selectedspecialad", campaignForm.special_ad_category==""?"NONE":campaignForm.special_ad_category);
    var obj = "";
    setProgressbar(true);
    if (!flagforshowselectedcampaign) {
      const add_cat = [campaignForm.special_ad_category];

      if (campaignForm.objective == "Brand Awareness") {
        // obj = "BRAND_AWARENESS";
        obj = "OUTCOME_AWARENESS";
      }
      if (campaignForm.objective == "Reach") {
        // obj = "REACH";
        obj = "OUTCOME_AWARENESS";
      }
      if (campaignForm.objective == "Traffic") {
        // obj = "LINK_CLICKS";
        obj = "OUTCOME_TRAFFIC";
      }
      if (campaignForm.objective == "Video Views") {
        // obj = "VIDEO_VIEWS";
        obj = "OUTCOME_AWARENESS";
      }
      if (campaignForm.objective == "Conversions") {
        // obj = "CONVERSIONS";
        obj = "OUTCOME_ENGAGEMENT";
      }

      if (add_cat.length > 0) {
        if (add_cat[0] == "") {
          add_cat[0] = "NONE";
        }
      } else {
        add_cat[0] = "NONE";
      }
      // console.log(add_cat,"daaaa");
      axios({
        method: "post",
        url:
          "https://graph.facebook.com/v15.0/" +
          adsAccountId +
          "/campaigns?&name=" +
          campaignForm.campaign_name +
          "&objective=" +
          obj +
          "&status=PAUSED&special_ad_categories=" +
           add_cat +
          //"NONE" +
          "&access_token=" +
          accessToken,

        responseType: "stream",
      })
        .then(function (responsef) {
          console.log(responsef.data.id, "campaign_id");
          console.log(responsef, "data");
          SET_FB_CAMPAIGN_ID(responsef.data.id);
          setTest(true);
        })
        .catch((error) => {
          setProgressbar(true)
        if(error.response.data.error.message.indexOf('Objective is invalid') >= 0){
          toast.error("Please select valid objective");
          setTimeout(() => {
            setProgressbar(false)
          }, 2500);

        }
     if(error.response.data.error.message.indexOf('Invalid OAuth access token') >= 0){
          toast.error("Invalid Login Credentials");
          setTimeout(() => {
            setProgressbar(false)
          }, 2500);
        }


          console.log(error.response,"campaignError");

          setcampaign_pagesavestatus(false);
          setProgressbar(false);
        });
    } else {
      setProgressbar(false);
      RedirectAdset();
    }
  };

  //updateCampaign in Fb
  const updatecampaignfb = () => {
    let CampIdforUpdate = ExistingSelectedFB_Camp_Id === undefined?FB_CAMPAIGN_ID:ExistingSelectedFB_Camp_Id;
    var obj = "";
    if (flagforshowselectedcampaign) {
      const add_cat = [campaignForm.special_ad_category];
      if (campaignForm.objective == "Brand Awareness") {
        //  obj = "BRAND_AWARENESS";
        obj = "OUTCOME_AWARENESS";
      }
      else if (campaignForm.objective == "Reach") {
        // obj = "REACH";
        obj = "OUTCOME_AWARENESS";
      }
      else if (campaignForm.objective == "Traffic") {
        // obj = "LINK_CLICKS";
        obj = "OUTCOME_TRAFFIC";
      }
      else if (campaignForm.objective == "Video Views") {
        // obj = "VIDEO_VIEWS";
        obj = "OUTCOME_AWARENESS";
      }
      // if (campaignForm.objective == "Conversions") {
      //    obj = "CONVERSIONS";
      //    alert(obj);
      // }

      axios({
        method: "post",
        url:
          "https://graph.facebook.com/v15.0/" +
          CampIdforUpdate +
          "?name=" +
          campaignForm.campaign_name +
          "&objective=" +
          obj +
          "&status=PAUSED&special_ad_categories=" +
          add_cat +
          "&access_token=" +
          accessToken,

        responseType: "stream",
      })
        .then(function (responsef) {
          console.log(responsef.data.id, "campaign_id");
          console.log(responsef.data.data, "data");
          updatecampaign();
        })
        .catch((error) => {
          setProgressbar(true)
          if(error.response.data.error.message.indexOf('Objective is invalid') >= 0){
            toast.error("Please select valid objective");
            setTimeout(() => {
              setProgressbar(false)
            }, 2500);
          }
          if(error.response.data.error.message.indexOf('Invalid parameter')>=0)
            {
              toast.error("This campaign has been deleted in FB. Please create a new campaign");
            setTimeout(() => {
              setProgressbar(false)
            }, 2500);
          }
       if(error.response.data.error.message.indexOf('Invalid OAuth access token') >= 0){
            toast.error("Invalid OAuth access token");
            setTimeout(() => {
              setProgressbar(false)
            }, 2500);
          }
        });
    } else {
      setProgressbar(false);
     // toast.error("");
      //RedirectAdset();
    }
  };

  //save campaign dynamo db
  const Savecampaign = async () => {
    try {
      const camp = {
        companyinfoID: localStorage.getItem("companyinfoID"),
        campaign_name: campaignForm.campaign_name,
        objective: campaignForm.objective,
        special_ad_category: campaignForm.special_ad_category,
        ads_account_id: adsAccountId,
        fb_campaign_id: FB_CAMPAIGN_ID,
      };

      const obj = await API.graphql(
        graphqlOperation(createAdCampaign, { input: camp })
      );
      console.log(obj.data.createAdCampaign._version, "hello");
      SET_CAMPID_FRM_DB(obj.data.createAdCampaign.id);
      setJustCreatedCampaignVersion(obj.data.createAdCampaign._version)
      // campaignid(obj.data.createAdCampaign.id);
      //fetch campaign dynamo db
      if (obj) {
        try {
          let filter = {
            id: {
              eq: obj.data.createAdCampaign.id,
            },
          };
          const userdata = await API.graphql({
            query: listAdCampaigns,
            variables: { filter: filter },
          });
          console.log(
            "listAdCampaigns",
            userdata.data.listUserInfos.items[0].role_id
          );
          setCampaignForm(userdata.data.listUserInfos.items[0]);
        } catch (err) {
          console.log("error fetching actors");
        }
      }
      setProgressbar(false);
      setcampaign_pagesavestatus(true);
      toast.success("Campaign Created Successfully");
      setTimeout(() => {
        RedirectAdset();
      }, 2000);
    } catch (err) {
      setProgressbar(false);
      toast.error(err.message);
      setcampaign_pagesavestatus(false);
      console.log(err);
    }
  };

  //update campaign dynamo db
  const updatecampaign = async () => {
    try {
      
      // alert("update")
    let  campaignIdforUpdate = ExistingCampaignId !== undefined ?ExistingCampaignId:CAMPID_FRM_DB;
    let versionforUpdate =ExistingCampaignVersion!== undefined ?ExistingCampaignVersion:JustCreatedCampaignVersion;
    // console.log("gggGGG",ExistingCampaignVersion,versionforUpdate,JustCreatedCampaignVersion)

    // console.log("ggg",ExistingCampaignId,FB_CAMPAIGN_ID,campaignIdforUpdate)
      const camp = {
        id: campaignIdforUpdate,
        campaign_name: campaignForm.campaign_name,
        objective: campaignForm.objective,
        special_ad_category: campaignForm.special_ad_category,
        _version: versionforUpdate,
      };
      //console.log(camp, "camp");

      const obj = await API.graphql(
        graphqlOperation(updateAdCampaign, { input: camp })
      );
      setCampaignForm(obj.data.updateAdCampaign);
      toast.success("Campaign Updated Successfully");
      setTimeout(() => {
        RedirectAdset();
      }, 1000);
      setProgressbar(false);
      console.log(obj, "updatecamp");
    } catch (err) {
      setProgressbar(false);
      toast.error("Failed to update Campaign");
      console.log(err, "updatecamp");
    }
  };


  const { tokens } = useTheme();
  const IconArrowDropDown = () => {
    return <Icon pathData="M7 10L12 15L17 10H7Z" ariaLabel="Down arrow" />;
  };

  const handleShow = () => {
    getCampaignFunction();
    setExisting_campaign(true);
    setflagorshowselectedcampaign(true);
  };
  const validate = () => {
  
     setProgressbar(true)
    if (campaignForm.campaign_name == "") {
      
      setcampnameError(true);
    } else {
    
      setcampnameError(false);
    }
    if (campaignForm.objective == "") {

      setobjectiverror(true);
    } else {
      setobjectiverror(false);
    }
    if (campaignForm.campaign_name !== "" && campaignForm.objective !== "") {
      BridgeFunction();
    }
    //////////mandatory/////////


    if(campaignForm.campaign_name == "" || campaignForm.objective =="" ){
      toast.error("Please Fill manadatory fields")

      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
     }

    // if(campaignForm.special_ad_category=="NONE")
    // {
    //  setnoneobj(true);
    // }else{setnoneobj(false);}

    // setcampnameError(false);
    //setobjectiverror(false);
  };
  //validate inputfield
  const validinput = (key, value) => {
    if (key == "campaign_name") {
      if (value.length == "") {
        setcampnameError(true);
      } else {
        setcampnameError(false);
      }
    }
    if (key == "objective") {
      if (value == "") {
        setobjectiverror(true);
      } else {
        setobjectiverror(false);
      }
    }
    setInput(key, value);
  };

  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />
      <Flex
        direction={{
          base: "column",
          small: "column",
          medium: "column",
          large: "column",
          xl: "column",
          xxl: "column",
        }}
        alignItems="center"
        gap={{ large: "xxs" }}
        style={{
          paddingTop: "2em",
          border: "2px solid #E8E8E8",
          borderRadius: "0.5em",
        }}
      >
        <TextField
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
          width={{
            base: "90%",
            small: "80%",
            medium: "60%",
            large: "30%",
          }}
          // label="Campaign Name"
          label={
            <Text>
              Campaign Name
              <Text as="span" fontSize="0.8rem" color="red">
                {" "}
                *
              </Text>
            </Text>
          }
          placeholder="Enter Campaign Name"
          hasError={campnameError}
          value={campaignForm.campaign_name}
          // disabled={flagforshowselectedcampaign}
          onChange={(e) => {
            validinput("campaign_name", e.target.value);
          }}
          disabled={DisableAfterPublish}
        />
       {!HideExisitingcampaign? (<Text
          style={{
            fontWeight: "500",
            textAlign: "right",
            cursor: "pointer",
          }}
          color="#b71f39"
          width={{
            base: "90%",
            small: "80%",
            medium: "60%",
            large: "30%",
          }}
          onClick={handleShow}
        >
          Choose from Existing Campaign
        </Text>):<Text
       marginTop={"1em"}
        />}
        <SelectField
          // label="Objective"
          label={
            <Text>
              Objective
              <Text as="span" fontSize="0.8rem" color="red">
                {" "}
                *
              </Text>
            </Text>
          }
          hasError={objectiverror}
          icon={<IconArrowDropDown />}
          iconColor="black"
          placeholder="Select Objective"
          value={campaignForm.objective}
          //disabled={flagforshowselectedcampaign}
          onChange={(e) => {
            validinput("objective", e.target.value);
          }}
          onClick={(e) => {
            setInput("objective", e.target.value);
          }}
          width={{
            base: "90%",
            small: "80%",
            medium: "60%",
            large: "30%",
          }}
          disabled={DisableAfterPublish}
        >
          <option selected disabled hidden></option>
          {objectivesList !== undefined
            ? objectivesList.map((row, key) => (
                <option key={key} value={row?.value}>
                  {row?.value}
                </option>
              ))
            : ""}
        </SelectField>
        <SelectField
          label="Special Ad Category (Optional)"
          icon={<IconArrowDropDown />}
          iconColor="black"
          placeholder=""
          marginTop={"1em"}
          value={
            campaignForm.special_ad_category !== ""
              ? campaignForm.special_ad_category
              : "NONE"
          }
          onChange={(e) => {
            setInput("special_ad_category", e.target.value);
          }}
          onClick={(e) => {
            setInput("special_ad_category", e.target.value);
          }}
          // disabled={flagforshowselectedcampaign}
          width={{
            base: "90%",
            small: "80%",
            medium: "60%",
            large: "30%",
          }}
          disabled={DisableAfterPublish}
        >
          {/* <option selected disabled hidden></option> */}
          {categoryList !== undefined
            ? categoryList.map((row, key) => (
                <option key={key} value={row?.value}>
                  {row?.value.replace(
                    /(^\w|\s\w)(\S*)/g,
                    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
                  )}
                </option>
              ))
            : ""}
        </SelectField>
        <Flex
          justifyContent="center"
          marginTop="2em"
          marginBottom="2em"
          gap="3rem"
        >
          {forNewExistingCampaign || HideExisitingcampaign  ? null :( 
          <Button
            backgroundColor="#df2121"
            style={primaryButton}
            boxShadow="large"
            onClick={ResetCampaign}
            borderRadius="2em"
            disabled={DisableAfterPublish}
          >
            Reset
            <Icon
              as={BiReset}
              fontSize={{ large: "large" }}
              color="white"
              fontWeight={"bold"}
              marginLeft="0.3em"
            ></Icon>
          </Button>
          )}
          <Button
            variation="primary"
            style={primaryButton}
            boxShadow="large"
            borderRadius="2em"
            backgroundColor={"#202A44"}
            isLoading={Progressbar}
            loadingText="processing..."
            onClick={validate} //
            disabled={DisableAfterPublish}
          >
            Next
            <Icon
              as={HiChevronDoubleRight}
              fontSize={{ large: "xl" }}
              color="white"
              fontWeight={"bold"}
              marginLeft="0.3em"
              marginTop={"0.2em"}
            ></Icon>
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default Campaign;
