import {
  Grid,
  Divider,
  Flex,
  View,
  useTheme,
  Heading,
  SearchField,
  Button,
  ThemeProvider,
  Icon
} from "@aws-amplify/ui-react";
import { Tabs, TabItem, Theme } from "@aws-amplify/ui-react";
import { viewCompList, createButtonTheme } from "../../assets/style";
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listAdDetails, listAdCampaigns,listPostDetails } from "../../graphql/queries";
import { dashboardcounttheme } from "../../assets/style";
import { RiDraftLine } from "react-icons/ri";
import '../../layout/DashboardHeader';
const DashboardCount = () => {
  const { tokens } = useTheme();
  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };

  const [Draft_count, setDraft_count] = useState();
  const [Requires_Edits_count, setRequires_Edits_count] = useState();
  const [Scheduled_count, setScheduled_count] = useState();
  const [Pending_Approval_count, setPending_Approval_count] = useState();
  const [Approved_count, setApproved_count] = useState();
  const [Published_count, setPublished_count] = useState();
  const [Posted_count, setPosted_count] = useState();
  const [Failed_count, setFailed_count] = useState();



  const [Draft_Postcount, setDraft_Postcount] = useState();
  const [Requires_Edits_Postcount, setRequires_Edits_Postcount] = useState();
  const [Scheduled_Postcount, setScheduled_Postcount] = useState();
  const [Pending_Approval_Postcount, setPending_Approval_Postcount] = useState();
  const [Approved_Postcount, setApproved_Postcount] = useState();
  const [Published_Postcount, setPublished_Postcount] = useState();
  const [Posted_Postcount, setPosted_Postcount] = useState();
  const [Failed_Postcount, setFailed_Postcount] = useState();

  const [AdStatus, setAdStatus] = useState();
  let status_counts = [];

  useEffect(() => {
    AdStatus_counts();
    postStatus_counts();
  }, []);

  // post count

  const postStatus_counts = async () => {

    try {
      let filter = {
        companyinfoID: { eq: localStorage.getItem("companyinfoID") }
      }
      const status = await API.graphql({
        query: listPostDetails,
        variables: { limit: 10000, filter: filter }
      });
      console.log("countsofdashboradpost", status.data.listPostDetails.items);
      let posttempArr = [];
      let tempJson={};

      posttempArr=    status.data.listPostDetails.items.filter((item) => item._deleted !== true || item._deleted == null)
        
             //   tempJson = {...z}

            
        
            
          
  
      
      console.log(posttempArr, "DatafromCodepost")
      let draftPostcount = posttempArr.filter(
        (item) => item.post_status ==6
      );
      setDraft_Postcount(draftPostcount.length);
      let requires_editsPostcount = posttempArr.filter(
        (item) => item.post_status == 5
      );
      setRequires_Edits_Postcount(requires_editsPostcount.length);
      let scheduledcount = posttempArr.filter(
        (item) => item.post_status === "SCHEDULED"
      );
      setScheduled_Postcount(scheduledcount.length);
      let pending_approvalPostcount = posttempArr.filter(
        (item) => item.post_status ==2
      );
      setPending_Approval_Postcount(pending_approvalPostcount.length);
      let approvedPostcount = posttempArr.filter(
        (item) => item.post_status ==3
      );
      setApproved_Postcount(approvedPostcount.length);
      let publishedPostcount = posttempArr.filter(
        (item) => item.post_status ==4
      );
      setPublished_Postcount(publishedPostcount.length);
      let postedcount = posttempArr.filter(
        (item) => item.post_status === "POSTED"
      );
      setPosted_Postcount(postedcount.length);
      let failedPostcount = posttempArr.filter(
        (item) => item.post_status ==1
      );
      setFailed_Postcount(failedPostcount.length);
    }
    catch (err) {

      console.log("error fetching actors", err);
    }
  }
  const AdStatus_counts = async () => {
    try {
      let filter = {
        companyinfoID: { eq: localStorage.getItem("companyinfoID") }
      }
      const status = await API.graphql({
        query: listAdCampaigns,
        variables: { limit: 10000, filter: filter }
      });
      console.log("countsofdashborad", status.data.listAdCampaigns.items);
      let tempArr = [];
      let tempJson={};

      status.data.listAdCampaigns.items.filter((item) => item._deleted !== true || item._deleted == null).map(x => {
        if (x.AdSets.items.length > 0) {
          x.AdSets.items.filter((item =>(item._deleted !== true || item._deleted == null)&& item._ad_name !="")).map(y => {
            if (y.AdDetails.items.length > 0) {
              y.AdDetails.items.filter(item=>item.ad_name !="").map(z => {
             //   tempJson = {...z}
             tempArr=[...tempArr,z]
              })

            }
          })


        }
        //  tempArr=[...tempArr,tempJson]
        //  tempJson=[];
        
      })
      console.log(tempArr, "DatafromCodeAdd")
      let draftcount = tempArr.filter(
        (item) => item.ad_status ==6
      );
      setDraft_count(draftcount.length);
      let requires_editscount = tempArr.filter(
        (item) => item.ad_status ==5
      );
      setRequires_Edits_count(requires_editscount.length);
      let scheduledcount = tempArr.filter(
        (item) => item.ad_status === "SCHEDULED"
      );
      setScheduled_count(scheduledcount.length);
      let pending_approvalcount = tempArr.filter(
        (item) => item.ad_status ==="2"
      );
      
      setPending_Approval_count(pending_approvalcount.length);
      let approvedcount = tempArr.filter(
        (item) => item.ad_status ==3
        
      );
     
      setApproved_count(approvedcount.length);
      let publishedcount = tempArr.filter(
        (item) => item.ad_status ==="4"
      );
  
      setPublished_count(publishedcount.length);
      let postedcount = tempArr.filter(
        (item) => item.ad_status === "POSTED"
      );

      setPosted_count(postedcount.length);
      let failedcount = tempArr.filter(
        (item) => item.ad_status ==1
      );
      setFailed_count(failedcount.length);

      //console.log("Here I am", tempArr.length);
      setAdStatus(tempArr.length);
    } catch (err) {

      console.log("error fetching actors", err);
    }
  };

  return (
    <div>
      <Flex direction="row" alignItems={"center"}>
        <View
          style={viewCompList}
          width="100%"
          margin={{ large: "2em", medium: "0.5em", small: "0em", base: "1em" }}
          borderRadius="1em"
          boxShadow="large"
        // padding="2em"
        // paddingTop="0em"
        // backgroundColor={tokens.colors.neutral[40]}
        >
          {/* <Flex
            margin={{ base: "large", medium: "xl", large: "small" }}
            gap={{ large: "relative.xxxl" }}
          >
            <Heading
              level={3}
              color={"rgb(183, 31, 57)"}
              fontStyle={"normal"}
              fontSize={"medium"}
              fontWeight={"500"}
            >
              Post Overview
            </Heading>
          </Flex> */}
          <ThemeProvider theme={dashboardcounttheme} colorMode="light">
            <Tabs justifyContent="center" >
              <TabItem title="Ad Overview">
                <Grid
                  templateColumns={{ large: "1fr 1fr", medium: "1fr 1fr", small: "1fr 1fr", base: "1fr" }}
                  gap={tokens.space.large}
                  margin={"auto"}
                  padding="4em"
                >
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(0, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Draft
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(0, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                        {Draft_count}
                      </Heading>
                    </Flex>
                  </View>
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(30, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Requires Edits
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(30, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                        {Requires_Edits_count}
                      </Heading>
                    </Flex>
                  </View>
                  {/* <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(60, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Scheduled
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(60, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                        {Scheduled_count}
                      </Heading>
                    </Flex>
                  </View> */}
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(190, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Pending Approval
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(190, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                        {Pending_Approval_count}
                      </Heading>
                    </Flex>
                  </View>
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(130, 43%, 46%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Approved
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(130, 43%, 46%)"}
                        fontWeight={"normal"}
                      >
                        {Approved_count}
                      </Heading>
                    </Flex>
                  </View>
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(220, 95%, 30%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Published
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(220, 95%, 30%)"}
                        fontWeight={"normal"}
                      >
                        {Published_count}
                      </Heading>
                    </Flex>
                  </View>
                  {/* <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(300, 95%, 30%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Posted
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(300, 95%, 30%)"}
                        fontWeight={"normal"}
                      >
                        {Posted_count}
                      </Heading>
                    </Flex>
                  </View> */}
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    {" "}
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(0, 95%, 30%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Failed
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(0, 95%, 30%)"}
                        fontWeight={"normal"}
                      >
                        {Failed_count}
                      </Heading>
                    </Flex>
                  </View>
                </Grid>
              </TabItem>
              <TabItem title="Post Overview">
                <Grid
                  templateColumns={{ large: "1fr 1fr", medium: "1fr 1fr", small: "1fr 1fr", base: "1fr" }}
                  gap={tokens.space.large}
                  padding={{ base: "1em", small: "1em", medium: "1em", large: "2em", xl: "4em" }}
                  margin={"auto"}
                >
                  <View borderRadius={tokens.radii.medium} boxShadow="large" classname="btngrpactive">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(0, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Draft
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(0, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                      {Draft_Postcount}
                      </Heading>
                    </Flex>
                  </View>
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(30, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Requires Edits
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(30, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                      {Requires_Edits_Postcount}
                      </Heading>
                    </Flex>
                  </View>
                  {/* <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(60, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Scheduled
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(60, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                       {Scheduled_Postcount}
                      </Heading>
                    </Flex>
                  </View> */}
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(190, 50%, 50%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Pending Approval
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(190, 50%, 50%)"}
                        fontWeight={"normal"}
                      >
                        {Pending_Approval_Postcount}
                      </Heading>
                    </Flex>
                  </View>
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(130, 43%, 46%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Approved
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(130, 43%, 46%)"}
                        fontWeight={"normal"}
                      >
                        {Approved_Postcount}
                      </Heading>
                    </Flex>
                  </View>
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(220, 95%, 30%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Published
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(220, 95%, 30%)"}
                        fontWeight={"normal"}
                      >
                        {Published_Postcount}
                      </Heading>
                    </Flex>
                  </View>
                  {/* <View borderRadius={tokens.radii.medium} boxShadow="large">
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(300, 95%, 30%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Posted
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(300, 95%, 30%)"}
                        fontWeight={"normal"}
                      >
                        {Posted_Postcount}
                      </Heading>
                    </Flex>
                  </View> */}
                  <View borderRadius={tokens.radii.medium} boxShadow="large">
                    {" "}
                    <Flex margin="small">
                      <Divider
                        orientation="vertical"
                        size="large"
                        border="5px solid hsl(0, 95%, 30%)"
                        borderRadius="10px"
                      />
                      <Heading
                        level={6}
                        fontWeight={"normal"}
                        color={"hsl(210, 50%, 10%)"}
                      >
                        Failed
                      </Heading>
                      <Heading
                        level={3}
                        marginLeft="auto"
                        color={"hsl(0, 95%, 30%)"}
                        fontWeight={"normal"}
                      >
                        {Failed_Postcount}
                      </Heading>
                    </Flex>
                  </View>
                </Grid>
              </TabItem>
            </Tabs>
          </ThemeProvider>
        </View>
      </Flex>
    </div>
  );
};
export default DashboardCount;
