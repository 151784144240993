import { Flex, View, useTheme } from '@aws-amplify/ui-react'
import DashboardHeader from '../../layout/DashboardHeader'
import CompaniesList from './CompaniesList'
import DashboardCount from './DashboardCount'
import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listAdDetails } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
const Dashboard = () => {
  const { tokens } = useTheme()
  const shadowProp = {
    boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)'
  }
  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  return (
    <div>
      <Flex
        direction={{
          base: 'column',
          small: 'column',
          medium: 'row',
          large: 'row'
        }}
        gap={{ base: '1em', small: '1em', medium: '1em', large: '1em' }}
      >
        <View width='100%'>
          <DashboardHeader
            db={true}
            mc={false}
            cl={false}
            mp={false}
            ndb={false}
          ></DashboardHeader>
        </View>
      </Flex>
      <Flex
        direction={{
          base: 'column',
          small: 'column',
          medium: 'row',
          large: 'row'
        }}
      >
        <View
          width={{ base: '100%', small: '100%', medium: '50%', large: '50%' }}
          marginTop='2em'
        >
          <CompaniesList></CompaniesList>
        </View>
        <View
          width={{ base: '100%', small: '100%', medium: '50%', large: '50%' }}
          marginTop='2em'
        >
          <DashboardCount></DashboardCount>
        </View>
      </Flex>
    </div>
  )
}
export default Dashboard
