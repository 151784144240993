import {
  Grid,
  View,
  Flex,
  Button,
  Icon,
  Text,
  Link,
  Image,
  SelectField,
  Collection,
  TextAreaField,
} from "@aws-amplify/ui-react";
import DashboardHeader from "../../layout/DashboardHeader";
import { primaryButton, secondaryButton } from "../../assets/style";
import { BsFillFileEarmarkPlusFill, BsUpload, BsTag } from "react-icons/bs";
import { ListofAccounts, MediaCollection } from "../../ui-components";
import { MdDelete } from "react-icons/md";
import LinearProgress from "@mui/material/LinearProgress";
import {
  RiDeleteBin5Line,
  RiEditFill,
  RiCloseCircleLine,
  RiVideoDownloadLine,
} from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";
import { BiFilterAlt, BiImage, BiCheckCircle, BiWorld } from "react-icons/bi";
import {
  MdCloudUpload,
  MdOutlineTouchApp,
  MdFileUpload,
  MdModeEditOutline,
} from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Carousel, Card, Stack } from "react-bootstrap";
import moment from "moment";
import "./ContentLibrary.css";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { datagridSx } from "../managecontent/Gridpopups";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Storage } from "@aws-amplify/storage";
import { ToastContainer, toast } from "react-toastify";
import {
  createContentLibrary,
  updateContentLibrary,
  deleteContentLibrary,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { listContentLibraries, listUserInfos } from "../../graphql/queries";
import HashLoader from "react-spinners/HashLoader";
import LoadingOverlay from "react-loading-overlay";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Auth } from 'aws-amplify'
// loading design
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
};

const ContentLibrary = () => {
  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  const [ContentLibraryfromstate, setContentLibraryfromstate] = useState();

  const IconArrowDropDown = () => {
    return <Icon pathData="M7 10L12 15L17 10H7Z" ariaLabel="Down arrow" color={"white"} marginTop={{ base: "0em", small: "0em", large: "-0.8em" }} />;
  };

  //for description
  function setInput(key, value) {
    // alert(value)
    console.log(listOfFile, "listoffile");
    let temp = [...listOfFile];
    temp[value]["media_desc"] = key;
    setListOfFile(temp);
    console.log(temp, "temp");
    setContentLibraryfromstate(key);
    //setContentLibraryfromstate(value);
    //console.log(ContentLibraryfromstate, "CformState");
  }

  // popup show
  const [showpopup, setShowpopup] = useState(false);
  const DeletePopupHide = () => setShowpopup(false);
  const DeletePopupShow = () => setShowpopup(true);
  const [showaddcontent, setShowaddcontent] = useState(false);
  const AddContentClose = () => {
    setShowaddcontent(false);
    removeAll();
  };
  const AddContentShow = () => setShowaddcontent(true);
  const [loading, setLoading] = useState(false);
  const [showcarosel, setShowcarosel] = useState(false);
  const handleClosecarousel = () => setShowcarosel(false);
  const handleShowcarousel = () => {
    setShowcarosel(true);
    setpreviewCheck(true);
    setdetailsCheck(false);
    setdeleteCheck(false);
    handleclickClose();
  };

  // menu bar
  const [anchorEl, setAnchorEl] = useState(false);
  const [previewCheck, setpreviewCheck] = useState(false);
  const [detailsCheck, setdetailsCheck] = useState(false);
  const [deleteCheck, setdeleteCheck] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setpreviewCheck(false);
    console.log("value", setAnchorEl);
  };
  const handleclickClose = () => {
    setAnchorEl(false);
    console.log("shfgsh", previewCheck);
  };
  // all files feed
  const optionsfeed = [
    { value: "allfiles", text: "All Files" },
    { value: "video", text: "Video" },
    { value: "image", text: "Image" },
  ];
  const [selected, setSelected] = useState(optionsfeed[0].value);
  const handleChange = (event) => {
    console.log("selected", selected, event.target.value)
    if (event.target.value == "allfiles") {
      getImageShowCarusel();
    }
    else if (event.target.value == "video") {
      getVideo();
    }
    else {
      getImage();
    }
    setSelected(event.target.value);
  };

  // upload file
  const MAX_COUNT = 3;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imgkey, set_imgkey] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [fileUpload, setFileUpload] = useState();
  const handlefileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    let SizeLimit = false;
    setProgress(0);
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        console.log("Type", file.size);
        if (file.type.substring(0, 5) == "video") {
          var SizeGB = file.size / 1000000000;
          SizeGB = Math.round(SizeGB);
          console.log("Total", SizeGB, file.size);
        } else {
          var SizeMB = file.size / 1000000;
          SizeMB = Math.round(SizeMB);
          console.log("Totalmb", SizeMB, file.size);
        }
        if (SizeGB > 4) {
          SizeLimit = true;
        } else if (SizeMB > 30) {
          SizeLimit = true;
        } else if (SizeMB > 30 && SizeGB > 4) {
          SizeLimit = true;
        }
        if (uploaded.length === MAX_COUNT) {
          setFileLimit(true);
        }
        if (uploaded.length > MAX_COUNT) {
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    // if (!limitExceeded) {
    //   setUploadedFiles(uploaded);
    // } else {
    //   toast.error("File upload Only Three");
    // }
    if (!limitExceeded) {
      if (!SizeLimit) {
        setUploadedFiles(uploaded);
      } else {
        toast.error("File is too large");
        setProgress(100);
      }
    } else {
      toast.error("File upload Only Three");
      setProgress(100);
    }
    console.log("Uploaded", uploaded);
  };

  // remove file onclick
  const removeFile = (index) => {
    console.log("Delete1", uploadedFiles);
    const newFiles = [...uploadedFiles];
    newFiles.splice(newFiles.indexOf(index), 1);
    toast.success("Delete Successfully");
    setUploadedFiles(newFiles);
    console.log("Delete", newFiles);
  };
  const removeAll = () => {
    setUploadedFiles([]);
  };

  // File store to Table
  async function UploadedFiles() {
    const files = uploadedFiles;
    console.log("fileslength", files.length, files);
    if (files.length != "" && files.length != 0) {
      for (var i = 0; i < files.length; i++) {
        try {
          setLoading(true);
          const File = files[i];
          const CognitoUserid = localStorage.getItem("Cognito_User_Id");
          const result = await Storage.put(File.name, File, {
            contentType: File.type, // contentType is optional
            level: "protected",
            identityId: CognitoUserid,
          });

          console.log("hellothere", result, CognitoUserid);
          set_imgkey(result.key);
          const NewsignedURL = await Storage.get(result.key, {
            expires: 3600,
            level: "protected",
            identityId: CognitoUserid,
          });
          setLoading(false);
          console.log("NewsignedURL", NewsignedURL, File.type, File.size);
          try {
            const SaveFile = {
              media_type: File.type,
              media_url: NewsignedURL,
              media_name: result.key,
              media_size: File.size,
              media_desc: "",
              isActive: true,
              isDelete: false,
              default: false,
              uploaded_by: "",
              userid: localStorage.getItem("userid"),
              companyinfoID: localStorage.getItem("companyinfoID"),
            };
            const obj = await API.graphql(
              graphqlOperation(createContentLibrary, { input: SaveFile })
            );
            console.log("saveresponse", obj);
            // toast.success("Data saved successfully");
          } catch (err) {
            // toast.error("error bruh");
            console.log(err, "aaaa");
          }
        } catch (error) {
          console.log("Error uploading file: ", error);
        }
      }
      setFileUpload(0);
      setLoading(false);
      toast.success("Upload successfully");
      AddContentClose();
      getImageShowCarusel();
    } else {
      setLoading(false);
      toast.error("Please Select file");
    }
  }

  // for description update
  const updateContentLibrarys = async (index) => {
    try {
      const updateFile = {
        media_desc: ContentLibraryfromstate,
        id: listOfFile[index].id,
        _version: listOfFile[index]._version,
      };
      const obj = await API.graphql(
        graphqlOperation(updateContentLibrary, { input: updateFile })
      );
      console.log("saveresponse", updateFile);

      // setContentLibraryfromstate()  ;

      toast.success("Data updated successfully");
      //for editable
      let temp = [...listOfFile];
      temp[index]["editable"] = false;
      setListOfFile(temp);
    } catch (err) {
      toast.error(err.response);
      console.log("Error updating: ", err);
    }
  };

  //for edit
  const editchange = (index) => {
    console.log("Dggg", index);

    let temp = [...listOfFile];
    temp[index]["editable"] = true;
    setListOfFile(temp);

    // if(index.currentTarget)
    // {
    //
    // }
    // else{
    //   seteditable(false);
    // }
  };

  // Get Data From Table
  const [listOfFile, setListOfFile] = useState([]);
  const [listOfFilefull, setListOfFilefull] = useState([]);
  let [onloading, setonLoading] = useState(false);
  const getImageShowCarusel = async () => {
    try {
      let filter = {
        companyinfoID: { eq: localStorage.getItem("companyinfoID") },
        isDelete: { eq: false },
      };
      const listOfItem = await API.graphql({
        query: listContentLibraries,
        variables: { filter: filter },
      });

      const listofUser = await API.graphql({
        query: listUserInfos,
        variables: { filter: filter },
      });
      const CognitoUserid = localStorage.getItem("Cognito_User_Id"); //particular company Id
      const ListOfContentLibrary =
        listOfItem.data.listContentLibraries.items.filter(
          (item) => item._deleted !== true || item._deleted == null
        ); //Store all data
      const ListUser = listofUser.data.listUserInfos.items.filter(
        (item) => item._deleted !== true || item._deleted == null
      ); //Store all data
      // Loop the All File to Get from S3
      setonLoading(true);

      console.log("Listofimage: ", ListOfContentLibrary);
      if (localStorage.getItem("role_id") === "6") {
        if (
          ListOfContentLibrary.length != "" &&
          ListOfContentLibrary.length != 0
        ) {
          ListUser.map((x) => {
            if (x.cognito_identity_id) {
              ListOfContentLibrary.map(async (y) => {
                if (x.id == y.userid) {
                  console.log("x.cognito_identity_id", x.cognito_identity_id);
                  const signedURL = await Storage.get(y.media_name, {
                    expires: 3600,
                    level: "protected",
                    identityId: x.cognito_identity_id,
                  });
                  y.media_url = signedURL;
                }
              });
            }
          });
          console.log("ListofimageUpdatedfsf: ", ListOfContentLibrary);
          setListOfFilefull(ListOfContentLibrary);
          setTimeout(() => {
            ListOfContentLibrary.map((x) => {
              x.editable = false;
            });

            setListOfFile(ListOfContentLibrary);
            setonLoading(false);
          }, 2000);
        } else {
          setonLoading(false);
        }
      } else if (localStorage.getItem("role_id") === "1") {
        let filter = {
          isDelete: { eq: false },
        };
        const listOfItem = await API.graphql({
          query: listContentLibraries,
          variables: { filter: filter },
        });

        const listofUser = await API.graphql({
          query: listUserInfos,
          variables: { filter: filter },
        });

        const ListOfContentLibrary =
          listOfItem.data.listContentLibraries.items.filter(
            (item) => item._deleted !== true || item._deleted == null
          ); //Store all data
        const ListUser = listofUser.data.listUserInfos.items.filter(
          (item) => item._deleted !== true || item._deleted == null
        ); //Store all data

        if (
          ListOfContentLibrary.length != "" &&
          ListOfContentLibrary.length != 0
        ) {
          ListUser.map((x) => {
            if (x.cognito_identity_id) {
              ListOfContentLibrary.map(async (y) => {
                if (x.companyinfoID === y.companyinfoID) {
                  if (x.id == y.userid) {
                    console.log("x.cognito_identity_id", x.cognito_identity_id);
                    const signedURL = await Storage.get(y.media_name, {
                      expires: 3600,
                      level: "protected",
                      identityId: x.cognito_identity_id,
                    });
                    y.media_url = signedURL;
                  }
                }
              });
            }
          });
          console.log("ListofimageUpdatedfsf: ", ListOfContentLibrary);
          setListOfFilefull(ListOfContentLibrary);
          setTimeout(() => {
            setListOfFile(ListOfContentLibrary);
            setonLoading(false);
          }, 2000);
        } else {
          setonLoading(false);
        }
      } else {
        if (
          ListOfContentLibrary.length != "" &&
          ListOfContentLibrary.length != 0
        ) {
          for (var i = 0; i < ListOfContentLibrary.length; i++) {
            const signedURL = await Storage.get(
              ListOfContentLibrary[i].media_name,
              {
                expires: 3600,
                level: "protected",
                identityId: CognitoUserid,
              }
            );

            ListOfContentLibrary[i].media_url = signedURL; //Get Signedurl Reassign Media_url
            console.log(
              "signedURL",
              signedURL,
              ListOfContentLibrary[i].media_url
            );
          }
          console.log("ListofimageUpdatedfsf: ", ListOfContentLibrary);
          setListOfFilefull(ListOfContentLibrary);
          //  setListOfFile(ListOfContentLibrary);
          // setonLoading(false);
          setTimeout(() => {
            // for description
            ListOfContentLibrary.map((x) => {
              x.Description = "";
            });
            setListOfFile(ListOfContentLibrary);
            setonLoading(false);
          }, 1000);
        } else {
          setonLoading(false);
        }
      }
    } catch (err) {
      setonLoading(false);
      console.log("error fetching actorsnjhehjwrehjwehjrjh", err);
    }
    console.log("ListofimageUpdate: ", listOfFile);
  };

  // Carusel deafult Active Index
  const [indexvalue, setindexvalue] = useState();
  const handler = function (e) {
    const index = e;
    console.log("Index", index); //will log the index of the clicked item
    setindexvalue(index);
  };

  // progress bar
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 20
      );
    }, 800);
    getImageShowCarusel();
    return () => {
      clearInterval(timer);
    };
  }, []);

  // delete Content
  async function removeContent(index) {
    console.log("delete", listOfFile[index]);
    const DeleteFile = {
      id: listOfFile[index].id,
      _version: listOfFile[index]._version,
    };
    console.log(DeleteFile, "DeleteFile");
    try {
      API.graphql(graphqlOperation(deleteContentLibrary, { input: DeleteFile }))
        .then(() => {
          listOfFile.splice(listOfFile.indexOf(index), 1);
          setListOfFile(
            listOfFile.filter(
              (item) => item._deleted !== true || item._deleted == null
            )
          );
          getImageShowCarusel();
          toast.success("Deleted Successfully");
        })
        .catch(() => {
          toast.warning("Deleted Failed");
        });
    } catch (err) {
      console.log(err, "Delete");
    }
  }

  // end
  const getVideo = async () => {
    setListOfFile(
      listOfFilefull.filter(
        (item) => item.media_type.substring(0, 5) == "video"
      )
    );
  };
  const getImage = async () => {
    setListOfFile(
      listOfFilefull.filter(
        (item) => item.media_type.substring(0, 5) != "video"
      )
    );
  };
  return (
    <div>
      <LoadingOverlay
        className="loadoverlay"
        active={onloading}
        spinner={
          <HashLoader
            color={"white"}
            className="loadoverlay1"
            loading={true}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        }
      >
        <ToastContainer autoClose={1500} limit={0} />
        {/* Dashboard */}
        <Flex direction={"row"}>
          <View width="100%">
            <DashboardHeader
              db={false}
              mc={false}
              cl={true}
              mp={false}
              ndb={false}
            ></DashboardHeader>
          </View>
        </Flex>
        {/* Header */}
        <Flex
          direction={{ base: "column", small: "column", large: "row" }}
          margin={{ base: "xs", large: "medium" }}
          marginBottom="0em"
        >
          <Flex
            direction={{ base: "column", small: "column", large: "row" }}
            gap={{ large: "relative.xs" }}
          >
            <Text
              fontSize={"1.5em"}
              fontWeight="600"
              style={{ borderBottom: "3px solid #b71f39" }}
              paddingBottom="5px"
              paddingRight={{
                base: "auto",
                small: "auto",
                medium: "auto",
                large: "3em",
              }}
            >
              Content Library
            </Text>
          </Flex>
          <Flex
            direction={{ base: "column", small: "row", large: "row" }}
            justifyContent={{
              base: "space-between",
              small: "space-between",
              large: "center",
            }}
            gap={{ large: "relative.small" }}
            marginLeft={{ base: "0", large: "auto" }}
            marginBottom={{ base: "1.5em", large: "0em" }}
          > 
            <Button
              style={secondaryButton}
              fontWeight="normal"
              width={{ base: "100%", medium: "100%", large: "80%" }}
              backgroundColor={"rgb(104, 2, 80)"}
              boxShadow="large"
              borderRadius={"25px"}
              onClick={AddContentShow}
            >
              <Icon
                as={BsFillFileEarmarkPlusFill}
                color={"white"}
                fontSize={"large"}
                marginTop={{ large: "relative.xxs" }}
              ></Icon>{" "}
              Add Content
            </Button>
            <SelectField
              labelHidden
              icon={<IconArrowDropDown />}
              iconColor="black"
              width={{ base: "100%", medium: "100%", large: "73%" }}
              value={selected}
              onChange={handleChange}
              className="contentlibrary"
            >
              <option value="allfiles" selected>All Files</option>
              <option value="video">Video</option>
              <option value="image">Image</option>
            </SelectField>
            {/* <Button
            style={primaryButton}
            fontWeight="normal"
            backgroundColor={"rgb(142, 198, 65)"}
            boxShadow="large"
            borderRadius={"25px"}
            // onClick={handleShowcarousel}
          >
            <Icon
              as={BsTag}
              color={"white"}
              fontSize={"xl"}
              marginTop={{ large: "relative.xxs" }}
            ></Icon>{" "}
            Label
          </Button> */}
            {/* <Button
              style={primaryButton}
              fontWeight="normal"
              backgroundColor={"red"}
              boxShadow="large"
              borderRadius={"25px"}
              onClick={DeletePopupShow}
            >
              <Icon
                as={MdDelete}
                color={"white"}
                fontSize="1.1em"
                marginTop="-3px"
                marginRight="0.3em"
              ></Icon>{" "}
              Delete
            </Button> */}
            {/* <DropdownButton
              id="dropdown-basic-button"
              title="Sort by"
              size="sm"
              className="pe-1 toggle"
            >
              <Dropdown.Item href="#/action-1">Ascending order</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Descending order</Dropdown.Item>
            </DropdownButton>*/}
            {/* 
            <DropdownButton
              id="dropdown-basic-button"
              title="All File"
              size="sm"
              className="pe-1 toggle"
              variant="secondary"
              
            >
              <Dropdown.Item
                onClick={() => {
                  getImageShowCarusel();
                }}
              >
                All Files
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  getVideo();
                }}
              >
                Video
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  getImage();
                }}
              >
                Image
              </Dropdown.Item>
            </DropdownButton> */}
          </Flex>
        </Flex>
        {/* Collection */}
        <Flex>
          <View>
            <Collection
            style={{ overflowY: 'auto', maxHeight: '60vh' }}
              type="grid"
              isSearchable={true}
              isPaginated={true}
              searchPlaceholder="Search..."
              itemsPerPage={12}
              templateColumns={{
                base: "1fr 1fr",
                small: "1fr 1fr 1fr",
                medium: "1fr 1fr 1fr 1fr",
                large: "1fr 1fr 1fr 1fr",
                xl: "1fr 1fr 1fr 1fr 1fr 1fr",
                xxl: "1fr 1fr 1fr 1fr 1fr 1fr",
              }}
              padding={"1em"}
              autoFlow="row"
              marginLeft={{
                base: "0em",
                small: "0em",
                medium: "0em",
                large: "0em",
                xxl: "0em",
              }}
              alignItems="center"
              justifyContent="center"
              className="figma"
              items={listOfFile
                .sort(function (a, b) {
                  return a._lastChangedAt - b._lastChangedAt;
                })
                .reverse()} // items={listOfFile}
            >
              {(image, index) => (
                <Flex
                  key={index}
                  gap="10px"
                  direction="column"
                  width={{
                    base: "165px",
                    medium: "180px",
                    small: "190px",
                    large: "250px",
                    xl: "190px",
                    xxl: "235px",
                  }}
                  height={{
                    base: "160px",
                    medium: "170px",
                    small: "180px",
                    large: "190px",
                    xl: "190px",
                    xxl: "200px",
                  }}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  position="relative"
                  borderRadius="5px"
                  padding="8px 7px 8px 7px"
                  boxShadow={"large"}
                  marginTop={"1em"}
                  marginBottom={"1em"}
                  marginLeft={"1em"}
                  backgroundColor="rgba(255,255,255,1)"
                  onClick={() => handler(index)}
                >
                  <Flex
                    gap="6px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                  >
                    <Flex
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      borderRadius="5px"
                      padding="0px 0px 0px 0px"
                      backgroundColor="rgba(56, 53, 53, 0.06)"
                      onClick={() => {
                        handleShowcarousel();
                      }}
                    >
                      {image.media_type.substring(0, 5) != "video" ? (
                        <Image
                          src={image.media_url}
                          width="unset"
                          height={{
                            base: "110px",
                            medium: "120px",
                            small: "130px",
                            large: "140px",
                          }}
                          display="block"
                          gap="unset"
                          alignItems="unset"
                          justifyContent="unset"
                          shrink="0"
                          alignSelf="stretch"
                          position="relative"
                          borderRadius="5px"
                          alt="image"
                          padding="0px 0px 0px 0px"
                          objectFit="contain"
                          style={{ cursor: "pointer" }}
                        ></Image>
                      ) : (
                        <ReactPlayer
                          url={image.media_url}
                          width="100%"
                          height="100%"
                          playing={false}
                          controls={true}
                          playsinline={true}
                          style={{ cursor: "pointer" }}
                          className="videoplayer"
                        />
                      )}
                      {/* <Image
            src={image.media_url}
    width="unset"
    height={{ base: "110px", medium: "120px", small: "130px", large: "140px" }}
    display="block"
    gap="unset"
    alignItems="unset"
    justifyContent="unset"
    shrink="0"
    alignSelf="stretch"
    position="relative"
    borderRadius="5px"
    alt="image"
    padding="0px 0px 0px 0px"
    objectFit="contain"
    style={{ cursor: "pointer" }}
    onClick={() => {
      handleShowcarousel();
    }}
  ></Image> */}
                    </Flex>
                    <Flex
                      gap="1px"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                    >
                      <Flex
                        gap="10px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        grow="1"
                        shrink="1"
                        basis="0"
                        position="relative"
                        padding="10px 10px 10px 10px"
                      >
                        {" "}
                        {image.media_name.length > 15 ? (
                          <Text
                            fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                            fontSize={{
                              base: "10px",
                              medium: "10px",
                              small: "10px",
                              large: "12px",
                              xxl: "15px",
                            }}
                            fontWeight="500"
                            color="rgba(0,0,0,1)"
                            lineHeight="14px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            grow="1"
                            shrink="1"
                            basis="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            textTransform="capitalize"
                          >
                            {image.media_name.substring(0, 15)}...
                          </Text>
                        ) : (
                          <Text
                            fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                            fontSize={{
                              base: "10px",
                              medium: "10px",
                              small: "10px",
                              large: "12px",
                              xxl: "15px",
                            }}
                            fontWeight="500"
                            color="rgba(0,0,0,1)"
                            lineHeight="14px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            textTransform="capitalize"
                            gap="unset"
                            alignItems="unset"
                            grow="1"
                            shrink="1"
                            basis="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                          >
                            {image.media_name}
                          </Text>
                        )}
                      </Flex>
                      <View
                        width="unset"
                        height="unset"
                        display="block"
                        gap="unset"
                        alignItems="center"
                        justifyContent="center"
                        overflow="hidden"
                        shrink="0"
                        position="relative"
                        borderRadius="5px"
                        padding="0px 0px 0px 0px"
                      >
                        {/* <Icon
                as={HiDotsVertical}
                id="demo-positioned-button"
                aria-controls={
                  open ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                fontSize={{ large: "large" }}
                alignItems="right"
                justifyContent="right"
                color={"black"}
                style={{ cursor: "pointer", verticalAlign: "Top"}}
                marginLeft={"auto"}
                
              ></Icon> */}
                        {/* <Icon
                          as={HiDotsVertical}
                          id="demo-positioned-button"
                          aria-controls={
                            open ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          fontSize={{ large: "large" }}
                          alignItems="right"
                          justifyContent="right"
                          color={"black"}
                          style={{ cursor: "pointer", verticalAlign: "Top" }}
                          marginLeft={"auto"}
                        ></Icon> */}
                        <Tooltip title="Delete" disableInteractive>
                          <Flex width={"100%"}>
                            <Icon
                              as={MdDelete}
                              color={"red"}
                              fontSize="1.2em"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeContent(index)}
                            ></Icon>
                          </Flex>
                        </Tooltip>
                      </View>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Collection>
            <Modal
              size="xl"
              className="modaltransperent"
              show={showcarosel}
              onHide={handleClosecarousel}
              centered
            >
              <Modal.Body>
                <Carousel
                  color="black"
                  data-interval="false"
                  data-wrap="true"
                  data-ride="carousel"
                  autoPlay={false}
                  interval={null}
                  pause={true}
                  indicators={false}
                  defaultActiveIndex={indexvalue}
                >
                  {listOfFile.map((review, index) => (
                    <Carousel.Item className="carouselresponsive">
                      <Card className="cardresponsive">
                        <Card.Header
                          style={{
                            border: "none",
                            padding: "0.5em",
                            display: "flow-root",
                            backgroundColor: "transparent",
                          }}
                        >
                          <Flex
                            direction={{
                              base: "column",
                              medium: "column",
                              small: "column",
                              large: "row",
                              xl: "row",
                            }}
                            gap="xs"
                            justifyContent={{
                              base: "left",
                              medium: "left",
                              small: "left",
                              large: "space-between",
                              xl: "space-between",
                            }}
                          >
                            <View
                              width={{
                                base: "100%",
                                medium: "100%",
                                small: "100%",
                                large: "70%",
                                xl: "70%",
                              }}
                            >
                              {review.media_name.length < 30 ? (
                                <Text
                                  style={{ fontWeight: "600" }}
                                  textTransform="capitalize"
                                  fontSize={{
                                    base: "0.9em",
                                    medium: "1em",
                                    small: "1em",
                                    large: "1.3em",
                                    xl: "1.3em",
                                  }}
                                >
                                  {review.media_name}
                                </Text>
                              ) : (
                                <Text
                                  style={{ fontWeight: "600" }}
                                  textTransform="capitalize"
                                  fontSize={{
                                    base: "0.9em",
                                    medium: "1em",
                                    small: "1em",
                                    large: "1.3em",
                                    xl: "1.3em",
                                  }}
                                >
                                  {review.media_name.substring(0, 30)}...
                                </Text>
                              )}
                            </View>
                            <View
                              width={{
                                base: "100%",
                                medium: "100%",
                                small: "100%",
                                large: "30%",
                                xl: "30%",
                              }}
                              justifyContent="right"
                              textAlign="right"
                            >
                              {/* <Button
                          style={{ verticalAlign: "top" }}
                          fontWeight="normal"
                          backgroundColor={"#008eff"}
                          size="small"
                          marginRight={"0.2em"}
                          marginTop="0px"
                          color="white"
                        >
                          Edit{" "}
                          <Icon
                            as={RiEditFill}
                            fontSize={{ large: "small" }}
                            marginLeft="0.3em"
                          ></Icon>
                        </Button> */}
                            </View>
                          </Flex>
                        </Card.Header>
                        <Card.Body style={{ padding: "0em" }}>
                          <Flex
                            direction={{
                              base: "column",
                              medium: "column",
                              small: "column",
                              large: "row",
                              xl: "row",
                            }}
                            gap="0"
                            justifyContent={{
                              base: "left",
                              medium: "left",
                              small: "left",
                              large: "space-between",
                              xl: "space-between",
                            }}
                          >
                            <View
                              width={{
                                base: "100%",
                                medium: "100%",
                                small: "100%",
                                large: "55%",
                                xl: "55%",
                              }}
                              margin="auto"
                              padding={{
                                base: "0.2em",
                                medium: "1em",
                                small: "1em",
                                large: "1em",
                                xl: "1em",
                              }}
                              paddingRight="0em"
                            >
                              <Card.Text>
                                {review.media_type.substring(0, 5) !=
                                  "video" ? (
                                  <Image
                                    className="img-fluid img-responsive"
                                    src={review.media_url}
                                    alt="Image"
                                    maxWidth={{
                                      base: "35vh",
                                      small: "50vh",
                                      medium: "40vh",
                                      large: "40vh",
                                      xl: "65vh",
                                      xxl: "45vh",
                                    }}
                                    minWidth={{
                                      base: "35vh",
                                      small: "50vh",
                                      medium: "40vh",
                                      large: "40vh",
                                      xl: "65vh",
                                      xxl: "45vh",
                                    }}
                                    minHeight={{
                                      base: "30vh",
                                      small: "40vh",
                                      medium: "40vh",
                                      large: "60vh",
                                      xl: "50vh",
                                      xxl: "50vh",
                                    }}
                                    maxHeight={{
                                      base: "30vh",
                                      small: "40vh",
                                      medium: "40vh",
                                      large: "60vh",
                                      xl: "50vh",
                                      xxl: "50vh",
                                    }}
                                    style={{
                                      width: "100%",
                                      objectFit: "contain",
                                      backgroundColor: "rgb(56 53 53 / 6%)",
                                      borderRadius: "0.5em",
                                    }}
                                  />
                                ) : (
                                  <Flex
                                    gap="10px"
                                    direction="column"
                                    width="unset"
                                    height="unset"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    shrink="0"
                                    alignSelf="stretch"
                                    position="relative"
                                    borderRadius="5px"
                                    padding="0px 0px 0px 0px"
                                    backgroundColor="rgba(56, 53, 53, 0.06)"
                                  >
                                    <ReactPlayer
                                      url={review.media_url}
                                      width="100%"
                                      height="100%"
                                      playing={false}
                                      controls={true}
                                      className="videoplayercarousel"
                                    />
                                  </Flex>
                                )}
                              </Card.Text>
                            </View>
                            <View
                              width={{
                                base: "100%",
                                medium: "100%",
                                small: "100%",
                                large: "45%",
                                xl: "45%",
                              }}
                              padding="1em"
                            >
                              <Flex
                                direction={"row"}
                                gap="xs"
                                justifyContent="space-between"
                              >
                                <View width="40%">
                                  <Text
                                    color={"grey"}
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    File Type
                                  </Text>
                                </View>
                                <View width="60%">
                                  <Text
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    {review.media_type}
                                  </Text>
                                </View>
                              </Flex>
                              <Flex
                                direction={"row"}
                                gap="xs"
                                justifyContent="space-between"
                                paddingTop={"0.5em"}
                              >
                                <View width="40%">
                                  <Text
                                    color={"grey"}
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    File Size
                                  </Text>
                                </View>
                                <View width="60%">
                                  <Text
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    {review.media_size}
                                  </Text>
                                </View>
                              </Flex>
                              <Flex
                                direction={"row"}
                                gap="xs"
                                justifyContent="space-between"
                                paddingTop={"0.5em"}
                              >
                                <View width="40%">
                                  <Text
                                    color={"grey"}
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    Dimension
                                  </Text>
                                </View>
                                <View width="60%">
                                  <Text
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    1440/893
                                  </Text>
                                </View>
                              </Flex>
                              <Flex
                                direction={"row"}
                                gap="xs"
                                justifyContent="space-between"
                                paddingTop={"0.5em"}
                              >
                                <View width="40%">
                                  <Text
                                    color={"grey"}
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    Uploaded
                                  </Text>
                                </View>
                                <View width="60%">
                                  {/* <Text fontSize={{ base: "0.5em", medium: "0.5em", small: "0.5em", large: "small", xl: "small" }}>{new Date(review.updatedAt).toLocaleString()}</Text> */}
                                  <Text
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    {moment(review.updatedAt).format(
                                      "MMM DD YYYY, h:mm a"
                                    )}
                                  </Text>
                                </View>
                              </Flex>
                              <Flex
                                direction={"row"}
                                gap="xs"
                                justifyContent="space-between"
                                paddingTop={"0.5em"}
                              >
                                <View>
                                  <Text
                                    color={"grey"}
                                    fontSize={{
                                      base: "0.5em",
                                      medium: "0.5em",
                                      small: "0.5em",
                                      large: "small",
                                      xl: "small",
                                    }}
                                  >
                                    Description
                                    <Link
                                      class="descriptionlink"
                                      width={{ base: "4em", medium: "4em" }}
                                      fontSize={{
                                        base: "0.5em",
                                        medium: "0.5em",
                                        small: "0.5em",
                                        large: "small",
                                        xl: "small",
                                      }}
                                      padding={"0em"}
                                      marginLeft={{
                                        base: "10em",
                                        medium: "12em",
                                      }}
                                      border="none"
                                      color={"#002147"}
                                      hidden={review.editable == true}
                                      fontWeight="normal"
                                      onClick={() => editchange(index)}
                                    //  onClick={(e) => { editchange(index); }}
                                    >
                                      <Icon
                                        as={MdModeEditOutline}
                                        fontSize={{
                                          base: "0.8em",
                                          medium: "0.8em",
                                          small: "0.5em",
                                          large: "small",
                                          xl: "1em",
                                        }}
                                        color="#002147"
                                        fontWeight={"30em"}
                                        marginLeft="0.3em"
                                      ></Icon>
                                      Edit
                                    </Link>
                                  </Text>
                                </View>
                              </Flex>
                              <Flex
                                direction={"row"}
                                gap="xs"
                                justifyContent="space-between"
                              >
                                <View
                                  width={{
                                    base: "100%",
                                    medium: "100%",
                                    small: "100%",
                                    large: "100%",
                                    xl: "150%",
                                  }}
                                >
                                  {review.editable == true ? (
                                    <TextAreaField
                                      onChange={(e) => {
                                        setInput(e.target.value, index);
                                      }}
                                      rows={6}
                                      column={7}
                                      size="large"
                                      resize="vertical"
                                      fontSize={{
                                        base: "0.5em",
                                        medium: "0.5em",
                                        small: "0.5em",
                                        large: "small",
                                        xl: "small",
                                      }}
                                      value={review.media_desc}
                                      style={{ overflow: "hidden" }}
                                    >
                                      {" "}
                                    </TextAreaField>
                                  ) : (
                                    <Text
                                      fontSize={{
                                        base: "0.5em",
                                        medium: "0.5em",
                                        small: "0.5em",
                                        large: "small",
                                        xl: "small",
                                      }}
                                    >
                                      {" "}
                                      {review.media_desc}
                                    </Text>
                                  )}
                                </View>
                              </Flex>

                              <Flex
                                direction="row"
                                marginTop={{ base: "0em", large: "0.9em" }}
                                justifyContent="flex-end"
                                marginRight={{ base: "0.1em", large: "0.1em" }}
                              >
                                <Button
                                  backgroundColor="#8EC641"
                                  style={primaryButton}
                                  hidden={review.editable == false}
                                  boxShadow="large"
                                  borderRadius="2em"
                                  onClick={() => updateContentLibrarys(index)}
                                >
                                  Save{" "}
                                </Button>
                              </Flex>
                            </View>
                          </Flex>
                          {/* <Divider size="small" marginTop={"1em"} />
                    <Flex
                      direction={"row"}
                      gap="xs"
                      paddingLeft={"2em"}
                      paddingBottom={"2em"}
                      paddingRight={"2em"}
                      height="300px"
                    >
                      <View style={{ height: "230px", width: "100%" }}>
                        <Text
                          textAlign={"center"}
                          fontWeight="600"
                          marginTop="1em"
                          marginBottom={"0.5em"}
                          fontSize={{ base: "0.9em", medium: "0.9em", small: "0.9em", large: "large" , xl:"large"}}
                        >
                          History
                        </Text>
                        <DataGrid
                          width="100%"
                          headerHeight={40}
                          rowHeight={40}
                          sx={datagridSx}
                          columns={[
                            {
                              field: "id",
                              flex: 1,
                              minWidth: 150,
                            },
                            {
                              field: "username",
                              width: 200,
                            },
                            {
                              field: "age",
                              flex: 0.3,
                              minWidth: 50,
                            },
                          ]}
                          rows={rows}
                          hideFooter
                          disableSelectionOnClick
                        />
                      </View>
                    </Flex> */}
                        </Card.Body>
                      </Card>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Modal.Body>
            </Modal>
            {/* <MediaCollection></MediaCollection> */}
          </View>
        </Flex>
        {/* delete popup */}
        <Modal
          size="md"
          show={showpopup}
          onHide={DeletePopupHide}
          border="none"
          centered
        >
          <Modal.Header
            style={{
              border: "none",
              padding: "2em",
              paddingBottom: "0em",
              justifyContent: "center",
            }}
          >
            <Flex direction={"row"} gap="xxxl">
              <View width="100%">
                <Text>
                  <Icon
                    as={RiDeleteBin5Line}
                    color={"white"}
                    fontSize="1.2em"
                    textAlign={"center"}
                    backgroundColor={"rgb(252 71 71)"}
                    padding={"0.6em"}
                    borderRadius={"50%"}
                    height="3em"
                    boxShadow={"large"}
                    border="5px solid #ec8787"
                  ></Icon>
                </Text>
              </View>
            </Flex>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Text>
              The assets will be deleted from your library. if any of the assets
              were included into a post, neither the post nor the assets will be
              impacted
            </Text>
            <Text>
              Are you sure you want to delete the assets from the library?
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={primaryButton}
              fontWeight="normal"
              backgroundColor={"grey"}
              onClick={DeletePopupHide}
              marginRight="1em"
            >
              Cancel
            </Button>
            <Button
              style={primaryButton}
              fontWeight="normal"
              backgroundColor={"rgb(244, 67, 54)"}
              onClick={DeletePopupHide}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* add Content popup*/}
        <Modal size="md" show={showaddcontent} onHide={AddContentClose}>
          <Modal.Header
            style={{
              border: "none",
              padding: "2em",
              paddingBottom: "0em",
              justifyContent: "center",
            }}
          >
            <Flex direction={"row"} gap="xl">
              <View width="100%">
                <Text fontWeight={"600"} fontSize="1.2em">
                  Add New conent
                </Text>
              </View>
            </Flex>
          </Modal.Header>
          <Modal.Body className="pe-5" style={{ paddingLeft: "3em" }}>
            <Flex
              direction={"row"}
              gap="xxxl"
              border={"2px dashed #b71f39"}
              backgroundColor="rgb(244 5 46 / 6%)"
              boxShadow={"large"}
              marginBottom="2em"
            >
              <View width="100%">
                <Text textAlign={"center"} margin={"1em"}>
                  <Icon
                    as={MdCloudUpload}
                    color={"#b71f39"}
                    fontSize="4em"
                  ></Icon>{" "}
                </Text>
                <Text fontWeight={"600"} margin="1em" textAlign={"center"}>
                  Drag and Drop your files here
                </Text>
                <Text fontWeight={"600"} margin="1em" textAlign={"center"}>
                  Or
                </Text>
                <Text margin="1em" textAlign={"center"}>
                  <Button
                    style={primaryButton}
                    fontWeight="normal"
                    backgroundColor={"#b71f39"}
                    textAlign="center"
                    target="#browse"
                    cursor="pointer"
                  >
                    <input
                      className="choosefile"
                      type="file"
                      name="myImage"
                      id="browse"
                      accept="image/gif,image/jpeg,video/mp4,image/png,video/*"
                      onChange={handlefileEvent}
                      multiple
                    />
                    Browse{" "}
                    <Icon
                      as={MdOutlineTouchApp}
                      color={"white"}
                      fontSize="1.4em"
                      marginLeft={"0.3em"}
                    ></Icon>
                  </Button>
                </Text>
              </View>
            </Flex>
            {/* <div className='player-wrapper'>
              <ReactPlayer
          className='react-player'
          url={Vid}
          width='100%'
          height='100%'
          playing={true}
            controls={true}
            loop={true}
            muted={true}
            playsinline={true}
        />
        </div> */}
            {uploadedFiles.map((file, index) => (
              <Flex direction={"row"} key={index}>
                <View width="100%" paddingTop="2em">
                  <Card className="shadow-sm py-1">
                    <Card.Header
                      style={{
                        border: "transparent",
                        padding: "0.3em",
                        display: "flow-root",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Flex
                        direction={"row"}
                        gap="small"
                        justifyContent="space-between"
                      >
                        <View width="10%" margin="auto">
                          {file.type.substring(0, 5) != "video" ? (
                            <Icon
                              as={BiImage}
                              marginLeft="0.2em"
                              color={"hsl(0deg 0% 0% / 72%)"}
                              fontSize="1.8em"
                            ></Icon>
                          ) : (
                            <Icon
                              as={RiVideoDownloadLine}
                              color={"hsl(0deg 0% 0% / 72%)"}
                              marginLeft="0.2em"
                              fontSize="1.8em"
                            ></Icon>
                          )}
                        </View>
                        {file.type.substring(0, 5) != "video" ? (
                          <View width="70%" margin="auto">
                            {file.name.length < 30 ? (
                              <Text
                                textTransform="capitalize"
                                style={{ fontWeight: "500" }}
                              >
                                {file.name}
                              </Text>
                            ) : (
                              <Text
                                textTransform="capitalize"
                                style={{ fontWeight: "500" }}
                              >
                                {file.name.substring(0, 30)}...
                              </Text>
                            )}
                            {progress != 100 ? (
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                              />
                            ) : (
                              <Text color={"grey"} fontSize="0.8em">
                                {file.size} bytes
                              </Text>
                            )}
                          </View>
                        ) : (
                          <View width="70%" margin="auto">
                            <Text style={{ fontWeight: "500" }}>
                              {" "}
                              {file.name.substring(0, 25)}...
                            </Text>
                            {progress != 100 ? (
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                              />
                            ) : (
                              <Text color={"grey"} fontSize="0.8em">
                                {file.size} bytes
                              </Text>
                            )}
                          </View>
                        )}
                        <View
                          width="15%"
                          justifyContent="center"
                          textAlign="center"
                        >
                          <Flex direction={"row"} gap="0em">
                            {progress == 100 ? (
                              <View
                                width="50%"
                                justifyContent="center"
                                textAlign="center"
                              >
                                <Icon
                                  as={BiCheckCircle}
                                  fontSize={{ large: "xl" }}
                                  alignItems="right"
                                  justifyContent="right"
                                  color={"#64cd64"}
                                  marginTop="0.5em"
                                  marginRight={"1em"}
                                  style={{ cursor: "pointer" }}
                                  marginLeft={"auto"}
                                ></Icon>
                              </View>
                            ) : null}
                            <View
                              width="50%"
                              justifyContent="center"
                              textAlign="center"
                            >
                              <Icon
                                as={RiCloseCircleLine}
                                fontSize={{ large: "xl" }}
                                alignItems="right"
                                justifyContent="right"
                                color={"#fd1d1ded"}
                                marginTop="0.5em"
                                style={{ cursor: "pointer" }}
                                marginLeft={"auto"}
                                onClick={() => removeFile(index)}
                              ></Icon>
                            </View>
                          </Flex>
                        </View>
                      </Flex>
                    </Card.Header>
                  </Card>
                </View>
              </Flex>
            ))}
            {/* <Flex direction={"row"}>
            <View width="100%" paddingTop="1em">
              <Card className="shadow-sm py-1">
                <Card.Header
                  style={{
                    border: "transparent",
                    padding: "0.3em",
                    display: "flow-root",
                    backgroundColor: "transparent",
                  }}
                >
                  <Flex
                    direction={"row"}
                    gap="small"
                    justifyContent="space-between"
                  >
                    <View width="10%" margin="auto">
                      <Icon
                        as={RiVideoDownloadLine}
                        color={"hsl(0deg 0% 0% / 72%)"}
                        fontSize="1.8em"
                      ></Icon>
                    </View>
                    <View width="80%" margin="auto">
                      <Text style={{ fontWeight: "500" }}>Video 1</Text>
                      <ProgressBar
                        now={60}
                        label={`60%`}
                        className="progresssize"
                      />
                    </View>
                    <View
                      width="10%"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Icon
                        as={RiCloseCircleLine}
                        fontSize={{ large: "large" }}
                        alignItems="right"
                        justifyContent="right"
                        color={"#fd1d1ded"}
                        marginTop="0.5em"
                        style={{ cursor: "pointer" }}
                        marginLeft={"auto"}
                      ></Icon>
                    </View>
                  </Flex>
                </Card.Header>
              </Card>
            </View>
          </Flex>
          <Flex direction={"row"}>
            <View width="100%" paddingTop="1em">
              <Card className="shadow-sm py-0.5">
                <Card.Header
                  style={{
                    border: "transparent",
                    padding: "0.3em",
                    display: "flow-root",
                    backgroundColor: "transparent",
                  }}
                >
                  <Flex
                    direction={"row"}
                    gap="small"
                    justifyContent="space-between"
                  >
                    <View width="10%" margin="auto">
                      <Icon
                        as={BiImage}
                        color={"hsl(0deg 0% 0% / 72%)"}
                        fontSize="1.8em"
                      ></Icon>
                    </View>
                    <View width="80%" margin="auto">
                      <Text style={{ fontWeight: "500" }}>Image 2</Text>
                      <Text color={"grey"} fontSize="0.8em">
                        436 Kb
                      </Text>
                    </View>
                    <View
                      width="10%"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Icon
                        as={BiCheckCircle}
                        fontSize={{ large: "large" }}
                        alignItems="right"
                        justifyContent="right"
                        color={"#64cd64"}
                        marginTop="0.5em"
                        style={{ cursor: "pointer" }}
                        marginLeft={"auto"}
                      ></Icon>
                    </View>
                  </Flex>
                </Card.Header>
              </Card>
            </View>
          </Flex> */}
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center", border: "none" }}>
            <Button
              style={primaryButton}
              fontWeight="normal"
              backgroundColor={"grey"}
              onClick={AddContentClose}
              marginRight="1em"
            >
              Cancel
            </Button>
            <Button
              style={primaryButton}
              fontWeight="normal"
              isLoading={loading}
              loadingText="processing..."
              backgroundColor={"#b71f39"}
              onClick={() => UploadedFiles()}
            >
              Upload{" "}
              <Icon
                as={MdFileUpload}
                color={"white"}
                fontSize="1.4em"
                marginLeft={"0.3em"}
              ></Icon>
            </Button>
          </Modal.Footer>
        </Modal>
        {/* bootstrap carosel */}
        {/* <Modal
        size="xl"
        className="modaltransperent"
        show={showcarosel}
        onHide={handleClosecarousel}
      >
        <Modal.Body>
          <Carousel color="black" autoPlay={false} interval={null}>
            {reviews.map((review, index) => (
              <Carousel.Item
                style={{ paddingRight: "7em", paddingLeft: "7em" }}
              >
                <Card
                  style={{
                    color: "black",
                    border: "none",
                    paddingRight: "1.5em",
                    paddingLeft: "1.5em",
                  }}
                >
                  <Card.Header
                    style={{
                      border: "none",
                      padding: "1em",
                      display: "flow-root",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Flex
                      direction={"row"}
                      gap="xs"
                      justifyContent="space-between"
                    >
                      <View width="50%">
                        <Text style={{ fontSize: "1.3em", fontWeight: "600" }}>
                          Image 1
                        </Text>
                      </View>
                      <View
                        width="50%"
                        justifyContent="right"
                        textAlign="right"
                      >
                        <Button
                          style={{ verticalAlign: "top" }}
                          fontWeight="normal"
                          backgroundColor={"#008eff"}
                          size="small"
                          marginRight={"0.2em"}
                          marginTop="0px"
                          color="white"
                        >
                          Edit{" "}
                          <Icon
                            as={RiEditFill}
                            fontSize={{ large: "small" }}
                            marginLeft="0.3em"
                          ></Icon>
                        </Button>
                        <Tooltip title="Add" disableInteractive>
                          <Button
                            backgroundColor={"transparent"}
                            border="none"
                            size="xs"
                          >
                            <Icon
                              as={HiDotsVertical}
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                              fontSize={{ large: "xl" }}
                              alignItems="right"
                              justifyContent="right"
                              color={"black"}
                              style={{ cursor: "pointer" }}
                              marginLeft={"auto"}
                            ></Icon>
                          </Button>
                        </Tooltip>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleclickClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={handleclickClose}>
                            Profile
                          </MenuItem>
                          <MenuItem onClick={handleclickClose}>
                            My account
                          </MenuItem>
                          <MenuItem onClick={handleclickClose}>Logout</MenuItem>
                        </Menu>
                      </View>
                    </Flex>
                  </Card.Header>
                  <Card.Body style={{ padding: "0em" }}>
                    <Flex
                      direction={"row"}
                      gap="0"
                      justifyContent="space-between"
                    >
                      <View width="55%" margin="auto" paddingLeft="1em">
                        <Card.Text>
                          <Image
                            className="img-fluid img-responsive"
                            src={review.src}
                            alt="Second slide"
                            minHeight={{
                              base: "30vh",
                              small: "30vh",
                              medium: "30vh",
                              large: "30vh",
                              xl: "40vh",
                              xxl: "40vh",
                            }}
                            maxHeight={{
                              base: "30vh",
                              small: "30vh",
                              medium: "30vh",
                              large: "30vh",
                              xl: "40vh",
                              xxl: "40vh",
                            }}
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              backgroundColor: "rgb(56 53 53 / 6%)",
                              borderRadius: "0.5em",
                            }}
                          />
                        </Card.Text>
                      </View>
                      <View width="45%" padding="1em">
                        <Flex
                          direction={"row"}
                          gap="xs"
                          justifyContent="space-between"
                        >
                          <View width="40%">
                            <Text color={"grey"} fontSize="small">
                              File Type
                            </Text>
                          </View>
                          <View width="60%">
                            <Text fontSize="small">Image/png</Text>
                          </View>
                        </Flex>
                        <Flex
                          direction={"row"}
                          gap="xs"
                          justifyContent="space-between"
                          paddingTop={"0.5em"}
                        >
                          <View width="40%">
                            <Text color={"grey"} fontSize="small">
                              File Size
                            </Text>
                          </View>
                          <View width="60%">
                            <Text fontSize="small">960kb</Text>
                          </View>
                        </Flex>
                        <Flex
                          direction={"row"}
                          gap="xs"
                          justifyContent="space-between"
                          paddingTop={"0.5em"}
                        >
                          <View width="40%">
                            <Text color={"grey"} fontSize="small">
                              Dimension
                            </Text>
                          </View>
                          <View width="60%">
                            <Text fontSize="small">1440/893</Text>
                          </View>
                        </Flex>
                        <Flex
                          direction={"row"}
                          gap="xs"
                          justifyContent="space-between"
                          paddingTop={"0.5em"}
                        >
                          <View width="40%">
                            <Text color={"grey"} fontSize="small">
                              Uploaded
                            </Text>
                          </View>
                          <View width="60%">
                            <Text fontSize="small">Nov 8, 2022 3:00 am</Text>
                          </View>
                        </Flex>
                        <Flex
                          direction={"row"}
                          gap="xs"
                          justifyContent="space-between"
                          paddingTop={"0.5em"}
                        >
                          <View>
                            <Text color={"grey"} fontSize="small">
                              Description
                            </Text>
                          </View>
                        </Flex>
                        <Flex
                          direction={"row"}
                          gap="xs"
                          justifyContent="space-between"
                        >
                          <View>
                            <Text fontSize="small">
                              Cards include a few options for working with
                              images. Choose from appending “image caps” at
                              either end of a card, overlaying images with card
                              content, or simply embedding the image in a card.
                            </Text>
                          </View>
                        </Flex>
                      </View>
                    </Flex>
                    <Divider size="small" marginTop={"1em"} />
                    <Flex
                      direction={"row"}
                      gap="xs"
                      paddingLeft={"2em"}
                      paddingBottom={"2em"}
                      paddingRight={"2em"}
                      height="300px"
                    >
                      <View style={{ height: "230px", width: "100%" }}>
                        <Text
                          textAlign={"center"}
                          fontWeight="600"
                          marginTop="1em"
                          marginBottom={"0.5em"}
                        >
                          History
                        </Text>
                        <DataGrid
                          width="100%"
                          headerHeight={40}
                          rowHeight={40}
                          sx={datagridSx}
                          columns={[
                            {
                              field: "id",
                              flex: 1,
                              minWidth: 150,
                            },
                            {
                              field: "username",
                              width: 200,
                            },
                            {
                              field: "age",
                              flex: 0.3,
                              minWidth: 50,
                            },
                          ]}
                          rows={rows}
                          hideFooter
                          disableSelectionOnClick
                        />
                      </View>
                    </Flex>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal> */}
        {/* material carosel */}
        {/* <Modal size="xl" >
        <Modal.Body style={{backgroundColor:"#d4d4d45e",margin: 'auto'}} >
        <Box sx={{ maxWidth: 800, flexGrow: 1 }} >
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 800,
                  objectFit:'contain',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
        </Modal.Body>
      </Modal> */}
      </LoadingOverlay>
    </div>
  );
};
export default ContentLibrary;
