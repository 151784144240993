import {
  Button,
  Grid,
  useBreakpointValue,
  Flex,
  Icon,
  View,
  TabItem,
  Tabs,
  Text,
  TextField,
  SelectField,
  PhoneNumberField,
  FieldGroupIcon,
  FieldGroupIconButton,
  Alert,
  Card,
} from "@aws-amplify/ui-react";
import { useLocation } from "react-router-dom";
import { listCompanyInfos } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { updateCompanyInfo } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import DashboardHeader from "../../layout/DashboardHeader";
import "../../App.css";
import { Auth } from 'aws-amplify'
import { MdCreditCard } from "react-icons/md";
import AccountInfo from "./AccountInfo";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const initialState = {
  id: "",
  card_number: "",
  card_holder_name: "",
  security_code: "",
  expiry_month: "",
  expiry_year: "",
  billing_address: "",
  city: "",
  state: "",
  zipcode: "",
};

const BillingInfo = ({ tabindex, compIdFromAccInfo, backindex,currentIndex }) => {
  const [BillInfoformState, setFormState] = useState(initialState);
  const [version, set_version] = useState(0);
  const location = useLocation();

  function setInput(key, value) {
    setFormState({ ...BillInfoformState, [key]: value });
    localStorage.setItem(
      "BillInfoformState",
      JSON.stringify(BillInfoformState)
    );
  }
  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])

  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const buttonStyle = {
    backgroundColor: "#639",
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
  };

  const clearStyle = {
    backgroundColor: "#f44336",
    size: "small",
    color: "#fff",
    height: "2rem",
    border: "rgb(244 67 54)",
    float: "center",
  };
  const comHead = {
    width: "101%",
    marginleft: "-1%",
    marginright: "-1%",
    margintop: "-1%",
  };
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const card = {
    width: "80%",
    backgroundColor: "white",
    marginLeft: "11%",
    boxShadow: "0px 4px 9px 0px rgb(0 0 0 / 20%)",
  };

  //save Function
  const SaveBillingInfo = async () => {
    localStorage.setItem("From", 0);

    try {
      const saveInfos = {
        id: compIdFromAccInfo,
        card_number: parseInt(BillInfoformState.card_number),
        card_holder_name: BillInfoformState.card_holder_name,
        security_code: BillInfoformState.security_code,
        expiry_month: BillInfoformState.expiry_month,
        expiry_year: BillInfoformState.expiry_year,
        billing_address: BillInfoformState.billing_address,
        city: BillInfoformState.city,
        state: BillInfoformState.state,
        zipcode: BillInfoformState.zipcode,
        _version: version,
      };
      await API.graphql(
        graphqlOperation(updateCompanyInfo, { input: saveInfos })
      );

      setFormState(initialState);
      // Uid(userid);
      setTimeout(() => {tabindex(parseInt(currentIndex) + 1);
        
      }, 1500);
     // toast.success("Info has been updated successfully");
    } catch (err) {
      toast.error("Error");

      console.log("error", err);
    }
  };
  const back = () => {
    localStorage.setItem("From", 0);
    tabindex(parseInt(currentIndex)-1);
    //Uid(userid);
    backindex(1);
  };
  //fetch fetchBillinginfo
  const fetchBillinginfo = async () => {
    try {
      let filter = {
        id: {
          eq: compIdFromAccInfo, // filter priority = 1
        },
      };

      const userdata = await API.graphql({
        query: listCompanyInfos,
        variables: { filter: filter },
      });

      set_version(userdata.data.listCompanyInfos.items[0]._version);
      setFormState(userdata.data.listCompanyInfos.items[0]);
    } catch (err) {
      console.log("error fetching actors");
    }
  };
  useEffect(() => {
    // alert("BI" + userid);
    fetchBillinginfo();

    try {
      let data = JSON.parse(localStorage.getItem("BillInfoformState"));

      if (data != null) {
        setFormState(data);
      }
    } catch (err) {
      toast.error("err save");
      console.log(err);
    }
  }, [compIdFromAccInfo]);

  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />

      <View>
        <Flex direction={"1em"} marginTop="2em">
          <View width="100%">
            <Card
              className="cardmain"
              style={card}
              direction={{ base: "column", large: "row" }}
            >
              <View
                className="comHead"
                backgroundColor={"#b71f39"}
                borderRadius={{ base: "xs", large: "small" }}
                fontSize={{ base: "small", large: "medium" }}
                color={"white"}
                padding={{ base: "xs", large: "xs" }}
              >
                Billing Info
              </View>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  id="hosp_name_field"
                  onPaste={(event) => event.replace(/[aA-zZ]|[^\w\s]/gi, "")}
                  onChange={(event) =>
                    setInput(
                      "card_number",
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, "")
                        .toString()
                        .slice(0, 10)
                    )
                  }
                  value={BillInfoformState.card_number}
                  label={<Text>Card Number</Text>}
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%"}}
                ></TextField>

                <TextField
                  onChange={(event) =>
                    setInput(
                      "security_code",
                      event.target.value
                        .replace(/[^\w\s]|[aA-zZ]/, "")
                        .toString()
                        .slice(0, 4)
                    )
                  }
                  placeholder={"****"}
                  value={BillInfoformState.security_code}
                  id="hosp_zip_field"
                  label="Security Code*"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "20%"}}
                  innerEndComponent={
                    <FieldGroupIconButton
                      ariaLabel="Search"
                      variation="link"
                      // onClick={() => alert("😎")}
                    >
                      <MdCreditCard />
                    </FieldGroupIconButton>
                  }
                ></TextField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) =>
                    setInput("card_holder_name", event.target.value)
                  }
                  value={BillInfoformState.card_holder_name}
                  id="hosp_zip_field"
                  label="Card Holder Name"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%"}}
                ></TextField>

                <SelectField
                  label="Expiration Month"
                  width={{
                    base: "100%",
                    large: "20%",
                    medium: "100%",
                    small: "100%",
                  }}
                  onChange={(event) =>
                    setInput("expiry_month", event.target.value)
                  }
                  value={BillInfoformState.expiry_month}

                  // size="small"
                >
                  <option selected hidden>
                    MM
                  </option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </SelectField>
                <SelectField
                  label="ExpirationYear"
                  width={{
                    base: "100%",
                    large: "14%",
                    medium: "100%",
                    small: "100%",
                  }}
                  onChange={(event) =>
                    setInput("expiry_year", event.target.value)
                  }
                  value={BillInfoformState.expiry_year}
                >
                  <option selected hidden>
                    YY
                  </option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                </SelectField>
              </Flex>

              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) =>
                    setInput("billing_address", event.target.value)
                  }
                  value={BillInfoformState.billing_address}
                  id="hosp_zip_field"
                  label="Billing Street Address"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%"}}
                ></TextField>
                <TextField
                  onChange={(event) => setInput("city", event.target.value)}
                  value={BillInfoformState.city}
                  id="hosp_zip_field"
                  label="City"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%"}}
                ></TextField>
              </Flex>
              <Flex
                direction={{ base: "column", large: "row" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <TextField
                  onChange={(event) => setInput("state", event.target.value)}
                  value={BillInfoformState.state}
                  id="hosp_zip_field"
                  label="State"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%"}}
                ></TextField>
                <TextField
                  onPaste={(event) => event.replace(/[aA-zZ]|[^\w\s]/gi, "")}
                  onChange={(event) =>
                    setInput(
                      "zipcode",
                      event.target.value
                        .replace(/[^\w\s]|[aA-zZ]/gi, "")
                        .toString()
                        .slice(0, 6)
                    )
                  }
                  value={BillInfoformState.zipcode}
                  id="hosp_zip_field"
                  label="Zip"
                  size={{ base: "small", large: "small" }}
                  width={{ base: "100%", large: "40%"}}
                ></TextField>
              </Flex>

              <Flex
                direction={{ base: "row", large: "row" }}
                marginTop={{ base: "2em", small:"2em",medium:"2em", large: "2em" }}
                margin={{ base: "small", large: "medium" }}
                gap={{ base: "small", large: "xxxl" }}
              >
                <Button
                  size={{ base:"small", small:"small", large: "small" }}
                  backgroundColor={"rgb(33, 46, 80)"}
                  fontSize={{base:"0.6em",large:"0.9em"}}
                  style={buttonStyle}
                  marginRight={"auto"}
                  onClick={back}
                >
                  <Icon
                    as={BsFillArrowLeftCircleFill}
                    fontSize={{ base:"large", small:"xl", large: "xxl" }}
                    marginTop={{  base:"relative.xs", small:"relative.xs", large: "relative.xxs" }}
                  ></Icon>
                  &nbsp;&nbsp; Account Info
                </Button>
                <Button
                  size="small"
                  backgroundColor={"rgb(33, 46, 80)"}
                  style={buttonStyle}
                  marginLeft={"auto"}
                  fontSize={{base:"0.6em",large:"0.9em"}}
                  onClick={SaveBillingInfo}
                >
                  User Info &nbsp;&nbsp;
                  <Icon
                    as={BsFillArrowRightCircleFill}
                    fontSize={{ base:"large", small:"xl", large: "xxl" }}
                    marginTop={{  base:"relative.xs", small:"relative.xs", large: "relative.xxs" }}
                  ></Icon>
                </Button>
              </Flex>
            </Card>
          </View>
        </Flex>
      </View>
    </div>
  );
};
export default BillingInfo;
