import React, { useEffect, useState, CSSProperties } from "react";
import {
    Button,
    useTheme,
    TextField,
    Text,
    Icon,
    Flex,
    Image,
    View,
    Grid,
    TextAreaField,
    Collection,
    SelectField,
} from "@aws-amplify/ui-react";
import { ToggleButton, ToggleButtonGroup } from "@aws-amplify/ui-react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    RiDeleteBin5Line,
    RiImage2Fill,
    RiEditBoxLine,
    RiImageAddFill,
    RiShareForwardLine,
    RiClapperboardLine,
} from "react-icons/ri";
import { Carousel, Card, Stack } from "react-bootstrap";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from '@mui/icons-material/Instagram';
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { createAdCampaign, updateAdCampaign } from "../../graphql/mutations";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { listAdCampaigns ,  listContentLibraries} from "../../graphql/queries";
import "../../App.css";
import { BiReset, BiSkipNext } from "react-icons/bi";
import { BsFillArrowRightCircleFill, BsHandIndexThumb } from "react-icons/bs";
import { SiFacebook } from "react-icons/si";
import { GrInstagram } from 'react-icons/gr';
import { ResetIcon, primaryButton, secondaryButton } from "../../assets/style";
import { ToastContainer, toast } from "react-toastify";
import ContentLibrary from "../contentlibrary/ContentLibrary";
import Modal from "react-bootstrap/Modal";
import { fontWeight, width } from "@mui/system";
import { Storage } from "@aws-amplify/storage";
const imageArr = ["https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE", "https://cn.i.cdn.ti-platform.com/cnapac/content/2017/showpage/ben-10/sa/showicon.png"]
const GenericContent = ({ setGeneriContentValue, GenericContentValue, redirect_ge,token,adsAccountId ,DisableFieldsAfterpublished}) => {
    const setInput = (key, value) => {
     
      console.log("key",key ,value)
        let temp = [...GenericContentValue]
        temp[0][key] = value;
        setGeneriContentValue(temp);
////////validate/////
console(GenericContentValue[0].image,"ss")
if (key == "fbmsg") {

    if (value == "") {
      setcontent(true);
    } else {
      setcontent(false);
    }
  }


  if(key == "fbimage"){
    if ( value == ""){
        setimagee(true);

    }else{
        setimagee(false);
    }
  }

}
  ////validation setvariable
  const [content, setcontent] = useState(false);
  const [ imagee, setimagee] = useState(imageArr[0]);
   ////   validation for content
   const validation=()=>
   {

     if(GenericContentValue[0]["fbmsg"]=="")
     {
      setcontent("Required content");
     }else{
      setcontent(false);
     }

     if(GenericContentValue[0]["fbmsg"]!=""&& GenericContentValue[0].fbimage !="")
      {
        
          redirect_ge()

      }
     if(GenericContentValue[0].fbimage =="" )
      { 
      toast.error("upload image");
          setimagee("required image");

      }else{
          setimagee(false);
      }







    };

    const [ShowContentLibrary, set_ShowContentLibrary] = useState(false);




 // content Library
const [ListOfContentLibrary, setListOfContentLibrary] = useState([]);
const getContentLibrary = async () => {
  try {
    let filter = {
      companyinfoID: { eq: localStorage.getItem("companyinfoID") },
      isDelete: { eq: false },
    };
    const listOfItem = await API.graphql({
      query: listContentLibraries,
      variables: { filter: filter },
    });
    const ListOfContentLibraryTable =
      listOfItem.data.listContentLibraries.items.filter(
        (item) =>
          (item._deleted !== true || item._deleted == null) &&
          item.media_type.substring(0, 5) == "fbimage"
      ); //Store all data
    // Loop the All File to Get from S3
    console.log("Listofimage: ", ListOfContentLibraryTable);
    const CognitoUserid = localStorage.getItem("Cognito_User_Id"); //particular company Id
    if (
      ListOfContentLibraryTable.length != "" &&
      ListOfContentLibraryTable.length != 0
    ) {
      for (var i = 0; i < ListOfContentLibraryTable.length; i++) {
        const signedURL = await Storage.get(
          ListOfContentLibraryTable[i].media_name,
          {
            expires: 3600,
            level: "protected",
            identityId: CognitoUserid,
          }
        );
        ListOfContentLibraryTable[i].media_url = signedURL; //Get Signedurl Reassign Media_url
        console.log(
          "signedURL",
          signedURL,
          ListOfContentLibraryTable[i].media_url
        );
      }
      console.log("ListofimageUpdatedfsf: ", ListOfContentLibraryTable);

      setTimeout(() => {
        setListOfContentLibrary(ListOfContentLibraryTable);
      }, 1000);
    }
  } catch (err) {
    console.log("error fetching Ad", err);
  }
  console.log("ListofimageUpdateAd: ", ListOfContentLibrary);
};
const [ImageSrcValue, setImageSrcValue] = useState();
    const handler = (event) => {
      const ImageSrc = event.target.src;
      console.log("Index", ImageSrc); //will log the index of the clicked item
      setImageSrcValue(ImageSrc);
    };

    const [showad, setShowad] = useState(false);
      const handleClosead = () => {
        setShowad(false);
        set_ShowContentLibrary(false);
        setImage(ImageSrcValue);
        // console.log("Value", ImageSrcValue);
      };


       //fetch image from content library
  const ShowContentPoppUp = () => {
  set_ShowContentLibrary(true);
  getContentLibrary();
};
const handleShowad = () => {
  setShowad(true);
};



    const ResetGeCt = () => {
        let temp = [...GenericContentValue]
        console.log(temp, "GCtemp");
        temp[0]["fbmsg"] = "";
        temp[0]["fbimage"] = "";
        setGeneriContentValue(temp);
        setImage(imageArr[0]);

    }
const[Progressbar,setProgressbar]=useState(false);

    const onImageChange =  (event) => {
        const file = event.target.files[0];
        console.log(event.target.files[0], "event");
        if (event.target.files && event.target.files[0]) {
            setProgressbar(true);
            setImage(URL.createObjectURL(event.target.files[0]));

            toDataURL(URL.createObjectURL(event.target.files[0]))
              .then(dataUrl => {
                const headers = {
                  "content-type": "application/x-www-form-urlencoded",
                };

                const data = new FormData();

                data.append("bytes", dataUrl.split(",")[1]);
                axios
                    .post(
                        "https://graph.facebook.com/v15.0/" +
                        adsAccountId +
                        "/adimages?&access_token=" +
                        token,
                        data,
                        {
                            headers: headers,
                        }
                    )
                    .then(function (responsef) {
                        console.log(responsef.data.images.bytes.hash, "campaign_id");
                        GenericContentValue[0]["fbimage"] = responsef.data.images.bytes.url
                        setProgressbar(false)
                    })
                    .catch(function (error) {
                        toast.error("Error Saving Ad to Fb");
                        setProgressbar(false)
                    });
            })
        }
    };

    const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))
    const { tokens } = useTheme();
    //const[imageindex,setimageindex]=useState();
    const [image, setImage] = useState(imageArr[0]);
    return (<><div>
        <ToastContainer autoClose={1500} limit={0} />
        <Flex
            direction={{
                base: "column",
                small: "column",
                medium: "column",
                large: "column",
                xl: "column",
                xxl: "column",
            }}
            gap={{ large: "xs" }}
            border="2px solid #E8E8E8"
            borderRadius="0.5em"
            padding={"2rem"}
        >
            <Flex
                direction={{
                    base: "column",
                    small: "column",
                    medium: "row",
                    large: "row",
                    xl: "row",
                    xxl: "row",
                }}
                gap={{ large: "xxl" }}
            >
                <View width={{ base: "100%", small: "100%", medium: "50%", large: "50%" }}>

                <TextAreaField  value={GenericContentValue[0]["fbmsg"]} onChange={(event) => setInput("fbmsg", event.target.value)} placeholder="Relax! Life is Beautiful" height={{ base: "10rem", small: "22rem", medium: "22rem", large: "22rem" }}
                        size="small" rows="15" hasError={content} errorMessage={content}  isDisabled={DisableFieldsAfterpublished}
                        label={
                            <Text>
                             Content
                              <Text as="span" fontSize="0.8rem" color="red">
                                {" "}
                                *
                              </Text>
                            </Text>
                        }>
                        </TextAreaField>
                </View>
                <View width={{ base: "100%", small: "100%", medium: "50%", large: "50%" }}>
                     {/* <Text fontWeight={"bold"}>Media  {" "}
                        *{" "}</Text> */
                        <Text>
                             Media
                              <Text as="span" fontSize="0.8rem" color="red">
                                {" "}
                                *
                              </Text>
                            </Text>

                        }
                    <Card
                        style={{
                            width: "100%",
                            border: "1px solid black",
                            marginTop: "5px",
                            padding: "1em",
                        }}

                    >
                        <Flex
                            direction="row"
                            justifyContent="center"
                            style={{ marginTop: "-1em" }}
                        >
                            <ToggleButtonGroup
                                isExclusive
                                border="none"
                                margin="0em"
                                className="custom-tabstoggle"
                            >
                                <ToggleButton
                                    // style={{ boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)', backgroundColor: 'rgb(5 84 164 / 46%)', backdropFilter: 'blur(2px)', border: 'none' }}
                                    className="custom-tab-itemtoggle"
                                    tooltip="Browse From Local"
                                    isDisabled={DisableFieldsAfterpublished}
                                >
                                    <input
                                        className="choosefile"
                                        type="file"
                                        name="myImage"
                                        id="browse"
                                        onChange={onImageChange}
                                        multiple
                                       // disabled={DisableFieldsAfterpublished}
                                    />
                                    <Icon
                                        target="#browse"
                                        as={RiImage2Fill}
                                        fontSize={{ large: "large" }}
                                    ></Icon>

                                </ToggleButton>
                                <ToggleButton
                                    className="custom-tab-itemtoggle"
                                    tooltip="Browse From Content Library"
                                    isDisabled={DisableFieldsAfterpublished}
                                    onClick={ShowContentPoppUp}
                                >
                                    <Icon
                                      as={RiClapperboardLine}
                                      fontSize={{ large: "large" }}
                                    />
                                </ToggleButton>
                                {/* <ToggleButton className="custom-tab-itemtoggle">
                                    <Icon
                                        as={RiDeleteBin5Line}
                                        fontSize={{ large: "large" }}

                                    ></Icon>
                                </ToggleButton> */}
                                {/* <ToggleButton className="custom-tab-itemtoggle">
                                        <Icon
                                            as={RiEditBoxLine}
                                            fontSize={{ large: "large" }}
                                        ></Icon>
                                    </ToggleButton> */}
                                {/* <ToggleButton className="custom-tab-itemtoggle">
                                    <Icon
                                        as={RiImageAddFill}
                                        fontSize={{ large: "large" }}
                                    ></Icon>
                                </ToggleButton> */}
                            </ToggleButtonGroup>
                        </Flex>
                        <Card
                            style={{
                                color: "black",
                                border: "none",
                                marginTop: "-2em",
                            }}
                        >
                            <Card.Body style={{ padding: "0em" }}>
                                <Card.Text>
                                    <Flex direction={"row"} alignItems="center">
                                        {/* onClick={() => { setImage(imageArr[0]) }} */}
                                        <View width="10%"  >
                                            <ChevronLeftIcon />
                                        </View>
                                        <View width="80%">
                                            <Image
                                                className="img-fluid rounded img-responsive"
                                                src={GenericContentValue[0]["fbimage"] != "" ? GenericContentValue[0]["fbimage"] : image}
                                                // alt="Second slide"
                                                minHeight={{
                                                    base: "30vh",
                                                    small: "30vh",
                                                    medium: "30vh",
                                                    large: "30vh",
                                                    xl: "30vh",
                                                    xxl: "40vh",
                                                }}
                                                maxHeight={{
                                                    base: "30vh",
                                                    small: "30vh",
                                                    medium: "30vh",
                                                    large: "30vh",
                                                    xl: "40vh",
                                                    xxl: "40vh",
                                                }}
                                                style={{
                                                    width: "100%",
                                                    objectFit: "contain",
                                                    backgroundColor: "rgba(128, 128, 128, 0.07)",
                                                    borderRadius: "0.5em",
                                                }}
                                            />
                                        </View>
                                        <View width="10%">
                                            {/* onClick={() => { setImage(imageArr[1]) }} */}
                                            <ChevronRightIcon />
                                        </View>
                                    </Flex>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Card>
                </View>
            </Flex>
            <Flex
                justifyContent="center"
                gap="3rem"
                marginTop={{ base: "1em", small: "2em", medium: "2em", large: "2em" }}
            >
                <Button
                    backgroundColor="#df2121"
                    style={primaryButton}
                    boxShadow="large"
                    onClick={ResetGeCt}
                    borderRadius="2em"
                    isDisabled={DisableFieldsAfterpublished}
                >
                    Reset
                    <Icon
                        as={RestartAltIcon}
                        fontSize={{ large: "xl" }}
                        color="white"
                        fontWeight={"bold"}
                        marginLeft="0.3em"
                    ></Icon>
                </Button>
                <Button
                    variation="primary"
                    style={primaryButton}
                    boxShadow="large"
                    borderRadius="2em"
                    backgroundColor={"#202A44"}
                    isLoading={Progressbar}
                    //onClick={redirect_ge}
                    onClick={validation}
                    loadingText="Image Uploading..."
                    isDisabled={DisableFieldsAfterpublished}
                //onClick={CreateCampaign} //
                >
                    Next
                    <Icon
                        as={BsFillArrowRightCircleFill}
                        fontSize={{ base: "1.6em", small: "1.6em", medium: "1.8em", large: "1.9em" }}
                        color="white"
                        fontWeight={"bold"}
                        marginLeft="0.5em"
                        marginTop={"0.36em"}
                    ></Icon>
                </Button>
            </Flex>
        </Flex>




        <Modal size="xl" show={ShowContentLibrary} onHide={handleClosead}>
        <Modal.Header
          style={{
            border: "none",
            padding: "2em",
            paddingBottom: "0em",
            display: "flow-root",
          }}
        >
          <Flex direction={"row"} gap="xxxl" justifyContent="space-between">
            <View width="50%">
              <Text style={{ fontSize: "1.3em", fontWeight: "600" }}>
                Content Library
              </Text>
            </View>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          {/* <MediaCollection
              setUrl={setImage}
              set_localimg={set_localimg}
            ></MediaCollection> */}

          <Collection
            type="grid"
            isSearchable={true}
            isPaginated={true}
            searchPlaceholder="Search..."
            itemsPerPage={12}
            templateColumns={{
              base: "1fr 1fr",
              small: "1fr 1fr 1fr",
              medium: "1fr 1fr 1fr",
              large: "1fr 1fr 1fr 1fr ",
              xl: "1fr 1fr 1fr 1fr 1fr 1fr",
              xxl: "1fr 1fr 1fr 1fr 1fr 1fr",
            }}
            autoFlow="row"
            marginLeft={{
              base: "0em",
              small: "0em",
              medium: "0em",
              large: "0em",
              xxl: "0em",
            }}
            alignItems="center"
            justifyContent="center"
            className="collectionad"
            items={ListOfContentLibrary.sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt;
            }).reverse()} // items={listOfFile}
          >
            {(image, index) => (
              <Flex
                key={index}
                className="onfocus"
                gap="10px"
                direction="column"
                width={{
                  base: "150px",
                  medium: "140px",
                  small: "135px",
                  large: "170px",
                  xl: "165px",
                  xxl: "170px",
                }}
                height={{
                  base: "155px",
                  medium: "170px",
                  small: "180px",
                  large: "190px",
                  xl: "190px",
                  xxl: "190px",
                }}
                justifyContent="flex-start"
                alignItems="flex-start"
                position="relative"
                borderRadius="5px"
                padding="8px 7px 8px 7px"
                boxShadow={"large"}
                marginTop={"1em"}
                marginBottom={"1em"}
                marginLeft={"1em"}
                backgroundColor="rgba(255,255,255,1)"
                onClick={handler}
              >
                <Flex
                  gap="0px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                >
                  <Flex
                    gap="10px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    borderRadius="5px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(56, 53, 53, 0.06)"
                  >
                    <Image
                      src={image.media_url}
                      width="unset"
                      height={{
                        base: "110px",
                        medium: "120px",
                        small: "130px",
                        large: "140px",
                      }}
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      borderRadius="5px"
                      alt="image"
                      padding="0px 0px 0px 0px"
                      objectFit="contain"
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Flex>
                  <Flex
                    gap="1px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                  >
                    <Flex
                      gap="10px"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="10px 10px 10px 10px"
                    >
                      {" "}
                      {image.media_name.length > 15 ? (
                        <Text
                          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                          fontSize={{
                            base: "10px",
                            medium: "10px",
                            small: "10px",
                            large: "12px",
                            xxl: "15px",
                          }}
                          fontWeight="500"
                          color="rgba(0,0,0,1)"
                          lineHeight="14px"
                          textAlign="left"
                          display="block"
                          direction="column"
                          justifyContent="unset"
                          width="unset"
                          height="unset"
                          gap="unset"
                          alignItems="unset"
                          grow="1"
                          shrink="1"
                          basis="0"
                          position="relative"
                          padding="0px 0px 0px 0px"
                          whiteSpace="pre-wrap"
                          textTransform="capitalize"
                        >
                          {image.media_name.substring(0, 12)}...
                        </Text>
                      ) : (
                        <Text
                          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                          fontSize={{
                            base: "10px",
                            medium: "10px",
                            small: "10px",
                            large: "12px",
                            xxl: "15px",
                          }}
                          fontWeight="500"
                          color="rgba(0,0,0,1)"
                          lineHeight="14px"
                          textAlign="left"
                          display="block"
                          direction="column"
                          justifyContent="unset"
                          width="unset"
                          height="unset"
                          textTransform="capitalize"setImage
                          gap="unset"
                          alignItems="unset"
                          grow="1"
                          shrink="1"
                          basis="0"
                          position="relative"
                          padding="0px 0px 0px 0px"
                          whiteSpace="pre-wrap"
                        >
                          {image.media_name}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Collection>
          {/* <Button variant="secondary" onClick={handleClose}>
  Close
</Button> */}
          <Flex
            direction="column"
            alignItems="center"
            style={{ marginTop: "1em" }}
            marginBottom={{ large: "relative.large" }}
          >
            <Button
              style={primaryButton}
              boxShadow="large"
              onClick={handleClosead}
            >
              Insert{" "}
              <Icon
                as={BsHandIndexThumb}
                fontSize={{ large: "large" }}
                color="white"
                fontWeight={"bold"}
                marginTop="0.4em"
                marginLeft="0.3em"
              ></Icon>
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>














    </div ></>






    );
}

export default GenericContent;
