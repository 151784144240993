import React, { useEffect, useState, CSSProperties } from 'react'
import { Flex } from '@aws-amplify/ui-react'
import './managestyle.css'
import {
  Icon,
  SelectField,
  FieldGroupIcon,
  FieldGroupIconButton,
  CheckboxField
} from '@aws-amplify/ui-react'
import {
  Button,
  Grid,
  View,
  useTheme,
  TextField,
  Text,
  Divider,
  SearchField,
  Link
} from '@aws-amplify/ui-react'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { BiMinus, BiReset, BiExit, BiSave, BiSkipNext } from 'react-icons/bi'
import { BsHandIndexThumb } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import { getAdSet } from '../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { Form } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import axios from 'axios'
import '../../App.css'
import { createAdSet, updateAdSet } from '../../graphql/mutations'
import Multiselect from 'multiselect-react-dropdown'
import { ToastContainer, toast } from 'react-toastify'
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel
} from '@mui/x-data-grid'
import { SwitchField } from '@aws-amplify/ui-react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { listOrganizationInfos } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'
import { ResetIcon } from '../../assets/style'
import { datagridSx } from './Gridpopups'
import { RiDraftLine } from 'react-icons/ri'
import { useRef } from 'react'
import { primaryButton, secondaryButton } from '../../assets/style'
import { listAdSets } from '../../graphql/queries'
//import Instagram from "@mui/icons-material/Instagram";
//import { LocationOn, PhotoSizeSelectLargeOutlined } from "@mui/icons-material";
const Adset = ({
  editAdsetValue,
  Conversion_check,
  setcampaignForm,
  setlistofplacement,
  campaignForm,
  adsetBudget,
  adsetConversionLocation,
  adsetConversionEvent,
  adsetplacement,
  fun,
  adsetage,
  FB_CAMPAIGN_ID,
  adsAccountId,
  accessToken,
  PageIdFromFbLogin,
  ADSET_ID,
  SET_ADSET_ID,
  SET_ADSETID_FRM_DB,
  SETUPDT_ADSET_ID,
  ADSETID_FRM_DB,
  CAMPID_FRM_DB,
  listofplacement,
  // ADSET_ID,
  RedirectAd,
  columns,
  setadset_pagestatus,
  AdsetformState,
  setAdsetformState,
  selectedOptionscustomaudience,
  setSelectedOptionscustomaudience,
  selectintrests,
  setselectintrests,
  selectdemographics,
  setselectdemographics,
  selectexclude_audience,
  setselectexcludeaudience,
  selectlocation,
  setselectlocation,
  selectbehaviour,
  setselectbehaviour,
  checked1,
  setchecked1,
  checked2,
  setchecked2,
  DisableAfterPublish,
  AdsetVersionforUpdate,
  setAdsetVersionforUpdate
}) => {
  //form datafields
  // const [AdsetformState, setAdsetformState] = useState({
  // campaign_id: "",
  // adset_name: "",
  // adset_id: "",
  // budget_type: "",
  // budget_amount: "",
  // schedule_start_date: "",
  // schedule_start_time: "",
  // schedule_end_date: "",
  // schedule_end_time: "",
  // conversion_location: "",
  // conversion_events: "",
  // placements: "",
  // custom_audience: "",
  // exclude_custom_audience: "",
  // age_from: "",
  // age_to: "",
  // ads_account_id: "",
  // gender: "",
  // demographics: "",
  // interests: "",
  // behaviors: "",
  // location: "",
  // language: "",
  // });
  const [chkstate, setchkstate] = useState(false)
  //const [adset_id, setadset_id] = useState();
  const [company_id, setcompany_id] = useState()
  const [facebook_userid, setfacebook_userid] = useState()
  const [adsaccount, setadsaccount] = useState('')
  const [listCampaign, setlistCampaign] = useState()
  const [listofCategories, setlistofCategories] = useState()
  const [listofObjectives, setlistofObjectives] = useState()
  const [listofBudget, setlistofBudget] = useState()
  const [Progressbar, setProgressbar] = useState(false)
  const [Convo, setConvo] = useState(false)
  let [color, setColor] = useState('#ffffff')
  let [loader, setloader] = useState(false)
  //state variable for serachfield grid
  const [listad, setlistad] = useState([])
  const [staticlist, setstaticlistadd] = useState()
  const [SelectedAdsetId, setSelectedAdsetId] = useState()
  const [FlagVariable, set_FlagVariable] = useState(false)
  const [Ageto, setAgeto] = useState(false)

  //state variables for API calls
  const [listOfLocation, setListOfLocation] = useState()
  const [listOfDemographics, setListOfDemographics] = useState()
  const [listOfBehaviors, setListOfBehaviors] = useState()
  const [listOfInterests, setListOfInterests] = useState()
  const [listofcustomAudience, setlistofcustomAudience] = useState()
  const passwordRef = useRef()
  // let fbcheck = "";
  // let instacheck = "";
  const [fbcheck, setfbcheck] = useState()
  const [instacheck, setinstacheck] = useState()
  const [DOBB, setDOBB] = useState('')
  // const [checked1, setchecked1] = useState(false);
  // const [checked2, setchecked2] = useState(false);
  const [UpdtCustomAudience, setUpdtCustomAudience] = useState(false)
  const [UpdtExcludeCustomAUdience, setUpdtExcludeCustomAUdience] =
    useState(false)
  const [UpdtDempographics, setUpdtDempographics] = useState(false)
  const [UpdtInterests, setUpdtInterests] = useState(false)
  const [UpdtBehaviours, setUpdtBehaviours] = useState(false)
  const [UpdtLocation, setUpdtLocation] = useState(false)
  const initialState = { name: '' }
  // const [formState, setFormState] = useState(initialState);
  const [nameErrorr, setNameErrorr] = useState('')
  const [nameError, setNameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [validatestartdate, setvalidatestartdate] = useState(false) //validatestartdate
  const [passerorr, setpasserror] = useState('')
  const [DOB, setDOB] = useState(false)

  const [Age, setAge] = useState('')
  const [Agee, setAgee] = useState(false)
  const [validateLocation, setvalidateLocation] = useState(false)
  const [locations, setlocations] = useState(false)
  const [instagram, setinstagram] = useState(false)
  const [validateenddate, setdate] = useState(false)
  const [validatedate, setdates] = useState(false)

  const [usdError, setusdError] = useState('')
  const [usdsError, setusdsError] = useState(false)
  const [timeError, settimeError] = useState(false)
  const [timesError, setimesError] = useState(false) //validateenddtime
  const [validateenddtime, setvalidateenddtime] = useState(false)
  let PlacementArray = []
  let LocationArray = []
  let temp = []
  //state variable for agefromto
  const [startage, setstartage] = useState(adsetage)
  const [endage, setendage] = useState(adsetage)
  const [staticage, setstaticage] = useState(adsetage)
  const [shows, setshows] = useState(false)
  const [Updatecheck, setUpdatecheck] = useState(false)
  const [ExistingAdset_Id, setExistingAdset_Id] = useState()
  const [ExistingAdset_version, setExistingAdset_version] = useState()
  const [HideExistingAdSet, setHideExistingAdSet] = useState(false)
  const [SpecialAdcategoryselected, setSpecialAdcategoryselected] =
    useState(false)
  const [SpecialAdcategorydemobehav, setSpecialAdcategorydemobehav] =
    useState(false)
  

  //bridge Function
  const bridgeFunction = () => {

    //alert(Conversion_check);
    setProgressbar(true)
    if (Conversion_check == 'CONVERSIONS') {
      setConvo(true)
    } else {
      setConvo(false)
    }

    if (AdsetformState.adset_name == '') {
      setNameError(true)
    } else {
      setNameError(false)
    }
    if (AdsetformState.budget_type == '') {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }
    if (AdsetformState.budget_amount == '') {
      setusdsError(true)
    } else {
      setusdsError(false)
    }
    if (SpecialAdcategoryselected) {
      if (AdsetformState.age_from == '') {
        setAgee(true)
      } else {
        setAgee(false)
      }
      if (AdsetformState.age_to == '') {
        setAgeto(true)
      } else {
        setAgeto(false)
      }
    }

    if (AdsetformState.schedule_start_time == '') {
      setvalidatestartdate(true)
    } else {
      setvalidatestartdate(false)
    }

    if (AdsetformState.schedule_start_time == '') {
      setvalidatestartdate(true)
    } else {
      setvalidatestartdate(false)
    }

    if (AdsetformState.schedule_start_date == '') {
      setdates(true)
    } else {
      setdates(false)
    }

    if (locationRef.current.getSelectedItems().length > 0) {
      setvalidateLocation(false)
      setselectlocation(AdsetformState.location)
    } else {
      setvalidateLocation(true)
      setTimeout(() => {
        setProgressbar(false)
      }, 2500)
    }
    if (!fbcheck && !instacheck) {
      setDOB(true)
    } else {
      setDOB(false)
    }
    //if(AdsetformState.budget_type=="daily_budget")
    //{
    if (AdsetformState.schedule_end_date == '') {
      setdate(true)
    } else {
      setdate(false)
    }

    if (AdsetformState.schedule_end_time == '') {
      setvalidateenddtime(true)
    } else {
      setvalidateenddtime(false)
    }
    ///////////////mandatory/////////////

    if (!checked1 && !checked1) {
      toast.error('Please select the placement')
      setTimeout(() => {
        setProgressbar(false)
      }, 2500)
    }

    if (SpecialAdcategoryselected) {
      if (
        AdsetformState.adset_name == '' ||
        adsetplacement.fbcheck == '' ||
        adsetplacement.instacheck == '' ||
        AdsetformState.budget_type == '' ||
        AdsetformState.schedule_start_date == '' ||
        AdsetformState.schedule_start_time == '' ||
        AdsetformState.schedule_end_date == '' ||
        AdsetformState.schedule_end_time == '' ||
        locationRef.current.getSelectedItems().length < 0 ||
        AdsetformState.budget_amount == '' ||
        AdsetformState.age_from == '' ||
        AdsetformState.age_to == ''
      ) {
        toast.error('Please Fill mandatory fields')
        setTimeout(() => {
          setProgressbar(false)
        }, 2500)
      }
    } else {
      if (
        AdsetformState.adset_name == '' ||
        adsetplacement.fbcheck == '' ||
        adsetplacement.instacheck == '' ||
        AdsetformState.budget_type == '' ||
        AdsetformState.schedule_start_date == '' ||
        AdsetformState.schedule_start_time == '' ||
        AdsetformState.schedule_end_date == '' ||
        AdsetformState.schedule_end_time == '' ||
        locationRef.current.getSelectedItems().length < 0 ||
        AdsetformState.budget_amount == ''
      ) {
        toast.error('Please Fill mandatory fields')
        setTimeout(() => {
          setProgressbar(false)
        }, 2500)
      }
    }

    if (
      AdsetformState.adset_name != '' &&
      locationRef.current.getSelectedItems().length > 0 &&
      AdsetformState.budget_type != '' &&
      AdsetformState.schedule_start_date != '' &&
      AdsetformState.schedule_end_date != '' &&
      AdsetformState.budget_amount != '' &&
      AdsetformState.schedule_start_time !== '' &&
      AdsetformState.schedule_end_time !== ''
    ) {
      if (SpecialAdcategoryselected) {
        if (AdsetformState.age_from != '' || AdsetformState.age_to != '') {
          if (fbcheck || instacheck) {
            // alert("budget")
            setProgressbar(true)
            if (!Updatecheck) {
              CreateAdset()
            } else {
              UpdateAdset()
            }
          }
        }
      } else {
        if (fbcheck || instacheck) {
          // alert("budget")
          setProgressbar(true)
          if (!Updatecheck) {
            CreateAdset()
          } else {
            UpdateAdset()
          }
        }
      }
    } else {
      if (AdsetformState.budget_type == 'lifetime_budget') {
        setvalidateenddtime(false)
        setdate(false)

        if (
          AdsetformState.adset_name != '' &&
          locationRef.current.getSelectedItems().length > 0 &&
          AdsetformState.budget_type != '' &&
          AdsetformState.schedule_start_date != '' &&
          AdsetformState.age_from != '' &&
          AdsetformState.budget_amount != '' &&
          AdsetformState.age_to != '' &&
          AdsetformState.schedule_start_time
        ) {
          if (fbcheck || instacheck) {
            setProgressbar(true)
            CreateAdset()
          }
        }
      }
    }
    //setInput(key,value)
    // CreateAdset();
  }

  /////////////////////////////////////////////////

  const validation = (key, value) => {
    if (key == 'adset_name' && value.length == '') {
      setNameError(true)
    } else {
      setNameError(false)
    }

    if (key == 'budget_amount') {
      if (value.length == '') {
        setusdsError(true)
        setusdsError('Enter Digits.')
      } else {
        setusdsError(false)
      }
    }

    if (key == 'age_from') {
      if (value.length == '') {
        setAgee(true)
      } else {
        setAgee(false)
      }
    }
    if (key == 'age_to') {
      if (value.length == '') {
        setAgeto(true)
      } else {
        setAgeto(false)
      }
    }
    if (key === 'schedule_start_date') {
      if (value.length == '') {
        setdates(true)
      } else {
        setdates(false)
      }
    }
    if (key === 'schedule_end_date') {
      if (value.length == '') {
        setdate(true)
      } else {
        setdate(false)
      }
    }
    if (key == 'schedule_start_time') {
      if (value.length == '') {
        setvalidatestartdate(true)
      } else {
        setvalidatestartdate(false)
      }
    }
    if (key == 'schedule_end_time') {
      if (value.length == '') {
        setvalidateenddtime(true)
      } else {
        setvalidateenddtime(false)
      }
    }

    if (key == 'location') {
      if (value.length != '') {
        if (locationRef.current.getSelectedItems().length <= 0) {
          setvalidateLocation(true)
          setselectlocation(AdsetformState.location)
        } else {
          setvalidateLocation(false)
        }
      }
    }
    setInput(key, value)
  }

  //let placementRef = useRef();
  let locationRef = useRef()
  //let languageRef = useRef();
  let demographicsRef = useRef()
  let interestsRef = useRef()
  let behaviorsRef = useRef()
  let customAudienceRef = useRef()
  let excludeCustomAudienceRef = useRef()

  function setInput (key, value) {
    setAdsetformState({ ...AdsetformState, [key]: value })

  }

  //page rendering here
  useEffect(() => {
    // alert(campaignForm.special_ad_category);
    if (localStorage.getItem('Selectedspecialad') == 'NONE') {
      // alert(localStorage.getItem("Selectedspecialad"));
      setSpecialAdcategoryselected(true)
      //for hide demographics and behavoiur
      setSpecialAdcategorydemobehav(false)
    } else {
      setSpecialAdcategoryselected(false)
      //for hide demographics and behavoiur
      setSpecialAdcategorydemobehav(false)
      // alert(localStorage.getItem("Selectedspecialad"));
    }
    listAdSetss()
    setloader(true)
    GetCustomAudience()
    getLocation()
    getDemographicsList()
    getBehaviorsList()
    getInterestList('country')
  
    if (editAdsetValue !== undefined) {
      setUpdatecheck(true)
      setExistingAdset_Id(editAdsetValue.adset_id)
      setSelectedAdsetId(editAdsetValue.id)
      setExistingAdset_version(editAdsetValue._version)
      setListOfInterests(() => {
        if (editAdsetValue.interests?.length > 0) {
          return JSON.parse(editAdsetValue.interests[0])
        } else {
          return []
        }
      })
      
      setAdsetformState(editAdsetValue)
      setHideExistingAdSet(true)

      if (
        editAdsetValue.age_from == '0' &&
        editAdsetValue.age_to == '0' &&
        editAdsetValue.gender == ''
      ) {
        setSpecialAdcategoryselected(false)
      } else {
        setSpecialAdcategoryselected(true)
      }
      //console.log(editAdsetValue,"datafordata")
      JSON.parse(editAdsetValue.placements[0]).map(x => {
        if (x == 'facebook') {
          setfbcheck('facebook')
          setchecked1(true)
        }
        if (x == 'instagram') {
          setinstacheck('instagram')
          setchecked2(true)
        }
      })
      let temp = { ...editAdsetValue }
     
      temp.custom_audience =
        JSON.parse(editAdsetValue.custom_audience)?.length > 0
          ? JSON.parse(editAdsetValue.custom_audience[0])
          : []
      temp.demographics =
        JSON.parse(editAdsetValue.demographics)?.length > 0
          ? JSON.parse(editAdsetValue.demographics[0])
          : []
      temp.behaviors =
        JSON.parse(editAdsetValue.behaviors)?.length > 0
          ? JSON.parse(editAdsetValue.behaviors[0])
          : []

  
      temp.interests =
        editAdsetValue.interests?.length > 0
          ? JSON.parse(editAdsetValue.interests[0])
          : []
      temp.exclude_custom_audience =
        JSON.parse(editAdsetValue.exclude_custom_audience)?.length > 0
          ? JSON.parse(editAdsetValue.exclude_custom_audience[0])
          : []
      temp.gender =
        editAdsetValue.gender?.length > 0 ? editAdsetValue.gender[0] : []

      //onselect dropdown
      setSelectedOptionscustomaudience(() => {
        if (editAdsetValue.custom_audience?.length > 0) {
          return JSON.parse(editAdsetValue.custom_audience[0])
        } else {
          return []
        }
      })

      setselectdemographics(() => {
        if (editAdsetValue.demographics?.length > 0) {
          return JSON.parse(editAdsetValue.demographics[0])
        } else {
          return []
        }
      })

      setselectbehaviour(() => {
        if (editAdsetValue.behaviors?.length > 0) {
          return JSON.parse(editAdsetValue.behaviors[0])
        } else {
          return []
        }
      })

      setselectintrests(() => {
        if (editAdsetValue.interests?.length > 0) {
          return JSON.parse(editAdsetValue.interests[0])
        } else {
          return []
        }
      })

      setselectexcludeaudience(() => {
        if (editAdsetValue.exclude_custom_audience?.length > 0) {
          return JSON.parse(editAdsetValue.exclude_custom_audience[0])
        } else {
          return []
        }
      })

      try {
        temp.location =
          JSON.parse(editAdsetValue.location[0]).length > 0
            ? JSON.parse(JSON.parse(editAdsetValue.location[0]))
            : []
        setselectlocation(() => {
          if (JSON.parse(editAdsetValue.location[0]).length > 0) {
            return JSON.parse(JSON.parse(editAdsetValue.location[0]))
          } else {
            return []
          }
        })
      } catch {
        temp.location =
          editAdsetValue.location?.length > 0
            ? JSON.parse(editAdsetValue.location[0])
            : []
        setselectlocation(() => {
          if (editAdsetValue.location?.length > 0) {
            return JSON.parse(editAdsetValue.location[0])
          } else {
            return []
          }
        })
      }

      setAdsetformState(temp)

      if (editAdsetValue.conversion_events != '') {
        setchkstate(true)
      } else {
        setchkstate(false)
      }
    }
    if (FlagVariable) {
      getlistAdSetsUsingId()
    }
  }, [campaignForm, SelectedAdsetId, PageIdFromFbLogin, editAdsetValue])

  useEffect(() => {
    if (AdsetformState.adset_name !== '' && ADSETID_FRM_DB) setUpdatecheck(true)
  }, [])

  useEffect(() => {
    checked1 && setfbcheck('facebook')
    checked2 && setinstacheck('instagram')
  }, [checked1, checked2])

  const Resetbutton = () => {
    demographicsRef.current.resetSelectedValues()
    locationRef.current.resetSelectedValues()
    behaviorsRef.current.resetSelectedValues()
    customAudienceRef.current.resetSelectedValues()
    interestsRef.current.resetSelectedValues()
    excludeCustomAudienceRef.current.resetSelectedValues()
    setselectbehaviour('')
    setselectdemographics('')
    setselectexcludeaudience('')
    setselectlocation('')
    setselectintrests('')
    setSelectedOptionscustomaudience('')
    setchecked1(false)
    setchecked2(false)
    setchkstate(false)
    setAdsetformState({
      campaign_id: '',
      adset_name: '',
      adset_id: '',
      budget_type: '',
      budget_amount: '',
      schedule_start_date: '',
      schedule_start_time: '',
      schedule_end_date: '',
      schedule_end_time: '',
      age_from: '',
      age_to: '',
      ads_account_id: '',
      gender: ''
    })
  }

  //Save Adset to the model
  const saveAdset = async Ad_set_id => {
    

    // if(AdsetformState.age_from == "" && AdsetformState.age_to == "" && AdsetformState.gender == ""){

    // }

    try {
      const adset = {
        campaign_id: CAMPID_FRM_DB,
        adset_name: AdsetformState.adset_name,
        adset_id: Ad_set_id,
        budget_type: AdsetformState.budget_type,
        budget_amount: AdsetformState.budget_amount,
        schedule_start_date: AdsetformState.schedule_start_date,
        schedule_start_time: AdsetformState.schedule_start_time,
        schedule_end_date: AdsetformState.schedule_end_date,
        schedule_end_time: AdsetformState.schedule_end_time,
        conversion_location: '',
        conversion_events: AdsetformState.conversion_events,
        placements: JSON.stringify(temp),
        custom_audience: JSON.stringify(
          customAudienceRef.current.getSelectedItems()
        ),
        exclude_custom_audience: JSON.stringify(
          excludeCustomAudienceRef.current.getSelectedItems()
        ),
        age_from: AdsetformState.age_from == '' ? 0 : AdsetformState.age_from,
        age_to: AdsetformState.age_to == '' ? 0 : AdsetformState.age_to,
        ads_account_id: adsAccountId,
        gender: AdsetformState.gender == '' ? '' : AdsetformState.gender,
        interests:
          localStorage.getItem('Selectedspecialad') == 'NONE'
            ? JSON.stringify(interestsRef.current.getSelectedItems())
            : [],

        location: JSON.stringify(
          JSON.stringify(locationRef.current.getSelectedItems())
        ),
        //  language: [],
        adset_status: 'ACTIVE',
        created_by: localStorage.getItem('user_id'),
        updated_by: null,
        isActive: true,
        isDelete: false
      }
      const newAdset = await API.graphql(
        graphqlOperation(createAdSet, { input: adset })
      )
   
      // setAdsetformState(newAdset.data.createAdSet);
      setAdsetformState(newAdset.data.createAdSet)

      SET_ADSETID_FRM_DB(newAdset.data.createAdSet.id)
      setAdsetVersionforUpdate(newAdset.data.createAdSet._version)
      //////
      // alert(newAdset.data.createAdSet.id + " : yep");
      toast.success('AdSet created successfully')
      setadset_pagestatus(true)
      setTimeout(() => {
        if (temp.length > 0) {
          setProgressbar(false)
          RedirectAd()
        }
      }, 2000)

      //  console.log(newAdset, "newAdset");
    } catch (error) {
      toast.error('Error while creating AdSet')
      setadset_pagestatus(false)
      setProgressbar(false)
      
    }
  }

  //Create Adset in Facebook
  const CreateAdset = () => {
    let GenderArr = []
    setlistofplacement([])
    temp = []

    if (fbcheck !== '' && fbcheck !== null && fbcheck !== undefined) {
      temp.push('facebook')
    }
    if (instacheck !== '' && instacheck !== null && instacheck !== undefined) {
      temp.push('instagram')
    }
    setlistofplacement(temp)

    if (AdsetformState.gender === 'All') {
      GenderArr = ['1', '2']
    } else if (AdsetformState.gender === 'Male') {
      GenderArr = ['1']
    } else if (AdsetformState.gender === 'Female') {
      GenderArr = ['2']
    }


    let targeting_object = {}
    let promoted_object = {}

    LocationArray = JSON.parse(
      JSON.stringify(locationRef.current.getSelectedItems())
    ).map(function (el) {
      return el.key
    })

    const setselectintrests = selectedList => {
      setselectintrests(selectedList) // Update the state with selected interests
    }

    if (adsAccountId !== '' && adsAccountId !== undefined) {
      if (localStorage.getItem('Selectedspecialad') == 'NONE') {
        if (checked2) {
          // alert("none");
          promoted_object = {
            //: AdsetformState.conversion_events.toUpperCase(),
            page_id: PageIdFromFbLogin
          }
          targeting_object = {
            genders: GenderArr,
            age_min: AdsetformState.age_from,
            age_max: AdsetformState.age_to,
            geo_locations: { countries: LocationArray },
            publisher_platforms: temp,
            interests: selectintrests.map(interest => ({
              id: interest.id,
              name: interest.name
            })),

            instagram_positions: [
              'stream',
              'story',
              // "shop",
              'explore',
              'explore_home',
              'reels',
              'profile_feed'
            ]
          }
        } else {
          //  alert("dd1");
          promoted_object = {
            // custom_event_type: AdsetformState.conversion_events.toUpperCase(),
            page_id: PageIdFromFbLogin
          }
          targeting_object = {
            genders: GenderArr,
            age_min: AdsetformState.age_from,
            age_max: AdsetformState.age_to,
            geo_locations: { countries: LocationArray },
            publisher_platforms: temp,
            interests: selectintrests.map(interest => ({
              id: interest.id,
              name: interest.name
            }))
            // Demographics:selectdemographics.map(demographics=>({
            //   id:demographics.id,
            //   name:demographics.name
            // })),
            // behaviors:selectbehaviour.map(behaviors=>({id:behaviors.id,name:behaviors.name}))
          }
        }
      } else {
        if (checked2) {
          // alert("dd");
          promoted_object = {
            page_id: PageIdFromFbLogin
          }
          targeting_object = {
            geo_locations: { countries: LocationArray },
            publisher_platforms: temp,

            instagram_positions: [
              'stream',
              'story',
              // "shop",
              'explore',
              'explore_home',
              'reels',
              'profile_feed'
            ]
          }
        } else {
          // alert("5");
          promoted_object = {
            // custom_event_type: AdsetformState.conversion_events.toUpperCase(),
            page_id: PageIdFromFbLogin
          }
          targeting_object = {
            geo_locations: { countries: LocationArray },
            publisher_platforms: temp
          }
        }
      }

    
      axios({
        method: 'post',
        url:
          'https://graph.facebook.com//v15.0/' +
          adsAccountId +
          '/adsets?name=' +
          AdsetformState.adset_name +
          '&optimization_goal=REACH&is_dynamic_creative=true&billing_event=IMPRESSIONS&bid_strategy=LOWEST_COST_WITHOUT_CAP&' +
          AdsetformState.budget_type +
          '=' +
          AdsetformState.budget_amount * 100 +
          '&campaign_id=' +
          FB_CAMPAIGN_ID +
          '&time_start=' +
          AdsetformState.schedule_start_date +
          ' ' +
          AdsetformState.schedule_start_time +
          ' EDT' +
          '&time_stop=' +
          AdsetformState.schedule_end_date +
          ' ' +
          AdsetformState.schedule_end_time +
          ' EDT' +
          '&targeting=' +
          JSON.stringify(targeting_object) +
          '&status=ACTIVE&promoted_object=' +
          JSON.stringify(promoted_object) +
          '&access_token=' +
          accessToken,
        responseType: 'stream'
      })
        .then(function (FBAdsetResponse) {
          SET_ADSET_ID(FBAdsetResponse.data.id)
          saveAdset(FBAdsetResponse.data.id)
          setadset_pagestatus(true)
          setTimeout(() => {
            setProgressbar(false)
          }, 2000)
          // console.log(FBAdsetResponse, "FBAdsetResponse");
        })
        .catch(function (error) {
          //disable button
          setadset_pagestatus(false)
          setProgressbar(false)
          // toast.error(error.response.data.error.error_user_title);
          if (
            error.response.data.error.error_user_title.indexOf(
              'No End Date Entered'
            ) >= 0
          ) {
            toast.error('LifetimeBudget Temporarily Unavailable')
            setTimeout(() => {
              setProgressbar(false)
            }, 2500)
          } else {
            toast.error(error.response.data.error.error_user_title)
            setTimeout(() => {
              setProgressbar(false)
            }, 2500)
          }
          
        })
    }
  }

  //Update Adset in Facebook
  const UpdateAdset = () => {
    // console.log('UPDATEADSET');
    // console.log("accessToken", accessToken);
    temp = []
    setlistofplacement([])
    if (fbcheck !== '' && fbcheck !== null && fbcheck !== undefined) {
      temp.push(fbcheck.toLowerCase())
    }
    if (instacheck !== '' && instacheck !== null && instacheck !== undefined) {
      temp.push(instacheck.toLowerCase())
    }
    setlistofplacement(temp)

    let targeting_object = {}
    let promoted_object = {}

    LocationArray = JSON.parse(
      JSON.stringify(locationRef.current.getSelectedItems())
    ).map(function (el) {
      return el.key
    })
    if (adsAccountId !== '' && adsAccountId !== undefined) {
      if (checked2) {
        promoted_object = {
          // custom_event_type: AdsetformState.conversion_events.toUpperCase(),
          page_id: PageIdFromFbLogin
        }
        targeting_object = {
          geo_locations: { countries: LocationArray },
          publisher_platforms: temp,
          instagram_positions: [
            'stream',
            'story',
            // "shop",
            'explore',
            'explore_home',
            'reels',
            'profile_feed'
          ] //["stream", "explore", "explore_home"],
        }
      } else {
        promoted_object = {
          // custom_event_type: AdsetformState.conversion_events.toUpperCase(),
          page_id: PageIdFromFbLogin
        }
        targeting_object = {
          geo_locations: { countries: LocationArray },
          publisher_platforms: temp
        }
      }
    }

    let AdsetIdforUpdate =
      ExistingAdset_Id !== undefined ? ExistingAdset_Id : ADSET_ID
    axios({
      method: 'post',
      url:
        ' https://graph.facebook.com/v15.0/' +
        AdsetIdforUpdate +
        '?name=' +
        AdsetformState.adset_name +
        '&optimization_goal=REACH&is_dynamic_creative=true&billing_event=IMPRESSIONS&bid_strategy=LOWEST_COST_WITHOUT_CAP&' +
        AdsetformState.budget_type +
        '=' +
        AdsetformState.budget_amount * 100 +
        '&time_start=' +
        AdsetformState.schedule_start_date +
        ' ' +
        AdsetformState.schedule_start_time +
        ' EDT' +
        '&time_stop=' +
        AdsetformState.schedule_end_date +
        ' ' +
        AdsetformState.schedule_end_time +
        ' EDT' +
        '&targeting=' +
        JSON.stringify(targeting_object) +
        '&status=ACTIVE&promoted_object=' +
        JSON.stringify(promoted_object) +
        '&access_token=' +
        accessToken
      // responseType: "stream",
    })
      .then(async () => {
        let GraphqlAdsetIdforupdate =
          SelectedAdsetId !== undefined ? SelectedAdsetId : ADSETID_FRM_DB
        let GraphqlVersionforupdate =
          ExistingAdset_version !== undefined
            ? ExistingAdset_version
            : AdsetVersionforUpdate
        const adsetupdate = {
          id: GraphqlAdsetIdforupdate, //SelectedAdsetId
          adset_name: AdsetformState.adset_name,
          budget_type: AdsetformState.budget_type,
          budget_amount: AdsetformState.budget_amount,
          schedule_start_date: AdsetformState.schedule_start_date,
          schedule_start_time: AdsetformState.schedule_start_time,
          schedule_end_date: AdsetformState.schedule_end_date,
          schedule_end_time: AdsetformState.schedule_end_time,
          conversion_events: AdsetformState.conversion_events,
          placements: JSON.stringify(temp),
          custom_audience: JSON.stringify(
            customAudienceRef.current.getSelectedItems()
          ),
          exclude_custom_audience: JSON.stringify(
            excludeCustomAudienceRef.current.getSelectedItems()
          ),
          age_from: AdsetformState.age_from,
          age_to: AdsetformState.age_to,
          ads_account_id: adsAccountId,
          gender: AdsetformState.gender,
          // demographics: JSON.stringify(
          //   demographicsRef.current.getSelectedItems()
          // ),
          interests:
            localStorage.getItem('Selectedspecialad') == 'NONE'
              ? JSON.stringify(interestsRef.current.getSelectedItems())
              : [],
          // behaviors: JSON.stringify(behaviorsRef.current.getSelectedItems()),
          location: JSON.stringify(
            JSON.stringify(locationRef.current.getSelectedItems())
          ),
          //  language: [],
          adset_status: 'ACTIVE',
          updated_by: localStorage.getItem('user_id'),
          _version: GraphqlVersionforupdate
        }
      
        const updatedAdset = await API.graphql(
          graphqlOperation(updateAdSet, { input: adsetupdate })
        )
   
        setTimeout(() => {
          setProgressbar(false)
        }, 2000)
        toast.success('AdSet Updated Successfully')
        setadset_pagestatus(true)
        setTimeout(() => {
          RedirectAd()
        }, 2500)

        // console.log(FBAdsetResponse, "FBAdsetResponse");
      })
      .catch(function (error) {
        //disable button
        setadset_pagestatus(false)
        if (
          error.response.data.error.error_user_title.indexOf(
            'No End Date Entered'
          ) >= 0
        ) {
          toast.error('LifetimeBudget Temporarily Unavailable')
          // alert('d')
        } else {
          toast.error(error.response.data.error.error_user_title)
          // alert('d1')
        }
        setProgressbar(false)
    
      })
  }

  ///filter age in age_to
  const AgeRestrict = value => {
    // console.log(staticage,"testing");
    setendage(staticage.filter(x => x.id > value))
  }
  ///filter age in age_from
  const Ageselect = value => {
    setstartage(staticage.filter(x => x.id < value))
  }

  const columnsforadd = [
    {
      field: 'adset_name',
      flex: 1,
      minwidth: 20,
      headerName: 'Adset name',
      align: 'left',

      renderCell: params => {
        return (
          <Button
            color={'black'}
            marginBlock={'zero'}
            //border={"1px solid transparent"}
            size='small'
            width={'100%'}
            //border={"transparent"}
            justifyContent={'left'}
            onClick={() => ExistingAdsetlist(params.row.id)}
            border={'none'}
            boxShadow={'none'}
          >
            {params.value}
          </Button>
        )
      }
    }
  ]

  //Choose from Existing Add Click Event
  const ExistingAdsetlist = async e => {
    setSelectedAdsetId(e)
    localStorage.setItem('Existing_Adset_Id', e)
  }
  ///searching existing add name
  const handleChanges = value => {
   
    var filteredItems = staticlist.filter((item, i) => {
      if (value == '') {
        return item
      } else {
        return item.adset_name.toLowerCase().includes(value.toLowerCase())
      }
    })

  
    setlistad(filteredItems)
  }

  //fetch listaddsets
  const listAdSetss = async () => {
    let filter = {
      campaign_id: { eq: CAMPID_FRM_DB }
    }
    try {
      const listads = await API.graphql({
        query: listAdSets,
        variables: { filter: filter }
      })
     
      setlistad(listads.data.listAdSets.items)
      setstaticlistadd(listads.data.listAdSets.items)
    } catch (err) {
      console.log('error fetching actors' + err)
    }
  }
  //Fetching the specific data from the listAdsets model
  const getlistAdSetsUsingId = async () => {
    //alert(SelectedAdsetId+" : adsetid")
    try {
      setUpdatecheck(true)
      let filter = {
        id: {
          eq: SelectedAdsetId // filter priority = 1
        }
      }
      const addlist = await API.graphql({
        query: listAdSets,
        variables: { filter: filter }
      })

      setExistingAdset_Id(addlist.data.listAdSets.items[0].adset_id)
      setExistingAdset_version(addlist.data.listAdSets.items[0]._version)
    

      JSON.parse(addlist.data.listAdSets.items[0].placements[0]).map(x => {
        if (x == 'facebook') {
          setfbcheck('facebook')
          temp.push('facebook')
          setchecked1(true)
        }
        if (x == 'instagram') {
          setinstacheck('instagram')
          temp.push('instagram')
          setchecked2(true)
        }
      })

      if (
        addlist.data.listAdSets.items[0].age_from == 0 &&
        addlist.data.listAdSets.items[0].age_to == 0 &&
        addlist.data.listAdSets.items[0].gender == ''
      ) {
        setSpecialAdcategoryselected(false)
      } else {
        setSpecialAdcategoryselected(true)
      }
      if (addlist.data.listAdSets.items[0].conversion_events != '') {
        setchkstate(true)
      } else {
        setchkstate(false)
      }
      //setAdsetformState(addlist.data.listAdSets.items[0]);
      let EXistingBind = { ...addlist.data.listAdSets.items[0] }
     
      EXistingBind.custom_audience = JSON.parse(
        addlist.data.listAdSets.items[0].custom_audience[0]
      )
      // EXistingBind.demographics = JSON.parse(
      //   addlist.data.listAdSets.items[0].demographics[0]
      // );
      // EXistingBind.behaviors = JSON.parse(
      //   addlist.data.listAdSets.items[0].behaviors[0]
      // );
      const interestsString =
        addlist?.data?.listAdSets?.items?.[0]?.interests?.[0] ?? '[]'

      // Parse the interests string and assign to state
      const parsedInterests = JSON.parse(interestsString) ?? []
      EXistingBind.interests = parsedInterests
      EXistingBind.exclude_custom_audience = JSON.parse(
        addlist.data.listAdSets.items[0].exclude_custom_audience[0]
      )
      EXistingBind.gender = addlist.data.listAdSets.items[0].gender[0]

      //dropdown on select
      setSelectedOptionscustomaudience(
        JSON.parse(addlist.data.listAdSets.items[0].custom_audience[0])
      )

      //     setselectdemographics(JSON.parse(
      //   addlist.data.listAdSets.items[0].demographics[0]
      // ));

      //   setselectbehaviour(JSON.parse(
      //   addlist.data.listAdSets.items[0].custom_audience[0]
      // ));
      //       const interestsString = addlist?.data?.listAdSets?.items?.[0]?.interests?.[0] ?? '[]';

      // // Parse the interests string and assign to state
      //      const parsedInterests = JSON.parse(interestsString) ?? [];
      setselectintrests(parsedInterests)

      // setselectintrests(JSON.parse(
      //   addlist.data.listAdSets.items[0].interests[0])==undefined?[]: addlist.data.listAdSets.items[0].interests[0]);

      setselectexcludeaudience(
        JSON.parse(addlist.data.listAdSets.items[0].exclude_custom_audience[0])
      )

      try {
        EXistingBind.location = JSON.parse(
          JSON.parse(addlist.data.listAdSets.items[0].location[0])
        )
        setselectlocation(
          JSON.parse(JSON.parse(addlist.data.listAdSets.items[0].location[0]))
        )
      } catch {
        EXistingBind.location = JSON.parse(
          addlist.data.listAdSets.items[0].location[0]
        )
        setselectlocation(
          JSON.parse(addlist.data.listAdSets.items[0].location[0])
        )
      }
      setAdsetformState(EXistingBind)
      
    } catch (err) {
      // alert("err",err)
      console.log('error fetching actors' + err)
    }
  }
  //----------------------------------------------------------------------------createadsetstatic------------------------------------------------------------------------------
  //creating Ad Set
  // const CreateAdset = () => {
  //   axios({
  //     method: "post",
  //     url:
  //       "https://graph.facebook.com//v15.0/" +
  //       adsaccount +
  //       "?name=My Reach Ad Set&optimization_goal=REACH&billing_event=IMPRESSIONS&bid_amount=2&daily_budget=1000&campaign_id=6081673038945&targeting=%7B%22geo_locations%22%3A%7B%22countries%22%3A%5B%22US%22%5D%7D%2C%22facebook_positions%22%3A%5B%22feed%22%5D%7D&status=PAUSED&promoted_object=%7B%22page_id%22%3A%22%3CPAGE_ID%3E%22%7D&access_token=" +
  //       accessToken,
  //     responseType: "stream",
  //   }).then(function (AdsetResponse) {
  //     console.log(AdsetResponse, "AdsetResponse");
  //   });
  // };
  //--------------------------------------------------------------------------------#######------------------------------------------------------------------------------------

  //getcustom audience
  const GetCustomAudience = () => {
    // alert("yeah");
    axios({
      method: 'get',
      url:
        'https://graph.facebook.com/v15.0/' +
        adsAccountId +
        '/customaudiences?fields=id,name&access_token=' +
        accessToken,
      responseType: 'stream'
    })
      .then(function (ListOfCustomAudienceResponse) {
        let customAudienceList = ListOfCustomAudienceResponse.data.data
        setlistofcustomAudience(customAudienceList)
        
      })
      .catch(function (error) {
        console.log(error, 'error')
      })
  }

  //Fetching Location Dropdown data
  const getLocation = () => {
    axios({
      method: 'get',
      //  url: 'https://graph.facebook.com/v15.0/'+adsaccount+'/campaigns?access_token='+accessToken,
      url:
        'https://graph.facebook.com/v15.0/search?access_token=' +
        accessToken +
        '&type=adgeolocation&location_types=%5B%22country%22%5D&q&limit=400',
      responseType: 'stream'
    })
      .then(function (responsef) {
        let locMap = JSON.parse(JSON.stringify(responsef.data.data))
        //    console.log("Loc", JSON.stringify(responsef.data.data));
        setListOfLocation(locMap)
      })
      .catch(function (error) {
        console.log('inside error' + error.response.data)
      })
  }

  //fetching demographics dropdown data
  const getDemographicsList = () => {
    axios({
      method: 'get',
      //  url: 'https://graph.facebook.com/v15.0/'+adsaccount+'/campaigns?access_token='+accessToken,
      url:
        'https://graph.facebook.com/v15.0/search?access_token=' +
        accessToken +
        '&type=adTargetingCategory&class=demographics&limit=400',
      responseType: 'stream'
    })
      .then(function (responsef) {
        setListOfDemographics(responsef.data.data)
      })
      .catch(function (error) {
        console.log('inside error' + error.response.data)
      })
  }

  //fetching BehaviuorsList dropdown data
  const getBehaviorsList = () => {
    axios({
      method: 'get',
      //  url: 'https://graph.facebook.com/v15.0/'+adsaccount+'/campaigns?access_token='+accessToken,
      url:
        'https://graph.facebook.com/v15.0/search?access_token=' +
        accessToken +
        '&type=adTargetingCategory&class=behaviors&limit=400',
      responseType: 'stream'
    })
      .then(function (responsef) {
        setListOfBehaviors(JSON.parse(JSON.stringify(responsef.data.data)))
      })
      .catch(function (error) {
        console.log('inside error' + error)
      })
  }

  //fetching InterestsList dropdown data
  const getInterestList = searchParameter => {
    axios({
      method: 'get',
      //  url: 'https://graph.facebook.com/v15.0/'+adsaccount+'/campaigns?access_token='+accessToken,
      url:
        'https://graph.facebook.com/v15.0/search?access_token=' +
        accessToken +
        '&type=adinterest&q=' +
        searchParameter +
        '&limit=400',
      responseType: 'stream'
    })
      .then(function (responsef) {
        setListOfInterests(JSON.parse(JSON.stringify(responsef.data.data)))
      })
      .catch(function (error) {
        console.log('inside error' + error)
      })
  }

  //Individual existing campaign click event
  const handleShow = () => {
    // alert("getting campaign");
    // fun();
    //getCampaign();
  }

  //check box click event
  const fbcheckboxvalue = e => {
    if (e.target.checked) {
      setfbcheck('facebook')
      setchecked1(true)
    } else {
      setfbcheck('')
      setchecked1(false)
    }
  }

  //check box click event
  const instacheckboxvalue = e => {
    if (e.target.checked) {
      setinstacheck('instagram')
      setchecked2(true)
    } else {
      setinstacheck('')
      setchecked2(false)
    }
  }

  const handleClosevalue = () => setShowvalue(false)
  const handleShowvalue = () => setShowvalue(true)

  const { tokens } = useTheme()
  const IconArrowDropDown = () => {
    return <Icon pathData='M7 10L12 15L17 10H7Z' ariaLabel='Down arrow' />
  }
  const [show, setShow] = useState(false)
  const [showvalue, setShowvalue] = useState(false)
  const [adsetdata, setadsetData] = useState()
  const handleClose = () => setShow(false)
  const [showadset, setShowadset] = useState(false)
  const [HideResetButton, setHideResetButton] = useState(false)
  // existing Adset close
  const handleCloseadset = () => {
    setShowadset(false)
  }
  const SaveAudience = () => {
    getlistAdSetsUsingId()
    setHideResetButton(true)
    handleCloseadset()
  }
  const handleShowadset = () => {
    setShowadset(true)
  }

  const handleChange = (key, value) => {
    if (key == 'budget_type') {
      if (value.length == '') {
        setPasswordError(true)
      } else {
        setPasswordError(false)
      }
    }
    //  if (value =="lifetime_budget")
    //  {
    //    setview(true)
    //  }
    //  else{
    //    setview(false)
    //  }
    setInput(key, value)
  }

  return (
    <>
      <ToastContainer autoClose={1500} limit={0} />
      <Flex
        direction={{
          base: 'column',
          small: 'column',
          medium: 'column',
          large: 'column',
          xl: 'column',
          xxl: 'column'
        }}
        gap='1rem'
        style={{ border: '2px solid #E8E8E8', borderRadius: '0.5em' }}
      >
        <Flex
          direction={{
            base: 'column',
            small: 'column',
            medium: 'row',
            large: 'row',
            xl: 'row',
            xxl: 'row'
          }}
        >
          <View padding='2em' alignItems='center'>
            <TextField
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '500'
              }}
              onChange={event => validation('adset_name', event.target.value)}
              //defaultValue={AdsetformState.adset_name}
              value={AdsetformState.adset_name}
              label='Ad Set Name'
              hasError={nameError}
              errorMessage={nameError}
              disabled={DisableAfterPublish}
            />
            {!HideExistingAdSet ? (
              <Text
                fontWeight='500'
                textAlign='right'
                style={{ cursor: 'pointer' }}
                color='#b71f39'
                width={'100%'}
                marginTop='0.5em'
                onClick={handleShowadset}
              >
                Choose from Existing Ad Set
              </Text>
            ) : (
              <Text marginTop='1em'></Text>
            )}
            <Grid templateColumns='2fr 2fr' gap={tokens.space.xl}>
              <SelectField
                label='Budget'
                icon={<IconArrowDropDown />}
                iconColor='black'
                placeholder='Select Budget'
                onChange={e => handleChange('budget_type', e.target.value)}
                selectedValues={AdsetformState.budget_type}
                value={AdsetformState.budget_type}
                hasError={passwordError}
                disabled={DisableAfterPublish}
              >
                <option selected disabled hidden></option>
                {adsetBudget !== undefined
                  ? adsetBudget.map((row, key) => (
                      <option key={key} value={row?.value}>
                        {row?.name}
                      </option>
                    ))
                  : ''}
              </SelectField>
              <TextField
                marginTop='1.5em'
                innerStartComponent={
                  <FieldGroupIcon ariaLabel='' color='black' marginTop='0.7em'>
                    $
                  </FieldGroupIcon>
                }
                onChange={event =>
                  validation(
                    'budget_amount',
                    event.target.value.replace(/[aA-zZ]|[^\w\s]/gi, '')
                  )
                }
                disabled={DisableAfterPublish}
                value={AdsetformState.budget_amount}
                hasError={usdsError}
                //errorMessage={usdError}
                // value={AdsetformState.budget_amount}
                // hasError={usds}
                // errorMessage={usds}
                innerEndComponent={
                  <FieldGroupIcon
                    ariaLabel='Search'
                    color='black'
                    border='none'
                    fontWeight='200'
                    marginTop='0.7em'
                    disabled={DisableAfterPublish}
                  >
                    USD
                  </FieldGroupIcon>
                }
              />
            </Grid>
            <Text
              style={{ fontWeight: '450', marginTop: '1em' }}
              fontSize='1.1em'
            >
              Schedule
            </Text>
            <Grid
              templateColumns='2fr 2fr'
              templateRows='4rem'
              gap={tokens.space.large}
            >
              <View
                style={{ marginTop: '0.5em' }}
                width={{ base: '7em', small: '', medium: '15em' }}
              >
                <Form.Group controlId='dob'>
                  <Form.Label style={{ color: '#000' }}>
                    Start Date
                    <Text as='span' fontSize='0.8rem' color='red'>
                      {' '}
                      *
                    </Text>
                  </Form.Label>
                  <Form.Control
                    type='date'
                    name='dob'
                    placeholder='Date of Birth'
                    onChange={event =>
                      validation('schedule_start_date', event.target.value)
                    }
                    value={AdsetformState.schedule_start_date}
                    disabled={DisableAfterPublish}
                  />

                  {validatedate && (
                    <Text color='#d85151' fontSize={'0.8em'}>
                      Select date
                    </Text>
                  )}
                </Form.Group>
              </View>
              <View
                style={{ marginTop: '0.5em' }}
                width={{ base: '7em', small: '', medium: '15em' }}
              >
                <Form.Group controlId='dob'>
                  <Form.Label style={{ color: '#000' }}>Start Time</Form.Label>
                  <Form.Control
                    type='time'
                    name='dob'
                    placeholder='Date of Birth'
                    onChange={event =>
                      validation('schedule_start_time', event.target.value)
                    }
                    value={AdsetformState.schedule_start_time}
                    disabled={DisableAfterPublish}
                  />
                  {validatestartdate && (
                    <Text color='#d85151' fontSize={'0.8em'}>
                      Select Time
                    </Text>
                  )}
                </Form.Group>
              </View>
              <View
                style={{ marginTop: '0.7em' }}
                width={{ base: '7em', small: '', medium: '15em' }}
              >
                <Form.Group controlId='dob'>
                  <Form.Label style={{ color: '#000' }}>
                    End Date
                    <Text as='span' fontSize='0.8rem' color='red'>
                      {' '}
                      *
                    </Text>
                  </Form.Label>
                  <Form.Control
                    type='date'
                    name='dob'
                    placeholder='Date of Birth'
                    onChange={event =>
                      validation('schedule_end_date', event.target.value)
                    }
                    value={AdsetformState.schedule_end_date}
                    disabled={DisableAfterPublish}
                    // disabled={AdsetformState.schedule_start_date=== "" ? true: false}
                    min={
                      AdsetformState.schedule_start_date
                        ? new Date(AdsetformState.schedule_start_date)
                            .toISOString()
                            .split('T')[0]
                        : ''
                    }
                  />
                  {validateenddate && (
                    <Text color='#d85151' fontSize={'0.8em'}>
                      Select date
                    </Text>
                  )}
                </Form.Group>
              </View>
              <View
                style={{ marginTop: '0.7em' }}
                width={{ base: '7em', small: '', medium: '15em' }}
              >
                <Form.Group controlId='dob'>
                  <Form.Label style={{ color: '#000' }}>End Time</Form.Label>
                  <Form.Control
                    type='time'
                    name='dob'
                    placeholder='Date of Birth'
                    onChange={event =>
                      validation('schedule_end_time', event.target.value)
                    }
                    value={AdsetformState.schedule_end_time}
                    disabled={DisableAfterPublish}
                  />

                  {validateenddtime && (
                    <Text color='#d85151' fontSize={'0.8em'}>
                      Select Time
                    </Text>
                  )}
                </Form.Group>
              </View>
            </Grid>
            {/* {showConversionLocationdd && (
                <SelectField
                  label="Conversion Location"
                  icon={<IconArrowDropDown />}
                  iconColor="black"
                  placeholder="Select"
                  defaultValue={CformState.conversion_location}
                  marginTop="1em"
                >
                  <option selected disabled hidden></option>
                  {subCL !== undefined
                    ? subCL.map((row, key) => (
                        <option key={key} value={row?.name}>
                          {row?.name}
                        </option>
                      ))
                    : ""}
                </SelectField>
              )} */}

            {
              //  <CheckboxField
              //     label="Use Tracking Pixel(Meta pixel)"
              //     // name="subscribe"
              //     value="yes"
              //     marginTop="1.1em"
              //     onChange={(e) => {
              //       e.target.checked ? setchkstate(true) : setchkstate(false);
              //     }}
              //     checked={chkstate}
              //   />
              //   {chkstate ? (
              //     <SelectField
              //       label="Conversion Events"
              //       icon={<IconArrowDropDown />}
              //       iconColor="black"
              //       placeholder="Select"
              //       onChange={(event) =>
              //         setInput("conversion_events", event.target.value)
              //       }
              //       value={AdsetformState.conversion_events}
              //       marginTop="1em"
              //     >
              //       <option selected disabled hidden></option>
              //       {adsetConversionEvent !== undefined &&
              //       adsetConversionEvent !== null
              //         ? adsetConversionEvent.map((row, key) => (
              //             <option key={key} value={row?.name}>
              //               {row?.name}
              //             </option>
              //           ))
              //         : ""}
              //     </SelectField>
              //   ) : (
              //     ""
              //   )}
            }

            <Text
              style={{
                fontWeight: '300',
                marginTop: '1em',
                marginBottom: '0.5em'
              }}
            >
              Placement
              <Text as='span' fontSize='0.8rem' color='red'>
                {' '}
                *
              </Text>
            </Text>
            <Flex>
              <CheckboxField
                label='Facebook'
                // name="subscribe"
                value={fbcheck}
                hasError={DOB}
                onChange={e => {
                  e.target.checked ? fbcheckboxvalue(e) : fbcheckboxvalue(e)
                }}
                checked={checked1}
                disabled={DisableAfterPublish}
              />
              <CheckboxField
                label='Instagram'
                //  name="subscribe"
                value={instacheck}
                hasError={DOB}
                // errorMessage={DOBB}
                onChange={e => {
                  e.target.checked
                    ? instacheckboxvalue(e)
                    : instacheckboxvalue(e)
                }}
                checked={checked2}
                disabled={DisableAfterPublish}
              />
            </Flex>
            {/* <Multiselect
              style={{ color: "black" }}
              ref={placementRef}
              options={
                adsetplacement !== undefined && adsetplacement != null
                  ? adsetplacement
                  : []
              } // Options to display in the dropdown
              selectedValues={
                adsetplacement !== undefined && adsetplacement != null
                  ? adsetplacement
                  : []
              } // Preselected value to persist in dropdown
              // onChange={(event) => setInput("placements", event.target.value)}
              // defaultValue={AdsetformState.placements}
              displayValue="value"
              placeholder=""
              showCheckbox={false}
              closeOnSelect={true}
              avoidHighlightFirstOption={true}
              showArrow={true}
            /> */}
          </View>
          <Divider orientation='vertical' size='small' />
          <View
            padding={{ base: '2em', large: '2em' }}
            paddingTop={{ base: '0em', large: '2em' }}
            width='100%'
          >
            <Flex
              direction={{ base: 'row', large: 'row' }}
              marginTop={{
                base: '-2em',
                medium: '0em',
                large: '0em'
              }}
              gap={{ base: 'small', large: 'small' }}
            >
              {/* <Button
                  variation="danger"
                  backgroundColor="#df2121"
                  color="white"
                  border="none"
                  boxShadow="large"
                  borderRadius={"5px"}
                  size={{ base: "small", large: "large" }}
                >
                  Reset
                </Button> */}
              {/* <Button
                backgroundColor="#8EC641"
                style={primaryButton}
                boxShadow="large"
                borderRadius={"2em"}
                size={{ base: "small", large: "small" }}
                //onClick={handleShowvalue}
               disabled={true}
                fontSize={{ base: "0.8em", medium: "0.8em", large: "1em" }}
              >
                Save{" "}
                <Icon
                  as={BiSave}
                  fontSize={{ large: "large" }}
                  color="white"
                  fontWeight={"bold"}
                  marginLeft="0.3em"
                ></Icon>
              </Button>
              <Button
                backgroundColor="rgb(246 113 113 / 92%)"
               // onClick={handleShow}
               disabled={true}
                style={primaryButton}
                boxShadow="large"
                borderRadius={"2em"}
                size={{ base: "small", large: "small" }}
                fontSize={{ base: "0.8em", medium: "0.8em", large: "1em" }}
              >
                Existing Audience{" "}
                <Icon
                  as={BiExit}
                  fontSize={{ large: "large" }}
                  color="#fff"
                  fontWeight={"bold"}
                  marginLeft="0.3em"
                ></Icon>
              </Button> */}
            </Flex>
            <Text
              style={{
                fontWeight: '400',
                marginTop: '10px',
                fontSize: '2em'
              }}
            >
              Audience
            </Text>
            <Flex
              direction={{ base: 'column', large: 'row' }}
              gap={{ base: 'small', large: 'xl' }}
            >
              <View width='100%'>
                <Flex
                  direction={{ base: 'column', large: 'column' }}
                  gap={{ base: 'small', large: 'xs' }}
                >
                  <Text
                    style={{
                      fontWeight: '400',
                      marginTop: '1em'
                    }}
                  >
                    Custom Audience
                  </Text>
                  <Multiselect
                    style={{ color: 'black' }}
                    options={
                      listofcustomAudience !== undefined &&
                      listofcustomAudience !== null
                        ? listofcustomAudience
                        : []
                    }
                    onSelect={setSelectedOptionscustomaudience}
                    //selectedValues={selectedOptionscustomaudience}
                    onRemove={setSelectedOptionscustomaudience}
                    selectedValues={selectedOptionscustomaudience}
                    displayValue='name'
                    ref={customAudienceRef}
                    // onChange={(event) =>
                    //   setInput("custom_audience", event.target.value)
                    // }
                    // defaultValue={AdsetformState.custom_audience}
                    showCheckbox={false}
                    closeOnSelect={true}
                    avoidHighlightFirstOption={true}
                    showArrow={true}
                    // selectedValues={AdsetformState.custom_audience}
                    singleSelect={false}
                    placeholder='Select'
                    hidePlaceholder={true}
                    selectionLimit='-1'
                    emptyRecordMsg='No options available'
                    disablePreSelectedValues={false}
                    disable={DisableAfterPublish}
                  />
                </Flex>
              </View>
              <View width='100%'>
                <Flex
                  direction={{ base: 'column', large: 'column' }}
                  gap={{ base: 'small', large: 'xs' }}
                >
                  <Text
                    style={{
                      fontWeight: '400',
                      marginTop: '1em'
                    }}
                  >
                    Exclude Custom Audience
                  </Text>
                  <Multiselect
                    style={{ color: 'black' }}
                    // selectedValues={AdsetformState.exclude_custom_audience}
                    options={
                      listofcustomAudience !== undefined &&
                      listofcustomAudience !== null
                        ? listofcustomAudience
                        : []
                    }
                    displayValue='name'
                    ref={excludeCustomAudienceRef}
                    // onChange={(event) =>
                    //   setInput("exclude_custom_audience", event.target.value)
                    // }
                    // defaultValue={AdsetformState.exclude_custom_audience}
                    onSelect={setselectexcludeaudience}
                    //selectedValues={selectexclude_audience}
                    onRemove={setselectexcludeaudience}
                    selectedValues={selectexclude_audience}
                    showCheckbox={false}
                    closeOnSelect={true}
                    avoidHighlightFirstOption={true}
                    showArrow={true}
                    singleSelect={false}
                    placeholder='Select'
                    hidePlaceholder={true}
                    selectionLimit='-1'
                    emptyRecordMsg='No options available'
                    disablePreSelectedValues={false}
                    disable={DisableAfterPublish}
                  />
                </Flex>
              </View>
            </Flex>
            {SpecialAdcategoryselected ? (
              <Flex
                direction={{ base: 'column', medium: 'column', large: 'row' }}
                gap={{ base: 'small', large: 'xl' }}
                marginTop={{ base: 'small', large: 'small' }}
              >
                <View width='100%'>
                  <Flex
                    direction={{ base: 'row', large: 'row' }}
                    gap={{ base: 'small', large: 'xs' }}
                  >
                    <SelectField
                      // label="Age Range *"

                      label={
                        <Text style={{ fontWeight: '400', marginTop: '0em' }}>
                          Age Range
                          <Text as='span' fontSize='0.8rem' color='red'>
                            {' '}
                            *
                          </Text>
                        </Text>
                      }
                      onChange={event => {
                        AgeRestrict(event.target.value)
                        validation('age_from', event.target.value)
                      }}
                      value={AdsetformState.age_from}
                      hasError={Agee}
                      //errorMessage={Age}
                      icon={<IconArrowDropDown />}
                      iconColor='black'
                      marginTop={{
                        base: '10px',
                        medium: '10px',
                        large: '10px'
                      }}
                      width={{ base: '100%', medium: '100%', large: '100%' }}
                      disabled={DisableAfterPublish}
                    >
                      {/* <Text as="span" fontSize="0.8rem" color="red">
                      {" "}
                      *
                    </Text> */}
                      <option selected disabled hidden></option>
                      {startage !== undefined && startage !== null
                        ? startage.map((row, key) => (
                            <option key={key} value={row?.id}>
                              {row?.id}
                            </option>
                          ))
                        : ''}
                    </SelectField>
                    <Text marginTop={'3em'}>
                      <Icon
                        as={BiMinus}
                        fontSize={{ large: 'small' }}
                        color='black'
                      ></Icon>
                    </Text>
                    <SelectField
                      label=''
                      onChange={event => {
                        Ageselect(event.target.value)
                        validation('age_to', event.target.value)
                      }}
                      value={AdsetformState.age_to}
                      hasError={Ageto}
                      //errorMessage={Age}
                      icon={<IconArrowDropDown />}
                      iconColor='black'
                      marginTop={{
                        base: '34px',
                        medium: '34px',
                        large: '34px'
                      }}
                      width={{ base: '100%', medium: '100%', large: '100%' }}
                      disabled={DisableAfterPublish}
                    >
                      <option selected disabled hidden></option>
                      {endage !== undefined && endage !== null
                        ? endage.map((row, key) => (
                            <option key={key} value={row?.id}>
                              {row?.id}
                            </option>
                          ))
                        : ''}
                    </SelectField>
                  </Flex>
                </View>
                <View width='100%'>
                  <Flex
                    direction={{ base: 'column', large: 'column' }}
                    gap={{ base: 'small', large: 'xs' }}
                  >
                    <SelectField
                      label='Gender'
                      //  defaultValue={CformState.gender}
                      placeholder='Select Gender'
                      icon={<IconArrowDropDown />}
                      iconColor='black'
                      width={{ base: '100%', medium: '100%', large: '100%' }}
                      marginTop='10px'
                      onChange={event => setInput('gender', event.target.value)}
                      value={AdsetformState.gender}
                      disabled={DisableAfterPublish}
                    >
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='All'>All</option>
                    </SelectField>
                  </Flex>
                </View>
              </Flex>
            ) : null}
            <Flex
              direction={{ base: 'column', large: 'row' }}
              gap={{ base: 'small', large: 'xl' }}
            >
              {/* <View width="100%">
              {SpecialAdcategorydemobehav ? (<Flex
                  direction={{ base: "column", large: "column" }}
                  gap={{ base: "small", large: "xs" }}
                >
                  <Text
                    style={{
                      fontWeight: "400",
                      marginTop: "1em",
                    }}
                  >
                    Demographics
                  </Text>
                  <Multiselect
                    style={{ color: "black" }}
                    options={
                      listOfDemographics !== undefined &&
                      listOfDemographics !== null
                        ? listOfDemographics
                        : []
                    }
                    ref={demographicsRef}
                    // onChange={(event) =>
                    //   setInput("demographics", event.target.value)
                    // }
                    // defaultValue={AdsetformState.demographics}
                    onSelect={setselectdemographics}
                    selectedValues={selectdemographics}
                    onRemove={setselectdemographics}

                    displayValue="name"
                    showCheckbox={false}
                    closeOnSelect={true}
                    avoidHighlightFirstOption={true}
                    showArrow={true}
                    singleSelect={false}
                    placeholder="Select"
                    //  selectedValues={AdsetformState.demographics}
                    hidePlaceholder={true}
                    selectionLimit="-1"
                    emptyRecordMsg="No options available"
                    disablePreSelectedValues={false}
                    disable={DisableAfterPublish}
                  />
                </Flex>):null}
              </View> */}
              <View width='100%'>
                <Flex
                  direction={{ base: 'column', large: 'column' }}
                  gap={{ base: 'small', large: 'xs' }}
                >
                  <Text
                    style={{
                      fontWeight: '400',
                      marginTop: '1em'
                    }}
                  >
                    Location
                    <Text as='span' fontSize='0.8rem' color='red'>
                      {' '}
                      *
                    </Text>
                  </Text>
                  {validateLocation || selectlocation == '' ? (
                    <Multiselect
                      // selectedValues={AdsetformState.location}
                      value={AdsetformState.location}
                      style={{ color: 'black' }}
                      options={
                        listOfLocation !== undefined && listOfLocation !== null
                          ? listOfLocation
                          : []
                      }
                      // onChange={(event) =>
                      //   setInput("location", event.target.value)
                      // }
                      // defaultValue={AdsetformState.location}
                      onSelect={setselectlocation =>
                        validation('location', setselectlocation)
                      }
                      selectedValues={selectlocation}
                      onRemove={selectlocation =>
                        validation('location', selectlocation)
                      }
                      ref={locationRef}
                      displayValue='name'
                      showCheckbox={false}
                      closeOnSelect={true}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      singleSelect={false}
                      placeholder='Select'
                      hidePlaceholder={true}
                      selectionLimit='-1'
                      emptyRecordMsg='No options available'
                      disablePreSelectedValues={false}
                      disable={DisableAfterPublish}
                    />
                  ) : (
                    <Multiselect
                      // selectedValues={AdsetformState.location}
                      disable={DisableAfterPublish}
                      value={AdsetformState.location}
                      style={{ color: 'black' }}
                      options={
                        listOfLocation !== undefined && listOfLocation !== null
                          ? listOfLocation
                          : []
                      }
                      // onChange={(event) =>
                      //   setInput("location", event.target.value)
                      // }
                      // defaultValue={AdsetformState.location}
                      onSelect={setselectlocation}
                      selectedValues={selectlocation}
                      onRemove={selectlocation =>
                        validation('location', selectlocation)
                      }
                      ref={locationRef}
                      displayValue='name'
                      showCheckbox={false}
                      closeOnSelect={true}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      singleSelect={false}
                      placeholder='Select'
                      hidePlaceholder={true}
                      selectionLimit='-1'
                      emptyRecordMsg='No options available'
                      disablePreSelectedValues={false}

                      // isdisabled={DisableAfterPublish}
                    />
                  )}
                  {validateLocation && (
                    <Text color='#d85151' fontSize={'0.8em'}>
                      Select Location
                    </Text>
                  )}
                </Flex>
              </View>
              <View width='100%'>
                {SpecialAdcategoryselected ? (
                  <Flex
                    direction={{ base: 'column', large: 'column' }}
                    gap={{ base: 'small', large: 'xs' }}
                  >
                    <Text
                      style={{
                        fontWeight: '400',
                        marginTop: '1em'
                      }}
                    >
                      Interests
                    </Text>
                    <Multiselect
                      style={{ color: 'black' }}
                      // selectedValues={AdsetformState.interests}
                      options={
                        listOfInterests !== undefined &&
                        listOfInterests !== null
                          ? listOfInterests
                          : []
                      }
                      onSearch={e => {
                        //  console.log(e, "e");
                        getInterestList(e)
                      }}
                      ref={interestsRef}
                      // onChange={(event) =>
                      //   setInput("interests", event.target.value)
                      // }
                      // defaultValue={AdsetformState.interests}
                      // onSelect={onSelectInterests}
                      onSelect={setselectintrests}
                      selectedValues={selectintrests}
                      onRemove={setselectintrests}
                      displayValue='name'
                      showCheckbox={false}
                      closeOnSelect={true}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      singleSelect={false}
                      placeholder='Search Interests'
                      hidePlaceholder={true}
                      selectionLimit='-1'
                      emptyRecordMsg='No options available'
                      disablePreSelectedValues={false}
                      disable={DisableAfterPublish}
                    />
                  </Flex>
                ) : null}
              </View>
            </Flex>
            <Flex
              direction={{ base: 'column', large: 'row' }}
              gap={{ base: 'small', large: 'xl' }}
              marginTop={{ base: 'small', large: 'xs' }}
            >
              <View width='100%'>
                {SpecialAdcategorydemobehav ? (
                  <Flex
                    direction={{ base: 'column', large: 'column' }}
                    gap={{ base: 'small', large: 'xs' }}
                  >
                    <Text
                      style={{
                        fontWeight: '400',
                        marginTop: '1em'
                      }}
                    >
                      Behaviours
                    </Text>
                    <Multiselect
                      // selectedValues={AdsetformState.behaviors}
                      style={{ color: 'black' }}
                      options={
                        listOfBehaviors !== undefined &&
                        listOfBehaviors !== null
                          ? listOfBehaviors
                          : []
                      }
                      // onChange={(event) =>
                      //   setInput("behaviors", event.target.value)
                      // }
                      // defaultValue={AdsetformState.behaviors}
                      onSelect={setselectbehaviour}
                      selectedValues={selectbehaviour}
                      onRemove={setselectbehaviour}
                      ref={behaviorsRef}
                      displayValue='name'
                      showCheckbox={false}
                      closeOnSelect={true}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      singleSelect={false}
                      placeholder='Select'
                      hidePlaceholder={true}
                      selectionLimit='-1'
                      emptyRecordMsg='No options available'
                      disablePreSelectedValues={false}
                      disable={DisableAfterPublish}
                    />
                  </Flex>
                ) : null}
              </View>
              <View width='100%'>
                {SpecialAdcategorydemobehav ? (
                  <Flex
                    direction={{ base: 'column', large: 'column' }}
                    gap={{ base: 'small', large: 'xs' }}
                  >
                    <Text
                      style={{
                        fontWeight: '400',
                        marginTop: '1em'
                      }}
                    >
                      Demographics
                    </Text>
                    <Multiselect
                      style={{ color: 'black' }}
                      options={
                        listOfDemographics !== undefined &&
                        listOfDemographics !== null
                          ? listOfDemographics
                          : []
                      }
                      ref={demographicsRef}
                      // onChange={(event) =>
                      //   setInput("demographics", event.target.value)
                      // }
                      // defaultValue={AdsetformState.demographics}
                      onSelect={setselectdemographics}
                      selectedValues={selectdemographics}
                      onRemove={setselectdemographics}
                      displayValue='name'
                      showCheckbox={false}
                      closeOnSelect={true}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      singleSelect={false}
                      placeholder='Select'
                      //  selectedValues={AdsetformState.demographics}

                      hidePlaceholder={true}
                      selectionLimit='-1'
                      emptyRecordMsg='No options available'
                      disablePreSelectedValues={false}
                      disable={DisableAfterPublish}
                    />
                  </Flex>
                ) : null}
              </View>
            </Flex>
            <Flex
              direction={{ base: 'column', large: 'row' }}
              gap={{ base: 'small', large: 'xl' }}
              marginTop={{ base: 'small', large: 'xs' }}
            >
              <View width={{ base: '100%', medium: '100%', large: '100%' }}>
                {/* <Text
                    style={{
                      fontWeight: "400",
                      marginTop: "1em",
                      marginBottom: "0.5em",
                    }}
                  >
                    Language
                  </Text>
                  <Multiselect
                    style={{ color: "black" }}
                    options={state.options}
                    displayValue="name"
                    // onChange={(event) =>
                    //   setInput("language", event.target.value)
                    // }
                    // defaultValue={AdsetformState.language}
                    ref={languageRef}
                    showCheckbox={false}
                    closeOnSelect={true}
                    avoidHighlightFirstOption={true}
                    showArrow={true}
                    singleSelect={false}
                    placeholder="Select"
                    hidePlaceholder={true}
                    selectionLimit="-1"
                    emptyRecordMsg="No options available"
                    disablePreSelectedValues={false}
                  /> */}
              </View>
            </Flex>
            <Flex direction='row' marginTop={{ base: 'xl', large: 'xxl' }}>
              {HideResetButton || HideExistingAdSet ? null : (
                <Button
                  backgroundColor='#df2121'
                  style={primaryButton}
                  boxShadow='large'
                  borderRadius='2em'
                  onClick={Resetbutton}
                  disabled={DisableAfterPublish}
                >
                  Reset
                  <Icon
                    as={BiReset}
                    fontSize={{ large: 'large' }}
                    fontWeight={'bold'}
                    marginLeft='0.3em'
                  ></Icon>
                </Button>
              )}
              <Button
                backgroundColor='#202A44'
                style={primaryButton}
                boxShadow='large'
                isLoading={Progressbar}
                loadingText='processing...'
                onClick={bridgeFunction}
                borderRadius='2em'
                disabled={DisableAfterPublish}
              >
                Next{' '}
                <Icon
                  as={HiChevronDoubleRight}
                  fontSize={{ large: 'xl' }}
                  color='white'
                  fontWeight={'bold'}
                  marginLeft='0.3em'
                  marginTop={'0.2em'}
                ></Icon>
              </Button>
            </Flex>
          </View>
        </Flex>
      </Flex>

      <Modal
        show={showvalue}
        onHide={handleClosevalue}
        aria-labelledby='contained-modal-title-vcenter'
      >
        <Modal.Header style={{ border: 'none', marginLeft: '1.5em' }}>
          <Modal.Title>Save Audience</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ border: 'none', margin: 'auto' }}>
          <Container>
            <Row style={{ fontWeight: '500', fontSize: '1.1em' }}>
              <Col xs={4} md={4} lg={4}>
                <Text color='rgb(169 169 169)'>Age</Text>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Text color='rgb(169 169 169)'>:</Text>
              </Col>
              <Col xs={7} md={7} lg={7}>
                <TextField
                  label='Search'
                  labelHidden={true}
                  value='Seaervh'
                  border='none'
                  marginTop='-0.5em'
                />
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: '500',
                fontSize: '1.1em',
                marginTop: '1em'
              }}
            >
              <Col xs={4} md={4} lg={4}>
                <Text color='rgb(169 169 169)'>Gender</Text>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Text color='rgb(169 169 169)'>:</Text>
              </Col>
              <Col xs={7} md={7} lg={7}>
                <TextField
                  label='Search'
                  labelHidden={true}
                  value='Male'
                  border='none'
                  marginTop='-0.5em'
                />
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: '500',
                fontSize: '1.1em',
                marginTop: '1em'
              }}
            >
              <Col xs={4} md={4} lg={4}>
                <Text color='rgb(169 169 169)'>Location</Text>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Text color='rgb(169 169 169)'>:</Text>
              </Col>
              <Col xs={7} md={7} lg={7}>
                <TextField
                  label='Search'
                  labelHidden={true}
                  value='Seaervh'
                  border='none'
                  marginTop='-0.5em'
                />
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: '500',
                fontSize: '1.1em',
                marginTop: '1em'
              }}
            >
              <Col xs={4} md={4} lg={4}>
                <Text color='rgb(169 169 169)'>Interests</Text>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Text color='rgb(169 169 169)'>:</Text>
              </Col>
              <Col xs={7} md={7} lg={7}>
                <TextField
                  label='Search'
                  labelHidden={true}
                  value='Seaervh'
                  border='none'
                  marginTop='-0.5em'
                />
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: '500',
                fontSize: '1.1em',
                marginTop: '1em'
              }}
            >
              <Col xs={4} md={4} lg={4}>
                <Text color='rgb(169 169 169)'>Behaviours</Text>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Text color='rgb(169 169 169)'>:</Text>
              </Col>
              <Col xs={7} md={7} lg={7}>
                <TextField
                  label='Search'
                  labelHidden={true}
                  value='Seaervh'
                  border='none'
                  marginTop='-0.5em'
                />
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: '500',
                fontSize: '1.1em',
                marginTop: '1em'
              }}
            >
              <Col xs={4} md={4} lg={4}>
                <Text color='rgb(169 169 169)'>Location</Text>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Text color='rgb(169 169 169)'>:</Text>
              </Col>
              <Col xs={7} md={7} lg={7}>
                <TextField
                  label='Search'
                  labelHidden={true}
                  value='Seaervh'
                  border='none'
                  marginTop='-0.5em'
                />
              </Col>
            </Row>
            <Row
              style={{
                fontWeight: '500',
                fontSize: '1.1em',
                marginTop: '1em'
              }}
            >
              <Text>Name of Audience</Text>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6} className='mt-3'>
                <TextField label='Search' labelHidden={true} />
              </Col>
              <Col
                xs={6}
                md={6}
                lg={3}
                className='mt-3'
                style={{ textAlign: 'right' }}
              >
                <Button
                  backgroundColor={'grey'}
                  style={primaryButton}
                  boxShadow='large'
                  onClick={handleClosevalue}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={6} md={6} lg={3} className='mt-3'>
                <Button style={primaryButton} boxShadow='large'>
                  Save
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal size='md' show={showadset} onHide={handleCloseadset} border='none'>
        <Modal.Header
          style={{
            border: 'none',
            padding: '2em',
            paddingBottom: '0em',
            display: 'flow-root'
          }}
        >
          <Flex direction={'row'} gap='small' justifyContent='space-between'>
            <View width='55%'>
              <Text style={{ fontSize: '1.2em', fontWeight: '600' }}>
                Existing Ad Name
              </Text>
            </View>
            <View width='45%'>
              <SearchField
                label='search'
                placeholder='Search...'
                size='small'
                onChange={e => handleChanges(e.target.value)}
              />
            </View>
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ padding: '2em', paddingTop: '1em' }}>
          <View style={{ height: 180, width: '100%' }}>
            <DataGrid
              width='10%'
              headerHeight={40}
              rowHeight={40}
              sx={datagridSx}
              rows={listad}
              columns={columnsforadd}
              hideFooter
              pageSize={100}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
            />
          </View>
        </Modal.Body>
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
          </Button> */}
        <Flex
          direction='column'
          alignItems='center'
          style={{ marginTop: '1em' }}
          marginBottom={{ large: 'relative.large' }}
        >
          <Button
            style={primaryButton}
            boxShadow='large'
            onClick={() => SaveAudience()}
          >
            Save
            <Icon
              as={BsHandIndexThumb}
              fontSize={{ large: 'xl' }}
              color='white'
              fontWeight={'bold'}
              marginLeft='0.3em'
              marginTop={'0.3em'}
            ></Icon>
          </Button>
        </Flex>
      </Modal>
    </>
  )
}
export default Adset
