// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { PostHistory, AdHistory, RolesModules, AdStatus, PostDetails, AdDetails, AdSet, AdCampaign, ConfigItems, ContentLibrary, FBLoginInfo, UserInfo, CompanyInfo } = initSchema(schema);

export {
  PostHistory,
  AdHistory,
  RolesModules,
  AdStatus,
  PostDetails,
  AdDetails,
  AdSet,
  AdCampaign,
  ConfigItems,
  ContentLibrary,
  FBLoginInfo,
  UserInfo,
  CompanyInfo
};