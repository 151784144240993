import React, { useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import MoreIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import { dashboardLinkButton } from '../assets/style'
import { Image } from '@aws-amplify/ui-react'
import './Dashboarddesign.css'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useTheme, Text, Icon } from '@aws-amplify/ui-react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import Person3Icon from '@mui/icons-material/Person3'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined'
import { BiPaperPlane } from 'react-icons/bi'
const pages = ['Products', 'Pricing', 'Blog']

const DashboardHeader = ({ db, cl, mc, mp, ndb }) => {
  const [dboutline, setDBOutline] = useState(db)
  const [cloutline, setCLOutline] = useState(cl)
  const [mcoutline, setMCOutline] = useState(mc)
  const [mpoutline, setMpoutLine] = useState(mp)
  const [newdboutline, setNewdboutLine] = useState(ndb)
  const navigate = useNavigate()
  const navigation = () => {
    localStorage.setItem(
      'companyinfoID',
      localStorage.getItem('backupCompanyInfoid')
    )
    localStorage.setItem('distinct', false)
    navigate('/dashboard', { replace: true })
  }
  const dashboard = () => {
    navigate('/newdashboard', { replace: true })
  }

  const [content_library, setcontent_library] = useState(
    localStorage.getItem('content_library')
  )
  const [create_manage_acccount, setcreate_manage_acccount] = useState(
    localStorage.getItem('create_manage_acccount')
  )
  const [create_manage_users, setcreate_manage_users] = useState(
    localStorage.getItem('create_manage_users')
  )
  const [dashboardd, setdashboard] = useState(localStorage.getItem('dashboard'))
  const [landing_page, setlanding_page] = useState(
    localStorage.getItem('landing_page')
  )

  const [manage_ads, setmanage_ads] = useState(
    localStorage.getItem('manage_ads')
  )
  const [manage_posts, setmanage_posts] = useState(
    localStorage.getItem('manage_posts')
  )

  const [Loading, setLoading] = useState(false)
  const [checkrole, check_role] = useState(localStorage.getItem('role_id'))
  const [fname, set_fname] = useState(localStorage.getItem('fname'))

  const contentLibNav = () => {
    navigate('/contentlib', { replace: true })
  }
  const manageContNav = () => {
    navigate('/managecontent', { replace: true })
  }
  const managePostNav = () => {
    navigate('/managepost', { replace: true })
  }
  const navigatefun = () => {
    navigate('/account', {
      state: { tabindex: 0, key: 'managesetting' }
    })
  }
  const { tokens } = useTheme()

  const buttonStyle = {
    backgroundColor: 'rgb(244 67 54)',
    border: 'rgb(244 67 54)',
    size: 'small',
    variation: 'primary',
    color: '#fff',
    fontSize: 'small',
    float: 'center'
  }

  const signOut = async () => {
    localStorage.removeItem('role_id')
    localStorage.removeItem('fname')
    localStorage.removeItem('companyinfoID')

    localStorage.removeItem('content_library')
    localStorage.removeItem('create_manage_acccount')
    localStorage.removeItem('create_manage_users')
    localStorage.removeItem('dashboard')
    localStorage.removeItem('landing_page')
    localStorage.removeItem('manage_ads')
    localStorage.removeItem('manage_posts')
    localStorage.clear()
    sessionStorage.clear()
    localStorage.removeItem('manage_social_account')
    setLoading(true)

    Auth.signOut()
      .then(data => {
        window.location.href = '/'
      })
      .catch(err => console.log(err))
  }

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  }))

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }))

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch'
      }
    }
  }))
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const open = Boolean(anchorElNav)
  const handleClick = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElNav(null)
  }
  const ITEM_HEIGHT = 48
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'
  const mobileMenuId = 'primary-search-account-menu-mobile'

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' backgroundColor='#fff' className='appcolor'>
        <Toolbar className='dashboard' sx={{ color: '#b71f39' }}>
          <FacebookOutlinedIcon
            onClick={() => {
              navigation()
            }}
            sx={{ color: '#b71f39', fontSize: '3rem', cursor: 'pointer' }}
          ></FacebookOutlinedIcon>
          <Box sx={{ flexGrow: 1, color: '#b71f39' }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {dashboardd == 'true' && newdboutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  dashboard()
                }}
                className='btngrpactive'
                style={{ fontSize: '0.7em' }}
              >
                <SpeedOutlinedIcon style={{ marginRight: '0.3em' }} /> Dashboard
              </Button>
            )}
            {dashboardd == 'true' && !newdboutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  dashboard()
                }}
                className='btngrphover'
                style={{ fontSize: '0.7em' }}
              >
                <SpeedOutlinedIcon style={{ marginRight: '0.3em' }} /> Dashboard
              </Button>
            )}

            {content_library == 'true' && cloutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  contentLibNav()
                }}
                className='btngrpactive'
                style={{ fontSize: '0.7em' }}
              >
                <PermMediaOutlinedIcon style={{ marginRight: '0.3em' }} />{' '}
                Content Library
              </Button>
            )}
            {content_library == 'true' && !cloutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  contentLibNav()
                }}
                className='btngrphover'
                style={{ fontSize: '0.7em' }}
              >
                <PermMediaOutlinedIcon style={{ marginRight: '0.3em' }} />{' '}
                Content Library
              </Button>
            )}
            {manage_ads == 'true' && mcoutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  manageContNav()
                }}
                className='btngrpactive'
                style={{ fontSize: '0.7em' }}
              >
                {' '}
                <CampaignOutlinedIcon
                  style={{ marginRight: '0.3em', fontSize: '2.6em' }}
                />{' '}
                Manage Ads
              </Button>
            )}
            {manage_ads == 'true' && !mcoutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  manageContNav()
                }}
                className='btngrphover'
                style={{ fontSize: '0.7em' }}
              >
                {' '}
                <CampaignOutlinedIcon
                  style={{ marginRight: '0.3em', fontSize: '2.6em' }}
                />{' '}
                Manage Ads
              </Button>
            )}

            {manage_posts == 'true' && mpoutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  managePostNav()
                }}
                className='btngrpactive'
                style={{ fontSize: '0.7em' }}
              >
                {' '}
                <Icon
                  as={BiPaperPlane}
                  color={'#b71f39'}
                  fontSize={'2em'}
                  marginRight='0.2em'
                ></Icon>{' '}
                Manage Post
              </Button>
            )}
            {manage_posts == 'true' && !mpoutline && (
              <Button
                sx={{ fontWeight: '600', color: '#b71f39', mr: '0.5em' }}
                onClick={() => {
                  managePostNav()
                }}
                className='btngrphover'
                style={{ fontSize: '0.7em' }}
              >
                {' '}
                <Icon
                  as={BiPaperPlane}
                  color={'#b71f39'}
                  fontSize={'2em'}
                  marginRight='0.2em'
                ></Icon>{' '}
                Manage Post
              </Button>
            )}
            {(create_manage_acccount == 'true' ||
              create_manage_users == 'true') && (
              <IconButton
                size='large'
                edge='end'
                aria-label='account of current user'
                //  aria-controls={menuId}
                id='long-menu'
                aria-haspopup='true'
                color='inherit'
                onClick={handleClick}
              >
                <SettingsOutlinedIcon />
              </IconButton>
            )}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
              <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              size='large'
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <Person3Icon />
              <Text fontSize='14px' margin={'auto'} marginLeft='0.5em'>
                Hi,
                {fname?.replace(
                  /(^\w|\s\w)(\S*)/g,
                  (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
                )}{' '}
              </Text>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        sx={{ mt: '30px' }}
      >
        <MenuItem>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <AccountCircle />
          </IconButton>
          <p style={{ marginBottom: '0' }}>
            Hi,
            {fname?.replace(
              /(^\w|\s\w)(\S*)/g,
              (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
            )}{' '}
          </p>
        </MenuItem>
        {dashboardd == 'true' && (
          <MenuItem>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='primary-search-account-menu'
              aria-haspopup='true'
              color='inherit'
            >
              <SpeedOutlinedIcon />
            </IconButton>
            <p
              style={{ marginBottom: '0' }}
              onClick={() => {
                dashboard()
              }}
            >
              Dashboard
            </p>
          </MenuItem>
        )}

        {content_library == 'true' && (
          <MenuItem>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='primary-search-account-menu'
              aria-haspopup='true'
              color='inherit'
            >
              <PermMediaOutlinedIcon />
            </IconButton>
            <p
              style={{ marginBottom: '0' }}
              onClick={() => {
                contentLibNav()
              }}
            >
              Content Library
            </p>
          </MenuItem>
        )}

        {manage_ads == 'true' && (
          <MenuItem>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='primary-search-account-menu'
              aria-haspopup='true'
              color='inherit'
            >
              <LibraryBooksOutlinedIcon />
            </IconButton>
            <p
              style={{ marginBottom: '0' }}
              onClick={() => {
                manageContNav()
              }}
            >
              Manage Ads
            </p>
          </MenuItem>
        )}

        {manage_posts == 'true' && (
          <MenuItem>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='primary-search-account-menu'
              aria-haspopup='true'
              color='inherit'
            >
              <Icon as={BiPaperPlane} fontSize={'0.9em'}></Icon>
            </IconButton>
            <p
              style={{ marginBottom: '0' }}
              onClick={() => {
                managePostNav()
              }}
            >
              Manage post
            </p>
          </MenuItem>
        )}

        {/* <MenuItem>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <LibraryBooksOutlinedIcon />
          </IconButton>
          <p
            style={{ marginBottom: "0" }}
            onClick={() => {
              managePostNav();
            }}
          >
            Manage Post
          </p>
        </MenuItem> */}
        {create_manage_users == 'true' && (
          <MenuItem>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='primary-search-account-menu'
              aria-haspopup='true'
              color='inherit'
            >
              <SettingsOutlinedIcon />
            </IconButton>
            <p
              style={{ marginBottom: '0' }}
              onClick={() => navigate('/Settings')}
            >
              {' '}
              User Settings
            </p>
          </MenuItem>
        )}

        {/* <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p style={{ marginBottom: "0" }}>Notifications</p>
        </MenuItem> */}

        <MenuItem>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <LogoutIcon />
          </IconButton>
          <p style={{ marginBottom: '0' }} onClick={signOut}>
            Sign out
          </p>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: '45px' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={signOut}>
          <LogoutIcon style={{ marginRight: '0.4em' }} /> Sign out
        </MenuItem>
      </Menu>

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorElNav}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: '45px' }}
        onClose={handleClose}
      >
        {create_manage_users == 'true' && (
          <MenuItem onClick={() => navigate('/Settings')}>
            <ManageAccountsOutlinedIcon style={{ marginRight: '0.3em' }} /> User
            Settings
          </MenuItem>
        )}
        {create_manage_acccount == 'true' && (
          <MenuItem onClick={navigatefun}>
            <ManageAccountsOutlinedIcon style={{ marginRight: '0.3em' }} />{' '}
            Manage Accounts
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
export default DashboardHeader
