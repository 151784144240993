import {
  Grid,
  View,
  useTheme,
  Tabs,
  TabItem,
  ThemeProvider,
  Theme,
  Flex,
} from "@aws-amplify/ui-react";
import DashboardHeader from "../../layout/DashboardHeader";
import { accountTabTheme } from "../../assets/style";
import AccountInfo from "./AccountInfo";
import UserInfo from "./UserInfo";
import BillingInfo from "./BillingInfo";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SocialAccount from "./SocialAccount";
import Manageaccount from "./Managaccount";
import { listAdDetails } from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify'
const AccountDashboard = () => {
  const location = useLocation();
  const [index, setIndex] = useState(location.state.tabindex);
  const [back, setback] = useState(0);
  const [locationid, setlocationid] = useState(location.state.id);
  const [manage_social_account, setmanage_social_account] = useState(localStorage.getItem("manage_social_account"));
  const [updateText, setupdateText] = useState("d");
  const [checkrole, check_role] = useState(localStorage.getItem("role_id"));
  const [companyIdFromAccountDashbaord, setCompanyIdFromAccountDashbaord] =
    useState(0);
  const { tokens } = useTheme();
  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  //useEffect(() => {}, [companyIdFromAccountDashbaord]);

  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  
  return (
    <div>
      <Flex direction={"row"}>
        <View width="100%">
          <DashboardHeader
            db={false}
            mc={false}
            cl={false}
            mp={false}
            ndb={false}
          ></DashboardHeader>
        </View>
      </Flex>
      <Flex direction={"row"}>
        <View
          backgroundColor={"hsl(210, 5%, 98%)"}
          margin={{ small: "0", medium: "xs", large: "xs" }}
          width="100%"
        >
          <ThemeProvider theme={accountTabTheme} colorMode="light">
            <Tabs
              justifyContent="center"
              currentIndex={index}
              onChange={(i: number) => setIndex(i)}
            >
              {location.state.key == "managesetting" && (
                <TabItem title="Manage Account" >
                  <Manageaccount
                    tabindex={setIndex}
                    currentIndex={index}
                    retreiveCompIdFromAccDashboard={
                      setCompanyIdFromAccountDashbaord
                    }
                    setupdateText={setupdateText}
                    backindexx={back}
                    selectedCompanyId={locationid}
                    seteditid={setlocationid}
                  ></Manageaccount>
                </TabItem>
              )}
              <TabItem title="Account Info" isDisabled>
                <AccountInfo
                  tabindex={setIndex}
                  currentIndex={index}
                  retreiveCompIdFromAccDashboard={
                    setCompanyIdFromAccountDashbaord
                  }
                  backindexx={back}
                  selectedCompanyId={locationid}
                  updateText={updateText}
                ></AccountInfo>
              </TabItem>
              <TabItem title="Billing Info" isDisabled>
                <BillingInfo
                  tabindex={setIndex}
                  currentIndex={index}
                  // retreiveCompIdFromAccDashboard={setCompanyIdFromAccountDashbaord}
                  compIdFromAccInfo={companyIdFromAccountDashbaord}
                  backindex={setback}
                  selectedCompanyId={location.state.id}
                ></BillingInfo>
              </TabItem>
              <TabItem title="User Info" isDisabled>
                <UserInfo
                  tabindex={setIndex}
                  currentIndex={index}
                  // compIdFromAccInfo={setId}
                  compIdFromAccInfo={companyIdFromAccountDashbaord}
                  selectedCompanyId={location.state.id}
                  updateText={updateText}
                ></UserInfo>
              </TabItem>
              {manage_social_account =="true" && (
              <TabItem title="Social Account Integrate" isDisabled>
                <SocialAccount
                  tabindex={setIndex}
                  currentIndex={index}
                  //  Uid={setCompanyIdFromAccountDashbaord}
                  compIdFromAccInfo={companyIdFromAccountDashbaord}
                ></SocialAccount>
              </TabItem>
              )}
            </Tabs>
          </ThemeProvider>
        </View>
      </Flex>
    </div>
  );
};
export default AccountDashboard;
