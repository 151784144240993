/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPostHistory = /* GraphQL */ `
  query GetPostHistory($id: ID!) {
    getPostHistory(id: $id) {
      id
      post_status_id
      assigned_by_userid
      assigned_to_userid
      postdetailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPostHistories = /* GraphQL */ `
  query ListPostHistories(
    $filter: ModelPostHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post_status_id
        assigned_by_userid
        assigned_to_userid
        postdetailsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPostHistories = /* GraphQL */ `
  query SyncPostHistories(
    $filter: ModelPostHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPostHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        post_status_id
        assigned_by_userid
        assigned_to_userid
        postdetailsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdHistory = /* GraphQL */ `
  query GetAdHistory($id: ID!) {
    getAdHistory(id: $id) {
      id
      addetailsID
      ad_status_id
      assigned_by_userid
      assigned_to_userid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdHistories = /* GraphQL */ `
  query ListAdHistories(
    $filter: ModelAdHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        addetailsID
        ad_status_id
        assigned_by_userid
        assigned_to_userid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdHistories = /* GraphQL */ `
  query SyncAdHistories(
    $filter: ModelAdHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        addetailsID
        ad_status_id
        assigned_by_userid
        assigned_to_userid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRolesModules = /* GraphQL */ `
  query GetRolesModules($id: ID!) {
    getRolesModules(id: $id) {
      id
      role_name
      create_manage_acccount
      create_manage_users
      manage_social_account
      landing_page
      dashboard
      content_library
      manage_ads
      manage_posts
      role_id
      isActive
      isDelete
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRolesModules = /* GraphQL */ `
  query ListRolesModules(
    $filter: ModelRolesModulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRolesModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role_name
        create_manage_acccount
        create_manage_users
        manage_social_account
        landing_page
        dashboard
        content_library
        manage_ads
        manage_posts
        role_id
        isActive
        isDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRolesModules = /* GraphQL */ `
  query SyncRolesModules(
    $filter: ModelRolesModulesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRolesModules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        role_name
        create_manage_acccount
        create_manage_users
        manage_social_account
        landing_page
        dashboard
        content_library
        manage_ads
        manage_posts
        role_id
        isActive
        isDelete
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdStatus = /* GraphQL */ `
  query GetAdStatus($id: ID!) {
    getAdStatus(id: $id) {
      id
      status_name
      isActive
      status_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdStatuses = /* GraphQL */ `
  query ListAdStatuses(
    $filter: ModelAdStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status_name
        isActive
        status_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdStatuses = /* GraphQL */ `
  query SyncAdStatuses(
    $filter: ModelAdStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status_name
        isActive
        status_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPostDetails = /* GraphQL */ `
  query GetPostDetails($id: ID!) {
    getPostDetails(id: $id) {
      id
      post_message
      post_schedule_timestamp
      page_id
      user_id
      post_status
      isActive
      isDelete
      post_type
      post_channels
      media_hash_id
      fb_post_id
      insta_container_id
      companyinfoID
      PostHistories {
        items {
          id
          post_status_id
          assigned_by_userid
          assigned_to_userid
          postdetailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      fb_post_msg
      insta_post_msg
      fb_image_url
      insta_image_url
      insta_post_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPostDetails = /* GraphQL */ `
  query ListPostDetails(
    $filter: ModelPostDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        PostHistories {
          nextToken
          startedAt
          items {
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            assigned_to_userid
            assigned_by_userid
            post_status_id
            updatedAt
            postdetailsID
          }
        }
        id
        post_message
        post_schedule_timestamp
        page_id
        user_id
        post_status
        isActive
        isDelete
        post_type
        post_channels
        media_hash_id
        fb_post_id
        insta_container_id
        companyinfoID
        fb_post_msg
        insta_post_msg
        fb_image_url
        insta_image_url
        insta_post_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPostDetails = /* GraphQL */ `
  query SyncPostDetails(
    $filter: ModelPostDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPostDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        post_message
        post_schedule_timestamp
        page_id
        user_id
        post_status
        isActive
        isDelete
        post_type
        post_channels
        media_hash_id
        fb_post_id
        insta_container_id
        companyinfoID
        fb_post_msg
        insta_post_msg
        fb_image_url
        insta_image_url
        insta_post_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdDetails = /* GraphQL */ `
  query GetAdDetails($id: ID!) {
    getAdDetails(id: $id) {
      id
      ad_name
      ad_primary_text
      media_type
      media_file_name
      media_file_path
      ad_headline
      ad_description
      ad_website_url
      ad_see_more_url
      ad_see_more_display
      call_to_action
      created_by
      updated_by
      ad_campaign_id
      ad_account_id
      user_id
      isActive
      IsDelete
      ad_status
      fb_ad_id
      insta_selected_account
      insta_selected_id
      adset_Id
      AdHistories {
        items {
          id
          addetailsID
          ad_status_id
          assigned_by_userid
          assigned_to_userid
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Published_Ad_Id
      Media_Hash_Id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdDetails = /* GraphQL */ `
  query ListAdDetails(
    $filter: ModelAdDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        IsDelete
        _deleted
        _lastChangedAt
        _version
        ad_account_id
        ad_campaign_id
        ad_description
        ad_headline
        ad_name
        ad_primary_text
        ad_see_more_display
        ad_see_more_url
        ad_status
        ad_website_url
        adset_Id
        Published_Ad_Id
        Media_Hash_Id
        call_to_action
        createdAt
        created_by
        fb_ad_id
        id
        insta_selected_account
        insta_selected_id
        isActive
        media_file_name
        media_file_path
        media_type
        updatedAt
        updated_by
        user_id
        AdHistories {
          items {
            _deleted
            _lastChangedAt
            _version
            ad_status_id
            addetailsID
            assigned_by_userid
            assigned_to_userid
            createdAt
            id
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdDetails = /* GraphQL */ `
  query SyncAdDetails(
    $filter: ModelAdDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ad_name
        ad_primary_text
        media_type
        media_file_name
        media_file_path
        ad_headline
        ad_description
        ad_website_url
        ad_see_more_url
        ad_see_more_display
        call_to_action
        created_by
        updated_by
        ad_campaign_id
        ad_account_id
        user_id
        isActive
        IsDelete
        ad_status
        fb_ad_id
        insta_selected_account
        insta_selected_id
        adset_Id
        Published_Ad_Id
        Media_Hash_Id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdSet = /* GraphQL */ `
  query GetAdSet($id: ID!) {
    getAdSet(id: $id) {
      id
      adset_id
      budget_type
      budget_amount
      schedule_start_date
      schedule_start_time
      schedule_end_date
      schedule_end_time
      conversion_location
      conversion_events
      placements
      custom_audience
      exclude_custom_audience
      age_from
      age_to
      ads_account_id
      gender
      demographics
      interests
      behaviors
      location
      created_by
      updated_by
      language
      adset_status
      isActive
      isDelete
      campaign_id
      AdDetails {
        nextToken
        startedAt
      }
      adset_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdSets = /* GraphQL */ `
  query ListAdSets(
    $filter: ModelAdSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adset_id
        budget_type
        budget_amount
        schedule_start_date
        schedule_start_time
        schedule_end_date
        schedule_end_time
        conversion_location
        conversion_events
        placements
        custom_audience
        exclude_custom_audience
        age_from
        age_to
        ads_account_id
        gender
        demographics
        interests
        behaviors
        location
        created_by
        updated_by
        language
        adset_status
        isActive
        isDelete
        campaign_id
        adset_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdSets = /* GraphQL */ `
  query SyncAdSets(
    $filter: ModelAdSetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdSets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        adset_id
        budget_type
        budget_amount
        schedule_start_date
        schedule_start_time
        schedule_end_date
        schedule_end_time
        conversion_location
        conversion_events
        placements
        custom_audience
        exclude_custom_audience
        age_from
        age_to
        ads_account_id
        gender
        demographics
        interests
        behaviors
        location
        created_by
        updated_by
        language
        adset_status
        isActive
        isDelete
        campaign_id
        adset_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdCampaign = /* GraphQL */ `
  query GetAdCampaign($id: ID!) {
    getAdCampaign(id: $id) {
      id
      ads_account_id
      campaign_name
      fb_campaign_id
      special_ad_category
      objective
      created_by
      updated_by
      campaign_status
      isActive
      isDelete
      AdSets {
        items {
          AdDetails {
            nextToken

            startedAt

            items {
              IsDelete

              _lastChangedAt

              _deleted

              _version

              ad_account_id

              ad_description

              ad_campaign_id

              ad_primary_text

              ad_name

              ad_headline

              ad_see_more_display

              ad_see_more_url

              ad_status

              ad_website_url

              adset_Id

              call_to_action

              createdAt

              created_by

              fb_ad_id

              id

              insta_selected_account

              insta_selected_id

              isActive

              media_file_name

              media_file_path

              media_type

              Media_Hash_Id

              updatedAt

              updated_by

              user_id
              AdHistories {
                items {
                  _deleted
                  _lastChangedAt
                  _version
                  ad_status_id
                  addetailsID
                  assigned_by_userid
                  assigned_to_userid
                  createdAt
                  updatedAt
                  id
                }
                nextToken
                startedAt
              }
            }
          }

          _deleted

          _lastChangedAt

          _version

          ads_account_id

          adset_id

          adset_name

          adset_status

          age_from

          age_to

          behaviors

          budget_amount

          budget_type

          campaign_id

          conversion_events

          createdAt

          conversion_location

          created_by

          custom_audience

          demographics

          exclude_custom_audience

          gender

          id

          interests

          isActive

          language

          isDelete

          location

          placements

          schedule_end_time

          schedule_end_date

          updated_by

          updatedAt

          schedule_start_time

          schedule_start_date
        }

        nextToken

        startedAt
      }
      companyinfoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdCampaigns = /* GraphQL */ `
  query ListAdCampaigns(
    $filter: ModelAdCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _lastChangedAt
        _version
        ads_account_id
        campaign_name
        campaign_status
        companyinfoID
        createdAt
        created_by
        fb_campaign_id
        id
        isActive
        isDelete
        objective
        special_ad_category
        updatedAt
        updated_by
        AdSets {
          items {
            _deleted
            _lastChangedAt
            _version
            ads_account_id
            adset_id
            adset_name
            adset_status
            age_from
            age_to
            behaviors
            budget_amount
            budget_type
            campaign_id
            conversion_events
            conversion_location
            createdAt
            created_by
            custom_audience
            demographics
            exclude_custom_audience
            gender
            interests
            id
            isActive
            isDelete
            language
            location
            placements
            schedule_end_date
            schedule_end_time
            schedule_start_date
            schedule_start_time
            updatedAt
            updated_by
            AdDetails {
              items {
                IsDelete
                _deleted
                _lastChangedAt
                _version
                ad_account_id
                ad_campaign_id
                ad_description
                ad_headline
                ad_name
                ad_primary_text
                ad_see_more_display
                ad_see_more_url
                ad_status
                ad_website_url
                adset_Id
                call_to_action
                createdAt
                created_by
                fb_ad_id
                id
                insta_selected_account
                insta_selected_id
                isActive
                media_file_name
                media_file_path
                media_type
                Media_Hash_Id
                updatedAt
                updated_by
                user_id
                AdHistories {
                  items {
                    _deleted
                    _lastChangedAt
                    _version
                    ad_status_id
                    addetailsID
                    assigned_by_userid
                    assigned_to_userid
                    createdAt
                    updatedAt
                    id
                  }
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdCampaigns = /* GraphQL */ `
  query SyncAdCampaigns(
    $filter: ModelAdCampaignFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ads_account_id
        campaign_name
        fb_campaign_id
        special_ad_category
        objective
        created_by
        updated_by
        campaign_status
        isActive
        isDelete
        companyinfoID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConfigItems = /* GraphQL */ `
  query GetConfigItems($id: ID!) {
    getConfigItems(id: $id) {
      id
      campaign_objective
      campaign_categories
      adset_budget
      conversion_location
      conversion_event
      placemen
      age_from_and_to
      preview_format
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConfigItems = /* GraphQL */ `
  query ListConfigItems(
    $filter: ModelConfigItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign_objective
        campaign_categories
        adset_budget
        conversion_location
        conversion_event
        placemen
        age_from_and_to
        preview_format
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConfigItems = /* GraphQL */ `
  query SyncConfigItems(
    $filter: ModelConfigItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConfigItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign_objective
        campaign_categories
        adset_budget
        conversion_location
        conversion_event
        placemen
        age_from_and_to
        preview_format
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContentLibrary = /* GraphQL */ `
  query GetContentLibrary($id: ID!) {
    getContentLibrary(id: $id) {
      id
      media_type
      media_url
      media_name
      media_width
      media_height
      media_size
      media_desc
      isActive
      isDelete
      uploaded_by
      companyinfoID
      default
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContentLibraries = /* GraphQL */ `
  query ListContentLibraries(
    $filter: ModelContentLibraryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentLibraries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        media_type
        media_url
        media_name
        media_width
        media_height
        userid
        media_size
        media_desc
        isActive
        isDelete
        uploaded_by
        companyinfoID
        default
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentLibraries = /* GraphQL */ `
  query SyncContentLibraries(
    $filter: ModelContentLibraryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentLibraries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        media_type
        media_url
        media_name
        media_width
        media_height
        media_size
        media_desc
        isActive
        isDelete
        uploaded_by
        companyinfoID
        default
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFBLoginInfo = /* GraphQL */ `
  query GetFBLoginInfo($id: ID!) {
    getFBLoginInfo(id: $id) {
      id
      user_id
      facebook_userid
      pagename
      pageid
      long_access_token
      ads_accountname
      ads_accountid
      facebook_username
      company_id
      instagram_connected
      insta_business_id
      instagram_page_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFBLoginInfos = /* GraphQL */ `
  query ListFBLoginInfos(
    $filter: ModelFBLoginInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFBLoginInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        facebook_userid
        pagename
        pageid
        long_access_token
        ads_accountname
        ads_accountid
        facebook_username
        company_id
        instagram_connected
        insta_business_id
        instagram_page_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFBLoginInfos = /* GraphQL */ `
  query SyncFBLoginInfos(
    $filter: ModelFBLoginInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFBLoginInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        facebook_userid
        pagename
        pageid
        long_access_token
        ads_accountname
        ads_accountid
        facebook_username
        company_id
        instagram_connected
        insta_business_id
        instagram_page_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      user_fname
      user_lname
      user_email
      user_phone
      user_password
      isActive
      isDelete
      created_by
      updated_by
      companyinfoID
      role_id
      cognito_identity_id
      multiple_account
      multiple_accounts_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_fname
        user_lname
        user_email
        user_phone
        user_password
        isActive
        isDelete
        created_by
        updated_by
        companyinfoID
        role_id
        cognito_identity_id
        multiple_account
        multiple_accounts_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserInfos = /* GraphQL */ `
  query SyncUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_fname
        user_lname
        user_email
        user_phone
        user_password
        isActive
        isDelete
        created_by
        updated_by
        companyinfoID
        role_id
        cognito_identity_id
        multiple_account
        multiple_accounts_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompanyInfo = /* GraphQL */ `
  query GetCompanyInfo($id: ID!) {
    getCompanyInfo(id: $id) {
      id
      comp_name
      comp_street
      comp_city
      comp_email
      comp_phone
      comp_state
      comp_zipcode
      isActive
      isDelete
      created_by
      updated_by
      comp_timezone
      contact_fname
      contact_lname
      contacy_email
      contact_phone
      UserInfos {
        items {
          id
          user_fname
          user_lname
          user_email
          user_phone
          user_password
          isActive
          isDelete
          created_by
          updated_by
          companyinfoID
          multiple_account
          multiple_accounts_id
          role_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      comp_type
      card_number
      card_holder_name
      security_code
      expiry_month
      expiry_year
      billing_address
      city
      state
      zipcode
      ContentLibraries {
        nextToken
        startedAt
      }
      AdCampaigns {
        nextToken
        startedAt
      }
      PostDetails {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCompanyInfos = /* GraphQL */ `
  query ListCompanyInfos(
    $filter: ModelCompanyInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comp_name
        comp_street
        comp_city
        comp_email
        comp_phone
        comp_state
        comp_zipcode
        isActive
        isDelete
        created_by
        updated_by
        comp_timezone
        contact_fname
        contact_lname
        contacy_email
        contact_phone
        UserInfos {
          items {
            id
            user_fname
            user_lname
            user_email
            user_phone
            user_password
            isActive
            isDelete
            created_by
            updated_by
            companyinfoID
            multiple_account
            multiple_accounts_id
            role_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        comp_type
        card_number
        card_holder_name
        security_code
        expiry_month
        expiry_year
        billing_address
        city
        state
        zipcode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanyInfos = /* GraphQL */ `
  query SyncCompanyInfos(
    $filter: ModelCompanyInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanyInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        comp_name
        comp_street
        comp_city
        comp_email
        comp_phone
        comp_state
        comp_zipcode
        isActive
        isDelete
        created_by
        updated_by
        comp_timezone
        contact_fname
        contact_lname
        contacy_email
        contact_phone
        comp_type
        card_number
        card_holder_name
        security_code
        expiry_month
        expiry_year
        billing_address
        city
        state
        zipcode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
