import { Link, View,Flex,useTheme ,Image } from "@aws-amplify/ui-react";

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

const Header = () => {
    const { tokens } = useTheme();
    return (<div>
        <Flex
        marginTop = {{base:"large",medium:"xl",large:"small"}}
        maxWidth ={{base:"300px",medium:"40vw",large:"10vw"}}
        justifyContent="Center"
       // marginInline={"auto"}
        >
             {/* <Image
        alt="DAM logo"
        src="./assets/images/logo.png"
        objectFit="cover"
        objectPosition="40% 40%"
        backgroundColor="center"
        height="70%"
        width="70%"
        opacity="100%">

        </Image> */}
         <FacebookOutlinedIcon sx={{ color: "#b71f39", fontSize: '5rem' }}></FacebookOutlinedIcon>
        <Flex direction={"row"}
        alignItems={"center"}
        gap={{large:"relative.xs"}} marginLeft={"auto"}>
            {/* <Link >Home</Link>
            <Link>About</Link>
            <Link>Pricing</Link> */}
            {/* <Link>Contact Us</Link> */}
            </Flex>

           
        </Flex>
    </div>)

};  
export default Header;