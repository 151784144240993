import logo from './logo.svg'
import './App.css'
import { withAuthenticator } from '@aws-amplify/ui-react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from 'react-router-dom'
import LoginScreen from './Login'
import Dashboard from './pages/dashboard/Dashboard'
import AccountDashboard from './pages/account/AccDashboard'
import ContentLibrary from './pages/contentlibrary/ContentLibrary'
import BillingInfo from './pages/account/BillingInfo'
import Settings from './pages/Settings/UserSettings'
import Facebook from './pages/social/Facebook'
import Instagram from './pages/social/Instagram'
import ManageCont from './pages/managecontent/ManageContent'
import ContentPreview from './pages/contentlibrary/ContentPreview'
import ManagePost from './pages/ManagePost/Managepost'
import Newdashboard from './pages/dashboard/Newdashboard'

function App () {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<LoginScreen />} />
        </Routes>

        <Routes>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route
            path='/account'
            element={<AccountDashboard></AccountDashboard>}
          />
          <Route path='/newdashboard' element={<Newdashboard></Newdashboard>} />
          <Route
            path='/contentlib'
            element={<ContentLibrary></ContentLibrary>}
          />
          <Route path='/managecontent' element={<ManageCont></ManageCont>} />
          <Route path='/billing' element={<BillingInfo></BillingInfo>} />
          <Route path='/Settings' element={<Settings></Settings>} />
          <Route path='/facebook' element={<Facebook></Facebook>} />
          <Route path='/instagram' element={<Instagram></Instagram>} />
          <Route path='/preview' element={<ContentPreview></ContentPreview>} />
          <Route path='/managepost' element={<ManagePost></ManagePost>} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
