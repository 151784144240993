import {
  Button,
  Grid,
  useBreakpointValue,
  Flex,
  Icon,
  View,
  TabItem,
  Tabs,
  Text,
  TextField,
  SelectField,
  PhoneNumberField,
  Alert,
  Card,
  PasswordField,
} from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createUserInfo, updateUserInfo } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import DashboardHeader from "../../layout/DashboardHeader";
import { BsFillForwardFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  getCompanyInfo,
  listRolesModules,
  listUserInfos,
} from "../../graphql/queries";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";

const UserInfo = ({
  tabindex,
  currentIndex,
  compIdFromAccInfo,
  updateText,
}) => {
  const initialState = {
    user_fname: "",
    user_lname: "",
    user_email: "",
    user_phone: "",
    user_password: "",
    confirm_password: "",
    role_id: "",
  };
  let { id } = useParams();
  const [Fname, setFname] = useState(false);
  const [Lname, setLname] = useState(false);
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Password, setPassword] = useState("");
  const [listofroles, ListOfRoles] = useState([]);
  const [CnfrmPassword, setCnfrmPassword] = useState(false);
  const [savedUserId, setSavedUserId] = useState("");
  const [Role, setRole] = useState(false);
  const [Progressbar, setProgressbar] = useState(false);
  const [UformState, setUformState] = useState(initialState);
  const [version, set_version] = useState(0);
  const location = useLocation();
 
  const [updtcnfrmPassword, set_updtcnfrmPassword] = useState();
  const [updateconfirmpasword, setupdateconfirmpasword]=useState(false);
  const [bindconfirmpasword, setbindconfirmpasword]=useState();
 

  function setInput(key, value) {
    setUformState({ ...UformState, [key]: value });
    localStorage.setItem("UformState", JSON.stringify(UformState));

    // validation();
  }

  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const buttonStyle = {
    backgroundColor: "rgb(107 42 149)",
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
  };

  const clearStyle = {
    backgroundColor: "#f44336",
    size: "small",
    color: "#fff",
    height: "2rem",
    border: "rgb(244 67 54)",
    float: "center",
  };
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
    xl: "xl",
    xxl: "xxl",
  });
  const card = {
    width: "80%",
    boxShadow: "0px 4px 9px 0px rgb(0 0 0 / 20%)",
    marginLeft: "10%",
  };

  const toastStyle = {
    backgroundColor: "rgb(107 42 149)",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    padding: "1rem",
    borderRadius: "0.5rem",
  };

  //saveuserinfo to the model
  const saveUserinfo = async () => {
    setProgressbar(true);
    try {
      //  alert("try");
      const { cognitouser } = await Auth.signUp({
        username: UformState.user_email,
        password: UformState.user_password,
        attributes: {
          email: UformState.email,
          //phone: UformState.phone,
        },
      });

      try {
        const saveInfos = {
          // id: id,
          companyinfoID: compIdFromAccInfo,
          user_fname: UformState.user_fname,
          user_lname: UformState.user_lname,
          user_email: UformState.user_email,
          user_phone: UformState.user_phone,
          user_password: UformState.user_password,
          created_by: "admin",
          updated_by: "admin",
          role_id: UformState.role_id,
          cognito_identity_id: localStorage.getItem("Cognito_User_Id"),
          isActive: true,
          isDelete: false,
        };
        //  console.log(saveInfos, "saveInfos");

        let modeldata = await API.graphql(
          graphqlOperation(createUserInfo, {
            input: saveInfos,
          })
        );

        console.log("modeldata", modeldata);
        toast.success("Info has been saved successfully");
        setTimeout(() => {
          setProgressbar(false)
        }, 2500);
        setTimeout(() => {
          tabindex(parseInt(currentIndex) + 1);
        }, 1500);
        // Uid(modeldata.data.createUserInfo.id);
      } catch (err) {
        //  alert(1);
        toast.error(err.message);
        setTimeout(() => {
          setProgressbar(false)
        }, 2500);
        console.log(err);
      }
    } catch (error) {
      // alert(1);
      toast.error(error.message);
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      console.log("error signing up:", error);
    }
  };

  //fetching User infos
  const fetchUserInfo = async () => {
      setupdateconfirmpasword(true);
      
    try {
      let filter = {
        UserInfos: {
          filter: { role_id: { eq: 6 } },
        },
      };

      const userInfo = await API.graphql({
        query: getCompanyInfo,

        variables: { id: compIdFromAccInfo, filter: filter },
      });

       setbindconfirmpasword(userInfo.data.getCompanyInfo.UserInfos.items[0].user_password);
       console.log(userInfo.data.getCompanyInfo.UserInfos.items[0].user_password
     ,"update")
      
      let filterr=userInfo.data.getCompanyInfo.UserInfos.items.filter((item) =>  item.role_id==6)
      console.log(userInfo.data.getCompanyInfo.UserInfos.items,"swathi")
      //setUformState(userInfo.data.getCompanyInfo.UserInfos.items[0]);
      //let temp = {...userInfo.data.getCompanyInfo.UserInfos.items[0]}
     // temp.confirm_password = userInfo.data.getCompanyInfo.UserInfos.items[0].user_password
      //setUformState(temp);
      setUformState(filterr[0]);
      set_updtcnfrmPassword(filterr[0].user_password)
      console.log(filterr[0], "userInfo.data.getCompanyInfo.UserInfos.items[0]")
      set_version(filterr[0]._version);
      setSavedUserId(filterr[0].id);
    } catch (err) {
      console.log(err);
    }
  };

  //update userinfo
  const updateUserinfo = async () => {
    setProgressbar(true);
    try {
      console.log("  $$$$$$$savedUserId");
      //console.log(savedUserId, "  $$$$$$$savedUserId");
      const updateInfos = {
        id: savedUserId,
        companyinfoID: compIdFromAccInfo,
        user_fname: UformState.user_fname,
        user_lname: UformState.user_lname,
        user_email: UformState.user_email,
        user_phone: UformState.user_phone,
        user_password: UformState.user_password,
      //  confirm_password:UformState.confirm_password,
        role_id: UformState.role_id,
        isActive: true,
        isDelete: false,
        _version: version,
      };
      //  console.log(saveInfos, "saveInfos");

      let modeldata = await API.graphql(
        graphqlOperation(updateUserInfo, {
          input: updateInfos,
        })
      );
      setTimeout(() => {
        
        tabindex(parseInt(currentIndex) + 1);
      }, 1500);
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      toast.success("User Info has been updated successfully");
      // console.log("IDD " + modeldata.data.createUserInfo.id);
      console.log(modeldata, "modeldata");
      // ---------------------------------------------------------------------------------------cognitouserupdate-----------------------------------------------------------------------------------------------
      //   try {
      //     // alert("hi");
      //     const { cognitouser } = await Auth.signUp({
      //       username: UformState.email,
      //       password: UformState.password,
      //       attributes: {
      //         email: UformState.email,
      //         //phone: UformState.phone,
      //       },
      //     });
      //     toast.success("Info has been saved successfully");
      //     tabindex(3);
      //     console.log(cognitouser, "signup");
      //   } catch (error) {
      //     toast.error(error.message);
      //     console.log("error signing up:", error);
      //   }
      // ----------------------------------------------------------------------------------------------end--------------------------------------------------------------------------------------------------------
    } catch (err) {
      setTimeout(() => {
        setProgressbar(false)
      }, 2500);
      toast.error(err.message);
      console.log(err);
    }
  };

  //fetching roles
  const fetchrole = async () => {
    try {
      let filter = {
        role_id: {
          eq: 6,
        },
      };
      const userdata = await API.graphql({
        query: listRolesModules,
        variables: { filter: filter },
      });

      ListOfRoles(userdata.data.listRolesModules.items);
    } catch (err) {
      console.log("error fetching actors");
    }
  };

  //for back button
  const back = () => {
    tabindex(parseInt(currentIndex) - 1);
  };

  //page rendering
  useEffect(() => {
    console.log("updateText", updateText);
    fetchrole();
    if (location.state.key == "update" || updateText == "Update") {
      fetchUserInfo();
    }
    try {
      let data = JSON.parse(localStorage.getItem("UformState"));

      if (data != null) {
        setUformState(data);
      }
    } catch (err) {
      toast.error("err save");
      console.log(err);
    }
  }, []);

  //form validations
  const validation = (key) => {
    if (UformState.user_fname == "") {
      setFname(true);
    } else {
      setFname(false);
    }
    if (UformState.user_lname == "") {
      setLname(true);
    } else {
      setLname(false);
    }

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        UformState.user_email
      )
    ) {
      setEmail("Enter valid email");
    } else {
      setEmail("");
    }

    if (UformState.user_email == "") {
      setEmail("Enter the Email");
    }

    if (UformState.user_phone.length < 10) {
      setPhone("Phone number should be 10 digits");
    } else {
      setPhone("");
    }

    if (UformState.user_phone == "") {
      setPhone("Enter the phone number");
    }

    if (UformState.user_password == "") {
      setPassword("Enter the password");
    }

    if (UformState.user_password.length > 7) {
      setPassword("");
    } else {
      setPassword("Enter the Password");
    }

    
   ///CONFIRM PASWORD VALIDATION

    if(UformState.confirm_password == "" ||bindconfirmpasword=="")
    {
       setCnfrmPassword(true);
       setCnfrmPassword("confirm password is required");
    }
     if(UformState.user_password != (UformState.confirm_password||bindconfirmpasword))
    {
      setCnfrmPassword(true);
       setCnfrmPassword("Password Must be Same ");

    }else{
       setCnfrmPassword(false);
    }
    




    if (UformState.role_id === "") {
      setRole(true);
    } else {
      setRole(false);
    }
    /////save ///
    if (
      UformState.user_fname != "" &&
      UformState.user_lname != "" &&
      UformState.user_email != "" &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        UformState.user_email
      ) &&
      UformState.user_phone != "" &&
      UformState.user_phone.length == 10 &&
      UformState.user_password != "" &&
       (UformState.confirm_password != ""|| bindconfirmpasword !=="") &&
      UformState.role_id != "" 
     && UformState.user_password  ==  (UformState.confirm_password || bindconfirmpasword)
    ) {
      if (
        key == "save" &&
        location.state.key == "create" &&
        key == "save" &&
        updateText == "d"
      ) {
        setFname(false);
        setLname(false);
        setEmail(false);
        setPhone(false);
        setPassword(false);
        setCnfrmPassword(false);
        setRole(false);
        saveUserinfo();
      } else {
        // alert("update");
        setFname(false);
        setLname(false);
        setEmail(false);
        setPhone(false);
        setPassword(false);
        setCnfrmPassword(false);
        setRole(false);
        updateUserinfo();

        // tabindex(1);
      }
    }
  };


  //////////////////////VALIDATION IN ONCHANGE///////////////////////////////////
  const validinputfield = (key, value) => {
    if (key == "user_fname") {
      if (value.length == 0) {
        setFname(true);
      } else {
        setFname(false);
      }
    }

    if (key == "user_lname") {
      if (value.length == 0) {
        setLname(true);
      } else {
        setLname(false);
      }
    }

    if (key == "user_email") {
      const exp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        );
      if (value.length == 0) {
        setEmail(true);
        setEmail("Email is Required!");
      } else if (!exp) {
        setEmail(true);
        setEmail("Email Id must be valid");
      } else {
        setEmail(false);
      }
    }

    if (key == "user_phone") {
      if (value.length == 0) {
        setPhone(true);
        setPhone("Contact Number is required.");
      } else if (value.length < 10) {
        setPhone(true);
        setPhone("Contact Number should be 10 digit");
      } else {
        setPhone(false);
      }
    }
    if (key == "user_password") {
      const uppercasePassword = /(?=.*?[A-Z])/.test(value);
      const minLengthPassword = /.{8,}/.test(value);
      const lowercaseRegExp = /(?=.*?[a-z])/.test(value);
      const digitsPassword = /(?=.*?[0-9])/.test(value);
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/.test(value);
      //onst spcial_digit=/(?=.*[\d$@.$!%*#?&])/.test(value);

      if (value.length == 0) {
        setPassword("Password is required.");
      } else if (!uppercasePassword) {
        setPassword("At least one Uppercase");
      } else if (!lowercaseRegExp) {
        setPassword("At least one Lowercase");
      } else if (!digitsPassword) {
        setPassword("At least one  digit");
      } else if (!specialCharRegExp) {
        setPassword("At least one  specialcharacter");
      } else if (!minLengthPassword) {
        setPassword("At least minumum 8 characters");
      } else {
        setPassword(false);
      }
    }


  
      if(key=="user_password")
      {

      if(value!=(UformState.confirm_password||bindconfirmpasword))
        {
         setCnfrmPassword(true);
        setCnfrmPassword("Password Must Be Same");
          }else{
         setCnfrmPassword(false);
         }

       }

   
    if (key == "confirm_password") { 
      const valpas = UformState.user_password;
      
      const valcon = UformState.confirm_password;

      if (value.length == 0 ) {
     
        setCnfrmPassword("Confirm Password  required");
      }  
      
      if ( valcon!== valpas ) {  
        setCnfrmPassword("Password Must Be Same");
      } else {
        setCnfrmPassword(false);
      }
    }


    if (key == "role_id") { 
      if (value == "") {
        setRole(true);
      } else {
        setRole(false);
      }
    }


   //// while fetch or update confirm password///
     if(key=="bindpasword") 
     {
       setbindconfirmpasword(value)


       if(bindconfirmpasword=="")
        {
        setCnfrmPassword(true);
        setCnfrmPassword("confirm password is required");
        }
      if(bindconfirmpasword!=UformState.user_password )
        {
         setCnfrmPassword(true);
         setCnfrmPassword("Password Must Be Same");

         }else{
           setCnfrmPassword(false);
        }
  
     }

    setInput(key, value);
  };



  /////////////////////////////

  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />
      <Flex direction={"row"} marginTop="2em">
        <View width="100%">
          <Card
            className="cardmain"
            style={card}
            direction={{ base: "column", large: "row" }}
          >
            <View
              className="comHead"
              backgroundColor={"#b71f39"}
              borderRadius={{ base: "xs", large: "small" }}
              fontSize={{ base: "small", large: "medium" }}
              color={"white"}
              padding={{ base: "xs", large: "xs" }}
            >
              User Info
            </View>
            <Flex
              direction={{ base: "column", large: "row" }}
              margin={{ base: "small", large: "medium" }}
              gap={{ base: "small", large: "xxxl" }}
            >
              <TextField
                id="hosp_name_field"
                onChange={(event) =>
                  validinputfield("user_fname", event.target.value)
                }
                onBlur={(event) =>
                  validinputfield("user_fname", event.target.value)
                }
                hasError={Fname}
                value={UformState.user_fname}
                errorMessage="Enter the First Name"
                label="First Name"
                size={{ base: "small", large: "small" }}
                maxLength="16"
                width={{ base: "100%", large: "50%" }}
                autoComplete="first-name"
              ></TextField>
              <TextField
                id="hosp_add_field"
                onChange={(event) =>
                  validinputfield("user_lname", event.target.value)
                }
                onBlur={(event) =>
                  validinputfield("user_lname", event.target.value)
                }
                hasError={Lname}
                value={UformState.user_lname}
                label="Last Name"
                errorMessage="Enter the Last Name"
                size={{ base: "small", large: "small" }}
                maxLength="16"
                width={{ base: "100%", large: "50%" }}
                autoComplete="last-name"
              ></TextField>
            </Flex>
            <Flex
              direction={{ base: "column", large: "row" }}
              margin={{ base: "small", large: "medium" }}
              gap={{ base: "small", large: "xxxl" }}
            >
              <TextField
                onChange={(event) =>
                  validinputfield("user_email", event.target.value)
                }
                onBlur={(event) =>
                  validinputfield("user_email", event.target.value)
                }
                id="hosp_zip_field"
                value={UformState.user_email}
                hasError={Email}
                errorMessage={Email}
                label="Email"
                size={{ base: "small", large: "small" }}
                width={{ base: "100%", large: "50%" }}
                autoComplete="new-email"
              ></TextField>
              <PhoneNumberField
                onChange={(event) =>
                  validinputfield(
                    "user_phone",
                    event.target.value
                      .replace(/[^\w\s]|[aA-zZ]/, "")
                      .toString()
                      .slice(0, 10)
                  )
                }
                onBlur={(event) =>
                  validinputfield(
                    "user_phone",
                    event.target.value
                      .replace(/[^\w\s]|[aA-zZ]/, "")
                      .toString()
                      .slice(0, 10)
                  )
                }
                id="hosp_zip_field"
                value={UformState.user_phone}
                hasError={Phone}
                errorMessage={Phone}
                label="Phone Number"
                size={{ base: "small", large: "small" }}
                width={{ base: "100%", large: "50%" }}
                autoComplete="new-phonenumber"
              ></PhoneNumberField>
            </Flex>
            <Flex
              direction={{ base: "column", large: "row" }}
              margin={{ base: "small", large: "medium" }}
              gap={{ base: "small", large: "xxxl" }}
            >
              <PasswordField
                onChange={(event) =>
                  validinputfield("user_password", event.target.value)
                }
                onBlur={(event) =>
                  validinputfield("user_password", event.target.value)
                }
                id="hosp_zip_field"
                value={UformState.user_password}
                hasError={Password}
                errorMessage={Password}
                label="Password"
                size={{ base: "small", large: "small" }}
                width={{ base: "100%", large: "50%" }}
                autoComplete="password"
              ></PasswordField>
              <PasswordField
                onChange={(event) => { updateconfirmpasword?
                   validinputfield("bindpasword",event.target.value): validinputfield("confirm_password", event.target.value)}
                
                 }
                 onBlur={(event) => { updateconfirmpasword?
                   validinputfield("bindpasword",event.target.value): validinputfield("confirm_password", event.target.value)}
                
                 }
                
                 onKeyUp={(event) => { updateconfirmpasword?
                   validinputfield("bindpasword",event.target.value): validinputfield("confirm_password", event.target.value)}
                
                 }
                id="hosp_zip_field"
                value={updateconfirmpasword?bindconfirmpasword:UformState.confirm_password}
                hasError={CnfrmPassword}
                errorMessage={CnfrmPassword}
                label="Confirm Password"
                size={{ base: "small", large: "small" }}
                width={{ base: "100%", large: "50%" }}
                autoComplete="reenter-password"
              ></PasswordField>
            </Flex>
            <Flex
              direction={{ base: "column", large: "row" }}
              margin={{ base: "small", large: "medium" }}
              gap={{ base: "small", large: "xxxl", medium: "small" }}
            >
              <Flex width={{ base: "100%", large: "50%" }}>
                <ul>
                  <li>
                    <font color={"#808080"}>
                      {" "}
                      Password can contain Alphanumeric characters
                    </font>
                  </li>
                  <li>
                    <font color={"#808080"}>
                      {" "}
                      Use a Number or special characters
                    </font>
                  </li>
                </ul>
              </Flex>
              <SelectField
                width={{ base: "100%", large: "50%" }}
                label="Role"
                onChange={(event) =>
                  validinputfield("role_id", event.target.value)
                }
                onBlur={(event) =>
                  validinputfield("role_id", event.target.value)
                }
                value={UformState.role_id}
                hasError={Role}
                errorMessage="Please select a role"
                placeholder=" Select Role"
              >
                {/* <option value="0" isdisabled>
                  Select Role
                </option> */}
                {listofroles.map((row, key) => (
                  <option key={key} value={row?.role_id}>
                    {row?.role_name}
                  </option>
                ))}
              </SelectField>
            </Flex>
            <Flex
              direction={{ base: "row", large: "row" }}
              marginTop="2em"
              margin={{ base: "small", large: "medium" }}
              gap={{ base: "small", large: "xxxl" }}
            >
              <Button
                size="small"
                backgroundColor={"rgb(33, 46, 80)"}
                style={buttonStyle}
                marginRight={"auto"}
                fontSize={{ base: "0.6em", large: "0.9em" }}
                onClick={back}
              >
                <Icon
                  as={BsFillArrowLeftCircleFill}
                  fontSize={{ base: "small", large: "xxl" }}
                  marginTop={{ base: "relative.xs", large: "relative.xxs" }}
                ></Icon>
                &nbsp;&nbsp; Billing Info{" "}
              </Button>
              <Button
                size="small"
                backgroundColor={"rgb(33, 46, 80)"}
                style={buttonStyle}
                marginLeft={"auto"}
                fontSize={{ base: "0.6em", large: "0.9em" }}
                onClick={() => {
                  validation("save");
                }}
                isLoading={Progressbar}
                loadingText="processing..."
              >
                Social Account &nbsp;&nbsp;
                <Icon
                  as={BsFillArrowRightCircleFill}
                  fontSize={{ base: "small", large: "xxl" }}
                  marginTop={{ base: "relative.xs", large: "relative.xxs" }}
                ></Icon>
              </Button>
            </Flex>
          </Card>
        </View>
      </Flex>
    </div>
  );
};
export default UserInfo;
