import './App.css'
import {
  Grid,
  View,
  useTheme,
  TextField,
  useBreakpointValue,
  Flex,
  Text,
  Heading,
  PasswordField,
  Button,
  ThemeProvider
} from '@aws-amplify/ui-react'
import Header from './layout/Header'
import { primaryButton } from './assets/style'
import { ToastContainer, toast } from 'react-toastify'
import { getUserInfo, listUserInfos, listRolesModules } from './graphql/queries'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateUserInfo } from './graphql/mutations'

const LoginScreen = () => {
  const navigate = useNavigate()
  const passwordRef = useRef()
  const { tokens } = useTheme()
  const initialState = { name: '', password: '', confirmationCode: '' }
  const [Signup, setSignup] = useState(false)
  const [formState, setFormState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [nameErrorr, setNameErrorr] = useState('')
  const [passwordErrorr, setPasswordErrorr] = useState('')
  const [errormsg, setErrormsg] = useState(false)
  const [email, setEmail] = useState('')
  const [Passwordmsg, setPasswordmsg] = useState('')
  const [Cognito_User_Id, setCognito_User_Id] = useState()
  // /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/; --email Regex

  //password Validation

  const Validpassword = password => {
    const uppercaseRegExp = /(?=.*?[A-Z])/
    const lowercaseRegExp = /(?=.*?[a-z])/
    const digitsRegExp = /(?=.*?[0-9])/
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/
    const minLengthRegExp = /.{8,}/
    const passwordLength = password.length
    const uppercasePassword = uppercaseRegExp.test(password)
    const lowercasePassword = lowercaseRegExp.test(password)
    const digitsPassword = digitsRegExp.test(password)
    const specialCharPassword = specialCharRegExp.test(password)
    const minLengthPassword = minLengthRegExp.test(password)
    let errMsg = ''
    if (passwordLength === 0) {
      errMsg = 'Password is Required'
      setPasswordErrorr(true)
    } else if (!uppercasePassword) {
      errMsg = 'At least one Uppercase'
      setPasswordErrorr(true)
    } else if (!lowercasePassword) {
      errMsg = 'At least one Lowercase'
      setPasswordErrorr(true)
    } else if (!digitsPassword) {
      setPasswordErrorr(true)
      errMsg = 'At least one digit'
    } else if (!specialCharPassword) {
      setPasswordErrorr(true)
      errMsg = 'At least one Special Characters'
    } else if (!minLengthPassword) {
      setPasswordErrorr(true)
      errMsg = 'At least minumum 8 characters'
    } else {
      errMsg = ''
      setPasswordErrorr(false)
    }
    setInput('password', password)
    if (errMsg !== '') {
      setPasswordError(true)
      setPasswordmsg(errMsg)
    } else {
      setPasswordError(false)
    }
  }

  // obscure Email Function
  const obscureEmail = email => {
    const [name, domain] = email.split('@')
    const sub_domain = domain.split('.')
    return `${name[0]}${new Array(name.length).join('*')}@${
      domain[0]
    }${new Array(sub_domain[0].length).join('*')}.${sub_domain[1]}`
  }

  //form validation
  const validateForm = () => {
    if (formState.name == '') {
      setNameErrorr('Email is Required')
      setNameError(true)
    } else {
      setNameErrorr('')
      setNameError(false)
    }
    if (formState.password == '') {
      setPasswordErrorr('Password is Required')
      setPasswordError(true)
    } else {
      setPasswordErrorr('')
      setPasswordError(false)
    }
    if (formState.name != '' && formState.password != '') {
      verifyCredentials()
    }
  }

  //updating userinfo for cognitoIdentityId
  const updatingUserInfo = () => {
    try {
      const updateInfos = {
        id: localStorage.getItem('userid'),
        cognito_identity_id: localStorage.getItem('Cognito_User_Id'),
        _version: localStorage.getItem('version')
      }
      let modeldata = API.graphql(
        graphqlOperation(updateUserInfo, {
          input: updateInfos
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  //OnClick for Login Button
  const verifyCredentials = async () => {
    fetchuserinfo()
    setErrormsg(false)
    try {
      setLoading(true)
      await Auth.signIn(formState.name, formState.password).then(async user => {
        const credentials = await Auth.currentUserCredentials()
       
        localStorage.setItem('Cognito_User_Id', credentials.identityId)
        if (Cognito_User_Id == null || Cognito_User_Id == undefined) {
          updatingUserInfo()
        }
        setLoading(false)
        navigate('/dashboard', { replace: true })
      })
    } catch (error) {
      setErrormsg(true)
      setLoading(false)
      console.log('error signing in', error)
      if (error.code === 'UserNotConfirmedException') {
        setSignup(true)
        setEmail(obscureEmail(formState.name))
        setErrormsg(false)
      }
    }
  }

  //OnClick for verify button
  const cognitoconfirmsignup = () => {
    setNameError(false)
    try {
      // if (
      //   formState.confirmationCode !== "" &&
      //   formState.confirmationCode.length === 6
      // ) {
      const { user } = Auth.confirmSignUp(
        formState.name,
        formState.confirmationCode
      )
        .then(user => {
          console.log(user, 'responsey') //
          toast.success('User Verified Successfully')
          navigate('/dashboard', { replace: true })
          setSignup(false)
          //setFormState(initialState);
        })
        .catch(err => {
          console.log(err.code)
          if (err.code === 'CodeMismatchException') {
            toast.error('Invalid Code')
          }
        })
      // console.log("cguser", user);

      // // if (user) {
      // alert("hi");
      // toast.success("User Verified Successfully");
      // //  navigate("/dashboard", { replace: true });
      // console.log(user, "confirm signup");
      // } else {
      //   alert("bye");
      //   toast.error("Invalid Code");
      // }
      // } else {
      //   //  toast.error("Please enter the confirmation code");
      //   setNameError(true);
      // }
    } catch (err) {
      // console.log(err.code);
    }
  }
  //fetch userinfo
  const fetchuserinfo = async () => {
    try {
      let filter = {
        user_email: {
          eq: formState.name.toLowerCase() // filter priority = 1
        }
      }

      const userdata = await API.graphql({
        query: listUserInfos,
        variables: { limit: 10000, filter: filter }
      })

  
      //set roleid in localstorage
      localStorage.setItem(
        'role_id',
        userdata.data.listUserInfos.items[0].role_id
      )
      localStorage.setItem(
        'fname',
        userdata.data.listUserInfos.items[0].user_fname
      )
      localStorage.setItem(
        'companyinfoID',
        userdata.data.listUserInfos.items[0].companyinfoID
      )
      localStorage.setItem('distinct', false)
      localStorage.setItem(
        'backupCompanyInfoid',
        userdata.data.listUserInfos.items[0].companyinfoID
      )
      localStorage.setItem('userid', userdata.data.listUserInfos.items[0].id)
      localStorage.setItem(
        'version',
        userdata.data.listUserInfos.items[0]._version
      )
      setCognito_User_Id(
        userdata.data.listUserInfos.items[0].cognito_identity_id
      )
      modules(userdata.data.listUserInfos.items[0].role_id)
    } catch (err) {
      console.log('error fetching actors')
    }
  }
  //fetch userinfo
  const modules = async id => {
    try {
      let filter = {
        role_id: {
          eq: id // filter priority = 1
        }
      }

      const userdata = await API.graphql({
        query: listRolesModules,
        variables: { filter: filter }
      })

      localStorage.setItem(
        'content_library',
        userdata.data.listRolesModules.items[0].content_library
      )
      localStorage.setItem(
        'create_manage_acccount',
        userdata.data.listRolesModules.items[0].create_manage_acccount
      )
      localStorage.setItem(
        'create_manage_users',
        userdata.data.listRolesModules.items[0].create_manage_users
      )
      localStorage.setItem(
        'dashboard',
        userdata.data.listRolesModules.items[0].dashboard
      )
      localStorage.setItem(
        'landing_page',
        userdata.data.listRolesModules.items[0].landing_page
      )
      localStorage.setItem(
        'manage_ads',
        userdata.data.listRolesModules.items[0].manage_ads
      )
      localStorage.setItem(
        'manage_social_account',
        userdata.data.listRolesModules.items[0].manage_social_account
      )
      localStorage.setItem(
        'manage_posts',
        userdata.data.listRolesModules.items[0].manage_posts
      )
      //set roleid in localstorage
    } catch (err) {
      console.log('error fetching actors')
    }
  }
  function setInput (key, value) {
    setFormState({ ...formState, [key]: value })
  }

  const shadowProp = {
    boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)'
  }
  useEffect(() => {}, [])
  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />
      {!Signup && (
        <Grid
          templateColumns={{ base: '1fr', large: '1fr' }}
          templateRows={{
            base: '3rem 1rem 34rem 2rem',
            medium: '3rem 1rem 64rem 2rem',
            large: '2rem 1rem 27rem 2rem'
          }}
          gap={tokens.space.xs}
        >
          <View>
            <Header></Header>
          </View>
          <View></View>
          <View>
            <Flex
              direction={'column'}
              style={shadowProp}
              border=' 1px solid #b71f39'
              borderRadius={'0.5em'}
              boxShadow='large'
              marginTop={{ base: 'large', medium: 'xl', large: 'xxxl' }}
              maxWidth={{ base: '80vw', medium: '60vw', large: '40vw' }}
              marginInline={{ base: 'medium', medium: 'xl', large: 'auto' }}
              height={{ base: '25rem', medium: '25rem', large: '25rem' }}
            >
              <Heading
                marginInline={'auto'}
                level={4}
                color='#b71f39'
                fontSize={'2em'}
                marginTop={{ base: 'large', medium: 'xl', large: 'xl' }}
              >
                Login
              </Heading>

              <Flex
                direction={'column'}
                marginLeft={{ base: 'medium', medium: 'xxl', large: 'xxxl' }}
                maxWidth={{ base: '70vw', medium: '50vw', large: '30vw' }}
                height={'100%'}
                gap={tokens.space.large}
              >
                <TextField
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      passwordRef.current.focus()
                    }
                  }}
                  onChange={event => setInput('name', event.target.value)}
                  value={formState.name.toLowerCase()}
                  hasError={nameErrorr}
                  errorMessage={nameErrorr}
                  label='Username'
                  size='small'
                  width='100%'
                ></TextField>
                <PasswordField
                  ref={passwordRef}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      validateForm()
                    }
                  }}
                  onChange={event => Validpassword(event.target.value)}
                  value={formState.password}
                  hasError={passwordErrorr}
                  errorMessage={Passwordmsg}
                  label='Password'
                  size='small'
                  width='100%'
                ></PasswordField>
                <div>
                  {errormsg && (
                    <Text
                      variation='warning'
                      color='red'
                      marginLeft={{ base: '26%', large: '26%' }}
                    >
                      Invalid Username or Password!
                    </Text>
                  )}
                </div>

                <Button
                  onClick={validateForm}
                  isLoading={loading}
                  loadingText='processing...'
                  width='100%'
                  backgroundColor={'#b71f39'}
                  style={primaryButton}
                >
                  {' '}
                  Login
                </Button>
              </Flex>
            </Flex>
          </View>
          <View></View>
        </Grid>
      )}
      {Signup && (
        <Grid
          templateColumns={{ base: '1fr', large: '1fr' }}
          templateRows={{
            base: '3rem 1rem 34rem 2rem',
            medium: '3rem 1rem 64rem 2rem',
            large: '2rem 1rem 27rem 2rem'
          }}
          gap={tokens.space.xs}
        >
          <View>
            <Header></Header>
          </View>
          <View></View>
          <View>
            <Flex
              direction={'column'}
              style={shadowProp}
              marginTop={{ base: 'large', medium: 'xl', large: 'xxxl' }}
              maxWidth={{ base: '80vw', medium: '60vw', large: '40vw' }}
              marginInline={{ base: 'medium', medium: 'xl', large: 'auto' }}
              height={{ base: '25rem', medium: '25rem', large: '25rem' }}
            >
              {/* alignItems={"center"}> */}
              <Heading
                marginInline={'auto'}
                level={4}
                marginTop={{ base: 'large', medium: 'xl', large: 'xxl' }}
              >
                We Emailed You
              </Heading>
              <div>
                <Text
                  marginLeft={{ base: '1%', medium: '1%', large: '1%' }}
                  textAlign='center'
                >
                  To Log In , Enter the confirmation code<br></br> we sent to
                  &nbsp;
                  <b>{email}</b>
                </Text>
              </div>

              <Flex
                direction={'column'}
                marginLeft={{ base: 'medium', medium: 'xxl', large: 'xxxl' }}
                maxWidth={{ base: '70vw', medium: '50vw', large: '30vw' }}
                height={'100%'}
                gap={tokens.space.large}
              >
                {/* <TextField
                  onChange={(event) => setInput("name", event.target.value)}
                  value={formState.name}
                  // hasError={nameError}
                  // errorMessage={"Please enter your username"}
                  label="Email"
                  size="small"
                  width="100%"
                ></TextField> */}
                <TextField
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      cognitoconfirmsignup()
                    }
                  }}
                  onChange={event =>
                    setInput(
                      'confirmationCode',
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, '')
                        .slice(0, 6)
                    )
                  }
                  onPaste={event =>
                    setInput(
                      'confirmationCode',
                      event.target.value
                        .replace(/[aA-zZ]|[^\w\s]/gi, '')
                        .slice(0, 6)
                    )
                  }
                  value={formState.confirmationCode}
                  hasError={nameError}
                  errorMessage={'Please enter Confirmation Code'}
                  label='Confirmation Code'
                  size='small'
                  width='100%'
                ></TextField>
                <div>
                  {errormsg && (
                    <Text
                      variation='warning'
                      color='red'
                      marginLeft={{ base: '26%', large: '26%' }}
                    >
                      Enter Valid Confirmation Code!
                    </Text>
                  )}
                </div>

                <Button
                  onClick={cognitoconfirmsignup}
                  isLoading={loading}
                  loadingText='Verifying...'
                  width='100%'
                  style={primaryButton}
                >
                  {' '}
                  Verify
                </Button>
              </Flex>
            </Flex>
          </View>
        </Grid>
      )}
    </div>
  )
}
export default LoginScreen
