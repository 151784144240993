import {
  Button,
  Icon,
  View,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ScrollView,
  ThemeProvider,
  Theme,
  Link,
  Flex,
  Text,
  Divider,
  Alert,
} from "@aws-amplify/ui-react";

import "react-toastify/dist/ReactToastify.css";
import { primaryButton } from "../../assets/style";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { getFBLoginInfo, listFBLoginInfos } from "../../graphql/queries";
import { deleteFBLoginInfo, updateFBLoginInfo } from "../../graphql/mutations";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross, ImBriefcase } from "react-icons/im";
import { IoMdSync } from "react-icons/io";
import { API, graphqlOperation, toast } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { BsFillArrowLeftCircleFill, BsInstagram } from "react-icons/bs";
import { BsCheckCircleFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import Helper from "../../assets/Helper";
import LoopIcon from "@mui/icons-material/Loop";
const SocialAccount = ({
  tabindex,
  currentIndex,
  compIdFromAccInfo,
  backindex,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [login, setLogin] = useState(false);
  const [Instalogin, setInstaLogin] = useState(false);
  const [adsacc, setadsacc] = useState("");
  const [fbusername, setfbusername] = useState("");
  const [pagname, setpagname] = useState("");
  const [InstaUsercheck, setInstaUsercheck] = useState();
  const [buttonchange, setbuttonchange] = useState(false);
  //const [popup, setPopup] = useState(false);
  //connect social account from manage ad page
  const [connectsocial, setconnectsocial] = useState(false);
  //page Rendering
  useEffect(() => {
    // alert(connectsocial)
    //connect social account from manage ad page
    if (location.state.key == "connectsocaialaccount") {
      setconnectsocial(true);
    } else {
      setconnectsocial(false);
    }
    fetchfblogin();
  }, [buttonchange, login]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loginid, setloginid] = useState();
  const [loginversion, setloginversion] = useState();

  //Fetching the data from the FBLoginInfo table
  const fetchfblogin = async () => {
    if (location.state.FcompanyId != null) {
      compIdFromAccInfo = location.state.FcompanyId;
    }
    
    if (true) {
      try {
        //  alert("APP ID :" + Helper.FACEBOOK_APP_ID);
        let filter = {
          company_id: {
            eq: localStorage.getItem("companyinfoID"), // filter priority = 1
          },
        };
        const fbdata = await API.graphql({
          query: listFBLoginInfos,
          variables: { filter: filter },
        });


        if (
          fbdata.data.listFBLoginInfos.items.filter(
            (item) => item._deleted == null || item._deleted !== true
          ).length > 0
        ) {
          
          // alert(fbdata.data.listFBLoginInfos.items.filter(
          //   (item) => item._deleted == null || item._deleted !== true
          // )[0]._version);
          
          setloginid(
            fbdata.data.listFBLoginInfos.items.filter(
              (item) => item._deleted == null || item._deleted !== true
            )[0].id
          );
          setloginversion(
            fbdata.data.listFBLoginInfos.items.filter(
              (item) => item._deleted == null || item._deleted !== true
            )[0]._version
          );
          setfbusername(
            fbdata.data.listFBLoginInfos.items[0].facebook_username
          );
          setadsacc(
            fbdata.data.listFBLoginInfos.items.filter(
              (item) => item._deleted == null || item._deleted !== true
            )[0].ads_accountname
          );
          setpagname(
            fbdata.data.listFBLoginInfos.items.filter(
              (item) => item._deleted == null || item._deleted !== true
            )[0].pagename
          );

          setLogin(true); //if the data is present in the table then set the login to true
          setInstaUsercheck(
            fbdata.data.listFBLoginInfos.items.filter(
              (item) => item._deleted == null || item._deleted !== true
            )[0].instagram_connected
          );
       

          if (
            fbdata.data.listFBLoginInfos.items.filter(
              (item) => item._deleted == null || item._deleted !== true
            )[0].instagram_connected
          ) {

            setbuttonchange(true);
          } else {

            setbuttonchange(false);
          }
          
        }
      } catch (err) {
        console.log("error fetching actors" + err);
      }
    }
  };

  //Disconnect the facebook account
  const Disconnect = async () => {
    // alert(loginversion+"fff")
    try {
      const updateObj = {
        id: loginid,
        _version: loginversion,
      };
      await API.graphql(
        graphqlOperation(deleteFBLoginInfo, { input: updateObj })
      ).then(() => {
        setLogin(false);
      });
       toast.success(" Facebook Disconnected Successfully");
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };

  //Disconnect Inst
  const InstaDisconnect = async () => {
    try {
      // const fbdata = await API.graphql({
      //   query: deleteFBLoginInfo,
      //   variables: { input: { id: fbusername } },
      // });
      const updateObj = {
        id: loginid,
        _version: loginversion,
        instagram_connected: false,
      };
      await API.graphql(
        graphqlOperation(updateFBLoginInfo, { input: updateObj })
      ).then(() => {
        setbuttonchange(false);
         toast.success(" Instagram Disconnected Successfully");

      });
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };

  //Reconnecting to the facebook account
  const Reconnect = async () => {
    //alert("Work in Progress");
  };

  //getting Facebook Response
  // const responseFacebook = (response) => {
  //   if (response.accessToken) {
  //     navigate("/facebook", {
  //       state: { data: response, compIdFromSocialAccount: compIdFromAccInfo },
  //     });
  //   }
  // };
  const responseFacebook = (response) => {
    
    if (response.accessToken) {
      navigate("/facebook", {
        state: { data: response, compIdFromSocialAccount: compIdFromAccInfo },
      });
    }
  };

  //Back to the previous page
  const back = () => {
    tabindex(parseInt(currentIndex) - 1);
  };

  //Not In Use
  const connect = () => {
    localStorage.removeItem("AccInfoformState");
    localStorage.removeItem("BillInfoformState");
    localStorage.removeItem("UformState");
  };

  //-------------------------------------------------------------------------DesignStuffs---------------------------------------------------------------------------
  const card = {
    width: "80%",
    boxShadow: "0px 4px 9px 0px rgb(0 0 0 / 20%)",
    marginLeft: "10%",
  };
  const Tablehead = {
    backgroundColor: "#b71f39",
    color: "#FFFFFF",
  };
  const Tablebody = {
    color: "white",
  };
  const buttonStyle = {
    backgroundColor: "#639",
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
  };

  const theme: Theme = {
    name: "table-theme",
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: "{colors.blue.20}" },
            },

            striped: {
              backgroundColor: { value: "{colors.blue.10}" },
            },
          },

          header: {
            color: { value: "{colors.blue.80}" },
            fontSize: { value: "{fontSizes.medium}" },
          },

          data: {
            fontSize: { value: "{fontSizes.small}" },
          },
        },
      },
    },
  };

  const connectInstapopup = () => {
    //alert("Work in Progress");
    setShow(true);
    //setPopup(true);
  };

  const InstaConnect = async () => {
    navigate("/instagram", {
      state: { compIdFromSocialAccount: compIdFromAccInfo },
    });
  };

  const ConnectHome = async () => {
    navigate("/dashboard", {
      state: { compIdFromSocialAccount: ConnectHome },
    });
  };



  // -------------------------------------------------------------------------------DesignStuffs---------------------------------------------------------------------------

  return (
    <div>
      <View marginTop="2em">
        <ThemeProvider theme={theme} colorMode="light">
          <ScrollView>
            <Table
              highlightOnHover
              variation="striped"
              boxShadow="0px 4px 9px 0px rgb(0 0 0 / 20%)"
              width="80%"
              marginLeft="10%"
            >
              <TableHead>
                <TableRow style={Tablehead}>
                  <TableCell style={Tablebody} as="th">
                    SocialPlatforms
                  </TableCell>
                  <TableCell style={Tablebody} as="th">
                    Status
                  </TableCell>
                  <TableCell style={Tablebody} as="th">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Icon
                      as={ImBriefcase}
                      marginTop={"0.4rem"}
                      fontSize={"xl"}
                      color={"#b71f39"}
                    />
                    Facebook for Ads Management{" "}
                    <Link>{login ? "(" + pagname + ")" : ""}</Link> <br></br>
                    <small style={{ display: login ? "block" : "none" }}>
                      {" "}
                      &nbsp;with Ads Account <Link>" {adsacc} "</Link>for Ads
                      Management
                    </small>
                  </TableCell>

                  <TableCell>
                    <font Color={login ? "green" : "none"}>
                      {" "}
                      {login ? "Connected" : "No Accounts Connected"}{" "}
                    </font>{" "}
                  </TableCell>
                  <TableCell>
                    <div style={{ display: login ? "none" : "block" }}>
                      <FacebookLogin
                        appId={Helper["FACEBOOK_APP_ID"]}
                        version={Helper["FB_APP_VERSION"]}
                        fields={Helper["FB_APP_FIELDS"]}
                        scope={Helper["FACEBOOK_SCOPE"]}
                        // appId="320067396410532"
                        // version="15.0"
                        // fields="name,email,picture"
                        // scope="public_profile"
                        autoLoad={false}
                        cookie={false}
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <Button
                            onClick={renderProps.onClick}
                            style={primaryButton}
                            backgroundColor="rgb(33, 46, 80)"
                          >
                            {login ? "Connected" : "Connect"}
                          </Button>
                        )}
                      />
                    </div>

                    <div style={{ display: login ? "block" : "none" }}>
                      <Icon
                        as={LoopIcon}
                        fontSize={"2.3em"}
                        color={"hsl(220, 50%, 50%)"}
                        onClick={Reconnect}
                         style={{ cursor: "pointer" }}
                      />
                      <Icon
                        as={ImCross}
                        marginTop={"0.4rem"}
                        marginLeft={"84px"}
                        fontSize={"xl"}
                        color={"red"}
                        onClick={Disconnect}
                         style={{ cursor: "pointer" }}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Icon
                      as={BsInstagram}
                      marginTop={"0.4rem"}
                      fontSize={"xl"}
                      color={"#b71f39"}
                    />
                    Instagram{" "}
                    <Link>{buttonchange ? "(" + pagname + ")" : ""}</Link>{" "}
                    <br></br>
                    <small style={{ display: buttonchange ? "block" : "none" }}>
                      {" "}
                      &nbsp;with Ads Account <Link>" {adsacc} "</Link>for Ads
                      Management
                    </small>
                  </TableCell>

                  <TableCell>
                    <font Color={buttonchange ? "green" : "none"}>
                      {" "}
                      {buttonchange
                        ? "Connected"
                        : "No Accounts Connected"}{" "}
                    </font>{" "}
                  </TableCell>
                  <TableCell>
                    <div style={{ display: buttonchange ? "none" : "block" }}>
                      <Button
                        style={primaryButton}
                        backgroundColor="rgb(33, 46, 80)"
                        onClick={connectInstapopup}
                      >
                        {buttonchange ? "Connected" : "Connect"}
                      </Button>
                    </div>
                    <div style={{ display: buttonchange ? "block" : "none" }}>
                      <Icon
                        as={LoopIcon}
                        fontSize={"2.3em"}
                        color={"hsl(220, 50%, 50%)"}
                        onClick={Reconnect}
                        style={{ cursor: "pointer" }}
                      />
                      <Icon
                        as={ImCross}
                        marginTop={"0.4rem"}
                        marginLeft={"84px"}
                        fontSize={"xl"}
                        color={"red"}
                        onClick={InstaDisconnect}
                         style={{ cursor: "pointer" }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>
                    <Icon as={BsTwitter}  marginTop={"0.4rem"} fontSize={"xl"} color={"hsl(220, 50%, 50%)"} />
                    Twitter
                  </TableCell>
                  <TableCell>No Accounts Connected</TableCell>
                  <TableCell>
                    <Button style={primaryButton}>Connect</Button>
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
            <Button
              size="small"
              backgroundColor={"rgb(33, 46, 80)"}
              style={buttonStyle}
              marginRight={"auto"}
              marginLeft={"10%"}
              marginTop={"4%"}
              onClick={back}
              disabled={connectsocial} //disable when redirect from manage ad page
            >
              <Icon
                as={BsFillArrowLeftCircleFill}
                fontSize={{ large: "xxl" }}
                marginTop={{ large: "relative.xxs" }}
              ></Icon>
              &nbsp;&nbsp; User Info
            </Button>

            <Button
              size="small"
              backgroundColor={"rgb(33, 46, 80)"}
              style={buttonStyle}
              marginRight={"auto"}
              marginLeft={"60%"}
              marginTop={"4%"}
              onClick={ConnectHome}
            >
              <Icon
                as={BsCheckCircleFill}
                fontSize={{ large: "xxl" }}
                marginTop={{ large: "relative.xxs" }}
              ></Icon>
              Finish &nbsp;&nbsp;
            </Button>
            <Modal size="xl" show={show} onHide={handleClose} border="none">
              <Modal.Header
                style={{
                  justifyContent: "center",
                }}
              >
                <Text color="black" fontSize="1.5em" textAlign="center">
                  Connect to Instagram
                </Text>
              </Modal.Header>
              <Modal.Body>
                <Flex
                  direction={{ base: "column", large: "column" }}
                  gap={{ base: "small", large: "small" }}
                  padding="1em"
                  paddingTop="0em"
                >
                  <View width="100%">
                    <Text
                      fontSize={"1em"}
                      fontWeight="400"
                      marginTop={{
                        base: "-4em",
                        medium: "5px",
                        large: "5px",
                      }}
                    >
                      <b>Important Notes: </b>
                      <br></br>
                      <br></br>
                      <ul>
                        <li>
                          Instagram Direct Publishing requires an&nbsp;
                          <b>Instagram Business Account</b>.
                        </li>
                        <br></br>
                        <li>
                          Your Instagram Account needs to be connected to a
                          Facebook Page and you need to be an administrator of
                          that Facebook Page.
                        </li>
                        <br></br>
                        <li>
                          Once you click the Connect button below, you will be
                          redirected to Facebook: make sure you accept all
                          permissions requested by Facebook. Otherwise, DAM will
                          not be able to access & manage your Instagram Account
                          as necessary.
                        </li>
                        <br></br>
                        <li>
                          Once you have accepted all permissions requested by
                          Facebook, you will be redirected back to DAM, where
                          you will be able to select the Facebook Page
                          associated with the Instagram Account.
                        </li>
                      </ul>
                      {/* <br></br>
                      If you don't have a Facebook Page associated with your
                      Instagram Account, please&nbsp;
                      <Link>click here instead</Link>. */}
                    </Text>
                  </View>
                </Flex>
              </Modal.Body>
              <Flex
                direction="column"
                alignItems="center"
                style={{ marginTop: "1em" }}
                marginBottom={{ large: "relative.large" }}
              >
                <Button
                  style={primaryButton}
                  boxShadow="large"
                  onClick={InstaConnect}
                >
                  Connect
                </Button>
              </Flex>
            </Modal>
          </ScrollView>
        </ThemeProvider>
      </View>
    </div>
  );
};
export default SocialAccount;
