import "../../App.css";
import {
  Grid,
  View,
  useTheme,
  Flex,
  Heading,
  Button,
  SelectField,
} from "@aws-amplify/ui-react";
import { primaryButton, dsbprimaryButton } from "../../assets/style";
import { ToastContainer, toast } from "react-toastify";
import { updateFBLoginInfo } from "../../graphql/mutations";
import { listFBLoginInfos } from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import DashboardHeader from "../../layout/DashboardHeader";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Helper from "../../assets/Helper";
import { Auth } from 'aws-amplify'
const Facebook = () => {
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const location = useLocation();
  let { data } = location.state;
  let [pagelist, setPagelist] = useState([]);
  let [fadslist, setads] = useState([]);
  const [fbuserid, setFbuserid] = useState("");
  const [username, setusername] = useState("");
  const [longaccess, setLongaccess] = useState("");
  const [selectpage, setselectpage] = useState("0");
  const [selectads_account, setads_account] = useState("0");
  const [company_id, setcompany_id] = useState("");
  const [fbidfromfblogin, setfbidfromfblogin] = useState();
  const [fbacctokenfromfblogin, setfbacctokenfromfblogin] = useState();
  const [IdfrmFBlogin, setIdfrmFBlogin] = useState();
  const [version, set_version] = useState(0);
  const[AccessToken,setAccessToken]=useState();
  const [InstaBusinessID,setInstaBusinessID] = useState();

  //page render
  useEffect(() => {
    fetchFblogininfo();
  }, []);
  const [logged, setlogged] = useState(true)
 
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  //getting Page List
  const getpagelist = async (id, token) => {
    try {
      axios({
        method: "get",
        url:
          "https://graph.facebook.com//v15.0/" +
          id +
          "/accounts?fields=id,name&access_token=" +
          token,
        responseType: "stream",
      }).then(function (responsef) {
        console.log(responsef);
        let pagedata = responsef.data.data;
        let pagelistmap = pagedata.map((item, i) => {
          return (
            <option key={i} value={item.name + "~" + item.id}>
              {item.name + " (" + item.id + ")"}
            </option>
          );
        });
        setPagelist(pagelistmap);
        console.log("pagelist", pagelistmap);
      });
    } catch (err) {
      console.log("error fetching fblogininfo");
    }
  };

  const getInstaDetails = async () => {
    if (selectpage != "0") {
    const selectedPageId =  selectpage.split("~")[1];
    //page access token
    axios({
      method: "get",

      url:
        "https://graph.facebook.com/" +
        selectedPageId +
        "?fields=access_token&access_token=" +
        AccessToken,

      responseType: "stream",
    })
      .then(function (responsef) {
        console.log(responsef.data.access_token, "page_token");
        axios({
          method: "get",

          url:
            "https://graph.facebook.com/v15.0/" +
            selectedPageId +
            "?fields=instagram_business_account&access_token=" +
            responsef.data.access_token,
          //responsef.data.access_token,

          responseType: "stream",
        })
          .then(function (responsef) {
            console.log(responsef.data?.id, "instagram_business_account");
           // setInstaBusinessID(responsef.data.insta_business_account.id);
          //  alert(InstaBusinessID+" :IBI")
            submitconnection(responsef.data.id);
          //   responsef.data.data.map((item) => {
          //     if (item.username === AddformState.insta_selected_account) {
          //       set_InstaUserId(item.id);
          //     }
          //   });
          //   set_InstaAccounts(responsef.data.data);
          //   // set_InstaUserId(responsef.data.data[0].id);
           })

          .catch(function (error) {
            console.log("Instaaccfetcherror", error);
          });
      })
      .catch(function (error) {
        console.log("page token Error", error);
      });
    }
    //instagram accounts
  };

  //page redirect
  const cancelconnection = () => {
    navigate("/account", { state: { tabindex: "4" } });
  };
  //listing Fblogininfo from db
  const fetchFblogininfo = async () => {
    try {
      const fblogininfoData = await API.graphql(
        graphqlOperation(listFBLoginInfos, {
          filter: {
            company_id: { eq: localStorage.getItem("companyinfoID") },
          },
        })
      );
      // setfbidfromfblogin(
      //   fblogininfoData.data.listFBLoginInfos.items[0].facebook_userid
      // );
      // setfbacctokenfromfblogin(
      //   fblogininfoData.data.listFBLoginInfos.items[0].long_access_token
      // );
      set_version(fblogininfoData.data.listFBLoginInfos.items.filter(
        (item) => item._deleted == null || item._deleted !== true
      )[0]._version);
      setIdfrmFBlogin(fblogininfoData.data.listFBLoginInfos.items.filter(
        (item) => item._deleted == null || item._deleted !== true
      )[0].id);
      setAccessToken(fblogininfoData.data.listFBLoginInfos.items.filter(
        (item) => item._deleted == null || item._deleted !== true
      )[0].long_access_token)

      getpagelist(
        fblogininfoData.data.listFBLoginInfos.items.filter(
          (item) => item._deleted == null || item._deleted !== true
        )[0].facebook_userid,
        fblogininfoData.data.listFBLoginInfos.items.filter(
          (item) => item._deleted == null || item._deleted !== true
        )[0].long_access_token
      );
     // getInstaDetails(fblogininfoData.data.listFBLoginInfos.items[0].long_access_token)

      console.log("fbinfofrminstapage", fblogininfoData);
    } catch (err) {
      console.log("error fetching fblogininfo");
    }
  };

  //Create Connection Click
  const submitconnection = async (Insta_business_Id) => {
    try {
      if (selectpage == "0") {
        toast.error("Please select a page");
      } else {
        console.log("instapageid", selectpage.split("~")[1]);
        const saveInstaInfotoFBinfo = {
          id: IdfrmFBlogin,
          instagram_connected: true,
          insta_business_id: Insta_business_Id, //data.business_id
          instagram_page_id: selectpage.split("~")[1],
          _version: version,
        };
        console.log("saveInstaInfotoFBinfo", saveInstaInfotoFBinfo);
        await API.graphql(
          graphqlOperation(updateFBLoginInfo, { input: saveInstaInfotoFBinfo })
        );

        navigate("/account", {
          state: {
            From: "dashboard",
            tabindex: "4",
            FcompanyId: location.state.compIdFromSocialAccount,
          },
        });
      }
    } catch (err) {
      // alert("erroR");
      console.log("error", err);
    }
  };

  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };

  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />

      <Grid
        templateColumns={{ base: "1fr", large: "1fr" }}
        templateRows={{
          base: "3rem 1rem 34rem 2rem",
          medium: "3rem 1rem 64rem 2rem",
          large: "2rem 1rem 27rem 2rem",
        }}
        gap={tokens.space.xs}
      >
        <View>
          <DashboardHeader></DashboardHeader>
        </View>
        <View></View>
        <View>
          <Flex
            direction={"column"}
            style={shadowProp}
            marginTop={{ base: "large", medium: "xl", large: "xxxl" }}
            maxWidth={{ base: "124vw", medium: "60vw", large: "40vw" }}
            marginInline={{ base: "medium", medium: "xl", large: "auto" }}
            height={{ base: "25rem", medium: "25rem", large: "25rem" }}
          >
            <Heading
              marginInline={"auto"}
              level={4}
              marginTop={{ base: "large", medium: "xl", large: "xxl" }}
            >
              Connecting To Instagram
            </Heading>

            <Flex
              direction={"column"}
              marginLeft={{ base: "medium", medium: "xxl", large: "xxxl" }}
              maxWidth={{ base: "111vw", medium: "50vw", large: "30vw" }}
              height={"100%"}
              gap={tokens.space.large}
            >
              <SelectField
                label=" Where Would You Like To Publish The Post From This Account? "
                onChange={(event) => setselectpage(event.target.value)}
                width="100%"
              >
                <option value="0">--Select--</option>
                {pagelist}
              </SelectField>

              <div></div>
              <Flex>
                <Button
                  style={primaryButton}
                  width="50%"
                  onClick={getInstaDetails}
                >
                  Create Connection
                </Button>
                <Button
                  onClick={cancelconnection}
                  width="50%"
                  style={primaryButton}
                >
                  {" "}
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </View>
        <View></View>
      </Grid>
    </div>
  );
};
export default Facebook;
