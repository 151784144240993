import React, { useEffect, useState } from 'react'
import {
  View,
  Flex,
  Image,
  SelectField,
  Icon,
  Button,
  ThemeProvider,
  Theme,
  TextField,
  SearchField,
  Text
} from '@aws-amplify/ui-react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Person3Icon from '@mui/icons-material/Person3'
import DashboardHeader from '../../layout/DashboardHeader'
import HashLoader from 'react-spinners/HashLoader'
import LoadingOverlay from 'react-loading-overlay'
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel
} from '@mui/x-data-grid'
import axios from 'axios'
import { newdashboarddatagridSx } from '../../assets/style'
import { BiEdit, BiPaperPlane } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import Badge from '@mui/material/Badge'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { ToggleButton, ToggleButtonGroup } from '@aws-amplify/ui-react'
import ChatIcon from '@mui/icons-material/Chat'
import { primaryButton, primaryButtonselect } from '../../assets/style'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import '../contentlibrary/ContentLibrary.css'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import { BsHandIndexThumb } from 'react-icons/bs'
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import VerifiedIcon from '@mui/icons-material/Verified'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  getAdStatus,
  listAdCampaigns,
  listUserInfos,
  listAdStatuses,
  listPostDetails,
  getCompanyInfo,
  getPostDetails,
  listFBLoginInfos,
  getAdSet,
  listAdSets
} from '../../graphql/queries'
import {
  deleteAdCampaign,
  deletePostDetails,
  updatePostDetails,
  createPostHistory,
  deleteAdSet,
  deleteAdDetails,
  updateAdDetails,
  createAdHistory
} from '../../graphql/mutations'
import { toast, ToastContainer } from 'react-toastify'
import { Assignment, RoomPreferencesTwoTone } from '@mui/icons-material'
import { RiEyeCloseFill } from 'react-icons/ri'
import { useRef } from 'react'
import { Auth } from 'aws-amplify'
const Newdashboard = () => {
  const navigate = useNavigate()
  const [logged, setlogged] = useState(true)
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  const [fb_userid, setfacebook_userid] = useState()
  const [fb_account_id, setfb_account_id] = useState()
  const [long_access_token, setlong_access_token] = useState()
  const [FbPageId, setFbPageId] = useState()
  const location = useLocation()
  const [ig_user_id, setig_user_id] = useState()

  const toastId = useRef(null)
  const adtoastId = useRef(null)
  const instatoastId = useRef(null)

  const [searchAd, setsearchAd] = useState()
  const [searchpost, setsearchpost] = useState()

  const fetchLogininfo = async () => {
    try {
      let filter = {
        company_id: {
          eq: localStorage.getItem('companyinfoID') // filter priority = 1  get location.state/id or
        }
      }

      const fbdata = await API.graphql({
        query: listFBLoginInfos,
        variables: { filter: filter }
      })

      const filteredFBData = fbdata.data.listFBLoginInfos.items.filter(
        item => item._deleted !== true
      )
      if (fbdata.data.listFBLoginInfos.items.length > 0) {
        setfacebook_userid(filteredFBData[0].facebook_userid)
        setfb_account_id(filteredFBData[0].ads_accountid)
        setig_user_id(filteredFBData[0].insta_business_id)
        setlong_access_token(filteredFBData[0].long_access_token)
        setFbPageId(filteredFBData[0].pageid)
        //  console.log(facebook_userid);

        // alert("Facebook Login Success");
        // -----------------------------------------------------------------------------------AdsetCalls--------------------------------------------------------------------------------------------------------------
        // GetAdset();
        //  CreateAdset();
        // --------------------------------------------------------------------------------------END--------------------------------------------------------------------------------------------------------------
      }
    } catch (err) {
      // alert("not it is");
      console.log('error fetching actors' + err)
    }
  }

  useEffect(() => {
    fetchLogininfo()
    getAdDataList()
    getPostDataList()
    if (localStorage.getItem('role_id') != '1') {
      getCompName()
    }
    if (location.state != undefined) {
      setCompanyName(location.state.comp_ame)
    }
  }, [location.state])

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white'
  }

  const getCompName = async () => {
    await API.graphql(
      graphqlOperation(getCompanyInfo, {
        id: localStorage.getItem('companyinfoID')
      })
    ).then(x => {
      setCompanyName(x.data.getCompanyInfo.comp_name)
    })
  }

  const [postRowDetails, setpostRowDetails] = useState([])
  const [CompanyName, setCompanyName] = useState()

  const getPostDataList = async () => {
    try {
      setLoader(true)
      let filter = {
        companyinfoID: { eq: localStorage.getItem('companyinfoID') }
      }
      const listpost = await API.graphql({
        query: listPostDetails,
        variables: { filter: filter, limit: 100000 }
      })
      console.log('Listpost', listpost)
      //
      let temp = listpost.data.listPostDetails.items.filter(
        item => item._deleted !== true || item._deleted == null
      )
      temp.map(item => {
        if (
          item.PostHistories.items.sort(function (a, b) {
            return a._lastChangedAt - b._lastChangedAt
          }).length > 0
        ) {
          if (item.post_status == '4') {
            item.disabled = true
          } else {
            item.disabled = false
          }
          // // Check
          if (item.post_status == '4' || item.post_status == '1') {
            item.unpublish = true
          } else {
            item.unpublish = false
          }
          /////approve//////

          //Admin
          if (localStorage.getItem('role_id') === '6') {
            if (item.post_status == '1' || item.post_status == '4') {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }

          //content currator
          if (localStorage.getItem('role_id') === '2') {
            if (
              item.post_status == '1' ||
              item.post_status == '2' ||
              item.post_status == '3' ||
              item.post_status == '4'
            ) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }
          //Approver
          if (localStorage.getItem('role_id') === '5') {
            if (
              item.post_status == '1' ||
              item.post_status == '3' ||
              item.post_status == '4' ||
              item.post_status == '5' ||
              item.post_status == '6'
            ) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }
          //Publisher
          if (localStorage.getItem('role_id') === '4') {
            if (
              item.post_status == '1' ||
              item.post_status == '2' ||
              item.post_status == '4' ||
              item.post_status == '5' ||
              item.post_status == '6'
            ) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }

          item.assigned_to_userid = item.PostHistories.items
            .sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt
            })
            .reverse()[0].assigned_to_userid
          item.assigned_by_userid = item.PostHistories.items
            .sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt
            })
            .reverse()[0].assigned_by_userid
        }
      })
      let filterforuser = []

      if (
        localStorage.getItem('role_id') !== '1'
        // && localStorage.getItem("role_id") !== "3" &&
        // localStorage.getItem("role_id") !== "6"
      ) {
        filterforuser = temp.filter(
          item => item.companyinfoID == localStorage.getItem('companyinfoID')
          // item.assigned_to_userid == localStorage.getItem("userid") ||
          // item.assigned_by_userid == localStorage.getItem("userid")
        )

        // .sort(function (a, b) {
        //   return a._lastChangedAt - b._lastChangedAt;
        // })
        // .reverse();
      } else {
        filterforuser = temp
        console.log('filterforuser1', filterforuser)
        // .sort(function (a, b) {
        //   return a._lastChangedAt - b._lastChangedAt;
        // })
        // .reverse();
      }

      filterforuser.map(async arr => {
        arr.userList = await getUserInfo()
        arr.adStatus = await getadStatus()
      })

      setTimeout(() => {
        filterforuser.map(x => {
          if (x.userList) {
            if (x.assigned_to_userid) {
              if (
                x.userList.filter(item => item.id == x.assigned_to_userid)[0]
              ) {
                x.assigned_to_userid =
                  x.assigned_to_userid +
                  '~' +
                  x.userList.filter(item => item.id == x.assigned_to_userid)[0]
                    .role_id
              }
            } else {
              if (x.assigned_by_userid) {
                if (
                  x.userList.filter(item => item.id == x.assigned_by_userid)[0]
                ) {
                  x.assigned_by_userid =
                    x.assigned_by_userid +
                    '~' +
                    x.userList.filter(
                      item => item.id == x.assigned_by_userid
                    )[0].role_id
                }
              }
            }
          }
        })
        setsearchpost(filterforuser)
        setpostRowDetails(filterforuser)
        setLoader(false)
      }, 1500)
    } catch {
      setLoader(false)
    }
  }

  const getUserInfo = async () => {
    let filter = {
      companyinfoID: { eq: localStorage.getItem('companyinfoID') }
    }
    const ListUsrInfo = await API.graphql({
      query: listUserInfos,
      variables: { limit: 10000, filter: filter }
    })
    setuserinfoList(
      ListUsrInfo.data.listUserInfos.items.filter(
        item => item._deleted !== true || item._deleted == null
      )
    )

    // if (localStorage.getItem("role_id") === "2") {
    //   return ListUsrInfo.data.listUserInfos.items.filter(
    //     (item) =>
    //       (item._deleted !== true || item._deleted == null) &&
    //       (item.role_id == 5 || item.role_id == 2)
    //   );
    // }
    // if (localStorage.getItem("role_id") === "5") {
    //   return ListUsrInfo.data.listUserInfos.items.filter(
    //     (item) =>
    //       (item._deleted !== true || item._deleted == null) &&
    //       (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
    //   );
    // }
    // if (localStorage.getItem("role_id") === "4") {
    //   return ListUsrInfo.data.listUserInfos.items.filter(
    //     (item) =>
    //       (item._deleted !== true || item._deleted == null) &&
    //       (item.role_id == 4 || item.role_id == 5 || item.role_id == 2)
    //   );
    // } else {
    return ListUsrInfo.data.listUserInfos.items.filter(
      item => item._deleted !== true || item._deleted == null
    )
    //  }
  }

  const getadStatus = async () => {
    const getStatus = await API.graphql({ query: listAdStatuses })
    setadStatus(getStatus.data.listAdStatuses.items)
    // if (localStorage.getItem("role_id") === "2") {
    //   return getStatus.data.listAdStatuses.items.filter(
    //     (item) =>
    //       item.status_id == 2 || item.status_id == 6 || item.status_id == 5
    //   );
    // }
    // if (localStorage.getItem("role_id") === "5") {
    //   return getStatus.data.listAdStatuses.items.filter(
    //     (item) =>
    //       item.status_id == 3 ||
    //       item.status_id == 5 ||
    //       item.status_id == 1 ||
    //       item.status_id == 2 ||
    //       item.status_id == 6
    //   );
    // }
    // if (localStorage.getItem("role_id") === "4") {
    //   return getStatus.data.listAdStatuses.items.filter(
    //     (item) =>
    //       item.status_id == 3 ||
    //       item.status_id == 6 ||
    //       item.status_id == 5 ||
    //       item.status_id == 1 ||
    //       item.status_id == 2 ||
    //       item.status_id == 4
    //   );
    // } else {
    return getStatus.data.listAdStatuses.items
    //  }
  }

  const getAdDataList = async () => {
    let filter = {}
    setLoader(true)
    try {
      if (localStorage.getItem('role_id') != '1') {
        filter = {
          companyinfoID: { eq: localStorage.getItem('companyinfoID') }
        }
      }

      const listOfItem = await API.graphql({
        query: listAdCampaigns,
        variables: { limit: 10000, filter: filter }
      })
      let tempArr = []
      let tempJSON = {}

      console.log(listOfItem, 'listOfItem')

      listOfItem.data.listAdCampaigns.items
        .filter(item => item._deleted !== true || item._deleted == null)
        .sort(function (a, b) {
          return a._lastChangedAt - b._lastChangedAt
        })
        .reverse()
        .map(x => {
          if (x.AdSets.items.length > 0) {
            x.AdSets.items
              .filter(item => item._deleted !== true || item._deleted == null)
              .map(y => {
                if (y.AdDetails.items.length > 0) {
                  y.AdDetails.items
                    .filter(
                      item =>
                        (item._deleted !== true || item._deleted == null) &&
                        item._ad_name != ''
                    )
                    .map(z => {
                      // tempJSON = { ...tempJSON, campaign_name: x.campaign_name, id: x.id, budget_amount: y.budget_amount, budget_type: y.budget_type, schedule_start_time: y.schedule_start_time, schedule_start_date: y.schedule_start_date, schedule_end_time: y.schedule_end_time, schedule_end_date: y.schedule_end_date, adset_name: y.adset_name, ad_name: z.ad_name, media_file_path: z.media_file_path, lastchanged: x._lastChangedAt, version: x._version }
                      tempArr = [
                        ...tempArr,
                        {
                          campaign_name: x.campaign_name,
                          AdHistory: z.AdHistories,
                          adset_id: y.adset_id,
                          creative_id: z.fb_ad_id,
                          id: x.id,
                          budget_amount: y.budget_amount,
                          budget_type: y.budget_type,
                          schedule_start_time: y.schedule_start_time,
                          schedule_start_date: y.schedule_start_date,
                          schedule_end_time: y.schedule_end_time,
                          schedule_end_date: y.schedule_end_date,
                          adset_name: y.adset_name,
                          ad_name: z.ad_name,
                          media_file_path: z.media_file_path,
                          ad_id: z.id,
                          lastchanged: x._lastChangedAt,
                          adlastchanged: z._lastChangedAt,
                          companyid: x.companyinfoID,
                          version: z._version,
                          AdStatus: z.ad_status
                        }
                      ]
                    })
                }
              })
          }
        })

      // let temp = tempArr.AdHistory.items.filter(
      //   (item) => item._deleted !== true || item._deleted == null
      // );

      tempArr.map(item => {
        if (item.AdHistory.items.length > 0) {
          if (item.AdStatus == '4') {
            item.disabled = true
          } else {
            item.disabled = false
          }
          // // Check
          // if (item.post_status == "4" || item.post_status == "1") {
          //   item.unpublish = true;
          // } else {
          //   item.unpublish = false;
          // }

          ///////////approve//////////

          //Admin
          if (localStorage.getItem('role_id') === '6') {
            if (item.AdStatus == '1' || item.AdStatus == '4') {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }

          //content currator
          if (localStorage.getItem('role_id') === '2') {
            if (
              item.AdStatus == '1' ||
              item.AdStatus == '2' ||
              item.AdStatus == '3' ||
              item.AdStatus == '4'
            ) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }
          //Approver
          if (localStorage.getItem('role_id') === '5') {
            if (
              item.AdStatus == '1' ||
              item.AdStatus == '3' ||
              item.AdStatus == '4' ||
              item.AdStatus == '5' ||
              item.AdStatus == '6'
            ) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }
          //Publisher
          if (localStorage.getItem('role_id') === '4') {
            if (
              item.AdStatus == '1' ||
              item.AdStatus == '2' ||
              item.AdStatus == '4' ||
              item.AdStatus == '5' ||
              item.AdStatus == '6'
            ) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          }

          item.assigned_to_userid = item.AdHistory.items
            .sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt
            })
            .reverse()[0].assigned_to_userid

          item.assigned_by_userid = item.AdHistory.items
            .sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt
            })
            .reverse()[0].assigned_by_userid
        }
      })
      let filterforuser = []
      if (localStorage.getItem('role_id') !== '1') {
        // localStorage.getItem("role_id") !== "1" &&
        // localStorage.getItem("role_id") !== "3" &&
        // localStorage.getItem("role_id") !== "6"
        filterforuser = tempArr.filter(
          item => item.companyid == localStorage.getItem('companyinfoID')
          // item.assigned_to_userid == localStorage.getItem("userid") ||
          // item.assigned_by_userid == localStorage.getItem("userid")
        )
      } else {
        console.log('else', tempArr)
        filterforuser = [...tempArr]
      }
      filterforuser.map(async arr => {
        arr.userList = await getUserInfo()
        arr.adStatus = await getadStatus()
      })
      setTimeout(() => {
        filterforuser.map(x => {
          if (x.userList) {
            if (x.assigned_to_userid) {
              if (
                x.userList.filter(item => item.id == x.assigned_to_userid)[0]
              ) {
                x.assigned_to_userid =
                  x.assigned_to_userid +
                  '~' +
                  x.userList.filter(item => item.id == x.assigned_to_userid)[0]
                    .role_id
              }
            } else if (x.assigned_by_userid) {
              if (
                x.userList.filter(item => item.id == x.assigned_by_userid)[0]
              ) {
                x.assigned_by_userid =
                  x.assigned_by_userid +
                  '~' +
                  x.userList.filter(item => item.id == x.assigned_by_userid)[0]
                    .role_id
              }
            }
          }
        })

        let assignedusersfilter = []
        filterforuser.map(c => {
          if (c.AdHistory.items.length > 0) {
          }
          {
            if (
              c.AdHistory.items.filter(
                d =>
                  d.assigned_by_userid == localStorage.getItem('userid') ||
                  d.assigned_to_userid == localStorage.getItem('userid')
              ).length > 0
            ) {
              if (
                localStorage.getItem('role_id') === '5' ||
                localStorage.getItem('role_id') === '4'
              ) {
                ;[c].map(e => {
                  if (localStorage.getItem('role_id') === '5') {
                    if (e.AdStatus != '6') {
                      assignedusersfilter.push(e)
                    }
                  }

                  if (localStorage.getItem('role_id') === '4') {
                    if (e.AdStatus != '6' || e.AdStatus != '2') {
                      assignedusersfilter.push(e)
                    }
                  }
                })
              } else {
                assignedusersfilter.push(c)
              }
            }
          }
        })

        // if(assignedusersfilter)

        console.log(assignedusersfilter, 'assignedusersfilter')

        if (assignedusersfilter.length > 0) {
          assignedusersfilter
            .sort(function (a, b) {
              return a.adlastchanged - b.adlastchanged
            })
            .reverse()
        }
        // console.log(assignedusersfilter,"filterforuser");
        setlistofCamopaignname(assignedusersfilter)
        setsearchAd(assignedusersfilter)
        setLoader(false)
      }, 1000)
      setlistofCamopaignname(listOfItem.data.listAdCampaigns)
    } catch (err) {
      setLoader(false)
      console.log('error fetching actors', err)
    }
  }

  const IconArrowDropDown = () => {
    return <Icon pathData='M7 10L12 15L17 10H7Z' ariaLabel='Down arrow' />
  }
  const [loader, setLoader] = useState(false)

  const [listofCamopaignname, setlistofCamopaignname] = useState([])
  const [pagelimit, setpagelimit] = useState(5)
  const [postpagelimit, setpostpagelimit] = useState(5)
  const [userinfoList, setuserinfoList] = useState()
  const [adStatus, setadStatus] = useState()

  const navigateManageAds = params => {
    if (params.row.disabled) {
      if (
        localStorage.getItem('role_id') === '2' &&
        (params.row.AdStatus === '2' ||
          params.row.AdStatus === '6' ||
          params.row.AdStatus === '5')
      ) {
        navigate('/managecontent', {
          state: {
            id: params.row.id,
            Assigned_user: params.row.assigned_to_userid
              ? params.row.assigned_to_userid
              : params.row.assigned_by_userid,
            AdState: params.row.AdStatus,
            key: 'Editableforcc'
          }
        })
      } else {
        navigate('/managecontent', {
          state: {
            id: params.row.id,
            Assigned_user: params.row.assigned_to_userid
              ? params.row.assigned_to_userid
              : params.row.assigned_by_userid,
            AdState: params.row.AdStatus,
            key: 'disabled'
          }
        })
      }
    } else {
      navigate('/managecontent', {
        state: {
          id: params.row.id,
          Assigned_user: params.row.assigned_to_userid
            ? params.row.assigned_to_userid
            : params.row.assigned_by_userid,
          AdState: params.row.AdStatus
        }
      })
    }
  }

  const renderAssignButtonAd = params => {
    console.log(params.row.assigned_to_userid, 'params.row.assigned_to_userid')
    console.log(params.row.assigned_by_userid, 'params.row.assigned_by_userid')
    return (
      <div>
        <Flex width={'100%'}>
          <SelectField
            labelHidden
            icon={<IconArrowDropDown />}
            disabled={params.row.disabled ? true : false}
            iconColor='black'
            onChange={e => {
              params.row.assigned_to_userid = e.target.value
            }}
            value={
              params.row.assigned_to_userid
                ? params.row.assigned_to_userid
                : params.row.assigned_by_userid
            }
          >
            {params.row.userList
              ? params.row.userList.map(x => {
                  return (
                    <option
                      disabled={
                        localStorage.getItem('role_id') == '2' &&
                        (x.role_id == 5 || x.role_id == 2)
                          ? false
                          : localStorage.getItem('role_id') == '5' &&
                            (x.role_id == 5 || x.role_id == 2 || x.role_id == 4)
                          ? false
                          : localStorage.getItem('role_id') == '4' &&
                            (x.role_id == 2 || x.role_id == 4 || x.role_id == 5)
                          ? false
                          : true
                      }
                      value={x.id + '~' + x.role_id}
                    >
                      {x.user_fname + ' ' + x.user_lname}
                    </option>
                  )
                })
              : ''}
          </SelectField>
        </Flex>
      </div>
    )
  }

  const renderAssignButton = params => {
    return (
      <div>
        <Flex width={'100%'}>
          <SelectField
            labelHidden
            onChange={e => {
              params.row.assigned_to_userid = e.target.value
            }}
            disabled={params.row.disabled}
            icon={<IconArrowDropDown />}
            iconColor='black'
            value={
              params.row.assigned_to_userid
                ? params.row.assigned_to_userid
                : params.row.assigned_by_userid
            }
          >
            {params.row.userList
              ? params.row.userList.map(x => {
                  return (
                    <option
                      disabled={
                        localStorage.getItem('role_id') == '2' &&
                        (x.role_id == 5 || x.role_id == 2)
                          ? false
                          : localStorage.getItem('role_id') == '5' &&
                            (x.role_id == 5 || x.role_id == 2 || x.role_id == 4)
                          ? false
                          : localStorage.getItem('role_id') == '4' &&
                            (x.role_id == 2 || x.role_id == 4 || x.role_id == 5)
                          ? false
                          : true
                      }
                      value={x.id + '~' + x.role_id}
                    >
                      {x.user_fname + ' ' + x.user_lname}
                    </option>
                  )
                })
              : ''}
          </SelectField>
        </Flex>
      </div>
    )
  }

  const renderContent = params => {
    return (
      <div>
        <Flex width={'100%'}>
          <Text>{params.row.post_message ? params.row.post_message : ''}</Text>
        </Flex>
      </div>
    )
  }

  const renderBudget = params => {
    return (
      <div>
        <Flex width={'100%'}>
          <View>
            <Text>{params.row.budget_type ? params.row.budget_type : ''}</Text>
            <Text color='grey'>
              ${params.row.budget_amount ? params.row.budget_amount : ''}
            </Text>
          </View>
        </Flex>
      </div>
    )
  }

  const renderSchedulepost = params => {
    return (
      <div>
        <Flex width={'100%'} direction='column' gap='0.4em'>
          <View>
            <Text>
              <span style={{ color: 'grey', marginLeft: '0.5em' }}>
                {params.row.post_schedule_timestamp
                  ? new Date(
                      params.row.post_schedule_timestamp * 1000
                    ).toDateString()
                  : '-'}{' '}
                {params.row.post_schedule_timestamp
                  ? new Date(
                      params.row.post_schedule_timestamp * 1000
                    ).toLocaleTimeString()
                  : '-'}
              </span>
            </Text>
          </View>
          <View></View>
        </Flex>
      </div>
    )
  }

  const renderScheduled = params => {
    return (
      <div>
        <Flex width={'100%'} direction='column' gap='0.4em'>
          <View>
            <Text>
              Start:{' '}
              <span style={{ color: 'grey', marginLeft: '0.5em' }}>
                {params.row.schedule_start_date
                  ? moment(params.row.schedule_start_date).format('MMM DD YYYY')
                  : ''}
              </span>
            </Text>
          </View>
          <View>
            <Text>
              End:{' '}
              <span style={{ color: 'grey', marginLeft: '1.1em' }}>
                {params.row.schedule_end_date
                  ? moment(params.row.schedule_end_date).format('MMM DD YYYY')
                  : ''}
              </span>
            </Text>
          </View>
        </Flex>
      </div>
    )
  }

  const renderStateAd = params => {
    console.log({ name: params.row.ad_name, status: params.row.AdStatus })
    return (
      <div>
        <Flex
          direction={'column'}
          gap='0.2em'
          padding={'0.5em'}
          justifyContent='center'
        >
          <View width='100%'>
            <SelectField
              value={params.row.AdStatus}
              labelHidden
              disabled={params.row.disabled}
              icon={<IconArrowDropDown />}
              iconColor='black'
              onChange={e => {
                params.row.AdStatus = e.target.value
              }}
              style={primaryButtonselect}
            >
              {params.row.adStatus
                ? params.row.adStatus.map(y => {
                    // if(y.status_id!=1){
                    return (
                      <option
                        disabled={
                          localStorage.getItem('role_id') == '2' &&
                          (y.status_id == 2 ||
                            y.status_id == 5 ||
                            y.status_id == 6)
                            ? false
                            : localStorage.getItem('role_id') == '5' &&
                              (y.status_id == 2 ||
                                y.status_id == 3 ||
                                y.status_id == 5)
                            ? false
                            : localStorage.getItem('role_id') == '4' &&
                              (y.status_id == 4 || y.status_id == 5)
                            ? false
                            : localStorage.getItem('role_id') == '6'
                            ? false
                            : true
                        }
                        value={y.status_id}
                      >
                        {y.status_name}
                      </option>
                    )
                    // }
                  })
                : ''}
              {/* <option>John</option>
              <option>Joes</option>
              <option>Charels</option>
              <option>Mark</option> */}
            </SelectField>
          </View>
          <View width='100%'>
            <Button
              backgroundColor='var(--amplify-components-button-hover-color)'
              color=' white'
              boxShadow='large'
              style={primaryButton}
              borderRadius={'2em'}
              disabled={params.row.disabled}
              size='medium'
              fontSize={{ base: '0.8em', medium: '0.8em', large: '1em' }}
              onClick={() => {
                AsssignandupdateAd(params.row)
              }}
            >
              Submit{' '}
              <Icon
                as={BsHandIndexThumb}
                fontSize={{ base: 'large', large: 'large' }}
                color='white'
                fontWeight={'bold'}
                marginLeft='0.3em'
                marginTop={'0.3em'}
              ></Icon>
            </Button>
          </View>
        </Flex>
      </div>
    )
  }

  const renderState = params => {
    return (
      <div>
        <Flex
          direction={'column'}
          gap='0.2em'
          padding={'0.5em'}
          justifyContent='center'
        >
          <View width='100%'>
            <SelectField
              value={params.row.post_status}
              disabled={params.row.disabled}
              labelHidden
              icon={<IconArrowDropDown />}
              iconColor='black'
              onChange={e => {
                params.row.post_status = e.target.value
              }}
              style={primaryButtonselect}
            >
              {params.row.adStatus
                ? params.row.adStatus.map(y => {
                    return (
                      <option
                        disabled={
                          localStorage.getItem('role_id') == '2' &&
                          (y.status_id == 2 ||
                            y.status_id == 5 ||
                            y.status_id == 6)
                            ? false
                            : localStorage.getItem('role_id') == '5' &&
                              (y.status_id == 2 ||
                                y.status_id == 3 ||
                                y.status_id == 5)
                            ? false
                            : localStorage.getItem('role_id') == '4' &&
                              (y.status_id == 4 || y.status_id == 5)
                            ? false
                            : localStorage.getItem('role_id') == '6'
                            ? false
                            : true
                        }
                        value={y.status_id}
                      >
                        {y.status_name}
                      </option>
                    )
                  })
                : ''}
              {/* <option>John</option>
              <option>Joes</option>
              <option>Charels</option>
              <option>Mark</option> */}
            </SelectField>
          </View>
          <View width='100%'>
            <Button
              disabled={params.row.disabled}
              backgroundColor='var(--amplify-components-button-hover-color)'
              color=' white'
              boxShadow='large'
              style={primaryButton}
              borderRadius={'2em'}
              size='medium'
              fontSize={{ base: '0.8em', medium: '0.8em', large: '1em' }}
              onClick={() => {
                Asssignandupdatepost(params.row)
              }}
            >
              Submit{' '}
              <Icon
                as={BsHandIndexThumb}
                fontSize={{ base: 'large', large: 'large' }}
                color='white'
                fontWeight={'bold'}
                marginLeft='0.3em'
                marginTop={'0.3em'}
              ></Icon>
            </Button>
          </View>
        </Flex>
      </div>
    )
  }

  const AsssignandupdateAd = async row => {
    console.log(row, 'ADSETROW')
    try {
      if (row.AdStatus == '6') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '2'
            : row.assigned_by_userid.split('~')[1] == '2'
        ) {
          let updateobj = {
            id: row.ad_id,
            ad_status: row.AdStatus,
            _version: row.version
          }
          // console.log(updateobj, "updateobj")

          let createHistoryObj = {
            ad_status_id: row.AdStatus,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            addetailsID: row.ad_id
          }

          await API.graphql(
            graphqlOperation(updateAdDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createAdHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Ad Assigned to Content Curator')
              setTimeout(() => {
                getAdDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Content Curator can be Edit')
        }
      } else if (row.AdStatus == '2') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '5'
            : row.assigned_by_userid.split('~')[1] == '5'
        ) {
          let updateobj = {
            id: row.ad_id,
            ad_status: row.AdStatus,
            _version: row.version
          }

          let createHistoryObj = {
            ad_status_id: row.AdStatus,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            addetailsID: row.ad_id
          }

          await API.graphql(
            graphqlOperation(updateAdDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createAdHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Ad Assigned to Approver')

              setTimeout(() => {
                getAdDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Approver can be approved')
        }
      } else if (row.AdStatus == '3') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '4'
            : row.assigned_by_userid.split('~')[1] == '4'
        ) {
          let updateobj = {
            id: row.ad_id,
            ad_status: row.AdStatus,
            _version: row.version
          }

          let createHistoryObj = {
            ad_status_id: row.AdStatus,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            addetailsID: row.ad_id
          }

          await API.graphql(
            graphqlOperation(updateAdDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createAdHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Ad Assigned to Publisher')
              setTimeout(() => {
                getAdDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Publisher can be published')
        }
      } else if (row.AdStatus == '5') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '2'
            : row.assigned_by_userid.split('~')[1] == '2'
        ) {
          let updateobj = {
            id: row.ad_id,
            ad_status: row.AdStatus,
            _version: row.version
          }

          let createHistoryObj = {
            ad_status_id: row.AdStatus,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            addetailsID: row.ad_id
          }

          await API.graphql(
            graphqlOperation(updateAdDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createAdHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Ad Assigned to Content Curator')
              setTimeout(() => {
                getAdDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Content Curator can be Edit')
        }
      } else if (row.AdStatus == '4') {
        if (
          (row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '4'
            : row.assigned_by_userid.split('~')[1] == '4') ||
          (row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '1'
            : row.assigned_by_userid.split('~')[1] == '1' ||
              row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '6'
            : row.assigned_by_userid.split('~')[1] == '6')
        ) {
          try {
            console.log(parseInt(row.adset_id), 'row.adset_id', row.adset_id)
            adtoastId.current = toast.loading(
              'Please wait while we are publishing your ad'
            )
            let adCreatives = {
              creative_id: row.creative_id
            }
            axios({
              method: 'post',
              url:
                'https://graph.facebook.com/v15.0/' +
                fb_account_id +
                '/ads?name=' +
                row.ad_name +
                '&adset_id=' +
                row.adset_id +
                '&creative=' +
                JSON.stringify(adCreatives) +
                '&status=PAUSED&access_token=' +
                long_access_token,

              responseType: 'stream'
            })
              .then(async responsef => {
                const obj = {
                  id: row.ad_id,
                  ad_status: '4',
                  _version: row.version,
                  Published_Ad_Id: responsef.data.id
                }
                toast.update(adtoastId.current, {
                  render: 'Ad Published Successfully',
                  type: 'success',
                  isLoading: false,
                  autoClose: 1000
                })
                await API.graphql(
                  graphqlOperation(updateAdDetails, { input: obj })
                )
                  .then(async response => {
                    const createHistoryobj = {
                      ad_status_id: '4',
                      assigned_by_userid: localStorage.getItem('userid'),
                      assigned_to_userid: null,
                      addetailsID: row.ad_id
                    }
                    await API.graphql(
                      graphqlOperation(createAdHistory, {
                        input: createHistoryobj
                      })
                    ).then(() => {})
                  })
                  .catch(() => {
                    toast.error('error when save to db')
                  })
                //set_FbAdIdtoDB(responsef.data.id);

                // UpdateinExistingAd(responsef.data.id);
                // SaveAdHistory();
                setTimeout(() => {
                  getAdDataList()
                }, 800)
              })
              .catch(async error => {
                toast.update(adtoastId.current, {
                  render: 'Something went wrong',
                  type: 'error',
                  isLoading: false,
                  autoClose: 1000
                })
                const obj = {
                  id: row.ad_id,
                  ad_status: '1',
                  _version: row.version,
                  Published_Ad_Id: null
                }

                await API.graphql(
                  graphqlOperation(updateAdDetails, { input: obj })
                )
                  .then(async response => {
                    setTimeout(() => {
                      getAdDataList()
                    }, 800)
                    const createHistoryobj = {
                      ad_status_id: '1',
                      assigned_by_userid: localStorage.getItem('userid'),
                      assigned_to_userid: null,
                      addetailsID: row.ad_id
                    }
                    await API.graphql(
                      graphqlOperation(createAdHistory, {
                        input: createHistoryobj
                      })
                    ).then(() => {})
                  })
                  .catch(() => {
                    toast.error('error when save to db')
                  })
                // toast.error("Error Publishing Ad");
              })
          } catch (e) {
            setTimeout(() => {
              getAdDataList()
            }, 800)
            console.log(e)
          }
        } else {
          toast.info('only Publisher can be publish')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const Asssignandupdatepost = async row => {
    //alert(row.post_status+row.assigned_to_userid)

    try {
      if (row.post_status == '6') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '2'
            : row.assigned_by_userid.split('~')[1] == '2'
        ) {
          let updateobj = {
            id: row.id,
            post_status: row.post_status,
            _version: row._version
          }

          let createHistoryObj = {
            post_status_id: row.post_status,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            postdetailsID: row.id
          }

          await API.graphql(
            graphqlOperation(updatePostDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createPostHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Post Assigned to Content Curator')
              setTimeout(() => {
                getPostDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Content Curator can be Edit')
        }
      } else if (row.post_status == '2') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '5'
            : row.assigned_by_userid.split('~')[1] == '5'
        ) {
          let updateobj = {
            id: row.id,
            post_status: row.post_status,
            _version: row._version
          }

          let createHistoryObj = {
            post_status_id: row.post_status,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            postdetailsID: row.id
          }

          await API.graphql(
            graphqlOperation(updatePostDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createPostHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Post Assigned to Approver')
              setTimeout(() => {
                getPostDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Approver can be approved')
        }
      } else if (row.post_status == '3') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '4'
            : row.assigned_by_userid.split('~')[1] == '4'
        ) {
          let updateobj = {
            id: row.id,
            post_status: row.post_status,
            _version: row._version
          }

          let createHistoryObj = {
            post_status_id: row.post_status,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            postdetailsID: row.id
          }

          await API.graphql(
            graphqlOperation(updatePostDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createPostHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Post Assigned to Publisher')
              setTimeout(() => {
                getPostDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Publisher can be published')
        }
      } else if (row.post_status == '5') {
        if (
          row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '2'
            : row.assigned_by_userid.split('~')[1] == '2'
        ) {
          let updateobj = {
            id: row.id,
            post_status: row.post_status,
            _version: row._version
          }

          let createHistoryObj = {
            post_status_id: row.post_status,
            assigned_by_userid: localStorage.getItem('userid'),
            assigned_to_userid: row.assigned_to_userid.split('~')[0],
            postdetailsID: row.id
          }

          await API.graphql(
            graphqlOperation(updatePostDetails, { input: updateobj })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(createPostHistory, { input: createHistoryObj })
            ).then(res => {
              toast.success('Post Assigned to Content Curator')
              setTimeout(() => {
                getPostDataList()
              }, 500)
            })
          })
        } else {
          toast.info('only Assign to Content Curator can be Edit')
        }
      } else if (row.post_status == '4') {
        if (
          (row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '4'
            : row.assigned_by_userid.split('~')[1] == '4') ||
          (row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '1'
            : row.assigned_by_userid.split('~')[1] == '1' ||
              row.assigned_to_userid
            ? row.assigned_to_userid.split('~')[1] == '6'
            : row.assigned_by_userid.split('~')[1] == '6')
        ) {
          // alert("alert",JSON.parse(row.post_channels).length)
          if (JSON.parse(row.post_channels).length > 1) {
            JSON.parse(row.post_channels).map(x => {
              if (x == 'Facebook') {
                try {
                  instatoastId.current = toast.loading(
                    'Publishing in Facebook ...'
                  )

                  axios({
                    method: 'get',
                    url:
                      'https://graph.facebook.com/v15.0/' +
                      FbPageId +
                      '?fields=access_token&access_token=' +
                      long_access_token
                  })
                    .then(data => {
                      axios({
                        method: 'post',
                        url:
                          'https://graph.facebook.com/v15.0/' +
                          FbPageId +
                          '/photos?url=' +
                          encodeURIComponent(row.media_hash_id) +
                          '&message=' +
                          row.post_message +
                          '&scheduled_publish_time=' +
                          row.post_schedule_timestamp +
                          '&published=false&access_token=' +
                          data.data.access_token
                      })
                        .then(async data => {
                          const obj = {
                            id: row.id,
                            post_status: '4',
                            _version: row._version,
                            fb_post_id: data.data.id
                          }
                          await API.graphql(
                            graphqlOperation(updatePostDetails, { input: obj })
                          )
                            .then(async response => {
                              await InstaPublish(
                                row,
                                response.data.updatePostDetails._version
                              )
                              const createHistoryobj = {
                                post_status_id: '4',
                                assigned_by_userid:
                                  localStorage.getItem('userid'),
                                assigned_to_userid: null,
                                postdetailsID: row.id
                              }
                              await API.graphql(
                                graphqlOperation(createPostHistory, {
                                  input: createHistoryobj
                                })
                              ).then(() => {})
                            })
                            .catch(() => {
                              toast.error('error when save to db')
                            })
                          //setfbpoststate(true);
                          // toast.success("Feed Posted SuccessFully");
                          toast.update(instatoastId.current, {
                            render: 'Feed Posted SuccessFully',
                            type: 'success',
                            isLoading: false,
                            autoClose: 1000
                          })
                          setTimeout(() => {
                            getPostDataList()
                          }, 1000)
                        })
                        .catch(async error => {
                          console.log('err', error)

                          toast.update(instatoastId.current, {
                            render:
                              'Check the Schedule Time, Something went wrong',
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000
                          })

                          setTimeout(() => {
                            getPostDataList()
                          }, 1000)

                          // toast.error("something went wrong")

                          const obj = {
                            id: row.id,
                            post_status: '1',
                            _version: row._version,
                            fb_post_id: ''
                            //  insta_container_id:data.data.id
                          }

                          await API.graphql(
                            graphqlOperation(updatePostDetails, { input: obj })
                          )
                            .then(async response => {
                              await InstaPublish(
                                row,
                                response.data.updatePostDetails._version
                              )
                              const createHistoryobj = {
                                post_status_id: '1',
                                assigned_by_userid:
                                  localStorage.getItem('userid'),
                                assigned_to_userid: row.assigned_to_userid
                                  ? row.assigned_to_userid.split('~')[0]
                                  : row.assigned_by_userid.split('~')[0],
                                postdetailsID: row.id
                              }
                              await API.graphql(
                                graphqlOperation(createPostHistory, {
                                  input: createHistoryobj
                                })
                              ).then(() => {})
                            })
                            .catch(() => {
                              toast.error('error when save to db')
                            })
                        })
                    })
                    .catch(async () => {
                      const obj = {
                        id: row.id,
                        post_status: '1',
                        _version: row._version

                        //  insta_container_id:data.data.id
                      }

                      await API.graphql(
                        graphqlOperation(updatePostDetails, { input: obj })
                      )
                        .then(async response => {
                          await InstaPublish(
                            row,
                            response.data.updatePostDetails._version
                          )
                          const createHistoryobj = {
                            post_status_id: '1',
                            assigned_by_userid: localStorage.getItem('userid'),
                            assigned_to_userid: row.assigned_to_userid
                              ? row.assigned_to_userid.split('~')[0]
                              : row.assigned_by_userid.split('~')[0],
                            postdetailsID: row.id
                          }
                          await API.graphql(
                            graphqlOperation(createPostHistory, {
                              input: createHistoryobj
                            })
                          ).then(() => {})
                        })
                        .catch(() => {
                          toast.error('error when save to db')
                        })

                      toast.update(instatoastId.current, {
                        render: 'something went wrong',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000
                      })
                      setTimeout(() => {
                        getPostDataList()
                      }, 1000)
                    })
                } catch (err) {
                  console.log(err)
                  toast.update(instatoastId.current, {
                    render: 'something went wrong',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000
                  })
                }
              }
              // if (x == "Instagram") {
              //   try {
              //     toastId.current = toast.loading("Publishing in Instagram...");

              //     axios({
              //       method: "get",
              //       url:
              //         "https://graph.facebook.com/v15.0/" +
              //         FbPageId +
              //         "?fields=access_token&access_token=" +
              //         long_access_token,
              //     })
              //       .then((data) => {
              //         axios({
              //           method: "post",
              //           url:
              //             "https://graph.facebook.com/v15.0/" +
              //             ig_user_id +
              //             "/media?image_url=" +
              //             encodeURIComponent(row.media_hash_id) +
              //             "&caption=" +
              //             row.post_message +
              //             "&access_token=" +
              //             data.data.access_token,
              //         })
              //           .then((get) => {
              //             axios({
              //               method: "post",
              //               url:
              //                 "https://graph.facebook.com/v15.0/" +
              //                 ig_user_id +
              //                 "/media_publish?creation_id=" +
              //                 get.data.id +
              //                 "&access_token=" +
              //                 data.data.access_token,
              //             })
              //               .then(async (d) => {
              //                 alert(d.data.id+"okbye")
              //                 const obj = {
              //                   id: row.id,
              //                   post_status: "4",
              //                   _version: row._version,
              //                   insta_post_id: d.data.id,
              //                   insta_container_id: get.data.id,
              //                 };

              //                 await API.graphql(
              //                   graphqlOperation(updatePostDetails, {
              //                     input: obj,
              //                   })
              //                 )
              //                   .then(async (response) => {
              //                     const createHistoryobj = {
              //                       post_status_id: "4",
              //                       assigned_by_userid:
              //                         localStorage.getItem("userid"),
              //                       assigned_to_userid: null,
              //                       postdetailsID: row.id,
              //                     };
              //                     await API.graphql(
              //                       graphqlOperation(createPostHistory, {
              //                         input: createHistoryobj,
              //                       })
              //                     ).then(() => {});
              //                   })
              //                   .catch(() => {
              //                     toast.error("error when save to db");
              //                   });
              //                 toast.update(toastId.current, {
              //                   render: "Succesfully Posted in IG",
              //                   type: "success",
              //                   isLoading: false,
              //                   autoClose: 1000,
              //                 });
              //               })
              //               .catch(async () => {
              //                 toast.update(toastId.current, {
              //                   render: "Something Wrong",
              //                   type: "error",
              //                   isLoading: false,
              //                   autoClose: 1000,
              //                 });

              //                 const obj = {
              //                   id: row.id,
              //                   post_status: "1",
              //                   _version: row._version,
              //                   fb_post_id: "",
              //                   insta_container_id: "",
              //                 };

              //                 await API.graphql(
              //                   graphqlOperation(updatePostDetails, {
              //                     input: obj,
              //                   })
              //                 )
              //                   .then(async (response) => {
              //                     const createHistoryobj = {
              //                       post_status_id: "1",
              //                       assigned_by_userid:
              //                         localStorage.getItem("userid"),
              //                       assigned_to_userid: row.assigned_to_userid
              //                         ? row.assigned_to_userid.split("~")[0]
              //                         : row.assigned_by_userid.split("~")[0],
              //                       postdetailsID: row.id,
              //                     };
              //                     await API.graphql(
              //                       graphqlOperation(createPostHistory, {
              //                         input: createHistoryobj,
              //                       })
              //                     ).then(() => {});
              //                   })
              //                   .catch(() => {
              //                     //toast.error("error when save to db")
              //                     toast.update(toastId.current, {
              //                       render: "error when save to db",
              //                       type: "error",
              //                       isLoading: false,
              //                       autoClose: 1000,
              //                     });
              //                   });
              //               });
              //           })
              //           .catch(async () => {
              //             //toast.error("Please Select Valid Image")
              //             toast.update(toastId.current, {
              //               render: "Please Select Valid Image",
              //               type: "error",
              //               isLoading: false,
              //               autoClose: 1000,
              //             });
              //             const obj = {
              //               id: row.id,
              //               post_status: "1",
              //               _version: row._version,
              //               fb_post_id: "",
              //               insta_container_id: "",
              //             };

              //             await API.graphql(
              //               graphqlOperation(updatePostDetails, { input: obj })
              //             )
              //               .then(async (response) => {
              //                 const createHistoryobj = {
              //                   post_status_id: "1",
              //                   assigned_by_userid:
              //                     localStorage.getItem("userid"),
              //                   assigned_to_userid: row.assigned_to_userid
              //                     ? row.assigned_to_userid.split("~")[0]
              //                     : row.assigned_by_userid.split("~")[0],
              //                   postdetailsID: row.id,
              //                 };
              //                 await API.graphql(
              //                   graphqlOperation(createPostHistory, {
              //                     input: createHistoryobj,
              //                   })
              //                 ).then(() => {});
              //               })
              //               .catch(() => {
              //                 toast.error("error when save to db");
              //               });
              //             setTimeout(() => {
              //               getPostDataList();
              //             }, 1000);
              //           });
              //       })
              //       .catch(async () => {
              //         const obj = {
              //           id: row.id,
              //           post_status: "1",
              //           _version: row._version,
              //           fb_post_id: "",
              //           insta_container_id: "",
              //         };

              //         await API.graphql(
              //           graphqlOperation(updatePostDetails, { input: obj })
              //         )
              //           .then(async (response) => {
              //             const createHistoryobj = {
              //               post_status_id: "1",
              //               assigned_by_userid: localStorage.getItem("userid"),
              //               assigned_to_userid: row.assigned_to_userid
              //                 ? row.assigned_to_userid.split("~")[0]
              //                 : row.assigned_by_userid.split("~")[0],
              //               postdetailsID: row.id,
              //             };
              //             await API.graphql(
              //               graphqlOperation(createPostHistory, {
              //                 input: createHistoryobj,
              //               })
              //             ).then(() => {});
              //           })
              //           .catch(() => {
              //             toast.error("error when save to db");
              //           });
              //         toast.update(toastId.current, {
              //           render: "something Went Wrong",
              //           type: "error",
              //           isLoading: false,
              //           autoClose: 1000,
              //         });
              //         setTimeout(() => {
              //           getPostDataList();
              //         }, 1000);
              //       });
              //   } catch (err) {
              //     toast.update(toastId.current, {
              //       render: "something went wrong",
              //       type: "error",
              //       isLoading: false,
              //       autoClose: 1000,
              //     });
              //   }
              // }

              //})
            })
          } else {
            JSON.parse(row.post_channels).map(x => {
              if (x == 'Facebook') {
                try {
                  instatoastId.current = toast.loading(
                    'Publishing in Facebook ...'
                  )

                  axios({
                    method: 'get',
                    url:
                      'https://graph.facebook.com/v15.0/' +
                      FbPageId +
                      '?fields=access_token&access_token=' +
                      long_access_token
                  })
                    .then(data => {
                      axios({
                        method: 'post',
                        url:
                          'https://graph.facebook.com/v15.0/' +
                          FbPageId +
                          '/photos?url=' +
                          encodeURIComponent(row.media_hash_id) +
                          '&message=' +
                          row.post_message +
                          '&scheduled_publish_time=' +
                          row.post_schedule_timestamp +
                          '&published=false&access_token=' +
                          data.data.access_token
                      })
                        .then(async data => {
                          const obj = {
                            id: row.id,
                            post_status: '4',
                            _version: row._version,
                            fb_post_id: data.data.id
                          }

                          await API.graphql(
                            graphqlOperation(updatePostDetails, { input: obj })
                          )
                            .then(async response => {
                              const createHistoryobj = {
                                post_status_id: '4',
                                assigned_by_userid:
                                  localStorage.getItem('userid'),
                                assigned_to_userid: null,
                                postdetailsID: row.id
                              }
                              await API.graphql(
                                graphqlOperation(createPostHistory, {
                                  input: createHistoryobj
                                })
                              ).then(() => {})
                            })
                            .catch(() => {
                              toast.error('error when save to db')
                            })
                          //setfbpoststate(true);
                          // toast.success("Feed Posted SuccessFully");
                          toast.update(instatoastId.current, {
                            render: 'Feed Posted SuccessFully',
                            type: 'success',
                            isLoading: false,
                            autoClose: 1000
                          })
                          setTimeout(() => {
                            getPostDataList()
                          }, 1000)
                        })
                        .catch(async error => {
                          console.log('err', error)

                          toast.update(instatoastId.current, {
                            render:
                              'Check the Schedule Time, Something went wrong',
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000
                          })

                          setTimeout(() => {
                            getPostDataList()
                          }, 1000)

                          // toast.error("something went wrong")

                          const obj = {
                            id: row.id,
                            post_status: '1',
                            _version: row._version,
                            fb_post_id: ''
                            //  insta_container_id:data.data.id
                          }

                          await API.graphql(
                            graphqlOperation(updatePostDetails, { input: obj })
                          )
                            .then(async response => {
                              const createHistoryobj = {
                                post_status_id: '1',
                                assigned_by_userid:
                                  localStorage.getItem('userid'),
                                assigned_to_userid: row.assigned_to_userid
                                  ? row.assigned_to_userid.split('~')[0]
                                  : row.assigned_by_userid.split('~')[0],
                                postdetailsID: row.id
                              }
                              await API.graphql(
                                graphqlOperation(createPostHistory, {
                                  input: createHistoryobj
                                })
                              ).then(() => {})
                            })
                            .catch(() => {
                              toast.error('error when save to db')
                            })
                        })
                    })
                    .catch(async () => {
                      const obj = {
                        id: row.id,
                        post_status: '1',
                        _version: row._version

                        //  insta_container_id:data.data.id
                      }

                      await API.graphql(
                        graphqlOperation(updatePostDetails, { input: obj })
                      )
                        .then(async response => {
                          const createHistoryobj = {
                            post_status_id: '1',
                            assigned_by_userid: localStorage.getItem('userid'),
                            assigned_to_userid: row.assigned_to_userid
                              ? row.assigned_to_userid.split('~')[0]
                              : row.assigned_by_userid.split('~')[0],
                            postdetailsID: row.id
                          }
                          await API.graphql(
                            graphqlOperation(createPostHistory, {
                              input: createHistoryobj
                            })
                          ).then(() => {})
                        })
                        .catch(() => {
                          toast.error('error when save to db')
                        })

                      toast.update(instatoastId.current, {
                        render: 'something went wrong',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000
                      })
                      setTimeout(() => {
                        getPostDataList()
                      }, 1000)
                    })
                } catch (err) {
                  console.log(err)
                  toast.update(instatoastId.current, {
                    render: 'something went wrong',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000
                  })
                }
              }
              if (x == 'Instagram') {
                try {
                  toastId.current = toast.loading('Publishing in Instagram...')

                  axios({
                    method: 'get',
                    url:
                      'https://graph.facebook.com/v15.0/' +
                      FbPageId +
                      '?fields=access_token&access_token=' +
                      long_access_token
                  })
                    .then(data => {
                      axios({
                        method: 'post',
                        url:
                          'https://graph.facebook.com/v15.0/' +
                          ig_user_id +
                          '/media?image_url=' +
                          encodeURIComponent(row.media_hash_id) +
                          '&caption=' +
                          row.post_message +
                          '&access_token=' +
                          data.data.access_token
                      })
                        .then(get => {
                          axios({
                            method: 'post',
                            url:
                              'https://graph.facebook.com/v15.0/' +
                              ig_user_id +
                              '/media_publish?creation_id=' +
                              get.data.id +
                              '&access_token=' +
                              data.data.access_token
                          })
                            .then(async d => {
                              // alert(d.data.id+"okbye")
                              const obj = {
                                id: row.id,
                                post_status: '4',
                                _version: row._version,
                                insta_post_id: d.data.id,
                                insta_container_id: get.data.id
                              }

                              await API.graphql(
                                graphqlOperation(updatePostDetails, {
                                  input: obj
                                })
                              )
                                .then(async response => {
                                  const createHistoryobj = {
                                    post_status_id: '4',
                                    assigned_by_userid:
                                      localStorage.getItem('userid'),
                                    assigned_to_userid: null,
                                    postdetailsID: row.id
                                  }
                                  await API.graphql(
                                    graphqlOperation(createPostHistory, {
                                      input: createHistoryobj
                                    })
                                  ).then(() => {})
                                })
                                .catch(() => {
                                  toast.error('error when save to db')
                                })
                              toast.update(toastId.current, {
                                render: 'Succesfully Posted in IG',
                                type: 'success',
                                isLoading: false,
                                autoClose: 1000
                              })
                              setTimeout(() => {
                                getPostDataList()
                              }, 1000)
                            })
                            .catch(async () => {
                              toast.update(toastId.current, {
                                render: 'Something Wrong',
                                type: 'error',
                                isLoading: false,
                                autoClose: 1000
                              })

                              const obj = {
                                id: row.id,
                                post_status: '1',
                                _version: row._version,
                                fb_post_id: '',
                                insta_container_id: ''
                              }

                              await API.graphql(
                                graphqlOperation(updatePostDetails, {
                                  input: obj
                                })
                              )
                                .then(async response => {
                                  const createHistoryobj = {
                                    post_status_id: '1',
                                    assigned_by_userid:
                                      localStorage.getItem('userid'),
                                    assigned_to_userid: row.assigned_to_userid
                                      ? row.assigned_to_userid.split('~')[0]
                                      : row.assigned_by_userid.split('~')[0],
                                    postdetailsID: row.id
                                  }
                                  await API.graphql(
                                    graphqlOperation(createPostHistory, {
                                      input: createHistoryobj
                                    })
                                  ).then(() => {})
                                })
                                .catch(() => {
                                  //toast.error("error when save to db")
                                  toast.update(toastId.current, {
                                    render: 'error when save to db',
                                    type: 'error',
                                    isLoading: false,
                                    autoClose: 1000
                                  })
                                })
                            })
                        })
                        .catch(async () => {
                          //toast.error("Please Select Valid Image")
                          toast.update(toastId.current, {
                            render: 'Please Select Valid Image',
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000
                          })
                          const obj = {
                            id: row.id,
                            post_status: '1',
                            _version: row._version,
                            fb_post_id: '',
                            insta_container_id: ''
                          }

                          await API.graphql(
                            graphqlOperation(updatePostDetails, { input: obj })
                          )
                            .then(async response => {
                              // alert("response5"+response.updatePostDetails._version)
                              const createHistoryobj = {
                                post_status_id: '1',
                                assigned_by_userid:
                                  localStorage.getItem('userid'),
                                assigned_to_userid: row.assigned_to_userid
                                  ? row.assigned_to_userid.split('~')[0]
                                  : row.assigned_by_userid.split('~')[0],
                                postdetailsID: row.id
                              }
                              await API.graphql(
                                graphqlOperation(createPostHistory, {
                                  input: createHistoryobj
                                })
                              ).then(() => {})
                            })
                            .catch(() => {
                              toast.error('error when save to db')
                            })
                          setTimeout(() => {
                            getPostDataList()
                          }, 1000)
                        })
                    })
                    .catch(async () => {
                      const obj = {
                        id: row.id,
                        post_status: '1',
                        _version: row._version,
                        fb_post_id: '',
                        insta_container_id: ''
                      }

                      await API.graphql(
                        graphqlOperation(updatePostDetails, { input: obj })
                      )
                        .then(async response => {
                          const createHistoryobj = {
                            post_status_id: '1',
                            assigned_by_userid: localStorage.getItem('userid'),
                            assigned_to_userid: row.assigned_to_userid
                              ? row.assigned_to_userid.split('~')[0]
                              : row.assigned_by_userid.split('~')[0],
                            postdetailsID: row.id
                          }
                          await API.graphql(
                            graphqlOperation(createPostHistory, {
                              input: createHistoryobj
                            })
                          ).then(() => {})
                        })
                        .catch(() => {
                          toast.error('error when save to db')
                        })
                      toast.update(toastId.current, {
                        render: 'something Went Wrong',
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000
                      })
                      setTimeout(() => {
                        getPostDataList()
                      }, 1000)
                    })
                } catch (err) {
                  toast.update(toastId.current, {
                    render: 'something went wrong',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000
                  })
                }
              }
            })
          }
        } else {
          toast.info('Only Publisher can be Published')
        }
      } else {
      }
    } catch {}
  }

  const InstaPublish = (row, _v) => {
    try {
      toastId.current = toast.loading('Publishing in Instagram...')
      axios({
        method: 'get',
        url:
          'https://graph.facebook.com/v15.0/' +
          FbPageId +
          '?fields=access_token&access_token=' +
          long_access_token
      })
        .then(data => {
          axios({
            method: 'post',
            url:
              'https://graph.facebook.com/v15.0/' +
              ig_user_id +
              '/media?image_url=' +
              encodeURIComponent(row.media_hash_id) +
              '&caption=' +
              row.post_message +
              '&access_token=' +
              data.data.access_token
          })
            .then(get => {
              axios({
                method: 'post',
                url:
                  'https://graph.facebook.com/v15.0/' +
                  ig_user_id +
                  '/media_publish?creation_id=' +
                  get.data.id +
                  '&access_token=' +
                  data.data.access_token
              })
                .then(async d => {
                  // alert(d.data.id+_v+"okbye")
                  const obj = {
                    id: row.id,
                    post_status: '4',
                    _version: _v,
                    insta_post_id: d.data.id,
                    insta_container_id: get.data.id
                  }

                  await API.graphql(
                    graphqlOperation(updatePostDetails, {
                      input: obj
                    })
                  )
                    .then(async response => {})
                    .catch(() => {
                      toast.error('error when save to db')
                    })
                  toast.update(toastId.current, {
                    render: 'Succesfully Posted in IG',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000
                  })
                })
                .catch(async () => {
                  toast.update(toastId.current, {
                    render: 'Something Wrong',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000
                  })

                  const obj = {
                    id: row.id,
                    post_status: '1',
                    _version: _v,
                    insta_container_id: ''
                  }

                  await API.graphql(
                    graphqlOperation(updatePostDetails, {
                      input: obj
                    })
                  )

                  //toast.error("error when save to db")
                  toast.update(toastId.current, {
                    render: 'error when save to db',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000
                  })
                })
            })
            .catch(async () => {
              //toast.error("Please Select Valid Image")
              toast.update(toastId.current, {
                render: 'Please Select Valid Image',
                type: 'error',
                isLoading: false,
                autoClose: 1000
              })
              const obj = {
                id: row.id,
                post_status: '1',
                _version: _v,

                insta_container_id: ''
              }

              await API.graphql(
                graphqlOperation(updatePostDetails, { input: obj })
              )

              setTimeout(() => {
                getPostDataList()
              }, 1000)
            })
        })
        .catch(async () => {
          const obj = {
            id: row.id,
            post_status: '1',
            _version: _v,
            insta_container_id: ''
          }

          await API.graphql(graphqlOperation(updatePostDetails, { input: obj }))

          toast.update(toastId.current, {
            render: 'something Went Wrong',
            type: 'error',
            isLoading: false,
            autoClose: 1000
          })
          setTimeout(() => {
            getPostDataList()
          }, 1000)
        })
    } catch (err) {
      toast.update(toastId.current, {
        render: 'something went wrong',
        type: 'error',
        isLoading: false,
        autoClose: 1000
      })
    }
  }

  const editpost = params => {
    if (params.row.disabled) {
      if (
        localStorage.getItem('role_id') === '2' &&
        (params.row.post_status === '2' ||
          params.row.post_status === '6' ||
          params.row.post_status === '5')
      ) {
        navigate('/managepost', {
          state: {
            id: params.row.id,
            Assigned_user: params.row.assigned_to_userid
              ? params.row.assigned_to_userid
              : params.row.assigned_by_userid,
            AdState: params.row.post_status,
            key: 'Editableforcc'
          }
        })
      } else {
        navigate('/managepost', {
          state: {
            id: params.row.id,
            Assigned_user: params.row.assigned_to_userid
              ? params.row.assigned_to_userid
              : params.row.assigned_by_userid,
            AdState: params.row.post_status,
            key: 'disabled'
          }
        })
      }
    } else {
      navigate('/managepost', {
        state: {
          id: params.row.id,
          Assigned_user: params.row.assigned_to_userid
            ? params.row.assigned_to_userid
            : params.row.assigned_by_userid,
          AdState: params.row.post_status
        }
      })
    }
  }

  const renderActionButtonPost = params => {
    return (
      <div>
        <Flex width={'100%'} justifyContent='center'>
          <Tooltip title='Edit'>
            <View width='50%'>
              <BorderColorIcon
                as={BiEdit}
                onClick={() => {
                  editpost(params)
                  // params.row.disabled
                  //   ? editpost(params)// toast.info("You can't edit this post")
                  //   : editpost(params);
                }}
                sx={{ color: 'Green', fontSize: '1.5em', cursor: 'pointer' }}
              />
            </View>
          </Tooltip>
          <Tooltip title='Delete'>
            <View width='50%'>
              <DeleteForeverIcon
                as={MdDelete}
                onClick={() => {
                  deletePost(params)
                }}
                sx={{ color: 'red', fontSize: '1.5em', cursor: 'pointer' }}
              />
            </View>
          </Tooltip>
        </Flex>
      </div>
    )
  }

  const deletePost = async params => {
    const deleteObj = {
      id: params.row.id,
      _version: params.row._version
    }
    await API.graphql(graphqlOperation(deletePostDetails, { input: deleteObj }))
      .then(() => {
        toast.success('Post Deleted Successfully')
        setTimeout(() => {
          getPostDataList()
        }, 200)
      })
      .catch(err => {
        toast.error('Error in deleting post')
      })
  }

  const renderActionButton = params => {
    return (
      <div>
        <Flex width={'100%'} justifyContent='center' direction={'row'}>
          <Tooltip title='Edit'>
            <View width='50%'>
              <BorderColorIcon
                as={BiEdit}
                onClick={() => {
                  navigateManageAds(params)
                  // params.row.disabled
                  //   ? toast.info("You can't edit this ad")
                  //   : navigateManageAds(params);
                }}
                sx={{ color: 'Green', fontSize: '1.5em', cursor: 'pointer' }}
              />
            </View>
          </Tooltip>
          <Tooltip title='Delete'>
            <View width='50%'>
              <DeleteForeverIcon
                as={MdDelete}
                onClick={() => {
                  deleteAd(params)
                }}
                sx={{ color: 'red', fontSize: '1.5em', cursor: 'pointer' }}
              />
            </View>
          </Tooltip>
        </Flex>
      </div>
    )
  }

  //delete Adset for deleting ad
  const deleteAd = async params => {
    console.log(params, 'deleteAd')

    try {
      axios.delete(
        'https://graph.facebook.com/v15.0/' +
          params.row.adset_id +
          '?access_token=' +
          long_access_token
      )

      let filter = {
        adset_id: {
          eq: params.row.adset_id // filter priority = 1  get location.state/id or
        }
      }

      const Adsetdata = await API.graphql({
        query: listAdSets,
        variables: { filter: filter }
      })

      let Adset_graph_id = Adsetdata.data.listAdSets.items[0].id
      let Adset_graph_version = Adsetdata.data.listAdSets.items[0]._version
      const obj1 = {
        id: Adset_graph_id,
        _version: Adset_graph_version
      }
      API.graphql(graphqlOperation(deleteAdSet, { input: obj1 }))
        .then(() => {
          const obj2 = {
            id: params.row.ad_id,
            _version: params.row.version
          }
          API.graphql(graphqlOperation(deleteAdDetails, { input: obj2 }))
        })
        .catch(() => {
          toast.error('Failed to Delete Ad')
        })

      toast.success(
        params.row.ad_name + ' ' + 'and its corresponding Adset are deleted'
      )
      setTimeout(() => {
        getAdDataList()
      }, 200)
    } catch (e) {
      console.log(e, 'e')
    }
  }

  // const deleteAdinFbandDb = async () => {
  //   axios
  //     .delete(
  //       "https://graph.facebook.com/v15.0/" +
  //         listofCamopaignname.adset_id +
  //         "&access_token=" +
  //         long_access_token
  //     )

  //     .then(function (responsef) {})
  //     .catch(function (error) {
  //       console.log(error, "error");
  //     });
  // };

  const renderChannelButton = params => {
    {
      console.log(
        'facebook',
        params,
        params.row.post_status,
        params.row.fb_post_id,
        params.row.insta_post_id
      )
      return (
        <div>
          <Flex width={'100%'} justifyContent='center'>
            {JSON.parse(params.row.post_channels).map(x => {
              if (x == 'Facebook') {
                if (params.row.unpublish) {
                  if (
                    params.row.fb_post_id != null &&
                    params.row.fb_post_id != ''
                  ) {
                    return (
                      <>
                        <FacebookIcon
                          sx={{ color: '#4267B2' }}
                          className='verify'
                        ></FacebookIcon>
                        <VerifiedIcon
                          sx={{
                            fontSize: '16px',
                            color: 'rgb(139, 195, 74)',
                            ml: '-1.5em',
                            mt: '0.9em'
                          }}
                          className='verify'
                        ></VerifiedIcon>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <FacebookIcon
                          sx={{ color: '#4267B2' }}
                          className='verify'
                        ></FacebookIcon>
                        <CancelIcon
                          sx={{
                            fontSize: '16px',
                            color: '#e91e63',
                            ml: '-1.5em',
                            mt: '0.9em'
                          }}
                        ></CancelIcon>
                      </>
                    )
                  }
                } else {
                  return (
                    <FacebookIcon
                      sx={{ color: '#4267B2' }}
                      className='verify'
                    ></FacebookIcon>
                  )
                }
              }
              if (x == 'Instagram') {
                if (params.row.unpublish) {
                  if (
                    params.row.insta_post_id != null &&
                    params.row.insta_post_id != ''
                  ) {
                    return (
                      <>
                        <InstagramIcon
                          className='instagram'
                          sx={{ marginTop: '0.1em' }}
                        />
                        <VerifiedIcon
                          sx={{
                            fontSize: '16px',
                            color: 'rgb(139, 195, 74)',
                            ml: '-1.5em',
                            mt: '0.9em'
                          }}
                          className='verify'
                        ></VerifiedIcon>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <InstagramIcon
                          className='instagram'
                          sx={{ marginTop: '0.1em' }}
                        />
                        <CancelIcon
                          sx={{
                            fontSize: '16px',
                            color: '#e91e63',
                            ml: '-1.5em',
                            mt: '0.9em'
                          }}
                        ></CancelIcon>
                      </>
                    )
                  }
                } else {
                  return (
                    <InstagramIcon
                      className='instagram'
                      sx={{ marginTop: '0.1em' }}
                    />
                  )
                }
              }
            })}

            {/* <InstagramIcon className="instagram" sx={{ marginTop: "0.1em" }} /> */}
          </Flex>
        </div>
      )
    }
  }

  const renderPreviewPost = params => {
    return (
      <div>
        <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
          <Image
            className='img-fluid rounded img-responsive'
            src={
              params.row.media_hash_id
                ? params.row.media_hash_id
                : 'http://accordelectrotechnics.in/img/product/no-preview/no-preview.png'
            }
            alt='Second slide'
            style={{
              width: '50%',
              height: '50%',
              objectFit: 'contain',
              backgroundColor: 'white',
              border: '1px solid var(--bs-border-color-translucent)',
              borderRadius: '0.5em'
            }}
          />
        </Flex>
      </div>
    )
  }

  const renderPreview = params => {
    let isvideo = ''
    if (params.row.media_file_path.indexOf('video') >= 0) {
      isvideo =
        'https://w7.pngwing.com/pngs/425/361/png-transparent-cinema-film-clapperboard-computer-icons-video-production-cine-miscellaneous-photography-logo.png'
    } else {
      isvideo =
        'http://accordelectrotechnics.in/img/product/no-preview/no-preview.png'
    }

    return (
      <div>
        <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
          <Image
            className='img-fluid rounded img-responsive'
            src={
              !params.row.media_file_path.includes('video')
                ? params.row.media_file_path
                : isvideo
            }
            alt='No Preview'
            style={{
              width: '50%',
              height: '50%',
              objectFit: 'contain',
              backgroundColor: 'white',
              border: '1px solid var(--bs-border-color-translucent)',
              borderRadius: '0.5em'
            }}
          />
        </Flex>
      </div>
    )
  }

  const columnspost = [
    {
      field: 'post_schedule_timestamp',
      headerName: 'Scheduled',
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: renderSchedulepost
    },
    {
      field: 'post_channels',
      headerName: 'Channels',
      flex: 1,
      editable: false,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderChannelButton
    },
    {
      field: 'post_message',
      headerName: 'Content',
      align: 'center',
      minWidth: 250,
      headerAlign: 'center',
      flex: 1,
      editable: false,
      renderCell: renderContent
    },
    {
      field: 'role',
      headerName: 'Preview',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      editable: false,
      align: 'center',
      renderCell: renderPreviewPost
    },
    {
      field: 'Edit',
      minWidth: 250,
      headerName: 'Assigned To',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: renderAssignButton
    },
    {
      field: 'Delete',
      headerName: 'Status',
      headerAlign: 'center',
      minWidth: 250,
      flex: 1,
      align: 'center',
      editable: false,
      renderCell: renderState
    },
    {
      field: 'hu',
      headerName: 'Actions',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      align: 'center',
      editable: false,
      renderCell: renderActionButtonPost
    }
  ]

  const columns = [
    {
      field: 'campaign_name',
      headerName: 'Campaign',
      minWidth: 150,
      flex: 1,
      editable: false
    },
    {
      field: 'Vlue',
      headerName: 'Budget',
      flex: 1,
      minWidth: 120,
      editable: false,
      renderCell: renderBudget
    },
    {
      field: 'Phone',
      headerName: 'Scheduled',
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: renderScheduled
    },
    {
      field: 'adset_name',
      headerName: 'Ad set',
      flex: 1,
      minWidth: 140,
      editable: false
    },
    {
      field: 'ad_name',
      headerName: 'Ad',
      minWidth: 140,
      flex: 1,
      editable: false
    },
    {
      field: 'role',
      headerName: 'Preview',
      flex: 1,
      headerAlign: 'center',
      minWidth: 130,
      editable: false,
      align: 'center',
      renderCell: renderPreview
    },
    {
      field: 'Edit',
      headerName: 'Assigned To',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
      editable: false,
      renderCell: renderAssignButtonAd
    },
    {
      field: 'Delete',
      headerName: 'State',
      headerAlign: 'center',
      flex: 1,
      minWidth: 180,
      align: 'center',
      editable: false,
      renderCell: renderStateAd
    },
    {
      field: 'hu',
      headerName: 'Actions',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      minWidth: 80,
      editable: false,
      renderCell: renderActionButton
    }
  ]
  const [value, setValue] = useState('Ad')
  const theme: Theme = {
    name: 'toggleButton-theme',
    tokens: {
      components: {
        togglebutton: {
          borderColor: { value: 'rgb(104, 2, 80)' },
          color: { value: 'black' },
          _hover: {
            backgroundColor: { value: 'rgb(104, 2, 80)' },
            color: { value: 'white' }
          },
          _focus: {
            color: { value: 'white' },
            borderColor: { value: 'rgb(104, 2, 80)' }
          },
          _active: {
            backgroundColor: { value: 'rgb(104, 2, 80)' },
            color: { value: 'white' },
            borderColor: { value: 'rgb(104, 2, 80)' }
          },
          _pressed: {
            backgroundColor: { value: 'rgb(104, 2, 80)' },
            color: { value: 'white' },
            borderColor: { value: 'rgb(104, 2, 80)' },
            _hover: {
              backgroundColor: { value: 'rgb(104, 2, 80)' },
              color: { value: 'white' }
            }
          },
          primary: {
            backgroundColor: { value: 'rgb(104, 2, 80)' }
          }
        }
      }
    }
  }
  const [searchvaluead, setsearchvaluead] = useState('')
  const searchInAdList = e => {
    setsearchvaluead(e)
    setlistofCamopaignname(
      searchAd.filter(
        item =>
          item.ad_name.includes(e) ||
          item.adset_name.includes(e) ||
          item.campaign_name.includes(e)
      )
    )
  }

  const searchInPost = e => {
    setsearchvaluepost(e)
    setpostRowDetails(searchpost.filter(item => item.post_message.includes(e)))
  }

  const [searchvaluepost, setsearchvaluepost] = useState('')

  return (
    <div>
      {loader ? (
        <LoadingOverlay
          className='loadoverlay'
          active={loader}
          spinner={
            <HashLoader
              color={'white'}
              className='loadoverlay1'
              loading={true}
              cssOverride={override}
              size={80}
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          }
        >
          <Flex direction='row' gap='0em'>
            <DashboardHeader
              ndb={true}
              mc={false}
              cl={false}
              mp={false}
              db={false}
            ></DashboardHeader>
            <ToastContainer autoClose={1500} limit={0} />
          </Flex>
          <Flex direction={'column'}>
            <View width='100%' padding='2em' paddingBottom='0em'>
              <Flex
                direction={{
                  base: 'column',
                  small: 'row',
                  medium: 'row',
                  large: 'row'
                }}
              >
                <View
                  width={{
                    base: '100%',
                    small: '50%',
                    medium: '60%',
                    large: '70%'
                  }}
                >
                  <ThemeProvider theme={theme} colorMode='light'>
                    <ToggleButtonGroup
                      isExclusive
                      border='none'
                      margin='0em'
                      tooltip='Browse From Local'
                      value={value}
                      onChange={value => {
                        setValue(value)
                        setsearchvaluepost('')
                        setsearchvaluead('')
                      }}
                      isSelectionRequired
                      justifyContent={{
                        base: 'center',
                        small: 'left',
                        medium: 'Left',
                        large: 'left'
                      }}
                    >
                      <ToggleButton variation='primary' value='Ad'>
                        Ad{' '}
                        <CampaignOutlinedIcon style={{ marginLeft: '0.3em' }} />
                      </ToggleButton>
                      <ToggleButton variation='primary' value='Post'>
                        Post <Icon as={BiPaperPlane} marginLeft='0.2em' />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </View>
                {value == 'Ad' ? (
                  <View
                    width={{
                      base: '100%',
                      small: '50%',
                      medium: '40%',
                      large: '30%'
                    }}
                    justifyContent={{
                      base: 'center',
                      small: 'right',
                      medium: 'right',
                      large: 'right'
                    }}
                  >
                    <SearchField
                      label='Search'
                      placeholder='Search here...'
                      value={searchvaluead}
                      onClear={() => {
                        getAdDataList()
                        setsearchvaluead('')
                      }}
                      onChange={e => {
                        searchInAdList(e.target.value)
                      }}
                    />
                  </View>
                ) : (
                  <View
                    width={{
                      base: '100%',
                      small: '100%',
                      medium: '40%',
                      large: '30%'
                    }}
                    justifyContent={{
                      base: 'center',
                      small: 'center',
                      medium: 'right',
                      large: 'right'
                    }}
                  >
                    <SearchField
                      label='Search'
                      placeholder='Search here...'
                      value={searchvaluepost}
                      onClear={() => {
                        getPostDataList()
                        setsearchvaluepost('')
                      }}
                      onChange={e => {
                        searchInPost(e.target.value)
                      }}
                    />
                  </View>
                )}
              </Flex>
            </View>
            <View width='100%' paddingLeft='2em'>
              <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link
                  underline='hover'
                  color='rgb(183, 31, 57)'
                  fontSize={'1em'}
                >
                  <Person3Icon
                    style={{
                      marginRight: '0.1em',
                      marginTop: '-5px',
                      fontSize: '1.3em'
                    }}
                  />{' '}
                  {CompanyName !== undefined ? CompanyName : 'Company Name'}
                </Link>
                <Link
                  underline='hover'
                  color='rgb(183, 31, 57)'
                  fontSize={'1em'}
                >
                  {value == 'Ad' ? (
                    <CampaignOutlinedIcon
                      style={{
                        marginRight: '0.1em',
                        marginTop: '-5px',
                        fontSize: '1.4em'
                      }}
                    />
                  ) : (
                    <Icon
                      as={BiPaperPlane}
                      style={{
                        marginRight: '0.1em',
                        marginTop: '-5px',
                        fontSize: '1.2em'
                      }}
                    />
                  )}{' '}
                  {value == 'Ad' ? 'Manage Ads' : 'Manage Post'}
                </Link>
              </Breadcrumbs>
            </View>
            {value == 'Ad' ? (
              <View
                width='100%'
                height='600px'
                padding='2em'
                paddingBottom='0em'
                paddingTop='0em'
              >
                {/* <TextField placeholder="Search" value={searchvaluead} onChange={(e) => {
              searchInAdList(e.target.value)
            }} /> */}
                <DataGrid
                  width='100%'
                  headerHeight={50}
                  getRowHeight={() => 'auto'}
                  sx={newdashboarddatagridSx}
                  columns={columns}
                  pageSize={pagelimit}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageSizeChange={newpagesize => {
                    setpagelimit(newpagesize)
                  }}
                  pagination={true}
                  rows={listofCamopaignname}
                />
              </View>
            ) : (
              <View
                width='100%'
                height='600px'
                padding='2em'
                paddingBottom='0em'
                paddingTop='0em'
              >
                {/* <TextField placeholder="Search" value={searchvaluepost} onChange={(e) => { searchInPost(e.target.value) }} /> */}
                <DataGrid
                  width='100%'
                  headerHeight={50}
                  getRowHeight={() => 'auto'}
                  sx={newdashboarddatagridSx}
                  columns={columnspost}
                  rows={postRowDetails}
                  pageSize={postpagelimit}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageSizeChange={newpagesize => {
                    setpostpagelimit(newpagesize)
                  }}
                  pagination={true}
                />
              </View>
            )}
          </Flex>
        </LoadingOverlay>
      ) : (
        <>
          {' '}
          <Flex direction='row' gap='0em'>
            <DashboardHeader
              ndb={true}
              mc={false}
              cl={false}
              mp={false}
              db={false}
            ></DashboardHeader>
            <ToastContainer autoClose={1500} limit={0} />
          </Flex>
          <Flex direction={'column'}>
            <View width='100%' padding='2em' paddingBottom='0em'>
              <Flex
                direction={{
                  base: 'column',
                  small: 'row',
                  medium: 'row',
                  large: 'row'
                }}
              >
                <View
                  width={{
                    base: '100%',
                    small: '50%',
                    medium: '60%',
                    large: '70%'
                  }}
                >
                  <ThemeProvider theme={theme} colorMode='light'>
                    <ToggleButtonGroup
                      isExclusive
                      border='none'
                      margin='0em'
                      tooltip='Browse From Local'
                      value={value}
                      onChange={value => {
                        setValue(value)
                        setsearchvaluepost('')
                        setsearchvaluead('')
                      }}
                      isSelectionRequired
                      justifyContent={{
                        base: 'center',
                        small: 'left',
                        medium: 'Left',
                        large: 'left'
                      }}
                    >
                      <ToggleButton variation='primary' value='Ad'>
                        Ad{' '}
                        <CampaignOutlinedIcon style={{ marginLeft: '0.3em' }} />
                      </ToggleButton>
                      <ToggleButton variation='primary' value='Post'>
                        Post <Icon as={BiPaperPlane} marginLeft='0.2em' />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </View>
                {value == 'Ad' ? (
                  <View
                    width={{
                      base: '100%',
                      small: '50%',
                      medium: '40%',
                      large: '30%'
                    }}
                    justifyContent={{
                      base: 'center',
                      small: 'right',
                      medium: 'right',
                      large: 'right'
                    }}
                  >
                    <SearchField
                      label='Search'
                      placeholder='Search here...'
                      value={searchvaluead}
                      onClear={() => {
                        getAdDataList()
                        setsearchvaluead('')
                      }}
                      onChange={e => {
                        searchInAdList(e.target.value)
                      }}
                    />
                  </View>
                ) : (
                  <View
                    width={{
                      base: '100%',
                      small: '100%',
                      medium: '40%',
                      large: '30%'
                    }}
                    justifyContent={{
                      base: 'center',
                      small: 'center',
                      medium: 'right',
                      large: 'right'
                    }}
                  >
                    <SearchField
                      label='Search'
                      placeholder='Search here...'
                      value={searchvaluepost}
                      onClear={() => {
                        getPostDataList()
                        setsearchvaluepost('')
                      }}
                      onChange={e => {
                        searchInPost(e.target.value)
                      }}
                    />
                  </View>
                )}
              </Flex>
            </View>
            <View width='100%' paddingLeft='2em'>
              <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <Link
                  underline='hover'
                  color='rgb(183, 31, 57)'
                  fontSize={'1em'}
                >
                  <Person3Icon
                    style={{
                      marginRight: '0.1em',
                      marginTop: '-5px',
                      fontSize: '1.3em'
                    }}
                  />{' '}
                  {CompanyName !== undefined ? CompanyName : 'Company Name'}
                </Link>
                <Link
                  underline='hover'
                  color='rgb(183, 31, 57)'
                  fontSize={'1em'}
                >
                  {value == 'Ad' ? (
                    <CampaignOutlinedIcon
                      style={{
                        marginRight: '0.1em',
                        marginTop: '-5px',
                        fontSize: '1.4em'
                      }}
                    />
                  ) : (
                    <Icon
                      as={BiPaperPlane}
                      style={{
                        marginRight: '0.1em',
                        marginTop: '-5px',
                        fontSize: '1.2em'
                      }}
                    />
                  )}{' '}
                  {value == 'Ad' ? 'Manage Ads' : 'Manage Post'}
                </Link>
              </Breadcrumbs>
            </View>
            {value == 'Ad' ? (
              <View
                width='100%'
                height='600px'
                padding='2em'
                paddingBottom='0em'
                paddingTop='0em'
              >
                {/* <TextField placeholder="Search" value={searchvaluead} onChange={(e) => {
              searchInAdList(e.target.value)
            }} /> */}
                <DataGrid
                  width='100%'
                  headerHeight={50}
                  getRowHeight={() => 'auto'}
                  sx={newdashboarddatagridSx}
                  columns={columns}
                  pageSize={pagelimit}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageSizeChange={newpagesize => {
                    setpagelimit(newpagesize)
                  }}
                  pagination={true}
                  rows={listofCamopaignname}
                />
              </View>
            ) : (
              <View
                width='100%'
                height='600px'
                padding='2em'
                paddingBottom='0em'
                paddingTop='0em'
              >
                {/* <TextField placeholder="Search" value={searchvaluepost} onChange={(e) => { searchInPost(e.target.value) }} /> */}
                <DataGrid
                  width='100%'
                  headerHeight={50}
                  getRowHeight={() => 'auto'}
                  sx={newdashboarddatagridSx}
                  columns={columnspost}
                  rows={postRowDetails}
                  pageSize={postpagelimit}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageSizeChange={newpagesize => {
                    setpostpagelimit(newpagesize)
                  }}
                  pagination={true}
                />
              </View>
            )}
          </Flex>
        </>
      )}
    </div>
  )
}
export default Newdashboard
