import React, { useEffect, useState, CSSProperties } from 'react'
import DashboardHeader from '../../layout/DashboardHeader'
import { Expander, ExpanderItem, Flex } from '@aws-amplify/ui-react'
import { ImPencil } from 'react-icons/im'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import {
  Icon,
  Image,
  SelectField,
  SearchField,
  ScrollView,
  FieldGroupIcon,
  FieldGroupIconButton
} from '@aws-amplify/ui-react'
import { RiDeleteBin5Line, RiDraftLine } from 'react-icons/ri'
import { VscOpenPreview } from 'react-icons/vsc'
import { useRef } from 'react'
import {
  Button,
  ButtonGroup,
  Grid,
  View,
  useTheme,
  Link,
  TextField,
  Text,
  Divider
} from '@aws-amplify/ui-react'
import { BiUpload, BiUserCheck } from 'react-icons/bi'
import {
  BsHandThumbsUp,
  BsHandIndexThumb,
  BsArrowsExpand
} from 'react-icons/bs'
import { GiExpandedRays } from 'react-icons/gi'
import Modal from 'react-bootstrap/Modal'
import {
  getFBLoginInfo,
  listFBLoginInfos,
  listConfigItems,
  listAdStatuses,
  listUserInfos,
  getPostDetails
} from '../../graphql/queries'
import { Form } from 'react-bootstrap'
import InputAdornment from '@mui/material/InputAdornment'
import { Carousel, Card, Stack } from 'react-bootstrap'
// import Button from 'react-bootstrap/Button';
import { ToggleButton, ToggleButtonGroup } from '@aws-amplify/ui-react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { MapView } from '@aws-amplify/ui-react'
// import TimePicker from 'react-time-picker';
// import DatePicker from 'react-date-picker';
import OutlinedInput from '@mui/material/OutlinedInput'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Tabs, TabItem, ThemeProvider, Theme } from '@aws-amplify/ui-react'
import axios from 'axios'
import { API, graphqlOperation } from 'aws-amplify'
import { fontSize } from '@mui/system'
import HashLoader from 'react-spinners/HashLoader'
import LoadingOverlay from 'react-loading-overlay'
import '../../App.css'
import Multiselect from 'multiselect-react-dropdown'
import '../managecontent/managestyle.css'
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel
} from '@mui/x-data-grid'
import { SwitchField } from '@aws-amplify/ui-react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { redirect, useNavigate } from 'react-router-dom'
import { alertTitleClasses, styled } from '@mui/material'
import '../managecontent/managestyle.css'
import { listAdDetails } from '../../graphql/queries'
import { primaryButton, secondaryButton, ResetIcon } from '../../assets/style'
import Post from './Post'
import PostDetails from './PostDetail'
import GenericContent from './GenericContent'
import SocialChannel from './SocialChannels'
import Assinged from './Assinged'
import { useLocation } from 'react-router-dom'
import {
  createPostHistory,
  updatePostDetails,
  updatePostHistory
} from '../../graphql/mutations'
import { AssistWalkerOutlined } from '@mui/icons-material'
import { Auth } from 'aws-amplify'
const ManagePost = () => {
  const location = useLocation()
  const [saveText, setSaveText] = useState('Submit for Approval')

  const [staticAdStatus, setStaticAdStatus] = useState()
  const [StaticUserList, setStaticUserList] = useState()

  const [postfbversion, setpostfbversion] = useState()
  const [postigversion, setpostigversion] = useState()

  const [historyfbversion, sethistoryfbversion] = useState()
  const [historyigversion, sethistoryigversion] = useState()

  const [fb_userid, setfacebook_userid] = useState()
  const [fb_account_id, setfb_account_id] = useState()
  const [ig_user_id, setig_user_id] = useState()
  const [publishstate, setpublishstate] = useState(false)
  const [ManagePostData, setManagePostData] = useState([
    {
      scheduleDate: '',
      scheduleTime: '',
      timestamp: '',
      label: '',
      Subject: '',
      isfbpost: false,
      isigpost: false,
      msg: '',
      image: '',
      fbmsg: '',
      fbimage: '',
      igmsg: '',
      igimage: ''
    }
  ])
  const [postdetaildisable, setpostdetaildisable] = useState(false)
  const [Genericdisable, setGenericdisable] = useState(false)
  const [socialchanneldisable, setsocialchanneldisable] = useState(false)
  const [updateclick, setupdateclick] = useState(false)

  const [logged, setlogged] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const onInit = async () => {
      try {
        await Auth.currentAuthenticatedUser()

        setlogged(true)
      } catch (err) {
        setlogged(false)
      }
    }
    if (!logged) {
      navigate('/')
    }
    onInit()
  }, [logged])
  const [fbPostIdDB, setFbPostIdDB] = useState()
  const [igPostIdDB, setIgPostIdDB] = useState()
  const [updatepostId, setupdatepostId] = useState()
  const [updatepostversion, setupdatepostversion] = useState()
  const [fbPostHistoryIdDB, setFbPostHistoryId] = useState()
  const [editpostid, seteditpostid] = useState()
  const [igPostHistoryIdDB, setigPostHistoryId] = useState()
  const [showAssignjs, setShowAssignjs] = useState(false)
  const [AssignUser, setAssignuser] = useState()
  const handlecloseAssign = () => {
    setShowAssignjs(false)
  }

  const [long_access_token, setlong_access_token] = useState()
  const [FbPageId, setFbPageId] = useState()
  const [role, setrole] = useState(localStorage.getItem('role_id'))

  //////show connect message
  const [managepostconnect, setmanagepostconnect] = useState(false)
  const [AdState, setAdState] = useState()

  //general post states
  const [Post_IdFrom_MP, setPost_IdFrom_MP] = useState()
  const [postversFrom_MP, setpostversFrom_MP] = useState()
  const [PostHistory_IdFrom_MP, setPostHistory_IdFrom_MP] = useState()
  const [PostHistoryversFrom_MP, setPostHistoryversFrom_MP] = useState()

  const [DisableFieldsAfterpublished, setDisableFieldsAfterpublished] =
    useState(false)

  const fetchfblogin = async () => {
    console.log(localStorage.getItem('companyinfoID'), 'comid')
    try {
      let filter = {
        company_id: {
          eq: localStorage.getItem('companyinfoID') // filter priority = 1  get location.state/id or
        }
      }

      const fbdata = await API.graphql({
        query: listFBLoginInfos,
        variables: { filter: filter }
      })

      console.log(fbdata.data.listFBLoginInfos.items.length)
      if (
        fbdata.data.listFBLoginInfos.items.filter(
          item => item._deleted == null || item._deleted !== true
        ).length > 0
      ) {
        // alert("yes it is");
        setfacebook_userid(
          fbdata.data.listFBLoginInfos.items.filter(
            item => item._deleted == null || item._deleted !== true
          )[0].facebook_userid
        )

        //setadsaccount(fbdata.data.listFBLoginInfos.items[0].ads_accountid);
        setfb_account_id(
          fbdata.data.listFBLoginInfos.items.filter(
            item => item._deleted == null || item._deleted !== true
          )[0].ads_accountid
        )
        setig_user_id(
          fbdata.data.listFBLoginInfos.items.filter(
            item => item._deleted == null || item._deleted !== true
          )[0].insta_business_id
        )
        setlong_access_token(
          fbdata.data.listFBLoginInfos.items.filter(
            item => item._deleted == null || item._deleted !== true
          )[0].long_access_token
        )
        setFbPageId(
          fbdata.data.listFBLoginInfos.items.filter(
            item => item._deleted == null || item._deleted !== true
          )[0].pageid
        )

        //for social account connect message
        setmanagepostconnect(false)

        setpostdetaildisable(false)
        setGenericdisable(false)
        setsocialchanneldisable(false)

        //  console.log(facebook_userid);

        // alert("Facebook Login Success");
        // -----------------------------------------------------------------------------------AdsetCalls--------------------------------------------------------------------------------------------------------------
        // GetAdset();
        //  CreateAdset();

        // --------------------------------------------------------------------------------------END--------------------------------------------------------------------------------------------------------------
      } else {
        setmanagepostconnect(true)
        setShowPost(false)
        setpostdetaildisable(true)
        setGenericdisable(true)
        setsocialchanneldisable(true)
        setShowDetails(false)
      }
    } catch (err) {
      // alert("not it is");
      console.log('error fetching actors' + err)
    }
  }
  // scroll
  const messagesEndRef = useRef()
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 1500)
  }

  useEffect(() => {
    fetchfblogin()
    fetchAdStatus()
    if (location.state == undefined) {
      redirect_to_PostDetails()
    }

    fetchUserInfo()
    if (localStorage.getItem('role_id') === '5') {
      setSaveText('Submit for Publish')
    }
    if (location.state != undefined) {
      console.log(location.state, 'location.state')

      //Published posts are Non editable
      if (location.state.key == 'disabled') {
        setDisableFieldsAfterpublished(true)
      }

      if (location.state.key == 'Editableforcc') {
      }

      getexitPost(location.state.id, location.state.key)
      seteditpostid(location.state.id)
      setPost_IdFrom_MP(location.state.id)
      setAssignuser(location.state.Assigned_user)
      setAdState(location.state.AdState)
      console.log('setmanagepostconnect', ManagePostData)
    }
  }, [])

  const getexitPost = async (id, key) => {
    setpostdetaildisable(true)
    setGenericdisable(true)
    setsocialchanneldisable(true)
    await API.graphql(graphqlOperation(getPostDetails, { id: id })).then(
      result => {
        setpostversFrom_MP(result.data.getPostDetails._version)
        console.log(result, 'forupdatepost')
        if (
          JSON.parse(result.data.getPostDetails.post_channels)[0] ===
            'Facebook' &&
          JSON.parse(result.data.getPostDetails.post_channels)[1] ===
            'Instagram'
        ) {
          let temp = [...ManagePostData]
          temp[0].timestamp = result.data.getPostDetails.post_schedule_timestamp
          temp[0].isfbpost = true
          temp[0].msg = result.data.getPostDetails.post_message
          temp[0].image = result.data.getPostDetails.media_hash_id
          temp[0].igimage = result.data.getPostDetails.insta_image_url
          temp[0].fbimage = result.data.getPostDetails.fb_image_url
          temp[0].igmsg = result.data.getPostDetails.insta_post_msg
          temp[0].fbmsg = result.data.getPostDetails.fb_post_msg
          temp[0].isigpost = true
          temp[0].scheduleDate = moment
            .unix(temp[0].timestamp)
            .format('YYYY-MM-DD')
          temp[0].scheduleTime = moment.unix(temp[0].timestamp).format('hh:mm')
          setManagePostData(temp)
          console.log(
            '.............',
            temp[0].scheduleDate,
            temp[0].scheduleTime
          )
        }
        if (
          JSON.parse(result.data.getPostDetails.post_channels)[0] === 'Facebook'
        ) {
          let temp = [...ManagePostData]
          temp[0].timestamp = result.data.getPostDetails.post_schedule_timestamp
          temp[0].isfbpost = true
          temp[0].msg = result.data.getPostDetails.post_message
          temp[0].image = result.data.getPostDetails.media_hash_id
          temp[0].scheduleDate = moment
            .unix(temp[0].timestamp)
            .format('YYYY-MM-DD')
          temp[0].scheduleTime = moment.unix(temp[0].timestamp).format('hh:mm')
          setManagePostData(temp)
        }
        if (
          JSON.parse(result.data.getPostDetails.post_channels)[0] ===
          'Instagram'
        ) {
          let temp = [...ManagePostData]
          temp[0].timestamp = result.data.getPostDetails.post_schedule_timestamp
          temp[0].isigpost = true
          temp[0].msg = result.data.getPostDetails.post_message
          temp[0].image = result.data.getPostDetails.media_hash_id
          temp[0].scheduleDate = moment
            .unix(temp[0].timestamp)
            .format('YYYY-MM-DD')
          temp[0].scheduleTime = moment.unix(temp[0].timestamp).format('hh:mm')
          setManagePostData(temp)
        }
        setupdatepostId(result.data.getPostDetails.id)
        setFbPostIdDB(result.data.getPostDetails.id)
        setIgPostIdDB(result.data.getPostDetails.id)
        setpostigversion(result.data.getPostDetails._version)
        setpostfbversion(result.data.getPostDetails._version)
        setupdatepostversion(result.data.getPostDetails._version)

        if (key == 'Editableforcc') {
          var posthistorydatalength =
            result.data.getPostDetails.PostHistories.items.length - 1
          console.log(posthistorydatalength, 'posthistorydatalength')
          changePostStateToDraft(
            result.data.getPostDetails.id,
            result.data.getPostDetails._version,
            result.data.getPostDetails.PostHistories.items[
              posthistorydatalength
            ].id,
            result.data.getPostDetails.PostHistories.items[
              posthistorydatalength
            ]._version
          )
        }
      }
    )

    setupdateclick(true)
    setGeneriContent(true)
  }

  // //change Adstate to Draft for Content currator while Editing
  const changePostStateToDraft = async (
    postid,
    postversion,
    posthistoryid,
    posthistoryversion
  ) => {
    const poststate = {
      id: postid,
      post_status: '6',
      _version: postversion
    }

    await API.graphql(graphqlOperation(updatePostDetails, { input: poststate }))
      .then(async () => {
        const PosthistoryState = {
          id: posthistoryid,
          post_status_id: '6',
          assigned_by_userid: localStorage.getItem('userid'),
          assigned_to_userid: localStorage.getItem('userid'),
          _version: posthistoryversion
        }
        await API.graphql(
          graphqlOperation(updatePostHistory, { input: PosthistoryState })
        )
      })
      .catch(err => {
        console.log(
          err,
          'changing state to draft for content curartor while clicking Edit button'
        )
      })
  }

  const fetchAdStatus = async () => {
    const getStatus = await API.graphql({ query: listAdStatuses })
    setStaticAdStatus(getStatus.data.listAdStatuses.items)
  }
  const fetchUserInfo = async () => {
    let filter = {
      companyinfoID: { eq: localStorage.getItem('companyinfoID') }
    }
    const ListUsrInfo = await API.graphql({
      query: listUserInfos,
      variables: { limit: 10000, filter: filter }
    })
    setStaticUserList(
      ListUsrInfo.data.listUserInfos.items.filter(
        item => item._deleted !== true || item._deleted == null
      )
    )
  }

  const [ShowPost, setShowPost] = useState(false)
  const [ShowGeneriContent, setGeneriContent] = useState(false)
  const [ShowPostDetails, setShowDetails] = useState(false)
  const [ShowSocialChannels, setShowSocialchannels] = useState(false)
  const [ShowSaveAndPublish, setShowSaveAndPublish] = useState(false)
  const [showvPopup, setshowpopup] = useState(false)
  const redirect_to_PostDetails = () => {
    setShowPost(false)
    setGeneriContent(false)
    setShowDetails(!ShowPostDetails)
    setShowSocialchannels(false)
    setShowSaveAndPublish(false)
  }
  const redirect_to_Post = () => {
    setShowPost(!ShowPost)
    setGeneriContent(false)
    setShowDetails(false)
    setShowSocialchannels(false)
    setShowSaveAndPublish(false)
  }
  const redirect_to_GeneriContent = () => {
    setShowPost(false)
    setGeneriContent(!ShowGeneriContent)
    setShowDetails(false)
    setShowSocialchannels(false)
    setShowSaveAndPublish(false)
  }
  const redirect_to_SocialChannels = () => {
    setShowPost(false)
    setGeneriContent(false)
    setShowDetails(false)
    setShowSocialchannels(!ShowSocialChannels)
    setShowSaveAndPublish(false)
  }
  const redirect_to_SaveAndPublish = () => {
    setShowPost(false)
    setGeneriContent(false)
    setShowDetails(false)
    setShowSocialchannels(false)
    setShowSaveAndPublish(!ShowSaveAndPublish)
  }
  const PublishPost = () => {
    if (ManagePostData[0].isfbpost && !ManagePostData[0].isigpost) {
      facebookpostPublish()
    } else if (!ManagePostData[0].isfbpost && ManagePostData[0].isigpost) {
      instagrampostpublish()
    } else if (ManagePostData[0].isfbpost && ManagePostData[0].isigpost) {
      bothPublish()
    }
  }

  const bothPublish = () => {
    setpublishstate(true)
    axios({
      method: 'get',
      url:
        'https://graph.facebook.com/v15.0/' +
        FbPageId +
        '?fields=access_token&access_token=' +
        long_access_token
    })
      .then(data => {
        axios({
          method: 'post',
          url:
            'https://graph.facebook.com/v15.0/' +
            FbPageId +
            '/photos?url=' +
            encodeURIComponent(ManagePostData[0].fbimage) +
            '&message=' +
            ManagePostData[0].fbmsg +
            '&scheduled_publish_time=' +
            ManagePostData[0].timestamp +
            '&published=false&access_token=' +
            data.data.access_token
        })
          .then(async data => {
            console.log(data, Post_IdFrom_MP, 'data11111')
            const obj = {
              id: Post_IdFrom_MP,
              post_status: '4',
              _version: postversFrom_MP,
              fb_post_id: data.data.id
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                console.log(response, 'FULLDATA1')
                await InstaPublishFunction(
                  response.data.updatePostDetails._version
                )
                const createHistoryobj = {
                  post_status_id: '4',
                  assigned_by_userid: localStorage.getItem('userid'),
                  assigned_to_userid: null,
                  postdetailsID: Post_IdFrom_MP
                }
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj
                  })
                ).then(() => {})
              })
              .catch(() => {
                toast.error('error when save to db')
              })
            toast.success('Published in Facebook')
            // toast.update(instatoastId.current, {
          })
          .catch(async error => {
            console.log('err', error)
            toast.error('Check the Schedule Time')
            const obj = {
              id: Post_IdFrom_MP,
              post_status: '1',
              _version: postversFrom_MP,
              fb_post_id: ''

              //  insta_container_id:data.data.id
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            ).then(async response => {
              console.log(response, 'FULLDATA2')
              await InstaPublishFunction(
                response.data.updatePostDetails._version
              )
              const createHistoryobj = {
                post_status_id: '1',
                assigned_by_userid: localStorage.getItem('userid'),
                assigned_to_userid: null,
                postdetailsID: Post_IdFrom_MP
              }
              await API.graphql(
                graphqlOperation(createPostHistory, {
                  input: createHistoryobj
                })
              ).then(() => {})
            })

            setpublishstate(false)
          })
      })
      .catch(async () => {
        setpublishstate(false)
        const obj = {
          id: Post_IdFrom_MP,
          post_status: '1',
          _version: postversFrom_MP,
          fb_post_id: ''
        }

        await API.graphql(graphqlOperation(updatePostDetails, { input: obj }))
          .then(async response => {
            console.log(response, 'FULLDATA3')
            await InstaPublishFunction(response.data.updatePostDetails._version)
            const createHistoryobj = {
              post_status_id: '1',
              assigned_by_userid: localStorage.getItem('userid'),
              assigned_to_userid: null,
              postdetailsID: Post_IdFrom_MP
            }
            await API.graphql(
              graphqlOperation(createPostHistory, {
                input: createHistoryobj
              })
            ).then(() => {})
          })
          .catch(() => {
            toast.error('error when save to db')
            setpublishstate(false)
          })
        toast.error('something went wrong')
        setpublishstate(false)
        // toast.update(instatoastId.current, {
        //     render: "something went wrong",
        //     type: "error",
        //     isLoading: false,
        //     autoClose: 1000,
        // });
      })
  }
  const InstaPublishFunction = async _v => {
    axios({
      method: 'get',
      url:
        'https://graph.facebook.com/v15.0/' +
        FbPageId +
        '?fields=access_token&access_token=' +
        long_access_token
    })
      .then(data => {
        axios({
          method: 'post',
          url:
            'https://graph.facebook.com/v15.0/' +
            ig_user_id +
            '/media?image_url=' +
            encodeURIComponent(ManagePostData[0].igimage) +
            '&caption=' +
            ManagePostData[0].igmsg +
            '&access_token=' +
            data.data.access_token
        })
          .then(get => {
            console.log(get, 'frominstapost')
            axios({
              method: 'post',
              url:
                'https://graph.facebook.com/v15.0/' +
                ig_user_id +
                '/media_publish?creation_id=' +
                get.data.id +
                '&access_token=' +
                data.data.access_token
            })
              .then(async d => {
                toast.success('Succesfully Posted in IG')
                const obj = {
                  id: Post_IdFrom_MP,
                  post_status: '4',
                  _version: _v,
                  insta_post_id: d.data.id
                }

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async response => {
                    setpublishstate(false)
                    // const createHistoryobj = {
                    //   post_status_id: "4",
                    //   assigned_by_userid: localStorage.getItem("userid"),
                    //   assigned_to_userid: null,
                    //   postdetailsID: GeneralPost_Id,
                    // };
                    // await API.graphql(
                    //   graphqlOperation(createPostHistory, {
                    //     input: createHistoryobj,
                    //   })
                    // ).then(() => {});
                  })
                  .catch(() => {
                    toast.error('error when save to db')
                  })
              })
              .catch(async () => {
                const obj = {
                  id: Post_IdFrom_MP,
                  post_status: '1',
                  _version: _v
                }
                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async response => {
                    setpublishstate(false)
                    // const createHistoryobj = {
                    //   post_status_id: "4",
                    //   assigned_by_userid: localStorage.getItem("userid"),
                    //   assigned_to_userid: null,
                    //   postdetailsID: GeneralPost_Id,
                    // };
                    // await API.graphql(
                    //   graphqlOperation(createPostHistory, {
                    //     input: createHistoryobj,
                    //   })
                    // ).then(() => {});
                  })
                  .catch(() => {
                    setpublishstate(false)
                    toast.error('error when save to db')
                  })
              })
          })
          .catch(async () => {
            setpublishstate(false)
            toast.error('Something Wrong')
            const obj = {
              id: Post_IdFrom_MP,
              post_status: '1',
              _version: _v
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                setpublishstate(false)
                // const createHistoryobj = {
                //   post_status_id: "4",
                //   assigned_by_userid: localStorage.getItem("userid"),
                //   assigned_to_userid: null,
                //   postdetailsID: GeneralPost_Id,
                // };
                // await API.graphql(
                //   graphqlOperation(createPostHistory, {
                //     input: createHistoryobj,
                //   })
                //  ).then(() => {});
              })
              .catch(() => {
                setpublishstate(false)
                toast.error('error when save to db')
              })
          })
          .catch(async () => {
            toast.error('Please Select Valid Image')
            const obj = {
              id: Post_IdFrom_MP,
              post_status: '1',
              _version: _v
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                setpublishstate(false)
                // const createHistoryobj = {
                //   post_status_id: "4",
                //   assigned_by_userid: localStorage.getItem("userid"),
                //   assigned_to_userid: null,
                //   postdetailsID: GeneralPost_Id,
                // };
                // await API.graphql(
                //   graphqlOperation(createPostHistory, {
                //     input: createHistoryobj,
                //   })
                // ).then(() => {});
              })
              .catch(() => {
                setpublishstate(false)
                toast.error('error when save to db')
              })
          })
          .catch(async () => {
            const obj = {
              id: Post_IdFrom_MP,
              post_status: '1',
              _version: _v
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                setpublishstate(false)
                // const createHistoryobj = {
                //   post_status_id: "4",
                //   assigned_by_userid: localStorage.getItem("userid"),
                //   assigned_to_userid: null,
                //   postdetailsID: GeneralPost_Id,
                // };
                // await API.graphql(
                //   graphqlOperation(createPostHistory, {
                //     input: createHistoryobj,
                //   })
                // ).then(() => {});
              })
              .catch(() => {
                setpublishstate(false)
                toast.error('error when save to db')
              })
          })
      })
      .catch(async () => {
        const obj = {
          id: Post_IdFrom_MP,
          post_status: '1',
          _version: _v
        }

        await API.graphql(graphqlOperation(updatePostDetails, { input: obj }))
          .then(async response => {
            setpublishstate(false)
            // const createHistoryobj = {
            //   post_status_id: "4",
            //   assigned_by_userid: localStorage.getItem("userid"),
            //   assigned_to_userid: null,
            //   postdetailsID: GeneralPost_Id,
            // };
            // await API.graphql(
            //   graphqlOperation(createPostHistory, {
            //     input: createHistoryobj,
            //   })
            // ).then(() => {});
          })
          .catch(() => {
            setpublishstate(false)
            toast.error('error when save to db')
          })
      })
  }
  const facebookpostPublish = async () => {
    setpublishstate(true)
    axios({
      method: 'get',
      url:
        'https://graph.facebook.com/v15.0/' +
        FbPageId +
        '?fields=access_token&access_token=' +
        long_access_token
    })
      .then(data => {
        axios({
          method: 'post',
          url:
            'https://graph.facebook.com/v15.0/' +
            FbPageId +
            '/photos?url=' +
            encodeURIComponent(ManagePostData[0]['fbimage']) +
            '&message=' +
            ManagePostData[0]['fbmsg'] +
            '&scheduled_publish_time=' +
            ManagePostData[0].timestamp +
            '&published=false&access_token=' +
            data.data.access_token
        })
          .then(async data => {
            const obj = {
              id: updatepostId,
              post_status: '4',
              _version: updatepostversion,
              fb_post_id: data.data.id
              //  insta_container_id:data.data.id
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                const createHistoryobj = {
                  post_status_id: '4',
                  assigned_by_userid: localStorage.getItem('userid'),
                  assigned_to_userid: null,
                  postdetailsID: updatepostId
                }
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj
                  })
                ).then(() => {})
              })
              .catch(() => {
                toast.error('error when save to db')
                setpublishstate(false)
              })
            //setfbpoststate(true);
            toast.success('Feed Posted SuccessFully')
            setpublishstate(false)
          })
          .catch(async err => {
            console.log('err', err)
            toast.error('something went wrong')
            setpublishstate(false)
            const obj = {
              id: updatepostId,
              post_status: '1',
              _version: updatepostversion,
              fb_post_id: ''
              //  insta_container_id:data.data.id
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                const createHistoryobj = {
                  post_status_id: '1',
                  assigned_by_userid: localStorage.getItem('userid'),
                  assigned_to_userid: null,
                  postdetailsID: updatepostId
                }
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj
                  })
                ).then(() => {})
              })
              .catch(() => {
                toast.error('error when save to db')
                setpublishstate(false)
              })
          })
      })
      .catch(() => {
        toast.error('something went wrong')
        setpublishstate(false)
      })
  }
  const instagrampostpublish = async () => {
    setpublishstate(true)
    axios({
      method: 'get',
      url:
        'https://graph.facebook.com/v15.0/' +
        FbPageId +
        '?fields=access_token&access_token=' +
        long_access_token
    })
      .then(data => {
        axios({
          method: 'post',
          url:
            'https://graph.facebook.com/v15.0/' +
            ig_user_id +
            '/media?image_url=' +
            encodeURIComponent(ManagePostData[0]['igimage']) +
            '&caption=' +
            ManagePostData[0]['igmsg'] +
            '&access_token=' +
            data.data.access_token
        })
          .then(get => {
            console.log(get, 'frominstapost')
            axios({
              method: 'post',
              url:
                'https://graph.facebook.com/v15.0/' +
                ig_user_id +
                '/media_publish?creation_id=' +
                get.data.id +
                '&access_token=' +
                data.data.access_token
            })
              .then(async d => {
                const obj = {
                  id: updatepostId,
                  post_status: '4',
                  _version: updatepostversion,
                  fb_post_id: d.data.id,
                  insta_container_id: get.data.id
                }

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async response => {
                    const createHistoryobj = {
                      post_status_id: '4',
                      assigned_by_userid: localStorage.getItem('userid'),
                      assigned_to_userid: null,
                      postdetailsID: updatepostId
                    }
                    await API.graphql(
                      graphqlOperation(createPostHistory, {
                        input: createHistoryobj
                      })
                    ).then(() => {})
                  })
                  .catch(() => {
                    toast.error('error when save to db')
                    setpublishstate(false)
                  })

                toast.success('Succesfully Posted in IG')
                setpublishstate(false)
              })
              .catch(async () => {
                toast.error('Something Wrong')
                setpublishstate(false)
                const obj = {
                  id: updatepostId,
                  post_status: '1',
                  _version: updatepostversion,
                  fb_post_id: '',
                  insta_container_id: ''
                }

                await API.graphql(
                  graphqlOperation(updatePostDetails, { input: obj })
                )
                  .then(async response => {
                    const createHistoryobj = {
                      post_status_id: '1',
                      assigned_by_userid: localStorage.getItem('userid'),
                      assigned_to_userid: null,
                      postdetailsID: updatepostId
                    }
                    await API.graphql(
                      graphqlOperation(createPostHistory, {
                        input: createHistoryobj
                      })
                    ).then(() => {})
                  })
                  .catch(() => {
                    toast.error('error when save to db')
                    setpublishstate(false)
                  })
              })
          })
          .catch(async () => {
            toast.error('Please Select Valid Image')
            setpublishstate(false)
            const obj = {
              id: updatepostId,
              post_status: '1',
              _version: updatepostversion,
              fb_post_id: '',
              insta_container_id: ''
            }

            await API.graphql(
              graphqlOperation(updatePostDetails, { input: obj })
            )
              .then(async response => {
                const createHistoryobj = {
                  post_status_id: '1',
                  assigned_by_userid: localStorage.getItem('userid'),
                  assigned_to_userid: null,
                  postdetailsID: updatepostId
                }
                await API.graphql(
                  graphqlOperation(createPostHistory, {
                    input: createHistoryobj
                  })
                ).then(() => {})
              })
              .catch(() => {
                toast.error('error when save to db')
                setpublishstate(false)
              })
          })
      })
      .catch(() => {
        toast.error('something Went Wrong')
        setpublishstate(false)
      })
  }

  ///to navigate social account

  const connectsocialacc = () => {
    navigate('/account', {
      state: { From: 'dashboard', tabindex: '4', key: 'connectsocaialaccount' }
    })
  }

  return (
    <>
      <Flex direction={'row'}>
        <View width='100%'>
          <DashboardHeader
            db={false}
            mc={false}
            cl={false}
            mp={true}
            ndb={false}
          ></DashboardHeader>
          <Flex
            hidden={!managepostconnect}
            justifyContent='center'
            padding={{
              base: '1em',
              small: '0em',
              medium: '0em',
              large: '0em'
            }}
            paddingTop={{
              base: '0em',
              small: '2em',
              medium: '2.2em',
              large: '2em'
            }}
          >
            <Text
              style={{
                fontWeight: '400',
                marginTop: '1em',
                color: '#4267B2'
              }}
            >
              Connect Your {''} &nbsp;
              <Text
                class='flash'
                textDecoration='none'
                onClick={connectsocialacc}
                style={{
                  cursor: 'pointer'
                }}
                as='span'
                fontSize='1rem'
                color='#4267B2'
              >
                <u> SocialAccount </u> {''}
              </Text>
              <Text as='span' fontSize='1rem' color='#4267B2'>
                {' '}
                {'  '} &nbsp; For Ads Management
              </Text>
            </Text>
          </Flex>

          <ButtonGroup
            direction={{ base: 'column', large: 'column' }}
            margin={{ base: 'large', large: 'xxl' }}
            style={{ marginTop: '2em', color: '#fff' }}
            variant='primary'
          >
            <Button
              disabled={postdetaildisable}
              fontWeight='600'
              border='none'
              justifyContent='left'
              boxShadow='large'
              color={'white'}
              backgroundColor='#B71F39'
              onClick={redirect_to_PostDetails}
              className='btngrp'
            >
              Post Details
            </Button>
            {ShowPostDetails ? (
              <PostDetails
                DisableFieldsAfterpublished={DisableFieldsAfterpublished}
                redirect_sc={redirect_to_SocialChannels}
                setPostDetailsValue={setManagePostData}
                postDetailsvalue={ManagePostData}
              ></PostDetails>
            ) : null}
            <Button
              disabled={ManagePostData[0]['scheduleDate']==''||ManagePostData[0]['scheduleTime']==''}
              fontWeight='600'
              border='none'
              justifyContent='left'
              boxShadow='large'
              color={'white'}
              backgroundColor='#B71F39'
              onClick={redirect_to_SocialChannels}
              className='btngrp'
            >
              Social Channels
            </Button>
            {ShowSocialChannels ? (
              <SocialChannel
                DisableFieldsAfterpublished={DisableFieldsAfterpublished}
                redirect_pt={redirect_to_Post}
                setSocialchannelValue={setManagePostData}
                SocialChannelValue={ManagePostData}
              ></SocialChannel>
            ) : null}
            <Button
              fontWeight='600'
              disabled={
                (!ManagePostData[0]['isfbpost'] ||!ManagePostData[0]['isfbpost'])
              }
              border='none'
              justifyContent='left'
              boxShadow='large'
              color={'white'}
              backgroundColor='#B71F39'
              onClick={redirect_to_Post}
              className='btngrp'
            >
              Generic Content
            </Button>
            {ShowPost ? (
              <GenericContent
                DisableFieldsAfterpublished={DisableFieldsAfterpublished}
                redirect_ge={redirect_to_GeneriContent}
                setGeneriContentValue={setManagePostData}
                GenericContentValue={ManagePostData}
                token={long_access_token}
                adsAccountId={fb_account_id}
              ></GenericContent>
            ) : null}
            <Button
              disabled={
                (ManagePostData[0]['fbimage'] == '' &&
                  ManagePostData[0]['fbmsg'] == '' )&&
                  (!ManagePostData[0]['isigpost'] ||
                    !ManagePostData[0]['isfbpost'])
              }
              fontWeight='600'
              border='none'
              justifyContent='left'
              boxShadow='large'
              color={'white'}
              backgroundColor='#B71F39'
              onClick={
                ManagePostData[0]['image'] != '' &&
                ManagePostData[0]['msg'] != ''
                  ? redirect_to_GeneriContent
                  : null
              }
              className='btngrp'
            >
              Post
            </Button>
            {ShowGeneriContent ? (
              <Post
                DisableFieldsAfterpublished={DisableFieldsAfterpublished}
                updatecheck={updateclick}
                updateid={updatepostId}
                updateversion={updatepostversion}
                fbpostDetailsId={fbPostIdDB}
                setfbpostDetailsId={setFbPostIdDB}
                igpostDetailsId={igPostIdDB}
                setigpostDetailsId={setIgPostIdDB}
                setigpostHistId={setigPostHistoryId}
                setfbpostHistId={setFbPostHistoryId}
                setpostfbvers={setpostfbversion}
                sethistoryfbvers={sethistoryfbversion}
                setpostigvers={setpostigversion}
                sethistoryigvers={sethistoryigversion}
                redirect_sp={redirect_to_SaveAndPublish}
                adsAccountId={fb_account_id}
                instg_user={ig_user_id}
                userid={fb_userid}
                token={long_access_token}
                pageid={FbPageId}
                setPostValues={setManagePostData}
                postvalue={ManagePostData}
                setGeneralPost_Id={setPost_IdFrom_MP}
                setGeneralpostvers={setpostversFrom_MP}
                setGeneralPostHistory_Id={setPostHistory_IdFrom_MP}
                setGeneralPostHistoryvers={setPostHistoryversFrom_MP}
              ></Post>
            ) : null}
            <Button
              fontWeight='600'
              border='none'
              justifyContent='left'
              boxShadow='large'
              color={'white'}
              backgroundColor='#B71F39'
              onClick={redirect_to_SaveAndPublish}
              className='btngrp'
              disabled={
                (ManagePostData[0]['fbimage'] == '' &&
                  ManagePostData[0]['fbmsg'] == '') &&
                  (!ManagePostData[0]['isigpost'] ||
                    !ManagePostData[0]['isfbpost']) 
               
              }
            >
              Save and Publish
            </Button>
            {ShowSaveAndPublish ? (
              <Flex
                direction={{
                  base: 'column',
                  small: 'column',
                  medium: 'column',
                  large: 'column',
                  xl: 'column',
                  xxl: 'column'
                }}
                gap='1rem'
                style={{ border: '2px solid #E8E8E8', borderRadius: '0.5em' }}
              >
                <Flex
                  direction={{
                    base: 'column',
                    small: 'row',
                    medium: 'row',
                    large: 'row'
                  }}
                  justifyContent='center'
                  padding={{
                    base: '1em',
                    small: '0em',
                    medium: '0em',
                    large: '0em'
                  }}
                  paddingTop={{
                    base: '3em',
                    small: '6em',
                    medium: '6em',
                    large: '6em'
                  }}
                  paddingBottom={{
                    base: '3em',
                    small: '6em',
                    medium: '6em',
                    large: '6em'
                  }}
                  gap={{
                    base: 'small',
                    small: 'small',
                    medium: 'small',
                    large: 'xl'
                  }}
                >
                  {/* <Button
                    style={primaryButton}
                    backgroundColor={"#202A44"}
                    boxShadow="large"
                    borderRadius="2em"
                    fontSize={{ base: "small", large: "medium" }}
                  //onClick={Redirect_to_Preview}
                  >
                    Preview{" "}
                    <Icon
                      as={VscOpenPreview}
                      fontSize={{ base: "xl", large: "xl" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.2em"
                      marginTop={"0.4em"}
                    ></Icon>
                  </Button> */}
                  {/* <Button
                    style={primaryButton}
                    boxShadow="large"
                    backgroundColor="grey"
                    borderRadius="2em"
                    fontSize={{ base: "small", large: "medium" }}
                    size={{ base: "xs", large: "large" }}
                  >
                    Save Draft{" "}
                    <Icon
                      as={RiDraftLine}
                      fontSize={{ large: "medium" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.3em"
                    ></Icon>
                  </Button> */}
                  {role != 4 && (
                    <Button
                      style={primaryButton}
                      boxShadow='large'
                      fontSize={{ base: 'xs', large: 'medium' }}
                      borderRadius='2em'
                      backgroundColor='rgb(104, 2, 80)'
                      onClick={() => {
                        setshowpopup(true)
                        setShowAssignjs(true)
                        scrollToBottom()
                      }}
                    >
                      {saveText}{' '}
                      <Icon
                        as={BsHandIndexThumb}
                        fontSize={{ base: 'xl', large: 'xl' }}
                        color='white'
                        fontWeight={'bold'}
                        marginLeft='0.3em'
                        marginTop={'0.3em'}
                      ></Icon>
                    </Button>
                  )}
                  {showvPopup ? (
                    <Assinged
                      DisableFieldsAfterpublished={DisableFieldsAfterpublished}
                      GeneralPost_Id={Post_IdFrom_MP}
                      Generalpostvers={postversFrom_MP}
                      GeneralPostHistory_Id={PostHistory_IdFrom_MP}
                      GeneralPostHistoryvers={PostHistoryversFrom_MP}
                      updateversion={updatepostversion}
                      instg_user={ig_user_id}
                      editid={editpostid}
                      token={long_access_token}
                      AdStat={AdState}
                      pageid={FbPageId}
                      toast={toast}
                      Assign={AssignUser}
                      uList={StaticUserList}
                      AdList={staticAdStatus}
                      postvers={postfbversion}
                      historyvers={historyfbversion}
                      postid={fbPostIdDB}
                      historyid={fbPostHistoryIdDB}
                      igpostid={igPostIdDB}
                      ighistoryid={igPostHistoryIdDB}
                      igpostvers={postigversion}
                      ighistoryvers={historyigversion}
                      assignpost={ManagePostData}
                      messagesEndRef={messagesEndRef}
                      show={showAssignjs}
                      setshow={setShowAssignjs}
                      handleCloseAss={handlecloseAssign}
                    ></Assinged>
                  ) : null}
                  {(role == 4 || role == 1) && (
                    <Button
                      style={primaryButton}
                      boxShadow='large'
                      isLoading={publishstate}
                      loadingText='Processing..'
                      onClick={PublishPost}
                      borderRadius='2em'
                      fontSize={{ base: 'small', large: 'medium' }}
                      backgroundColor='rgb(142, 198, 65)'
                      size={{ base: 'xs', large: 'small' }}
                    >
                      Publish{' '}
                      <Icon
                        as={BsHandThumbsUp}
                        fontSize={{ base: 'xl', large: 'xl' }}
                        color='white'
                        fontWeight={'bold'}
                        marginLeft='0.3em'
                        marginTop={'0.3em'}
                      ></Icon>
                    </Button>
                  )}
                </Flex>
              </Flex>
            ) : null}
          </ButtonGroup>

          <ToastContainer autoClose={1500} limit={0} />
        </View>
      </Flex>
    </>
  )
}

export default ManagePost
