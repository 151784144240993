export const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 1 },
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: "hsl(270deg 55% 42%)",
    color: "#639",
    fontSize: "small",
    fontStyle: "bold",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell':{
  //   borderRight:'1px solid #b9b9b9',
  //   borderBottom:'1px solid #b9b9b9',
  // },
  // '& .MuiDataGrid-row, .MuiDataGrid-cell':{
  //   borderBottom:'1px solid #b9b9b9',
  //   borderRight:'1px solid #b9b9b9'
  // }
  "& .MuiDataGrid-columnHeaders": { display: "none" },
  "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
};
