import React, { useEffect, useState, CSSProperties } from "react";
import DashboardHeader from "../../layout/DashboardHeader";
import {
  Expander,
  ExpanderItem,
  Flex,
  TextAreaField,
} from "@aws-amplify/ui-react";
import Table from "react-bootstrap/Table";
import { ImPencil } from "react-icons/im";
import Helper from "../../assets/Helper";
import { Buffer } from "buffer";
import { MediaCollection } from "../../ui-components";
import { ToastContainer, toast } from "react-toastify";
import CryptoJS from "crypto-js";
import MD5 from "crypto-js/md5";
import {
  Icon,
  Image,
  SelectField,
  SearchField,
  ScrollView,
  FieldGroupIcon,
  Collection,
  FieldGroupIconButton,
  Link,
} from "@aws-amplify/ui-react";
import {
  RiDeleteBin5Line,
  RiImage2Fill,
  RiEditBoxLine,
  RiImageAddFill,
  RiShareForwardLine,
  RiClapperboardLine,
} from "react-icons/ri";
import { Storage } from "@aws-amplify/storage";
import {
  BiLike,
  BiMessage,
  BiWorld,
  BiSave,
  BiReset,
  BiImageAdd,
  BiSlider,
} from "react-icons/bi";
import {
  Button,
  ButtonGroup,
  Grid,
  View,
  useTheme,
  TextField,
  Text,
  Divider,
} from "@aws-amplify/ui-react";
import { BiSkipNext } from "react-icons/bi";
import { HiDotsHorizontal, HiChevronDoubleRight } from "react-icons/hi";
import { BsHandIndexThumb } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import {
  getFBLoginInfo,
  listFBLoginInfos,
  listConfigItems,
  listContentLibraries,
  listUserInfos
} from "../../graphql/queries";
import { Form } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import { Carousel, Card, Stack } from "react-bootstrap";
// import Button from 'react-bootstrap/Button';
import { ToggleButton, ToggleButtonGroup } from "@aws-amplify/ui-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MapView } from "@aws-amplify/ui-react";
import {
  createAdDetails,
  createAdHistory,
  updateAdDetails,
} from "../../graphql/mutations";
// import TimePicker from 'react-time-picker';
// import DatePicker from 'react-date-picker';
import OutlinedInput from "@mui/material/OutlinedInput";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Tabs, TabItem, ThemeProvider, Theme } from "@aws-amplify/ui-react";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { fontSize } from "@mui/system";
import HashLoader from "react-spinners/HashLoader";
import LoadingOverlay from "react-loading-overlay";
import "../../App.css";
import Multiselect from "multiselect-react-dropdown";
// import Ad.css
import "./Ad.css";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { SwitchField } from "@aws-amplify/ui-react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { datagridSx } from "./Gridpopups";
import { listAdDetails } from "../../graphql/queries";
import { primaryButton, secondaryButton, ResetIcon } from "../../assets/style";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";

let PresignedURLFunction = "";
const Ad = ({
  fun,
  editAdValues,
  UPDT_ADSET_ID,
  SET_VERSION_FRM_AD,
  VERSION_FRM_AD,
  setforadminad_version,
  setforadmin_id,
  adsAccountId,
  campid,
  PREVIEW_FORMAT,
  SETAD_NAME_FRM_ADPAGE,
  AD_NAME_FRM_ADPAGE,
  listofplacement,
  Redirectsaveandpublish, //Redirectsaveandpublish
  FB_CAMPAIGN_ID,
  accessToken,
  PageIdFromFbLogin,
  ADSET_ID,
  setAD_ID,
  signUrl,
  setSignurl,
  VideourlfunctionforExistingAd,
  ADSETID_FRM_DB,
  ADID_FRM_DB,
  SETADID_FRM_DB,
  AD_ID,
  setad_pagestatus,
  Ad_History_ID,
  setAd_History_ID,
  AddformState,
  setAddformState,
  multimedia_url,
  Setmultimedia_url,
  DisableAfterPublish,

}) => {
  // const [AddformState, setAddformState] = useState({
  //   ad_name: "",
  //   ad_primary_text: "",
  //   media_type: "",
  //   media_file_name: "",
  //   media_file_path: "",
  //   ad_description: "",
  //   ad_headline: "",
  //   ad_website_url: "",
  //   ad_see_more_url: "",
  //   ad_see_more_display: "",
  //   call_to_action: "",
  //   insta_selected_account: "",
  //   insta_feed: "",
  //   ad_campaign_id: "",
  //   ad_account_id: "",
  //   adset_id: "",
  //   user_id: "",
  //   ad_status: "",
  // });
  var [image, setImage] = useState(
    "https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE"
  );
  const [listofCategories, setlistofCategories] = useState([]);
  // const [multimedia_url, Setmultimedia_url] = useState();
  const [listofObjectives, setlistofObjectives] = useState([]);
  const [listofBudget, setlistofBudget] = useState([]);
  const [FbAddId, set_FbAddId] = useState();
  const [imagehash, setimagehash] = useState();

  const [ShowContentLibrary, set_ShowContentLibrary] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  let [loader, setloader] = useState(false);
  const [imgkey, set_imgkey] = useState();
  const [signedurl, set_signedurl] = useState();
  const [listAdd, setlistAdd] = useState([]);
  const [staticlist, setstaticlist] = useState();
  const [SelectedAddListId, setSelectedAddListId] = useState();
  const [FlagVariable, set_FlagVariable] = useState(false);
  const [Instapresent, setInstapresent] = useState(false);
  const [InstaAccounts, set_InstaAccounts] = useState();
  const [InstaUserId, set_InstaUserId] = useState();
  //validate state variable
  const [adnamerr, setadnamerr] = useState(false);
  const [weburlerr, setweburlerr] = useState(false);
  const [callaction, setcallaction] = useState(false);
  const [instaaccount, setinstaaccount] = useState(false);
  const [Mediatype, setMediatype] = useState(false);

  /////replace adweburl to displink
  const [webdisp, setwebdisp] = useState(AddformState.ad_website_url);
  const [VideoId, setVideoId] = useState();
  const[MediaHashId,setMediaHashId]= useState();
  const[VideoorImage,setVideoorImage]=useState();
  const [ProcessingState, setProcessingState] = useState(false);

  // loader
  const [Progressbar, setProgressbar] = useState(false);

  let [pageToken, set_pageToken] = useState(false);
  function setInput(key, value) {
    setAddformState({ ...AddformState, [key]: value });
    console.log(AddformState, "CformState");

    if (AddformState.ad_see_more_display == "") {
      setwebdisp(AddformState.ad_website_url);
    }
    if (AddformState.ad_see_more_display != "") {
      setwebdisp(AddformState.ad_see_more_display);
    }
  }

  // content Library
  const [ListOfContentLibrary, setListOfContentLibrary] = useState([]);
  const getContentLibrary = async () => {
    try {
      let filter = {
        companyinfoID: { eq: localStorage.getItem("companyinfoID") },
        isDelete: { eq: false },
      };
      const listOfItem = await API.graphql({
        query: listContentLibraries,
        variables: { filter: filter },
      });

      const listofUser = await API.graphql({
        query: listUserInfos,
        variables: { filter: filter },
      });
      console.log("User",listOfItem,listofUser)
      const CognitoUserid = localStorage.getItem("Cognito_User_Id"); //particular company Id
      const ListOfContentLibrary =
        listOfItem.data.listContentLibraries.items.filter(
          (item) => item._deleted !== true || item._deleted == null && item.media_type.substring(0, 5) == "image"
        ); //Store all data
      const ListUser = listofUser.data.listUserInfos.items.filter(
        (item) => item._deleted !== true || item._deleted == null && item.media_type.substring(0, 5) == "image"
      ); //Store all data
      // Loop the All File to Get from S3

      console.log("Listofimage: ", ListOfContentLibrary);
      if (localStorage.getItem("role_id") === "6") {
        if (
          ListOfContentLibrary.length != "" &&
          ListOfContentLibrary.length != 0
        ) {
          ListUser.map((x) => {
            if (x.cognito_identity_id) {
              ListOfContentLibrary.map(async (y) => {
                if (x.id == y.userid) {
                  console.log("x.cognito_identity_id", x.cognito_identity_id);
                  const signedURL = await Storage.get(y.media_name, {
                    expires: 3600,
                    level: "protected",
                    identityId: x.cognito_identity_id,
                  });
                  y.media_url = signedURL;
                }
              });
            }
          });
          console.log("ListofimageUpdatedfsf: ", ListOfContentLibrary);
          setTimeout(() => {
            ListOfContentLibrary.map((x) => {
              x.editable = false;
            });

            setListOfContentLibrary(ListOfContentLibrary);

          }, 2000);
        }
      } else if (localStorage.getItem("role_id") === "1") {
        let filter = {
          isDelete: { eq: false },
        };
        const listOfItem = await API.graphql({
          query: listContentLibraries,
          variables: { filter: filter },
        });

        const listofUser = await API.graphql({
          query: listUserInfos,
          variables: { filter: filter },
        });
        const ListOfContentLibrary =
          listOfItem.data.listContentLibraries.items.filter(
            (item) => item._deleted !== true || item._deleted == null
          ); //Store all data
        const ListUser = listofUser.data.listUserInfos.items.filter(
          (item) => item._deleted !== true || item._deleted == null
        ); //Store all data

        if (
          ListOfContentLibrary.length != "" &&
          ListOfContentLibrary.length != 0
        ) {
          ListUser.map((x) => {
            if (x.cognito_identity_id) {
              ListOfContentLibrary.map(async (y) => {
                if (x.companyinfoID === y.companyinfoID) {
                  if (x.id == y.userid) {
                    console.log("x.cognito_identity_id", x.cognito_identity_id);
                    const signedURL = await Storage.get(y.media_name, {
                      expires: 3600,
                      level: "protected",
                      identityId: x.cognito_identity_id,
                    });
                    y.media_url = signedURL;
                  }
                }
              });
            }
          });
          console.log("ListofimageUpdatedfsf: ", ListOfContentLibrary);
          setTimeout(() => {
            setListOfContentLibrary(ListOfContentLibrary);
          }, 2000);
        }
      } else {
        if (
          ListOfContentLibrary.length != "" &&
          ListOfContentLibrary.length != 0
        ) {
          for (var i = 0; i < ListOfContentLibrary.length; i++) {
            const signedURL = await Storage.get(
              ListOfContentLibrary[i].media_name,
              {
                expires: 3600,
                level: "protected",
                identityId: CognitoUserid,
              }
            );

            ListOfContentLibrary[i].media_url = signedURL; //Get Signedurl Reassign Media_url
            console.log(
              "signedURL",
              signedURL,
              ListOfContentLibrary[i].media_url
            );
          }
          console.log("ListofimageUpdatedfsf: ", ListOfContentLibrary);
          //  setListOfFile(ListOfContentLibrary);
          // setonLoading(false);
          setTimeout(() => {
            // for description
            ListOfContentLibrary.map((x) => {
              x.Description = "";
            });
            setListOfContentLibrary(ListOfContentLibrary);
          }, 1000);
        }
      }
    } catch (err) {
      console.log("error fetching Ad", err);
    }
    console.log("ListofimageUpdateAd: ", ListOfContentLibrary);
  };

  // const getContentLibrary = async () => {
  //   try {
  //     let filter = {
  //       companyinfoID: { eq: localStorage.getItem("companyinfoID") },
  //       isDelete: { eq: false },
  //     };
  //     const listOfItem = await API.graphql({
  //       query: listContentLibraries,
  //       variables: { filter: filter },
  //     });
  //     const ListOfContentLibraryTable =
  //       listOfItem.data.listContentLibraries.items.filter(
  //         (item) =>
  //           (item._deleted !== true || item._deleted == null) &&
  //           item.media_type.substring(0, 5) == "image"
  //       ); //Store all data
  //     // Loop the All File to Get from S3
  //     console.log("Listofimage: ", ListOfContentLibraryTable);
  //     const CognitoUserid = localStorage.getItem("Cognito_User_Id"); //particular company Id
  //     if (
  //       ListOfContentLibraryTable.length != "" &&
  //       ListOfContentLibraryTable.length != 0
  //     ) {
  //       for (var i = 0; i < ListOfContentLibraryTable.length; i++) {
  //         const signedURL = await Storage.get(
  //           ListOfContentLibraryTable[i].media_name,
  //           {
  //             expires: 3600,
  //             level: "protected",
  //             identityId: CognitoUserid,
  //           }
  //         );
  //         ListOfContentLibraryTable[i].media_url = signedURL; //Get Signedurl Reassign Media_url
  //         console.log(
  //           "signedURL",
  //           signedURL,
  //           ListOfContentLibraryTable[i].media_url
  //         );
  //       }
  //       console.log("ListofimageUpdatedfsf: ", ListOfContentLibraryTable);

  //       setTimeout(() => {
  //         setListOfContentLibrary(ListOfContentLibraryTable);
  //       }, 1000);
  //     }
  //   } catch (err) {
  //     console.log("error fetching Ad", err);
  //   }
  //   console.log("ListofimageUpdateAd: ", ListOfContentLibrary);
  // };
  const [ImageSrcValue, setImageSrcValue] = useState();
  const [Editableinput, setEditableinput] = useState(true);
  const handler = (event) => {
    const ImageSrc = event.target.src;
    console.log("Index", ImageSrc); //will log the index of the clicked item
    setImageSrcValue(ImageSrc);
  };
  const [HideExisitingAdName, setHideExisitingAdName] = useState(false);
  useEffect(() => {
    console.log(editAdValues, "videoooo");
    if(signUrl!==undefined){
      if(signUrl.includes('video')){
        setMediatype(true)
        setMediaHashId(localStorage.getItem("Hash_Id"))
      }
    }
    placementcheckforinstagram();
    // console.log(signUrl + "okBye");
    // console.log(listofplacement, "listofplacement2");
    if(Editableinput){
      listAdddetails();
    }

    setloader(true);
    // if (FlagVariable == true) {
    //   getListAdDetailsUsingId();
    // }
    if (editAdValues !== undefined) {
     // localStorage.removeItem("Hash_Id");
      console.log(editAdValues, "datafound");
      setupdatedFBAd_Id(editAdValues.fb_ad_id)
      setMediaHashId(editAdValues.Media_Hash_Id);
      if(Editableinput){
      setAddformState(editAdValues);
      }
      setHideExisitingAdName(true);
      if (editAdValues.media_file_path.indexOf("video") >= 0) {
        // getVideourlfromS3(editAdValues.media_file_name);
        setMediatype(true);


      }
       if (editAdValues.ad_see_more_display == "") {
        setwebdisp(editAdValues.ad_website_url);
      }
      if (editAdValues.ad_website_url == "") {
        setwebdisp(editAdValues.ad_see_more_display);
      }


      else{
        // setImage(editAdValues.media_file_path);
        Setmultimedia_url(editAdValues.media_file_path);
      }
    }
    //   console.log(editAdValues.media_file_path,"multimedia_url")
  }, [
    FlagVariable,
    SelectedAddListId,
    webdisp,
    PageIdFromFbLogin,
    image,
    editAdValues,
    signUrl
  ]);

  const getVideourlfromS3 = async (key) => {
    const NewsignedURL = await Storage.get(key, {
      expires: 100000,
      level: "protected",
      identityId: localStorage.getItem("Cognito_User_Id"),
      customPrefix: {
        protected:
          "protected/" + localStorage.getItem("Cognito_User_Id") + "/video/",
      },
    });
    PresignedURLFunction = NewsignedURL;
    console.log("checkkkuu", PresignedURLFunction);
  };

  const placementcheckforinstagram = () => {
    console.log(listofplacement, "vvvvvv");
    try{
if(editAdValues!==undefined){
  if(JSON.parse(listofplacement).includes("instagram")){
    setInstapresent(true);
    getInstaAccounts();
  }
  else{
    setInstapresent(false);
  }
}
else{
  if (listofplacement.includes("instagram")) {
  setInstapresent(true);
  getInstaAccounts();
} else {
  setInstapresent(false);
}

}


  }
  catch(err){
    console.log(err,"kkk")
  }
  };

  const reviews = [
    { _id: 1, text: "abc" },
    { _id: 2, text: "def" },
    { _id: 3, text: "ghi" },
    { _id: 4, text: "jkl" },
    { _id: 5, text: "mno" },
    { _id: 6, text: "pqr" },
    { _id: 7, text: "stu" },
    { _id: 8, text: "vwx" },
    { _id: 9, text: "yza" },
  ];
  const { tokens } = useTheme();
  const IconArrowDropDown = () => {
    return <Icon pathData="M7 10L12 15L17 10H7Z" ariaLabel="Down arrow" />;
  };
  const [show, setShow] = useState(false);
  const [localimg, set_localimg] = useState(false);
  const [showvalueimage, setShowvalueimage] = useState(true);
  const [showvalueCaurosel, setShowvalueCaurosel] = useState(false);
  const [updatedFBAd_Id, setupdatedFBAd_Id] = useState();

  // const handleClose = () => {
  //   setShow(false);
  // };
  const [showad, setShowad] = useState(false);
  const [Advalue, setAdvalue] = useState(false);
  const [HideResetButton, setHideResetButton] = useState(false);
  const handleClosead = () => {
    setShowad(false);

    // console.log("Value", ImageSrcValue);
  };
  const SaveAd = () => {
    setHideResetButton(true);
    getListAdDetailsUsingId(SelectedAddListId);
    handleClosead();
  }
  const handleCloseadlibrary = () => {
    set_ShowContentLibrary(false);
    setImage(ImageSrcValue);
    // console.log("Value", ImageSrcValue);
  };
  const columnsforadd: GridColDef[] = [
    {
      field: "ad_name",
      flex: 1,
      minwidth: 20,
      headerName: "Campaign Name",
      align: "left",

      renderCell: (params) => {
        return (
          <Button
            color={"black"}
            size="small"
            width={"200%"}
            justifyContent={"left"}
            border={"transparent"}
            boxShadow={"none"}
            backgroundColor="transparent"
            onClick={() => ExistingAddlist(params.row.id)}
          >
            {params.value}
          </Button>
        );
      },
    },

    // {
    //   field: "edit",
    //   flex: 1,
    //   headerName: "Edit",
    //   minwidth: 150,
    //   align: "center",
    //   editable: true,
    //   renderCell: (params) => {
    //     return (
    //       <Icon
    //         as={ImPencil}
    //         fontSize={{ large: "large" }}SaveAd
    //         marginTop={{ large: "relative.xxs" }}
    //         onClick={() => ExistingAddlist(params.row.id)}
    //         color="red"
    //       ></Icon>
    //     );
    //   },
    // },
  ];

  const reset = () => {
    set_FlagVariable(false);
    setAddformState({
      ad_name: "",
      ad_primary_text: "",
      media_type: "",
      media_file_name: "",
      media_file_path: "",
      ad_headline: "",
      ad_description: "",
      ad_website_url: "",
      ad_see_more_url: "",
      ad_see_more_display: "",
      call_to_action: "",
      insta_selected_account: "",
      insta_selected_id: "",
      ad_campaign_id: "",
      ad_account_id: "",
      adset_Id: "",
      user_id: "",
      ad_status: ""
      
    });
    if(multimedia_url!=undefined)
      {
        
        Setmultimedia_url("https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE");
      }
      if(setSignurl!=undefined)
        {
          setSignurl("https://miro.medium.com/max/720/0*x9T-FxZYevvRfOyE");
        }
  };
  //Choose from Existing Add Click Event
  const ExistingAddlist = (e) => {
    //set_FlagVariable(true);
    setSelectedAddListId(e);
  };

  // const handleShow = () => {
  //   // alert("getting campaign");
  //   fun();
  //   // getCampaign();
  // };

  const handleShowimage = () => {
    setShowvalueCaurosel(false);
    setShowvalueimage(true);
  };
  const handleShowCaurosel = () => {
    setShowvalueCaurosel(true);
    setShowvalueimage(false);
  };
  const optionsfeed = [
    { value: "desktop", text: "Desktop Feed" },
    { value: "mobile", text: "Mobile Feed" },
    { value: "Right", text: "Facebook Right column" },
  ];

  const [selected, setSelected] = useState(optionsfeed[0].value);

  const handleChange = (event) => {
    setSelected(event.target.value);
    // console.log(
    //   "mobilefedd",
    //   event.target.value,
    //   setSelected(event.target.value)
    // );
  };

  const getPresignesUrl = async (File) => {
    const CognitoUserid = localStorage.getItem("Cognito_User_Id");
    const result = await Storage.put(File.name, File, {
      contentType: File.type, // contentType is optional
      level: "protected",
      customPrefix: { protected: "protected/" + CognitoUserid + "/video/" },
    });
    set_imgkey(result.key);
    const NewsignedURL = await Storage.get(result.key, {
      expires: 100000,
      level: "protected",
      identityId: CognitoUserid,
      customPrefix: { protected: "protected/" + CognitoUserid + "/video/" },
    });
    setLoading(false);

    console.log("NewsignedURL", NewsignedURL);
    return NewsignedURL;
  };

  //fetch image from Local disk
  const onImageChange = async (event) => {
    set_localimg(true);
    setProcessingState(true);
    setProgressbar(true);
    const file = event.target.files[0];

    // let url=  await getPresignesUrl(file)
    // console.log(url,"PResignedURL")

    if (event.target.files && event.target.files[0]) {
      //  setImage(URL.createObjectURL(event.target.files[0]));
      toDataURL(URL.createObjectURL(event.target.files[0])).then(
        async (dataUrl) => {
          console.log("RESULT:", dataUrl); //video
          const headers = {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
            "Content-Length": 3000000, //<calculated when request is sent>
          };

          if (dataUrl.includes("video")) {
            // alert("hi");
            PresignedURLFunction = await getPresignesUrl(file);
            console.log(PresignedURLFunction, "PresignedURLFunction");
            //For Video
            const data = new FormData();
            data.append("file_url", PresignedURLFunction);
            setSignurl(PresignedURLFunction);

            axios
              .post(
                "https://graph.facebook.com/v15.0/" +
                  adsAccountId +
                  "/advideos?&access_token=" +
                  accessToken,
                data
              )

              .then(function (responsef) {
                console.log(responsef.data.id, "advideos");
                setVideoId(responsef.data.id);
                setMediaHashId(responsef.data.id);
                localStorage.setItem("Hash_Id",responsef.data.id);
                Setmultimedia_url(PresignedURLFunction);
                setSignurl(PresignedURLFunction)
                //localStorage.setItem("mediatagcheck",true)
                setMediatype(true);
                setTimeout(()=>{
                  setProgressbar(false);
                  setProcessingState(false);
                },1000)

              })
              .catch(function (error) {
                console.log(error, "error");
                setProcessingState(false);
                setProgressbar(false);
                //toast.error("Error Saving Ad to Fb");
              });
          } else {
            setMediatype(false);
            setProcessingState(true);
            const data = new FormData();
            data.append("bytes", dataUrl.split(",")[1]);
            setImage(URL.createObjectURL(event.target.files[0]));

            //For Image
            axios
              .post(
                "https://graph.facebook.com/v15.0/" +
                  adsAccountId +
                  "/adimages?&access_token=" +
                  accessToken,
                data,
                {
                  headers: headers,
                }
              )

              .then(function (responsef) {
                console.log(responsef.data.images.bytes.hash, "adimages");
                Setmultimedia_url(responsef.data.images.bytes.url);
                setimagehash(responsef.data.images.bytes.hash);
                setMediaHashId(responsef.data.images.bytes.hash);
                setProgressbar(false);
                setProcessingState(false);
              })
              .catch(function (error) {
                toast.error("Error Saving Ad to Fb");
                setProcessingState(false);
                setProgressbar(false);
              });
          }
        }
      );
    }

    // try {
    //   const result = await Storage.put(file.name, file, {
    //     contentType: "image/png", // contentType is optional
    //   });
    //   console.log("key", result.key);
    //   set_imgkey(result.key);
    // } catch (error) {
    //   console.log("Error uploading file: ", error);
    // }
  };
  // async function imageonchange(event) {
  //   try {
  //     var file = event.target.files[0];
  //     const result = await Storage.put(file.name, file, {
  //       contentType: "image/png", // contentType is optional
  //     });
  //     console.log("key", result.key);
  //     set_imgkey(result.key);

  //   } catch (error) {
  //     console.log("Error uploading file: ", error);
  //   }
  // }

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())

      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => resolve(reader.result);

            reader.onerror = reject;

            reader.readAsDataURL(blob);
          })
      );
  //fetch image from content library
  const ShowContentPoppUp = () => {
    set_ShowContentLibrary(true);
    getContentLibrary();
  };

  const handleShowad = () => {
    setShowad(true);
  };
  ///searching existing campaign name
  const handleChanges = (value) => {
    console.log(staticlist, "test");
    var filteredItems = staticlist.filter((item, i) => {
      if (value == "") {
        return item;
      } else {
        return item.ad_name.toLowerCase().includes(value.toLowerCase());
      }
    });

    console.log(filteredItems, "filter");
    setlistAdd(filteredItems);
  };

  //Fetching the fulllist from the listAdddetails model
  const listAdddetails = async () => {
    // alert(UPDT_ADSET_ID+":UPDT_ADSET_ID")
    // alert(ADSETID_FRM_DB+":ADSETID_FRM_DB")

    let AdsetDifferId = "";
    if (
      UPDT_ADSET_ID != undefined &&
      UPDT_ADSET_ID != null &&
      UPDT_ADSET_ID != ""
    ) {
      AdsetDifferId = UPDT_ADSET_ID;
    } else if (
      localStorage.getItem("Existing_Adset_Id") !== undefined &&
      localStorage.getItem("Existing_Adset_Id") !== null &&
      localStorage.getItem("Existing_Adset_Id") !== ""
    ) {
      AdsetDifferId = localStorage.getItem("Existing_Adset_Id");
    } else {
      AdsetDifferId = ADSETID_FRM_DB;
    }

    let filter = {
      adset_Id: { eq: AdsetDifferId },
    };
    try {
      const addlist = await API.graphql({
        query: listAdDetails,
        variables: { filter: filter },
      });
      console.log(addlist.data.listAdDetails.items);
      setlistAdd(addlist.data.listAdDetails.items);
      setstaticlist(addlist.data.listAdDetails.items);
      console.log(addlist.listAdDetails, "ad");
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };

  //Fetching the specific data from the listAdddetails model
  const getListAdDetailsUsingId = async (SelectedAdid) => {
    // alert(SelectedAdid+" :bbbbb")
    try {
      let filter = {
        id: {
          eq: SelectedAdid, // filter priority = 1
        },
      };
      const addlist = await API.graphql({
        query: listAdDetails,
        variables: { filter: filter },
      });
      // console.log("%", addlist.data.listAdDetails.items[0].media_file_path);
      setAddformState(addlist.data.listAdDetails.items[0]);
      setupdatedFBAd_Id(addlist.data.listAdDetails.items[0].fb_ad_id);
      SET_VERSION_FRM_AD(addlist.data.listAdDetails.items[0]._version);
      setAD_ID(addlist.data.listAdDetails.items[0].fb_ad_id);
      SETADID_FRM_DB(addlist.data.listAdDetails.items[0].id);
      setMediaHashId(addlist.data.listAdDetails.items[0].Media_Hash_Id);
      console.log("VideoorImage",addlist.data.listAdDetails.items[0].media_file_path);
      setVideoorImage(addlist.data.listAdDetails.items[0].media_file_path);
      if (
        addlist.data.listAdDetails.items[0].media_file_path.indexOf("video") >=
        0
      ) {

        // getVideourlfromS3(addlist.data.listAdDetails.items[0].media_file_name)
        VideourlfunctionforExistingAd(
          addlist.data.listAdDetails.items[0].user_id,
          addlist.data.listAdDetails.items[0].media_file_name
        ); //function from Manage content
        //  alert("hi");
        setMediatype(true);
        // PresignedURLFunction=addlist.data.listAdDetails.items[0].media_file_path;
      } else {
        Setmultimedia_url(addlist.data.listAdDetails.items[0].media_file_path);
      }

      localStorage.setItem(
        "ExistingAdCreative_Id",
        addlist.data.listAdDetails.items[0].fb_ad_id
      );

      if (addlist.data.listAdDetails.items[0].ad_see_more_display == "") {
        setwebdisp(addlist.data.listAdDetails.items[0].ad_website_url);
      }
      if (addlist.data.listAdDetails.items[0].ad_website_url == "") {
        setwebdisp(addlist.data.listAdDetails.items[0].ad_see_more_display);
      }
    } catch (err) {
      console.log("error fetching actors" + err);
    }
  };
  //create Add details in facebook
  const CreateAddInFb = async () => {
    let asset_feed_spec = {};
    setProgressbar(true);
    if (localimg == true) {
      const S3URL =
        "https://fb-dam-storage81147-staging.s3.amazonaws.com/public/";
      image = S3URL + imgkey;
    }

    let object_story_spec = {
      page_id: PageIdFromFbLogin,
      instagram_actor_id: InstaUserId,
    };
    if (Mediatype) { //VideoId != undefined && VideoId != null && VideoId != "undefined"
      asset_feed_spec = {
        bodies: [
          {
            text: AddformState.ad_primary_text,
          },
        ],

        titles: [
          {
            text: AddformState.ad_headline,
          },
        ],

        descriptions: [
          {
            text: AddformState.ad_description,
          },
        ],

        ad_formats: ["SINGLE_VIDEO"],

        call_to_action_types: [AddformState.call_to_action],

        link_urls: [
          {
            website_url:AddformState.ad_see_more_display =="" ?AddformState.ad_see_more_display :AddformState.ad_website_url
          },
        ],

        videos: [
          {
            video_id: MediaHashId,
          },
          // {
          //   url_tags: "video=video1",
          //   video_id: "2053108854721025",
          //   thumbnail_url: "<thumnail_url>",
          //   thumbnail_hash: "<thumnail_hash>",
          // },
        ],
      };
    } else {
      asset_feed_spec = {
        images: [
          {
            //url_tags: "image=beer.webp",

            hash: MediaHashId,
          },
        ],

        bodies: [
          {
            text: AddformState.ad_primary_text,
          },
        ],

        titles: [
          {
            text: AddformState.ad_headline,
          },
        ],

        descriptions: [
          {
            text: AddformState.ad_description,
          },
        ],

        ad_formats: ["SINGLE_IMAGE"],

        call_to_action_types: [AddformState.call_to_action],

        link_urls: [
          {
            // website_url: AddformState.ad_website_url,
            website_url:AddformState.ad_see_more_display =="" ?AddformState.ad_see_more_display :AddformState.ad_website_url
          },
        ],
      };
    }

    //create Ad details in facebook

    axios({
      method: "post",

      url:
        "https://graph.facebook.com/v15.0/" +
        adsAccountId +
        "/adcreatives?name= " +
        AddformState.ad_name +
        "&object_story_spec=" +
        JSON.stringify(object_story_spec) +
        "&asset_feed_spec=" +
        JSON.stringify(asset_feed_spec) +
        "&access_token=" +
        accessToken,

      responseType: "stream",
    })
      .then(function (responsef) {
        console.log(responsef.data.id, "campaign_id");
        SETAD_NAME_FRM_ADPAGE(AddformState.ad_name);
        set_FbAddId(responsef.data.id);
        setAD_ID(responsef.data.id);
        createAdd(responsef.data.id);
        toast.success("Ad Saved Successfully");
        setad_pagestatus(true);
        setTimeout(() => {
          setProgressbar(false);
        }, 2500);
        // let adCreatives = {
        //   creative_id: responsef.data.id,
        // };
        //   axios({
        //     method: "post",
        //     url:
        //       "https://graph.facebook.com/v15.0/" +
        //       adsAccountId +
        //       "/ads?name=" +
        //       AddformState.ad_name +
        //       "&adset_id=" +
        //       parseInt(ADSET_ID) +
        //       "&creative=" +
        //       JSON.stringify(adCreatives) +
        //       "&status=PAUSED&access_token=" +
        //       accessToken,

        //     responseType: "stream",
        //   })
        //     .then(function (responsef) {
        //       console.log(responsef.data.id, "campaign_id");

        //       set_FbAddId(responsef.data.id);
        //       setAD_ID(responsef.data.id);
        //       createAdd(responsef.data.id);
        //       toast.success("Ad Saved Successfully");
        //     })
        //     .catch(function (error) {
        //       toast.error("Error Saving Ad to Fb");
        //     });
      })
      .catch(function (error) {
        setProgressbar(true);
        console.log("Ad creative Error", error.response.data.error.message);
        setad_pagestatus(false);
        if (
          error.response.data.error.message.indexOf("does not exist") >= 0 ||
          error.response.data.error.message.indexOf(
            "Invalid OAuth access token"
          ) >= 0
        ) {
          toast.error("Login credentials are not valid");
        }
        if (error.response.data.error.message.indexOf("hash") >= 0) {
          toast.error(
            "Please Upload Image/image url you have given is not valid"
          );
          setTimeout(() => {
            setProgressbar(false);
          }, 2500);
        }
        if (
          error.response.data.error.message.indexOf("call_to_action_types") >= 0
        ) {
          toast.error(
            "Please Select Call to Action/Call to Action you have given is not valid"
          );
          setTimeout(() => {
            setProgressbar(false);
          }, 2500);
        }
        if (
          error.response.data.error.error_user_msg.indexOf(
            "The URL you entered"
          ) >= 0
        ) {
          toast.error("Please Enter Valid Website URL");
          setTimeout(() => {
            setProgressbar(false);
          }, 2500);
        }
        if (
          error.response.data.error.error_user_msg.indexOf(
            "Something went wrong. Please try again later"
          )
        ) {
          toast.error("Something went wrong. Please try again later");
          setTimeout(() => {
            setProgressbar(false);
          }, 2500);
        }
        if(error.response.data.error.error_user_msg.indexOf("Hash is invalid")>= 0){
          toast.error("Invalid Media Hash");
          setProgressbar(false);
        }


        // toast.error(error.response.data.error.message);
      });
  };

  //save Ad dynamo db
  const createAdd = async (Fb_Add_id) => {
    // alert(imgkey+" : imgkey")
    setProgressbar(true);
    const S3URL =
      "https://fb-dam-storage81147-staging.s3.amazonaws.com/public/";

    const Ad = {
      ad_name: AddformState.ad_name,
      ad_primary_text: AddformState.ad_primary_text,
      media_type: Mediatype ? "video" : "image", //VIDEO
      media_file_name: imgkey,
      media_file_path: multimedia_url,
      ad_headline: AddformState.ad_headline,
      ad_website_url: AddformState.ad_see_more_display==""?AddformState.ad_website_url:AddformState.ad_see_more_display,
      ad_see_more_url: AddformState.ad_see_more_url,
      ad_see_more_display: AddformState.ad_see_more_display,
      call_to_action: AddformState.call_to_action,
      insta_selected_account: AddformState.insta_selected_account,
      insta_selected_id: InstaUserId,
      ad_description: AddformState.ad_description,
      ad_campaign_id: FB_CAMPAIGN_ID,
      ad_account_id: adsAccountId,
      adset_Id: ADSETID_FRM_DB, //ADSET_ID,
      user_id: localStorage.getItem("userid"),
      fb_ad_id: Fb_Add_id,
      ad_status: "6",
      Media_Hash_Id:MediaHashId
    };
    // console.log(obj, "adid");
    await API.graphql(graphqlOperation(createAdDetails, { input: Ad }))
      .then(async (obj) => {
        console.log(obj, "adid");
        SETADID_FRM_DB(obj.data.createAdDetails.id);
        setforadminad_version(obj.data.createAdDetails._version);
        setforadmin_id(obj.data.createAdDetails.id);
        SET_VERSION_FRM_AD(obj.data.createAdDetails._version);
        const AdDraft = await API.graphql(
          graphqlOperation(createAdHistory, {
            input: {
              ad_status_id: 6,
              addetailsID: obj.data.createAdDetails.id,
              assigned_by_userid: localStorage.getItem("userid"),
            },
          })
        );
        console.log("AdDraft", AdDraft.data.createAdHistory.id);
        setAd_History_ID(AdDraft.data.createAdHistory.id);
        // alert(ADID_FRM_DB +" : db")
        setad_pagestatus(true);
        setTimeout(() => {
          Redirectsaveandpublish();
          setProgressbar(false);
        }, 1500);
      })
      .catch(function (error) {
        setad_pagestatus(false);
        setTimeout(() => {
          setProgressbar(false);
        }, 2500);
        // toast.error("Error Saving Ad to DynamoDB");
        console.log(error.response.data.error.message, "error");
      });
  };

  //Update Ad Creative in FB and DB
  const UpdateAdCreative = () => {
   // alert(MediaHashId+" : MediaHashId");
    let asset_feed_spec = {};

    setProgressbar(true);
    if (localimg == true) {
      const S3URL =
        "https://fb-dam-storage81147-staging.s3.amazonaws.com/public/";
      image = S3URL + imgkey;
    }

    let object_story_spec = {
      page_id: PageIdFromFbLogin,
      instagram_actor_id: InstaUserId,
    };
   
    let UpdateAdMediaHash = MediaHashId?MediaHashId:localStorage.getItem("Hash_Id");

    console.log("HHHH",MediaHashId,localStorage.getItem("Hash_Id"),UpdateAdMediaHash)
    if (Mediatype) { //VideoId != undefined && VideoId != null && VideoId != "undefined"
      asset_feed_spec = {
        bodies: [
          {
            text: AddformState.ad_primary_text,
          },
        ],

        titles: [
          {
            text: AddformState.ad_headline,
          },
        ],

        descriptions: [
          {
            text: AddformState.ad_description,
          },
        ],

        ad_formats: ["SINGLE_VIDEO"],

        call_to_action_types: [AddformState.call_to_action],

        link_urls: [
          {
            website_url:  AddformState.ad_see_more_display==""?AddformState.ad_website_url:AddformState.ad_see_more_display,
          },
        ],

        videos: [
          {
            video_id: UpdateAdMediaHash,
          },
          // {
          //   url_tags: "video=video1",
          //   video_id: "2053108854721025",
          //   thumbnail_url: "<thumnail_url>",
          //   thumbnail_hash: "<thumnail_hash>",
          // },
        ],
      };
    } else {
      asset_feed_spec = {
        images: [
          {
            //url_tags: "image=beer.webp",

            hash: UpdateAdMediaHash,
          },
        ],

        bodies: [
          {
            text: AddformState.ad_primary_text,
          },
        ],

        titles: [
          {
            text: AddformState.ad_headline,
          },
        ],

        descriptions: [
          {
            text: AddformState.ad_description,
          },
        ],

        ad_formats: ["SINGLE_IMAGE"],

        call_to_action_types: [AddformState.call_to_action],

        link_urls: [
          {
            website_url:  AddformState.ad_see_more_display==""?AddformState.ad_website_url:AddformState.ad_see_more_display,
          },
        ],
      };
    }

    //update Ad Creative in facebook

    let AdIdforUpdate = updatedFBAd_Id != undefined ?updatedFBAd_Id:AD_ID


    axios({
      method: "post",

      url:
        "https://graph.facebook.com/v15.0/" +
        AdIdforUpdate +
        "?name= " +
        AddformState.ad_name +
        "&object_story_spec=" +
        JSON.stringify(object_story_spec) +
        "&asset_feed_spec=" +
        JSON.stringify(asset_feed_spec) +
        " &access_token=" +
        accessToken,

      responseType: "stream",
    })
      .then(async () => {
        let Update_Id=""
        let Update_Version=""
        if(editAdValues!==undefined){
          Update_Id =editAdValues.id
          Update_Version=editAdValues._version

        }
        else{
          Update_Id=ADID_FRM_DB
          Update_Version=VERSION_FRM_AD

        }
        //update AdDetails(Creative) in DB
        const AdUpdate = {
          id: Update_Id,
          ad_name: AddformState.ad_name,
          ad_primary_text: AddformState.ad_primary_text,
          media_type: "image",
          media_file_name: imgkey,
          media_file_path: multimedia_url,
          ad_headline: AddformState.ad_headline,
          ad_website_url: AddformState.ad_website_url,
          ad_see_more_url: AddformState.ad_see_more_url,
          ad_see_more_display: AddformState.ad_see_more_display,
          call_to_action: AddformState.call_to_action,
          insta_selected_account: AddformState.insta_selected_account,
          insta_selected_id: InstaUserId,
          ad_description: AddformState.ad_description,
          ad_status: "6",
          _version: Update_Version,
          Media_Hash_Id:MediaHashId
        };

        await API.graphql(
          graphqlOperation(updateAdDetails, { input: AdUpdate })
        );

        toast.success("Ad Updated Successfully");
        setad_pagestatus(true);
        setTimeout(() => {
          Redirectsaveandpublish();
          setProgressbar(false);
        }, 1500);
      })
      .catch(function (error) {
        console.log("Ad creative Error", error);
        setad_pagestatus(false);
        if (
          error.response.data.error.message.indexOf("does not exist") >= 0 ||
          error.response.data.error.message.indexOf(
            "Invalid OAuth access token"
          ) >= 0
        ) {
          toast.error("Login credentials are not valid");
        }
        if (error.response.data.error.message.indexOf("hash") >= 0) {
          toast.error(
            "Please Upload Image/image url you have given is not valid"
          );
        }
        if (
          error.response.data.error.message.indexOf("call_to_action_types") >= 0
        ) {
          toast.error(
            "Please Select Call to Action/Call to Action you have given is not valid"
          );
        }
        if (
          error.response.data.error.error_user_msg.indexOf(
            "The URL you entered"
          ) >= 0
        ) {
          toast.error("Please Enter Valid Website URL");
        }
        setTimeout(() => {
          setProgressbar(false);
        }, 2500);
      });
  };

  //Fetching Instagram Accounts
  const getInstaAccounts = async () => {
    //page access token
    axios({
      method: "get",

      url:
        "https://graph.facebook.com/" +
        PageIdFromFbLogin +
        "?fields=access_token&access_token=" +
        accessToken,

      responseType: "stream",
    })
      .then(function (responsef) {
        console.log(responsef.data.access_token, "page_token");
        axios({
          method: "get",

          url:
            "https://graph.facebook.com/v15.0/" +
            PageIdFromFbLogin +
            "/instagram_accounts?fields=id,username&access_token=" +
            responsef.data.access_token,

          responseType: "stream",
        })
          .then(function (responsef) {
            console.log(responsef.data.data, "instagram_accounts");
            responsef.data.data.map((item) => {
              if (item.username === AddformState.insta_selected_account) {
                set_InstaUserId(item.id);
              }
            });
            set_InstaAccounts(responsef.data.data);
            // set_InstaUserId(responsef.data.data[0].id);
          })

          .catch(function (error) {
            console.log("Instaaccfetcherror", error);
          });
      })
      .catch(function (error) {
        console.log("page token Error", error);
      });
  };

  ////validate
  const validate = () => {
    if (AddformState.ad_name == "") {
      setadnamerr(true);
    } else {
      setadnamerr(false);
      
    }

    if (AddformState.ad_website_url == "") {
      setweburlerr(true);
    } else {
      const isValid = validateURL(AddformState.ad_website_url);
      console.log(AddformState.ad_website_url,'url')

          if (AddformState.ad_website_url.endsWith('.com')) {
           setweburlerr(false);
          
          } 
          else {
            setweburlerr(true)
            toast.error('Invalid Website URL')
          }
            
  }

    if (AddformState.call_to_action == "") {
      setcallaction(true);
    } else {
      setcallaction(false);
    }

    if (AddformState.insta_selected_account == "") {
      setinstaaccount(true);
    } else {
      setinstaaccount(false);
    }

    if (
      (SelectedAddListId != undefined || editAdValues!=undefined) && AddformState.ad_name != "" && AddformState.ad_website_url != "" && AddformState.call_to_action != "" && !weburlerr
    ) {
      try{
     
      if(listofplacement.includes("instagram")){
        if(AddformState.insta_selected_account != ""){
          UpdateAdCreative();
        }
        else{
          toast.info("Please Select Instagram Account");
        }
      }
      else{
        UpdateAdCreative();
       }
      }
      catch(e){
        console.log(e,"errorrru");
        UpdateAdCreative();
      }
    } else if(AddformState.ad_name != "" && AddformState.ad_website_url != "" && AddformState.call_to_action != "" && !weburlerr) {
      console.log(editAdValues,"editAdValues111");
     
     if(listofplacement.includes("instagram")){
      if(AddformState.insta_selected_account != ""){
        if(AD_ID!==undefined){
           UpdateAdCreative();
        }else{
            CreateAddInFb();
        }  
      }
      else{
        toast.info("Please Select Instagram Account");
      }
    }
    else{
      if(AD_ID!==undefined){
        UpdateAdCreative();
      }else{
        CreateAddInFb();
      }  
    }
    }

    if(AddformState.ad_name == "" || AddformState.ad_website_url == ""|| AddformState.call_to_action == "")
      {
        toast.error("Please Fill manadatory fields")

    setTimeout(() => {
      setProgressbar(false)
    }, 2500);
      }

     

  };

  const validateURL = (url) => {
    // const regex = /^(?:http|ftp)s?:\/\/[^\s/$.?#].[^\s]*$/i;
    const regex = /^(?:http|https|ftp|ftps):\/\/[^\s/$.?#].[^\s]*\.com$/i;
    return regex.test(url);
  };
  ///onchange validate inputfield
  const validinput = (key, value) => {
    setEditableinput(false)
    if (key == "ad_name") {
      if (value == "") {
        setadnamerr(true);
      } else {
        setadnamerr(false);
      }
    }
    if (key == "ad_website_url") {
      if (value == "") {
        setweburlerr(true);
      }
      else {
        if (value.endsWith(".com")) {
          setweburlerr(false);
        }else{
          setweburlerr(true);
        }
        
      }
    }
    if (key == "call_to_action") {
      if (value == "") {
        setcallaction(true);
      } else {
        setcallaction(false);
      }
    }

    if (key == "insta_selected_account") {
      if (value == "") {
        setinstaaccount(true);
      } else {
        setinstaaccount(false);
      }
    }
    setInput(key, value);
  };

  // //Ad preview in facebook
  // const AdPreview = async () => {
  //   axios({
  //     method: "get",
  //     url:
  //       "https://graph.facebook.com/v15.0/" +
  //       FbAddId +
  //       "/previews?ad_format=DESKTOP_FEED_STANDARD&access_token=" +
  //       accessToken,
  //     responseType: "stream",
  //   }).then(function (responsef) {
  //     console.log(responsef.data.previews, "preview");
  //     // set_AdPreview(responsef.data.previews);
  //   });
  // };

  // const handleShowad = () => {
  //   setShowad(true);
  // };
  return (
    <>
      <ToastContainer autoClose={1500} limit={0} />
      <Flex
        direction={{
          base: "column",
          small: "column",
          medium: "column",
          large: "column",
          xl: "column",
          xxl: "column",
        }}
        gap="1rem"
        style={{ border: "2px solid #E8E8E8", borderRadius: "0.5em" }}
      >
        <Flex
          direction={{
            base: "column",
            small: "column",
            medium: "row",
            large: "row",
            xl: "row",
            xxl: "row",
          }}
        >
          <View
            padding={{ base: "1em", large: "2em" }}
            alignItems="center"
            width="100%"
          >
            <TextField
              style={{
                alignItems: "left",
                justifyContent: "left",
              }}
              value={AddformState.ad_name}
              onChange={(e) => {
                validinput("ad_name", e.target.value);
              }}
              hasError={adnamerr}
              disabled={DisableAfterPublish}//DisableAfterPublish
              //label="Ad Name"
              label={
                <Text>
                  Ad Name
                  <Text as="span" fontSize="0.8rem" color="red">
                    {" "}
                    *{" "}
                  </Text>
                </Text>
              }
            />
            {!HideExisitingAdName ?(<Text
              style={{ fontWeight: "500", cursor: "pointer" }}
              color="#b71f39"
              onClick={handleShowad}
              textAlign="right"
              marginTop={"0.5em"}
              marginBottom={{ base: "0em", medium: "1em", large: "0em" }}
              fontSize={{
                base: "1em",
                small: "1em",
                medium: "0.8em",
                large: "1em",
              }}
              value={AddformState.ad_name}
              onChange={(e) => {
                validinput("ad_name", e.target.value);
              }}
              disabled={DisableAfterPublish}
            >
              Choose from Existing Ad
            </Text>):<Text marginTop="1em"></Text>}
            <TextAreaField
              alignItems="left"
              justifyContent="left"
              label="Primary text"
              value={AddformState.ad_primary_text}
              onChange={(e) => {
                setInput("ad_primary_text", e.target.value);
              }}
              disabled={DisableAfterPublish}
            />
            <Text
              style={{ marginTop: "1em" }}
              alignItems="left"
              justifyContent="left"
            >
              Add Media
            </Text>
            <ButtonGroup>
              {showvalueimage == true ? (
                <Button
                  backgroundColor="#680250"
                  style={primaryButton}
                  boxShadow="large"
                  color="white"
                  marginTop="20px"
                  borderRadius={"1em"}
                  border="1px solid #680250"
                  onClick={handleShowimage}
                  fontSize={{ base: "xs", medium: "small", large: "small" }}
                  disabled={DisableAfterPublish}
                >
                  Image or video{" "}
                  <Icon
                    as={BiImageAdd}
                    fontSize={{ large: "xl" }}
                    color="white"
                    fontWeight={"bold"}
                    marginLeft="0.3em"
                    marginTop={"0.2em"}
                  ></Icon>
                </Button>
              ) : (
                <Button
                  backgroundColor="white"
                  style={primaryButton}
                  color="#680250"
                  boxShadow="large"
                  marginTop="20px"
                  className="radient"
                  borderRadius={"1em"}
                  onClick={handleShowimage}
                  fontSize={{ base: "xs", medium: "small", large: "small" }}
                  disabled={DisableAfterPublish}
                >
                  Image or video{" "}
                  <Icon
                    as={BiImageAdd}
                    fontSize={{ large: "xl" }}
                    color="#680250"
                    fontWeight={"bold"}
                    marginLeft="0.3em"
                    marginTop={"0.2em"}
                  ></Icon>
                </Button>
              )}
              {showvalueCaurosel == true ? (
                <Button
                  backgroundColor="#680250"
                  style={primaryButton}
                  boxShadow="large"
                  color="white"
                  marginTop="20px"
                  border="1px solid #680250"
                  marginBottom={"1em"}
                  borderRadius="2em"
                  onClick={handleShowCaurosel}
                  fontSize={{ base: "xs", medium: "small", large: "small" }}
                  disabled={DisableAfterPublish}
                >
                  Caurosel{" "}
                  <Icon
                    as={BiSlider}
                    fontSize={{ large: "xl" }}
                    color="white"
                    fontWeight={"bold"}
                    marginLeft="0.3em"
                    marginTop={"0.2em"}
                  ></Icon>
                </Button>
              ) : (
                <Button
                  backgroundColor="white"
                  style={primaryButton}
                  color="#680250"
                  boxShadow="large"
                  marginTop="20px"
                  marginBottom={"1em"}
                  borderRadius="2em"
                  onClick={handleShowCaurosel}
                  fontSize={{ base: "xs", medium: "small", large: "small" }}
                  disabled={DisableAfterPublish}
                >
                  Caurosel{" "}
                  <Icon
                    as={BiSlider}
                    fontSize={{ large: "xl" }}
                    color="#680250"
                    fontWeight={"bold"}
                    marginLeft="0.3em"
                    marginTop={"0.2em"}
                  ></Icon>
                </Button>
              )}
            </ButtonGroup>
            {showvalueimage == true ? (
              <View>
                <Card
                  style={{
                    border: "1px solid black",
                    marginTop: "10px",
                    padding: "1em",
                  }}
                >
                  <Grid templateColumn="1rem" gap={tokens.space.xl}>
                    <Flex
                      direction="row"
                      justifyContent="center"
                      style={{ marginTop: "-1em" }}
                    >
                      <ToggleButtonGroup
                        isExclusive
                        border="none"
                        margin="0em"
                        className="custom-tabstoggle"
                        tooltip="Browse From Local"
                        disabled={DisableAfterPublish}

                      >
                        <ToggleButton
                          // style={{ boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)', backgroundColor: 'rgb(5 84 164 / 46%)', backdropFilter: 'blur(2px)', border: 'none' }}
                          className="custom-tab-itemtoggle"
                          disabled={DisableAfterPublish}
                        >
                          <input
                            className="choosefile"
                            type="file"
                            name="myImage"
                            id="browse"
                            onChange={onImageChange}
                          />
                          <Icon
                            target="#browse"
                            as={RiImage2Fill}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton>
                        <ToggleButton
                          className="custom-tab-itemtoggle"
                          tooltip="Browse From Content Library"
                          disabled={DisableAfterPublish}
                          onClick={ShowContentPoppUp}
                        >
                          <Icon
                            as={RiClapperboardLine}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton>
                        {/* <ToggleButton className="custom-tab-itemtoggle" disabled={DisableAfterPublish}>
                          <Icon
                            as={RiDeleteBin5Line}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton> */}
                        {/* <ToggleButton className="custom-tab-itemtoggle">
                          <Icon
                            as={RiEditBoxLine}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton>
                        <ToggleButton className="custom-tab-itemtoggle">
                          <Icon
                            as={RiImageAddFill}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton> */}
                      </ToggleButtonGroup>
                    </Flex>

                    <Card
                      style={{
                        color: "black",
                        border: "none",
                        marginTop: "-4em",
                      }}
                    >
                      <Card.Body style={{ padding: "0em" }}>
                        <Card.Text>
                          {!Mediatype ? (
                            <Image
                              className="img-fluid rounded img-responsive"
                              src={
                                multimedia_url != undefined
                                  ? multimedia_url
                                  : image
                              }
                              // alt="Second slide"
                              minHeight={{
                                base: "30vh",
                                small: "30vh",
                                medium: "30vh",
                                large: "30vh",
                                xl: "40vh",
                                xxl: "40vh",
                              }}
                              maxHeight={{
                                base: "30vh",
                                small: "30vh",
                                medium: "30vh",
                                large: "30vh",
                                xl: "40vh",
                                xxl: "40vh",
                              }}
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                backgroundColor: "#8988881a",
                                borderRadius: "0.5em",
                              }}
                            />
                          ) : ( <>

                          {signUrl !==undefined?( <video
                              minHeight={{
                                base: "30vh",
                                small: "30vh",
                                medium: "30vh",
                                large: "30vh",
                                xl: "40vh",
                                xxl: "40vh",
                              }}
                              maxHeight={{
                                base: "30vh",
                                small: "30vh",
                                medium: "30vh",
                                large: "30vh",
                                xl: "40vh",
                                xxl: "40vh",
                              }}
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                backgroundColor: "#8988881a",
                                borderRadius: "0.5em",
                              }}
                              controls
                            >
                              <source
                                src={signUrl !== undefined ? signUrl : image}
                                // src={
                                //   multimedia_url !== ""
                                //     ? multimedia_url
                                //     : image
                                // }

                                //multimedia_url
                                type="video/mp4"
                              />
                            </video>):null}

                            </> )}

                       
                        </Card.Text>
                       
                      </Card.Body>
                    </Card>
                  </Grid>
                </Card>
                <Flex direction="column" marginTop="0.5em">
                  <TextField
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    label="Headline"
                    value={AddformState.ad_headline}
                    onChange={(e) => {
                      setInput("ad_headline", e.target.value);
                    }}
                    disabled={DisableAfterPublish}
                  />
                  <TextField
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    label="Description (Optional)"
                    value={AddformState.ad_description}
                    onChange={(e) => {
                      setInput("ad_description", e.target.value);
                    }}
                    disabled={DisableAfterPublish}
                  />
                  <TextField
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    // label="Website URL"

                    label={
                      <Text>
                        Website URL
                        <Text as="span" fontSize="0.8rem" color="red">
                          {" "}
                          *
                        </Text>
                      </Text>
                    }
                    value={AddformState.ad_website_url}
                    onChange={(e) => {
                      validinput("ad_website_url", e.target.value);
                    }}
                    onInput={(e) => {
                      validinput("ad_website_url", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      validinput("ad_website_url", e.target.value);
                    }}
                    onKeyUp={(e) => {
                      validinput("ad_website_url", e.target.value);
                    }}
                    // onClick={(e) => {
                    //  setInput("ad_website_url", e.target.value);
                    // }}

                    hasError={weburlerr}
                    disabled={DisableAfterPublish}
                  />
                  {/* <TextField
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label="See more URL (optional)"
                      value={AddformState.ad_see_more_url}
                      onChange={(e) => {
                        setInput("ad_see_more_url", e.target.value);
                      }}
                      disabled={FlagVariable}
                    /> */}
                  <TextField
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    label="Display Link (optional)"
                    value={AddformState.ad_see_more_display}
                    onChange={(e) => {
                      setInput("ad_see_more_display", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      setInput("ad_see_more_display", e.target.value);
                    }}
                    onClick={(e) => {
                      setInput("ad_see_more_display", e.target.value);
                    }}
                    onKeyUp={(e) => {
                      validinput("ad_see_more_display", e.target.value);
                    }}
                    disabled={DisableAfterPublish}
                  />
                  <SelectField
                    label={
                      <Text>
                        Call to action
                        <Text as="span" fontSize="0.8rem" color="red">
                          {" "}
                          *
                        </Text>
                      </Text>
                    }
                    icon={<IconArrowDropDown />}
                    iconColor="black"
                    placeholder="Select Call to action"
                    value={AddformState.call_to_action}
                    onChange={(e) => {
                      setInput("call_to_action", e.target.value);
                    }}
                    hasError={callaction}
                    disabled={DisableAfterPublish}
                  >
                    <option value="" selected disabled hidden></option>
                    <option value="APPLY_NOW">Apply Now</option>
                    <option value="LEARN_MORE">Learn More</option>
                    <option value="ORDER_NOW">Order Now</option>
                    <option value="SHOP_NOW">Shop Now</option>
                    <option value="CONTACT_US">Contact Us</option>
                  </SelectField>
                  {Instapresent && (
                    <SelectField
                      // label="Instagram Accounts"
                      label={
                        <Text>
                          Instagram Accounts
                          <Text as="span" fontSize="0.8rem" color="red">
                            {" "}
                            *
                          </Text>
                        </Text>
                      }
                      icon={<IconArrowDropDown />}
                      iconColor="black"
                      placeholder="Select Account"
                      value={AddformState.insta_selected_account}
                      onChange={(e) => {
                        setInput("insta_selected_account", e.target.value);
                      }}
                      hasError={instaaccount}
                      disabled={DisableAfterPublish}
                    >
                      {/* <option selected disabled>Select Account</option> */}
                      {InstaAccounts !== undefined && InstaAccounts !== null
                        ? InstaAccounts.map((row, key) => (
                            <option key={key} value={row?.username}>
                              {row?.username}
                            </option>
                          ))
                        : null}
                    </SelectField>
                  )}
                </Flex>
                <Flex
                  direction="row"
                  marginTop={{ base: "xl", large: "xl" }}
                  justifyContent="center"
                >
                  <Button
                    backgroundColor="#8EC641"
                    style={primaryButton}
                    boxShadow="large"
                    //onClick={CreateAddInFb}
                    onClick={validate}
                    borderRadius="2em"
                    isLoading={Progressbar}
                    loadingText={
                      ProcessingState ? "File Uploading..." : "processing..."
                    }
                    disabled={DisableAfterPublish}
                  >
                    Save{" "}
                    <Icon
                      as={BiSave}
                      fontSize={{ large: "large" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.3em"
                    ></Icon>
                  </Button>
                 { HideResetButton || HideExisitingAdName ? null :(<Button
                    backgroundColor="#df2121"
                    style={primaryButton}
                    boxShadow="large"
                    borderRadius="2em"
                    onClick={reset}
                    disabled={DisableAfterPublish}
                  >
                    Reset
                    <Icon
                      as={BiReset}
                      fontSize={{ large: "large" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.3em"
                    ></Icon>
                  </Button>)}
                </Flex>
              </View>
            ) : null}
            {showvalueCaurosel == true ? (
              <View>
                <Card
                  style={{
                    border: "1px solid black",
                    marginTop: "10px",
                    padding: "1em",
                  }}
                >
                  <Grid templateColumn="1rem" gap={tokens.space.xl}>
                    <Flex
                      direction="row"
                      justifyContent="center"
                      style={{ marginTop: "-1em" }}
                    >
                      <ToggleButtonGroup
                        className="custom-tabstoggle"
                        isExclusive
                      >
                        <ToggleButton className="custom-tab-itemtoggle">
                          <Icon
                            as={RiImage2Fill}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton>
                        <ToggleButton className="custom-tab-itemtoggle">
                          <Icon
                            as={RiDeleteBin5Line}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton>
                        <ToggleButton className="custom-tab-itemtoggle">
                          <Icon
                            as={RiEditBoxLine}
                            fontSize={{ large: "large" }}
                          ></Icon>
                        </ToggleButton>
                        {/* <ToggleButton color='white'  backgroundColor="#0b81d5" ><Icon as={RiImageAddFill} fontSize={{ large: "large" }}></Icon></ToggleButton> */}
                      </ToggleButtonGroup>
                    </Flex>
                    <Carousel color="black" style={{ marginTop: "-4em" }}>
                      {reviews.map((review, index) => (
                        <Carousel.Item>
                          <Card style={{ color: "black", border: "none" }}>
                            <Card.Body style={{ padding: "0em" }}>
                              {/* <Card.Title>Card Title</Card.Title> */}
                              <Card.Text>
                                <Image
                                  className="img-fluid img-responsive"
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWgurc8A7bamFxsVpb4MRytYoYkLyYbXN_Gw&usqp=CAU"
                                  // alt="Second slide"
                                  minHeight={{
                                    base: "30vh",
                                    small: "30vh",
                                    medium: "30vh",
                                    large: "30vh",
                                    xl: "40vh",
                                    xxl: "40vh",
                                  }}
                                  maxHeight={{
                                    base: "30vh",
                                    small: "30vh",
                                    medium: "30vh",
                                    large: "30vh",
                                    xl: "40vh",
                                    xxl: "40vh",
                                  }}
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    backgroundColor: "white",
                                    border:
                                      "1px solid var(--bs-border-color-translucent)",
                                    borderRadius: "0.5em",
                                  }}
                                />
                              </Card.Text>
                              <Flex direction="column" marginTop="0.5em">
                                <TextField
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  label="Headline"
                                />
                                <TextField
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  label="Description (Optional)"
                                />
                                <TextField
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  label="Website URL"
                                />
                              </Flex>
                            </Card.Body>
                          </Card>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Grid>
                </Card>
                <Flex direction="column" marginTop="0.5em">
                  <TextField
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    label="See more URL (optional)"
                  />
                  <TextField
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    label="See moreDisplay Link (optional)"
                  />
                  <SelectField
                    label="Call to action"
                    icon={<IconArrowDropDown />}
                    iconColor="black"
                    placeholder=""
                    disabled={FlagVariable}
                  >
                    <option value="" selected disabled hidden></option>
                    <option value="one">Ad Campaign1</option>
                    <option value="two">Ad Campaign2</option>
                    <option value="three">Ad Campaign3</option>
                  </SelectField>
                </Flex>
                <Flex
                  direction="row"
                  marginTop={{ base: "xl", large: "xl" }}
                  justifyContent="center"
                >
                  <Button
                    backgroundColor="#8EC641"
                    style={primaryButton}
                    boxShadow="large"
                    borderRadius="2em"
                  >
                    Save{" "}
                    <Icon
                      as={BiSave}
                      fontSize={{ large: "large" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.3em"
                    ></Icon>
                  </Button>
                  <Button
                    backgroundColor="#df2121"
                    style={primaryButton}
                    boxShadow="large"
                    borderRadius="2em"
                    onClick={reset}
                  >
                    Reset
                    <Icon
                      as={BiReset}
                      fontSize={{ large: "large" }}
                      color="white"
                      fontWeight={"bold"}
                      marginLeft="0.3em"
                    ></Icon>
                  </Button>
                </Flex>
              </View>
            ) : null}
          </View>
          <Divider orientation="vertical" size="small" />
          <View
            padding={{ base: "1em", large: "2em" }}
            alignItems="center"
            width="100%"
            marginTop={{ base: "2em", large: "0em" }}
          >
            <Flex
              direction={{ base: "column", large: "row" }}
              gap={{ base: "small", large: "xl" }}
            >
              <Text
                fontSize={"1.2em"}
                fontWeight="400"
                marginTop={{ base: "-4em", medium: "5px", large: "5px" }}
              >
                Preview
              </Text>
              <SelectField
                labelHidden
                icon={<IconArrowDropDown />}
                iconColor="black"
                // onChange={(event) => previewWithDevice("preview", event.target.value)}
                width={{ base: "100%", medium: "100%", large: "30%" }}
                value={selected}
                onChange={handleChange}
                //</Flex>  selected={selected == "desktop"}
              >
                <option value="desktop" selected>Desktop Feed</option>
                <option value="mobile">Mobile Feed</option>
                <option value="Right">Facebook Right column</option>
              </SelectField>
              {/* {PREVIEW_FORMAT !== undefined
                    ? PREVIEW_FORMAT.map((row, key) => (
                        <option key={key} value={row?.name}>
                          {row?.name}
                        </option>
                      ))
                    : ""} */}
            </Flex>
            {selected == "desktop" ? (
              <Card
                style={{
                  backgroundColor: "rgb(213 208 208 / 82%)",
                  paddingTop: "3em",
                  paddingBottom: "3em",
                  marginTop: "3em",
                  border: "none",
                }}
              >
                <Card
                  style={{ color: "black", borderRadius: "0em" }}
                  className="shadow-lg"
                >
                  <Card.Header>
                    <Flex direction={{ base: "row", large: "row" }}>
                      <Image
                        src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                        width={{ base: 35, medium: 40, large: 40 }}
                        height={{ base: 35, medium: 40, large: 40 }}
                        alt="Avatar"
                        style={{
                          //Below lines will help to set the border radius
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      />
                      <Text
                        fontWeight={"600"}
                        fontSize={{ base: "0.7em", large: "1em" }}
                      >
                        Web Design Spot <br />
                        <Text fontSize={"0.6em"} color="grey">
                          Sponsered{" "}
                          <Icon
                            as={BiWorld}
                            fontSize={{ large: "small" }}
                            color="grey"
                          ></Icon>
                        </Text>
                      </Text>
                      <Icon
                        as={HiDotsHorizontal}
                        fontSize={{ large: "xl" }}
                        alignItems="right"
                        justifyContent="right"
                        marginLeft={"auto"}
                        marginTop="0.5em"
                      ></Icon>
                    </Flex>
                    <Flex direction={{ base: "row", large: "row" }}>
                      <Text fontWeight={"600"}>
                        {AddformState.ad_primary_text}
                      </Text>
                    </Flex>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Card.Text>
                      {!Mediatype ? (
                        <Image
                          className="img-responsive"
                          src={
                            multimedia_url != undefined ? multimedia_url : image
                          }
                          // alt="Second slide"
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            backgroundColor: "white",
                            maxHeight: "30vh",
                            borderBottom:
                              "1px solid var(--bs-border-color-translucent)",
                            // borderRadius: "0.5em",
                          }}
                        />
                      ) : (
                        <>

                        {signUrl !==undefined?(
                        <video
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            backgroundColor: "white",
                            maxHeight: "30vh",
                            borderBottom:
                              "1px solid var(--bs-border-color-translucent)",
                            // borderRadius: "0.5em",
                          }}
                          controls
                        >
                          <source
                            src={signUrl !== undefined ? signUrl : image}
                            // src={
                            //   multimedia_url !== ""
                            //     ? multimedia_url
                            //     : image
                            // }
                            type="video/mp4"
                          />
                        </video>):null}</>
                      )}
                      <Flex
                        direction={{ base: "row", large: "row" }}
                        gap={"0em"}
                      >
                        <View
                          padding="1em"
                          alignItems="center"
                          width={{
                            base: "100%",
                            medium: "50%",
                            large: "100%",
                          }}
                        >
                          <Text color="grey">{webdisp}</Text>
                          <br />{" "}
                          <Text fontWeight={"600"}>
                            {AddformState.ad_headline}
                          </Text>
                          <Text fontWeight={"600"}>
                            {AddformState.ad_description}
                          </Text>
                        </View>
                        <View
                          padding="1em"
                          width={{
                            base: "120%",
                            medium: "50%",
                            large: "50%",
                          }}
                          marginLeft={"auto"}
                          margin="1em"
                        >
                          <Button
                            size="small"
                            style={primaryButton}
                            boxShadow="large"
                            backgroundColor="#B71F39"
                            marginLeft={"auto"}
                            width="100%"
                            font-size={{ base: "0.7em", large: "1.3em" }}
                            hidden={!AddformState.call_to_action}
                          >
                            {AddformState.call_to_action
                              .replace(/_/g, "  ")
                              .replace(
                                /(^\w|\s\w)(\S*)/g,
                                (_, m1, m2) =>
                                  m1.toUpperCase() + m2.toLowerCase()
                              )}
                          </Button>
                        </View>
                      </Flex>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="p-0">
                    <Flex
                      direction={{ base: "row", large: "row" }}
                      gap={{ base: "0em", large: "1em" }}
                    >
                      {/* <Button
                        variation="link"
                        color={"black"}
                        fontWeight="500"
                        padding={{ base: "0.5em" }}
                      >
                        {" "} */}
                      <Icon
                        as={BiLike}
                        // marginTop="-0.1em"
                        // fontSize={{ medium: "xl" }}
                        // marginRight="-1.5em"
                        // fontWeight="200"
                        // //paddingLeft="35px"
                        // paddingRight={{
                        //   base: "0.5em",
                        //   medium: "1em",
                        //   large: "1em",
                        // }}
                        // margintop="2px"
                        // height="24px"
                        // paddingLeft="1em"
                        fontSize={{
                          base: "medium",
                          medium: "medium",
                          small: "medium",
                      }}
                      //  marginLeft={"auto"}
                      //  marginTop="0.5em"
                      padding={{ base: "-0.5em" }}
                     marginRight="1em"
                     fontWeight="500"
                     marginTop="0.3em"
                     marginLeft="1em"
                      ></Icon>{" "}
                      Like
                      {/* </Button> */}
                      {/* <Button
                        variation="link"
                        color={"black"}
                        fontWeight="500"
                        padding={{ base: "0.5em" }}
                      >
                        {" "} */}
                      <Icon
                        as={BiMessage}
                        //margintop="-0.1"
                        // fontSize={{ medium: "xl" }}
                        // marginRight="-1.5em"
                        // fontWeight="200"
                        // paddingRight={{
                        //   base: "0.5em",
                        //   medium: "1em",
                        //   large: "1em",
                        // }}
                        // margintop="4px"
                        // height="24px"


                        padding={{ base: "-0.5em" }}
                        marginRight="0.9em"
                        marginTop="4px"
                       marginLeft="1em"

                        //fontSize={{ medium:"15.5px"}}
                        fontSize={{
                         base: "medium",
                         medium: "medium",
                         large: "medium",
                         xl:""
                     }}
                      ></Icon>{" "}
                      Command
                      {/* </Button> */}
                      {/* <Button
                        variation="link"
                        color={"black"}
                        fontWeight="500"
                        padding={{ base: "0.5em" }}
                      >
                        {" "} */}
                      <Icon
                        as={RiShareForwardLine}
                        //margintop="-0.1"
                        // fontSize={{ medium: "xl" }}
                        // marginRight="-1.5em"
                        // fontWeight="200"
                        // paddingRight={{
                        //   base: "0.5em",
                        //   medium: "1em",
                        //   large: "1em",
                        // }}
                        // margintop="5px"
                        // height={"24px"}
                        padding={{ base: "-0.5em" }}
                        // fontSize={{ medium:"15.5px"}}
                        fontSize={{
                          base: "xs",
                          medium: "xs",
                          large: "small",
                      }}
                        marginRight="0.9em"
                        marginTop="0.5em"
                        fontWeight="500"
                        paddingLeft="0.5em"





                      ></Icon>{" "}
                      Share
                      {/* </Button> */}
                    </Flex>
                  </Card.Footer>
                </Card>
              </Card>
            ) : null}
            {selected == "mobile" ? (
              <Flex
                style={{
                  backgroundColor: "rgb(213 208 208 / 82%)",
                  marginTop: "3em",
                  border: "none",
                }}
                borderRadius="0.5em"
                paddingRight={{ base: "0em", medium: "1em", large: "3em" }}
                paddingLeft={{ base: "0em", medium: "1em", large: "3em" }}
              >
                <Flex
                  margin={"auto"}
                  width={{
                    base: "calc(100% - 0px)",
                    medium: "calc(100% - 0px)",
                    large: "calc(100% - 0px)",
                  }}
                  justifyContent="center"
                >
                  <Card
                    style={{
                      color: "black",
                      borderRadius: "0em",
                      width: "80%",
                    }}
                    className="shadow-lg"
                  >
                    <Card.Header>
                      <Flex direction={{ base: "row", large: "row" }}>
                        <Image
                          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                          width={{ base: 35, medium: 40, large: 40 }}
                          height={{ base: 35, medium: 40, large: 40 }}
                          alt="Avatar"
                          style={{
                            //Below lines will help to set the border radius
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                        />
                        <Text
                          fontWeight={"600"}
                          fontSize={{ base: "0.7em", large: "1em" }}
                        >
                          Webiner Design Spot <br />
                          <Text fontSize={"0.6em"} color="grey">
                            Sponsered{" "}
                            <Icon
                              as={BiWorld}
                              fontSize={{ large: "small" }}
                              color="grey"
                            ></Icon>
                          </Text>
                        </Text>
                        <Icon
                          as={HiDotsHorizontal}
                          fontSize={{ large: "xl" }}
                          alignItems="right"
                          justifyContent="right"
                          marginLeft={"auto"}
                          marginTop="0.5em"
                        ></Icon>
                      </Flex>
                      <Flex direction={{ base: "row", large: "row" }}>
                        <Text fontSize={"0.6em"}>
                          {AddformState.ad_primary_text}
                        </Text>
                      </Flex>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <Card.Text>
                        {!Mediatype ? (
                          <Image
                            className="img-responsive"
                            src={
                              multimedia_url != undefined
                                ? multimedia_url
                                : image
                            }
                            // alt="Second slide"
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              backgroundColor: "white",
                              maxHeight: "30vh",
                              borderBottom:
                                "1px solid var(--bs-border-color-translucent)",
                              // borderRadius: "0.5em",
                            }}
                          />
                        ) : (
                          <>
                          {signUrl !==undefined?(
                          <video
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              backgroundColor: "white",
                              maxHeight: "30vh",
                              borderBottom:
                                "1px solid var(--bs-border-color-translucent)",
                              // borderRadius: "0.5em",
                            }}
                            controls
                          >
                            <source
                              src={signUrl !== undefined ? signUrl : image}
                              // src={
                              //   multimedia_url !== ""
                              //     ? multimedia_url
                              //     : image
                              // }
                              type="video/mp4"
                            />
                          </video>):null}</>
                        )}
                        <Flex
                          direction={{
                            base: "row",
                            medium: "row",
                            large: "row",
                          }}
                          gap={"0em"}
                          marginRight="2em"
                        >
                          <View
                            padding="1.1em"
                            alignItems="center"
                            width={{
                              base: "80%",
                              medium: "60%",
                              large: "70%",
                            }}
                          >
                            <Text color="grey">
                              {webdisp}
                              <br />{" "}
                              <Text
                                fontWeight={"500"}
                                fontSize={{
                                  base: "xs",
                                  medium: "xs",
                                  large: "small",
                                }}
                              >
                                {AddformState.ad_headline}
                              </Text>
                              <Text
                                fontWeight={"500"}
                                fontSize={{
                                  base: "xs",
                                  medium: "xs",
                                  large: "small",
                                }}
                              >
                                {AddformState.ad_description}
                              </Text>
                            </Text>
                          </View>
                          <View
                            width={{
                              base: "100%",
                              medium: "40%",
                              large: "30%",
                            }}
                            marginLeft={"auto"}
                            marginTop="2em"
                          >
                            <Button
                              size={{
                                base: "small",
                                small: "xs",
                                medium: "xs",
                                large: "small",
                              }}
                              style={{
                                color: "#fff",
                                fontWeight: "600",
                                border: "none",
                              }}
                              boxShadow="large"
                              backgroundColor="#B71F39"
                              color="white"
                              marginLeft={"auto"}
                              fontSize={{
                                base: "xs",
                                small: "xs",
                                medium: "xs",
                                large: "small",
                              }}
                              width={{
                                base: "100%",
                                small: "100%",
                                medium: "120%",
                                large: "100%",
                              }}
                              hidden={!AddformState.call_to_action}
                            >
                              {AddformState.call_to_action}
                            </Button>
                          </View>
                        </Flex>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <Flex
                        direction={{ base: "row", large: "row" }}
                        //gap={{ base: "0.5em", medium: "1em", large: "1em" }}
                        gap={{ base: "1em", medium: "1em", large: "1em" }}
                        width={{ base: "100%", medium: "100%", large: "100%" }}
                        fontSize={{ base: "0.7em", large: "medium" }}
                      >
                        <Flex
                          width={{
                            base: "100%",
                            medium: "100%",
                            large: "100%",
                          }}
                        >
                          {/* {BiMessage} */}
                          <Icon
                            as={BiLike}
                            fontSize={{ base: "smaller", large: "medium" }}
                            marginRight="0.3em"
                            fontWeight="500"
                            marginInlineEnd={"-0.1em"}
                            //  marginBlockStart={"-5px"}
                            marginLeft={"-0.8em"}
                            marginTop={"4px"}

                            //  marginRight: "0.1em",
                            //  marginTop= "-5px"
                            //  fontSize: "1.3em",
                          ></Icon>{" "}
                          Like
                        </Flex>
                        <Flex
                          width={{
                            base: "100%",
                            medium: "100%",
                            large: "100%",
                          }}
                        >
                          {/* {RiShareForwardLine} */}
                          <Icon
                            as={BiMessage}
                            fontSize={{ base: "smaller", large: "medium" }}
                            marginRight="0.3em"
                            fontWeight="500"
                            //  marginInlineEnd={"-0.7"}
                            width={"2em"}
                            marginLeft={"-2.2em"}
                            marginTop={"4px"}

                            //width={"1em"}

                            //  fontSize: "1.3em",
                          ></Icon>{" "}
                          Command
                        </Flex>

                        {/*
                        </View> */}
                        {
                          <Flex
                            width={{
                              base: "100%",
                              medium: "150%",
                              large: "150%",
                            }}
                          >
                            <Icon
                              as={RiShareForwardLine}
                              fontSize={{ base: "smaller", large: "medium" }}
                              marginRight="0.3em"
                              fontWeight="500"
                              marginInlineEnd={"0.1em"}
                              marginTop={"4px"}
                              //width="10%"
                            ></Icon>{" "}
                            Share
                          </Flex>
                        }
                      </Flex>
                    </Card.Footer>
                  </Card>
                </Flex>
              </Flex>
            ) : null}
            {selected == "Right" ? (
              <Flex
                style={{
                  backgroundColor: "rgb(213 208 208 / 82%)",
                  marginTop: "3em",
                  border: "none",
                }}
                borderRadius="0.5em"
                paddingTop="10em"
                paddingBottom="10em"
                paddingRight="5em"
                paddingLeft="5em"
              >
                <Card
                  style={{ color: "black", width: "100%" }}
                  className="shadow-lg"
                >
                  <Card.Header className="p-4">
                    <Flex
                      direction={{
                        base: "row",
                        small: "row",
                        medium: "row",
                        large: "row",
                      }}
                    >
                      <View
                        width={{
                          base: "50%",
                          small: "50%",
                          medium: "50%",
                          large: "50%",
                        }}
                        margin="auto"
                      >
                        {!Mediatype ? (
                          <Image
                            src={
                              multimedia_url != undefined
                                ? multimedia_url
                                : image
                            }
                            className="rounded"
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              backgroundColor: "white",
                              maxHeight: "50vh",
                              borderBottom:
                                "1px solid var(--bs-border-color-translucent)",
                              // borderRadius: "0.5em",
                            }}
                            verticalAlign="middle"
                            imageResolution="initial"
                            alt="Avatar"
                          />
                        ) : (
                          <>
                          {signUrl !==undefined?(
                          <video
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              backgroundColor: "white",
                              maxHeight: "50vh",
                              borderBottom:
                                "1px solid var(--bs-border-color-translucent)",
                              // borderRadius: "0.5em",
                            }}
                            controls
                          >
                            <source
                              // src={
                              //   PresignedURLFunction !== ""
                              //     ? PresignedURLFunction
                              //     : image
                              // }
                              src={signUrl !== undefined ? signUrl : image}
                              // src={
                              //   multimedia_url !== ""
                              //     ? multimedia_url
                              //     : image
                              // }
                              type="video/mp4"
                            />
                          </video>):null}</>
                        )}
                      </View>
                      <View
                        width={{
                          base: "50%",
                          small: "50%",
                          medium: "50%",
                          large: "50%",
                        }}
                      >
                        <Text
                          fontWeight={"600"}
                          fontSize={{
                            base: "small",
                            small: "medium",
                            medium: "medium",
                            large: "medium",
                          }}
                        >
                          {AddformState.ad_headline} <br />
                          <Text
                            fontSize={{
                              base: "xs",
                              small: "xs",
                              medium: "xs",
                              large: "xs",
                            }}
                            color="grey"
                          >
                            {webdisp}
                          </Text>
                          {/* <Text
                              fontSize={{
                                base: "xs",
                                small: "xs",
                                medium: "xs",
                                large: "xs",
                              }}
                              color="grey"
                            >
                              {AddformState.ad_description}
                            </Text> */}
                        </Text>
                      </View>
                    </Flex>
                  </Card.Header>
                </Card>
              </Flex>
            ) : null}
            <Flex
              direction="row"
              marginTop={{ base: "xl", large: "xxl" }}
              justifyContent="flex-end"
            >
              {/* <Button
                backgroundColor="#202A44"
                style={primaryButton}
                boxShadow="large"
                borderRadius="2em"
                onClick={Redirectsaveandpublish}
              >
                Next{" "}
                <Icon
                  as={HiChevronDoubleRight}
                  fontSize={{ large: "xl" }}
                  color="white"
                  fontWeight={"bold"}
                  marginLeft="0.3em"
                  marginTop={"0.2em"}
                ></Icon>
              </Button> */}
            </Flex>
          </View>
        </Flex>
      </Flex>
      <Modal size="md" show={showad} onHide={handleClosead} border="none">
        <Modal.Header
          style={{
            border: "none",
            padding: "2em",
            paddingBottom: "0em",
            display: "flow-root",
          }}
        >
          <Flex direction={"row"} gap="xxxl" justifyContent="space-between">
            <View width="50%">
              <Text style={{ fontSize: "1.3em", fontWeight: "600" }}>
                Existing Ad
              </Text>
            </View>
            <View width="50%">
              <SearchField
                label="search"
                placeholder="Search..."
                size="small"
                onChange={(e) => handleChanges(e.target.value)}
              />
            </View>
          </Flex>
        </Modal.Header>
        <Modal.Body style={{ padding: "2em", paddingTop: "2em" }}>
          <View style={{ height: 200, width: "100%" }}>
            <DataGrid
              width="10%"
              headerHeight={40}
              rowHeight={40}
              sx={datagridSx}
              columns={columnsforadd}
              rows={listAdd}
              hideFooter
              pageSize={100}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
            />
          </View>
        </Modal.Body>
        {/* <Button variant="secondary" onClick={handleClose}>
    Close
  </Button> */}
        <Flex
          direction="column"
          alignItems="center"
          style={{ marginTop: "1em" }}
          marginBottom={{ large: "relative.large" }}
        >
          <Button
            style={primaryButton}
            boxShadow="large"
            onClick={() => SaveAd()}
          >
            Save
            <Icon
              as={BsHandIndexThumb}
              fontSize={{ large: "xl" }}
              color="white"
              fontWeight={"bold"}
              marginLeft="0.3em"
              marginTop={"0.3em"}
            ></Icon>
          </Button>
        </Flex>
      </Modal>
      <Modal size="xl" show={ShowContentLibrary} onHide={handleCloseadlibrary}>
        <Modal.Header
          style={{
            border: "none",
            padding: "2em",
            paddingBottom: "0em",
            display: "flow-root",
          }}
        >
          <Flex direction={"row"} gap="xxxl" justifyContent="space-between">
            <View width="50%">
              <Text style={{ fontSize: "1.3em", fontWeight: "600" }}>
                Content Library
              </Text>
            </View>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          {/* <MediaCollection
              setUrl={setImage}
              set_localimg={set_localimg}
            ></MediaCollection> */}

          <Collection
            type="grid"
            isSearchable={true}
            isPaginated={true}
            searchPlaceholder="Search..."
            itemsPerPage={12}
            templateColumns={{
              base: "1fr 1fr",
              small: "1fr 1fr 1fr",
              medium: "1fr 1fr 1fr",
              large: "1fr 1fr 1fr 1fr ",
              xl: "1fr 1fr 1fr 1fr 1fr 1fr",
              xxl: "1fr 1fr 1fr 1fr 1fr 1fr",
            }}
            autoFlow="row"
            marginLeft={{
              base: "0em",
              small: "0em",
              medium: "0em",
              large: "0em",
              xxl: "0em",
            }}
            alignItems="center"
            justifyContent="center"
            className="collectionad"
            items={ListOfContentLibrary.sort(function (a, b) {
              return a._lastChangedAt - b._lastChangedAt;
            }).reverse()} // items={listOfFile}
          >
            {(image, index) => (
              <Button
                key={index}
                gap="10px"
                direction="column"
                width={{
                  base: "150px",
                  medium: "140px",
                  small: "135px",
                  large: "170px",
                  xl: "165px",
                  xxl: "170px",
                }}
                height={{
                  base: "155px",
                  medium: "170px",
                  small: "180px",
                  large: "190px",
                  xl: "190px",
                  xxl: "190px",
                }}
                justifyContent="flex-start"
                alignItems="flex-start"
                position="relative"
                borderRadius="5px"
                padding="8px 7px 8px 7px"
                boxShadow={"large"}
                marginTop={"1em"}
                marginBottom={"1em"}
                marginLeft={"1em"}
                // backgroundColor="rgba(255,255,255,1)"
                onClick={handler}
                variation="link"
              >
                <Flex
                  gap="0px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                >
                  <Flex
                    gap="10px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    borderRadius="5px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(56, 53, 53, 0.06)"
                  >
                    <Image
                      src={image.media_url}
                      width="unset"
                      height={{
                        base: "110px",
                        medium: "120px",
                        small: "130px",
                        large: "140px",
                      }}
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      borderRadius="5px"
                      alt="image"
                      padding="0px 0px 0px 0px"
                      objectFit="contain"
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Flex>
                  <Flex
                    gap="1px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                  >
                    <Flex
                      gap="10px"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="10px 10px 10px 10px"
                    >
                      {" "}
                      {image.media_name.length > 15 ? (
                        <Text
                          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                          fontSize={{
                            base: "10px",
                            medium: "10px",
                            small: "10px",
                            large: "12px",
                            xxl: "15px",
                          }}
                          fontWeight="500"
                          color="rgba(0,0,0,1)"
                          lineHeight="14px"
                          textAlign="left"
                          display="block"
                          direction="column"
                          justifyContent="unset"
                          width="unset"
                          height="unset"
                          gap="unset"
                          alignItems="unset"
                          grow="1"
                          shrink="1"
                          basis="0"
                          position="relative"
                          padding="0px 0px 0px 0px"
                          whiteSpace="pre-wrap"
                          textTransform="capitalize"
                        >
                          {image.media_name.substring(0, 12)}...
                        </Text>
                      ) : (
                        <Text
                          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;"
                          fontSize={{
                            base: "10px",
                            medium: "10px",
                            small: "10px",
                            large: "12px",
                            xxl: "15px",
                          }}
                          fontWeight="500"
                          color="rgba(0,0,0,1)"
                          lineHeight="14px"
                          textAlign="left"
                          display="block"
                          direction="column"
                          justifyContent="unset"
                          width="unset"
                          height="unset"
                          textTransform="capitalize"
                          gap="unset"
                          alignItems="unset"
                          grow="1"
                          shrink="1"
                          basis="0"
                          position="relative"
                          padding="0px 0px 0px 0px"
                          whiteSpace="pre-wrap"
                        >
                          {image.media_name}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Button>
            )}
          </Collection>
          {/* <Button variant="secondary" onClick={handleClose}>
  Close
</Button> */}
          <Flex
            direction="column"
            alignItems="center"
            style={{ marginTop: "1em" }}
            marginBottom={{ large: "relative.large" }}
          >
            <Button
              style={primaryButton}
              boxShadow="large"
              onClick={handleCloseadlibrary}
            >
              Insert{" "}
              <Icon
                as={BsHandIndexThumb}
                fontSize={{ large: "large" }}
                color="white"
                fontWeight={"bold"}
                marginTop="0.4em"
                marginLeft="0.3em"
              ></Icon>
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Ad;
